import React, { useState, useEffect, memo } from 'react'
import { Button, Dialog, DialogContent, Collapse, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Occurrences from 'components/Occurrences/Occurrences'
import WordClouds from 'pages/Monitoring/modules/components/WordClouds/WordClouds'
import PoliticalRadar from 'components/PoliticalRadar/PoliticalRadar'
import VoxAi from 'components/VoxAI/VoxAi'
import TagViewer from 'pages/Monitoring/modules/components/TagViewer/TagViewer'
import { connect } from 'react-redux'
import { ListIcon, CloudIcon, RadarIcon, TagIcon } from 'utils/SystemIcons'
import { useQueryClient } from 'hooks/ReactQueryHooks'
import aiIcon from 'assets/img/whiteIcon.png'
import PhotoGalleryInspectInterval from 'components/PhotoGalleryInspectInterval/PhotoGalleryInspectInterval'

const blue = 'rgba(29, 138, 248, 100%)'

const isFirefox = typeof InstallTrigger !== 'undefined'

const useStyles = makeStyles({
	root: {
		color: blue,
		font: 'Poppins',
		fontWeight: '15'
	},
	label: {
		textTransform: 'capitalize'
	},
	button: {
		fontFamily: 'Poppins',
		border: '1px solid rgba(29, 138, 248, 25%)',
		boxShadow: 'none',
		color: blue,
		borderRadius: '100px',
		background: 'transparent',
		'&:hover': {
			border: '1px solid rgba(29, 138, 248, 90%)',
			backgroundColor: 'rgba(29, 138, 248, 5%)',
			boxShadow: '0px 0px -10px -17px rgba(0,0,0,255)'
		}
	},
	mainButton: {
		fontFamily: 'Poppins',
		border: '1px solid rgba(29, 138, 248, 80%)',
		boxShadow: 'none',
		color: 'white',
		borderRadius: '100px',
		background: 'rgba(29, 138, 248, 80%)',
		'&:hover': {
			border: '1px solid rgba(29, 138, 248, 50%)',
			backgroundColor: 'rgba(29, 138, 248, 50%)',
			boxShadow: '0px 0px -10px -17px rgba(0,0,0,255)'
		}
	},
	buttonNoborder: {
		fontFamily: 'Poppins',
		border: '1px solid rgba(29, 138, 248, 0%)',
		boxShadow: 'none',
		color: blue,
		borderRadius: '100px',
		background: 'transparent',
		'&:hover': {
			border: '1px solid rgba(255, 138, 248, 10%)',
			backgroundColor: 'rgba(29, 138, 248, 5%)',
			boxShadow: '0px 0px -10px -17px rgba(0,0,0,255)'
		}
	},
	backDrop: {
		backdropFilter: 'blur(3px)',
		backgroundColor: 'rgba(220, 220, 220, 0.4)'
	}
})

const InspectIntervalButton = ({
	cards,
	getInterval,
	filters,
	border = true,
	mainComparison = '',
	hideCardSelector = null,
	filtersWordClouds = { name: null, type: null },
	cardType = null,
	comparisonStatusRedux,
	showOccurrences = true,
	showCloud = false,
	showRadar = false,
	showAi = false,
	showTags = false,
	graphData = null,
	topicId = null,
	isCommentWordCloud = false,
	showPhotoGallery = false,
	style = {}
}) => {
	const [open, setOpen] = useState(false)
	const [thisFilters, setThisFilters] = useState(filters)
	const [inspectType, setInspectType] = useState('occurrences')

	useEffect(() => {
		if (getInterval) {
			const interval = getInterval()
			setThisFilters({ ...filters, fromDate: interval.fromDate, toDate: interval.toDate })
		} else {
			setThisFilters(filters)
		}
	}, [filters])

	const classes = useStyles()

	const handleClickOpen = (inspectType) => {
		if (getInterval) {
			const interval = getInterval()
			setThisFilters({
				...thisFilters,
				fromDate: interval.fromDate,
				toDate: interval.toDate
			})
		}

		setInspectType(inspectType)
		setOpen(true)
	}

	const queryClient = useQueryClient()

	const handleClose = () => {
		if (inspectType === 'occurrences') {
			queryClient.invalidateQueries('relevanceChange_useUpdateData')
		}
		setInspectType('')
		setOpen(false)
	}

	return (
		<Grid container style={style}>
			<Collapse in={showAi} orientation='horizontal'>
				<Grid item>
					<Button
						id='btn-open-ai'
						disableElevation
						disabled={!showAi}
						variant='outlined'
						onClick={() => handleClickOpen('voxAi')}
						style={{ outline: 'none', maxHeight: '36.5px', whiteSpace: 'nowrap', marginRight: '2em' }}
						className={classes.mainButton}
					>
						<img src={aiIcon} style={{ width: '26px', marginRight: '6px' }} />
						Obter análise com IA
					</Button>
				</Grid>
			</Collapse>
			{showOccurrences && (
				<Button
					id='open-posts-or-comments-btn'
					disableElevation
					variant='outlined'
					onClick={() => handleClickOpen('occurrences')}
					style={{ outline: 'none' }}
					className={border ? classes.button : classes.buttonNoborder}
				>
					<ListIcon size={16} style={{ color: blue, marginRight: '5px' }} />
					{isCommentWordCloud ? 'Comentários' : 'Postagens'}
				</Button>
			)}
			{showPhotoGallery && (
				<Button
					id='open-photo-gallery-btn'
					disableElevation
					variant='outlined'
					onClick={() => handleClickOpen('photoGallery')}
					style={{ outline: 'none', marginLeft: '2em' }}
					className={border ? classes.button : classes.buttonNoborder}
				>
					<ListIcon size={16} style={{ color: blue, marginRight: '5px' }} />
					Galeria de fotos
				</Button>
			)}
			{showCloud && (
				<Button
					disableElevation
					variant={border ? 'outlined' : 'text'}
					onClick={() => handleClickOpen('clouds')}
					style={{ outline: 'none', marginLeft: '2em' }}
					className={border ? classes.button : classes.buttonNoborder}
				>
					<CloudIcon size={16} style={{ color: blue, marginRight: '5px' }} />
					Nuvem
				</Button>
			)}

			{showTags && (
				<Button
					id='btn-open-tags'
					disableElevation
					variant={border ? 'outlined' : 'text'}
					onClick={() => handleClickOpen('tags')}
					style={{ outline: 'none', marginLeft: '2em' }}
					className={border ? classes.button : classes.buttonNoborder}
				>
					<TagIcon size={16} style={{ color: blue, marginRight: '5px' }} />
					Tags
				</Button>
			)}
			{showRadar && filters.sources.includes('twitter') && (
				<Button
					id='btn-open-radar'
					disableElevation
					variant={border ? 'outlined' : 'text'}
					onClick={() => handleClickOpen('radar')}
					style={{ outline: 'none', marginLeft: '2em' }}
					className={border ? classes.button : classes.buttonNoborder}
				>
					<RadarIcon size={16} style={{ color: blue, marginRight: '5px' }} />
					Radar político
				</Button>
			)}

			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
				fullWidth={true}
				maxWidth={inspectType === 'voxAi' || inspectType === 'tags' ? 'xl' : 'md'}
				PaperProps={{
					style: {
						overflowX: 'hidden',
						borderRadius: '20px',
						backdropFilter: 'blur(10px)',
						backgroundColor: `${isFirefox ? 'rgba(245, 245, 245, 1)' : 'rgba(255, 255, 255, 0.4)'}`,
						border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
						boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
					}
				}}
				BackdropProps={{
					style: {
						backdropFilter: 'blur(3px)',
						backgroundColor: 'rgba(220, 220, 220, 0.4)'
					}
				}}
			>
				{['occurrences', 'photoGallery', 'clouds', 'radar', 'tags'].includes(inspectType) && (
					<>
						{inspectType === 'occurrences' && showOccurrences && (
							<Occurrences
								cards={cards}
								isDialog={true}
								filters={thisFilters}
								mainComparison={mainComparison}
								hideCardSelector={hideCardSelector}
								filtersWordClouds={filtersWordClouds}
								type={cardType}
								topicId={topicId}
								isCommentWordCloud={isCommentWordCloud}
							/>
						)}
						{inspectType === 'photoGallery' && showPhotoGallery && (
							// <DialogContent style={{ margin: '0' }}>
							<PhotoGalleryInspectInterval cards={cards} filters={thisFilters} mainComparison={mainComparison} type={cardType} />
							// </DialogContent>
						)}

						{inspectType === 'clouds' && showCloud && (
							<DialogContent style={{ overflowY: 'hidden', marginBottom: '15px' }}>
								<WordClouds
									indentifier={'inspect-btn-wordcloud'}
									cardStatus={comparisonStatusRedux}
									cards={cards}
									externalFilters={thisFilters}
								/>
							</DialogContent>
						)}

						{inspectType === 'tags' && showTags && (
							<DialogContent style={{ overflowY: 'hidden', marginBottom: '15px' }}>
								<TagViewer cards={cards} isFetchingData={inspectType === 'tags'} />
							</DialogContent>
						)}

						{inspectType === 'radar' && showRadar && (
							<DialogContent style={{ overflowY: 'hidden', marginBottom: '15px' }}>
								<PoliticalRadar cardStatus={comparisonStatusRedux} cards={cards} externalFilters={thisFilters} />
							</DialogContent>
						)}
					</>
				)}
				{inspectType === 'voxAi' && showAi && <VoxAi cards={cards} graphData={graphData} isFetchingData={inspectType === 'voxAi'} />}
			</Dialog>
		</Grid>
	)
}

const mapStateToProps = ({ store }) => ({
	comparisonStatusRedux: store.card.comparison.status
})

export default connect(mapStateToProps)(memo(InspectIntervalButton))
