import React from 'react'
import { MessageIcon, UserIcon, GroupIcon, BubbleIcon, ErrorIcon, LoadingIcon, AlertIcon, User2Icon } from 'utils/SystemIcons'
import constantsVox from 'constants-vox'

const handleClickRedirectCard = (cardType, history, linkDownload) => {
	if (cardType === 'term') {
		history.push('/monitoring/sentiment-analysis')
	} else if (cardType === 'profile') {
		history.push('/monitoring/social-profile')
	} else if (cardType === 'group') {
		history.push('/monitoring/accounts-group')
	} else if (cardType === 'ACCOUNT_MANAGEMENT') {
		history.push('/account-management/social-profile')
	} else if (cardType === 'report' && linkDownload) {
		const newWindow = window.open(linkDownload, '_blank', 'noopener,noreferrer')
		if (newWindow) {
			newWindow.opener = null
		}
	}
}

const CardIcon = ({ status, type, termsRemainingMonth, searchType, isDemo }) => {
	let color = ''
	let icon = <></>

	switch (type.toUpperCase()) {
		case constantsVox.CARD_TYPE.TERM:
			color = '#1d8cf8'
			icon =
				searchType === constantsVox.CARD.SEARCH_TYPE.PROFILE ? (
					<GroupIcon size={25} style={{ color }} />
				) : (
					<MessageIcon size={25} style={{ color }} />
				)
			break
		case constantsVox.CARD_TYPE.ACCOUNT:
			color = '#ac60f7'
			icon = <UserIcon size={25} style={{ color }} />
			break
		case constantsVox.CARD_TYPE.GROUP:
			color = '#d41993'
			icon = <GroupIcon size={25} style={{ color }} />
			break
		case constantsVox.CARD_TYPE.ACCOUNT_MANAGEMENT:
			color = '#45c8d9'
			icon = <User2Icon size={25} style={{ color }} />
			break
		case constantsVox.CARD_TYPE.REPORT.GENERAL:
			color = '#18c1c4'
			icon = <BubbleIcon size={25} style={{ color }} />
			break
	}

	if (status === 'P' || status === 'C' || status === 'A') {
		icon = <LoadingIcon thickness={2} style={{ color, width: '25px', height: '25px' }} />
	} else if (status === 'E') {
		icon = <AlertIcon size={25} style={{ color }} />
	} else if (status !== 'S' && type.toUpperCase() !== constantsVox.CARD_TYPE.ACCOUNT_MANAGEMENT) {
		icon = <ErrorIcon size={25} style={{ color }} />
	}

	if (
		!isDemo &&
		termsRemainingMonth !== null &&
		type.toUpperCase() === constantsVox.CARD_TYPE.TERM &&
		searchType === constantsVox.CARD.SEARCH_TYPE.TERM
	) {
		if (termsRemainingMonth <= 0) {
			color = '#ff0000'
			icon = <ErrorIcon size={25} style={{ color }} />
		}
	}

	return icon
}

const getCardColorByCardType = (type) => {
	switch (type.toUpperCase()) {
		case constantsVox.CARD_TYPE.TERM:
			return '#1d8cf8'
		case constantsVox.CARD_TYPE.ACCOUNT:
			return '#ac60f7'
		case constantsVox.CARD_TYPE.GROUP:
			return '#d41993'
		case constantsVox.CARD_TYPE.ACCOUNT_MANAGEMENT:
			return '#45c8d9'
		case constantsVox.CARD_TYPE.REPORT.GENERAL:
			return '#18c1c4'
	}
}

const getBoxShadowByCardType = (type) => {
	switch (type.toUpperCase()) {
		case constantsVox.CARD_TYPE.TERM:
			return 'rgba(29, 140, 248, 0.25)'
		case constantsVox.CARD_TYPE.ACCOUNT:
			return 'rgba(172, 96, 247,0.25)'
		case constantsVox.CARD_TYPE.GROUP:
			return 'rgba(212, 25, 147,0.25)'
		default:
			return 'rgba(24, 193, 196,0.25)'
	}
}

const getClassNameByCardType = (type) => {
	switch (type.toUpperCase()) {
		case constantsVox.CARD_TYPE.TERM:
			return 'primary-round-button-app'
		case constantsVox.CARD_TYPE.ACCOUNT:
			return 'primary-round-button-app-profile'
		case constantsVox.CARD_TYPE.GROUP:
			return 'primary-round-button-app-group'
		case constantsVox.CARD_TYPE.REPORT.GENERAL:
			return 'primary-round-button-app-report'
		default:
			return 'primary-round-button-app-account-management'
	}
}

export { handleClickRedirectCard, CardIcon, getCardColorByCardType, getBoxShadowByCardType, getClassNameByCardType }
