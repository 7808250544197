import React, { useState, useEffect, useRef } from 'react'
import { Button, Dialog, Grid, DialogContent } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { LoadingIcon } from 'utils/SystemIcons'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import api from 'service/service'
import constantsVox from 'constants-vox'
import AurasightSyncVideos from './components/AurasightSyncVideos/AurasightSyncVideos'

const grey = '#828282'

const isFirefox = typeof InstallTrigger !== 'undefined'

const useStyles = makeStyles({
	backDrop: {
		backdropFilter: 'blur(3px)',
		backgroundColor: 'rgba(220, 220, 220, 0.4)'
	},
	button: {
		fontFamily: 'Poppins',
		border: '1px solid rgba(29, 138, 248, 100%)',
		boxShadow: 'none',
		color: 'white',
		backgroundColor: 'rgba(29, 138, 248, 100%)',
		borderRadius: '100px',
		background: 'transparent',
		'&:hover': {
			border: '1px solid rgba(29, 138, 248, 25%)',
			backgroundColor: 'rgba(29, 138, 248, 75%)',
			boxShadow: '0px 0px -10px -17px rgba(0,0,0,255)'
		}
	},
	tab: {
		fontFamily: 'Poppins',
		fontSize: 12,
		fontWeight: 300,
		color: grey
	},
	loadingContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '10vh'
	}
})

const isVideo = (url) => {
	return !!url?.match(/\.(mp4|webm|ogg)$/i)
}

const isImage = (url) => {
	return !!url?.match(/\.(jpeg|jpg|gif|png|bmp|svg|webp)$/i)
}

//a function that get de first 10 items of the array
const getFirstItems = (array, itemsPerPage) => {
	return array.slice(0, itemsPerPage)
}

const itemsPerPage = 10

const AurasightMediaDialog = ({ inferenceKey, open, onClose, media, setMedia, setChangeInference }) => {
	const [isSubmittingRemoveInference, setIsSubmittingRemoveInference] = useState(false)
	const [openMedia, setOpenMedia] = useState(false)
	const [selectedMedia, setSelectedMedia] = useState(null)
	const [selectedOriginalMedia, setSelectedOriginalMedia] = useState(null)
	const [currentPage, setCurrentPage] = useState(0)
	const [paginatedMedia, setPaginatedMedia] = useState([])
	const [loadingVideos, setLoadingVideos] = useState({})

	const classes = useStyles()

	useEffect(() => {
		setPaginatedMedia(media.slice(0, itemsPerPage))
		setCurrentPage(0)
		setSelectedMedia(null)
	}, [media, open])

	const loadMore = () => {
		const nextPage = currentPage + 1
		const newMedia = media.slice(0, (nextPage + 1) * itemsPerPage)
		setPaginatedMedia(newMedia)
		setCurrentPage(nextPage)
	}

	const removeInference = async (body) => {
		setIsSubmittingRemoveInference(true)
		try {
			const response = await api.post('/image-annotation/remove-inference', body)
			if (response.status === 200) {
				sendMessageToast(response?.data?.toast?.message, constantsVox.TOAST.TYPES.SUCCESS)
				setMedia(media.filter((item) => item.id !== body.occurrenceId))
				setChangeInference(true)
			}
		} catch (error) {
			sendMessageToast(error?.response?.data?.toast?.message, constantsVox.TOAST.TYPES.ERROR)
		} finally {
			setIsSubmittingRemoveInference(false)
		}
	}

	const handleClickOpen = (video, originalVideo) => {
		setSelectedMedia(video)
		setSelectedOriginalMedia(originalVideo)
		setOpenMedia(true)
	}

	const handleClose = () => {
		setOpenMedia(false)
		setSelectedMedia(null)
	}

	const handleVideoLoadStart = (media) => {
		setLoadingVideos((prev) => ({ ...prev, [media]: true }))
	}

	const handleVideoLoadedData = (media) => {
		setLoadingVideos((prev) => ({ ...prev, [media]: false }))
	}

	return (
		<>
			<Dialog
				id='dialog-app'
				maxWidth='md'
				fullWidth
				size='lg'
				open={open}
				style={{ marginTop: '5em' }}
				onClose={onClose}
				BackdropProps={{ classes: { root: classes.backDrop } }}
				PaperProps={{
					style: {
						borderRadius: '20px',
						backdropFilter: 'blur(10px)',
						backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
						border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.7)'}`,
						boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
					}
				}}
				unmountOnExit
			>
				<div id='dialog-content-gallery' style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', padding: '10px' }}>
					{paginatedMedia?.map((item, index) => (
						<div
							key={item.id}
							style={{
								flex: index % 5 === 0 ? '1 1 40%' : '1 1 20%',
								display: 'flex',
								borderRadius: '10px',
								border: '1px solid #e0e0e0'
							}}
						>
							{/* {loadingVideos[item.media] && (
								<div className={classes.loadingContainer}>
									<LoadingIcon size={40} color='#1d8cf8' />
								</div>
							)} */}
							{isVideo(item.media) &&
								(item?.thumbnail_media ? (
									<img
										onClick={() => handleClickOpen(item.media, item.original_media)}
										src={item.thumbnail_media}
										alt=''
										style={{
											width: '100%',
											height: 'auto',
											borderRadius: '10px',
											objectFit: 'cover'
										}}
									/>
								) : (
									// necessário para não quebrar os antigos
									<video
										onClick={() => handleClickOpen(item.media, item.original_media)}
										src={item.media}
										onLoadStart={() => handleVideoLoadStart(item.media)}
										onLoadedData={() => handleVideoLoadedData(item.media)}
										muted
										// autoPlay
										// loop
										style={{
											width: '100%',
											height: 'auto',
											borderRadius: '10px',
											objectFit: 'cover',
											cursor: 'pointer'
										}}
									/>
								))}
							{isImage(item.media) && (
								<>
									{/* <Button
									disabled={isSubmittingRemoveInference}
									onClick={() =>
										removeInference({
											occurrenceId: item?.id,
											inferenceMediaId: item?.inferenceId,
											inferenceLabel: inferenceKey
										})
									}
									style={{ borderRadius: '20px', position: 'absolute', top: '10px', right: '10px', zIndex: 1 }}
									variant='contained'
								>
									{isSubmittingRemoveInference && <LoadingIcon size={20} thickness={2} style={{ marginTop: '3px' }} />}
									<span style={{ marginLeft: '0.3em' }}>REMOVER ANOTAÇÔES {inferenceKey}</span>
								</Button> */}
									<img
										src={item.media}
										alt=''
										style={{
											width: '100%',
											height: 'auto',
											borderRadius: '10px',
											objectFit: 'cover'
										}}
									/>
								</>
							)}
							{!isVideo(item.media) && !isImage(item.media) && (
								<div style={{ textAlign: 'center', color: '#b0b0b0' }}>Mídia com formato indisponível</div>
							)}
						</div>
					))}
					{paginatedMedia.length !== media.length && (
						<Button fullWidth onClick={loadMore}>
							load more
						</Button>
					)}
				</div>
				{(!media || media.length === 0) && (
					<Grid container direction='row' justifyContent='center' alignItems='center' style={{ paddingBottom: '5em' }}>
						<h2 style={{ fontFamily: 'Poppins', color: '#b0b0b0', fontWeight: 'normal' }}>Não existe label para as mídias.</h2>
					</Grid>
				)}
			</Dialog>

			<AurasightSyncVideos open={openMedia} onClose={handleClose} media={selectedMedia} originalMedia={selectedOriginalMedia} />
		</>
	)
}

export default AurasightMediaDialog
