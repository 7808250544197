import React, { useState, useEffect, useRef, memo } from 'react'
import { connect } from 'react-redux'
import { Grid } from '@mui/material'
import { menuDefaultNew } from './utils/functionsSocialProfileAccountManagement'
import { useUpdateData } from 'hooks/ReactQueryHooks'
import { verifyCanShowMenuItemSocialProfileAccountManagement, verifyCardComparisonStatuses } from 'utils/generalFunctions'
import SideDrawer from 'components/SideDrawer/SideDrawer'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import GlobalfilterBar from 'components/GlobalFilters/components/GlobalFilterBar/GlobalFilterBar'
import OccurrencesContent from 'components/OccurrencesContent/OccurrencesContent'
import constantsVox from 'constants-vox'
import { updateCardComparisonStatusAction } from 'store/redux/actions/card.actions'
import DashboardShareable from 'components/DashboardShareable/DashboardShareable'

function getKeysWithS(obj) {
	return Object.keys(obj).filter((key) => key !== 'general' && obj[key] === 'S')
}

const SocialProfileAccountManagement = ({
	cardComparison,
	history,
	globalFiltersRedux,
	location,
	updateCardComparisonStatusDispatch,
	isSharing,
	sharingDashboard
}) => {
	const [menuState, setMenuState] = useState(null)
	const [tab, setTab] = useState(location.state?.menu || 'analysis') // socialProfile,overview,occurrences,wordClouds,export,report
	const [cards, setCards] = useState(cardComparison)
	const cardType = cards[0]?.type
	let updatedCards = useUpdateData({
		url: `/account-management/profile/get-by-ids/${cards?.map((card) => card.id)}`,
		updateItemName: 'Update cards',
		refetchInterval: 5000,
		freshDataTime: 5000,
		isEnabled: cards.length > 0
	})

	const isMounted = useRef(true)

	useEffect(() => {
		setCards(cardComparison)
	}, [cardComparison])

	useEffect(() => {
		isMounted.current = true
		return () => {
			isMounted.current = false
		}
	}, [])

	useEffect(() => {
		if (updatedCards?.data?.length) {
			//Atualiza status no redux (filtro global de source)
			const newCardStatus = verifyCardComparisonStatuses(updatedCards?.data, constantsVox.CARD_TYPE.ACCOUNT_MANAGEMENT)
			updateCardComparisonStatusDispatch(newCardStatus)

			//Atualiza status para o menu lateral
			let canShowMenuItem = verifyCanShowMenuItemSocialProfileAccountManagement(updatedCards?.data)
			setMenuState(
				menuDefaultNew(
					canShowMenuItem?.twitter_data_status,
					canShowMenuItem?.facebook_data_status,
					canShowMenuItem?.instagram_data_status,
					canShowMenuItem?.tiktok_data_status,
					canShowMenuItem?.meta_ads_data_status,
					canShowMenuItem[globalFiltersRedux.sources + 'DataLoadStatusPosts']
				)
			)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updatedCards?.data, globalFiltersRedux])

	if (updatedCards.error && cards?.length > 0 && !isSharing) {
		if (updatedCards.error?.response?.data?.toast) {
			sendMessageToast(updatedCards.error?.response?.data?.toast?.message, updatedCards.error?.response?.data?.toast?.type)
		}

		history.push('/account-management')
	}

	if (cards?.length < 1) {
		if (!isSharing) {
			history.push('/account-management')
		}
		return null
	}

	const onSelectItem = (item) => {
		setTab(item)
	}

	return (
		<>
			{!isSharing && <SideDrawer itemsToRender={menuState} onSelectItem={onSelectItem} selectedValue={tab} />}
			<div className={isSharing ? 'sharing-page-content' : 'page-content'}>
				<GlobalfilterBar isOverview={tab === 'overview'} disableSearch={tab === 'analysis'} />
				{getKeysWithS(verifyCardComparisonStatuses(updatedCards?.data, constantsVox.CARD_TYPE.ACCOUNT_MANAGEMENT)).includes(
					globalFiltersRedux?.sources[0]
				) && (
					<Grid container>
						{tab === 'analysis' && (
							<Grid container item>
								<DashboardShareable
									cardList={cards}
									globalFilters={globalFiltersRedux}
									isFetchingData={true}
									isSharing={isSharing}
									sharingDashboard={sharingDashboard}
									cardType={cardType}
								/>
							</Grid>
						)}
						{/* {tab === 'postsMetrics' && !isSharing && (
						<Grid container item>
							<AccountManagerMetricsPostsContent cardList={cards} isFetchingData={true} />
						</Grid>
					)} */}
						{tab === 'occurrences' && !isSharing && (
							<Grid container item>
								<OccurrencesContent cards={cardComparison} type={constantsVox.CARD_TYPE.ACCOUNT_MANAGEMENT} />
							</Grid>
						)}
					</Grid>
				)}
			</div>
		</>
	)
}

const mapStateToProps = ({ store }) => ({
	cardComparison: store.card.comparison.cards,
	globalFiltersRedux: store.global.filters
})

const mapDispatchToProps = (dispatch) => ({
	updateCardComparisonStatusDispatch: (newComparisonStatus) => dispatch(updateCardComparisonStatusAction(newComparisonStatus))
})

export default connect(mapStateToProps, mapDispatchToProps)(memo(SocialProfileAccountManagement))
