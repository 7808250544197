import React, { useState, memo, useEffect } from 'react'
import { Grid, Collapse, Button, Checkbox, Tooltip } from '@mui/material'
import './OccurrenceProfileDropdown.css'
import { ChevronUpIcon, ChevronDownIcon } from 'utils/SystemIcons'

const checkIfAllChildrenAreEmpty = (obj) => {
	if (obj === null || obj === undefined) {
		return true
	}
	if (typeof obj === 'object') {
		for (let key in obj) {
			if (!checkIfAllChildrenAreEmpty(obj[key])) {
				return false
			}
		}
		return true
	}
	if (typeof obj === 'string') {
		return obj === ''
	}
	return false
}

const OccurrenceProfileDropdown = ({
	cardId,
	children,
	profileData,
	buttonChildren,
	disabled = false,
	color,
	source,
	highlightedBio,
	openProfileInfo,
	toggleProfileInfo,
	isTopPosts = false,
	indexNumber,
	type,
	isButtonActive = true,
	onChangeSelectedPostsIds = null,
	selectedPostsIds = null,
	externalMultipleSelection = false,
	checkInstagramProfileEmpty,
	urlOriginal
}) => {
	const [thisHighlightedBio, setThisHighlightedBio] = useState(null)

	useEffect(() => {
		setThisHighlightedBio(highlightedBio)
	}, [highlightedBio])

	const handleToggle = () => {
		toggleProfileInfo()
	}

	const handleSelect = () => {
		onChangeSelectedPostsIds(cardId)
	}

	const linkText = 'ABRIR PERFIL'

	linkText.toUpperCase()

	return (
		<div>
			{isButtonActive && (
				<div style={{ display: 'flex', alignItems: 'center' }}>
					{isTopPosts && (
						<div style={{ fontFamily: 'Poppins', fontSize: '70px', textAlign: 'center', marginRight: '0.3em' }}>{indexNumber}</div>
					)}

					<Collapse orientation='horizontal' in={externalMultipleSelection} unmountOnExit>
						<Checkbox
							color='primary'
							style={{ marginRight: '10px' }}
							checked={selectedPostsIds && selectedPostsIds.find((postsId) => postsId === cardId)}
							onClick={handleSelect}
						/>
					</Collapse>
					{disabled ? (
						<Tooltip title='Abrir Perfil' placement='bottom' enterDelay={500} enterNextDelay={500}>
							<Button id='btn-profile-twitter' style={{ outline: 'none', borderRadius: '100px' }} disabled>
								{buttonChildren}
							</Button>
						</Tooltip>
					) : (
						<>
							{source === 'twitter' && (
								<a
									href={`https://www.twitter.com/${
										['term', 'profile', 'group'].includes(type) ? profileData?.username : profileData?.screen_name
									}`}
									target='_blank'
									rel='noopener noreferrer'
									className='occurrence-dropdown-link-abrir-perfil'
								>
									<Tooltip title='Abrir Perfil' placement='bottom' enterDelay={500} enterNextDelay={500}>
										<Button id='btn-profile-twitter' style={{ outline: 'none', borderRadius: '100px' }}>
											{buttonChildren}
										</Button>
									</Tooltip>
								</a>
							)}
							{source === 'bluesky' && (
								<a
									href={`https://bsky.app/profile/${
										['term', 'profile', 'group'].includes(type) ? profileData?.username : profileData?.screen_name
									}`}
									target='_blank'
									rel='noopener noreferrer'
									className='occurrence-dropdown-link-abrir-perfil'
								>
									<Tooltip title='Abrir Perfil' placement='bottom' enterDelay={500} enterNextDelay={500}>
										<Button id='btn-profile-bluesky' style={{ outline: 'none', borderRadius: '100px' }}>
											{buttonChildren}
										</Button>
									</Tooltip>
								</a>
							)}
							{source === 'facebook' && (
								<a
									href={
										['term', 'profile', 'group'].includes(type)
											? `https://www.facebook.com/${profileData?.username}`
											: profileData?.url
									}
									target='_blank'
									rel='noopener noreferrer'
									className='occurrence-dropdown-link-abrir-perfil'
								>
									<Tooltip title='Abrir Perfil' placement='bottom' enterDelay={500} enterNextDelay={500}>
										<Button id='btn-profile-facebook' style={{ outline: 'none', borderRadius: '100px' }}>
											{buttonChildren}
										</Button>
									</Tooltip>
								</a>
							)}
							{source === 'instagram' && (
								<>
									{!checkInstagramProfileEmpty(source) ? (
										<a
											href={`https://www.instagram.com/${profileData?.username}`}
											target='_blank'
											rel='noopener noreferrer'
											className='occurrence-dropdown-link-abrir-perfil'
										>
											<Tooltip title='Abrir Perfil' placement='bottom' enterDelay={500} enterNextDelay={500}>
												<Button id='btn-profile-instagram' style={{ outline: 'none', borderRadius: '100px' }}>
													{buttonChildren}
												</Button>
											</Tooltip>
										</a>
									) : (
										<a
											href={urlOriginal || ''}
											target='_blank'
											rel='noopener noreferrer'
											className='occurrence-dropdown-link-abrir-perfil'
										>
											<Tooltip title='Abrir Original' placement='bottom' enterDelay={500} enterNextDelay={500}>
												<Button id='btn-profile-instagram-empty' style={{ outline: 'none', borderRadius: '100px' }}>
													{buttonChildren}
												</Button>
											</Tooltip>
										</a>
									)}
								</>
							)}
							{source === 'tiktok' && (
								<a
									href={`https://www.tiktok.com/@${profileData?.username}`}
									target='_blank'
									rel='noopener noreferrer'
									className='occurrence-dropdown-link-abrir-perfil'
								>
									<Tooltip title='Abrir Perfil' placement='bottom' enterDelay={500} enterNextDelay={500}>
										<Button id='btn-profile-tiktok' style={{ outline: 'none', borderRadius: '100px' }}>
											{buttonChildren}
										</Button>
									</Tooltip>
								</a>
							)}
							{source === 'youtube' && (
								<a
									href={`https://www.youtube.com/@${profileData?.username}`}
									target='_blank'
									rel='noopener noreferrer'
									className='occurrence-dropdown-link-abrir-perfil'
								>
									<Tooltip title='Abrir Perfil' placement='bottom' enterDelay={500} enterNextDelay={500}>
										<Button id='btn-profile-youtube' style={{ outline: 'none', borderRadius: '100px' }}>
											{buttonChildren}
										</Button>
									</Tooltip>
								</a>
							)}
							{source === 'news' && (
								<a
									href={urlOriginal || ''}
									target='_blank'
									rel='noopener noreferrer'
									className='occurrence-dropdown-link-abrir-perfil'
								>
									<Tooltip title='Abrir Original' placement='bottom' enterDelay={500} enterNextDelay={500}>
										<Button id='btn-profile-news' style={{ outline: 'none', borderRadius: '100px' }}>
											{buttonChildren}
										</Button>
									</Tooltip>
								</a>
							)}
						</>
					)}

					{children}
				</div>
			)}

			<div className='divider-thin-default' style={{ marginTop: '10px' }} />
			<Collapse in={openProfileInfo}>
				{['twitter', 'bluesky'].includes(source) && (
					<Grid container style={{ fontSize: '14px', padding: '1em' }}>
						<Grid container item xs={12}>
							<Grid item md={9} xs={12}>
								<span style={{ fontFamily: 'Poppins', fontWeight: '600', fontSize: '1.3em', color: 'black' }}>
									{profileData?.username ? profileData?.username : profileData?.name}
								</span>
							</Grid>
						</Grid>
						{(['term', 'profile', 'group'].includes(type)
							? !!profileData?.engagement?.followersCount
							: !!profileData?.followers_count) && (
							<Grid item xs={12}>
								<span style={{ fontFamily: 'Poppins', fontWeight: '600', fontSize: '1.1em', color: 'black' }}>
									{['term', 'profile', 'group'].includes(type)
										? profileData?.engagement?.followersCount
										: profileData?.followers_count}
								</span>{' '}
								<span style={{ fontFamily: 'Poppins', fontWeight: '500', color: '#9a9a9a' }}>seguidores</span>
							</Grid>
						)}
						{(['term', 'profile', 'group'].includes(type) ? !!profileData?.engagement?.followingCount : !!profileData?.friends_count) && (
							<Grid item xs={12}>
								<span style={{ fontFamily: 'Poppins', fontWeight: '600', fontSize: '1.1em', color: 'black' }}>
									{['term', 'profile', 'group'].includes(type)
										? profileData?.engagement?.followingCount
										: profileData?.friends_count}
								</span>{' '}
								<span style={{ fontFamily: 'Poppins', fontWeight: '500', color: '#9a9a9a' }}>seguindo</span>
							</Grid>
						)}
						{(['term', 'profile', 'group'].includes(type) ? !!profileData?.engagement?.postsCount : !!profileData?.statuses_count) && (
							<Grid item xs={12}>
								<span style={{ fontFamily: 'Poppins', fontWeight: '600', fontSize: '1.1em', color: 'black' }}>
									{['term', 'profile', 'group'].includes(type) ? profileData?.engagement?.postsCount : profileData?.statuses_count}
								</span>{' '}
								<span style={{ fontFamily: 'Poppins', fontWeight: '500', color: '#9a9a9a' }}>tweets</span>
							</Grid>
						)}
						<Grid item xs={12} style={{ fontFamily: 'Poppins', fontWeight: '500', marginTop: '1em', color: '#9a9a9a' }}>
							{thisHighlightedBio ? (
								<div dangerouslySetInnerHTML={{ __html: thisHighlightedBio }} />
							) : ['term', 'profile', 'group'].includes(type) ? (
								profileData?.description
							) : (
								profileData?.bio
							)}
						</Grid>
					</Grid>
				)}

				{source === 'facebook' && (
					<Grid container style={{ fontSize: '14px', padding: '1em' }}>
						<Grid container item xs={12}>
							<Grid item md={9} xs={12}>
								<span style={{ fontFamily: 'Poppins', fontWeight: '600', fontSize: '1.3em', color: 'black' }}>
									{profileData?.username ? profileData?.username : profileData?.name}
								</span>
							</Grid>
						</Grid>
						{(['term', 'profile', 'group'].includes(type)
							? !!profileData?.engagement?.followersCount
							: !!profileData?.follower_count) && (
							<Grid item xs={12}>
								<span style={{ fontFamily: 'Poppins', fontWeight: '600', fontSize: '1.1em', color: 'black' }}>
									{['term', 'profile', 'group'].includes(type)
										? profileData?.engagement?.followersCount
										: profileData?.follower_count}
								</span>{' '}
								<span style={{ fontFamily: 'Poppins', fontWeight: '500', color: '#9a9a9a' }}>seguidores</span>
							</Grid>
						)}
						{(['term', 'profile', 'group'].includes(type) ? !!profileData?.engagement?.likesCount : !!profileData?.like_count) && (
							<Grid item xs={12}>
								<span style={{ fontFamily: 'Poppins', fontWeight: '600', fontSize: '1.1em', color: 'black' }}>
									{['term', 'profile', 'group'].includes(type) ? profileData?.engagement?.likesCount : profileData?.like_count}
								</span>{' '}
								<span style={{ fontFamily: 'Poppins', fontWeight: '500', color: '#9a9a9a' }}>curtidas</span>
							</Grid>
						)}
						{thisHighlightedBio ? (
							<div dangerouslySetInnerHTML={{ __html: thisHighlightedBio }} />
						) : ['term', 'profile', 'group'].includes(type) ? (
							profileData?.description
						) : (
							profileData?.bio
						)}
					</Grid>
				)}

				{source === 'instagram' && (
					<Grid container style={{ fontSize: '14px', padding: '1em' }}>
						<Grid container item xs={12}>
							<Grid item md={9} xs={12}>
								<span style={{ fontFamily: 'Poppins', fontWeight: '600', fontSize: '1.3em', color: 'black' }}>
									{profileData?.username ? profileData?.username : profileData?.name}
								</span>
								<span style={{ fontFamily: 'Poppins', fontWeight: '500', color: '#9a9a9a' }}>
									{/*FIXME: onde está no novo mapeamento?*/}
									{profileData?.is_business_account && ' (business account)'}
								</span>
							</Grid>
						</Grid>
						{(['term', 'profile', 'group'].includes(type)
							? !!profileData?.engagement?.followersCount
							: !!profileData?.follower_count) && (
							<Grid item xs={12}>
								<span style={{ fontFamily: 'Poppins', fontWeight: '600', fontSize: '1.1em', color: 'black' }}>
									{['term', 'profile', 'group'].includes(type)
										? profileData?.engagement?.followersCount
										: profileData?.follower_count}
								</span>{' '}
								<span style={{ fontFamily: 'Poppins', fontWeight: '500', color: '#9a9a9a' }}>seguidores</span>
							</Grid>
						)}
						{(['term', 'profile', 'group'].includes(type)
							? !!profileData?.engagement?.followingCount
							: !!profileData?.following_count) && (
							<Grid item xs={12}>
								<span style={{ fontFamily: 'Poppins', fontWeight: '600', fontSize: '1.1em', color: 'black' }}>
									{['term', 'profile', 'group'].includes(type)
										? profileData?.engagement?.followingCount
										: profileData?.following_count}
								</span>{' '}
								<span style={{ fontFamily: 'Poppins', fontWeight: '500', color: '#9a9a9a' }}>seguindo</span>
							</Grid>
						)}
						{(['term', 'profile', 'group'].includes(type) ? !!profileData?.description : !!profileData?.biography) && (
							<Grid item xs={12} style={{ fontFamily: 'Poppins', fontWeight: '500', marginTop: '1em', color: '#9a9a9a' }}>
								{thisHighlightedBio ? (
									<div dangerouslySetInnerHTML={{ __html: thisHighlightedBio }} />
								) : ['term', 'profile', 'group'].includes(type) ? (
									profileData?.description
								) : (
									profileData?.bio || profileData?.biography
								)}
							</Grid>
						)}
						{profileData?.url &&
							profileData?.name && ( //FIXME: onde está no novo mapeamento?
								<Grid item xs={12} style={{ fontFamily: 'Poppins', fontWeight: '500', color: '#9a9a9a' }}>
									{profileData?.url}
								</Grid>
							)}
					</Grid>
				)}

				{source === 'tiktok' && (
					<Grid container style={{ fontSize: '14px', padding: '1em' }}>
						<Grid container item xs={12}>
							<Grid item md={9} xs={12}>
								<span style={{ fontFamily: 'Poppins', fontWeight: '600', fontSize: '1.3em', color: 'black' }}>
									{profileData?.username ? profileData?.username : profileData?.name}
								</span>
								{/* <span style={{ fontFamily: 'Poppins', fontWeight: '500', color: '#9a9a9a' }}>
									{profileData?.is_business_account && ' (business account)'}
								</span> */}
							</Grid>
						</Grid>
						{!!profileData?.engagement?.followersCount && (
							<Grid item xs={12}>
								<span style={{ fontFamily: 'Poppins', fontWeight: '600', fontSize: '1.1em', color: 'black' }}>
									{profileData?.engagement?.followersCount}
								</span>{' '}
								<span style={{ fontFamily: 'Poppins', fontWeight: '500', color: '#9a9a9a' }}>seguidores</span>
							</Grid>
						)}
						{!!profileData?.engagement?.followingCount && (
							<Grid item xs={12}>
								<span style={{ fontFamily: 'Poppins', fontWeight: '600', fontSize: '1.1em', color: 'black' }}>
									{profileData?.engagement?.followingCount}
								</span>{' '}
								<span style={{ fontFamily: 'Poppins', fontWeight: '500', color: '#9a9a9a' }}>seguindo</span>
							</Grid>
						)}
						<Grid item xs={12} style={{ fontFamily: 'Poppins', fontWeight: '500', marginTop: '1em', color: '#9a9a9a' }}>
							{thisHighlightedBio ? (
								<div dangerouslySetInnerHTML={{ __html: thisHighlightedBio }} />
							) : ['term', 'profile', 'group'].includes(type) ? (
								profileData?.description
							) : (
								profileData?.bio
							)}
						</Grid>
					</Grid>
				)}

				{source === 'youtube' && (
					<Grid container style={{ fontSize: '14px', padding: '1em' }}>
						<Grid container item xs={12}>
							<Grid item md={9} xs={12}>
								<span style={{ fontFamily: 'Poppins', fontWeight: '600', fontSize: '1.3em', color: 'black' }}>
									{profileData?.username ? profileData?.username : profileData?.name}
								</span>
								{/* <span style={{ fontFamily: 'Poppins', fontWeight: '500', color: '#9a9a9a' }}>
									{profileData?.is_business_account && ' (business account)'}
								</span> */}
							</Grid>
						</Grid>
						{!!profileData?.engagement?.followersCount && (
							<Grid item xs={12}>
								<span style={{ fontFamily: 'Poppins', fontWeight: '600', fontSize: '1.1em', color: 'black' }}>
									{profileData?.engagement?.followersCount}
								</span>{' '}
								<span style={{ fontFamily: 'Poppins', fontWeight: '500', color: '#9a9a9a' }}>seguidores</span>
							</Grid>
						)}
						{!!profileData?.engagement?.viewCount && (
							<Grid item xs={12}>
								<span style={{ fontFamily: 'Poppins', fontWeight: '600', fontSize: '1.1em', color: 'black' }}>
									{profileData?.engagement?.viewCount}
								</span>{' '}
								<span style={{ fontFamily: 'Poppins', fontWeight: '500', color: '#9a9a9a' }}>visualizações</span>
							</Grid>
						)}
						<Grid item xs={12} style={{ fontFamily: 'Poppins', fontWeight: '500', marginTop: '1em', color: '#9a9a9a' }}>
							{thisHighlightedBio ? (
								<div dangerouslySetInnerHTML={{ __html: thisHighlightedBio }} />
							) : ['term', 'profile', 'group'].includes(type) ? (
								profileData?.description
							) : (
								profileData?.bio
							)}
						</Grid>
					</Grid>
				)}

				{source === 'news' && (
					<Grid container style={{ fontSize: '14px', padding: '1em' }}>
						<Grid container item xs={12}>
							<Grid item md={9} xs={12}>
								<span style={{ fontFamily: 'Poppins', fontWeight: '600', fontSize: '1.3em', color: 'black' }}>
									{profileData?.name}
								</span>
							</Grid>
						</Grid>
						{profileData?.username && (
							<Grid item xs={12}>
								<span style={{ fontFamily: 'Poppins', fontWeight: '600', fontSize: '1.1em', color: 'black' }}>
									{profileData?.username}
								</span>{' '}
							</Grid>
						)}
					</Grid>
				)}

				<div className='divider-thin-default' />
			</Collapse>
			{!checkIfAllChildrenAreEmpty(profileData) && type !== 'ACCOUNT_MANAGEMENT' && (
				<Button onClick={handleToggle} style={{ width: '100%', borderRadius: '0px 0px 25px 25px', height: '2em' }}>
					{openProfileInfo ? <ChevronUpIcon size={20} style={{ color: color }} /> : <ChevronDownIcon size={20} style={{ color: color }} />}
				</Button>
			)}
		</div>
	)
}

export default memo(OccurrenceProfileDropdown)
