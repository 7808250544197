import moment from 'moment-timezone'

export { MAIN_REDUCER_INITIAL_CONFIG, GLOBAL_FILTERS_DEFAULT, ADVANCED_GLOBAL_FILTERS_DEFAULT }

const ADVANCED_GLOBAL_FILTERS_DEFAULT = {
	authorIsVerified: 'all',
	contentType: 'all', //post... antigo isOriginal
	contentAttachmentsType: { text: true, image: true, video: true, mediaQuery: 'text or images or video' },
	authorGender: {
		male: false,
		female: false,
		organization: false
	},
	authorLocation: {
		country: '',
		region: '',
		state: '',
		city: ''
	},
	authorNameSearch: [],
	authorDescriptionSearch: '',
	authorDomainSearch: '',
	contentUrlSearch: '',
	authorFollowers: {
		higher: '',
		lower: ''
	},
	authorLikes: {
		higher: '',
		lower: ''
	},
	insertPostType: {
		system: true,
		manual: true
	}
}

const GLOBAL_FILTERS_DEFAULT = {
	sources: ['twitter', 'bluesky', 'facebook', 'instagram', 'tiktok', 'youtube', 'news'],
	fromDate: moment(new Date(), 'YYYY-MM-DD').subtract(6, 'days').format('YYYY-MM-DD'),
	toDate: moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD'),
	fromHour: '00:00:00',
	toHour: '23:59:59',
	contentTextSearch: '',
	classification: {
		hasAutomaticClassification: true,
		hasManualClassification: true,
		positive: true,
		negative: true,
		neutral: true,
		irrelevant: false //só tem em manual
	},
	manifestations: {
		higher: '',
		lower: ''
	},
	groupTags: [], //Esse é o tags dos grupos
	tagsGlobalFilters: [],
	isStopWordsActive: true,
	advancedFilters: ADVANCED_GLOBAL_FILTERS_DEFAULT,
	aurasightFilters: {
		hasMediaInference: false,
		hasMediaDownload: false
	}
}

const MAIN_REDUCER_INITIAL_CONFIG = {
	user: null,
	card: {
		comparison: {
			cards: [],
			type: null,
			status: { general: null, twitter: null, bluesky: null, facebook: null, instagram: null, tiktok: null, youtube: null, news: null }
		},
		allCardsCanOpen: [],
		cardType: null
	},
	global: {
		redirectUrl: null,
		updateAllComponentsAtThisTime: null,
		folder: {
			currentFolders: []
		},
		filters: GLOBAL_FILTERS_DEFAULT
	},
	monitoring: {
		filters: {
			tab: 'term',
			search: '',
			folderId: null,
			view: true
		}
	}
}
