import React, { useState, memo } from 'react'
import { Grid, Checkbox, TextField, Tooltip, IconButton, styled, Button, Divider, Collapse } from '@mui/material'
import { makeStyles } from '@mui/styles'
import {
	TwitterIcon,
	BlueskyIcon,
	FacebookIcon,
	InstagramIcon,
	TiktokIcon,
	YoutubeIcon,
	RadioWavesIcon,
	InfoIcon,
	DocumentIcon
} from 'utils/SystemIcons'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import { verifyServerResponseCanShowToast } from 'utils/generalFunctions'
import moment from 'moment-timezone'
import api from 'service/service'

const SourceNotIncluded = ({ name }) => (
	<Tooltip title={`Esse cartão não inclui esta fonte (${name})`} placement='bottom' enterDelay={100} enterNextDelay={100}>
		<IconButton style={{ padding: '5px', marginLeft: '0.3em' }}>
			<InfoIcon size={20} style={{ color: '#1d8cf8', cursor: 'pointer' }} />
		</IconButton>
	</Tooltip>
)

const blue = '#1d8af8'
const grey = '#828282'
const blueTransparent = 'rgba(29, 138, 248, 10%)'

const CssTextField = styled(TextField)({
	'& .MuiInputBase-root': {
		color: blue,
		fontFamily: 'Poppins',
		'& input': {
			textAlign: 'left'
		}
	},
	'& .MuiOutlinedInput-root': {
		'& fieldset': {
			borderColor: grey
		},
		'&:hover fieldset': {
			borderColor: grey
		},
		'&.Mui-focused fieldset': {
			borderColor: blue
		}
	}
})

const useStyles = makeStyles(() => ({
	button: {
		fontFamily: 'Poppins',
		borderRadius: '100px',
		color: blue,
		minWidth: '300px',
		background: 'transparent',
		border: `1px solid ${blueTransparent}`,
		'&:hover': {
			backgroundColor: blueTransparent,
			boxShadow: '0px 0px -10px -17px rgba(0,0,0,255)'
		},
		'&:disabled': {
			backgroundColor: 'transparent'
		}
	}
}))

const getCustomIntervalLabel = (fromDate, toDate) => {
	let label = null

	if (moment(fromDate).format('DD/MM/YYYY') === moment(toDate).format('DD/MM/YYYY')) {
		label = `${moment(fromDate).format('DD/MM/YYYY')}`
	} else {
		label = `${moment(fromDate).format('DD/MM/YYYY')} - ${moment(toDate).format('DD/MM/YYYY')}`
	}
	return label
}

const getInitialExportName = (cardName, fromDate, toDate) => {
	return `Relatório - ${cardName} - ${getCustomIntervalLabel(fromDate, toDate)}`
}

const ExportXlsx = ({ cardId, cardStatus, filters, wordCloudName, toggleXlsx, cardName, selectedPostsIds }) => {
	const [sources, setSources] = useState(filters?.sources)
	const [exportName, setExportName] = useState(getInitialExportName(cardName, filters?.fromDate, filters?.toDate))
	const [submitting, setSubmitting] = useState(false)
	const [errorText, setErrorText] = useState(false)
	const [errorSources, setErrorSources] = useState(false)

	const classes = useStyles()

	const newXlsxPostsExport = () => {
		const type = 'xlsx'
		if (!checkErrors()) {
			api.post('/occurrences/export-posts', { cardId, filters, sources, exportName, wordCloudName, selectedPostsIds, type })
				.then(() => {
					setSubmitting(true)
					sendMessageToast('A exportação começou, vamos enviar uma notifcação quando o download estiver disponível.', 'INFO')
				})
				.catch((error) => {
					if (verifyServerResponseCanShowToast(error)) {
						sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
					}
				})
				.finally(() => {
					setSubmitting(false)
					toggleXlsx()
				})
		}
	}

	const checkErrors = () => {
		let hasError = false
		if (sources.length === 0) {
			setErrorSources(true)
			hasError = true
		} else {
			setErrorSources(false)
		}
		if (exportName === '') {
			setErrorText(true)
			hasError = true
		} else {
			setErrorText(false)
		}
		return hasError
	}

	const handleChangeCheckAccount = (source, active) => {
		if (active && !submitting) {
			if (sources.includes(source)) {
				setSources(sources.filter((item) => item !== source))
			} else {
				setSources([...sources, source])
			}
		}
	}

	return (
		<div style={{ margin: '25px' }}>
			<Grid container justifyContent='center' alignItems='center'>
				<Grid
					item
					xs={12}
					container
					justifyContent='center'
					alignItems='center'
					style={{
						fontFamily: 'Poppins',
						textAlign: 'center',
						letterSpacing: '4px',
						fontSize: '16px',
						fontWeight: '500',
						marginBottom: '30px'
					}}
				>
					{selectedPostsIds.length <= 0
						? 'SELECIONE AS FONTES QUE DESEJA EXPORTAR'
						: 'EXPORTAR ' + selectedPostsIds.length + (selectedPostsIds.length === 1 ? ' POSTAGEM' : ' POSTAGENS')}
				</Grid>
				{selectedPostsIds.length <= 0 && (
					<Grid item xs={12} container justifyContent='center' alignItems='center'>
						<Grid item xs={3} container justifyContent='center' alignItems='center'>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									cursor: cardStatus.twitter === 'S' ? 'pointer' : '',
									userSelect: 'none'
								}}
								onClick={() => handleChangeCheckAccount('twitter', cardStatus.twitter === 'S')}
							>
								<TwitterIcon size={27} style={{ color: '#1d8cf8', marginRight: '0.3em' }} />
								Twitter
								{cardStatus.twitter === 'S' && (
									<Checkbox color='primary' checked={sources.includes('twitter')} disabled={cardStatus.twitter !== 'S'} />
								)}
								{cardStatus.twitter !== 'S' && <SourceNotIncluded name='Twitter' />}
							</div>
						</Grid>
						<Grid item xs={3} container justifyContent='center' alignItems='center'>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									cursor: cardStatus.bluesky === 'S' ? 'pointer' : '',
									userSelect: 'none'
								}}
								onClick={() => handleChangeCheckAccount('bluesky', cardStatus.bluesky === 'S')}
							>
								<BlueskyIcon size={27} style={{ color: '#1d8cf8', marginRight: '0.3em' }} />
								Bluesky
								{cardStatus.bluesky === 'S' && (
									<Checkbox color='primary' checked={sources.includes('bluesky')} disabled={cardStatus.bluesky !== 'S'} />
								)}
								{cardStatus.bluesky !== 'S' && <SourceNotIncluded name='Bluesky' />}
							</div>
						</Grid>
						<Grid item xs={3} container justifyContent='center' alignItems='center'>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									cursor: cardStatus.facebook === 'S' ? 'pointer' : '',
									userSelect: 'none'
								}}
								onClick={() => handleChangeCheckAccount('facebook', cardStatus.facebook === 'S')}
							>
								<FacebookIcon size={27} style={{ color: '#1d8cf8', marginRight: '0.3em' }} />
								Facebook
								{cardStatus.facebook === 'S' && (
									<Checkbox color='primary' checked={sources.includes('facebook')} disabled={cardStatus.facebook !== 'S'} />
								)}
								{cardStatus.facebook !== 'S' && <SourceNotIncluded name='Facebook' />}
							</div>
						</Grid>
						<Grid item xs={3} container justifyContent='center' alignItems='center'>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									cursor: cardStatus.instagram === 'S' ? 'pointer' : '',
									userSelect: 'none'
								}}
								onClick={() => handleChangeCheckAccount('instagram', cardStatus.instagram === 'S')}
							>
								<InstagramIcon size={27} style={{ color: '#1d8cf8', marginRight: '0.3em' }} />
								Instagram
								{cardStatus.instagram === 'S' && (
									<Checkbox color='primary' checked={sources.includes('instagram')} disabled={cardStatus.instagram !== 'S'} />
								)}
								{cardStatus.instagram !== 'S' && <SourceNotIncluded name='Instagram' />}
							</div>
						</Grid>
						<Grid item xs={3} container justifyContent='center' alignItems='center'>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									cursor: cardStatus.tiktok === 'S' ? 'pointer' : '',
									userSelect: 'none'
								}}
								onClick={() => handleChangeCheckAccount('tiktok', cardStatus.tiktok === 'S')}
							>
								<TiktokIcon size={27} style={{ color: '#1d8cf8', marginRight: '0.3em' }} />
								Tiktok
								{cardStatus.tiktok === 'S' && (
									<Checkbox color='primary' checked={sources.includes('tiktok')} disabled={cardStatus.tiktok !== 'S'} />
								)}
								{cardStatus.tiktok !== 'S' && <SourceNotIncluded name='Tiktok' />}
							</div>
						</Grid>
						<Grid item xs={3} container justifyContent='center' alignItems='center'>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									cursor: cardStatus.youtube === 'S' ? 'pointer' : '',
									userSelect: 'none'
								}}
								onClick={() => handleChangeCheckAccount('youtube', cardStatus.youtube === 'S')}
							>
								<YoutubeIcon size={27} style={{ color: '#1d8cf8', marginRight: '0.3em' }} />
								Youtube
								{cardStatus.youtube === 'S' && (
									<Checkbox color='primary' checked={sources.includes('youtube')} disabled={cardStatus.youtube !== 'S'} />
								)}
								{cardStatus.youtube !== 'S' && <SourceNotIncluded name='Youtube' />}
							</div>
						</Grid>
						<Grid item xs={3} container justifyContent='center' alignItems='center'>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									cursor: cardStatus.news === 'S' ? 'pointer' : '',
									userSelect: 'none'
								}}
								onClick={() => handleChangeCheckAccount('news', cardStatus.news === 'S')}
							>
								<RadioWavesIcon size={27} style={{ color: '#1d8cf8', marginRight: '0.3em' }} />
								Notícias
								{cardStatus.news === 'S' && (
									<Checkbox color='primary' checked={sources.includes('news')} disabled={cardStatus.news !== 'S'} />
								)}
								{cardStatus.news !== 'S' && <SourceNotIncluded name='Notícias' />}
							</div>
						</Grid>
						<Grid
							item
							xs={12}
							style={{
								fontFamily: 'Roboto',
								textAlign: 'start',
								fontSize: '12px',
								marginTop: '-15px',
								color: '#d32f2f',
								marginLeft: '36px',
								marginRight: '36px'
							}}
						>
							<Collapse in={errorSources === true}>
								<Divider
									style={{ backgroundColor: '#d32f2f', height: '1px', marginTop: '2em', width: '100%', marginBottom: '2px' }}
								/>
								Selecione pelo menos uma fonte
							</Collapse>
						</Grid>
					</Grid>
				)}
				<Grid item xs={12} style={{ paddingLeft: '35px', paddingRight: '35px', height: '48px', marginTop: '25px', marginBottom: '30px' }}>
					<CssTextField
						value={exportName}
						error={errorText === true}
						disabled={submitting}
						helperText={errorText ? 'Insira um nome para o arquivo' : ''}
						variant='standard'
						onChange={(e) => setExportName(e.target.value)}
						fullWidth
						label={
							<div style={{ display: 'flex', marginTop: '-6px', whiteSpace: 'nowrap' }}>
								<DocumentIcon
									size={17}
									style={{
										position: 'relative',
										transform: 'translate(0px, 5px)',
										color: errorText === true ? '#d32f2f' : '#3d8cf8'
									}}
								/>
								<div style={{ marginLeft: '5px', marginTop: '2px', fontFamily: 'Poppins', fontSize: '15px' }}>Nome da exportação</div>
							</div>
						}
					/>
				</Grid>
				<Grid item xs={12} container justifyContent='center' alignItems='center'>
					<Button onClick={newXlsxPostsExport} disabled={submitting} className={classes.button}>
						Exportar
					</Button>
				</Grid>
			</Grid>
		</div>
	)
}

export default memo(ExportXlsx)
