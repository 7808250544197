import React from 'react'
import { Responsive, WidthProvider } from 'react-grid-layout'

const ResponsiveGridLayout = WidthProvider(Responsive)

import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import './DragResizeDashboard.css'

const DragResizeDashboard = ({ children, layouts, onLayoutChange, editMode, droppingItem, handleAddWidget, isDroppable, isResizable = true }) => {
	const onDrop = (layout, layoutItem) => {
		const newItem = {
			...layoutItem,
			i: droppingItem.keyName,
			isDraggable: undefined
		}

		const currentWidgets = layout.filter((widgets) => widgets.i !== 'dropItem').map((widgets) => ({ ...widgets, isDraggable: undefined }))

		const newLayout = {
			md: [...currentWidgets, newItem],
			sm: [...currentWidgets, newItem]
		}

		handleAddWidget(newItem.i, newLayout)
	}

	return (
		<ResponsiveGridLayout
			className='layout'
			layouts={layouts}
			rowHeight={30}
			compactType='vertical'
			resizeHandles={['sw', 'nw', 'se', 'ne']}
			breakpoints={{ lg: 10000, md: 1000, sm: 800, xs: 500, xxs: 0 }}
			cols={{ lg: 12, md: 12, sm: 6, xs: 6, xxs: 2 }}
			onLayoutChange={onLayoutChange}
			isDraggable={editMode}
			isResizable={editMode && isResizable}
			isDroppable={isDroppable}
			onDrop={onDrop}
			droppingItem={droppingItem}
		>
			{children}
		</ResponsiveGridLayout>
	)
}

export default DragResizeDashboard
