import React from 'react'
import Grid from '@mui/material/Grid'

const InsertionsNumbers = ({ insertions = 0, time = 0, value = '' }) => {
	return (
		<Grid container spacing={3} style={{ marginTop: '0.1em' }}>
			<Grid item xs={6}>
				<div className='big-number' style={{ fontSize: '2.3em' }}>
					{insertions}
				</div>
				<div className='big-number-label'>Inserções</div>
			</Grid>
			<Grid item xs={6}>
				<div className='big-number' style={{ fontSize: '2.3em' }}>
					{time}s
				</div>
				<div className='big-number-label'>Tempo de exposição</div>
			</Grid>
			<Grid item xs={12} style={{ marginTop: '2.5em' }}>
				<div className='big-number' style={{ fontSize: '2.3em' }}>
					{value}
				</div>
				<div className='big-number-label'>Midia ajustada</div>
			</Grid>
		</Grid>
	)
}

export default InsertionsNumbers
