import React, { useState, useEffect, memo } from 'react'
import Plot from 'react-plotly.js'
import Graph from 'react-graph-vis'
import GraphNetworkSkeletonLoad from 'components/SkeletonLoads/GraphNetworkSkeletonLoad/GraphNetworkSkeletonLoad'
import { getRankDictionary, formatGraphIndividual, formatGraphComparative } from './utils/networkFormater'
import { Card, Grid } from '@mui/material'
import { useUpdateData } from 'hooks/ReactQueryHooks'
import NetworkMetricsIndividual from '../NetworkMetrics/Individual/NetworkMetrics'
import defaultImage from 'assets/img/twitterEmpty.jpg'

let rankDictionary = {}

const defaultNodeData = {
	title: '',
	score: '',
	imageUrl: '',
	networkItem: {}
}

const NetworkGraphRealTime = ({
	cards,
	fullScreenHandle,
	fullScreenState = false,
	sourceType = 'account',
	onPartialLoading,
	type = 'graphChart',
	isFetchingData,
	filters,
	cardType
}) => {
	const [loading, setLoading] = useState(true)
	const [graphChart, setGraphChart] = useState({ nodes: [], edges: [] })
	const [network, setNetwork] = useState(null)
	const [windowSize, setWindowSize] = useState(window.innerHeight - 350)
	const [nodeMetrics, setNodeMetrics] = useState(defaultNodeData)
	const [sankeyChart, setSankeyChart] = useState([])
	const [cardIds, setCardIds] = useState(cards?.map((card) => card.id))
	const [thisFilters, setThisFilters] = useState(filters)
	const [graphKey, setGraphKey] = useState(Math.random())

	const getBody = () => ({
		configGlobalFilters: {
			cardType: cardType.toUpperCase(),
			cardIds
		},
		globalFilters: thisFilters,
		componentProperties: {
			//Term
			networkGraphType: sourceType,
			orderBy: 'score',
			//Group
			isSummary: false,
			size: 50
		}
	})

	let { data, isFetching, isFetched } = useUpdateData({
		url: '/network/get-network-real-time',
		updateItemName: 'NetworkGraph',
		refetchInterval: 600000,
		freshDataTime: 600000,
		isEnabled: isFetchingData,
		queryName: 'relevanceChange_useUpdateData',
		method: 'post',
		body: getBody()
	})

	useEffect(() => {
		setCardIds(cards?.map((card) => card.id))
	}, [cards])

	useEffect(() => {
		setThisFilters(filters)
	}, [filters])

	useEffect(() => {
		setNodeMetrics(defaultNodeData)
		setCardIds(cards?.map((card) => card.id))
		if (isFetched) {
			setGraphKey(Math.random())
			load()
			network?.selectNodes([])
			setLoading(false)
		}
	}, [isFetched, thisFilters.fromDate, thisFilters.toDate, thisFilters.sources, data])

	useEffect(() => {
		network?.selectNodes([])
	}, [network])

	useEffect(() => {
		if (!loading) {
			onPartialLoading(isFetching)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFetching])

	useEffect(() => {
		if (isFetched) {
			setLoading(true)
			setWindowSize(window.innerHeight - 350)
			setTimeout(() => {
				setLoading(false)
			}, 100)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fullScreenState])

	const load = () => {
		setLoading(true)
		let res
		if (data?.length === 1) {
			res = formatGraphIndividual(data)
		} else if (data?.length > 1) {
			res = formatGraphComparative(
				data,
				cards.map((card) => card.id)
			)
		}
		setGraphChart(res?.graphData)
		setSankeyChart(res?.sankeyData)
		rankDictionary = getRankDictionary()
	}

	const checkEmptyImage = (url) => {
		if (url === '' || url === null) {
			return defaultImage
		} else {
			return url
		}
	}

	const handleGraphClick = {
		select: function (event) {
			let { nodes } = event
			if (graphChart.nodes[nodes] && nodes > data.length - 1) {
				const sel = graphChart.nodes[nodes]
				populateMetrics(sel)
			} else {
				network?.selectNodes([])
			}
		}
	}

	const handleSankeyClick = (event) => {
		if (event.points[0]?.label !== '') {
			const nodeIndex = event.points[0]?.customdata
			let { nodes } = event
			let index = []

			if (nodeIndex !== undefined) {
				index.push(nodeIndex)
			} else {
				index = nodes
			}

			if (graphChart.nodes[index] && index > data.length - 1) {
				const sel = graphChart.nodes[index]
				populateMetrics(sel)
			}
		}
	}

	const populateMetrics = (node) => {
		let title = node.label
		let score = rankDictionary[node.id]
		let imageUrl = checkEmptyImage(node.image)
		let networkItem = node.networkItem

		setNodeMetrics({
			title,
			score,
			imageUrl,
			networkItem
		})
	}

	const graphChartOptions = {
		layout: {
			hierarchical: false
		},
		edges: {
			color: 'rgba(82, 95, 127,0.5)',
			smooth: false,
			arrows: {
				to: false
			}
		},
		physics: {
			stabilization: {
				enabled: true,
				iterations: 5,
				updateInterval: 2,
				onlyDynamicEdges: false,
				fit: true
			},
			barnesHut: {
				springConstant: 0.03,
				avoidOverlap: 0.2
			}
		}
	}

	let layoutSankey = {
		showlegend: false,
		title: '',
		paper_bgcolor: 'rgba(255,255,255,255)',
		plot_bgcolor: 'rgba(0,0,0,0)',
		plot_color: 'rgba(0,0,0,0)',
		height: fullScreenState ? window.innerHeight : windowSize,
		width: windowSize,
		hovermode: false,
		font: {
			family: 'Poppins, sans-serif',
			size: 10,
			color: '#525f7f'
		},
		xaxis1: {
			gridcolor: 'rgba(0,0,0,0)',
			zerolinecolor: 'rgba(0,0,0,0)'
		},
		yaxis1: {
			gridcolor: 'rgba(0,0,0,0)',
			zerolinecolor: 'rgba(0,0,0,0)'
		}
	}

	return (
		<Card elevation={0} style={{ height: '100%' }}>
			{loading && <GraphNetworkSkeletonLoad />}
			{!loading && (
				<Grid container spacing={0}>
					{type === 'graphChart' && (graphChart?.nodes?.length !== 0 || graphChart?.edges?.length !== 0) && (
						<Grid item xs={9}>
							<Graph
								key={graphKey}
								style={{
									height: '977px',
									width: '100%',
									outline: 'none'
								}}
								graph={graphChart}
								options={graphChartOptions}
								events={handleGraphClick}
								getNetwork={(network) => {
									setNetwork(network)
								}}
							/>
						</Grid>
					)}
					{type === 'sankeyChart' && (
						<Grid item xs={9}>
							<div
								style={{
									textAlign: 'center',
									float: 'center'
								}}
							>
								<Plot
									data={sankeyChart}
									layout={layoutSankey}
									useResizeHandler={true}
									config={{
										showTips: false,
										displaylogo: false,
										modeBarButtonsToRemove: ['lasso', 'resetSankeyGroup', 'select']
									}}
									onClick={handleSankeyClick}
								/>
							</div>
						</Grid>
					)}
					<NetworkMetricsIndividual
						cards={cards}
						fullScreenHandle={fullScreenHandle}
						nodeMetrics={nodeMetrics}
						height={windowSize - 60}
						fullScreenState={fullScreenState}
					/>
				</Grid>
			)}
		</Card>
	)
}

export default memo(NetworkGraphRealTime)
