import React, { useState, useRef, useEffect } from 'react'
import { Button, Collapse, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import autoAnimate from '@formkit/auto-animate'
import CardQuery from '../CardQuery/CardQuery'
import { verifyCardQueryError } from '../../../SocialNetworksNewInsightTerm/components/SourceCheckContent/utils'

const blue = 'rgba(29, 138, 248, 100%)'

const getNextId = (queryObject) => {
	let nextId = 0
	if (queryObject?.length > 0) {
		const orderedDescQuerys = queryObject.sort((a, b) => b.id - a.id)
		nextId = orderedDescQuerys[0].id + 1
	}

	return nextId
}

const useStyles = makeStyles({
	button: {
		fontFamily: 'Poppins',
		border: '1px solid rgba(29, 138, 248, 25%)',
		boxShadow: 'none',
		color: blue,
		borderRadius: '100px',
		background: 'transparent',
		'&:hover': {
			border: '1px solid rgba(29, 138, 248, 90%)',
			backgroundColor: 'rgba(29, 138, 248, 5%)',
			boxShadow: '0px 0px -10px -17px rgba(0,0,0,255)'
		}
	}
})

const sortObjectKeys = (obj) => {
	return Object.keys(obj)
		.sort()
		.reduce((result, key) => {
			result[key] = obj[key]
			return result
		}, {})
}

const CardsQuery = ({ onChangeQuerys, externalQuerys, maxNumberOfTerms, messageToast, cardName }) => {
	const [querysObject, setQuerysObject] = useState(externalQuerys || [])
	const [indexId, setIndexId] = useState(getNextId(externalQuerys))
	const parent = useRef(null)
	const classes = useStyles()

	useEffect(() => {
		parent.current && autoAnimate(parent.current)
	}, [parent])

	useEffect(() => {
		setQuerysObject(externalQuerys)
		setIndexId(getNextId(externalQuerys))
	}, [externalQuerys])

	useEffect(() => {
		onChangeQuerys(querysObject)
	}, [querysObject])

	const onDelete = (id) => {
		let object = querysObject.find((element) => element.id === id)
		let index = querysObject.indexOf(object)
		querysObject.splice(index, 1)
		onChangeQuerys(querysObject)
	}

	const addQuery = () => {
		const error = querysObject && querysObject.length !== 0 ? verifyCardQueryError(querysObject) : false
		if (!error) {
			if (querysObject.length < maxNumberOfTerms) {
				const newQuery = {
					id: indexId,
					query: { termsAnd: [], termsOr: [], termsNot: [] },
					title: ''
				}
				let newArray = querysObject
				newArray.unshift(newQuery)
				setQuerysObject(newArray)
				setIndexId(indexId + 1)
			} else {
				messageToast('Quantidade máxima de coletas atingida')
			}
		} else {
			messageToast('Salve todas suas coletas antes de criar uma nova')
		}
	}

	const onChangeQuery = (newQueryText, queryKey, id, newTitle) => {
		let object = querysObject.find((element) => element.id === id)
		if (newQueryText && queryKey) {
			object.query[queryKey] = newQueryText
		} else {
			object.title = newTitle
		}
		onChangeQuerys(querysObject)
	}

	const validateUniqueQuery = (id, newQuery) => {
		let error = null
		for (let i = 0; i < querysObject.length; i++) {
			const query = querysObject[i]
			if (query.id === id) {
				continue
			}
			const newItem = sortObjectKeys(newQuery)
			const queryItem = sortObjectKeys(query.query)
			if (JSON.stringify(newItem) === JSON.stringify(queryItem)) {
				messageToast('Coletas duplicadas não são permitidas')
				error = 'Coletas duplicadas não são permitidas'
				break
			}
		}
		return error
	}

	return (
		<>
			<Grid container>
				<Grid item xs={12}>
					<Button className={classes.button} style={{ marginTop: '5px', marginBottom: '5px' }} onClick={() => addQuery()}>
						+ Nova Coleta de dados
					</Button>
				</Grid>
			</Grid>
			<Collapse in={querysObject.length > 0} timeout='auto' collapsedSize={'0px'}>
				<div ref={parent} style={{ marginTop: '1em' }}>
					{querysObject?.map((queryObject) => (
						<Grid item xs={12} key={queryObject.id} style={{ margin: '1em 1em 0em 1em' }}>
							<CardQuery
								id={queryObject.id}
								queryObject={queryObject.query}
								title={queryObject.title}
								onChangeQuery={onChangeQuery}
								onDelete={onDelete}
								validateUniqueQuery={validateUniqueQuery}
								messageToast={messageToast}
								cardName={cardName}
							/>
						</Grid>
					))}
				</div>
			</Collapse>
		</>
	)
}

export default CardsQuery
