import React, { useState, useEffect } from 'react'
import { DialogTitle, DialogContent, Dialog, Grid, Button, Card } from '@mui/material'

const isFirefox = typeof InstallTrigger !== 'undefined'

const DialogConfirmation = ({ open, title, subTitle, content, handleClose, disabled, handleConfirm, buttonText, color, icon }) => {
	const [openDialog, setOpenDialog] = useState(open)

	useEffect(() => {
		setOpenDialog(open)
	}, [open])

	return (
		<>
			<Dialog
				open={openDialog}
				onClose={handleClose}
				PaperProps={{
					style: {
						borderRadius: '20px',
						backdropFilter: 'blur(10px)',
						backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
						border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
						boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
					}
				}}
				BackdropProps={{ style: { backdropFilter: 'blur(3px)', backgroundColor: 'rgba(220, 220, 220, 0.4)' } }}
				fullWidth={true}
				maxWidth='sm'
			>
				<Card style={{ margin: '20px', background: 'transparent' }} elevation={0}>
					<DialogTitle id='alert-dialog-title'>
						<div className='jc-space-between d-flex ai-center'>
							<div style={{ color }}>
								{icon && <span style={{ marginRight: '0.3em' }}>{icon || ''}</span>}
								{title || ''}
							</div>
						</div>
						{subTitle && <div style={{ marginTop: '1em' }}>{subTitle}?</div>}
					</DialogTitle>

					{content && <DialogContent style={{ padding: '1.5em', paddingTop: 0 }}>{content}</DialogContent>}

					<Grid item xs={12} style={{ marginTop: '30px' }} container justifyContent='space-between'>
						<Button color='inherit' disabled={disabled} onClick={handleClose} style={{ borderRadius: '25px' }}>
							CANCELAR
						</Button>
						<Button disabled={disabled} style={{ color, borderRadius: '25px' }} onClick={handleConfirm}>
							{buttonText || ''}
						</Button>
					</Grid>
				</Card>
			</Dialog>
		</>
	)
}

export default DialogConfirmation
