import React, { memo, useEffect, useState } from 'react'
import api from 'service/service'
import PhotoGallery from 'components/PhotoGallery/PhotoGallery'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import { verifyServerResponseCanShowToast } from 'utils/generalFunctions'
import { MenuItem, Select, styled, FormControl, Grid } from '@mui/material'
import OccurrencesCardSelector from 'components/Occurrences/components/FilterBar/components/OccurrencesCardSelector/OccurrencesCardSelector'
import { makeStyles } from '@mui/styles'

const DEFAULT_PHOTO_GALLERY = { data: [], pageSize: 30, page: 0, searchAfter: null, endResults: false }

const GALLERY_TYPES = {
	POST_IMAGES: 'POST_IMAGES',
	DOWNLOADED_IMAGES: 'DOWNLOADED_IMAGES',
	INFERENCED_IMAGES: 'INFERENCED_IMAGES'
}

const isFirefox = typeof InstallTrigger !== 'undefined'

const blue = 'rgba(29, 138, 248, 100%)'
const grey = 'rgba(0, 0, 0, 4%)'
const darkGrey = 'rgba(0, 0, 0, 25%)'

const useStyles = makeStyles(() => ({
	formControl: {
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderColor: blue
		},
		width: '100%'
	},
	select: {
		'&:hover': {
			borderColor: grey
		},
		'&:before': {
			borderColor: grey
		},
		'&:after': {
			borderColor: blue
		},
		'& .MuiSvgIcon-root': {
			color: darkGrey
		},
		width: '100%',
		color: '#1d8cf8 !important'
	}
}))

const StyledMenuItem = styled(MenuItem)(({ selected }) => ({
	...(selected && {
		color: '#1d8cf8 !important'
	})
}))

const PhotoGalleryInspectInterval = ({ cards, filters, type }) => {
	const [photos, setPhotos] = useState(DEFAULT_PHOTO_GALLERY)
	const [galleryType, setGalleryType] = useState(GALLERY_TYPES.POST_IMAGES)
	const [isLoading, setIsLoading] = useState(true)
	const [selectedCard, setSelectedCard] = useState(cards[0])
	const classes = useStyles()

	useEffect(() => {
		loadPhotos()
	}, [filters, cards, galleryType, selectedCard])

	const loadPhotos = async (nextLoad) => {
		try {
			setIsLoading(true)
			if (!nextLoad) {
				setPhotos(DEFAULT_PHOTO_GALLERY)
			}
			const body = {
				configGlobalFilters: {
					cardType: type.toUpperCase(),
					cardIds: [selectedCard.id]
				},
				globalFilters: { ...filters },
				componentProperties: { pageSize: photos.pageSize, searchAfter: photos.searchAfter, type, galleryType }
			}
			const res = await api.post('/occurrences/get-photo-gallery', body)
			setPhotos({
				data: res.data.photos,
				pageSize: photos.pageSize,
				page: nextLoad ? photos.page + 1 : 0,
				searchAfter: res.data.searchAfter,
				endResults: res.data.photos.length < photos.pageSize
			})
		} catch (error) {
			setPhotos({ ...photos, loading: false })
			if (verifyServerResponseCanShowToast(error)) {
				sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
			}
		} finally {
			setIsLoading(false)
		}
	}

	const handleLoad = (isLoadNext) => {
		loadPhotos(isLoadNext)
	}

	const handleChangeGalleryType = (galleryType) => {
		setGalleryType(galleryType.target.value)
	}

	return (
		<div
			style={{
				height: '800px',
				borderRadius: '10px',
				width: '96.5%',
				margin: 'auto',
				overflow: 'hidden',
				marginTop: '1em'
			}}
		>
			<Grid container>
				<Grid item xs={4}>
					<FormControl className={classes.formControl}>
						<Select
							fullWidth
							variant='standard'
							className={classes.select}
							labelId='gallery-photo-type-label'
							id='gallery-photo-type'
							value={galleryType}
							onChange={handleChangeGalleryType}
							disabled={isLoading}
							MenuProps={{
								anchorOrigin: {
									vertical: 'bottom',
									horizontal: 'left'
								},
								transformOrigin: {
									vertical: 'top',
									horizontal: 'left'
								},
								PaperProps: {
									style: {
										marginTop: '0px',
										marginLeft: '-1px',
										borderRadius: '0px 0px 25px 25px',
										backdropFilter: 'blur(10px)',
										backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
										borderBottom: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
										borderLeft: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
										borderRight: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
										boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
									}
								}
							}}
						>
							<StyledMenuItem value={GALLERY_TYPES.POST_IMAGES}>Fotos da postagem</StyledMenuItem>
							<StyledMenuItem value={GALLERY_TYPES.DOWNLOADED_IMAGES}>Fotos baixadas</StyledMenuItem>
							<StyledMenuItem value={GALLERY_TYPES.INFERENCED_IMAGES}>Fotos com inferência</StyledMenuItem>
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={4}></Grid>
				<Grid item xs={4}>
					<OccurrencesCardSelector
						cards={cards}
						onCardChange={(newCard) => {
							setSelectedCard(newCard)
							setPhotos(DEFAULT_PHOTO_GALLERY)
						}}
						selectedCard={selectedCard}
					/>
				</Grid>
			</Grid>
			<PhotoGallery photoGallery={photos} loading={isLoading} direction='column' load={handleLoad} />
		</div>
	)
}

export default memo(PhotoGalleryInspectInterval)
