import React from 'react'
import Skeleton from 'react-loading-skeleton'

const PhotoGallerySkeletonLoad = ({ height }) => (
	<div style={{ width: '100%', margin: 'auto', height: '100%' }}>
		<div style={{ marginBottom: '25px', marginLeft: '0px', marginRight: '0px' }}>
			<Skeleton height={height} width='100%' style={{ borderRadius: '20px', lineHeight: 'inherit' }} />
		</div>
	</div>
)

export default PhotoGallerySkeletonLoad
