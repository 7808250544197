import React, { useState, useEffect } from 'react'
import { FiPlus, FiMinus, FiCircle } from 'react-icons/fi'
import { AiOutlineRetweet } from 'react-icons/ai'
import { Grid, CardContent } from '@mui/material'
import Chart from 'react-apexcharts'
import BarChart from 'graphs/BarChart/BarChart'
import {
	TwitterIcon,
	InstagramIcon,
	FacebookIcon,
	TiktokIcon,
	YoutubeIcon,
	BlueskyIcon,
	NewsIcon,
	ShareIcon,
	LikeIcon,
	HeartIcon,
	WowIcon,
	SadIcon,
	AngryIcon,
	CommentIcon,
	HahaIcon,
	UsersIcon,
	RetweetIcon,
	PageIcon,
	EyeIcon
} from 'utils/SystemIcons'

const blue = 'rgb(29, 138, 248)'
const purple = 'rgb(172, 96, 247)'
const red = 'rgb(255, 43, 107)'
const lightgrey = 'rgb(220, 220, 220)'
const lightgreen = 'rgb(0, 222, 174)'
const yellow = 'rgb(255, 162, 0)'
const pink = 'rgb(237, 40, 146)'

const height = 220

const pieOptionsProp = {
	colors: [lightgrey],
	chart: {
		type: 'donut'
	},
	labels: ['Tente mais tarde'],
	dataLabels: {
		enabled: false
	},
	legend: {
		show: true,
		position: 'bottom'
	},
	fill: {
		opacity: 0.9
	},
	stroke: {
		colors: ['transparent']
	},
	tooltip: {
		enabled: false
	}
}

const emptyProp = {
	options: pieOptionsProp,
	series: [1]
}

const emptyAgeProp = {
	options: pieOptionsProp,
	series: [{ name: '', data: [] }]
}

const likesSentimentsPercentage = (sentiment, metrics) => {
	let likes
	let res
	let likesPos = metrics?.like_count_good
	let likesNeg = metrics?.like_count_bad
	let likesNeutral = metrics?.like_count_neutral
	let likesCountSample = metrics?.like_count

	if (sentiment === 'positive') {
		likes = likesPos
	} else if (sentiment === 'negative') {
		likes = likesNeg
	} else if (sentiment === 'neutral') {
		likes = likesNeutral
	}

	let defaultRes = (likes * 100) / likesCountSample
	res = defaultRes === Math.round(defaultRes) ? defaultRes : defaultRes.toFixed(2)
	return isNaN(res) ? 0 : res
}

const numberWithSpaces = (number) => {
	if (number) {
		return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
	}
}

const postsPerAuthor = (metrics) => {
	let res = metrics?.post_count / metrics?.author_count_original
	if (isNaN(res) || res === Infinity) {
		res = 0
	} else {
		res = res.toFixed(2)
	}

	return res
}

const sentimentsOptions = {
	colors: [blue, red, purple, lightgrey],
	chart: {
		type: 'donut'
	},
	labels: ['Positivos', 'Negativos', 'Neutros', 'Bots'],
	dataLabels: {
		enabled: true
	},
	plotOptions: {
		pie: {
			donut: {
				size: '65%'
			}
		}
	},
	legend: {
		show: true,
		position: 'bottom'
	},
	fill: {
		opacity: 0.9
	},
	stroke: {
		colors: ['transparent']
	},
	tooltip: {
		enabled: true
	}
}

const genderOptions = {
	colors: [blue, pink, lightgrey, lightgreen],
	chart: {
		type: 'donut'
	},
	labels: ['Masculino', 'Feminino', 'Sem class.', 'Organização'],
	dataLabels: {
		enabled: true
	},
	plotOptions: {
		pie: {
			donut: {
				size: '65%'
			}
		}
	},
	legend: {
		show: true,
		position: 'bottom'
	},
	fill: {
		opacity: 0.9
	},
	stroke: {
		colors: ['transparent']
	},
	tooltip: {
		enabled: true
	}
}

const twitterIcon = <TwitterIcon size={25} style={{ color: '#1d8cf8', transform: 'translate(0px, 2px)', marginRight: '5px' }} />
const blueskyIcon = <BlueskyIcon size={25} style={{ color: '#1d8cf8', transform: 'translate(0px, 2px)', marginRight: '5px' }} />
const facebookIcon = <FacebookIcon size={25} style={{ color: '#1d8cf8', transform: 'translate(0px, 2px)', marginRight: '5px' }} />
const instagramIcon = <InstagramIcon size={25} style={{ color: '#1d8cf8', transform: 'translate(0px, 2px)', marginRight: '5px' }} />
const tiktokIcon = <TiktokIcon size={25} style={{ color: '#1d8cf8', transform: 'translate(0px, 2px)', marginRight: '5px' }} />
const newsIcon = <NewsIcon size={25} style={{ color: '#1d8cf8', transform: 'translate(0px, 2px)', marginRight: '5px' }} />
const youtubeIcon = <YoutubeIcon size={25} style={{ color: '#1d8cf8', transform: 'translate(0px, 2px)', marginRight: '5px' }} />
const sharesIcon = <ShareIcon size={35} style={{ color: '#1d8cf8', transform: 'translate(0px, 2px)', marginRight: '5px' }} />
const usersIcon = <UsersIcon size={35} style={{ color: '#1d8cf8', transform: 'translate(0px, 2px)', marginRight: '5px' }} />
const likeIconMetric = <LikeIcon size={35} style={{ color: '#1d8cf8', transform: 'translate(0px, 2px)', marginRight: '5px' }} />
const heartIconMetric = <HeartIcon size={35} style={{ color: red, transform: 'translate(0px, 2px)', marginRight: '5px' }} />
const commentIconMetric = <CommentIcon size={35} style={{ color: lightgreen, transform: 'translate(0px, 2px)', marginRight: '5px' }} />
const retweetIconMetric = <RetweetIcon size={35} style={{ color: '#1d8cf8', transform: 'translate(0px, 2px)', marginRight: '5px' }} />
const pageIconMetric = <PageIcon size={35} style={{ color: '#1d8cf8', transform: 'translate(0px, 2px)', marginRight: '5px' }} />
const visIconMetric = <EyeIcon size={35} style={{ color: '#1d8cf8', transform: 'translate(0px, 2px)', marginRight: '5px' }} />

const PostsPerUserIcon = (SourceIcon, MetricIcon) => (
	<>
		{MetricIcon}
		<SourceIcon
			size={15}
			style={{
				position: 'relative',
				transform: 'translate(0px, 0px)',
				color: '#1d8cf8',
				marginRight: '5px'
			}}
		/>
	</>
)

const sourcesProperties = {
	twitter: {
		rawIcon: TwitterIcon,
		formattedIcon: twitterIcon,
		share: { name: 'Reposts', icon: retweetIconMetric },
		comment: { name: 'Respostas', icon: commentIconMetric },
		like: { name: 'Curtidas', icon: heartIconMetric },
		authorUnique: { name: 'Autores Únicos', icon: usersIcon },
		postsPerAuthor: { name: 'Posts / Autor', icon: usersIcon },
		followersCount: { name: 'Seguidores', icon: twitterIcon }
	},
	bluesky: {
		rawIcon: BlueskyIcon,
		formattedIcon: blueskyIcon,
		share: { name: 'Reposts', icon: retweetIconMetric },
		comment: { name: 'Respostas', icon: commentIconMetric },
		like: { name: 'Curtidas', icon: heartIconMetric },
		authorUnique: { name: 'Autores Únicos', icon: usersIcon },
		postsPerAuthor: { name: 'Posts / Autor', icon: usersIcon },
		followersCount: { name: 'Seguidores', icon: blueskyIcon }
	},
	facebook: {
		rawIcon: FacebookIcon,
		formattedIcon: facebookIcon,
		share: { name: 'Compartilhamentos', icon: sharesIcon },
		comment: { name: 'Comentários', icon: commentIconMetric },
		like: { name: 'Curtidas', icon: likeIconMetric },
		authorUnique: { name: 'Páginas Únicas', icon: pageIconMetric },
		postsPerAuthor: { name: 'Posts / Página', icon: pageIconMetric },
		authorLikesCount: { name: 'Curtidas da Página', icon: facebookIcon }
	},
	instagram: {
		rawIcon: InstagramIcon,
		formattedIcon: instagramIcon,
		share: { name: 'Compartilhamentos', icon: sharesIcon },
		comment: { name: 'Comentários', icon: commentIconMetric },
		like: { name: 'Curtidas', icon: heartIconMetric },
		authorUnique: { name: 'Autores Únicos', icon: usersIcon },
		postsPerAuthor: { name: 'Posts / Autor', icon: usersIcon },
		followersCount: { name: 'Seguidores', icon: instagramIcon }
	},
	tiktok: {
		rawIcon: TiktokIcon,
		formattedIcon: tiktokIcon,
		share: { name: 'Compartilhamentos', icon: sharesIcon },
		comment: { name: 'Comentários', icon: commentIconMetric },
		like: { name: 'Curtidas', icon: heartIconMetric },
		authorUnique: { name: 'Autores Únicos', icon: usersIcon },
		postsPerAuthor: { name: 'Posts / Autor', icon: usersIcon }
	},
	youtube: {
		rawIcon: YoutubeIcon,
		formattedIcon: youtubeIcon,
		share: { name: 'Compartilhamentos', icon: sharesIcon },
		comment: { name: 'Comentários', icon: commentIconMetric },
		like: { name: 'Curtidas', icon: likeIconMetric },
		authorUnique: { name: 'Autores Únicos', icon: usersIcon },
		postsPerAuthor: { name: 'Posts / Autor', icon: usersIcon }
	},
	news: {
		rawIcon: NewsIcon,
		formattedIcon: newsIcon,
		share: { name: 'Compartilhamentos', icon: sharesIcon },
		comment: { name: 'Comentários', icon: commentIconMetric },
		authorUnique: { name: 'Páginas Únicas', icon: pageIconMetric },
		postsPerAuthor: { name: 'Posts / Página', icon: pageIconMetric }
	}
}

const SummaryMetrics = ({ data, isReport, sources }) => {
	const [selectedMetrics, setSelectedMetrics] = useState(data?.selectedInterval?.metrics)
	const [sentimentsGraph, setSentimentsGraph] = useState(emptyProp)
	const [genderGraph, setGenderGraph] = useState(emptyProp)
	const [ageGraph, setAgeGraph] = useState(emptyAgeProp)
	const [reportImage] = useState(null)

	useEffect(() => {
		setSelectedMetrics(data?.selectedInterval?.metrics)
	}, [data])

	useEffect(() => {
		setSentimentsGraph({
			options: sentimentsOptions,
			series: [
				selectedMetrics?.post_count_good || 0,
				selectedMetrics?.post_count_bad || 0,
				selectedMetrics?.post_count_neutral || 0,
				selectedMetrics?.post_count_likely_bot || 0
			]
		})
		setGenderGraph({
			options: genderOptions,
			series: [
				selectedMetrics?.gender_count?.male || 0,
				selectedMetrics?.gender_count?.female || 0,
				selectedMetrics?.gender_count?.notClassified || 0,
				selectedMetrics?.gender_count?.organization || 0
			]
		})
		setAgeGraph({
			labels: selectedMetrics?.age?.labels,
			series: selectedMetrics?.age?.series
		})
	}, [selectedMetrics])

	const isAllZeroSentiments = sentimentsGraph.series.every((e) => e === 0)
	const isAllZeroGender = genderGraph.series.every((e) => e === 0)
	let hideAgeData = []
	ageGraph?.series?.forEach((it) => hideAgeData?.push(...it.data.filter((d) => d)))
	const isAllZeroAge = hideAgeData?.length < 1

	return (
		<>
			{reportImage === null && (
				<div id='summary-metrics'>
					<Grid container style={{ marginTop: '10px' }}>
						<Grid item xs={4}>
							<CardContent>
								<h4 style={{ textAlign: 'left', font: 'Poppins', fontWeight: 'lighter', fontSize: '30px' }}>
									{sourcesProperties[sources[0]].formattedIcon} Número total de Posts
								</h4>
							</CardContent>
							<CardContent>
								<h4
									style={{
										marginTop: '-0.3em',
										marginBottom: '-0.5em',
										color: '#1d8cf8',
										fontFamily: 'Montserrat',
										whiteSpace: 'nowrap',
										fontSize: '80px',
										display: 'flex'
									}}
								>
									<div>{numberWithSpaces(selectedMetrics?.post_count) || 0}</div>
									<div style={{ marginTop: '0.28em' }}>
										<hr id='divider' style={{ opacity: 0.5 }} />
									</div>
								</h4>
							</CardContent>
						</Grid>

						{![null].includes(sentimentsGraph.series.length > 1) && (
							<Grid item xs={3}>
								{!isAllZeroSentiments && (
									<Chart
										options={sentimentsGraph.options}
										series={sentimentsGraph.series}
										type='donut'
										width='100%'
										height={height}
									/>
								)}
							</Grid>
						)}

						{![null].includes(genderGraph.series.length > 1) && sources[0] !== 'news' && (
							<Grid item xs={3}>
								{!isAllZeroGender && (
									<Chart options={genderGraph.options} series={genderGraph.series} type='donut' width={'100%'} height={height} />
								)}
							</Grid>
						)}

						{![null].includes(ageGraph.series.length > 1) && (
							<Grid item xs={2}>
								{!isAllZeroAge && (
									<BarChart
										series={ageGraph.series}
										height={190}
										width='100%'
										colors={['#008FFB', '#00E396', '#FEB019', '#FF4560']}
										labels={ageGraph.labels}
										isHorizontal={false}
										isStacked={false}
									/>
								)}
							</Grid>
						)}
					</Grid>

					<Grid container spacing={2} style={{ marginTop: '10px' }}>
						{![null].includes(selectedMetrics?.author_count_original) && (
							<Grid item xs={3}>
								<CardContent>
									<Grid container spacing={3}>
										<Grid item xs={7}>
											<div>
												<p style={{ fontSize: '14px', textAlign: 'left' }}>
													{sourcesProperties[sources[0]].authorUnique.name}
												</p>
												<h3>
													<div
														style={{
															color: '#1d8cf8',
															fontFamily: 'Montserrat',
															fontSize: '30px',
															whiteSpace: 'nowrap',
															marginTop: '-10px',
															textAlign: 'left'
														}}
													>
														{' '}
														{numberWithSpaces(selectedMetrics?.author_count_original) || 0}
													</div>
												</h3>
											</div>
										</Grid>
										<Grid item xs={5} style={{ textAlign: 'right' }}>
											<div style={{ textAlign: 'right', marginTop: '1.2em' }}>
												{sourcesProperties[sources[0]].authorUnique.icon}
											</div>
										</Grid>
									</Grid>
									<hr id='divider' style={{ opacity: 0.5 }} />
								</CardContent>
							</Grid>
						)}

						{![null].includes(selectedMetrics?.followers_count) && ['twitter', 'instagram'].includes(sources[0]) && (
							<Grid item xs={3}>
								<CardContent>
									<Grid container spacing={3}>
										<Grid item xs={7}>
											<div>
												<p style={{ fontSize: '14px', textAlign: 'left' }}>
													{sourcesProperties[sources[0]].followersCount.name}
												</p>
												<h3>
													<div
														style={{
															color: '#1d8cf8',
															fontFamily: 'Montserrat',
															fontSize: '30px',
															whiteSpace: 'nowrap',
															marginTop: '-10px',
															textAlign: 'left'
														}}
													>
														{numberWithSpaces(selectedMetrics?.followers_count) || 0}
													</div>
												</h3>
											</div>
										</Grid>
										<Grid item xs={5} style={{ textAlign: 'right' }}>
											<div style={{ textAlign: 'right', marginTop: '1.2em' }}>
												{sourcesProperties[sources[0]].followersCount.icon}
											</div>
										</Grid>
									</Grid>
									<hr id='divider' style={{ opacity: 0.5 }} />
								</CardContent>
							</Grid>
						)}
						{![null].includes(selectedMetrics?.author_likes_count) && ['facebook'].includes(sources[0]) && (
							<Grid item xs={3}>
								<CardContent>
									<Grid container spacing={3}>
										<Grid item xs={7}>
											<div>
												<p style={{ fontSize: '14px', textAlign: 'left' }}>
													{sourcesProperties[sources[0]].authorLikesCount.name}
												</p>
												<h3>
													<div
														style={{
															color: '#1d8cf8',
															fontFamily: 'Montserrat',
															fontSize: '30px',
															whiteSpace: 'nowrap',
															marginTop: '-10px',
															textAlign: 'left'
														}}
													>
														{numberWithSpaces(selectedMetrics?.author_likes_count) || 0}
													</div>
												</h3>
											</div>
										</Grid>
										<Grid item xs={5} style={{ textAlign: 'right' }}>
											<div style={{ textAlign: 'right', marginTop: '1.2em' }}>
												{sourcesProperties[sources[0]].authorLikesCount.icon}
											</div>
										</Grid>
									</Grid>
									<hr id='divider' style={{ opacity: 0.5 }} />
								</CardContent>
							</Grid>
						)}
						{![null].includes(selectedMetrics?.share_count) && (
							<Grid item xs={3}>
								<CardContent>
									<Grid container spacing={3}>
										<Grid item xs={7}>
											<div>
												<p style={{ fontSize: '14px', textAlign: 'left' }}>{sourcesProperties[sources[0]].share.name}</p>
												<h3>
													<div
														style={{
															color: '#1d8cf8',
															fontFamily: 'Montserrat',
															fontSize: '30px',
															whiteSpace: 'nowrap',
															marginTop: '-10px',
															textAlign: 'left'
														}}
													>
														{numberWithSpaces(selectedMetrics?.share_count) || 0}
													</div>
												</h3>
											</div>
										</Grid>
										<Grid item xs={5} style={{ textAlign: 'right' }}>
											<div style={{ textAlign: 'right', marginTop: '1.2em' }}>{sourcesProperties[sources[0]].share.icon}</div>
										</Grid>
									</Grid>
									<hr id='divider' style={{ opacity: 0.5 }} />
								</CardContent>
							</Grid>
						)}

						{![null].includes(selectedMetrics?.author_count_original) && ![null].includes(selectedMetrics?.post_count) && (
							<Grid item xs={3}>
								<CardContent>
									<Grid container spacing={3}>
										<Grid item xs={7}>
											<div>
												<p style={{ fontSize: '14px', textAlign: 'left' }}>
													{sourcesProperties[sources[0]].postsPerAuthor.name}
												</p>
												<h3>
													<div
														style={{
															color: '#1d8cf8',
															fontFamily: 'Montserrat',
															fontSize: '30px',
															whiteSpace: 'nowrap',
															marginTop: '-10px',
															textAlign: 'left'
														}}
													>
														{postsPerAuthor(selectedMetrics)}
													</div>
												</h3>
											</div>
										</Grid>
										<Grid item xs={5} style={{ textAlign: 'right' }}>
											<div style={{ textAlign: 'right', marginTop: '1.2em' }}>
												{PostsPerUserIcon(
													sourcesProperties[sources[0]].rawIcon,
													sourcesProperties[sources[0]].postsPerAuthor.icon
												)}
											</div>
										</Grid>
									</Grid>
									<hr id='divider' style={{ opacity: 0.5 }} />
								</CardContent>
							</Grid>
						)}

						{![null].includes(selectedMetrics?.like_count) && (
							<Grid item xs={3}>
								<CardContent>
									<Grid container spacing={3}>
										<Grid item xs={7}>
											<div>
												<p style={{ fontSize: '14px', textAlign: 'left' }}>{sourcesProperties[sources[0]].like.name}</p>
												<h3>
													<div
														style={{
															color: '#1d8cf8',
															fontFamily: 'Montserrat',
															fontSize: '30px',
															whiteSpace: 'nowrap',
															marginTop: '-10px',
															textAlign: 'left'
														}}
													>
														{numberWithSpaces(selectedMetrics?.like_count) || 0}
													</div>
												</h3>
											</div>
										</Grid>
										<Grid item xs={5} style={{ textAlign: 'right' }}>
											<div style={{ textAlign: 'right', marginTop: '1.2em' }}>{sourcesProperties[sources[0]].like.icon}</div>
										</Grid>
									</Grid>
									<hr id='divider' style={{ opacity: 0.5 }} />
								</CardContent>
							</Grid>
						)}

						{![null].includes(selectedMetrics?.comment_count) && (
							<Grid item xs={3}>
								<CardContent>
									<Grid container spacing={3}>
										<Grid item xs={7}>
											<div>
												<p style={{ fontSize: '14px', textAlign: 'left' }}>{sourcesProperties[sources[0]].comment.name}</p>
												<h3>
													<div
														style={{
															color: '#1d8cf8',
															fontFamily: 'Montserrat',
															fontSize: '30px',
															whiteSpace: 'nowrap',
															marginTop: '-10px',
															textAlign: 'left'
														}}
													>
														{numberWithSpaces(selectedMetrics?.comment_count) || 0}
													</div>
												</h3>
											</div>
										</Grid>
										<Grid item xs={5} style={{ textAlign: 'right', marginTop: '1.2em' }}>
											{sourcesProperties[sources[0]].comment.icon}
										</Grid>
									</Grid>
									<hr id='divider' style={{ opacity: 0.5 }} />
								</CardContent>
							</Grid>
						)}

						{![null].includes(selectedMetrics?.like_count_good) && (
							<Grid item xs={3}>
								<CardContent>
									<Grid container spacing={3}>
										<Grid item xs={7}>
											<div>
												<p style={{ fontSize: '14px', textAlign: 'left' }}>Curtidas positivas</p>
												<h3>
													<div
														style={{
															color: '#1d8cf8',
															fontFamily: 'Montserrat',
															fontSize: '30px',
															whiteSpace: 'nowrap',
															marginTop: '-10px',
															textAlign: 'left'
														}}
													>
														{likesSentimentsPercentage('positive', selectedMetrics)}%
													</div>
												</h3>
											</div>
										</Grid>
										<Grid item xs={5} style={{ textAlign: 'right' }}>
											<div style={{ textAlign: 'right', marginTop: '1.2em' }}>
												<FiPlus size={35} style={{ marginRight: '0.6em', color: '#1d8cf8' }} />
											</div>
										</Grid>
									</Grid>

									<hr id='divider' style={{ opacity: 0.5 }} />
								</CardContent>
							</Grid>
						)}

						{![null].includes(selectedMetrics?.like_count_neutral) && (
							<Grid item xs={3}>
								<CardContent>
									<Grid container spacing={3}>
										<Grid item xs={7}>
											<div>
												<p style={{ fontSize: '14px', textAlign: 'left' }}>Curtidas neutras</p>
												<h3>
													<div
														style={{
															color: '#1d8cf8',
															fontFamily: 'Montserrat',
															fontSize: '30px',
															whiteSpace: 'nowrap',
															marginTop: '-10px',
															textAlign: 'left'
														}}
													>
														{likesSentimentsPercentage('neutral', selectedMetrics)}%
													</div>
												</h3>
											</div>
										</Grid>
										<Grid item xs={5} style={{ textAlign: 'right' }}>
											<div style={{ textAlign: 'right', marginTop: '1.2em' }}>
												<FiCircle size={35} style={{ marginRight: '0.6em', color: purple }} />
											</div>
										</Grid>
									</Grid>

									<hr id='divider' style={{ opacity: 0.5 }} />
								</CardContent>
							</Grid>
						)}

						{![null].includes(selectedMetrics?.like_count_bad) && (
							<Grid item xs={3}>
								<CardContent>
									<Grid container spacing={3}>
										<Grid item xs={7}>
											<div>
												<p style={{ fontSize: '14px', textAlign: 'left' }}>Curtidas negativas</p>
												<h3>
													<div
														style={{
															color: '#1d8cf8',
															fontFamily: 'Montserrat',
															fontSize: '30px',
															whiteSpace: 'nowrap',
															marginTop: '-10px',
															textAlign: 'left'
														}}
													>
														{likesSentimentsPercentage('negative', selectedMetrics)}%
													</div>
												</h3>
											</div>
										</Grid>
										<Grid item xs={5} style={{ textAlign: 'right' }}>
											<div style={{ textAlign: 'right', marginTop: '1.2em' }}>
												<FiMinus size={35} style={{ marginRight: '0.6em', color: '#fc516b' }} />
											</div>
										</Grid>
									</Grid>

									<hr id='divider' style={{ opacity: 0.5 }} />
								</CardContent>
							</Grid>
						)}

						{![null].includes(selectedMetrics?.love_count) && (
							<Grid item xs={3}>
								<CardContent>
									<Grid container spacing={3}>
										<Grid item xs={7}>
											<div>
												<p style={{ fontSize: '14px', textAlign: 'left' }}>Amei</p>
												<h3>
													<div
														style={{
															color: '#1d8cf8',
															fontFamily: 'Montserrat',
															fontSize: '30px',
															whiteSpace: 'nowrap',
															marginTop: '-10px',
															textAlign: 'left'
														}}
													>
														{numberWithSpaces(selectedMetrics?.love_count) || 0}
													</div>
												</h3>
											</div>
										</Grid>
										<Grid item xs={5} style={{ textAlign: 'right', marginTop: '1.2em' }}>
											<HeartIcon size={35} style={{ marginRight: '0.6em', color: red }} />
										</Grid>
									</Grid>
									<hr id='divider' style={{ opacity: 0.5 }} />
								</CardContent>
							</Grid>
						)}

						{![null].includes(selectedMetrics?.haha_count) && (
							<Grid item xs={3}>
								<CardContent>
									<Grid container spacing={3}>
										<Grid item xs={7}>
											<div>
												<p style={{ fontSize: '14px', textAlign: 'left' }}>HaHa</p>
												<h3>
													<div
														style={{
															color: '#1d8cf8',
															fontFamily: 'Montserrat',
															fontSize: '30px',
															whiteSpace: 'nowrap',
															marginTop: '-10px',
															textAlign: 'left'
														}}
													>
														{numberWithSpaces(selectedMetrics?.haha_count) || 0}
													</div>
												</h3>
											</div>
										</Grid>
										<Grid item xs={5} style={{ textAlign: 'right', marginTop: '1.2em' }}>
											<HahaIcon size={35} style={{ marginRight: '0.6em', color: yellow }} />
										</Grid>
									</Grid>
									<hr id='divider' style={{ opacity: 0.5 }} />
								</CardContent>
							</Grid>
						)}

						{![null].includes(selectedMetrics?.wow_count) && (
							<Grid item xs={3}>
								<CardContent>
									<Grid container spacing={3}>
										<Grid item xs={7}>
											<div>
												<p style={{ fontSize: '14px', textAlign: 'left' }}>Wow</p>
												<h3>
													<div
														style={{
															color: '#1d8cf8',
															fontFamily: 'Montserrat',
															fontSize: '30px',
															whiteSpace: 'nowrap',
															marginTop: '-10px',
															textAlign: 'left'
														}}
													>
														{numberWithSpaces(selectedMetrics?.wow_count) || 0}
													</div>
												</h3>
											</div>
										</Grid>
										<Grid item xs={5} style={{ textAlign: 'right', marginTop: '1.2em' }}>
											<WowIcon size={35} style={{ marginRight: '0.6em', color: yellow }} />
										</Grid>
									</Grid>
									<hr id='divider' style={{ opacity: 0.5 }} />
								</CardContent>
							</Grid>
						)}

						{![null].includes(selectedMetrics?.sad_count) && (
							<Grid item xs={3}>
								<CardContent>
									<Grid container spacing={3}>
										<Grid item xs={7}>
											<div>
												<p style={{ fontSize: '14px', textAlign: 'left' }}>Triste</p>
												<h3>
													<div
														style={{
															color: '#1d8cf8',
															fontFamily: 'Montserrat',
															fontSize: '30px',
															whiteSpace: 'nowrap',
															marginTop: '-10px',
															textAlign: 'left'
														}}
													>
														{numberWithSpaces(selectedMetrics?.sad_count) || 0}
													</div>
												</h3>
											</div>
										</Grid>
										<Grid item xs={5} style={{ textAlign: 'right', marginTop: '1.2em' }}>
											<SadIcon size={35} style={{ marginRight: '0.6em', color: yellow }} />
										</Grid>
									</Grid>
									<hr id='divider' style={{ opacity: 0.5 }} />
								</CardContent>
							</Grid>
						)}

						{![null].includes(selectedMetrics?.angry_count) && (
							<Grid item xs={3}>
								<CardContent>
									<Grid container spacing={3}>
										<Grid item xs={7}>
											<div>
												<p style={{ fontSize: '14px', textAlign: 'left' }}>Grr</p>
												<h3>
													<div
														style={{
															color: '#1d8cf8',
															fontFamily: 'Montserrat',
															fontSize: '30px',
															whiteSpace: 'nowrap',
															marginTop: '-10px',
															textAlign: 'left'
														}}
													>
														{numberWithSpaces(selectedMetrics?.angry_count) || 0}
													</div>
												</h3>
											</div>
										</Grid>
										<Grid item xs={5} style={{ textAlign: 'right', marginTop: '1.2em' }}>
											<AngryIcon size={35} style={{ marginRight: '0.6em', color: red }} />
										</Grid>
									</Grid>
									<hr id='divider' style={{ opacity: 0.5 }} />
								</CardContent>
							</Grid>
						)}

						{![null].includes(selectedMetrics?.view_count) && (
							<Grid item xs={3}>
								<CardContent>
									<Grid container spacing={3}>
										<Grid item xs={7}>
											<div>
												<p style={{ fontSize: '14px', textAlign: 'left' }}>Visualizações</p>
												<h3>
													<div
														style={{
															color: '#1d8cf8',
															fontFamily: 'Montserrat',
															fontSize: '30px',
															whiteSpace: 'nowrap',
															marginTop: '-10px',
															textAlign: 'left'
														}}
													>
														{numberWithSpaces(selectedMetrics?.view_count) || 0}
													</div>
												</h3>
											</div>
										</Grid>
										<Grid item xs={5} style={{ textAlign: 'right' }}>
											<div style={{ textAlign: 'right', marginTop: '1.2em' }}>{visIconMetric}</div>
										</Grid>
									</Grid>
									<hr id='divider' style={{ opacity: 0.5 }} />
								</CardContent>
							</Grid>
						)}

						{![null].includes(selectedMetrics?.quote_count) && (
							<Grid item xs={3}>
								<CardContent>
									<Grid container spacing={3}>
										<Grid item xs={7}>
											<div>
												<p style={{ fontSize: '14px', textAlign: 'left' }}>Citações</p>
												<h3>
													<div
														style={{
															color: '#1d8cf8',
															fontFamily: 'Montserrat',
															fontSize: '30px',
															whiteSpace: 'nowrap',
															marginTop: '-10px',
															textAlign: 'left'
														}}
													>
														{numberWithSpaces(selectedMetrics?.quote_count) || 0}
													</div>
												</h3>
											</div>
										</Grid>
										<Grid item xs={5} style={{ textAlign: 'right' }}>
											<div style={{ textAlign: 'right', marginTop: '1.2em' }}>
												<AiOutlineRetweet size={35} style={{ marginRight: '0.6em', color: '#1d8cf8' }} />
											</div>
										</Grid>
									</Grid>
									<hr id='divider' style={{ opacity: 0.5 }} />
								</CardContent>
							</Grid>
						)}
					</Grid>
				</div>
			)}
			{isReport && reportImage && <img src={reportImage} style={{ width: '100%' }} />}
		</>
	)
}

export default SummaryMetrics
