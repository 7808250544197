import React, { memo, useEffect, useState } from 'react'
import { Button, Card, Grid, Tabs, Tab, Divider, Select, MenuItem, Box, Zoom, Checkbox, Collapse } from '@mui/material'
import { connect } from 'react-redux'
import { makeStyles } from '@mui/styles'
import { ConfigureIcon } from 'utils/SystemIcons'
import { getFirstCard } from 'components/Occurrences/utils/occurrencesFunctions'
import OccurrencesCardSelector from 'components/Occurrences/components/FilterBar/components/OccurrencesCardSelector/OccurrencesCardSelector'
import api from 'service/service'
import Skeleton from 'react-loading-skeleton'
import AurasightConfigDialog from './components/AurasightConfigDialog/AurasightConfigDialog'
import AurasightMediaDialog from './components/AurasightMediaDialog/AurasightMediaDialog'
import InferenceMediaCard from './components/InferenceMediaCard/InferenceMediaCard'
import AuraSightDashboard from './components/AurasightDashboard/AuraSightDashboard'

const blue = '#1d8cf8'
const grey = '#828282'

const isFirefox = typeof InstallTrigger !== 'undefined'
const linkToS3 = 'https://voxradar-public-develop.s3.us-east-1.amazonaws.com/' //

const useStyles = makeStyles({
	backDrop: {
		backdropFilter: 'blur(3px)',
		backgroundColor: 'rgba(220, 220, 220, 0.4)'
	},
	button: {
		fontFamily: 'Poppins',
		border: '1px solid rgba(29, 138, 248, 100%)',
		boxShadow: 'none',
		color: 'white',
		backgroundColor: blue,
		borderRadius: '100px',
		background: 'transparent',
		'&:hover': {
			border: '1px solid rgba(29, 138, 248, 25%)',
			backgroundColor: 'rgba(29, 138, 248, 75%)',
			boxShadow: '0px 0px -10px -17px rgba(0,0,0,255)'
		}
	},
	tab: {
		fontFamily: 'Poppins',
		fontSize: 12,
		fontWeight: 300,
		color: grey
	}
})

const AurasightContent = ({ cards, globalFiltersRedux }) => {
	const [images, setImages] = useState()
	const [videos, setVideos] = useState()
	const [selectedCard, setSelectedCard] = useState(getFirstCard(cards, ''))
	const [allCardsSelect, setAllCardsSelect] = useState(cards)
	const [thisFilters, setThisFilters] = useState(globalFiltersRedux)
	const [openModal, setOpenModal] = useState(false)
	const [inferenceMedias, setInferenceMedias] = useState()
	const [openMedia, setOpenMedia] = useState(false)
	const [inferenceKey, setInferenceKey] = useState()
	const [key, setKey] = useState()
	const [media, setMedia] = useState([])
	const [isLoading, setIsLoading] = useState(true)
	const [changeInference, setChangeInference] = useState(false)
	const [activeTab, setActiveTab] = useState('videos')
	const [brands, setBrands] = useState([])
	const [selectedBrands, setSelectedBrands] = useState()

	const classes = useStyles()

	useEffect(() => {
		setThisFilters(globalFiltersRedux)
	}, [globalFiltersRedux])

	useEffect(() => {
		setAllCardsSelect(cards)
		setSelectedCard(getFirstCard(cards, ''))
	}, [cards])

	useEffect(() => {
		load()
	}, [selectedCard, thisFilters, cards])

	useEffect(() => {
		let eachMedia = []
		inferenceMedias?.[key]?.forEach((media) => {
			media?._source?.general_automatic_classification?.inference_media?.forEach((inferenceMedia) => {
				if (inferenceMedia?.labels?.find((label) => label.label === inferenceKey)) {
					eachMedia.push({
						media: linkToS3 + inferenceMedia?.metadata?.url_media,
						thumbnail_media: inferenceMedia?.metadata?.media_thumbnail ? linkToS3 + inferenceMedia?.metadata?.media_thumbnail : undefined,
						original_media: linkToS3 + media?._source?.content?.attachments?.find((it) => it.type === 'video')?.url_downloaded,
						id: media?._id,
						inferenceId: inferenceMedia?.id
					})
				}
			})
		})
		setMedia(eachMedia)
	}, [inferenceKey, key, inferenceMedias])

	useEffect(() => {
		if (!openMedia && changeInference) {
			load()
		}
		setChangeInference(false)
	}, [openMedia])

	const load = async () => {
		const body = {
			configGlobalFilters: {
				cardType: selectedCard.type,
				cardIds: selectedCard.id
			},
			globalFilters: { ...thisFilters },
			componentProperties: {}
		}
		setIsLoading(true)
		await api.post('/image-annotation/get-with-filters', body).then((response) => {
			setInferenceMedias(response?.data?.inferenceMedias)
			setImages(null)
			setVideos(null)
			setBrands(response?.data?.brands)
			setSelectedBrands([response?.data?.brands[0]?.name])
			setImages(response?.data?.attachmentsType?.images)
			setVideos(response?.data?.attachmentsType?.videos)
			setIsLoading(false)
		})
	}

	const onCardChange = (newCard) => {
		setSelectedCard(newCard)
	}

	const handleTabChange = (_, newValue) => {
		setActiveTab(newValue)
	}

	const handleSelectBrandChange = (event) => {
		const {
			target: { value }
		} = event
		setSelectedBrands(value)
	}

	return (
		<Grid container spacing={1}>
			<Grid item xs={12} style={{ marginTop: '9px' }}>
				<Card
					style={{
						height: '77.5vh',
						overflowY: 'auto',
						margin: 4,
						padding: '1em',
						borderRadius: '20px',
						backgroundColor: 'transparent'
					}}
				>
					<Grid item xs={12} container direction='row'>
						<Grid item xs={4} container justifyContent='flex-start' alignItems='center'>
							{selectedBrands && brands.length > 0 && (
								<Select
									fullWidth
									variant='standard'
									multiple
									value={selectedBrands}
									onChange={handleSelectBrandChange}
									style={{ fontFamily: 'Poppins', fontSize: '20px', color: '#1d8cf8', minHeight: '53px' }}
									MenuProps={{
										anchorOrigin: {
											vertical: 'bottom',
											horizontal: 'left'
										},
										transformOrigin: {
											vertical: 'top',
											horizontal: 'left'
										},
										PaperProps: {
											style: {
												marginTop: '5px',
												marginLeft: '-1px',
												borderRadius: '0px 0px 20px 20px',
												backdropFilter: 'blur(10px)',
												backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.0)'}`,
												borderBottom: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
												borderLeft: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
												borderRight: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
												boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
											}
										}
									}}
									renderValue={(selected) => (
										<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '0.5em' }}>
											{selected.map((value, index) => {
												let brand = brands.find((brand) => brand?.name === value)
												return (
													<Zoom in={true} unmountOnExit key={index} style={{ transitionDelay: '100ms' }}>
														<div
															style={{
																fontFamily: 'Poppins',
																fontSize: '20px',
																display: 'flex',
																alignItems: 'center'
															}}
														>
															<img
																src={brand?.logoUrl}
																alt={`${brand?.key} logo`}
																style={{
																	fontFamily: 'Poppins',
																	borderRadius: '50%',
																	width: '2em',
																	height: '2em',
																	marginRight: '10px',
																	objectFit: 'cover',
																	border: '2px solid #1d8cf8',
																	imageRendering: 'auto',
																	filter: 'blur(0.4px)'
																}}
																draggable='false'
															/>
														</div>
													</Zoom>
												)
											})}
										</Box>
									)}
								>
									{brands.map((brand) => {
										let selected = selectedBrands.includes(brand?.name)
										return (
											<MenuItem key={brand?.key} value={brand?.name}>
												<div
													style={{
														fontFamily: 'Poppins',
														fontSize: '20px',
														display: 'flex',
														alignItems: 'center'
													}}
												>
													<Collapse in={selected} orientation='horizontal'>
														<Checkbox checked={selected} style={{ color: '#1d8cf8' }} />
													</Collapse>
													<img
														src={brand?.logoUrl}
														alt={`${brand?.key} logo`}
														style={{
															fontFamily: 'Poppins',
															borderRadius: '50%',
															width: '2em',
															height: '2em',
															marginRight: '10px',
															objectFit: 'cover',
															border: '2px solid #1d8cf8',
															imageRendering: 'crisp-edges'
														}}
														draggable='false'
													/>
													{brand.name}
												</div>
											</MenuItem>
										)
									})}
								</Select>
							)}
						</Grid>

						<Grid item xs={4}>
							{allCardsSelect && (
								<div style={{ marginTop: '1em' }}>
									<OccurrencesCardSelector cards={allCardsSelect} onCardChange={onCardChange} selectedCard={selectedCard} />
								</div>
							)}
						</Grid>

						<Grid item xs={4} container justifyContent='flex-end' alignItems='center'>
							<Button style={{ borderRadius: '20px' }} variant='contained' onClick={() => setOpenModal(true)}>
								<ConfigureIcon className='fix-icon-margin-bottom-negative' size={15} style={{ marginRight: '5px' }} />
								Config Download
							</Button>
						</Grid>
						<Divider style={{ backgroundColor: 'rgba(0, 0, 0, 0.01)', height: '0.5px', width: '100%', marginTop: '-1px' }} />
					</Grid>

					{isLoading ? (
						<Skeleton variant='rectangular' width='100%' height='86%' style={{ borderRadius: '20px' }} />
					) : (
						<>
							{brands.length > 0 ? (
								<>
									<AuraSightDashboard brands={brands} selectedBrands={selectedBrands} />
									<Tabs value={activeTab} onChange={handleTabChange} style={{ marginTop: '3em' }}>
										<Tab label='Vídeos' value={'videos'} className={classes.tab} />
										<Tab label='Imagens' value={'images'} className={classes.tab} />
									</Tabs>
									<Divider style={{ backgroundColor: 'rgba(0, 0, 0, 0.01)', height: '0.5px', width: '100%', marginTop: '-1px' }} />
									<Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} style={{ marginTop: '1em' }}>
										{activeTab === 'images' &&
											images?.labels?.label?.buckets?.map((inference, index) => {
												let delay = index * 100
												return (
													<InferenceMediaCard
														key={index}
														mediaType='image'
														inference={inference}
														delay={delay}
														setInferenceKey={setInferenceKey}
														setKey={setKey}
														setOpenMedia={setOpenMedia}
													/>
												)
											})}

										{activeTab === 'videos' &&
											videos?.labels?.label?.buckets?.map((inference, index) => {
												let delay = index * 100
												return (
													<InferenceMediaCard
														key={index}
														mediaType='video'
														inference={inference}
														delay={delay}
														setInferenceKey={setInferenceKey}
														setKey={setKey}
														setOpenMedia={setOpenMedia}
													/>
												)
											})}
									</Grid>
								</>
							) : (
								<div
									style={{
										height: '40vh',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										fontFamily: 'Poppins',
										fontSize: '24px',
										color: '#bdbbbb'
									}}
								>
									Sem dados para esse intervalo
								</div>
							)}
						</>
					)}
				</Card>
			</Grid>
			{openMedia && (
				<AurasightMediaDialog
					open={openMedia}
					onClose={() => setOpenMedia(false)}
					media={media}
					setMedia={setMedia}
					inferenceKey={inferenceKey}
					setChangeInference={setChangeInference}
				/>
			)}
			<AurasightConfigDialog openModal={openModal} setOpenModal={setOpenModal} filters={thisFilters} selectedCard={selectedCard} />
		</Grid>
	)
}

const mapStateToProps = ({ store }) => ({
	globalFiltersRedux: store.global.filters
})

export default connect(mapStateToProps, null)(memo(AurasightContent))
