import React from 'react'
import logo from '../../../assets/Aura.png'
import Title from '../Sections/Title/Title'
import Intro from '../Sections/Intro/Intro'
import Brands from '../Sections/Brands/Brands'
import FinancialGraphs from '../Sections/FinancialGraphs/FinancialGraphs'
import CustomTable from '../CustomTable/CustomTable'
import SubTitle from '../Sections/SubTitle/SubTitle'
import TableSocialMedia from '../TableSocialMedia/TableSocialMedia'
import './Aura.css'

const Aura = () => {
	return (
		<>
			<div className='background' />
			<div style={{ height: '100vh', overflow: 'auto', backgroundColor: 'rgba(255,255,255, 0.1)' }}>
				<div className='aura-header'>
					<div className='aura-logo-container'>
						<img src={logo} className='aura-logo' />
						<div className='aura-header-text'>aurasight</div>
					</div>
				</div>
				<Title title={'Análise do Atlético Mineiro Final Copa do Brasil'} date={'NOVEMBRO/2024'} />
				<SubTitle subTitle={'Análise do Jogo'} />
				<Brands />
				<FinancialGraphs />
				<CustomTable />
				<SubTitle subTitle={'Análise das Redes sociais'} />
				<Intro />
				<TableSocialMedia />
				<SubTitle subTitle={'Obrigado'} />
			</div>
		</>
	)
}

export default Aura
