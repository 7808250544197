import React from 'react'
import Chart from 'react-apexcharts'

const ApexColumn = ({ series, categories }) => {
	const options = {
		chart: {
			type: 'bar',
			height: 350,
			stacked: true
		},
		plotOptions: {
			bar: {
				horizontal: false,
				columnWidth: '55%',
				endingShape: 'rounded'
			}
		},
		legend: {
			labels: {
				colors: ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff']
			}
		},
		dataLabels: {
			enabled: false
		},
		stroke: {
			show: true,
			width: 2,
			colors: ['transparent']
		},
		fill: {
			opacity: 1
		},
		tooltip: {
			enabled: false
		},
		xaxis: {
			categories: categories,
			axisBorder: {
				show: true,
				color: 'rgba(255, 255, 255, 0.3)',
				height: 1,
				width: '100%',
				offsetX: 0,
				offsetY: 0
			},
			labels: {
				show: true,
				align: 'right',
				minWidth: 0,
				maxWidth: 160,
				style: {
					colors: ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff'],
					fontSize: '12px',
					fontFamily: 'Poppins',
					fontWeight: 400,
					cssClass: 'apexcharts-yaxis-label'
				}
			}
		},
		yaxis: {
			labels: {
				show: true,
				align: 'right',
				minWidth: 0,
				maxWidth: 160,
				style: {
					colors: ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff'],
					fontSize: '12px',
					fontFamily: 'Poppins',
					fontWeight: 400,
					cssClass: 'apexcharts-yaxis-label'
				}
			}
		}
	}

	return (
		<div style={{ padding: '1em' }}>
			<Chart options={options} series={series} type='bar' width={'100%'} height={'200px'} />
		</div>
	)
}

export default ApexColumn
