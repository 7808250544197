import React, { useEffect, useState } from 'react'
import { Grid, Tabs, Tab, Divider, Tooltip, Button, FormControl, Select, MenuItem } from '@mui/material'
// import { ClockIcon } from 'utils/SystemIcons'
import { AnalysisIcon, BarsIcon, BarsIcon2 } from 'utils/SystemIcons'
import { makeStyles, styled } from '@mui/styles'
import { canRenderHourInterval } from 'graphs/utils/TimelinesFunctions'

const useStyles = makeStyles(() => ({
	tab: {
		fontFamily: 'Poppins',
		fontSize: 12,
		fontWeight: 300,
		color: grey
	},
	buttonGroup: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	button: {
		fontFamily: 'Poppins',
		color: grey,
		width: '50%',
		borderColor: grey,
		'&:hover': {
			color: blue,
			borderColor: blue,
			backgroundColor: 'transparent'
		}
	},
	buttonLockInterval: {
		fontFamily: 'Poppins',
		fontSize: 12,
		fontWeight: 300,
		color: grey,
		borderColor: grey,
		borderBottom: '2px solid transparent',
		'&:hover': {
			borderBottom: '2px solid #1d8cf8',
			color: blue,
			borderColor: blue,
			backgroundColor: blue
		}
	},
	formControl: {
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderColor: blue
		},
		width: '100%'
	},
	select: {
		'&:hover': {
			borderColor: grey
		},
		'&:before': {
			borderColor: grey
		},
		'&:after': {
			borderColor: blue
		},
		height: '35px',
		'& .MuiSvgIcon-root': {
			color: darkGrey
		}
	}
}))

const blue = '#1d8af8'
const grey = '#828282'

let timer

const isFirefox = typeof InstallTrigger !== 'undefined'
const darkGrey = 'rgba(0, 0, 0, 25%)'

const StyledFormControl = styled(FormControl)({
	'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
		borderColor: blue
	},
	width: '100%'
})

const StyledSelect = styled(Select)({
	'&:hover': {
		borderColor: grey
	},
	'&:before': {
		borderColor: grey
	},
	'&:after': {
		borderColor: blue
	},
	height: '35px',
	'& .MuiSvgIcon-root': {
		color: darkGrey
	}
})

const optionsList = [
	{
		title: 'Linha do tempo',
		type: 'line'
	},

	{
		title: 'Barras',
		type: 'bars'
	},

	{
		title: 'Totais',
		type: 'totals'
	}
]

const getIcon = (type) => {
	switch (type) {
		case 'line':
			return <AnalysisIcon color={'#1d8cf8'} size={15} style={{ marginRight: '10px' }} />
		case 'bars':
			return <BarsIcon color={'#1d8cf8'} size={15} style={{ marginRight: '10px' }} />
		case 'totals':
			return (
				<div style={{ color: '#1d8cf8' }}>
					<BarsIcon2 color={'#1d8cf8'} size={15} style={{ marginRight: '10px' }} />
				</div>
			)
	}
}

const NowcastingOptionsMenu = ({
	timelineType,
	size,
	fromDate,
	toDate,
	handleChangeGraphType,
	handleChangeGraphTime,
	handleSelectGraphView,
	selectedGraphView,
	initialGraphTime,
	type = 'absolute',
	setType
	// getCanLockTime
	// onClickLockInterval,
}) => {
	// const [type, setType] = useState('absolute')
	const [time, setTime] = useState(initialGraphTime)
	const [timeline, setTimeline] = useState(timelineType)
	// const [canLockTime, setCanLockTime] = useState(getCanLockTime())
	const [selectedOptionList, setSelectedOptionList] = useState(selectedGraphView)

	useEffect(() => {
		checkZoom()
		if (timeline !== timelineType) {
			setTimeline(timelineType)
		}
		return () => {
			clearTimeout(timer)
		}
	}, [timelineType])

	useEffect(() => {
		setTime(initialGraphTime)
	}, [initialGraphTime])

	const checkZoom = () => {
		// setCanLockTime(getCanLockTime())
		timer = setTimeout(checkZoom, 500)
	}

	const classes = useStyles()

	const onGraphTypeChange = (_, newValue) => {
		setType(newValue)
		handleChangeGraphType(newValue, selectedOptionList)
	}

	const onGraphTimeChange = (_, newValue) => {
		setTime(newValue)
		handleChangeGraphTime(newValue)
	}

	const onGraphViewSelect = (event) => {
		event.target.value !== 'line' && onGraphTimeChange(undefined, 'day')
		setSelectedOptionList(event.target.value)
		handleSelectGraphView(event.target.value)
	}

	return (
		<>
			<Grid container spacing={0}>
				<Grid item xs={4}>
					<Tabs
						id='time-selector-nowcasting-options-tabs'
						value={time}
						onChange={onGraphTimeChange}
						TabIndicatorProps={{ style: { background: blue } }}
					>
						{selectedOptionList === 'line' &&
							(canRenderHourInterval(fromDate, toDate, 'months', 3) ? (
								<Tab
									id='time-selector-nowcasting-option-tab-hours'
									label='Horas'
									value='hour'
									style={{ outline: 'none' }}
									className={classes.tab}
								/>
							) : (
								<Tooltip
									title='Intervalo selecionado não disponível para visualização em horas (maior que 3 meses)'
									placement='right'
								>
									<Button
										id='time-selector-nowcasting-option-btn-hours'
										disableRipple
										style={{ borderRadius: 0, backgroundColor: 'transparent', textTransform: 'uppercase', width: '13em' }}
										className={classes.button}
									>
										Horas
									</Button>
								</Tooltip>
							))}
						{selectedOptionList !== 'totals' && (
							<Tab
								id='time-selector-nowcasting-option-tab-days'
								label='Dias'
								value='day'
								style={{ outline: 'none' }}
								className={classes.tab}
							/>
						)}
					</Tabs>
				</Grid>
				<Grid container justifyContent={'center'} alignItems={'center'} item xs={4}>
					{size > 1 && (
						<Tabs
							style={{ marginRight: '0px', float: 'right' }}
							value={type}
							onChange={onGraphTypeChange}
							TabIndicatorProps={{ style: { background: blue } }}
						>
							<Tab label='Absoluto' value='absolute' style={{ outline: 'none' }} className={classes.tab} />
							<Tab label='Relativo' value='relative' style={{ outline: 'none' }} className={classes.tab} />
						</Tabs>
					)}
					{/* <Collapse in={canLockTime}>
						<Button
							onClick={onClickLockInterval}
							style={{ borderRadius: 0, backgroundColor: 'transparent', textTransform: 'uppercase', height: '48px' }}
							className={classes.buttonLockInterval}
						>
							<ClockIcon size={17} style={{ color: '#1d8cf8', marginRight: '0.5em' }} />
							manter intervalo
						</Button>
					</Collapse> */}
				</Grid>
				<Grid item xs={4} style={{ margin: '0px' }} container justifyContent='flex-end'>
					<Grid item xs={6} style={{ marginTop: '6px', marginRight: '5px' }}>
						<StyledFormControl className={classes.formControl}>
							<StyledSelect
								id='select-nowcasting-viewer'
								variant='standard'
								className={classes.select}
								value={selectedOptionList}
								onChange={onGraphViewSelect}
								style={{ marginTop: '7px' }}
								MenuProps={{
									anchorOrigin: {
										vertical: 'bottom',
										horizontal: 'left'
									},
									transformOrigin: {
										vertical: 'top',
										horizontal: 'left'
									},
									PaperProps: {
										style: {
											marginTop: '0px',
											marginLeft: '-1px',
											borderRadius: '0px 0px 25px 25px',
											backdropFilter: 'blur(10px)',
											backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
											borderBottom: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
											borderLeft: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
											borderRight: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
											boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
										}
									}
								}}
							>
								{optionsList.map((option) => {
									return (
										<MenuItem key={option.type} value={option.type} id={'select-nowcasting-item-' + option.title}>
											<Grid container justifyContent='flex-start' alignItems='center' style={{ fontFamily: 'Poppins' }}>
												{getIcon(option.type)}
												{option.title}
											</Grid>
										</MenuItem>
									)
								})}
							</StyledSelect>
						</StyledFormControl>
					</Grid>
				</Grid>
			</Grid>
			<Divider style={{ marginBottom: '20px', background: '#f0f0f0', marginTop: '-1px' }} />
		</>
	)
}

export default NowcastingOptionsMenu
