import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { Grid, Tooltip, TextField, Autocomplete, Paper, styled } from '@mui/material'
import { SearchIcon } from 'utils/SystemIcons'
import { connect } from 'react-redux'
import { addCardComparisonAction } from 'store/redux/actions/card.actions'

const isFirefox = typeof InstallTrigger !== 'undefined'

const blue = 'rgba(29, 138, 248, 100%)'
const grey = 'rgba(184, 184, 184, 100%)'

const CssTextField = styled(TextField)({
	'& .MuiInputBase-root': {
		color: blue,
		fontFamily: 'Poppins',
		'& input': {
			textAlign: 'left'
		}
	},
	'& label.Mui-focused': {
		color: blue
	},
	'& .MuiInput-underline:after': {
		borderBottomColor: blue
	},
	'& .MuiOutlinedInput-root': {
		'& fieldset': {
			borderColor: grey
		},
		'&:hover fieldset': {
			borderColor: grey
		},
		'&.Mui-focused fieldset': {
			borderColor: blue
		}
	}
})

const categories = {
	GENERAL: 'GERAL',
	POLITICS: 'POLÍTICA',
	RELIGION: 'RELIGIÃO',
	'MEDIA CHANNEL': 'CANAL DE MÍDIA'
}

const CustomPaper = (props) => {
	return (
		<Paper
			{...props}
			sx={{
				minWidth: '15em',
				borderRadius: '0px 0px 25px 25px',
				backdropFilter: 'blur(10px)',
				backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
				border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
				boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
			}}
		/>
	)
}

const AnalysisCardSearch = ({ allCardsCanOpen, cards, addCardComparisonDispatch, onChange }) => {
	const [autocompleteValue, setAutocompleteValue] = useState('')
	const autocompleteRef = useRef()
	const history = useHistory()

	useEffect(() => {
		setAutocompleteValue(null)
	}, [allCardsCanOpen])

	const label = (
		<div style={{ display: 'flex', marginTop: '-6px' }}>
			<SearchIcon size={17} style={{ position: 'relative', transform: 'translate(0px, 5px)', color: '#3d8cf8' }} />
			<div style={{ marginLeft: '5px', marginTop: '0px', fontFamily: 'Poppins', fontSize: '15px' }}>Buscar Cartão</div>
		</div>
	)

	const handleChange = (newValue) => {
		if (newValue?.id) {
			setAutocompleteValue(newValue)
			handleOpen(newValue)
		}
	}

	const handleOpen = (card) => {
		if (card.type === 'term') {
			history.push('/monitoring/sentiment-analysis')
		} else if (card.type === 'profile') {
			history.push('/monitoring/social-profile')
		} else if (card.type === 'group') {
			history.push('/monitoring/accounts-group')
		} else if (card.type === 'report') {
			openInNewTab(card?.linkDownload)
		}

		if (card.type !== 'report') {
			addCardComparisonDispatch(card) //provavelmente vai ter q ter um fix
		}
	}

	const openInNewTab = (url) => {
		const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
		if (newWindow) {
			newWindow.opener = null
		}
	}

	return (
		<>
			<Grid item xs>
				<Tooltip title='' placement='top-start'>
					<Autocomplete
						id='input-search-card'
						style={{
							width: '100%'
						}}
						PaperComponent={CustomPaper}
						freeSolo
						noOptionsText='Não encontrado'
						autoComplete
						autoHighlight
						value={autocompleteValue}
						onInputChange={(_, value) => onChange(value)}
						onChange={(_, value) => handleChange(value)}
						options={allCardsCanOpen
							?.map((option) => {
								return {
									category: option.type !== 'group' ? categories[option.searchCategoryName] : '',
									...option
								}
							})
							.sort((a, b) => b.active - a.active)
							.sort((a, b) => -b.category?.localeCompare(a.category))}
						groupBy={(option) => option.category}
						getOptionDisabled={(item) => !!cards.find((li) => li.id === item.id)}
						getOptionLabel={(option) => option.name || ''}
						renderOption={(props, option) => {
							return (
								<li {...props} key={option.id} style={{ fontFamily: 'Poppins' }}>
									{option.name}
								</li>
							)
						}}
						renderInput={(params) => (
							<div style={{ position: 'relative' }}>
								<CssTextField variant='standard' fullWidth {...params} label={label} inputRef={autocompleteRef} />
							</div>
						)}
					/>
				</Tooltip>
			</Grid>
		</>
	)
}

const mapStateToProps = ({ store: { card } }) => ({
	cards: card.comparison.cards,
	allCardsCanOpen: card.allCardsCanOpen
})

const mapDispatchToProps = (dispatch) => ({
	addCardComparisonDispatch: (card) => dispatch(addCardComparisonAction(card))
})

export default connect(mapStateToProps, mapDispatchToProps)(AnalysisCardSearch)
