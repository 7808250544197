import React from 'react'
import WidgetContainer from '../../../../components/WidgetContainer/WidgetContainer'
import GraficoNowcasting from 'graphs/GraficoNowcasting/GraficoNowcasting'
import TopComments from 'pages/Monitoring/modules/SentimentAnalysis/components/TopComments/TopComments'
import WordClouds from 'pages/Monitoring/modules/components/WordClouds/WordClouds'
import GenderAgeDistribution from 'graphs/GenderAgeDistribution/GenderAgeDistribution'
import GraficoSensibilidade from 'graphs/GraficoSensibilidade/GraficoSensibilidade'
import NetworkGraph from 'graphs/NetworkGraph/NetworkGraph'
import LocationStateAndRegionGraph from 'graphs/LocationStateAndRegionGraph/LocationStateAndRegionGraph'
import LocationGraph from 'components/LocationGraph/LocationGraph'
import VoxRadarIaContent from 'pages/Monitoring/modules/SentimentAnalysis/components/VoxRadarIaContent/VoxRadarIaContent'
export { renderWidgets, DEFAULT_LAYOUTS_TERM, DEFAULT_WIDGETS_TERM }
// <WordCloudDashboardShareable
// 	title={descriptions.find((item) => item.key === 'facebook_word_clouds')?.title}
// 	keyName='facebook_word_clouds'
// 	cards={cards}
// 	source='facebook'
// 	editMode={editMode}
// 	addWidgetMode={addWidgetMode}
// 	removeWidget={removeWidget}
// 	description={descriptions.find((item) => item.key === 'facebook_word_clouds')?.description}
// />

const isSolidCardWidgets = ['overview_sensibility_by_time']
const isResizableCardWidgets = ['posts', 'engagement', 'overview_nowcasting_chart', 'sentiments']

const ALL_POSSIBLE_WIDGETS_TO_RENDER_TERM = {
	posts: {
		title: 'post',
		description: 'posts',
		component: ({ cards }) => <GraficoNowcasting cards={cards} isFetchingData={true} hideRightMenus isWidget={true} show={'posts'} />
	},
	engagement: {
		title: 'engagement',
		description: 'engagement',
		component: ({ cards }) => <GraficoNowcasting cards={cards} isFetchingData={true} hideRightMenus isWidget={true} show={'engagement'} />
	},
	sentiments: {
		title: 'sentiments',
		description: 'sentiments',
		component: ({ cards }) => <GraficoNowcasting cards={cards} isFetchingData={true} hideRightMenus isWidget={true} show={'sentiments'} />
	},
	overview_nowcasting_chart: {
		title: 'overview_nowcasting_chart',
		description: 'overview_nowcasting_chart',
		component: ({ cards }) => <GraficoNowcasting cards={cards} isFetchingData={true} hideRightMenus isWidget={true} show={'timeline'} />
	},
	overview_top_comments_occurrences: {
		title: 'overview_top_comments_occurrences',
		description: 'overview_top_comments_occurrences',
		component: ({ cards }) => <TopComments cards={cards} />
	},
	overview_word_clouds: {
		title: 'overview_word_clouds',
		description: 'overview_word_clouds',
		component: ({ cards }) => <WordClouds cards={cards} isDialog={false} />
	},
	overview_gender_age_distribution: {
		title: 'overview_gender_age_distribution',
		description: 'overview_gender_age_distribution',
		component: ({ cards }) => <GenderAgeDistribution cards={cards} isFetchingData={true} />
	},
	overview_sensibility_by_time: {
		title: 'overview_sensibility_by_time',
		description: 'overview_sensibility_by_time',
		component: ({ cards }) => <GraficoSensibilidade cards={cards} isFetchingData={true} hideRightMenus isWidget={true} />
	},
	ai_ai_analysis: {
		title: 'ai_ai_analysis',
		description: 'ai_ai_analysis',
		component: ({ cards }) => (
			<VoxRadarIaContent showAiAnalysis={true} showTopicAnalysis={false} cards={cards} isFetchingData={true} isWidget={true} />
		)
	},
	ai_topic_analysis: {
		title: 'ai_topic_analysis',
		description: 'ai_topic_analysis',
		component: ({ cards }) => (
			<VoxRadarIaContent showAiAnalysis={false} showTopicAnalysis={true} cards={cards} isFetchingData={true} isWidget={true} />
		)
	},
	influencers_network_chart: {
		title: 'influencers_network_chart',
		description: 'influencers_network_chart',
		component: ({ cards }) => (
			<NetworkGraph
				cards={cards}
				isFetchingData={true}
				cardType={cards[0]?.type}
				showNetworkChart={true}
				showNetworkSummaryTop={false}
				hideRightMenus
			/>
		)
	},
	influencers_network_top: {
		title: 'influencers_network_top',
		description: 'influencers_network_top',
		component: ({ cards }) => (
			<NetworkGraph cards={cards} isFetchingData={true} cardType={cards[0]?.type} showNetworkSummaryTop={true} showNetworkChart={false} />
		)
	},
	location_map: { title: 'location_map', description: 'location_map', component: ({ cards }) => <LocationGraph cards={cards} hideRightMenus /> },
	location_general_regions: {
		title: 'location_general_regions',
		description: 'location_general_regions',
		component: ({ cards }) => (
			<LocationStateAndRegionGraph cards={cards} isFetchingData={true} showRegions={true} showStates={false} hideRightMenus />
		)
	},
	location_general_states: {
		title: 'location_general_states',
		description: 'location_general_states',
		component: ({ cards }) => (
			<LocationStateAndRegionGraph cards={cards} isFetchingData={true} showRegions={false} showStates={true} hideRightMenus />
		)
	}
}

const renderWidgets = ({ editMode, addWidgetMode, removeWidget, cards, widgetsOnScreen }) => {
	return widgetsOnScreen?.map((widgetKey) => {
		const widgetFound = ALL_POSSIBLE_WIDGETS_TO_RENDER_TERM[widgetKey]
		const isSolidCardWidget = isSolidCardWidgets.includes(widgetKey)
		const isResizableWidget = isResizableCardWidgets.includes(widgetKey)
		return (
			<div key={widgetKey}>
				<WidgetContainer
					//title={null} //não precisa por enquanto pros componentes antigos
					//description={null} //não precisa por enquanto pros componentes antigos
					widgetKeyName={widgetKey}
					isAddWidgetMode={addWidgetMode}
					isEditMode={editMode}
					removeWidget={removeWidget}
					loading={false}
					isSolidCardWidget={isSolidCardWidget}
					isResizable={isResizableWidget}
				>
					{widgetFound.component({ cards })}
				</WidgetContainer>
			</div>
		)
	})
}
// { i: 'overview_nowcasting_chart', 			x: 0, y: 0, w: 12, h: 16, minH: 16, maxH: 16, minW: 12, maxW: 12 },
// { i: 'overview_top_comments_occurrences', 	x: 0, y: 17, w: 6, h: 16, minH: 16, maxH: 16, minW: 6, maxW: 12 },
// { i: 'overview_word_clouds', 				x: 7, y: 17, w: 6, h: 16, minH: 16, maxH: 16, minW: 6, maxW: 6 },
// { i: 'overview_gender_age_distribution', 	x: 0, y: 34, w: 12, h: 15, minH: 15, maxH: 15, minW: 6, maxW: 12 },
// { i: 'overview_sensibility_by_time', 		x: 0, y: 50, w: 12, h: 15, minH: 15, maxH: 15, minW: 6, maxW: 12 },
// { i: 'ai_ai_analysis', 						x: 0, y: 66, w: 12, h: 20, minH: 20, maxH: 20, minW: 6, maxW: 12 },
// { i: 'ai_topic_analysis', 					x: 0, y: 86, w: 12, h: 16, minH: 16, maxH: 16, minW: 6, maxW: 12 },
// { i: 'influencers_network_chart', 			x: 0, y: 103, w: 12, h: 24, minH: 24, maxH: 24, minW: 6, maxW: 12 },
// { i: 'influencers_network_top', 			x: 0, y: 121, w: 12, h: 17, minH: 17, maxH: 17, minW: 8, maxW: 12 },
// { i: 'location_map', 						x: 0, y: 146, w: 12, h: 19, minH: 19, maxH: 19, minW: 6, maxW: 12 },
// { i: 'location_general_regions', 			x: 0, y: 165, w: 12, h: 17, minH: 17, maxH: 17, minW: 6, maxW: 12 },
// { i: 'location_general_states', 			x: 0, y: 183, w: 12, h: 17, minH: 17, maxH: 17, minW: 6, maxW: 12 }
const DEFAULT_LAYOUTS_TERM = {
	lg: [
		{ i: 'posts', x: 0, y: 0, w: 3, h: 4, minH: 4, maxH: 16.2, minW: 3, maxW: 12 },
		{ i: 'engagement', x: 0, y: 0, w: 3, h: 4, minH: 4, maxH: 16.2, minW: 3, maxW: 12 },
		{ i: 'sentiments', x: 0, y: 0, w: 3, h: 6, minH: 4, maxH: 16.2, minW: 3, maxW: 12 },
		{ i: 'overview_nowcasting_chart', x: 5, y: 0, w: 9, h: 14, minH: 14, maxH: 16.2, minW: 6, maxW: 12 },
		{ i: 'overview_top_comments_occurrences', x: 0, y: 15, w: 6, h: 16, minH: 16, maxH: 16, minW: 6, maxW: 6 },
		{ i: 'overview_word_clouds', x: 7, y: 16, w: 6, h: 16, minH: 16, maxH: 16, minW: 6, maxW: 6 },
		{ i: 'overview_gender_age_distribution', x: 0, y: 33, w: 12, h: 15, minH: 15, maxH: 15, minW: 12, maxW: 12 },
		{ i: 'overview_sensibility_by_time', x: 0, y: 50, w: 12, h: 18.9, minH: 18.9, maxH: 18.9, minW: 12, maxW: 12 },
		{ i: 'ai_ai_analysis', x: 0, y: 57, w: 12, h: 28, minH: 28, maxH: 28, minW: 6, maxW: 12 },
		{ i: 'ai_topic_analysis', x: 0, y: 78, w: 12, h: 20.6, minH: 20.6, maxH: 20.6, minW: 12, maxW: 12 },
		{ i: 'influencers_network_chart', x: 0, y: 95, w: 12, h: 28.6, minH: 28.6, maxH: 28.6, minW: 12, maxW: 12 },
		{ i: 'influencers_network_top', x: 0, y: 121, w: 12, h: 17, minH: 17, maxH: 17, minW: 12, maxW: 12 },
		{ i: 'location_map', x: 0, y: 146, w: 12, h: 19, minH: 19, maxH: 19, minW: 12, maxW: 12 },
		{ i: 'location_general_regions', x: 0, y: 165, w: 12, h: 17, minH: 17, maxH: 17, minW: 12, maxW: 12 },
		{ i: 'location_general_states', x: 0, y: 183, w: 12, h: 17, minH: 17, maxH: 17, minW: 12, maxW: 12 }
	],
	md: [
		{ i: 'posts', x: 0, y: 0, w: 3, h: 4, minH: 4, maxH: 16.2, minW: 3, maxW: 12 },
		{ i: 'engagement', x: 0, y: 0, w: 3, h: 4, minH: 4, maxH: 16.2, minW: 3, maxW: 12 },
		{ i: 'sentiments', x: 0, y: 0, w: 3, h: 6, minH: 4, maxH: 16.2, minW: 3, maxW: 12 },
		{ i: 'overview_nowcasting_chart', x: 5, y: 0, w: 9, h: 14, minH: 14, maxH: 16.2, minW: 6, maxW: 12 },
		{ i: 'overview_top_comments_occurrences', x: 0, y: 15, w: 6, h: 16, minH: 16, maxH: 16, minW: 6, maxW: 6 },
		{ i: 'overview_word_clouds', x: 7, y: 16, w: 6, h: 16, minH: 16, maxH: 16, minW: 6, maxW: 6 },
		{ i: 'overview_gender_age_distribution', x: 0, y: 33, w: 12, h: 15, minH: 15, maxH: 15, minW: 12, maxW: 12 },
		{ i: 'overview_sensibility_by_time', x: 0, y: 50, w: 12, h: 18.9, minH: 18.9, maxH: 18.9, minW: 12, maxW: 12 },
		{ i: 'ai_ai_analysis', x: 0, y: 57, w: 12, h: 28, minH: 28, maxH: 28, minW: 12, maxW: 12 },
		{ i: 'ai_topic_analysis', x: 0, y: 78, w: 12, h: 20.6, minH: 20.6, maxH: 20.6, minW: 12, maxW: 12 },
		{ i: 'influencers_network_chart', x: 0, y: 95, w: 12, h: 28.6, minH: 28.6, maxH: 28.6, minW: 12, maxW: 12 },
		{ i: 'influencers_network_top', x: 0, y: 121, w: 12, h: 17, minH: 17, maxH: 17, minW: 12, maxW: 12 },
		{ i: 'location_map', x: 0, y: 146, w: 12, h: 19, minH: 19, maxH: 19, minW: 6, maxW: 12 },
		{ i: 'location_general_regions', x: 0, y: 165, w: 12, h: 17, minH: 17, maxH: 17, minW: 12, maxW: 12 },
		{ i: 'location_general_states', x: 0, y: 183, w: 12, h: 17, minH: 17, maxH: 17, minW: 12, maxW: 12 }
	],
	sm: [
		{ i: 'posts', x: 0, y: 0, w: 3, h: 4, minH: 4, maxH: 16.2, minW: 3, maxW: 12 },
		{ i: 'engagement', x: 0, y: 0, w: 3, h: 4, minH: 4, maxH: 16.2, minW: 3, maxW: 12 },
		{ i: 'sentiments', x: 0, y: 0, w: 3, h: 6, minH: 4, maxH: 16.2, minW: 3, maxW: 12 },
		{ i: 'overview_nowcasting_chart', x: 5, y: 0, w: 9, h: 14, minH: 14, maxH: 16.2, minW: 6, maxW: 12 },
		{ i: 'overview_top_comments_occurrences', x: 0, y: 15, w: 6, h: 16, minH: 16, maxH: 16, minW: 6, maxW: 6 },
		{ i: 'overview_word_clouds', x: 7, y: 16, w: 6, h: 16, minH: 16, maxH: 16, minW: 6, maxW: 6 },
		{ i: 'overview_gender_age_distribution', x: 0, y: 33, w: 12, h: 15, minH: 15, maxH: 15, minW: 12, maxW: 12 },
		{ i: 'overview_sensibility_by_time', x: 0, y: 50, w: 12, h: 18.9, minH: 18.9, maxH: 18.9, minW: 12, maxW: 12 },
		{ i: 'ai_ai_analysis', x: 0, y: 57, w: 12, h: 28, minH: 28, maxH: 28, minW: 12, maxW: 12 },
		{ i: 'ai_topic_analysis', x: 0, y: 78, w: 12, h: 20.6, minH: 20.6, maxH: 20.6, minW: 12, maxW: 12 },
		{ i: 'influencers_network_chart', x: 0, y: 95, w: 12, h: 28.6, minH: 28.6, maxH: 28.6, minW: 12, maxW: 12 },
		{ i: 'influencers_network_top', x: 0, y: 121, w: 12, h: 17, minH: 17, maxH: 17, minW: 12, maxW: 12 },
		{ i: 'location_map', x: 0, y: 146, w: 12, h: 19, minH: 19, maxH: 19, minW: 6, maxW: 12 },
		{ i: 'location_general_regions', x: 0, y: 165, w: 12, h: 17, minH: 17, maxH: 17, minW: 12, maxW: 12 },
		{ i: 'location_general_states', x: 0, y: 183, w: 12, h: 17, minH: 17, maxH: 17, minW: 12, maxW: 12 }
	]
}

const DEFAULT_WIDGETS_TERM = [
	'posts',
	'engagement',
	'sentiments',
	'overview_nowcasting_chart',
	'overview_top_comments_occurrences',
	'overview_word_clouds'
]

// const DEFAULT_WIDGETS_TERM = [
// 	'overview_nowcasting_chart',
// 	'overview_top_comments_occurrences',
// 	'overview_word_clouds',
// 	'overview_gender_age_distribution',
// 	'overview_sensibility_by_time',
// 	'ai_ai_analysis',
// 	'ai_topic_analysis',
// 	'influencers_network_chart',
// 	'influencers_network_top',
// 	'location_map',
// 	'location_general_regions',
// 	'location_general_states'
// ]
