import React, { memo } from 'react'
import { UnlinkIcon } from 'utils/SystemIcons'
import DialogConfirmation from 'components/DialogConfirmation/DialogConfirmation'

const ConfirmDisconnect = ({ isOpen, onClose, onConfirm }) => {
	return (
		<DialogConfirmation
			open={isOpen}
			handleClose={onClose}
			handleConfirm={onConfirm}
			color={'#ff2b6b'}
			title={'Deseja realmente desconectar a conta?'}
			buttonText={'Desconectar'}
			icon={<UnlinkIcon className='fix-icon-margin-bottom-negative' size={25} color='#ff2b6b' style={{ marginRight: '0.5em' }} />}
		/>
	)
}

export default memo(ConfirmDisconnect)
