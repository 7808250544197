import React, { memo } from 'react'
import { Grid } from '@mui/material'
import SelectVox from 'components/SelectVox/SelectVox'

const mediaList = [
	{
		id: 1,
		label: 'Tudo',
		value: 'text or images or video'
	},
	{
		id: 2,
		label: 'Imagens',
		value: 'images'
	},
	{
		id: 3,
		label: 'Vídeos',
		value: 'videos'
	},
	{
		id: 4,
		label: 'Links',
		value: 'links'
	},
	{
		id: 5,
		label: 'Imagens, videos ou links sem texto',
		value: 'images or video or links not text'
	}
]

const MediaSelect = ({ initialValue, onChange }) => {
	const handleChange = (item) => {
		onChange(item.value)
	}

	return (
		<Grid style={{ marginTop: '1.5em', marginBottom: '1.5em' }} item xs={12}>
			<SelectVox label='Mostrar Posts que tenham:' items={mediaList} onChange={handleChange} initialValue={initialValue} fullWidth={true} />
		</Grid>
	)
}

export default memo(MediaSelect)
