import * as React from 'react'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
		fontSize: '1.2em',
		border: 0
	},
	[`&.${tableCellClasses.body}`]: {
		// fontSize: 14,
		backgroundColor: 'rgba(30, 30, 30, 0.4)',
		color: theme.palette.common.white,
		fontSize: '1.2em',
		borderColor: 'rgba(255,255,255, 0.2)'
	}
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: 'rgba(30, 30, 30, 0.4)',
		color: theme.palette.common.white
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0
	}
}))

const createData = (brand, posts, mvp, adjustedMedia) => {
	return { brand, posts, mvp, adjustedMedia }
}

const rows = [
	createData('Gerdau', '25.614', '33,92 %', 'R$ 931.534'),
	createData('Betano', '20.210', '26,77 %', 'R$ 877.013'),
	createData('Bmg', '12.265', '16,24 %', 'R$ 532.254'),
	createData('Autotruck', '11.013', '14,59 %', 'R$ 477.898'),
	createData('Betnacional placa', '19.563', '25,91 %', 'R$ 297.121'),
	createData('Betano placa', '18.803', '24,90 %', 'R$ 285.578'),
	createData('Supermercados BH', '6.446', '8,54 %', 'R$ 278.715'),
	createData('Superbet placa', '17.747', '23,51 %', 'R$ 269.547'),
	createData('Vilma', '10.337', '13,69 %', 'R$ 224.291'),
	createData('ABC construção', '11.287', '14,95 %', 'R$ 189.808'),
	createData('Multimarcas consorcios', '4.314', '5,71 %', 'R$ 187.189'),
	createData('Bet7k placa', '15.720', '20,82 %', 'R$ 138.766'),
	createData('You Saúde', '4.356', '5,77 %', 'R$ 104.022')
]

const TableSocialMedia = () => {
	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				marginBottom: '20em'
			}}
		>
			<TableContainer
				component={Paper}
				style={{
					backgroundColor: 'transparent',
					borderRadius: '11px',
					width: '80%'
				}}
			>
				<Table sx={{ minWidth: 700 }} aria-label='customized table'>
					<TableHead>
						<TableRow>
							<StyledTableCell>Marca</StyledTableCell>
							<StyledTableCell align='right'>Postagens</StyledTableCell>
							<StyledTableCell align='right'>MVP&nbsp;(%)</StyledTableCell>
							<StyledTableCell align='right'>Midia ajustada&nbsp;(R$)</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.map((row) => (
							<StyledTableRow key={row.brand}>
								<StyledTableCell component='th' scope='row'>
									{row.brand}
								</StyledTableCell>
								<StyledTableCell align='right'>{row.posts}</StyledTableCell>
								<StyledTableCell align='right'>{row.mvp}</StyledTableCell>
								<StyledTableCell align='right'>{row.adjustedMedia}</StyledTableCell>
							</StyledTableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	)
}

export default TableSocialMedia
