const colorsNetworks = ['rgba(186, 34, 59, 1)', 'rgba(0, 0, 0, 1)', 'rgba(230, 81, 44, 1)', 'rgba(43, 179, 174, 1)', 'rgba(44, 125, 230, 1)']

const slides = [
	{
		index: 1,
		text: 'Tempo de Tela em segundos durante o jogo',
		animation: {},
		animationBehavior: { speed: 1 },
		extraData: {
			values: [1826.83, 1799.83, 1076.87, 254.01, 187.16, 152.71, 151.5, 149.67, 117.67, 101.41, 73.54, 60.62, 25.42],
			colors: [
				'#f58231',
				'#e61919',
				'#000075',
				'#bcf60c',
				'#e6194b',
				'#3cb44b',
				'#ffe119',
				'#4363d8',
				'#008080',
				'#911eb4',
				'#46f0f0',
				'#f032e6',
				'#fabebe',
				'#e6beff',
				'#9a6324',
				'#fffac8',
				'#800000',
				'#aaffc3',
				'#808000',
				'#ffd8b1',
				'#808080',
				'#e3e1e1',
				'#000000'
			],
			labels: [
				'Placas Betano',
				'Placas Superbet',
				'Placas Betnacional',
				'Bmg',
				'Supermercados BH',
				'Betano',
				'Multimarcas',
				'Gerdau',
				'Placas Bet7k',
				'You saúde',
				'Abc da construção',
				'Vilma',
				'Auto Truck'
			]
		}
	},
	{
		index: 2,
		text: 'Betano',
		animation: {},
		animationBehavior: { speed: 1 },
		extraData: {
			insertions: 131,
			time: 152.71,
			value: 'R$ 789.378'
		}
	},
	{
		index: 3,
		text: 'Gerdau',
		animation: {},
		animationBehavior: { speed: 1 },
		extraData: {
			insertions: 76,
			time: 149.67,
			value: 'R$ 593.902'
		}
	},
	{
		index: 4,
		text: 'Bmg',
		animation: {},
		animationBehavior: { speed: 1 },
		extraData: {
			insertions: 97,
			time: 254.01,
			value: 'R$ 484.795'
		}
	},
	{
		index: 5,
		text: 'Supermercados BH',
		animation: {},
		animationBehavior: { speed: 1 },
		extraData: {
			insertions: 78,
			time: 187.16,
			value: 'R$ 187.570'
		}
	},
	{
		index: 6,
		text: 'Abc da construção',
		animation: {},
		animationBehavior: { speed: 1 },
		extraData: {
			insertions: 43,
			time: 73.54,
			value: 'R$ 128.219'
		}
	},
	{
		index: 7,
		text: 'Multimarcas',
		animation: {},
		animationBehavior: { speed: 1 },
		extraData: {
			insertions: 57,
			time: 151.5,
			value: 'R$ 101.359'
		}
	},
	{
		index: 8,
		text: 'Vilma',
		animation: {},
		animationBehavior: { speed: 1 },
		extraData: {
			insertions: 40,
			time: 60.62,
			value: 'R$ 96.516'
		}
	},
	{
		index: 9,
		text: 'You saúde',
		animation: {},
		animationBehavior: { speed: 1 },
		extraData: {
			insertions: 48,
			time: 101.41,
			value: 'R$ 68.460'
		}
	},
	{
		index: 10,
		text: 'Auto Truck',
		animation: {},
		animationBehavior: { speed: 1 },
		extraData: {
			insertions: 20,
			time: 25.42,
			value: 'R$ 42.843'
		}
	},
	{
		index: 11,
		text: 'Placas Betano',
		animation: {},
		animationBehavior: { speed: 1 },
		extraData: {
			insertions: 396,
			time: 1826.83,
			value: 'R$ 5.342.746'
		}
	},
	{
		index: 12,
		text: 'Placas Superbet',
		animation: {},
		animationBehavior: { speed: 1 },
		extraData: {
			insertions: 262,
			time: 1799.83,
			value: 'R$ 4.968.251'
		}
	},
	{
		index: 13,
		text: 'Placas Betnacional',
		animation: {},
		animationBehavior: { speed: 1 },
		extraData: {
			insertions: 183,
			time: 1076.87,
			value: 'R$ 3.275.551'
		}
	},
	{
		index: 14,
		text: 'Placas Bet7k',
		animation: {},
		animationBehavior: { speed: 1 },
		extraData: {
			insertions: 14,
			time: 117.67,
			value: 'R$ 286.217'
		}
	}
]

export { slides }
