import React, { memo, useEffect, useRef, useState } from 'react'
import anychart from 'anychart'
import { Divider, Grid } from '@mui/material'
import ComponentCard from 'components/ComponentCard/ComponentCard'
import { MapPinIcon } from 'utils/SystemIcons'
import { connect } from 'react-redux'
import InspectIntervalButton from 'graphs/components/InspectIntervalButton/InspectIntervalButton'
import TopMetricsLocation from './components/TopMetricsLocation/TopMetricsLocation'
import PieChart from 'graphs/PieChart/PieChart'
import Skeleton from 'react-loading-skeleton'
import LoadingBar from 'components/LoadingBar/LoadingBar'
import CardSelector from 'components/CardSelector/CardSelector'
import { useUpdateData } from 'hooks/ReactQueryHooks'
import BarChart from 'graphs/BarChart/BarChart'

const blue = 'rgb(29, 138, 248)'
const purple = 'rgb(172, 96, 247)'
const red = 'rgb(255, 43, 107)'
const lightGrey = 'rgb(220, 220, 220)'
const lightGreen = 'rgb(0, 222, 174)'
const pink = 'rgb(237, 40, 146)'

const getDataById = (id, data) => data.find((it) => it.id === id)

const COUNTRY_CODE_DEFAULT = 76

let isFirstTimeMetricsGender = true
let isFirstTimeMetricsSentiment = true
let isFirstTimeMetricsAge = true
let isFirstTimeMap = true

const getInspectFilters = (filters, stateCodes) => {
	if (['twitter', 'bluesky', 'facebook', 'instagram', 'tiktok', 'youtube'].includes(filters.sources)) {
		let newFilters = {
			...filters,
			advancedFilters: {
				...filters.advancedFilters,
				authorLocation: {
					...filters.advancedFilters.authorLocation,
					country: COUNTRY_CODE_DEFAULT,
					state: stateCodes
				}
			}
		}

		return newFilters
	} else {
		return filters
	}
}

const isDisabled = (sources) => {
	const hasBluesky = sources.includes('bluesky')
	const hasYoutube = sources.includes('youtube')
	const hasNews = sources.includes('news')

	const isDisabledComponent =
		(sources.length === 1 && (hasNews || hasYoutube || hasBluesky)) ||
		(sources.length === 2 && ((hasNews && hasYoutube) || (hasNews && hasBluesky) || (hasYoutube && hasBluesky))) ||
		(sources.length === 3 && hasNews && hasYoutube && hasBluesky)

	return isDisabledComponent
}

const LocationGraph = ({ cards, globalFiltersTermRedux, hideRightMenus }) => {
	const [isLoadingTopMetrics, setIsLoadingTopMetrics] = useState({ value: true, isFirstTime: true })
	const [selectedStates, setSelectedStates] = useState(null)
	const [canLoadTopMetrics, setCanLoadTopMetrics] = useState(false)
	const loadingBarRef = useRef()
	const [selectedCard, setSelectedCard] = useState(cards[0])
	const [fullScreenState, setFullScreen] = useState(false)

	useEffect(() => {
		isFirstTimeMetricsGender = true
		isFirstTimeMetricsSentiment = true
		isFirstTimeMetricsAge = true
		isFirstTimeMap = true
	}, [])

	useEffect(() => {
		//resetando o estado quando muda de source ou quando muda de cartao
		setSelectedStates(null)
	}, [globalFiltersTermRedux.sources, selectedCard])

	useEffect(() => {
		const locationState = globalFiltersTermRedux.advancedFilters.authorLocation
		if (locationState.state && locationState.state !== '') {
			setSelectedStates([locationState.state])
		} else {
			setSelectedStates(null)
		}
	}, [globalFiltersTermRedux.sources, globalFiltersTermRedux?.advancedFilters])

	const getTopMetricsIsLoading = (topMetricsIsLoading) => {
		setIsLoadingTopMetrics(topMetricsIsLoading)
	}

	const onCardChangeFilter = (newCard) => {
		setSelectedCard(newCard)
	}

	const buildLocationInstanceMap = (graphData, onSelectStateBuild) => {
		if (!graphData || isDisabled(globalFiltersTermRedux.sources)) {
			return
		}
		document.getElementById('container-map-location-vox').innerHTML = ''
		let data = graphData.location
		// let cardName = graphData.cardName //colocar titulo no gráfico
		const dataSet = anychart.data.set(data.map((it) => ({ ...it, value: it.posts })))
		//Create map
		let map = anychart.map()
		map.id('map-location' + Math.random())
		// map.title(cardName)
		map.geoData(anychart.maps['brazil'])
		//Map series
		let mapSeries = map.choropleth(dataSet)
		mapSeries.geoIdField('id')
		mapSeries.colorScale(anychart.scales.linearColor('#deebf7', '#3182bd'))
		mapSeries.hovered().fill('#cfcfcf')
		mapSeries.selected().fill('#addd8e')

		map.listen('pointsSelect', (e) => {
			const selectedStates = e.seriesStatus[0].points
			const formattedStates = data.filter((it) => selectedStates.find((ss) => ss.id === it.id))
			const stateCodes = formattedStates.map((it) => it.code)
			onSelectStateBuild(stateCodes)
		})
		//NEW
		mapSeries.labels(null)
		mapSeries.tooltip().useHtml(true)
		mapSeries.tooltip().title().useHtml(true)
		mapSeries.tooltip().titleFormat((serie) => {
			// let thisData = getDataById(serie.id, data)
			return serie.name + '<div style="font-size: 8px"> (segure ctrl para múltiplos valores)</div>'
		})
		mapSeries.tooltip().format((serie) => {
			let thisData = getDataById(serie.id, data)
			return (
				'<span style="font-size: 12px; color: #b7b7b7">Posts: </span>' +
				thisData.posts +
				'<br/>' +
				'<span style="font-size: 12px; color: #b7b7b7">Engajamento: </span>' +
				thisData.engagement
			)
		})
		//Map layout
		let colorRange = map.colorRange()
		colorRange.enabled(true)
		colorRange.colorLineSize(5)
		map.container('container-map-location-vox').draw()
	}

	const onSelectStates = (statesCodes) => {
		if (statesCodes.length === 0) {
			statesCodes = null
		}

		setSelectedStates(statesCodes)
	}

	const getGraphStatesBody = () => ({
		configGlobalFilters: {
			cardIds: selectedCard.id,
			cardType: cards[0].type.toUpperCase()
		},
		globalFilters: {
			...globalFiltersTermRedux,
			advancedFilters: {
				...globalFiltersTermRedux.advancedFilters,
				authorLocation: { ...globalFiltersTermRedux.advancedFilters.authorLocation, country: COUNTRY_CODE_DEFAULT }
			}
		}
	})

	const getMetricsBody = (locationView) => ({
		configGlobalFilters: {
			cardIds: selectedCard.id,
			cardType: cards[0].type.toUpperCase()
		},
		globalFilters: {
			...globalFiltersTermRedux,
			advancedFilters: {
				...globalFiltersTermRedux.advancedFilters,
				authorLocation: {
					...globalFiltersTermRedux.advancedFilters.authorLocation,
					country: COUNTRY_CODE_DEFAULT,
					state: selectedStates ? selectedStates?.join(',') : ''
				}
			}
		},
		componentProperties: {
			locationView
		}
	})

	let mapData = useUpdateData({
		url: '/location/get-location-graph-states',
		updateItemName: 'LocationMap' + selectedCard.id,
		refetchInterval: 600000,
		freshDataTime: 600000,
		isEnabled: true,
		queryName: 'useUpdateData',
		method: 'post',
		body: getGraphStatesBody()
	})

	let metricsGenderData = useUpdateData({
		url: '/location/get-location-state-metrics',
		updateItemName: 'LocationMapMetrics' + selectedCard.id,
		refetchInterval: 600000,
		freshDataTime: 600000,
		isEnabled: true,
		queryName: 'useUpdateData',
		method: 'post',
		body: getMetricsBody('gender')
	})

	let metricsSentimentData = useUpdateData({
		url: '/location/get-location-state-metrics',
		updateItemName: 'LocationMapMetrics' + selectedCard.id,
		refetchInterval: 600000,
		freshDataTime: 600000,
		isEnabled: true,
		queryName: 'useUpdateData',
		method: 'post',
		body: getMetricsBody('impact')
	})

	let metricsAgeData = useUpdateData({
		url: '/location/get-location-state-metrics',
		updateItemName: 'LocationMapMetrics' + selectedCard.id,
		refetchInterval: 600000,
		freshDataTime: 600000,
		isEnabled: true,
		queryName: 'useUpdateData',
		method: 'post',
		body: getMetricsBody('age')
	})

	useEffect(() => {
		if (mapData.isFetched && !mapData.isFetching) {
			buildLocationInstanceMap(mapData.data, onSelectStates)
		}
	}, [mapData.data, selectedCard, globalFiltersTermRedux.sources])

	useEffect(() => {
		if (isDisabled(globalFiltersTermRedux.sources)) {
			return
		}
		loadingBarRef?.current?.load(
			(isLoadingTopMetrics.value && !isLoadingTopMetrics.isFirstTime) ||
				(metricsSentimentData.isFetching && !isFirstTimeMetricsSentiment) ||
				(metricsGenderData.isFetching && !isFirstTimeMetricsGender) ||
				(metricsAgeData.isFetching && !isFirstTimeMetricsAge)
		)
	}, [isLoadingTopMetrics.value, metricsSentimentData.isFetching, metricsGenderData.isFetching, metricsAgeData.isFetching])

	useEffect(() => {
		if (mapData.isFetched && isFirstTimeMap && !isDisabled(globalFiltersTermRedux.sources)) {
			isFirstTimeMap = false
			setCanLoadTopMetrics(true)
		}
		if (metricsGenderData.isFetched && isFirstTimeMetricsGender && !isDisabled(globalFiltersTermRedux.sources)) {
			isFirstTimeMetricsGender = false
		}
		if (metricsSentimentData.isFetched && isFirstTimeMetricsSentiment && !isDisabled(globalFiltersTermRedux.sources)) {
			isFirstTimeMetricsSentiment = false
		}
		if (metricsAgeData.isFetched && isFirstTimeMetricsAge && !isDisabled(globalFiltersTermRedux.sources)) {
			isFirstTimeMetricsAge = false
		}
	}, [metricsGenderData.isFetched, metricsSentimentData.isFetched, mapData.isFetched, metricsAgeData.isFetched])

	const onChangeFullScreen = (newfullScreenState) => {
		setFullScreen(newfullScreenState)
	}

	return (
		<>
			<ComponentCard
				style={{ width: '100%' }}
				isLoading={mapData.isFetching && !isFirstTimeMap}
				title={{
					name: 'Mapa de Localidade',
					icon: (
						<MapPinIcon
							size='20'
							style={{
								color: '#1d8cf8',
								marginTop: '0.15em'
							}}
						/>
					)
					// ,description: 'adicionar teste'
				}}
				hideRightMenus={hideRightMenus}
				onChangeFullScreen={onChangeFullScreen}
			>
				{isDisabled(globalFiltersTermRedux.sources) ? (
					<Grid container direction='row' justifyContent='center' alignItems='center' style={{ paddingBottom: '5em' }}>
						<h2 id='span-location-map-error' style={{ fontFamily: 'Poppins', color: '#b0b0b0', fontWeight: 'normal' }}>
							Componente não disponível para a fonte escolhida.
						</h2>
					</Grid>
				) : (
					<>
						<Grid item xs={3}>
							<CardSelector cards={cards} onCardChange={onCardChangeFilter} selectedCard={selectedCard} />
						</Grid>
						<Grid container item>
							<Grid item xs={5} style={{ padding: '1em' }}>
								<Grid item xs={12}>
									{mapData.isFetching && isFirstTimeMap ? (
										<Skeleton height='600px' width='100%' style={{ borderRadius: '20px', lineHeight: 'inherit' }} />
									) : (
										<div id='container-map-location-vox' style={{ height: '600px' }} />
									)}
								</Grid>
								<Grid item xs={12} style={{ marginTop: '1em', justifyContent: 'space-around', display: 'flex' }}>
									{fullScreenState && (
										<InspectIntervalButton
											cards={cards}
											filters={getInspectFilters(globalFiltersTermRedux, selectedStates)}
											cardType={cards[0]?.type}
											showCloud={false}
											showOccurrences={false}
											showRadar={cards[0]?.type === 'term' && globalFiltersTermRedux.sources.includes('twitter')}
										/>
									)}
								</Grid>
							</Grid>
							<Grid item container xs={7} style={{ height: 'fit-content', padding: '1.5em' }}>
								<Grid item xs={4}>
									<PieChart
										height={220}
										width='100%'
										series={metricsSentimentData.data}
										colors={[blue, red, purple, lightGrey]}
										isLoading={metricsSentimentData.isFetching && isFirstTimeMetricsSentiment}
									/>
								</Grid>
								<Grid item xs={4}>
									<PieChart
										height={220}
										width='100%'
										series={metricsGenderData.data}
										colors={[blue, pink, lightGrey, lightGreen]}
										isLoading={metricsGenderData.isFetching && isFirstTimeMetricsGender}
									/>
								</Grid>
								<Grid item xs={4}>
									<BarChart
										series={metricsAgeData.data}
										height={220}
										width='100%'
										colors={['#008FFB', '#00E396', '#FEB019', '#FF4560']}
										labels={cards.map((card) => card.name)}
										isHorizontal={false}
										isStacked={false}
										isLoading={metricsAgeData.isFetching && isFirstTimeMetricsAge}
									/>
								</Grid>
								<div style={{ marginTop: '1em', marginBottom: '0.5em', width: '100%' }}>
									<LoadingBar ref={loadingBarRef} />
									<Divider style={{ background: '#f0f0f0', height: '1px', width: '100%' }} />
								</div>
								<Grid item xs={12}>
									<TopMetricsLocation
										card={selectedCard}
										selectedStates={selectedStates}
										canLoad={canLoadTopMetrics}
										getIsLoading={getTopMetricsIsLoading}
									/>
								</Grid>
							</Grid>
						</Grid>
					</>
				)}
			</ComponentCard>
		</>
	)
}

const mapStateToProps = ({ store }) => ({
	globalFiltersTermRedux: store.global.filters
})

export default connect(mapStateToProps)(memo(LocationGraph))
