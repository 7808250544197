const recordData = [
	[0, 'Betano P.', 5342746, 'placas'],
	[1, 'Superbet P.', 4968251, 'placas'],
	[2, 'Betnacional P.', 3275551, 'placas'],
	[3, 'Bet7k P.', 286217, 'placas'],
	[4, 'Betano', 789378, 'uniforme'],
	[5, 'Vilma', 96516, 'uniforme'],
	[6, 'Auto Truck', 42843, 'uniforme'],
	[7, 'You saúde', 68460, 'uniforme'],
	[8, 'Multimarcas', 101359, 'uniforme'],
	[9, 'Gerdau', 593902, 'uniforme'],
	[10, 'Bmg', 484795, 'uniforme'],
	[11, 'Supermercados BH', 187570, 'uniforme'],
	[12, 'ABC', 128219, 'uniforme']
]

const slides = [
	{
		//configartion slide
		animation: {
			data: {
				series: [
					{ name: 'index', type: 'measure' },
					{ name: 'Marcas', type: 'dimension' },
					{ name: 'Share of Voice', type: 'measure' },
					{ name: 'Tipo', type: 'dimension' }
				],
				records: recordData
			},
			style: {
				backgroundColor: 'rgba(0,0,0,0)',
				borderColor: 'rgba(0,0,0,0)',
				title: { backgroundColor: 'rgba(0,0,0,0)', color: 'rgba(255,255,255,0.5)' },
				plot: {
					backgroundColor: 'rgba(0,0,0,0)',
					xAxis: {
						interlacing: { color: 'rgba(0,0,0,0.1)' }
					},
					yAxis: {
						interlacing: { color: 'rgba(0,0,0,0.1)' }
					},
					marker: {
						colorPalette: '#F9C80E',
						rectangleSpacing: '2em'
					}
				},
				legend: { backgroundColor: 'rgba(0,0,0,0)' }
			},
			config: {
				channels: {
					x: { set: ['Share of Voice'] },
					y: { set: null },
					label: 'Share of Voice',
					color: null
				},
				coordSystem: 'polar',
				geometry: 'rectangle',
				align: 'none',
				title: 'Share of Voice'
			}
		},
		animationBehavior: { speed: 4 },
		extraData: null
	},
	{
		index: 1,
		text: 'Nossa abordagem compara a visibilidade das placas publicitárias no estádio com os anúncios presentes nos uniformes durante a transmissão na TV. Os resultados revelam discrepâncias significativas, destacando o valor relativo de cada exposição.',
		animation: {
			config: {
				channels: {
					x: { set: ['Share of Voice'] },
					y: { attach: ['Tipo'], set: ['Tipo'] },
					label: 'Share of Voice',
					color: 'Tipo'
				},
				coordSystem: 'polar',
				geometry: 'rectangle',
				align: 'none',
				title: null,
				sort: 'byValue'
			},
			style: {
				backgroundColor: 'rgba(0,0,0,0)',
				borderColor: 'rgba(0,0,0,0)',
				title: { backgroundColor: 'rgba(0,0,0,0)' },
				plot: {
					marker: {
						colorPalette: '#ff8d29 #1d8cf8 ',
						rectangleSpacing: '1em'
					}
				},
				legend: { backgroundColor: 'rgba(0,0,0,0)' }
			}
		},
		animationBehavior: { speed: 1.6 },
		extraData: null
	},
	{
		index: 2,
		text: 'No comparativo entre placas e logos no uniforme, a Gerdau se destacou significativamente, com sua marca no ombro da camisa. Já a Bet7K, focada exclusivamente em placas luminosas, não utilizou placas estáticas. As placas posicionadas ao lado do gol foram um diferencial importante nesta partida.',
		animation: {
			config: {
				channels: {
					x: { set: ['Share of Voice'] },
					y: { set: ['Marcas'] },
					label: 'Share of Voice',
					color: 'Marcas'
				},
				coordSystem: 'cartesian',
				geometry: 'rectangle',
				align: 'none',
				title: null,
				sort: 'byValue'
			},
			style: {
				backgroundColor: 'rgba(0,0,0,0)',
				borderColor: 'rgba(0,0,0,0)',
				title: { backgroundColor: 'rgba(0,0,0,0)' },
				plot: {
					marker: {
						colorPalette: '#F86624 #fc031c #2929ff #8ac926 #ff8d29 #43BCCD #000000 #db5385 #b06cf0 #919191 #d62b2e #ffba24 #e3e1e1 ',
						rectangleSpacing: '1em'
					}
				},
				legend: { backgroundColor: 'rgba(0,0,0,0)' }
			}
		},
		animationBehavior: { speed: 1.6 },
		extraData: null
	}
]

export { slides }
