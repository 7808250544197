import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { Grid } from '@mui/material'

const GraphSkeletonLoad = () => (
	<div style={{ marginBottom: '2em', padding: '0.5em' }}>
		<Grid container style={{ justifyContent: 'flex-end' }}>
			<Grid item xs>
				<Skeleton height='1.5em' style={{ borderRadius: '25px' }} />
			</Grid>
		</Grid>
		<Grid
			container
			style={{
				justifyContent: 'flex-end',
				marginTop: '2em',
				marginBottom: '1em'
			}}
		>
			<Grid item xs>
				<Skeleton height='1.5em' style={{ borderRadius: '25px' }} />
			</Grid>
		</Grid>
		<Grid container>
			<Grid item xs>
				<Skeleton height='26.25em' style={{ borderRadius: '25px' }} />
			</Grid>
		</Grid>
	</div>
)

export default GraphSkeletonLoad
