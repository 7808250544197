import React, { useState, useEffect } from 'react'
import MetricsFacebookDashboardShareable from './MetricsFacebookDashboardShareable/MetricsFacebookDashboardShareable'
import MetricsInstagramDashboardShareable from './MetricsInstagramDashboardShareable/MetricsInstagramDashboardShareable'
import MetricsMetaAdsDashboardShareable from './MetricsMetaAdsDashboardShareable/MetricsMetaAdsDashboardShareable'
import SkeletonLoadDashboardShareable from 'components/DashboardShareable/components/SkeletonLoadDashboardShareable/SkeletonLoadDashboardShareable'
import api from 'service/service'
import { Grid } from '@mui/material'

const DashboardShareableAccountManager = ({
	currentLayout,
	handleOnLayoutChange,
	editMode,
	addWidgetMode,
	toggleAddWidgetMode,
	widgetsOnScreen,
	handleWidgetsOnScreen,
	handleRemoveWidget,
	cardList,
	globalFilters,
	cardType
}) => {
	const [loadingMetrics, setLoadingMetrics] = useState(true)
	const [metrics, setMetrics] = useState([])

	const loadMetrics = () => {
		setLoadingMetrics(true)
		const filters = globalFilters
		const configFilters = {
			cardIds: cardList.map((it) => it.id),
			cardType
		}
		api.post('/dashboard-shareable/get-metrics', {
			filters,
			configFilters
		})
			.then((res) => {
				setMetrics(res.data)
			})
			.finally(() => setLoadingMetrics(false))
	}

	useEffect(() => {
		loadMetrics()
	}, [cardList, globalFilters])

	return (
		<>
			{loadingMetrics ? (
				<Grid item xs={12} style={{ marginLeft: '0.5em', marginRight: '1.6em', marginTop: '1em' }}>
					<SkeletonLoadDashboardShareable />
				</Grid>
			) : (
				globalFilters?.sources?.length === 1 && (
					<>
						{globalFilters?.sources?.includes('facebook') && (
							<MetricsFacebookDashboardShareable
								data={metrics?.facebook}
								layout={currentLayout?.layouts?.facebook}
								onLayoutChange={handleOnLayoutChange}
								editMode={editMode}
								addWidgetMode={addWidgetMode}
								toggleAddWidgetMode={toggleAddWidgetMode}
								widgetsOnScreen={widgetsOnScreen.facebook}
								handleWidgetsOnScreen={handleWidgetsOnScreen}
								handleRemoveWidget={handleRemoveWidget}
								cards={cardList}
								descriptions={metrics?.facebook?.widgetsDescriptions}
							/>
						)}
						{globalFilters?.sources?.includes('instagram') && (
							<MetricsInstagramDashboardShareable
								data={metrics?.instagram}
								layout={currentLayout?.layouts?.instagram}
								onLayoutChange={handleOnLayoutChange}
								editMode={editMode}
								addWidgetMode={addWidgetMode}
								toggleAddWidgetMode={toggleAddWidgetMode}
								widgetsOnScreen={widgetsOnScreen.instagram}
								handleWidgetsOnScreen={handleWidgetsOnScreen}
								handleRemoveWidget={handleRemoveWidget}
								cards={cardList}
								descriptions={metrics?.instagram?.widgetsDescriptions}
							/>
						)}
						{globalFilters?.sources?.includes('metaAds') && (
							<MetricsMetaAdsDashboardShareable
								data={metrics?.metaAds}
								layout={currentLayout?.layouts?.metaAds}
								onLayoutChange={handleOnLayoutChange}
								editMode={editMode}
								addWidgetMode={addWidgetMode}
								toggleAddWidgetMode={toggleAddWidgetMode}
								widgetsOnScreen={widgetsOnScreen.metaAds}
								handleWidgetsOnScreen={handleWidgetsOnScreen}
								handleRemoveWidget={handleRemoveWidget}
								cards={cardList}
								descriptions={metrics?.metaAds?.widgetsDescriptions}
							/>
						)}
					</>
				)
			)}
		</>
	)
}

export default DashboardShareableAccountManager
