import React from 'react'

const SubTitle = ({ subTitle }) => {
	return (
		<div>
			<div
				style={{
					zIndex: '0',
					height: '30vh',
					marginTop: '10vh',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center'
				}}
			>
				<div style={{ fontFamily: 'MontSerrat', color: 'white', fontWeight: '200' }}>
					<div style={{ fontSize: '2.28vw', display: 'flex', justifyContent: 'center' }}>{subTitle}</div>
				</div>
			</div>
		</div>
	)
}

export default SubTitle
