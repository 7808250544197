import React, { useState, useEffect, useRef, memo } from 'react'
import LoadingBar from 'components/LoadingBar/LoadingBar'
import { Grid, Card } from '@mui/material'
import GraphTitle from 'components/GraphTitle/GraphTitle'
import MiniCardSentimentComp from '../MiniCardSentimentComp/MiniCardSentimentComp'
import MiniCardSkeletonLoad from 'components/SkeletonLoads/MiniCardSkeletonLoad/MiniCardSkeletonLoad'

const MiniCardSentiment = ({ id, data, title, loading = false, isWidget = false }) => {
	const [hardLoading, setHardLoading] = useState(true)
	const loadingBarRef = useRef()
	const isMounted = useRef(true)

	useEffect(() => {
		isMounted.current = true

		if (isMounted.current) {
			loadingBarRef?.current?.load(loading && !hardLoading)
		}

		if (!loading) {
			setHardLoading(false)
		}

		return () => {
			isMounted.current = false
		}
	}, [loading])

	return (
		<Grid item xs={12}>
			<Card style={{ borderRadius: '20px', width: '100%', height: '100%', border: isWidget ? '1px solid rgba(154, 154, 154, 0.2)' : 'none' }}>
				<Grid container spacing={3} style={{ background: '#fbfbfb', height: '70px', borderBottom: '1px solid #f5f5f5' }}>
					<Grid item xs={12} style={{ marginTop: '11px' }}>
						<GraphTitle id={id} title={title} titleCentered={true} />
					</Grid>
				</Grid>
				<div>
					<LoadingBar ref={loadingBarRef} />
				</div>
				{hardLoading && <MiniCardSkeletonLoad />}
				{!hardLoading && <MiniCardSentimentComp data={data} title='sentimentos' loading={loading} />}
			</Card>
		</Grid>
	)
}

export default memo(MiniCardSentiment)
