import React from 'react'

const CommentSection = ({ title, comment }) => {
	return (
		<div>
			<div
				style={{
					zIndex: '0',
					marginBottom: '30vh',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center'
				}}
			>
				<div className='comment-container'>
					<div style={{ fontFamily: 'Poppins', fontSize: '4em', color: '#fff', marginBottom: '0.8em' }}>{title}</div>
					<div style={{ fontFamily: 'Poppins', fontSize: '2.5em', color: '#fff', fontWeight: '200' }}>{comment}</div>
				</div>
			</div>
		</div>
	)
}

export default CommentSection
