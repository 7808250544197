import image from 'assets/img/mike.jpg'
import HashtagImage from 'assets/img/Hashtag.jpg'
import { setLegendColorDictonary, getLegendColorById } from 'utils/graphFunctions'

let rankDictionary = {}
let colorDictionary

const formatGraphIndividual = (data) => {
	let nodes = []
	let edges = []
	let sankeyNodes = []
	let sankeyEdges = []
	let sankeyCount = 0

	const cardData = data[0].data !== null ? data[0].data : 0

	let node = {
		id: 0,
		label: data[0]?.info?.name,
		color: '#4287f5',
		value: 1,
		shape: 'dot',
		font: {
			color: '#4287f5'
		}
	}

	nodes.push(node)
	sankeyNodes.push(node)

	cardData.forEach((networkItem, index) => {
		const nodeValue = networkItem?.impactMetric == 0 ? 1.2 : networkItem?.impactMetric

		let node = {
			id: index + 1,
			label: networkItem?.key,
			borderWidth: 2,
			color: '#4287f5',
			value: nodeValue,
			shape: 'circularImage',
			image: networkItem?.author?.profileImageUrl === 'hashtag' ? HashtagImage : networkItem?.author.profileImageUrl || image,
			font: {
				color: '#4287f5',
				face: 'Poppins',
				size: 5
			},
			networkItem
		}
		let edge = {
			scaling: {
				min: 1,
				max: 15,
				label: {
					enabled: true,
					min: 14,
					max: 30,
					maxVisible: 30,
					drawThreshold: 5
				}
			},
			from: 0,
			to: index + 1,
			length: 200,
			value: nodeValue
		}
		nodes.push(node)
		edges.push(edge)

		if (sankeyCount < 12) {
			sankeyNodes.push(node)
			sankeyEdges.push(edge)
		}

		sankeyCount++
	})

	let connectedNodes = []
	for (let index = 1; index < nodes.length; index++) {
		connectedNodes.push(nodes[index])
	}

	connectedNodes = sortByKey(connectedNodes, 'value')
	connectedNodes.forEach((node, index) => {
		let key = node.id.toString()
		rankDictionary[key] = index + 1
	})

	const graphData = { nodes: nodes, edges: edges }
	const sankeyData = formatSankeyDiagramComp({
		nodes: sankeyNodes,
		edges: sankeyEdges
	})

	return { graphData, sankeyData }
}

const formatGraphComparative = (data, cardIds) => {
	let graphNodes = []
	let graphEdges = []
	let sankeyNodes = []
	let sankeyEdges = []
	let colors = []

	let colorFillDictionary = setLegendColorDictonary(cardIds)

	//Cria grafo completo inicial de elementos a serem comparados
	for (let index = 0; index < data.length; index++) {
		colors.push([data[index]?.info?.name, getLegendColorById(data[index]?.info?.id, colorFillDictionary)])

		let node = {
			id: index,
			label: data[index]?.info?.name,
			color: getLegendColorById(data[index]?.info?.id, colorFillDictionary),
			borderWidth: 2,
			value: 1,
			shape: 'dot',
			font: {
				color: getLegendColorById(data[index]?.info?.id, colorFillDictionary),
				face: 'Poppins',
				size: 20
			},
			networkItem: []
		}

		graphNodes.push(node)
		sankeyNodes.push(node)
	}

	colorDictionary = colors

	for (let i = 0; i < data.length; i++) {
		for (let x = 0; x < data.length; x++) {
			if (x !== i) {
				graphEdges.push({ from: i, to: x, length: 800 })
			}
		}
	}

	let lastIndex = data.length - 1

	for (let indexCand = 0; indexCand < data.length; indexCand++) {
		let cardData = data[indexCand]?.data

		if (cardData?.length > 0) {
			let idUser
			let offset = 0
			let sankeyCount = 0

			for (let indexCard = 0; indexCard < cardData.length; indexCard++) {
				const itemData = cardData[indexCard]
				let isNewNode = true
				for (let indexNodes = data.length; indexNodes < graphNodes.length; indexNodes++) {
					if (itemData?.key === graphNodes[indexNodes]?.label) {
						let globalScore = graphNodes[indexNodes]?.value + itemData?.impactMetric

						let edge = {
							from: indexNodes,
							to: indexCand,
							value: itemData?.impactMetric
						}

						graphNodes[indexNodes].value = globalScore
						graphNodes[indexNodes].networkItem = itemData

						graphEdges.push(edge)

						if (sankeyCount < 12 && indexNodes < sankeyNodes.length) {
							sankeyNodes[indexNodes].value = globalScore
							sankeyNodes[indexNodes].networkItem = itemData
							sankeyEdges.push(edge)
						}

						isNewNode = false
						offset++
					}
				}

				idUser = lastIndex + indexCard + 1 - offset

				if (isNewNode) {
					let node = {
						id: idUser,
						label: itemData?.key,
						borderWidth: 2,
						color: '#4287f5',
						value: itemData?.impactMetric,
						shape: 'circularImage',
						image: itemData?.author?.profileImageUrl === 'hashtag' ? HashtagImage : itemData?.author?.profileImageUrl || image,
						font: {
							color: '#4287f5',
							face: 'Poppins',
							size: 5
						},
						networkItem: itemData
					}

					let edge = {
						scaling: {
							min: 1,
							max: 15,
							label: {
								enabled: true,
								min: 14,
								max: 30,
								maxVisible: 30,
								drawThreshold: 5
							}
						},
						from: indexCand,
						to: idUser,
						length: 200,
						value: itemData?.impactMetric / 2
					}

					graphNodes.push(node)
					graphEdges.push(edge)

					if (sankeyCount < 12) {
						sankeyNodes.push(node)
						sankeyEdges.push(edge)
					}
				}
				sankeyCount++
			}

			lastIndex = idUser
		}
	}

	let connectedNodes = []
	for (let index = data.length; index < graphNodes.length; index++) {
		connectedNodes.push(graphNodes[index])
	}

	connectedNodes = sortByKey(connectedNodes, 'value')
	connectedNodes.forEach((node, index) => {
		let key = node.id.toString()
		rankDictionary[key] = index + 1
	})

	const graphData = { nodes: graphNodes, edges: graphEdges }
	const sankeyData = formatSankeyDiagramComp({
		nodes: sankeyNodes,
		edges: sankeyEdges
	})

	return { graphData, sankeyData }
}

const formatSankeyDiagramComp = (data) => {
	let dictonary = {}
	let labels = []
	let values = []
	let ids = []
	let sources = []
	let targets = []
	let edgeValues = []

	//varrer os nos originais criar dicionario pela pos
	data.nodes.forEach((node, index) => {
		let key = node.id.toString()
		dictonary[key] = index
		labels.push(node.label)
		values.push(node.value)
		ids.push(node.id)
	})
	//inverter do edges original se from > to
	//traduzir do dicionario
	data.edges.forEach((edge) => {
		if (edge.from > edge.to) {
			sources.push(dictonary[edge.to])
			targets.push(dictonary[edge.from])
		} else {
			sources.push(dictonary[edge.from])
			targets.push(dictonary[edge.to])
		}
		edgeValues.push(edge.value)
	})

	let sankeyData = [
		{
			type: 'sankey',
			orientation: 'h',
			arrangement: 'fixed',
			domain: {
				x: [0, 1],
				y: [0, 1]
			},
			node: {
				pad: 20,
				thickness: 20,
				line: {
					color: 'black',
					width: 0.5
				},
				label: labels,
				value: values,
				customdata: ids
			},
			link: {
				source: sources,
				target: targets,
				value: edgeValues
			}
		}
	]

	return sankeyData
}

const sortByKey = (array, key) =>
	array.sort(function (a, b) {
		let x = a[key]
		let y = b[key]
		return x > y ? -1 : x < y ? 1 : 0
	})

const getColorDictionary = () => colorDictionary
const getRankDictionary = () => rankDictionary

export { formatGraphIndividual, formatGraphComparative, getColorDictionary, getRankDictionary }
