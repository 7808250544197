import React, { useState } from 'react'
import moment from 'moment-timezone'
import { Grid, Tooltip, IconButton } from '@mui/material'
import constantsVox from 'constants-vox'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import { CloseIcon, LinkShareIcon, ExternalLinkIcon } from 'utils/SystemIcons'
import api from 'service/service'
import { verifyServerResponseCanShowToast } from 'utils/generalFunctions'

const formatTextContent = (text) => {
	const limit = 20
	if (text.length > limit) {
		return text.substring(0, limit) + '...'
	} else {
		return text
	}
}

const SHARING_DASHBOARD_WEB_PATH = window.location.origin + constantsVox.SHARING.DASHBOARD.WEB_PATH

const handleClickOnCopyLink = async (sharingLinkToken) => {
	const link = SHARING_DASHBOARD_WEB_PATH + sharingLinkToken
	try {
		await navigator.clipboard.writeText(link)
		sendMessageToast('Link copiado para a área de transferência', constantsVox.TOAST.TYPES.SUCCESS)
	} catch (err) {
		sendMessageToast('Falha ao copiar o texto', constantsVox.TOAST.TYPES.ERROR)
	}
}

const SharingDashboardHistoryItem = ({ item, reload }) => {
	const [submitting, setSubmitting] = useState(false)

	const handleClickDeleteSharingDashboard = (sharingDashboardId) => {
		setSubmitting(true)
		api.post('/dashboard-shareable/sharing/delete-shared', { sharingDashboardId })
			.then((response) => {
				if (response?.data?.toast) {
					sendMessageToast(response?.data?.toast?.message, response?.data?.toast?.type)
					reload(false, true)
				}
			})
			.catch((error) => {
				if (verifyServerResponseCanShowToast(error)) {
					sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
				}
			})
			.finally(() => setSubmitting(false))
	}

	const handleOpenInNewTab = async (sharingLinkToken) => {
		const link = SHARING_DASHBOARD_WEB_PATH + sharingLinkToken
		window.open(link, '_blank')
	}

	return (
		<Grid
			container
			justifyContent='flex-start'
			alignItems='flex-start'
			style={{
				padding: '0.5em',
				paddingLeft: '3em',
				width: '100%',
				color: 'rgba(100,100,100, 0.9)',
				fontFamily: 'Poppins',
				fontSize: '13px',
				marginTop: '2em',
				alignItems: 'center',
				backgroundColor: 'white',
				borderRadius: '100px'
			}}
		>
			<Grid container style={{ alignItems: 'center' }}>
				<Grid item xs={1} container justifyContent='flex-start' alignItems='center'>
					<Tooltip title='Abrir em uma nova aba'>
						<IconButton
							onClick={() => handleOpenInNewTab(item.sharing_link_token)}
							color='primary'
							component='span'
							disabled={submitting}
						>
							<ExternalLinkIcon size={25} color='#1d8cf8' />
						</IconButton>
					</Tooltip>
				</Grid>
				<Grid item xs={3} style={{ cursor: 'pointer' }}>
					<Tooltip title={item.name}>Nome: {formatTextContent(item.name)}</Tooltip>
				</Grid>

				<Grid item xs={3}>
					Criado em: {moment(item.created_at, 'YYYY-MM-DD').format('DD/MM/YYYY')}
				</Grid>

				<Grid item xs={3}>
					Expira em: {moment(item.expire_date, 'YYYY-MM-DD').format('DD/MM/YYYY')}
				</Grid>

				{/* <Grid item xs={3}>
					Compartilhamento {item.active ? 'Ativado' : 'Desativado'}
				</Grid> */}

				<Grid item xs={1}>
					<Tooltip title={'Copiar Link'}>
						<IconButton onClick={() => handleClickOnCopyLink(item.sharing_link_token)} style={{ padding: '0.5em' }} disabled={submitting}>
							<LinkShareIcon size={25} color='#1d8cf8' />
						</IconButton>
					</Tooltip>
				</Grid>
				<Grid item xs={1} container justifyContent='center' alignItems='center'>
					<Tooltip title='Excluir dashboard compartilhável'>
						<IconButton onClick={() => handleClickDeleteSharingDashboard(item.id)} color='primary' component='span' disabled={submitting}>
							<CloseIcon size={25} color='#1d8cf8' />
						</IconButton>
					</Tooltip>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default SharingDashboardHistoryItem
