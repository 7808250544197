import * as React from 'react'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
		fontSize: '1.2em',
		border: 0
	},
	[`&.${tableCellClasses.body}`]: {
		// fontSize: 14,
		backgroundColor: 'rgba(30, 30, 30, 0.4)',
		color: theme.palette.common.white,
		fontSize: '1.2em',
		borderColor: 'rgba(255,255,255, 0.2)'
	}
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: 'rgba(30, 30, 30, 0.4)',
		color: theme.palette.common.white
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0
	}
}))

const createData = (brand, mediaFull, mvp, adjustedMedia) => {
	return { brand, mediaFull, mvp, adjustedMedia }
}

const rows = [
	createData('Betano placa', 'R$ 19.995.308', '26,72 %', 'R$ 5.342.746'),
	createData('Superbet placa', 'R$ 19.699.649', '25,22 %', 'R$ 4.968.251'),
	createData('Betnacional placa', 'R$ 11.782.558', '27,80 %', 'R$ 3.275.551'),
	createData('Betano', 'R$ 2.748.533', '28,72 %', 'R$ 789.378'),
	createData('Gerdau', 'R$ 1.631.599', '36,40 %', 'R$ 593.902'),
	createData('Bmg', 'R$ 2.781.384', '17,43 %', 'R$ 484.795'),
	createData('Bet7k placa', 'R$ 1.281.189', '22,34 %', 'R$ 286.217'),
	createData('Supermercados BH', 'R$ 2.047.712', '9,16 %', 'R$ 187.570'),
	createData('ABC construção', 'R$ 799.374', '16,04 %', 'R$ 128.219'),
	createData('Multimarcas consorcios', 'R$ 1.653.500', '6,13 %', 'R$ 101.359'),
	createData('Vilma', 'R$ 657.020', '14,69 %', 'R$ 96.516'),
	createData('You Saúde', 'R$ 1.105.983', '6,19 %', 'R$ 68.460'),
	createData('Autotruck', 'R$ 273.758', '15,65 %', 'R$ 42.843')
]

const CustomTable = () => {
	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				marginBottom: '20em'
			}}
		>
			<TableContainer
				component={Paper}
				style={{
					backgroundColor: 'transparent',
					borderRadius: '11px',
					width: '80%'
				}}
			>
				<Table sx={{ minWidth: 700 }} aria-label='customized table'>
					<TableHead>
						<TableRow>
							<StyledTableCell>Marca</StyledTableCell>
							<StyledTableCell align='right'>Media full Globo&nbsp;(R$)</StyledTableCell>
							<StyledTableCell align='right'>MVP&nbsp;(%)</StyledTableCell>
							<StyledTableCell align='right'>Midia ajustada&nbsp;(R$)</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.map((row) => (
							<StyledTableRow key={row.brand}>
								<StyledTableCell component='th' scope='row'>
									{row.brand}
								</StyledTableCell>
								<StyledTableCell align='right'>{row.mediaFull}</StyledTableCell>
								<StyledTableCell align='right'>{row.mvp}</StyledTableCell>
								<StyledTableCell align='right'>{row.adjustedMedia}</StyledTableCell>
							</StyledTableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	)
}

export default CustomTable
