import React, { memo, useState } from 'react'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import api from 'service/service'
import { PauseIcon, PlayIcon } from 'utils/SystemIcons'
import { verifyServerResponseCanShowToast } from 'utils/generalFunctions'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import DialogConfirmation from 'components/DialogConfirmation/DialogConfirmation'

const verifyUserType = (user) => {
	//essa função tá ruim, mas é como tá funcionando hj em dia
	if (user.proceedAsAdminId) {
		return { user: user.proceedAsAdminId }
	} else if (isEmpty(user.subUser)) {
		return { user: user.id_usuario }
	} else {
		return { subUser: user.subUser.id }
	}
}

const ActivatePauseAccountsDialog = ({ cardId, operation, close, color, onSuccess, user }) => {
	const [submitting, setSubmitting] = useState(false)
	const activatePauseAccounts = (event) => {
		event.preventDefault()
		if (!operation) {
			return
		}
		const userOrSubUserId = verifyUserType(user)

		setSubmitting(true)
		api.post('/card-group/monitoring/activate-pause-accounts-by-card-group-id', {
			cardId,
			isActivateAll: operation === 'activate',
			userOrSubUserId
		})
			.then((response) => {
				onSuccess()
				if (response?.data?.toast) {
					sendMessageToast(response?.data?.toast?.message, response?.data?.toast?.type)
				}
			})
			.catch((error) => {
				if (verifyServerResponseCanShowToast(error)) {
					sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
				}
			})
			.finally(() => {
				setSubmitting(false)
				close()
			})
	}

	return (
		<DialogConfirmation
			open={!!operation}
			handleClose={close}
			handleConfirm={activatePauseAccounts}
			color={color}
			title={`Deseja realmente ${operation === 'pause' ? 'pausar' : 'ativar'} todas as contas deste cartão?`}
			buttonText={`${operation === 'pause' ? 'PAUSAR' : 'ATIVAR'}`}
			icon={
				operation === 'pause' ? (
					<PauseIcon className='fix-icon-margin-bottom-negative' size={25} color={color} />
				) : (
					<PlayIcon className='fix-icon-margin-bottom-negative' size={25} color={color} />
				)
			}
		/>
	)
}

const mapStateToProps = ({ store }) => ({
	user: store?.user
})

export default connect(mapStateToProps)(memo(ActivatePauseAccountsDialog))
