import { Card, Grid } from '@mui/material'
import React, { memo, useEffect, useRef, useState } from 'react'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import FullScreenButton from 'components/FullScreenButton/FullScreenButton'
import LoadingBar from 'components/LoadingBar/LoadingBar'
import GraphTitle from 'components/GraphTitle/GraphTitle'

const ComponentCard = ({
	error,
	children,
	hasFullScreen = true,
	hasLoadingBar = true,
	title = { name: null, icon: null, description: null },
	isLoading,
	style,
	onChangeFullScreen = null,
	hideRightMenus = false
}) => {
	const handle = useFullScreenHandle()
	const [fullScreenState, setFullScreenState] = useState(false)
	const loadingBarRef = useRef()

	useEffect(() => {
		loadingBarRef?.current?.load(isLoading)
	}, [isLoading])

	useEffect(() => {
		onChangeFullScreen && onChangeFullScreen(fullScreenState)
	}, [fullScreenState])

	return (
		<Grid item xs={12} style={style}>
			<FullScreen handle={handle} onChange={() => setFullScreenState(!fullScreenState)}>
				<Card style={{ height: '100%', width: '100%', borderRadius: '20px' }}>
					{title && title.name && (
						<Grid container spacing={3} style={{ background: '#fbfbfb', height: '70px', borderBottom: '1px solid #f5f5f5' }}>
							<Grid item xs={6}>
								<GraphTitle id={`span-${title.name}`} title={title.name} icon={title.icon} description={title.description} />
							</Grid>
							{!hideRightMenus && (
								<Grid item xs={6} container justifyContent='flex-end'>
									{hasFullScreen && <FullScreenButton fullScreenState={fullScreenState} handle={handle} />}
								</Grid>
							)}
						</Grid>
					)}
					{hasLoadingBar && (
						<div>
							<LoadingBar ref={loadingBarRef} />
						</div>
					)}
					{!error ? (
						<>{children}</>
					) : (
						<Grid container direction='row' justifyContent='center' alignItems='center' style={{ paddingBottom: '5em' }}>
							<h2 style={{ fontFamily: 'Poppins', color: '#b0b0b0', fontWeight: 'normal' }}>{error}</h2>
						</Grid>
					)}
				</Card>
			</FullScreen>
		</Grid>
	)
}

export default memo(ComponentCard)
