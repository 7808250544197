import React, { useEffect, useState, memo } from 'react'
import { useUpdateData } from 'hooks/ReactQueryHooks'
import NetworkSummaryContent from './components/NetworkSummaryContent/NetworkSummaryContent'
import NetworkSummaryProp from './components/NetworkSummaryProp/NetworkSummaryProp'
import { Grid, Card, Tooltip, IconButton, Collapse, Divider, CardContent } from '@mui/material'
import { OrderIcon, NetworkIcon, DownloadIcon } from 'utils/SystemIcons'
import { downloadAsImage } from 'utils/downloadAsImage'
import GraphTitle from 'components/GraphTitle/GraphTitle'
import NetworkSizeSelector from './components/NetworkSummaryContent/components/NetworkSizeSelector'
import NetworkSortSelector from './components/NetworkSummaryContent/components/NetworkSortSelector'
import NetworkSummarySelector from './components/NetworkSummarySelector/NetworkSummarySelector'
import OccurrencesCardSelector from 'components/Occurrences/components/FilterBar/components/OccurrencesCardSelector/OccurrencesCardSelector'
import { getFirstCard } from 'components/Occurrences/utils/occurrencesFunctions'

const tooltipDelay = 300

const NetworkSummary = ({
	cardsIds,
	cards = [],
	mainComparison = '',
	isReport = false,
	hasTitle = true,
	filters,
	cardType,
	initialOrder = 'score',
	initialView = 'accounts'
}) => {
	const [loading, setLoading] = useState(true)
	const [size, setSize] = useState(10)
	const [thisFilters, setThisFilters] = useState(filters)
	const [order, setOrder] = useState(initialOrder)
	const [view, setView] = useState(initialView)
	const [open, setOpen] = useState(false)
	const [selectedCard, setSelectedCard] = useState(getFirstCard(cards, mainComparison))
	const [allCardsSelect, setAllCardsSelect] = useState(cards)
	const [formattedData, setFormattedData] = useState({
		trend: null,
		account: null,
		hashtags: null,
		positive: null,
		negative: null
	})
	const getBody = () => ({
		configGlobalFilters: {
			cardType: cardType.toUpperCase(),
			cardIds: isReport ? cardsIds : [selectedCard?.id]
		},
		globalFilters: thisFilters,
		componentProperties: {
			orderBy: order,
			//Group
			isSummary: true,
			size,
			view
		}
	})

	let { data, isFetching, isFetched } = useUpdateData({
		url: '/network/get-network-summary',
		updateItemName: 'NetworkGraphSummary',
		queryName: 'relevanceChange_useUpdateData',
		method: 'post',
		body: getBody()
	})

	useEffect(() => {
		if (!isReport) {
			setView(initialView)
		}
	}, [thisFilters.sources])

	useEffect(() => {
		if (!isReport) {
			setAllCardsSelect(cards)
			const cardFound = cards.find((it) => it.id === selectedCard?.id)
			setSelectedCard(getFirstCard(cards, cardFound ? cardFound.name : ''))
		}
	}, [cards])

	useEffect(() => {
		if (isFetched) {
			setLoading(false)
			setFormattedData({
				trend: data[0]?.info?.name,
				accounts: data[0]?.accounts,
				hashtags: data[0]?.hashtags,
				positive: data[0]?.positive,
				negative: data[0]?.negative
			})
		}
	}, [isFetched, data])

	useEffect(() => {
		setThisFilters(filters)
	}, [filters])

	const onChangeValue = (newValue) => {
		setSize(newValue)
	}

	const onChangeSort = (newOrder) => {
		setOrder(newOrder)
	}

	const onChangeView = (newView) => {
		setView(newView)
	}

	const onCardChange = (newCard) => {
		setSelectedCard(newCard)
	}

	const itemActiveColor = (isActive) => (isActive ? '#1d8cf8' : '#c0c0c0')

	return (
		<>
			{!loading && (
				<Card elevation={isReport ? 0 : 1} style={{ borderRadius: '20px', width: '100%' }}>
					{(hasTitle || !isReport) && (
						<Grid
							container
							spacing={3}
							style={!isReport ? { background: '#fbfbfb', height: '70px', borderBottom: '1px solid #f5f5f5' } : {}}
						>
							<GraphTitle
								id='span-network-top'
								isReport={isReport}
								title={`Top ${size} Influenciadores para ${formattedData && formattedData?.trend}`}
								icon={
									<NetworkIcon
										size={30}
										style={{
											color: '#1d8cf8'
										}}
									/>
								}
								description={`
									Os ${size} principais Influenciadores para redes sociais e fontes de notícias
								`}
							/>
						</Grid>
					)}
					{!isReport && (
						<>
							<Grid data-html2canvas-ignore container>
								<Grid item xs={4} container justifyContent='flex-start' alignItems='center'>
									<NetworkSizeSelector size={size} onChangeValue={onChangeValue} />
									<Tooltip title='Ordenar' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
										<IconButton
											id='btn-order-network-summary'
											style={{ outline: 'none', float: 'right', backgroundColor: 'transparent', marginLeft: '10px' }}
											onClick={() => setOpen(!open)}
										>
											<OrderIcon size={23} style={{ color: itemActiveColor(open) }} />
										</IconButton>
									</Tooltip>
									<Tooltip title='Baixar como PNG' placement='right'>
										<IconButton
											style={{ marginRight: '10px' }}
											onClick={() => downloadAsImage(document.getElementById('influenciadores'), 'influenciadores')}
										>
											<DownloadIcon size={20} style={{ color: '#1d8cf8' }} />
										</IconButton>
									</Tooltip>
								</Grid>
								<Grid item xs={4} container justifyContent='center' alignItems='center'>
									{allCardsSelect && (
										<OccurrencesCardSelector cards={allCardsSelect} onCardChange={onCardChange} selectedCard={selectedCard} />
									)}
								</Grid>
								<Grid item xs={4} container justifyContent='flex-end' alignItems='center'>
									<NetworkSummarySelector
										view={view}
										onChangeView={onChangeView}
										sources={thisFilters.sources}
										cardType={cardType}
									/>
								</Grid>

								<Collapse in={open}>
									<NetworkSortSelector order={order} onChangeSort={onChangeSort} />
								</Collapse>
							</Grid>
							<Divider style={{ marginTop: '0px' }} />
						</>
					)}
					<Grid item xs={12} style={{ marginBottom: '1em', overflowY: 'scroll', overflowX: 'hidden', height: '552px' }}>
						{isReport ? (
							<NetworkSummaryContent data={formattedData} loading={isFetching} order={order} />
						) : (
							<div id='influenciadores'>
								<CardContent style={{ marginTop: '0.7em' }}>
									<NetworkSummaryContent data={formattedData} loading={isFetching} order={order} />
								</CardContent>
							</div>
						)}
					</Grid>
				</Card>
			)}
			{loading && <NetworkSummaryProp isReport={isReport} />}
		</>
	)
}

export default memo(NetworkSummary)
