import React from 'react'
import SummaryItem from '../components/SummaryItem/SummaryItem'
import { FiUser, FiHash } from 'react-icons/fi'
import { Grid } from '@mui/material'
import { HahaIcon, AngryIcon } from 'utils/SystemIcons'

const sortByOrder = (accounts, order) => {
	if (order === 'posts') {
		return accounts.sort((a, b) => b.author.postCount - a.author.postCount)
	} else if (order === 'score') {
		return accounts.sort((a, b) => b.score - a.score)
	}
}

const RenderAccounts = ({ formattedData, isReport, order }) => {
	let account = formattedData?.accounts
	account = sortByOrder(account, order)
	return (
		<>
			<Grid container style={{ marginBottom: '0.5em', marginLeft: '0.1em' }}>
				<FiUser
					size={20}
					style={{
						transform: 'translate(-2px, 26px)',
						color: '#1d8cf8'
					}}
				/>{' '}
				<h3
					id='span-network-accounts'
					style={{
						...{
							font: 'Poppins',
							fontSize: '1.3em',
							marginLeft: '0.5em',
							fontWeight: '300'
						},
						...(isReport ? { color: 'black' } : {})
					}}
				>
					Contas
				</h3>
			</Grid>
			{!account || account.length < 1 ? (
				<Grid
					style={{
						height: '350px',
						fontFamily: 'Poppins',
						fontSize: '25px',
						color: '#9a9a9a',
						fontWeight: '300',
						letterSpacing: '6px'
					}}
					container
					direction='row'
					justifyContent='center'
					alignItems='center'
				>
					SEM CONTAS PARA {formattedData?.trend?.toUpperCase()} NESSE INTERVALO
				</Grid>
			) : (
				<>
					<Grid
						container
						style={{
							textAlign: 'left',
							justifyContent: 'space-between'
						}}
					>
						{account?.map((acc, index) => (
							<Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }} key={acc.key + acc.source}>
								<div
									style={{
										font: 'Poppins',
										fontSize: '2.7em',
										color: '#1d8cf8',
										width: '1em'
									}}
								>
									{index + 1}
								</div>
								<SummaryItem isReport={isReport} item={acc} type='twitter' rank={index + 1} order={order} />
							</Grid>
						))}
					</Grid>
					<div className='divider-thin-default' />
				</>
			)}
		</>
	)
}

const RenderHashtags = ({ formattedData, isReport, order }) => {
	let hashtags = formattedData?.hashtags
	hashtags = sortByOrder(hashtags, order)
	if (!hashtags || hashtags.length < 1) {
		return (
			<>
				<Grid container style={{ marginBottom: '0.5em', marginLeft: '0.1em' }}>
					<FiHash
						size={20}
						style={{
							transform: 'translate(-2px, 26px)',
							color: '#1d8cf8'
						}}
					/>{' '}
					<h3
						style={{
							...{
								font: 'Poppins',
								fontSize: '1.3em',
								marginLeft: '0.5em',
								fontWeight: '300'
							},
							...(isReport ? { color: 'black' } : {})
						}}
					>
						Hashtags
					</h3>
				</Grid>
				<Grid
					style={{
						height: '350px',
						fontFamily: 'Poppins',
						fontSize: '25px',
						color: '#9a9a9a',
						fontWeight: '300',
						letterSpacing: '6px'
					}}
					container
					direction='row'
					justifyContent='center'
					alignItems='center'
				>
					SEM HASHTAGS PARA {formattedData?.trend?.toUpperCase()} NESSE INTERVALO
				</Grid>
			</>
		)
	}
	return (
		<>
			<Grid container style={{ marginBottom: '0.5em', marginLeft: '0.1em' }}>
				<FiHash
					size={20}
					style={{
						transform: 'translate(-2px, 26px)',
						color: '#1d8cf8'
					}}
				/>{' '}
				<h3
					style={{
						...{
							font: 'Poppins',
							fontSize: '1.3em',
							marginLeft: '0.5em',
							fontWeight: '300'
						},
						...(isReport ? { color: 'black' } : {})
					}}
				>
					Hashtags
				</h3>
			</Grid>
			<Grid
				container
				style={{
					textAlign: 'left',
					justifyContent: 'space-between'
				}}
			>
				{hashtags?.map((hashtag, index) => (
					<Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }} key={hashtag.key}>
						<div
							style={{
								font: 'Poppins',
								fontSize: '2.7em',
								color: '#1d8cf8',
								width: '1em'
							}}
						>
							{index + 1}
						</div>
						<SummaryItem isReport={isReport} item={hashtag} key={hashtag.key} type='hashtags' rank={index + 1} order={order} />
					</Grid>
				))}
			</Grid>
			<div className='divider-thin-default' />
		</>
	)
}

const RenderPositive = ({ formattedData, isReport, order }) => {
	let account = formattedData?.positive
	account = sortByOrder(account, order)
	if (!account || account.length < 1) {
		return (
			<>
				<Grid container style={{ marginBottom: '0.5em', marginLeft: '0.1em' }}>
					<HahaIcon
						size={20}
						style={{
							transform: 'translate(-2px, 26px)',
							color: '#1d8cf8'
						}}
					/>{' '}
					<h3
						style={{
							...{
								font: 'Poppins',
								fontSize: '1.3em',
								marginLeft: '0.5em',
								fontWeight: '300'
							},
							...(isReport ? { color: 'black' } : {})
						}}
					>
						Contas mais positivas
					</h3>
				</Grid>
				<Grid
					style={{
						height: '350px',
						fontFamily: 'Poppins',
						fontSize: '25px',
						color: '#9a9a9a',
						fontWeight: '300',
						letterSpacing: '6px'
					}}
					container
					direction='row'
					justifyContent='center'
					alignItems='center'
				>
					SEM CONTAS PARA {formattedData?.trend?.toUpperCase()} NESSE INTERVALO
				</Grid>
			</>
		)
	}
	return (
		<>
			<Grid container style={{ marginBottom: '0.5em', marginLeft: '0.1em' }}>
				<HahaIcon
					size={20}
					style={{
						transform: 'translate(-2px, 26px)',
						color: '#1d8cf8'
					}}
				/>{' '}
				<h3
					style={{
						...{
							font: 'Poppins',
							fontSize: '1.3em',
							marginLeft: '0.5em',
							fontWeight: '300'
						},
						...(isReport ? { color: 'black' } : {})
					}}
				>
					Contas mais positivas
				</h3>
			</Grid>
			<Grid
				container
				style={{
					textAlign: 'left',
					justifyContent: 'space-between'
				}}
			>
				{account?.map((acc, index) => (
					<Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }} key={acc.key}>
						<div
							style={{
								font: 'Poppins',
								fontSize: '2.7em',
								color: '#1d8cf8',
								width: '1em'
							}}
						>
							{index + 1}
						</div>
						<SummaryItem isReport={isReport} item={acc} key={acc.key} type='account' rank={index + 1} order={order} />
					</Grid>
				))}
			</Grid>
			<div className='divider-thin-default' />
		</>
	)
}

const RenderNegative = ({ formattedData, isReport, order }) => {
	let account = formattedData?.negative
	account = sortByOrder(account, order)
	if (!account || account.length < 1) {
		return (
			<>
				<Grid container style={{ marginBottom: '0.5em', marginLeft: '0.1em' }}>
					<AngryIcon
						size={20}
						style={{
							transform: 'translate(-2px, 26px)',
							color: '#ff2b6b'
						}}
					/>{' '}
					<h3
						style={{
							...{
								font: 'Poppins',
								fontSize: '1.3em',
								marginLeft: '0.5em',
								fontWeight: '300'
							},
							...(isReport ? { color: 'black' } : {})
						}}
					>
						Contas mais negativas
					</h3>
				</Grid>
				<Grid
					style={{
						height: '350px',
						fontFamily: 'Poppins',
						fontSize: '25px',
						color: '#9a9a9a',
						fontWeight: '300',
						letterSpacing: '6px'
					}}
					container
					direction='row'
					justifyContent='center'
					alignItems='center'
				>
					SEM DADOS PARA {formattedData?.trend?.toUpperCase()} NESSE INTERVALO
				</Grid>
			</>
		)
	}
	return (
		<>
			<Grid container style={{ marginBottom: '0.5em', marginLeft: '0.1em' }}>
				<AngryIcon
					size={20}
					style={{
						transform: 'translate(-2px, 26px)',
						color: '#ff2b6b'
					}}
				/>{' '}
				<h3
					style={{
						...{
							font: 'Poppins',
							fontSize: '1.3em',
							marginLeft: '0.5em',
							fontWeight: '300'
						},
						...(isReport ? { color: 'black' } : {})
					}}
				>
					Contas mais negativas
				</h3>
			</Grid>
			<Grid
				container
				style={{
					textAlign: 'left',
					justifyContent: 'space-between'
				}}
			>
				{account?.map((acc, index) => (
					<Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }} key={acc.key}>
						<div
							style={{
								font: 'Poppins',
								fontSize: '2.7em',
								color: '#ff2b6b',
								width: '1em'
							}}
						>
							{index + 1}
						</div>
						<SummaryItem isReport={isReport} item={acc} key={acc.key} type='account' rank={index + 1} order={order} color='#ff2b6b' />
					</Grid>
				))}
			</Grid>
			<div className='divider-thin-default' />
		</>
	)
}

export { RenderAccounts, RenderHashtags, RenderPositive, RenderNegative }
