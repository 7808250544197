import React, { useState, useEffect, memo } from 'react'
import { Dialog, DialogTitle, IconButton, DialogContent, CardContent, Grid, Button, Collapse, CircularProgress } from '@mui/material'
import { CloseIcon, PlusIcon, NextIcon, BeforeIcon, EditIcon } from 'utils/SystemIcons'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import GeneralNewInsight from './components/GeneralNewInsightTerm/GeneralNewInsightTerm'
import TermNewInsight from './components/TermNewInsight/TermNewInsight'
import SocialNetworksNewInsight from './components/SocialNetworksNewInsightTerm/SocialNetworksNewInsightTerm'
import api from 'service/service'
import constantsVox from 'constants-vox'
import { verifyCanNextStepTerm } from 'utils/field-validations/createNewCardFieldValidations'
import Stepper from 'components/Stepper/Stepper'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import OptionalConfigNewInsightTerm from './components/OptionalConfigNewInsightTerm/OptionalConfigNewInsightTerm'
import { verifyServerResponseCanShowToast } from 'utils/generalFunctions'
import SearchTypeNewInsightTerm from './components/SearchTypeNewInsightTerm/SearchTypeNewInsightTerm'

const initialFormState = {
	cardName: '',
	searchTerms: {},
	cardQuery: [],
	category: '',
	country: '',
	language: '',
	politicalParty: '',
	hasTwitterSearch: false,
	hasBlueskySearch: false,
	hasFacebookSearch: false,
	hasInstagramSearch: false,
	hasTiktokSearch: false,
	hasYoutubeSearch: false,
	hasNewsSearch: false,
	manualClassificationDictionary: { positive: [], negative: [] },
	stopAccounts: {
		twitter: [],
		bluesky: [],
		facebook: [],
		instagram: [],
		tiktok: [],
		youtube: [],
		news: []
	},
	manualClassificationAccount: {
		twitter: {
			haters: [],
			lovers: []
		},
		bluesky: {
			haters: [],
			lovers: []
		},
		facebook: {
			haters: [],
			lovers: []
		},
		instagram: {
			haters: [],
			lovers: []
		},
		tiktok: {
			haters: [],
			lovers: []
		},
		youtube: {
			haters: [],
			lovers: []
		},
		news: {
			haters: [],
			lovers: []
		}
	},
	stopWords: {
		words: []
	},
	hasHistoric: false,
	maxHistoric: '',
	tagsGlobalFilters: { value: [] },
	changeTags: false,
	changeHatersLovers: false,
	changeDictionary: false,
	searchType: constantsVox.CARD.SEARCH_TYPE.TERM,
	searchTypeGroups: [],
	groups: []
}

const isFirefox = typeof InstallTrigger !== 'undefined'

const initialErrors = {}

const stepperData = [
	{ label: '', value: 1 },
	{ label: '', value: 2 },
	{ label: '', value: 3 },
	{ label: '', value: 4 },
	{ label: '', value: 5 }
]

const verifyUserType = (user) => {
	if (user.proceedAsAdminId) {
		return { user: user.proceedAsAdminId }
	} else if (isEmpty(user.subUser)) {
		return { user: user.id_usuario }
	} else {
		return { subUser: user.subUser.id }
	}
}

let isQueryFromstate = false

const NewMonitoringDialogTerm = ({ onSubmit, close, card = null, statusType = 'create', user }) => {
	const [fieldErrors, setFieldErrors] = useState(initialErrors)
	const [submitting, setSubmitting] = useState(false)
	const [formState, setFormState] = useState(initialFormState)
	const [sourceInfo, setSourceInfo] = useState(null)
	const [loadingSourceInfo, setLoadingSourceInfo] = useState(false)
	const [step, setStep] = useState(1)

	useEffect(() => {
		if (statusType === 'edit') {
			setLoadingSourceInfo(true)
			if (card) {
				api.get(`/monitoring/card/get-info-by-type/${card.id}/${card?.type}`)
					.then((response) => {
						setSourceInfo(response.data)
					})
					.finally(() => setLoadingSourceInfo(false))
			}
		}
	}, [])

	useEffect(() => {
		//Quando troca a categoria (para uma diferente de politics) ou o país (para um diferente de brasil),
		//e se tiver inclinacao ou political party, seta os dois pro valor inicial (reseta esses campos)
		if ((formState.category?.value !== 2 || formState.country.label !== 'Brasil') && formState.politicalParty) {
			setFormState({
				...formState,
				politicalParty: initialFormState.politicalParty
			})
		}
	}, [formState.category, formState.country])

	const isDifferentObject = (original, current) => {
		return JSON.stringify(original) != JSON.stringify(current)
	}

	const nextStep = async (newStep, submit = false) => {
		if (newStep < step) {
			let resetStep2 = {
				searchType: initialFormState.searchType
			}
			let resetStep3 = {
				hasTwitterSearch: initialFormState.hasTwitterSearch,
				hasBlueskySearch: initialFormState.hasBlueskySearch,
				hasFacebookSearch: initialFormState.hasFacebookSearch,
				hasInstagramSearch: initialFormState.hasInstagramSearch,
				hasTiktokSearch: initialFormState.hasTiktokSearch,
				hasYoutubeSearch: initialFormState.hasYoutubeSearch,
				hasNewsSearch: initialFormState.hasNewsSearch,
				stopAccounts: initialFormState.stopAccounts,
				manualClassificationAccount: initialFormState.manualClassificationAccount
			}
			let resetStep4 = {
				searchTerms: initialFormState.searchTerms,
				cardQuery: initialFormState.cardQuery,
				groups: initialFormState.groups
			}
			let resetStep5 = {
				manualClassificationDictionary: initialFormState.manualClassificationDictionary,
				stopWords: initialFormState.stopWords,
				hasHistoric: initialFormState.hasHistoric,
				tagsGlobalFilters: initialFormState.tagsGlobalFilters
			}

			let newFormState = {}

			if (newStep === 1) {
				newFormState = { ...formState, ...resetStep2 }
			} else if (newStep === 2) {
				newFormState = { ...formState, ...resetStep3 }
			} else if (newStep === 3) {
				//FIXME
				newFormState = { ...formState, ...resetStep4 }
				isQueryFromstate = false
			} else if (newStep === 4) {
				newFormState = { ...formState, ...resetStep5 }
				isQueryFromstate = true
			}

			setFieldErrors(initialErrors)
			setFormState(newFormState)
			setStep(newStep)
		} else {
			let verifiedErrors = await verifyCanNextStepTerm(formState, step, fieldErrors, statusType)
			let errors = { ...fieldErrors, ...verifiedErrors }
			setFieldErrors(errors)
			let hasError = Object.values(errors).some((e) => e.error && e.step == step)

			if (!hasError) {
				if (step === 5 && submit) {
					const userOrSubUserId = verifyUserType(user)
					setSubmitting(true)
					const formStateFormatted = {
						cardName: formState.cardName,
						cardQuery: formState.cardQuery,
						categoryId: formState.category.value,
						countryId: formState.country.value,
						languageId: formState.language.value,
						politicalPartyId: formState.politicalParty ? formState.politicalParty.id : null,
						hasTwitterSearch: formState.hasTwitterSearch,
						hasBlueskySearch: formState.hasBlueskySearch,
						hasFacebookSearch: formState.hasFacebookSearch,
						hasInstagramSearch: formState.hasInstagramSearch,
						hasTiktokSearch: formState.hasTiktokSearch,
						hasYoutubeSearch: formState.hasYoutubeSearch,
						hasNewsSearch: formState.hasNewsSearch,
						manualClassificationDictionary: formState.manualClassificationDictionary,
						stopAccounts: formState.stopAccounts,
						manualClassificationAccount: formState.manualClassificationAccount,
						stopWords: formState.stopWords,
						statusType,
						userOrSubUserId,
						cardId: card ? card?.id : null,
						hasHistoric: formState.hasHistoric,
						tagsGlobalFilters: formState.tagsGlobalFilters,
						changeTags: card ? isDifferentObject(card.tags, formState.tagsGlobalFilters) : true,
						changeHatersLovers: card ? isDifferentObject(card.manualClassificationAccount, formState.manualClassificationAccount) : true,
						changeDictionary: card
							? isDifferentObject(card.manualClassificationDictionary, formState.manualClassificationDictionary)
							: true,
						searchType: formState.searchType,
						groups: formState.groups
					}
					api.post('/card-term/monitoring/new-term', formStateFormatted)
						.then((res) => {
							onSubmit()
							sendMessageToast(res.data.toast.message, res.data.toast.type)
							close(null)
						})
						.catch((error) => {
							setFieldErrors(error.response?.data?.fieldErrors)
							if (verifyServerResponseCanShowToast(error)) {
								sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
							}
						})
						.finally(() => {
							setSubmitting(false)
						})
				} else {
					setStep(newStep)
				}
			}
		}
	}

	const onChangeForm = (newValue) => {
		setFormState({ ...formState, ...newValue })
	}

	const handleMessageToast = (message) => {
		sendMessageToast(message, constantsVox.TOAST.TYPES.WARNING)
	}

	const onChangeFieldErrors = (newValue) => {
		setFieldErrors({ ...fieldErrors, ...newValue })
	}

	return (
		<Dialog
			open={true}
			PaperProps={{
				style: {
					borderRadius: '20px',
					backdropFilter: 'blur(10px)',
					backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
					border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
					boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
				}
			}}
			BackdropProps={{
				style: { backdropFilter: 'blur(3px)', backgroundColor: 'rgba(220, 220, 220, 0.4)' }
			}}
			fullWidth={true}
			maxWidth='md'
		>
			<DialogTitle>
				<div className='jc-space-between d-flex ai-center'>
					{statusType === 'create' && (
						<div>
							<PlusIcon className='fix-icon-margin-bottom-negative' size={25} style={{ color: '#1d8cf8' }} /> Novo monitoramento de tema
						</div>
					)}
					{statusType === 'edit' && (
						<div>
							<EditIcon className='fix-icon-margin-bottom-negative' size={25} style={{ color: '#1d8cf8' }} /> Editar cartão de tema
							{loadingSourceInfo && (
								<CircularProgress thickness={2} style={{ marginLeft: '10px', color: '#1d8cf8', width: '20px', height: '20px' }} />
							)}
						</div>
					)}
					<div>
						<IconButton color='primary' style={{ outline: 'none' }} onClick={close}>
							<CloseIcon color='#1d8cf8' size={20} />
						</IconButton>
					</div>
				</div>
			</DialogTitle>
			<Collapse in={!loadingSourceInfo}>
				{!loadingSourceInfo && (
					<DialogContent style={{ padding: '1.5em', paddingTop: 0 }}>
						<CardContent>
							<Stepper data={stepperData} value={step} spacing={2} justifyContent='space-between' />
							<Grid container style={{ padding: '1.5em' }}>
								{step === 1 && (
									<GeneralNewInsight
										formState={formState}
										onChangeForm={onChangeForm}
										fieldErrors={fieldErrors}
										statusType={statusType}
										card={card}
										sourceInfo={sourceInfo}
									/>
								)}

								{step === 2 && (
									<SearchTypeNewInsightTerm
										formState={formState}
										onChangeForm={onChangeForm}
										fieldErrors={fieldErrors}
										card={card}
										statusType={statusType}
									/>
								)}

								{step === 3 && (
									<SocialNetworksNewInsight
										formState={formState}
										onChangeForm={onChangeForm}
										fieldErrors={fieldErrors}
										statusType={statusType}
										card={card}
										messageToast={handleMessageToast}
									/>
								)}

								{step === 4 && (
									<TermNewInsight
										onChangeForm={onChangeForm}
										fieldErrors={fieldErrors}
										card={card}
										formState={formState}
										isQueryFromstate={isQueryFromstate}
										statusType={statusType}
										maxNumberOfTerms={user.maxNumberOfTerms}
										messageToast={handleMessageToast}
									/>
								)}

								{step === 5 && (
									<OptionalConfigNewInsightTerm
										formState={formState}
										fieldErrors={fieldErrors}
										onChangeForm={onChangeForm}
										statusType={statusType}
										card={card}
										onChangeFieldErrors={onChangeFieldErrors}
									/>
								)}
							</Grid>
							<Grid container justifyContent='space-between' style={{ marginTop: '1em' }}>
								<Grid item>
									{step !== 1 && (
										<Button
											style={{ borderRadius: '25px' }}
											disabled={step === 1 || submitting}
											color='primary'
											onClick={() => nextStep(step - 1)}
										>
											<BeforeIcon size={24} /> <span style={{ marginTop: '3px' }}>Anterior</span>
										</Button>
									)}
								</Grid>
								<Grid item className='mt-autoi mb-autoi ml-6i'>
									{step === 5 ? (
										<Button
											style={{ borderRadius: '25px' }}
											color='primary'
											onClick={() => nextStep(step + 1, true)}
											disabled={submitting}
										>
											{statusType === 'create' ? 'Criar cartão' : 'Salvar mudanças'}
										</Button>
									) : (
										<Button
											style={{ borderRadius: '25px' }}
											color='primary'
											onClick={() => nextStep(step + 1, false)}
											disabled={submitting}
										>
											<span style={{ marginTop: '3px' }}>Próximo</span> <NextIcon size={24} />
										</Button>
									)}
								</Grid>
							</Grid>
						</CardContent>
					</DialogContent>
				)}
			</Collapse>
		</Dialog>
	)
}

const mapStateToProps = ({ store }) => ({
	user: store?.user
})

export default connect(mapStateToProps)(memo(NewMonitoringDialogTerm))
