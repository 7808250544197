import React, { useState, useEffect } from 'react'
import Chart from 'react-apexcharts'
import { setLegendColorDictonary, getLegendColorById } from 'utils/graphFunctions'

const barsDataProps = {
	options: {
		chart: {
			type: 'bar',
			toolbar: {
				show: false
			}
		},
		labels: [''],
		plotOptions: {
			bar: {
				horizontal: true
			}
		}
	},
	series: [{ data: [0] }]
}

const formatValue = (val) => {
	if (val !== undefined) {
		return val.toFixed(1)
	}
	return 0
}

const MiniCardNumberComp = ({ data, metric, title, cardIds, useColorDictionary = true }) => {
	const [barsData, setBarsData] = useState(barsDataProps)

	useEffect(() => {
		load()
	}, [data])

	const load = () => {
		let categories = []
		let seriesData = []

		let colorDictionary = setLegendColorDictonary(cardIds)
		let colors = []

		for (let index = 0; index < data.length; index++) {
			const element = data[index]
			categories.push(element?.name || '')
			seriesData.push(formatValue(element?.selectedInterval?.metrics[metric]))
			if (useColorDictionary) {
				colors.push(getLegendColorById(element.cardId, colorDictionary))
			}
		}

		if (!useColorDictionary) {
			colors = [
				'#008FFB',
				'#00E396',
				'#FEB019',
				'#FF4560',
				'#775DD0',
				'#104469',
				'#3cb44b',
				'#ffe119',
				'#4363d8',
				'#f58231',
				'#911eb4',
				'#46f0f0',
				'#f032e6',
				'#bcf60c',
				'#fabebe',
				'#008080',
				'#e6beff',
				'#9a6324',
				'#fffac8',
				'#800000',
				'#aaffc3',
				'#808000',
				'#ffd8b1',
				'#000075',
				'#808080',
				'#e3e1e1',
				'#000000'
			]
		}

		const optionsBars = {
			colors: colors,
			chart: {
				type: 'bar'
			},
			plotOptions: {
				bar: {
					borderRadius: 8,
					horizontal: true,
					dataLabels: {
						position: 'bottom'
					},
					distributed: true
				}
			},
			tooltip: {
				enabled: true
			},
			legend: {
				show: false,
				position: 'bottom'
			},
			dataLabels: {
				enabled: true,
				offsetX: 0,
				textAnchor: 'start',
				style: {
					fontSize: '14px',
					fontFamily: 'Roboto'
					// colors: colors
				},
				background: {
					enabled: true,
					foreColor: '#fff',
					padding: 4,
					borderRadius: 10,
					borderWidth: 1,
					borderColor: 'transparent',
					opacity: 0.15
				},
				dropShadow: {
					enabled: true,
					top: 1,
					left: 0,
					blur: 1,
					color: '#000',
					opacity: 0.45
				}
			},
			xaxis: {
				categories,
				labels: {
					show: false
				},
				axisBorder: {
					show: false
				},
				axisTicks: {
					show: false
				}
			},
			yaxis: {
				labels: {
					show: true,
					align: 'right',
					minWidth: 0,
					maxWidth: 160,
					style: {
						colors: [],
						fontSize: '12px',
						fontFamily: 'Poppins',
						fontWeight: 400,
						cssClass: 'apexcharts-yaxis-label'
					}
				}
			},
			grid: {
				show: false
			}
		}

		const apexData = {
			options: optionsBars,
			series: [{ name: title, data: seriesData }]
		}
		setBarsData(apexData)
	}

	return (
		<Chart
			style={{
				color: 'black',
				textAlign: 'left',
				fontFamily: 'Poppins'
			}}
			options={barsData.options}
			series={barsData.series}
			type='bar'
			width='100%'
			height='70%'
		/>
	)
}

export default MiniCardNumberComp
