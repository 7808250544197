import constantsVox from 'constants-vox'
//Account management
import {
	DEFAULT_LAYOUTS_FACEBOOK_ACCOUNT_MANAGER,
	DEFAULT_WIDGETS_FACEBOOK_ACCOUNT_MANAGER
} from '../modules/DashboardShareableAccountManager/MetricsFacebookDashboardShareable/utils/MetricsFacebookDashboardShareableUtils'
import {
	DEFAULT_LAYOUTS_INSTAGRAM_ACCOUNT_MANAGER,
	DEFAULT_WIDGETS_INSTAGRAM_ACCOUNT_MANAGER
} from '../modules/DashboardShareableAccountManager/MetricsInstagramDashboardShareable/utils/MetricsInstagramDashboardShareableUtils'
import {
	DEFAULT_LAYOUTS_META_ADS_ACCOUNT_MANAGER,
	DEFAULT_WIDGETS_META_ADS_ACCOUNT_MANAGER
} from '../modules/DashboardShareableAccountManager/MetricsMetaAdsDashboardShareable/utils/MetricsMetaAdsDashboardShareableUtils'
//Term
import {
	DEFAULT_LAYOUTS_TERM,
	DEFAULT_WIDGETS_TERM
} from '../modules/DashboardShareableTerm/MetricsTermDashboardShareable/utils/MetricsTermDashboardShareableUtils'

const getWidgetsDefaultByCardType = (cardType) => {
	let widgetsDefault = {
		term: undefined,
		facebook: undefined,
		instagram: undefined,
		metaAds: undefined
	}
	if (cardType === constantsVox.CARD_TYPE.ACCOUNT_MANAGEMENT) {
		widgetsDefault = {
			facebook: DEFAULT_WIDGETS_FACEBOOK_ACCOUNT_MANAGER,
			instagram: DEFAULT_WIDGETS_INSTAGRAM_ACCOUNT_MANAGER,
			metaAds: DEFAULT_WIDGETS_META_ADS_ACCOUNT_MANAGER
		}
	} else if (cardType === constantsVox.CARD_TYPE.TERM) {
		widgetsDefault = {
			term: DEFAULT_WIDGETS_TERM
		}
	}

	return widgetsDefault
}

const getLayoutsDefaultByCardType = (cardType) => {
	let layoutsDefault = {
		product_type: cardType,
		layouts: {
			term: undefined,
			facebook: undefined,
			instagram: undefined,
			metaAds: undefined
		}
	}
	if (cardType === constantsVox.CARD_TYPE.ACCOUNT_MANAGEMENT) {
		layoutsDefault.layouts = {
			facebook: DEFAULT_LAYOUTS_FACEBOOK_ACCOUNT_MANAGER,
			instagram: DEFAULT_LAYOUTS_INSTAGRAM_ACCOUNT_MANAGER,
			metaAds: DEFAULT_LAYOUTS_META_ADS_ACCOUNT_MANAGER
		}
	} else if (cardType === constantsVox.CARD_TYPE.TERM) {
		layoutsDefault.layouts = {
			term: DEFAULT_LAYOUTS_TERM
		}
	}

	return layoutsDefault
}

export { getWidgetsDefaultByCardType, getLayoutsDefaultByCardType }
