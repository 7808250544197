import React, { useState, useRef, memo, useEffect } from 'react'
import { connect } from 'react-redux'
import { saveGlobalFiltersTermAction } from 'store/redux/actions/global.actions'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import { ChartIcon } from 'utils/SystemIcons'
import { Grid, Card } from '@mui/material'
import moment from 'moment-timezone'
import FullScreenButton from '../../components/FullScreenButton/FullScreenButton'
import LoadingBar from 'components/LoadingBar/LoadingBar'
import DialogContents from 'components/DialogInformation/DialogContents'
import GraphTitle from 'components/GraphTitle/GraphTitle'
import SensitivityByTime from './components/SensitivityByTime/SensitivityByTime'
import { canRenderHourInterval } from 'graphs/utils/TimelinesFunctions'

const GraficoSensibilidade = ({
	cards,
	isFetchingData,
	globalFiltersTermRedux,
	saveGlobalFiltersDispatch,
	defaultGraphTime = null,
	hideRightMenus,
	isWidget = false
}) => {
	const [fullScreenState, setFullScreenState] = useState(false)
	const [openDateInfo, setOpenDateInfo] = useState(false)

	const handle = useFullScreenHandle()

	const loadingBarRef = useRef()

	const onPartialLoading = (status) => {
		loadingBarRef.current.load(status)
	}

	const getDefaultGraphTime = () => {
		return canRenderHourInterval(globalFiltersTermRedux.fromDate, globalFiltersTermRedux.toDate, 'months', 3) &&
			canRenderHourInterval(globalFiltersTermRedux.fromDate, globalFiltersTermRedux.toDate, 'weeks', 3)
			? defineSelectedTimeInterval()
			: 'day'
	}

	const defineSelectedTimeInterval = () => {
		if (defaultGraphTime) {
			return defaultGraphTime
		} else {
			return canRenderHourInterval(globalFiltersTermRedux.fromDate, globalFiltersTermRedux.toDate, 'weeks', 1) ? 'hour' : 'day'
		}
	}

	const lockTimeInterval = (interval) => {
		if (moment(globalFiltersTermRedux.toDate).diff(globalFiltersTermRedux.fromDate, 'months') > 12) {
			setOpenDateInfo(true)
		} else {
			saveGlobalFiltersDispatch({
				...globalFiltersTermRedux,
				fromDate: interval.fromDate,
				toDate: interval.toDate,
				fromHour: interval.fromHour,
				toHour: interval.toHour
			})
		}
	}

	const handleCloseDateInfo = () => {
		setOpenDateInfo(false)
	}

	return (
		<>
			<Grid item xs={12}>
				<DialogContents
					open={openDateInfo}
					handleClose={handleCloseDateInfo}
					title='Informação'
					text='Não é possível filtrar períodos maiores que 12 meses. Caso necessite de períodos maiores que 12 meses entre em contato com nosso suporte.'
				/>
				<FullScreen handle={handle} onChange={() => setFullScreenState(!fullScreenState)}>
					<Card
						elevation={isWidget ? 0 : 1}
						style={{ height: '100%', borderRadius: '20px', backgroundColor: isWidget ? 'transparent' : 'white' }}
					>
						<Grid container spacing={3} style={{ background: '#fbfbfb', height: '70px', borderBottom: '1px solid #f5f5f5' }}>
							<Grid item xs={6}>
								<GraphTitle
									id='span-sentiments-nowcasting'
									title='Sentimentos - linha do tempo'
									icon={<ChartIcon size={25} style={{ color: '#1d8cf8' }} />}
									description={`
											Sentimentalização das conversas ao longo do tempo sobre o tema analisado. 
											Visualize apenas os sentimentos positivo e negativo, ou opte por analisar o saldo 
											(diferença entre os dois primeiros) das postagens. 
										`}
								/>
							</Grid>
							{!hideRightMenus && (
								<Grid item xs={6}>
									<FullScreenButton fullScreenState={fullScreenState} handle={handle} />
								</Grid>
							)}
						</Grid>
						<div>
							<LoadingBar ref={loadingBarRef} />
						</div>
						<SensitivityByTime
							cards={cards}
							fullScreenState={fullScreenState}
							onPartialLoading={onPartialLoading}
							isFetchingData={isFetchingData}
							filters={globalFiltersTermRedux}
							defaultGraphTime={getDefaultGraphTime()}
							lockTimeInterval={lockTimeInterval}
						/>
					</Card>
				</FullScreen>
			</Grid>
		</>
	)
}

const mapDispatchToProps = (dispatch) => ({
	saveGlobalFiltersDispatch: (newFilters) => dispatch(saveGlobalFiltersTermAction(newFilters))
})

const mapStateToProps = ({ store }) => ({
	globalFiltersTermRedux: store.global.filters
})

export default connect(mapStateToProps, mapDispatchToProps)(memo(GraficoSensibilidade))
