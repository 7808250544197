import { Avatar, Box, Button, Card, CardContent, Chip, Grid, IconButton, List, ListItem, Menu, MenuItem, Tooltip, Typography } from '@mui/material'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import api from 'service/service'
import ImageAnnotationProjectTasks from './ImageAnnotationProjectTasks'
import ImageAnnotationCreateProject from './ImageAnnotationCreateProject'
// import { LoadingButton } from '@mui/lab'
import { EditIconAura } from 'utils/SystemIcons'
import DialogConfirmation from 'components/DialogConfirmation/DialogConfirmation'

const ImageAnnotationListProjects = () => {
	const [projects, setProjects] = useState([])
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)
	const [openTask, setOpenTask] = useState(false)
	const [isCreatingProject, setIsCreatingProject] = useState(false)
	// const [isSyncing, setIsSyncing] = useState(false)
	const [selectedProject, setSelectedProject] = useState()
	const [anchorEl, setAnchorEl] = useState(null)
	const [openConfirmChangeStatus, setOpenConfirmChangeStatus] = useState(false)
	const [newStatus, setNewStatus] = useState(false)

	const handleChipClick = (event, project) => {
		setSelectedProject(project)
		setAnchorEl(event.currentTarget)
	}

	const handleMenuClose = () => {
		setSelectedProject()
		setOpenConfirmChangeStatus(false)
		setAnchorEl(null)
	}

	const handleMenuClick = (option) => {
		setNewStatus(option)
		setOpenConfirmChangeStatus(true)
	}

	const handleSelectStatus = async () => {
		try {
			await api.post(`/image-annotation/change-status-project/${newStatus}/${selectedProject.id}`)
			await fetchProjects()
			handleMenuClose()
		} catch (err) {
			console.error('Error changing status project:', err)
			setError('Error changing status project')
		}
	}

	const fetchProjects = async () => {
		try {
			const response = await api.get('/image-annotation/projects')
			if (Array.isArray(response.data.data)) {
				setProjects(response.data.data)
			} else {
				throw new Error('Unexpected response format')
			}
		} catch (err) {
			console.error('Error fetching projects:', err)
			setError('Error fetching projects')
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		fetchProjects()
	}, [])

	const getStatusColor = (status) => {
		switch (status) {
			case 'annotation':
				return '#5FADFA'
			case 'completed':
				return 'green'
			case 'deleted':
				return 'red'
			default:
				return 'grey'
		}
	}

	const handleProjectClick = (project) => {
		setSelectedProject(project)
		setOpenTask(true)
	}

	const onCloseCreating = async () => {
		setIsCreatingProject(false)
		setSelectedProject()
		await fetchProjects()
	}

	const handleDownload = (url) => {
		const link = document.createElement('a')
		link.href = url
		document.body.appendChild(link)
		link.click()
		document.body.removeChild(link)
	}

	if (loading) {
		return <div>Loading...</div>
	}

	if (error) {
		return <div>{error}</div>
	}

	const handleWidth = (length) => {
		if (length === 1) {
			return 300
		}
		if (length <= 4) {
			return 150
		}
		if (length <= 9) {
			return 100
		}
		if (length <= 12) {
			return 75
		}
		if (length <= 15) {
			return 60
		}
		return 60
	}

	const handleHeight = (length) => {
		if (length <= 2) {
			return 225
		}
		if (length <= 6) {
			return 112.5
		}
		if (length <= 12) {
			return 75
		}
		return 75
	}

	// const handleSyncData = async () => {
	// 	setIsSyncing(true)

	// 	try {
	// 		await api.post('/image-annotation/sync-projects')
	// 		alert('Dados sincronizados!')
	// 		await fetchProjects()
	// 	} catch (error) {
	// 		console.error('Erro ao sincronizar dados:', error)
	// 		alert('Erro ao sincronizar dados.')
	// 	}

	// 	setIsSyncing(false)
	// }

	return (
		<Box style={{ width: '90%', maxWidth: 800, margin: '0 auto', padding: '16px', backgroundColor: '#f7f7f7' }}>
			<Typography variant='h4' gutterBottom>
				Lista de Projetos
			</Typography>

			<Grid container>
				<Grid item xs={6}>
					<Button variant='contained' color='primary' onClick={() => setIsCreatingProject(true)}>
						Criar novo projeto
					</Button>
				</Grid>
				<Grid item xs={6} display={'flex'} justifyContent={'end'}>
					{/* <LoadingButton style={{ width: '177px' }} onClick={handleSyncData} variant='contained' loading={isSyncing} disabled={isSyncing}>
						{!isSyncing && 'Sincronizar dados'}
					</LoadingButton> */}
				</Grid>
			</Grid>

			{isCreatingProject && (
				<ImageAnnotationCreateProject isOpen={isCreatingProject} handleOnClose={onCloseCreating} project={selectedProject} />
			)}

			<List>
				{projects.map((project) => (
					<ListItem key={project.id} style={{ padding: 0, marginBottom: '16px', cursor: 'pointer' }}>
						<Card style={{ width: '100%' }}>
							<Grid container>
								<Grid item xs={6}>
									<CardContent>
										<Typography variant='h5' gutterBottom>
											<Tooltip title='Editar projeto' placement='left'>
												<IconButton
													style={{ marginTop: '-0.25em' }}
													color='primary'
													onClick={() => {
														setIsCreatingProject(true)
														setSelectedProject(project)
													}}
												>
													<EditIconAura />
												</IconButton>
											</Tooltip>
											{`${project.id} - ${project.name}`}
										</Typography>
										<Chip
											onClick={(event) => handleChipClick(event, project)}
											label={project.status}
											style={{
												backgroundColor: getStatusColor(project.status),
												color: 'white',
												marginBottom: '8px'
											}}
										/>
										<Menu
											anchorEl={anchorEl}
											open={Boolean(anchorEl) && selectedProject.id === project.id}
											onClose={handleMenuClose}
										>
											<MenuItem onClick={() => handleMenuClick('annotation')}>annotation</MenuItem>
											<MenuItem onClick={() => handleMenuClick('completed')}>completed</MenuItem>
											<MenuItem onClick={() => handleMenuClick('deleted')}>deleted</MenuItem>
										</Menu>
										<Typography variant='body2'>CVAT ID: {project.cvatId}</Typography>
										<Typography variant='body2'>
											Último Modelo Rodado: {project.lastRunModel ? project.lastRunModel : null}
										</Typography>
										<Typography variant='body2'>Versão Modelo: {project.modelVersion}</Typography>
										{project.modelLastRunDate && (
											<Typography variant='body2'>
												Rodado pela última vez em: {moment(project.modelLastRunDate).format('DD/MM/YYYY')}
											</Typography>
										)}
										<Typography variant='body2'>Logos IDs: {project.auraLogosIds.join(', ')}</Typography>
										<Typography variant='body2'>Projeto criado em: {moment(project.createdAt).format('DD/MM/YYYY')}</Typography>
									</CardContent>
									{project.modelBasePath && (
										<Button
											variant='outlined'
											style={{ marginLeft: '15px' }}
											onClick={() => handleDownload(project.modelBasePath)}
										>
											Download modelo base
										</Button>
									)}
								</Grid>
								<Grid item xs={6}>
									<CardContent onClick={() => handleProjectClick(project)}>
										{project.auraLogosPaths && project.auraLogosPaths.length > 0 && (
											<Grid container direction={'row'} style={{ maxHeight: 225, maxWidth: 300 }}>
												{project.auraLogosPaths?.map((image) => (
													<Grid item key={image}>
														<Avatar
															src={image}
															sx={{
																width: handleWidth(project.auraLogosPaths.length),
																height: handleHeight(project.auraLogosPaths.length),
																objectFit: 'contain',
																backgroundColor: 'white'
															}}
															variant='square'
														/>
													</Grid>
												))}
											</Grid>
										)}
									</CardContent>
								</Grid>
							</Grid>
						</Card>
					</ListItem>
				))}
			</List>

			{openConfirmChangeStatus && (
				<DialogConfirmation
					open={openConfirmChangeStatus}
					handleClose={() => setOpenConfirmChangeStatus(false)}
					title={'Deseja realmente alterar o status do projeto?'}
					buttonText={'Alterar'}
					handleConfirm={handleSelectStatus}
				/>
			)}

			{openTask && <ImageAnnotationProjectTasks project={selectedProject} isOpen={openTask} handleOnClose={() => setOpenTask(false)} />}
		</Box>
	)
}

export default ImageAnnotationListProjects
