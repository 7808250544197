import {
	Box,
	Button,
	Card,
	CardContent,
	Chip,
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	// IconButton,
	List,
	ListItem,
	Menu,
	MenuItem,
	// Tooltip,
	// Tooltip,
	Typography
} from '@mui/material'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import api from 'service/service'
import ImageUploader from 'react-images-upload'
// import ImageGallery from 'react-image-gallery'
// import 'react-image-gallery/styles/css/image-gallery.css'
// import Gallery from 'react-photo-gallery'
import imageCompression from 'browser-image-compression'
import blobToBase64 from 'utils/blobToBase64'
// import { PlusIcon } from 'utils/SystemIcons'
import { uniqueNamesGenerator, adjectives, animals, colors, names } from 'unique-names-generator'
import { AurasightIcon, PlusIcon } from 'utils/SystemIcons'
import AurasightAnnotationTool from './AurasightAnnotationTool'
import DialogConfirmation from 'components/DialogConfirmation/DialogConfirmation'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import constantsVox from 'constants-vox'

function chunkArray(array, chunkSize) {
	const chunks = []
	for (let i = 0; i < array.length; i += chunkSize) {
		chunks.push(array.slice(i, i + chunkSize))
	}
	return chunks
}

const ImageAnnotationProjectTasks = ({ project, isOpen, handleOnClose }) => {
	const auraProjectId = project.id
	const [tasks, setTasks] = useState([])
	const [, setLoading] = useState(true)
	const [, setError] = useState(null)
	const [uploading, setUploading] = useState(false)
	const [uploadMessage, setUploadMessage] = useState(null)
	const [key, setKey] = useState(Date.now())
	// const [isFullScreen, setIsFullScreen] = useState(false)
	const [taskId, setTaskId] = useState(null)
	const [taskImages, setTaskImages] = useState(null)
	const [taskStatus, setTaskStatus] = useState(null)
	const [continuationToken, setContinuationToken] = useState(null)
	const [openAnnotationTool, setOpenAnnotationTool] = useState(false)
	const [anchorEl, setAnchorEl] = useState(null)
	const [openConfirmChangeStatus, setOpenConfirmChangeStatus] = useState(false)
	const [newStatus, setNewStatus] = useState(false)
	const [isFirstRendering, setIsFirstRendering] = useState(true)

	const handleChipClick = (event, task) => {
		setTaskId(task.id)
		setAnchorEl(event.currentTarget)
	}

	const handleMenuClose = () => {
		setTaskId(null)
		setOpenConfirmChangeStatus(false)
		setAnchorEl(null)
	}

	const handleMenuClick = (option) => {
		setNewStatus(option)
		setOpenConfirmChangeStatus(true)
	}

	const handleSelectStatus = async () => {
		try {
			await api.post(`/image-annotation/change-status-task/${newStatus}/${taskId}`)
			await fetchTasks()
			handleMenuClose()
		} catch (err) {
			console.error('Error changing status task:', err)
			setError('Error changing status task')
		}
	}

	const fetchTasks = async (token = null, images = null) => {
		try {
			const response = await api.get(
				`/image-annotation/tasks/${auraProjectId}/${taskId}/${encodeURIComponent(token ? token : continuationToken)}`
			)
			if (Array.isArray(response.data.data)) {
				const newData = response.data.data.map((it) => {
					const newImages = (images ? images : taskImages) ?? []
					if (it.id === taskId) {
						it.images = [...newImages, ...it.images]
					}
					return it
				})
				setTasks(newData)
			} else {
				throw new Error('Unexpected response format')
			}
		} catch (err) {
			console.error('Error fetching tasks:', err)
			setError('Error fetching tasks')
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		if (openAnnotationTool) {
			const task = tasks.find((task) => task.id === taskId)
			setTaskImages(
				task.imagesAnnotation
					? task.imagesAnnotation
					: task.images.map((image) => ({
							...image,
							name: `${project.name} - ${task.name} - ${image.src.split('/').pop().split('.')[0]}`
					  }))
			)
		}
	}, [tasks])

	useEffect(() => {
		fetchTasks()
	}, [auraProjectId])

	const getStatusColor = (status) => {
		switch (status) {
			case 'ANNOTATION':
				return '#5FADFA'
			case 'COMPLETED':
				return 'green'
			case 'DELETED':
				return 'red'
			default:
				return 'grey'
		}
	}

	const createTask = async () => {
		const randomName = uniqueNamesGenerator({
			dictionaries: [names],
			style: 'capital'
		})
		const customConfig = {
			dictionaries: [adjectives, colors, animals],
			separator: ' ',
			style: 'capital'
		}
		const nameNewTask = `Task ${randomName}, aka the "${uniqueNamesGenerator(customConfig)}"`

		try {
			const response = await api.post(`/image-annotation/create-task/${encodeURIComponent(nameNewTask)}/project/${auraProjectId}`)
			sendMessageToast('Tarefa criada com sucesso! Nome: ' + nameNewTask, constantsVox.TOAST.TYPES.SUCCESS)
			await fetchTasks()
			return response.data
		} catch (error) {
			console.error('Erro ao criar tarefa:', error)
			sendMessageToast('Erro ao criar tarefa.', constantsVox.TOAST.TYPES.ERROR)
		}
	}

	const handleImageUpload = async (imageFile, image64, taskId = null) => {
		setUploading(true)
		setUploadMessage('Uploading images...')

		if (imageFile.length > 500) {
			sendMessageToast('Você só pode fazer upload de no máximo 500 imagens por vez.', constantsVox.TOAST.TYPES.WARNING)
			return
		}

		const chunksFile = chunkArray(imageFile, 100)
		const chunks64 = chunkArray(image64, 100)

		for (const [idx, chunk] of chunksFile.entries()) {
			let filteredFile = []
			const validPictures = chunk.filter((picture, index) => {
				if (picture.size > 5 * 1024 * 1024) {
					filteredFile.push(index)
					sendMessageToast(`A imagem ${picture.name} excede o limite de 5MB.`, constantsVox.TOAST.TYPES.WARNING)
					return false
				}
				return true
			})
			const valid64 = chunks64[idx].filter((_, index) => !filteredFile.includes(index))

			let idTask = taskId
			if (!idTask) {
				const taskCreated = await createTask()
				if (taskCreated?.data) {
					idTask = taskCreated.data.id
				}
			}
			if (idTask) {
				const images = await Promise.all(
					validPictures.map(async (file, index) => {
						if (file.type === 'image/webp') {
							const options = {
								maxSizeMB: 1,
								maxWidthOrHeight: 1920,
								useWebWorker: true,
								fileType: 'image/jpeg' // Specify the output format as JPEG
							}
							const compressedFile = await imageCompression(file, options)
							return await blobToBase64(compressedFile)
						} else {
							return valid64[index]
						}
					})
				)

				try {
					await api.post(`/image-annotation/task/${idTask}/project/${auraProjectId}/upload-images`, { images })
					sendMessageToast('Imagens enviadas com sucesso para a tarefa!', constantsVox.TOAST.TYPES.SUCCESS)
					await fetchTasks()
				} catch (error) {
					console.error('Erro ao enviar imagens:', error)
					sendMessageToast('Erro ao enviar imagens.', constantsVox.TOAST.TYPES.ERROR)
				}
			}
		}

		setUploading(false)
		setUploadMessage(null)
		setKey(Date.now())
	}

	const handleAllInputsUpload = async () => {
		if (tasks.find((task) => task.status === 'COMPLETED')) {
			for (const task of tasks) {
				if (task.status === 'COMPLETED') {
					await handleInputsUpload(task.id, task.name)
				}
			}
		} else {
			sendMessageToast('Nenhuma task apta para fazer upload.', constantsVox.TOAST.TYPES.INFO)
		}
	}

	const handleInputsUpload = async (taskId, taskName) => {
		setUploading(true)
		setUploadMessage(`${taskName} - Uploading inputs..`)

		try {
			await api.post(`/image-annotation/task/${taskId}/project/${auraProjectId}/upload-inputs`)
			sendMessageToast(`${taskName} - Inputs enviadas com sucesso para o S3!`, constantsVox.TOAST.TYPES.SUCCESS)
			await fetchTasks()
		} catch (error) {
			console.error('Erro ao enviar inputs:', error)
			sendMessageToast('Erro ao enviar inputs.', constantsVox.TOAST.TYPES.ERROR)
		}

		setUploading(false)
		setUploadMessage(null)
	}

	const handleInputsDelete = async () => {
		setUploading(true)
		setUploadMessage('Limpando inputs..')

		try {
			await api.post(`/image-annotation/project/${auraProjectId}/delete-inputs`)
			sendMessageToast('Inputs deletados com sucesso do S3!', constantsVox.TOAST.TYPES.SUCCESS)
			await fetchTasks()
		} catch (error) {
			console.error('Erro ao deletar inputs:', error)
			sendMessageToast('Erro ao deletar inputs.', constantsVox.TOAST.TYPES.ERROR)
		}

		setUploading(false)
		setUploadMessage(null)
	}

	// const handleSyncData = async () => {
	// 	setUploading(true)
	// 	setUploadMessage('Sincronizando dados..')

	// 	try {
	// 		await api.post(`/image-annotation/project/${auraProjectId}/${project.cvatId}/sync-tasks`)
	// 		alert('Dados sincronizados!')
	// 		await fetchTasks()
	// 	} catch (error) {
	// 		console.error('Erro ao sincronizar dados:', error)
	// 		alert('Erro ao sincronizar dados.')
	// 	}

	// 	setUploading(false)
	// 	setUploadMessage(null)
	// }

	const handleFirstRendering = () => {
		const timer = setTimeout(() => setOpenAnnotationTool(true), 0)
		setIsFirstRendering(false)
		return () => {
			clearTimeout(timer)
		}
	}

	return (
		<Dialog fullWidth={true} maxWidth='md' open={isOpen} onClose={handleOnClose}>
			<DialogTitle>
				Lista de Tarefas do Projeto {auraProjectId} - {project.name}
			</DialogTitle>
			<DialogContent>
				<ImageUploader
					key={key}
					buttonClassName='image-button'
					withIcon={false}
					withLabel={false}
					buttonText={
						<span>
							<PlusIcon style={{ marginBottom: '-1px' }} /> NOVA TAREFA
						</span>
					}
					buttonStyles={{ border: '1px solid' }}
					fileContainerStyle={{
						backgroundColor: 'transparent',
						boxShadow: 'none',
						alignItems: 'start'
					}}
					onChange={handleImageUpload}
					imgExtension={['.png', '.jpg', '.jpeg', '.webp']}
					maxFileSize={5242880}
				/>
				{uploading && (
					<Box display='flex' alignItems='center' flexDirection='column' mt={2}>
						<CircularProgress />
						<Typography variant='body2' mt={1}>
							{uploadMessage}
						</Typography>
					</Box>
				)}
				<Grid container>
					<Grid item xs={6}>
						<Typography variant='h6' gutterBottom>
							Total de Tarefas: {tasks.length}
						</Typography>
						{/* <Button variant='contained' onClick={handleSyncData}>
							Sincronizar dados
						</Button> */}
					</Grid>
					<Grid item xs={6} display={'flex'} justifyContent={'end'}>
						<Button variant='contained' style={{ marginRight: '10px' }} onClick={handleAllInputsUpload}>
							Upload inputs S3
						</Button>
						<Button variant='contained' onClick={handleInputsDelete}>
							Limpar inputs S3
						</Button>
					</Grid>
				</Grid>
				<Box style={{ width: '95%', padding: '16px', backgroundColor: '#f7f7f7' }}>
					<List>
						{tasks.map((task) => (
							<ListItem key={task.id} style={{ padding: '8px', marginBottom: '8px' }}>
								<Card style={{ width: '100%' }}>
									<Grid container>
										<Grid item xs={4}>
											<CardContent style={{ padding: '8px' }}>
												{/* <Typography variant='h6' gutterBottom style={{ marginLeft: '10px' }}> */}
												<Typography variant='h6' gutterBottom>
													{`${task.id} - ${task.name}`}
												</Typography>
												{/* {task.status !== 'COMPLETED' && (
													<ImageUploader
														key={key}
														buttonClassName='image-button'
														withIcon={false}
														withLabel={false}
														buttonText={
															<Tooltip title='Adicionar mais fotos' placement='top'>
																<span>
																	<PlusIcon />
																</span>
															</Tooltip>
														}
														fileContainerStyle={{
															backgroundColor: 'transparent',
															boxShadow: 'none',
															height: '0px',
															width: '0px',
															marginTop: '-42.5px',
															marginLeft: '0px'
															// marginRight: '0px'
														}}
														onChange={(imageFile, image64) => handleImageUpload(imageFile, image64, task.id)}
														imgExtension={['.png', '.jpg', '.jpeg', '.webp']}
														maxFileSize={5242880}
													/>
												)} */}
												<Chip
													onClick={(event) => handleChipClick(event, task)}
													label={task.status}
													style={{
														backgroundColor: getStatusColor(task.status),
														color: 'white',
														marginBottom: '8px',
														fontSize: '0.75rem'
													}}
												/>
												<Menu anchorEl={anchorEl} open={Boolean(anchorEl) && taskId === task.id} onClose={handleMenuClose}>
													<MenuItem onClick={() => handleMenuClick('ANNOTATION')}>ANNOTATION</MenuItem>
													<MenuItem onClick={() => handleMenuClick('COMPLETED')}>COMPLETED</MenuItem>
													<MenuItem onClick={() => handleMenuClick('DELETED')}>DELETED</MenuItem>
												</Menu>
												<Typography variant='body2' style={{ fontSize: '0.875rem' }}>
													CVAT ID: {task.cvatId}
												</Typography>
												<Typography variant='body2' style={{ fontSize: '0.875rem' }}>
													Projeto ID: {task.auraProjectsId}
												</Typography>
												<Typography variant='body2' style={{ fontSize: '0.875rem' }}>
													Criado em: {moment(task.createdAt).format('DD/MM/YYYY')}
												</Typography>
												<Typography variant='body2' style={{ fontSize: '0.875rem' }}>
													Status upload: {task.saveModelInputLastDate ? 'OK' : 'Não realizado'}
												</Typography>
												{task.saveModelInputLastDate && (
													<Typography variant='body2' style={{ fontSize: '0.875rem' }}>
														Ultimo upload em: {moment(task.saveModelInputLastDate).format('DD/MM/YYYY')}
													</Typography>
												)}
												{task.status === 'COMPLETED' && (
													<Button variant='contained' onClick={() => handleInputsUpload(task.id, task.name)}>
														Upload inputs S3
													</Button>
												)}
											</CardContent>
										</Grid>
										<Grid item xs={3} />
										<Grid item xs={2} display={'flex'}>
											<CardContent>
												<Button
													style={{ border: '2px solid', marginTop: '1.5em', width: '125px' }}
													color='primary'
													onClick={() => {
														setTaskId(task.id)
														setTaskStatus(task.status)
														setTaskImages(
															task.imagesAnnotation
																? task.imagesAnnotation
																: task.images.map((image) => ({
																		...image,
																		name: `${project.name} - ${task.name} - ${
																			image.src.split('/').pop().split('.')[0]
																		}`
																  }))
														)
														setOpenAnnotationTool(true)
													}}
												>
													<Grid container>
														<Grid item xs={12}>
															<AurasightIcon size={60} />
														</Grid>
														<Grid item xs={12} style={{ fontWeight: 'bold' }}>
															Aurasight Annotation Tool
														</Grid>
													</Grid>
												</Button>
												<Typography variant='h6' justifyContent={'center'} display={'flex'}>
													{task.images.length} {task.images.length === 1 ? ' imagem' : ' imagens'}
												</Typography>
												{/* {task.images.length > 0 && (
													<>
														{!isFullScreen && (
															<ImageGallery
																items={task?.images?.map((image) => ({
																	original: image.src,
																	originalHeight: '200px',
																	originalWidth: '300px'
																}))}
																useBrowserFullscreen={false}
																showBullets={task?.images?.length <= 10 ? true : false}
																showIndex={true}
																autoPlay={true}
																onScreenChange={() => {
																	if (!openAnnotationTool) {
																		setTaskId(task.id)
																		setTaskImages(task.images)
																		setContinuationToken(task.continuationTokenImages)
																		setIsFullScreen(!isFullScreen)
																	}
																}}
															/>
														)}
														{isFullScreen && task.id === taskId && (
															<Dialog
																fullWidth={true}
																maxWidth='md'
																open={isFullScreen}
																onClose={() => {
																	setTaskId(null)
																	setTaskImages(null)
																	setContinuationToken(null)
																	setIsFullScreen(false)
																}}
															>
																<Gallery
																	photos={task?.images?.map((image) => ({
																		...image,
																		height: 1,
																		width: 1
																	}))}
																/>
																{task.hasMoreImages && (
																	<Button
																		variant='contained'
																		onClick={() => fetchTasks(task.continuationTokenImages, task.images)}
																	>
																		Carregar mais
																	</Button>
																)}
															</Dialog>
														)}
													</>
												)} */}
											</CardContent>
										</Grid>
										<Grid item xs={3} />
									</Grid>
								</Card>
							</ListItem>
						))}
					</List>
					{openConfirmChangeStatus && (
						<DialogConfirmation
							open={openConfirmChangeStatus}
							handleClose={() => setOpenConfirmChangeStatus(false)}
							title={'Deseja realmente alterar o status da tarefa?'}
							buttonText={'Alterar'}
							handleConfirm={handleSelectStatus}
						/>
					)}
					{openAnnotationTool && (
						<AurasightAnnotationTool
							isFirstRendering={isFirstRendering}
							isOpen={openAnnotationTool}
							handleOnClose={() => {
								setOpenAnnotationTool(false)
								isFirstRendering && handleFirstRendering()
								!isFirstRendering && setTaskImages([])
							}}
							updateTasks={async () => {
								await fetchTasks(null, [])
							}}
							taskImages={taskImages}
							labels={project.auraLogosIds.map((item, index) => ({
								id: item,
								name: project.auraLogosNames[index]
							}))}
							taskId={taskId}
							taskStatus={taskStatus}
						/>
					)}
				</Box>
			</DialogContent>
		</Dialog>
	)
}

export default ImageAnnotationProjectTasks
