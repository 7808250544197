import React, { useEffect, useState } from 'react'
import {
	Box,
	Button,
	CircularProgress,
	Container,
	FormControl,
	FormHelperText,
	Link,
	TextField,
	Grid,
	Checkbox,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grow,
	InputAdornment,
	Snackbar,
	Alert
} from '@mui/material'
import { AccountCircle, Lock } from '@mui/icons-material'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import LogRocket from 'logrocket'
import api from 'service/service'
import { loginAction } from '../../store/redux/actions/user.actions'
import VoxLogo from 'assets/img/react-logo-2.png'
import constantsVox from 'constants-vox'

const isFirefox = typeof InstallTrigger !== 'undefined'

const Login = () => {
	const dispatch = useDispatch()
	const [messageSuccessOpen, setMessageSuccessOpen] = useState(false)
	const [serverErrorsLogin, setServerErrorsLogin] = useState()
	const [serverErrorsForgotPassword, setServerErrorsForgotPassword] = useState()
	const { register: register1, formState: formState1, handleSubmit: handleSubmit1 } = useForm()
	const { register: register2, getValues: getValues2, formState: formState2, handleSubmit: handleSubmit2, clearErrors: clearErrors2 } = useForm()
	const [loadingLogin, setLoadingLogin] = useState(false)
	const [loadingForgotPassword, setLoadingForgotPassword] = useState(false)
	const [openForgotPassword, setOpenForgotPassword] = useState(false)
	const [keepConnected, setKeepConnected] = useState(false)
	const [isOpenAdminLoginField, setIsOpenAdminLoginField] = useState(false)
	const [logo, setLogo] = useState(null)

	useEffect(() => {
		const currentBaseUrl = window.location.origin.replace(/(http:\/\/|https:\/\/|www\.)/g, '').replace(/\/$/, '')
		if (constantsVox?.WHITE_LABEL?.BASE_DOMAINS?.includes(currentBaseUrl)) {
			setLogo(VoxLogo)
		} else {
			api.post('/user-white-label/get', { currentBaseUrl }).then((response) => {
				if (response.data?.images?.logo) {
					setLogo(response.data?.images?.logo)
				} else {
					setLogo(VoxLogo)
				}
			})
		}
	}, [])

	const handleMessageSuccessClose = (event, reason) => {
		if (reason === 'clickaway') {
			return
		}
		setMessageSuccessOpen(false)
	}

	const handleOpenForgotPassword = () => {
		setOpenForgotPassword(true)
	}

	const handleClose = () => {
		clearErrors2()
		setOpenForgotPassword(false)
	}

	const onSubmitLogin = async (formData) => {
		setLoadingLogin(true)
		setServerErrorsLogin('')
		await api
			.post('/login', { ...formData, keepConnected, proceedAsAdmin: isOpenAdminLoginField })
			.then((response) => {
				if (response.data.isAdmin && !response.data.proceedAsAdminId) {
					setIsOpenAdminLoginField(true)
					setLoadingLogin(false)
				} else {
					setLoadingLogin(false)
					dispatch(loginAction(response.data))
					LogRocket.identify(response.data.id_usuario, {
						name: response.data.name,
						email: response.data.email
					})
				}
			})
			.catch((error) => {
				setServerErrorsLogin('Falha na requisição.')
				if (error.response.data) {
					setServerErrorsLogin(error.response.data.error)
				} else {
					setServerErrorsLogin('Falha na requisição.')
				}
				setLoadingLogin(false)
			})
	}

	const onSubmitForgotPassword = async (formData) => {
		setLoadingForgotPassword(true)
		setServerErrorsForgotPassword('')

		try {
			const response = await api.post('/forgot-password', {
				email: formData.email_forgot_password
			})

			if (response.data.status === 200) {
				setMessageSuccessOpen(true)
				setOpenForgotPassword(false)
			}
		} catch (e) {
			if (e.response.status === 422) {
				e.response.data.errors.forEach((e) => {
					setServerErrorsForgotPassword(e[Object.keys(e)])
				})
			} else if (e.response.data) {
				setServerErrorsForgotPassword(e.response.data.message)
			} else {
				setServerErrorsForgotPassword('Falha na requisição.')
			}
		} finally {
			setLoadingForgotPassword(false)
		}
	}

	return (
		<div style={{ height: '85vh', paddingTop: '0.5em' }}>
			<Container>
				<Box display='flex' justifyContent='center' alignItems='center'>
					<Grow in={true}>
						<Box
							bgcolor='background.paper'
							pt={2}
							pb={2}
							pl={2}
							pr={2}
							mt={10}
							mb={2}
							style={{
								borderRadius: '20px',
								backdropFilter: 'blur(10px)',
								backgroundColor: 'rgba(255, 255, 255, 1)',
								boxShadow: '0 2px 5px -2px rgba( 200, 200, 200, 0.7 )'
							}}
						>
							<Box display='flex' justifyContent='center' alignItems='center' pb={4} pt={4} style={{ height: '10em', width: '22em' }}>
								{logo && <img src={logo} style={{ height: '10em', width: '10em' }} alt='logo' />}
							</Box>
							<form onSubmit={handleSubmit1(onSubmitLogin)} key={1} id='form-login'>
								<FormControl fullWidth={true} error={formState1.errors ? true : false}>
									<Box m={1}>
										<TextField
											id='email'
											label='Email'
											variant='outlined'
											autoComplete='username'
											fullWidth={true}
											error={formState1.errors.email ? true : false}
											helperText={formState1.errors.email ? formState1.errors.email.message : ''}
											{...register1('email', {
												required: 'Email é obrigatório',
												pattern: {
													value: /\S+@\S+\.\S+/,
													message: 'Digite um email válido'
												}
											})}
											InputProps={{
												startAdornment: (
													<InputAdornment position='start'>
														<AccountCircle />
													</InputAdornment>
												)
											}}
										/>
									</Box>

									<Box m={1}>
										<TextField
											id='password'
											label='Senha'
											variant='outlined'
											fullWidth={true}
											type='password'
											autoComplete='current-password'
											error={formState1.errors.password ? true : false}
											helperText={formState1.errors.password ? formState1.errors.password.message : ''}
											{...register1('password', {
												required: 'Senha é obrigatório'
											})}
											InputProps={{
												startAdornment: (
													<InputAdornment position='start'>
														<Lock />
													</InputAdornment>
												)
											}}
										/>
									</Box>

									{isOpenAdminLoginField && (
										<Box m={1}>
											<TextField
												id='email-user'
												label='Entrar com outro usuário (opcional)'
												variant='outlined'
												autoComplete='username'
												fullWidth={true}
												error={formState1.errors.emailUser ? true : false}
												helperText={formState1.errors.emailUser ? formState1.errors.emailUser.message : ''}
												{...register1('emailUser', {
													required: false,
													pattern: {
														value: /\S+@\S+\.\S+/,
														message: 'Digite um email válido'
													}
												})}
												InputProps={{
													startAdornment: (
														<InputAdornment position='start'>
															<AccountCircle />
														</InputAdornment>
													)
												}}
											/>
										</Box>
									)}

									<Grid container style={{ alignItems: 'baseline' }}>
										<Grid item style={{ display: 'flex', alignItems: 'center' }}>
											<Checkbox
												id='keep-connected-login'
												value={keepConnected}
												color='primary'
												onChange={(e, checked) => setKeepConnected(checked)}
											/>
											<div style={{ fontFamily: 'Poppins', marginTop: '-2px' }}>Manter conectado</div>
										</Grid>
									</Grid>

									<Box m={1}>
										{serverErrorsLogin && <FormHelperText className='fix-errors-custom'>{serverErrorsLogin}</FormHelperText>}
									</Box>

									<Box m={2} textAlign='center'>
										{!loadingLogin && (
											<Button
												id='btn-login'
												variant='contained'
												color='primary'
												type='submit'
												style={{ width: '50%', borderRadius: '100px', fontFamily: 'Poppins' }}
											>
												Entrar
											</Button>
										)}

										{loadingLogin && <CircularProgress />}
									</Box>
								</FormControl>
							</form>
							<Box textAlign='center' style={{ marginTop: '0.5em', fontFamily: 'Poppins', fontSize: '14px' }}>
								<Link id='forgot-password-login' href='#' color='primary' onClick={handleOpenForgotPassword}>
									Esqueci a senha
								</Link>
							</Box>
						</Box>
					</Grow>

					<Dialog
						open={openForgotPassword}
						onClose={handleClose}
						aria-labelledby='form-dialog-title'
						PaperProps={{
							style: {
								borderRadius: '20px',
								backdropFilter: 'blur(10px)',
								backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
								border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
								boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )',
								width: '30em'
							}
						}}
						BackdropProps={{
							style: {
								backdropFilter: 'blur(3px)',
								backgroundColor: 'rgba(220, 220, 220, 0.4)'
							}
						}}
					>
						<DialogTitle id='form-dialog-title'>Recuperar minha senha</DialogTitle>
						<DialogContent>
							<DialogContentText>Digite seu email cadastrado</DialogContentText>

							<form onSubmit={handleSubmit2(onSubmitForgotPassword)} key={2} id='form-forgot-password'>
								<FormControl fullWidth={true} error={formState2.errors ? true : false}>
									<Box m={1}>
										<TextField
											id='email_forgot_password'
											label='Email'
											variant='outlined'
											fullWidth={true}
											error={formState2.errors.email_forgot_password ? true : false}
											helperText={
												formState2.errors.email_forgot_password ? formState2.errors.email_forgot_password.message : ''
											}
											{...register2('email_forgot_password', {
												required: 'Email é obrigatório',
												pattern: {
													value: /\S+@\S+\.\S+/,
													message: 'Digite um email válido'
												}
											})}
											InputProps={{
												startAdornment: (
													<InputAdornment position='start'>
														<AccountCircle />
													</InputAdornment>
												)
											}}
										/>
									</Box>

									<Box m={1}>
										{serverErrorsForgotPassword && (
											<FormHelperText className='fix-errors-custom'>{serverErrorsForgotPassword}</FormHelperText>
										)}
									</Box>

									<Box m={2} textAlign='center'>
										{!loadingForgotPassword && (
											<Button
												variant='contained'
												color='primary'
												type='submit'
												style={{ borderRadius: '100px', fontFamily: 'Poppins' }}
											>
												Recuperar senha
											</Button>
										)}

										{loadingForgotPassword && <CircularProgress />}
									</Box>
								</FormControl>
							</form>
						</DialogContent>
					</Dialog>
				</Box>
			</Container>
			<Snackbar open={messageSuccessOpen} autoHideDuration={6000} onClose={handleMessageSuccessClose}>
				<Alert onClose={handleMessageSuccessClose} severity='success'>
					Enviamos um e-mail com instruções para a criação de uma nova senha para: <b>{getValues2('email_forgot_password')}</b>
				</Alert>
			</Snackbar>
		</div>
	)
}

export default withRouter(Login)
