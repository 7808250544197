import React from 'react'
import NumericMetric from '../../../../components/metrics/accountManagerOldMetrics/NumericMetric/NumericMetric'
import LineMetrics from '../../../../components/metrics/accountManagerOldMetrics/LineMetrics/LineMetrics'
import PostsFeedDashboardShareable from '../../../../components/metrics/accountManagerOldMetrics/PostsFeedDashboardShareable/PostsFeedDashboardShareable'
import TopCommentsDashboardShareable from '../../../../components/metrics/accountManagerOldMetrics/TopCommentsDashboardShareable/TopCommentsDashboardShareable'
export { getComponentsToRenderMetaAdsAccountManager, DEFAULT_LAYOUTS_META_ADS_ACCOUNT_MANAGER, DEFAULT_WIDGETS_META_ADS_ACCOUNT_MANAGER }

const getComponentsToRenderMetaAdsAccountManager = (metaAdsMetrics, editMode, addWidgetMode, removeWidget, cards, descriptions) => [
	{
		Component: (
			<LineMetrics
				isHorizontal={false}
				graphData={metaAdsMetrics?.apiSeries?.graphs?.impressionsFormatted}
				title={descriptions.find((item) => item.key === 'meta_ads_impressions_timeline')?.title}
				keyName='meta_ads_impressions_timeline'
				label='Cidades'
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'meta_ads_impressions_timeline')?.description}
			/>
		),
		key: 'meta_ads_impressions_timeline'
	},
	{
		Component: (
			<NumericMetric
				data={metaAdsMetrics?.apiSeries?.graphsTotalMetrics?.clicksFormattedTotal}
				title={descriptions.find((item) => item.key === 'meta_ads_clicks')?.title}
				keyName='meta_ads_clicks'
				graphData={metaAdsMetrics?.apiSeries?.graphs?.clicksFormatted}
				source='metaAds'
				isOverview={true}
				dataType={descriptions.find((item) => item.key === 'meta_ads_clicks')?.data_type}
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'meta_ads_clicks')?.description}
			/>
		),
		key: 'meta_ads_clicks'
	},
	{
		Component: (
			<NumericMetric
				data={metaAdsMetrics?.apiSeries?.graphsTotalMetrics?.impressionsFormattedTotal}
				title={descriptions.find((item) => item.key === 'meta_ads_impressions')?.title}
				keyName='meta_ads_impressions'
				graphData={metaAdsMetrics?.apiSeries?.graphs?.impressionsFormatted}
				source='metaAds'
				isOverview={true}
				dataType={descriptions.find((item) => item.key === 'meta_ads_impressions')?.data_type}
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'meta_ads_impressions')?.description}
			/>
		),
		key: 'meta_ads_impressions'
	},
	{
		Component: (
			<NumericMetric
				data={metaAdsMetrics?.apiSeries?.graphsTotalMetrics?.pageEngagementFormattedTotal}
				title={descriptions.find((item) => item.key === 'meta_ads_page_engagement')?.title}
				keyName='meta_ads_page_engagement'
				graphData={metaAdsMetrics?.apiSeries?.graphs?.pageEngagementFormatted}
				source='metaAds'
				isOverview={true}
				dataType={descriptions.find((item) => item.key === 'meta_ads_page_engagement')?.data_type}
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'meta_ads_page_engagement')?.description}
			/>
		),
		key: 'meta_ads_page_engagement'
	},
	{
		Component: (
			<NumericMetric
				data={metaAdsMetrics?.apiSeries?.graphsTotalMetrics?.postEngagementFormattedTotal}
				title={descriptions.find((item) => item.key === 'meta_ads_post_engagement')?.title}
				keyName='meta_ads_post_engagement'
				graphData={metaAdsMetrics?.apiSeries?.graphs?.postEngagementFormatted}
				source='metaAds'
				isOverview={true}
				dataType={descriptions.find((item) => item.key === 'meta_ads_post_engagement')?.data_type}
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'meta_ads_post_engagement')?.description}
			/>
		),
		key: 'meta_ads_post_engagement'
	},
	{
		Component: (
			<NumericMetric
				data={metaAdsMetrics?.apiSeries?.graphsTotalMetrics?.postReactionsFormattedTotal}
				title={descriptions.find((item) => item.key === 'meta_ads_post_reactions')?.title}
				keyName='meta_ads_post_reactions'
				graphData={metaAdsMetrics?.apiSeries?.graphs?.postReactionsFormatted}
				source='metaAds'
				isOverview={true}
				dataType={descriptions.find((item) => item.key === 'meta_ads_post_reactions')?.data_type}
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'meta_ads_post_reactions')?.description}
			/>
		),
		key: 'meta_ads_post_reactions'
	},
	{
		Component: (
			<NumericMetric
				data={metaAdsMetrics?.apiSeries?.graphsTotalMetrics?.spendFormattedTotal}
				title={descriptions.find((item) => item.key === 'meta_ads_spend')?.title}
				keyName='meta_ads_spend'
				graphData={metaAdsMetrics?.apiSeries?.graphs?.spendFormatted}
				source='metaAds'
				isOverview={true}
				dataType={descriptions.find((item) => item.key === 'meta_ads_spend')?.data_type}
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'meta_ads_spend')?.description}
			/>
		),
		key: 'meta_ads_spend'
	},
	{
		Component: (
			<NumericMetric
				data={metaAdsMetrics?.apiSeries?.graphsTotalMetrics?.videoViewsFormattedTotal}
				title={descriptions.find((item) => item.key === 'meta_ads_video_views')?.title}
				keyName='meta_ads_video_views'
				graphData={metaAdsMetrics?.apiSeries?.graphs?.videoViewsFormatted}
				source='metaAds'
				isOverview={true}
				dataType={descriptions.find((item) => item.key === 'meta_ads_video_views')?.data_type}
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'meta_ads_video_views')?.description}
			/>
		),
		key: 'meta_ads_video_views'
	},
	{
		Component: (
			<NumericMetric
				data={metaAdsMetrics?.apiSeries?.graphsTotalMetrics?.PurchaseRoasFormattedTotal}
				title={descriptions.find((item) => item.key === 'meta_ads_purchase_roas')?.title}
				keyName='meta_ads_purchase_roas'
				graphData={metaAdsMetrics?.apiSeries?.graphs?.PurchaseRoasFormatted}
				source='metaAds'
				isOverview={true}
				dataType={descriptions.find((item) => item.key === 'meta_ads_purchase_roas')?.data_type}
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'meta_ads_purchase_roas')?.description}
			/>
		),
		key: 'meta_ads_purchase_roas'
	},
	{
		Component: (
			<NumericMetric
				data={metaAdsMetrics?.apiSeries?.graphsTotalMetrics?.videoP25WatchedViewsFormattedTotal}
				title={descriptions.find((item) => item.key === 'meta_ads_video_p25_watched_views')?.title}
				keyName='meta_ads_video_p25_watched_views'
				graphData={metaAdsMetrics?.apiSeries?.graphs?.videoP25WatchedViewsFormatted}
				source='metaAds'
				isOverview={true}
				dataType={descriptions.find((item) => item.key === 'meta_ads_video_p25_watched_views')?.data_type}
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'meta_ads_video_p25_watched_views')?.description}
			/>
		),
		key: 'meta_ads_video_p25_watched_views'
	},
	{
		Component: (
			<NumericMetric
				data={metaAdsMetrics?.apiSeries?.graphsTotalMetrics?.videoP100WatchedViewsFormattedTotal}
				title={descriptions.find((item) => item.key === 'meta_ads_video_p100_watched_actions')?.title}
				keyName='meta_ads_video_p100_watched_actions'
				graphData={metaAdsMetrics?.apiSeries?.graphs?.videoP100WatchedViewsFormatted}
				source='metaAds'
				isOverview={true}
				dataType={descriptions.find((item) => item.key === 'meta_ads_video_p100_watched_actions')?.data_type}
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'meta_ads_video_p100_watched_actions')?.description}
			/>
		),
		key: 'meta_ads_video_p100_watched_actions'
	},
	{
		Component: (
			<NumericMetric
				data={metaAdsMetrics?.apiSeries?.graphsTotalMetrics?.socialSpendFormattedTotal}
				title={descriptions.find((item) => item.key === 'meta_ads_social_spend')?.title}
				keyName='meta_ads_social_spend'
				graphData={metaAdsMetrics?.apiSeries?.graphs?.socialSpendFormatted}
				source='metaAds'
				isOverview={true}
				dataType={descriptions.find((item) => item.key === 'meta_ads_social_spend')?.data_type}
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'meta_ads_social_spend')?.description}
			/>
		),
		key: 'meta_ads_social_spend'
	},
	{
		Component: (
			<NumericMetric
				data={metaAdsMetrics?.apiSeries?.graphsTotalMetrics?.inlineLinkClicksFormattedTotal}
				title={descriptions.find((item) => item.key === 'meta_ads_inline_link_clicks')?.title}
				keyName='meta_ads_inline_link_clicks'
				graphData={metaAdsMetrics?.apiSeries?.graphs?.inlineLinkClicksFormatted}
				source='metaAds'
				isOverview={true}
				dataType={descriptions.find((item) => item.key === 'meta_ads_inline_link_clicks')?.data_type}
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'meta_ads_inline_link_clicks')?.description}
			/>
		),
		key: 'meta_ads_inline_link_clicks'
	},
	{
		Component: (
			<NumericMetric
				data={metaAdsMetrics?.apiSeries?.graphsTotalMetrics?.inlinePostEngagementFormattedTotal}
				title={descriptions.find((item) => item.key === 'meta_ads_inline_post_engagement')?.title}
				keyName='meta_ads_inline_post_engagement'
				graphData={metaAdsMetrics?.apiSeries?.graphs?.inlinePostEngagementFormatted}
				source='metaAds'
				isOverview={true}
				dataType={descriptions.find((item) => item.key === 'meta_ads_inline_post_engagement')?.data_type}
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'meta_ads_inline_post_engagement')?.description}
			/>
		),
		key: 'meta_ads_inline_post_engagement'
	},
	{
		Component: (
			<LineMetrics
				isHorizontal={false}
				graphData={metaAdsMetrics?.apiSeries?.graphs?.reachFormatted}
				title={descriptions.find((item) => item.key === 'meta_ads_reach')?.title}
				keyName='meta_ads_reach'
				label=''
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'meta_ads_reach')?.description}
			/>
		),
		key: 'meta_ads_reach'
	},
	{
		Component: (
			<LineMetrics
				isHorizontal={false}
				graphData={metaAdsMetrics?.apiSeries?.graphs?.inlineLinkClickCtrFormatted}
				title={descriptions.find((item) => item.key === 'meta_ads_inline_link_click_ctr')?.title}
				keyName='meta_ads_inline_link_click_ctr'
				label=''
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'meta_ads_inline_link_click_ctr')?.description}
			/>
		),
		key: 'meta_ads_inline_link_click_ctr'
	},
	{
		Component: (
			<LineMetrics
				isHorizontal={false}
				graphData={metaAdsMetrics?.apiSeries?.graphs?.costPerUniqueClickFormatted}
				title={descriptions.find((item) => item.key === 'meta_ads_cost_per_unique_click')?.title}
				keyName='meta_ads_cost_per_unique_click'
				label=''
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'meta_ads_cost_per_unique_click')?.description}
			/>
		),
		key: 'meta_ads_cost_per_unique_click'
	},
	{
		Component: (
			<LineMetrics
				isHorizontal={false}
				graphData={metaAdsMetrics?.apiSeries?.graphs?.cppFormatted}
				title={descriptions.find((item) => item.key === 'meta_ads_cpp')?.title}
				keyName='meta_ads_cpp'
				label=''
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'meta_ads_cpp')?.description}
			/>
		),
		key: 'meta_ads_cpp'
	},
	{
		Component: (
			<LineMetrics
				isHorizontal={false}
				graphData={metaAdsMetrics?.apiSeries?.graphs?.frequencyFormatted}
				title={descriptions.find((item) => item.key === 'meta_ads_frequency')?.title}
				keyName='meta_ads_frequency'
				label=''
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'meta_ads_frequency')?.description}
			/>
		),
		key: 'meta_ads_frequency'
	},
	{
		Component: (
			<LineMetrics
				isHorizontal={false}
				graphData={metaAdsMetrics?.apiSeries?.graphs?.conversionsFormatted}
				title={descriptions.find((item) => item.key === 'meta_ads_conversions')?.title}
				keyName='meta_ads_conversions'
				label=''
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'meta_ads_conversions')?.description}
			/>
		),
		key: 'meta_ads_conversions'
	},
	{
		Component: (
			<LineMetrics
				isHorizontal={false}
				graphData={metaAdsMetrics?.apiSeries?.graphs?.costPer15SecVideoViewFormatted}
				title={descriptions.find((item) => item.key === 'meta_ads_cost_per_15_sec_video_view')?.title}
				keyName='meta_ads_cost_per_15_sec_video_view'
				label=''
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'meta_ads_cost_per_15_sec_video_view')?.description}
			/>
		),
		key: 'meta_ads_cost_per_15_sec_video_view'
	},
	{
		Component: (
			<NumericMetric
				title={descriptions.find((item) => item.key === 'meta_ads_cost_per_post_engagement')?.title}
				keyName='meta_ads_cost_per_post_engagement'
				data={metaAdsMetrics?.apiSeries?.graphsTotalMetrics?.costPerPostEngagementFormattedTotal}
				graphData={metaAdsMetrics?.apiSeries?.graphs?.costPerPostEngagementFormatted}
				source='metaAds'
				isOverview={true}
				dataType={descriptions.find((item) => item.key === 'meta_ads_cost_per_post_engagement')?.data_type}
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'meta_ads_cost_per_post_engagement')?.description}
				isFullValue={true}
			/>
		),
		key: 'meta_ads_cost_per_post_engagement'
	},
	{
		Component: (
			<NumericMetric
				title={descriptions.find((item) => item.key === 'meta_ads_cost_per_video_view')?.title}
				keyName='meta_ads_cost_per_video_view'
				data={metaAdsMetrics?.apiSeries?.graphsTotalMetrics?.costPerVideoViewFormattedTotal}
				graphData={metaAdsMetrics?.apiSeries?.graphs?.costPerVideoViewFormatted}
				source='metaAds'
				isOverview={true}
				dataType={descriptions.find((item) => item.key === 'meta_ads_cost_per_video_view')?.data_type}
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'meta_ads_cost_per_video_view')?.description}
				isFullValue={true}
			/>
		),
		key: 'meta_ads_cost_per_video_view'
	},
	{
		Component: (
			<NumericMetric
				title={descriptions.find((item) => item.key === 'meta_ads_cost_per_page_engagement')?.title}
				keyName='meta_ads_cost_per_page_engagement'
				data={metaAdsMetrics?.apiSeries?.graphsTotalMetrics?.costPerPageEngagementFormattedTotal}
				graphData={metaAdsMetrics?.apiSeries?.graphs?.costPerPageEngagementFormatted}
				source='metaAds'
				isOverview={true}
				dataType={descriptions.find((item) => item.key === 'meta_ads_cost_per_page_engagement')?.data_type}
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'meta_ads_cost_per_page_engagement')?.description}
				isFullValue={true}
			/>
		),
		key: 'meta_ads_cost_per_page_engagement'
	},
	{
		Component: (
			<NumericMetric
				title={descriptions.find((item) => item.key === 'meta_ads_cost_per_inline_post_engagement')?.title}
				keyName='meta_ads_cost_per_inline_post_engagement'
				data={metaAdsMetrics?.apiSeries?.graphsTotalMetrics?.costPerInlinePostEngagementFormattedTotal}
				graphData={metaAdsMetrics?.apiSeries?.graphs?.costPerInlinePostEngagementFormatted}
				source='metaAds'
				isOverview={true}
				dataType={descriptions.find((item) => item.key === 'meta_ads_cost_per_inline_post_engagement')?.data_type}
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'meta_ads_cost_per_inline_post_engagement')?.description}
				isFullValue={true}
			/>
		),
		key: 'meta_ads_cost_per_inline_post_engagement'
	},
	{
		Component: (
			<NumericMetric
				title={descriptions.find((item) => item.key === 'meta_ads_cpc')?.title}
				keyName='meta_ads_cpc'
				data={metaAdsMetrics?.apiSeries?.graphsTotalMetrics?.cpcFormattedTotal}
				graphData={metaAdsMetrics?.apiSeries?.graphs?.cpcFormatted}
				source='metaAds'
				isOverview={true}
				dataType={descriptions.find((item) => item.key === 'meta_ads_cpc')?.data_type}
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'meta_ads_cpc')?.description}
				isFullValue={true}
			/>
		),
		key: 'meta_ads_cpc'
	},
	{
		Component: (
			<NumericMetric
				title={descriptions.find((item) => item.key === 'meta_ads_cpm')?.title}
				keyName='meta_ads_cpm'
				data={metaAdsMetrics?.apiSeries?.graphsTotalMetrics?.cpmFormattedTotal}
				graphData={metaAdsMetrics?.apiSeries?.graphs?.cpmFormatted}
				source='metaAds'
				isOverview={true}
				dataType={descriptions.find((item) => item.key === 'meta_ads_cpm')?.data_type}
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'meta_ads_cpm')?.description}
				isFullValue={true}
			/>
		),
		key: 'meta_ads_cpm'
	},
	{
		Component: (
			<NumericMetric
				title={descriptions.find((item) => item.key === 'meta_ads_ctr')?.title}
				keyName='meta_ads_ctr'
				data={metaAdsMetrics?.apiSeries?.graphsTotalMetrics?.ctrFormattedTotal}
				graphData={metaAdsMetrics?.apiSeries?.graphs?.ctrFormatted}
				source='metaAds'
				isOverview={true}
				dataType={descriptions.find((item) => item.key === 'meta_ads_ctr')?.data_type}
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'meta_ads_ctr')?.description}
				isFullValue={true}
			/>
		),
		key: 'meta_ads_ctr'
	},
	{
		Component: (
			<TopCommentsDashboardShareable
				title={descriptions.find((item) => item.key === 'meta_ads_top_posts')?.title}
				cards={cards}
				source='metaAds'
				editMode={editMode}
				keyName={'meta_ads_top_posts'}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'meta_ads_top_posts')?.description}
			/>
		),
		key: 'meta_ads_top_posts'
	},
	{
		Component: (
			<PostsFeedDashboardShareable
				title={descriptions.find((item) => item.key === 'meta_ads_posts_feed')?.title}
				keyName='meta_ads_posts_feed'
				cards={cards}
				source='metaAds'
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'meta_ads_posts_feed')?.description}
			/>
		),
		key: 'meta_ads_posts_feed'
	}
]

const DEFAULT_LAYOUTS_META_ADS_ACCOUNT_MANAGER = {
	lg: [
		{ i: 'meta_ads_top_posts', x: 0, y: 0, w: 6, h: 15, minH: 15, maxH: 17, minW: 6, maxW: 12 },
		{ i: 'meta_ads_impressions_timeline', x: 6, y: 0, w: 6, h: 12, minH: 12, maxH: 17, minW: 6, maxW: 12 },
		{ i: 'meta_ads_impressions', x: 6, y: 12, w: 6, h: 7, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'meta_ads_clicks', x: 0, y: 15, w: 6, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'meta_ads_cost_per_post_engagement', x: 0, y: 19, w: 4, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'meta_ads_inline_post_engagement', x: 8, y: 19, w: 4, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'meta_ads_inline_link_clicks', x: 4, y: 19, w: 4, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'meta_ads_spend', x: 8, y: 23, w: 4, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'meta_ads_post_engagement', x: 0, y: 23, w: 4, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'meta_ads_page_engagement', x: 4, y: 23, w: 4, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'meta_ads_ctr', x: 8, y: 27, w: 4, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'meta_ads_cpc', x: 0, y: 27, w: 4, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'meta_ads_post_reactions', x: 4, y: 27, w: 4, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'meta_ads_cpm', x: 0, y: 31, w: 4, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'meta_ads_cost_per_page_engagement', x: 8, y: 31, w: 4, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'meta_ads_social_spend', x: 4, y: 31, w: 4, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'meta_ads_video_views', x: 4, y: 8, w: 4, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'meta_ads_purchase_roas', x: 4, y: 8, w: 4, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'meta_ads_video_p25_watched_views', x: 8, y: 8, w: 4, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'meta_ads_video_p100_watched_actions', x: 0, y: 12, w: 4, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'meta_ads_reach', x: 4, y: 16, w: 6, h: 12, minH: 12, maxH: 17, minW: 6, maxW: 12 },
		{ i: 'meta_ads_inline_link_click_ctr', x: 8, y: 16, w: 6, h: 12, minH: 12, maxH: 17, minW: 6, maxW: 12 },
		{ i: 'meta_ads_cost_per_unique_click', x: 0, y: 20, w: 6, h: 12, minH: 12, maxH: 17, minW: 6, maxW: 12 },
		{ i: 'meta_ads_cpp', x: 4, y: 20, w: 6, h: 12, minH: 12, maxH: 17, minW: 6, maxW: 12 },
		{ i: 'meta_ads_frequency', x: 8, y: 20, w: 6, h: 12, minH: 12, maxH: 17, minW: 6, maxW: 12 },
		{ i: 'meta_ads_conversions', x: 0, y: 24, w: 6, h: 12, minH: 12, maxH: 17, minW: 6, maxW: 12 },
		{ i: 'meta_ads_cost_per_15_sec_video_view', x: 4, y: 24, w: 6, h: 12, minH: 12, maxH: 17, minW: 6, maxW: 12 },
		{ i: 'meta_ads_cost_per_video_view', x: 0, y: 28, w: 4, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'meta_ads_cost_per_inline_post_engagement', x: 8, y: 28, w: 4, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'meta_ads_posts_feed', x: 0, y: 32, w: 6, h: 15, minH: 15, maxH: 17, minW: 6, maxW: 12 }
	],
	md: [
		{ i: 'meta_ads_top_posts', x: 0, y: 0, w: 6, h: 15, minH: 15, maxH: 17, minW: 6, maxW: 12 },
		{ i: 'meta_ads_impressions_timeline', x: 6, y: 0, w: 6, h: 12, minH: 12, maxH: 17, minW: 6, maxW: 12 },
		{ i: 'meta_ads_impressions', x: 6, y: 12, w: 6, h: 7, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'meta_ads_clicks', x: 0, y: 15, w: 6, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'meta_ads_cost_per_post_engagement', x: 0, y: 19, w: 4, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'meta_ads_inline_post_engagement', x: 8, y: 19, w: 4, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'meta_ads_inline_link_clicks', x: 4, y: 19, w: 4, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'meta_ads_spend', x: 8, y: 23, w: 4, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'meta_ads_post_engagement', x: 0, y: 23, w: 4, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'meta_ads_page_engagement', x: 4, y: 23, w: 4, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'meta_ads_ctr', x: 8, y: 27, w: 4, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'meta_ads_cpc', x: 0, y: 27, w: 4, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'meta_ads_post_reactions', x: 4, y: 27, w: 4, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'meta_ads_cpm', x: 0, y: 31, w: 4, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'meta_ads_cost_per_page_engagement', x: 8, y: 31, w: 4, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'meta_ads_social_spend', x: 4, y: 31, w: 4, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'meta_ads_video_views', x: 4, y: 8, w: 4, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'meta_ads_purchase_roas', x: 4, y: 8, w: 4, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'meta_ads_video_p25_watched_views', x: 8, y: 8, w: 4, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'meta_ads_video_p100_watched_actions', x: 0, y: 12, w: 4, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'meta_ads_reach', x: 4, y: 16, w: 6, h: 12, minH: 12, maxH: 17, minW: 6, maxW: 12 },
		{ i: 'meta_ads_inline_link_click_ctr', x: 8, y: 16, w: 6, h: 12, minH: 12, maxH: 17, minW: 6, maxW: 12 },
		{ i: 'meta_ads_cost_per_unique_click', x: 0, y: 20, w: 6, h: 12, minH: 12, maxH: 17, minW: 6, maxW: 12 },
		{ i: 'meta_ads_cpp', x: 4, y: 20, w: 6, h: 12, minH: 12, maxH: 17, minW: 6, maxW: 12 },
		{ i: 'meta_ads_frequency', x: 8, y: 20, w: 6, h: 12, minH: 12, maxH: 17, minW: 6, maxW: 12 },
		{ i: 'meta_ads_conversions', x: 0, y: 24, w: 6, h: 12, minH: 12, maxH: 17, minW: 6, maxW: 12 },
		{ i: 'meta_ads_cost_per_15_sec_video_view', x: 4, y: 24, w: 6, h: 12, minH: 12, maxH: 17, minW: 6, maxW: 12 },
		{ i: 'meta_ads_cost_per_video_view', x: 0, y: 28, w: 4, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'meta_ads_cost_per_inline_post_engagement', x: 8, y: 28, w: 4, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'meta_ads_posts_feed', x: 0, y: 32, w: 6, h: 15, minH: 15, maxH: 17, minW: 6, maxW: 12 }
	],
	sm: [
		{ i: 'meta_ads_top_posts', x: 0, y: 0, w: 12, h: 15, minH: 15, maxH: 17, minW: 6, maxW: 12 },
		{ i: 'meta_ads_impressions_timeline', x: 0, y: 0, w: 12, h: 12, minH: 12, maxH: 17, minW: 6, maxW: 12 },
		{ i: 'meta_ads_impressions', x: 0, y: 12, w: 12, h: 7, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'meta_ads_clicks', x: 0, y: 15, w: 12, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'meta_ads_cost_per_post_engagement', x: 0, y: 19, w: 12, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'meta_ads_inline_post_engagement', x: 0, y: 19, w: 12, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'meta_ads_inline_link_clicks', x: 0, y: 19, w: 12, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'meta_ads_spend', x: 0, y: 23, w: 12, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'meta_ads_post_engagement', x: 0, y: 23, w: 12, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'meta_ads_page_engagement', x: 0, y: 23, w: 12, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'meta_ads_ctr', x: 0, y: 27, w: 12, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'meta_ads_cpc', x: 0, y: 27, w: 12, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'meta_ads_post_reactions', x: 0, y: 27, w: 12, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'meta_ads_cpm', x: 0, y: 31, w: 12, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'meta_ads_cost_per_page_engagement', x: 0, y: 31, w: 12, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'meta_ads_social_spend', x: 0, y: 31, w: 12, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'meta_ads_video_views', x: 0, y: 8, w: 12, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'meta_ads_purchase_roas', x: 0, y: 8, w: 12, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'meta_ads_video_p25_watched_views', x: 0, y: 8, w: 12, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'meta_ads_video_p100_watched_actions', x: 0, y: 12, w: 12, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'meta_ads_reach', x: 0, y: 16, w: 12, h: 12, minH: 12, maxH: 17, minW: 6, maxW: 12 },
		{ i: 'meta_ads_inline_link_click_ctr', x: 0, y: 16, w: 12, h: 12, minH: 12, maxH: 17, minW: 6, maxW: 12 },
		{ i: 'meta_ads_cost_per_unique_click', x: 0, y: 20, w: 12, h: 12, minH: 12, maxH: 17, minW: 6, maxW: 12 },
		{ i: 'meta_ads_cpp', x: 0, y: 20, w: 12, h: 12, minH: 12, maxH: 17, minW: 6, maxW: 12 },
		{ i: 'meta_ads_frequency', x: 0, y: 20, w: 12, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'meta_ads_conversions', x: 0, y: 24, w: 12, h: 12, minH: 12, maxH: 17, minW: 6, maxW: 12 },
		{ i: 'meta_ads_cost_per_15_sec_video_view', x: 0, y: 24, w: 12, h: 12, minH: 12, maxH: 17, minW: 6, maxW: 12 },
		{ i: 'meta_ads_cost_per_video_view', x: 0, y: 28, w: 12, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'meta_ads_cost_per_inline_post_engagement', x: 8, y: 28, w: 12, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'meta_ads_posts_feed', x: 0, y: 32, w: 12, h: 15, minH: 15, maxH: 17, minW: 6, maxW: 12 }
	]
}

const DEFAULT_WIDGETS_META_ADS_ACCOUNT_MANAGER = [
	'meta_ads_top_posts',
	'meta_ads_impressions_timeline',
	'meta_ads_impressions',
	'meta_ads_clicks',
	'meta_ads_cost_per_post_engagement',
	'meta_ads_inline_post_engagement',
	'meta_ads_inline_link_clicks',
	'meta_ads_spend',
	'meta_ads_post_engagement',
	'meta_ads_page_engagement',
	'meta_ads_ctr',
	'meta_ads_cpc',
	'meta_ads_post_reactions',
	'meta_ads_cpm',
	'meta_ads_cost_per_page_engagement',
	'meta_ads_social_spend'
]
