import React, { useState, useEffect, useRef, Fragment } from 'react'
import { Chip, Grid, TextField, Autocomplete, Paper, styled, Collapse, Card, IconButton, Button, Tooltip } from '@mui/material'
import { TagIcon, ChevronUpIcon, EditIcon, DeleteBinIcon } from 'utils/SystemIcons'
import SearchBarGlobalFilters from 'components/GlobalFilters/components/SearchBarGlobalFilters/SearchBarGlobalFilters'
import autoAnimate from '@formkit/auto-animate'
import CardTagLabel from '../CardTagLabel/CardTagLabel'
import DialogConfirmation from 'components/DialogConfirmation/DialogConfirmation'

const isFirefox = typeof InstallTrigger !== 'undefined'

const blue = 'rgba(29, 138, 248, 100%)'
const white = '#ffffff'
const grey = 'rgba(184, 184, 184, 100%)'
const TAGS_REGEX = /[a-z0-9\b\0 _]/
const TAGS_REGEX_STRING = /^[a-z0-9\b\0 _]+$/

const CssTextField = styled(TextField)({
	'& .MuiInputBase-root': {
		color: blue,
		fontFamily: 'Poppins',
		'& input': {
			textAlign: 'left'
		}
	}
})

const CustomPaper = (props) => {
	return (
		<Paper
			{...props}
			sx={{
				minWidth: '15em',
				borderRadius: '0px 0px 25px 25px',
				backdropFilter: 'blur(10px)',
				backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
				border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
				boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
			}}
		/>
	)
}

const CardTag = ({ id, tagObject, onChangeName, onChangeQuery, onDelete, onChangeFieldErrors }) => {
	const [tag, setTag] = useState(tagObject.tag_key || '')
	const [tagName, setTagName] = useState(tagObject.tag_key || '')
	const [isMinimized, setIsMinimized] = useState(tagObject.tag_key !== '')
	const [queryText, setQueryText] = useState(tagObject.query || '')
	const [error, setError] = useState(false)
	const [errorText, setErrorText] = useState('')
	const [confirmDelete, setConfirmDelete] = useState(false)

	const autocompleteRef = useRef()

	const parent = useRef(null)

	useEffect(() => {
		parent.current && autoAnimate(parent.current)
	}, [parent])

	const handleChangeName = (value) => {
		setTag(value)
	}

	const handleInputChange = (value, id) => {
		setTagName(value)
		setError(!validateTag(value))
		onChangeFieldErrors({ tags: { error: !validateTag(value) } })
		onChangeName(value, id)
	}

	const handleClickDelete = (id) => {
		onDelete(id)
	}

	const toggleDelete = () => {
		setConfirmDelete(!confirmDelete)
	}

	const onChangeQueryObject = (newQueryObject, newQueryText) => {
		setQueryText(newQueryText)
		onChangeQuery(newQueryObject, newQueryText, id)
	}

	const validateTag = (tag) => {
		if (tag === '') {
			setErrorText('A tag não pode ser vazia')
			return false
		}
		if (tag.trim().length === 0) {
			setErrorText('A tag não pode ser composta apenas de espaços')
			return false
		}
		if (tag[0] === '_' || tag[tag.length - 1] === '_') {
			setErrorText('A tag não pode começar ou terminar com _')
			return false
		}
		if (tag.includes(' _') || tag.includes('_ ')) {
			setErrorText('A tag não pode conter espaços antes ou depois de _')
			return false
		}
		if (tag.includes('__')) {
			setErrorText('A tag não pode conter mais que um _ seguido')
			return false
		}
		const splitTag = tag.split('_')
		if (splitTag.length > 2) {
			setErrorText('A tag não pode conter mais que um _')
			return false
		}
		if (tag[0] === ' ' || tag[tag.length - 1] === ' ') {
			setErrorText('A tag não pode começar ou terminar com espaço vazio')
			return false
		}

		if (tag[0] === ' ' || tag[tag.length - 1] === ' ') {
			setErrorText('A tag não pode começar ou terminar com espaço vazio')
			return false
		}

		if (tag !== tag.toLowerCase()) {
			setErrorText('Tags não podem conter letra maiúscula')
			return false
		}

		if (!TAGS_REGEX_STRING.test(tag)) {
			setErrorText('A tag não pode conter caracteres especiais')
			return false
		}

		setErrorText('')
		return true
	}

	const label = (
		<div style={{ display: 'flex', marginTop: '-6px' }}>
			<TagIcon size={17} style={{ position: 'relative', transform: 'translate(0px, 7px)', color: '#3d8cf8' }} />
			<div style={{ marginLeft: '5px', marginTop: '3px', fontSize: '15px' }}>
				Nome da tag escreva ( _ ) para adicionar sub-tags exemplo: tag_subtag
			</div>
		</div>
	)

	return (
		<Card
			elevation={0}
			style={{
				borderRadius: '25px',
				marginTop: '1em',
				backgroundColor: 'rgba(255,255,255,0.6)',
				border: '1px solid rgba(29, 138, 248, 0.2)'
			}}
		>
			<Grid container style={{ padding: '1em' }}>
				<Grid item xs={12} spacing={1} container alignItems={'center'}>
					<Grid item xs={6} style={{ whiteSpace: 'nowrap', textAlign: 'left', display: 'flex', alignItems: 'center' }}>
						<div style={{ marginRight: '0.5em' }}>Taguear como:</div>
						{tagName !== '' && (
							<Chip
								style={{
									padding: '0.1em',
									color: white,
									backgroundColor: blue,
									borderColor: blue
								}}
								variant='outlined'
								label={<CardTagLabel tag={tagName} />}
							/>
						)}
					</Grid>
					<Grid item xs={6} container justifyContent={'flex-end'}>
						<Tooltip title={'Editar regra'} placement='top' enterDelay={200} enterNextDelay={200}>
							<IconButton size='medium' onClick={() => setIsMinimized(!isMinimized)}>
								<EditIcon size={20} color={isMinimized ? blue : grey} />
							</IconButton>
						</Tooltip>
						<Tooltip title={'Deletar regra'} placement='top' enterDelay={200} enterNextDelay={200}>
							<IconButton size='medium' onClick={() => setConfirmDelete(!confirmDelete)}>
								<DeleteBinIcon size={20} color={'#ff2b6b'} />
							</IconButton>
						</Tooltip>
					</Grid>
					{queryText !== '' && (
						<Grid item xs={12} style={{ whiteSpace: 'nowrap', textAlign: 'left', display: 'flex' }}>
							<div style={{ marginRight: '0.5em' }}>caso os posts tenham:</div>
							<Tooltip title={queryText} placement='bottom-start' enterDelay={200} enterNextDelay={200}>
								<div
									style={{
										overflow: 'hidden',
										display: 'inlineBlock',
										textOverflow: 'ellipsis',
										whiteSpace: 'nowrap',
										width: '90vw',
										color: blue
									}}
								>
									{queryText}
								</div>
							</Tooltip>
						</Grid>
					)}
				</Grid>
			</Grid>
			<Collapse in={!isMinimized}>
				<Grid container style={{ padding: '1em', marginTop: '1em' }}>
					<Grid item xs={12}>
						<Autocomplete
							id={`input-tag-${id}`}
							style={{
								width: '100%'
							}}
							PaperComponent={CustomPaper}
							freeSolo
							noOptionsText='Não encontrado'
							autoComplete
							autoHighlight
							value={tag}
							onInputChange={(_, value) => handleInputChange(value, id)}
							onChange={(_, value) => handleChangeName(value, id)}
							// options={Array.from(new Set(tagsObject?.value.map((v) => v.tag))).filter((it) => it !== '' && it.length > 3)}
							options={[]}
							getOptionLabel={(option) => option}
							renderOption={(props, option) => {
								return (
									<Fragment key={option}>
										{option.tag !== '' && (
											<li {...props} style={{ fontFamily: 'Poppins' }}>
												{option}
											</li>
										)}
									</Fragment>
								)
							}}
							renderInput={(params) => (
								<div style={{ position: 'relative' }}>
									<CssTextField
										error={error}
										onKeyDown={(event) => {
											if (!TAGS_REGEX.test(event.key)) {
												event.preventDefault()
											}
										}}
										variant='standard'
										fullWidth
										{...params}
										label={label}
										inputRef={autocompleteRef}
										helperText={errorText}
									/>
								</div>
							)}
						/>
					</Grid>
					<Grid item xs={12} style={{ marginTop: '1em' }}>
						{tagName !== '' && (
							<SearchBarGlobalFilters
								contentTextSearch={queryText || ''}
								title='Regra de tagueamento'
								isTaging={true}
								tag={tagName}
								externalQueryText={queryText || ''}
								jsonQuery={tagObject.jsonLogicFormat || null}
								onChangeQueryObject={onChangeQueryObject}
								onChangeFieldErrors={onChangeFieldErrors}
							/>
						)}
					</Grid>
				</Grid>
			</Collapse>
			{!isMinimized && (
				<Grid item xs={12}>
					<Button fullWidth onClick={() => setIsMinimized(true)}>
						<ChevronUpIcon size={20} color={blue} />
					</Button>
				</Grid>
			)}
			{confirmDelete && (
				<DialogConfirmation
					open={confirmDelete}
					handleClose={toggleDelete}
					handleConfirm={() => {
						toggleDelete()
						handleClickDelete(id)
					}}
					color={'#ff2b6b'}
					title={`Deseja realmente deletar ${tagName !== '' ? `a regra ${tagName}` : 'essa regra'}?`}
					buttonText={'DELETAR'}
					icon={<DeleteBinIcon className='fix-icon-margin-bottom-negative' size={25} color='#ff2b6b' />}
				/>
			)}
		</Card>
	)
}

export default CardTag
