import React, { useState, useEffect, memo } from 'react'
import Header from './components/Header/HeaderReport'
import OccurrencesContent from './modules/OccurencesContent/OccurrencesContent'
import ReportContent from './modules/ReportContent/ReportContent'
import { CircularProgress, Button } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useQuery } from 'hooks/ReactRouterDomHooks'
import api from 'service/service'

import './Report.css'

const Report = () => {
	//Relatório não possui source específica, apenas quando as ocorrencias sao exportadas
	const urlQuery = useQuery()
	const { type, cards_ids, from_date, to_date, from_hour, to_hour } = useParams()
	const cardsIdsObject = cards_ids?.split(',')?.map((id) => ({ id }))
	const pageSize = urlQuery.get('pageSize')
	const orderDate = urlQuery.get('orderDate')
	const orderRelevance = urlQuery.get('orderRelevance')
	const isExportOccurrences = urlQuery.get('isExportOccurrences')
	const exportOccurrencesSource = urlQuery.get('exportOccurrencesSource')
	const viewType = urlQuery.get('viewType')
	const graphInterval = urlQuery.get('graphInterval')
	const sentimentsTwitterStandard = urlQuery.get('sentimentsTwitterStandard') === 'true'
	const sentimentsTwitterBalance = urlQuery.get('sentimentsTwitterBalance') === 'true'
	const sentimentsFacebookStandard = urlQuery.get('sentimentsFacebookStandard') === 'true'
	const sentimentsFacebookBalance = urlQuery.get('sentimentsFacebookBalance') === 'true'
	const sentimentsInstagramStandard = urlQuery.get('sentimentsInstagramStandard') === 'true'
	const sentimentsInstagramBalance = urlQuery.get('sentimentsInstagramBalance') === 'true'
	const sentimentsTiktokStandard = urlQuery.get('sentimentsTiktokStandard') === 'true'
	const sentimentsTiktokBalance = urlQuery.get('sentimentsTiktokBalance') === 'true'
	const sentimentsYoutubeStandard = urlQuery.get('sentimentsYoutubeStandard') === 'true'
	const sentimentsYoutubeBalance = urlQuery.get('sentimentsYoutubeBalance') === 'true'
	const timelineTwitterEngagement = urlQuery.get('timelineTwitterEngagement') === 'true'
	const timelineFacebookEngagement = urlQuery.get('timelineFacebookEngagement') === 'true'
	const timelineInstagramEngagement = urlQuery.get('timelineInstagramEngagement') === 'true'
	const timelineTiktokEngagement = urlQuery.get('timelineTiktokEngagement') === 'true'
	const timelineYoutubeEngagement = urlQuery.get('timelineYoutubeEngagement') === 'true'
	const timelineNewsEngagement = urlQuery.get('timelineNewsEngagement') === 'true'
	const networkNewsAccounts = urlQuery.get('networkNewsAccounts') === 'true'
	const networkFacebookAccounts = urlQuery.get('networkFacebookAccounts') === 'true'
	const networkFacebookSentiments = urlQuery.get('networkFacebookSentiments') === 'true'
	const networkInstagramAccounts = urlQuery.get('networkInstagramAccounts') === 'true'
	const networkInstagramSentiments = urlQuery.get('networkInstagramSentiments') === 'true'
	const networkTiktokAccounts = urlQuery.get('networkTiktokAccounts') === 'true'
	const networkTiktokSentiments = urlQuery.get('networkTiktokSentiments') === 'true'
	const networkYoutubeChannels = urlQuery.get('networkYoutubeChannels') === 'true'
	const networkYoutubeSentiments = urlQuery.get('networkYoutubeSentiments') === 'true'
	const networkTwitterAccounts = urlQuery.get('networkTwitterAccounts') === 'true'
	const networkTwitterSentiments = urlQuery.get('networkTwitterSentiments') === 'true'
	const networkTwitterHashtags = urlQuery.get('networkTwitterHashtags') === 'true'
	const summaryTwitter = urlQuery.get('summaryTwitter') === 'true'
	const summaryFacebook = urlQuery.get('summaryFacebook') === 'true'
	const summaryInstagram = urlQuery.get('summaryInstagram') === 'true'
	const summaryTiktok = urlQuery.get('summaryTiktok') === 'true'
	const summaryYoutube = urlQuery.get('summaryYoutube') === 'true'
	const summaryNews = urlQuery.get('summaryNews') === 'true'
	const timelineTwitterPosts = urlQuery.get('timelineTwitterPosts') === 'true'
	const timelineFacebookPosts = urlQuery.get('timelineFacebookPosts') === 'true'
	const timelineInstagramPosts = urlQuery.get('timelineInstagramPosts') === 'true'
	const timelineTiktokPosts = urlQuery.get('timelineTiktokPosts') === 'true'
	const timelineYoutubePosts = urlQuery.get('timelineYoutubePosts') === 'true'
	const timelineNewsPosts = urlQuery.get('timelineNewsPosts') === 'true'
	const locationTwitterVolume = urlQuery.get('locationTwitterVolume') === 'true'
	const locationTwitterSentiments = urlQuery.get('locationTwitterSentiments') === 'true'
	const locationTwitterGender = urlQuery.get('locationTwitterGender') === 'true'
	const locationFacebookVolume = urlQuery.get('locationFacebookVolume') === 'true'
	const locationFacebookSentiments = urlQuery.get('locationFacebookSentiments') === 'true'
	const locationFacebookGender = urlQuery.get('locationFacebookGender') === 'true'
	const locationInstagramVolume = urlQuery.get('locationInstagramVolume') === 'true'
	const locationInstagramSentiments = urlQuery.get('locationInstagramSentiments') === 'true'
	const locationInstagramGender = urlQuery.get('locationInstagramGender') === 'true'
	const locationTiktokVolume = urlQuery.get('locationTiktokVolume') === 'true'
	const locationTiktokSentiments = urlQuery.get('locationTiktokSentiments') === 'true'
	const locationTiktokGender = urlQuery.get('locationTiktokGender') === 'true'
	const locationYoutubeVolume = urlQuery.get('locationYoutubeVolume') === 'true'
	const locationYoutubeSentiments = urlQuery.get('locationYoutubeSentiments') === 'true'
	const locationYoutubeGender = urlQuery.get('locationYoutubeGender') === 'true'
	const twitter = urlQuery.get('twitter') === 'true'
	const facebook = urlQuery.get('facebook') === 'true'
	const instagram = urlQuery.get('instagram') === 'true'
	const tiktok = urlQuery.get('tiktok') === 'true'
	const youtube = urlQuery.get('youtube') === 'true'
	const news = urlQuery.get('news') === 'true'
	const title = urlQuery.get('title')
	const subTitle = urlQuery.get('subTitle')
	const image = urlQuery.get('image')
	const wordCloudName = urlQuery.get('wordCloudName')
	const hasIgnoredWords = urlQuery.get('hasIgnoredWords') === 'true'
	const tagsGlobalFilters = urlQuery.get('tagsFilter') === '' ? [] : urlQuery.get('tagsFilter')

	const filters = {
		fromDate: from_date,
		toDate: to_date,
		fromHour: from_hour,
		toHour: to_hour,
		contentTextSearch: urlQuery.get('contentTextSearch') === 'null' ? null : urlQuery.get('contentTextSearch'),
		source: exportOccurrencesSource,
		tagsGlobalFilters,
		isStopWordsActive: hasIgnoredWords,
		classification: {
			hasAutomaticClassification: urlQuery.get('hasAutomaticClassification'),
			hasManualClassification: urlQuery.get('hasManualClassification'),
			positive: urlQuery.get('hasPositiveImpact'),
			negative: urlQuery.get('hasNegativeImpact'),
			neutral: urlQuery.get('hasNeutralImpact'),
			irrelevant: urlQuery.get('hasIrrelevantImpact')
		},
		manifestations: {
			higher: urlQuery.get('manifestationsHigher') === 'null' ? null : urlQuery.get('manifestationsLower'),
			lower: urlQuery.get('manifestationsLower') === 'null' ? null : urlQuery.get('manifestationsLower')
		},
		advancedFilters: {
			contentType: urlQuery.get('contentType'),
			authorIsVerified: urlQuery.get('authorIsVerified'),
			authorDescriptionSearch: urlQuery.get('authorDescriptionSearch') === 'null' ? null : urlQuery.get('authorDescriptionSearch'),
			authorGender: {
				male: !['false', 'null', '', 'undefined'].includes(urlQuery.get('genderMaleTwitter')),
				female: !['false', 'null', '', 'undefined'].includes(urlQuery.get('genderFemaleTwitter'))
			},
			authorNameSearch: urlQuery.get('authorNameSearch') === 'null' ? null : urlQuery.get('authorNameSearch'),
			authorFollowers: {
				higher: urlQuery.get('authorFollowersHigher'),
				lower: urlQuery.get('authorFollowersLower')
			},
			authorLikes: {
				higher: urlQuery.get('authorLikesHigher'),
				lower: urlQuery.get('authorLikesLower')
			},
			authorLocation: {
				country: ['null', 'undefined', ''].includes(urlQuery.get('countryCodeFacebook')) ? null : urlQuery.get('countryCodeFacebook'),
				region: ['null', 'undefined', ''].includes(urlQuery.get('regionCodeFacebook')) ? null : urlQuery.get('regionCodeFacebook'),
				state: ['null', 'undefined', ''].includes(urlQuery.get('stateCodeFacebook')) ? null : urlQuery.get('stateCodeFacebook'),
				city: ['null', 'undefined', ''].includes(urlQuery.get('cityCodeFacebook')) ? null : urlQuery.get('cityCodeFacebook')
			},
			authorDomainSearch: urlQuery.get('authorDomainSearch') === 'null' ? null : urlQuery.get('authorDomainSearch'),
			contentUrlSearch: urlQuery.get('contentUrlSearch') === 'null' ? null : urlQuery.get('contentUrlSearch')
		},
		group: {
			tags: ['null', 'undefined'].includes(urlQuery.get('tags')) ? [] : urlQuery.get('tags')
		}
	}
	const [enableDownloadPdf, setEnableDownloadPdf] = useState(false)
	const [verifiedDatasourcesSummary, setVerifiedDatasourcesSummary] = useState({
		facebook: false,
		twitter: false,
		trends: false,
		instagram: false,
		news: false
	})

	const loadDatasources = async () => {
		const datasourcesResponse = await api.get(`/social-source/get-active-sources-info/${type}/${cardsIdsObject?.map((card) => card.id)}`)
		setVerifiedDatasourcesSummary(datasourcesResponse.data)
	}

	useEffect(() => {
		loadDatasources()
		enableDownloadPdfButton()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const enableDownloadPdfButton = async () => {
		setTimeout(() => {
			setEnableDownloadPdf(true)
		}, 10000)
	}

	return (
		<>
			{cardsIdsObject?.length > 0 && cardsIdsObject.length < 6 && (
				<div className='report report-print'>
					<div style={{ textAlign: 'right', marginRight: '3em' }}>
						<div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', marginTop: '1em' }}>
							{!enableDownloadPdf && <CircularProgress size={24} style={{ color: '#1DA1F2', position: 'absolute' }} />}
							<Button
								className='hide-print'
								variant='contained'
								disabled={!enableDownloadPdf}
								onClick={() => window.print()}
								color='primary'
							>
								Gerar PDF
							</Button>
						</div>
					</div>
					<div className='break-print'>
						<Header viewType={viewType} cardType={type?.toUpperCase()} title={title} subTitle={subTitle} image={image} />
					</div>
					{!isExportOccurrences ? (
						<ReportContent
							type={type}
							verifiedDatasourcesSummary={verifiedDatasourcesSummary}
							cardsIdsObject={cardsIdsObject}
							filters={filters}
							viewType={viewType}
							graphInterval={graphInterval}
							sentimentsTwitterStandard={sentimentsTwitterStandard}
							sentimentsTwitterBalance={sentimentsTwitterBalance}
							sentimentsFacebookStandard={sentimentsFacebookStandard}
							sentimentsFacebookBalance={sentimentsFacebookBalance}
							sentimentsInstagramStandard={sentimentsInstagramStandard}
							sentimentsInstagramBalance={sentimentsInstagramBalance}
							sentimentsTiktokStandard={sentimentsTiktokStandard}
							sentimentsTiktokBalance={sentimentsTiktokBalance}
							sentimentsYoutubeStandard={sentimentsYoutubeStandard}
							sentimentsYoutubeBalance={sentimentsYoutubeBalance}
							timelineTwitterEngagement={timelineTwitterEngagement}
							timelineFacebookEngagement={timelineFacebookEngagement}
							timelineInstagramEngagement={timelineInstagramEngagement}
							timelineTiktokEngagement={timelineTiktokEngagement}
							timelineYoutubeEngagement={timelineYoutubeEngagement}
							timelineNewsEngagement={timelineNewsEngagement}
							networkNewsAccounts={networkNewsAccounts}
							networkFacebookAccounts={networkFacebookAccounts}
							networkFacebookSentiments={networkFacebookSentiments}
							networkTwitterAccounts={networkTwitterAccounts}
							networkTwitterSentiments={networkTwitterSentiments}
							networkTiktokAccounts={networkTiktokAccounts}
							networkTiktokSentiments={networkTiktokSentiments}
							networkYoutubeChannels={networkYoutubeChannels}
							networkYoutubeSentiments={networkYoutubeSentiments}
							networkTwitterHashtags={networkTwitterHashtags}
							networkInstagramAccounts={networkInstagramAccounts}
							networkInstagramSentiments={networkInstagramSentiments}
							summaryTwitter={summaryTwitter}
							summaryFacebook={summaryFacebook}
							summaryInstagram={summaryInstagram}
							summaryTiktok={summaryTiktok}
							summaryYoutube={summaryYoutube}
							summaryNews={summaryNews}
							timelineTwitterPosts={timelineTwitterPosts}
							timelineFacebookPosts={timelineFacebookPosts}
							timelineInstagramPosts={timelineInstagramPosts}
							timelineTiktokPosts={timelineTiktokPosts}
							timelineYoutubePosts={timelineYoutubePosts}
							timelineNewsPosts={timelineNewsPosts}
							locationTwitterVolume={locationTwitterVolume}
							locationTwitterSentiments={locationTwitterSentiments}
							locationTwitterGender={locationTwitterGender}
							locationFacebookVolume={locationFacebookVolume}
							locationFacebookSentiments={locationFacebookSentiments}
							locationFacebookGender={locationFacebookGender}
							locationInstagramVolume={locationInstagramVolume}
							locationInstagramSentiments={locationInstagramSentiments}
							locationInstagramGender={locationInstagramGender}
							locationTiktokVolume={locationTiktokVolume}
							locationTiktokSentiments={locationTiktokSentiments}
							locationTiktokGender={locationTiktokGender}
							locationYoutubeVolume={locationYoutubeVolume}
							locationYoutubeSentiments={locationYoutubeSentiments}
							locationYoutubeGender={locationYoutubeGender}
							twitter={twitter}
							facebook={facebook}
							instagram={instagram}
							tiktok={tiktok}
							youtube={youtube}
							news={news}
						/>
					) : (
						<OccurrencesContent
							filters={filters}
							cardsIdsObject={cardsIdsObject}
							pageSize={pageSize}
							orderDate={orderDate}
							orderRelevance={orderRelevance}
							type={type}
							wordCloudName={wordCloudName}
						/>
					)}
				</div>
			)}
		</>
	)
}

export default memo(Report)
