import axios from 'axios'
import api from 'service/service'

const uploadFile = async ({ file, onUploadProgress }) => {
	try {
		const fileInfo = { name: file.name, type: file.type }
		const presignedUrlData = await api.post('/occurrence/generate-presigned-url-s3', { fileInfo })
		const url = presignedUrlData.data.signedUrl
		const location = presignedUrlData.data.locationUrl
		await axios.put(url, file, {
			headers: { 'Content-Type': file.type },
			onUploadProgress: (progressEvent) => {
				const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
				onUploadProgress(percentCompleted)
			}
		})
		return location
	} catch (e) {
		console.error(e)
		return false
	}
}
export { uploadFile }
