import React, { useRef, useState } from 'react'
import { Dialog, DialogContent, IconButton } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { SplitIcon } from 'utils/SystemIcons'

const isFirefox = typeof InstallTrigger !== 'undefined'

const useStyles = makeStyles({
	videoBackDrop: {
		backdropFilter: 'blur(5px)',
		backgroundColor: 'transparent'
	}
})

const AurasightSyncVideos = ({ open, onClose, media, originalMedia }) => {
	const videoRef1 = useRef(null)
	const videoRef2 = useRef(null)
	const isSyncingRef = useRef(false)
	const [isOpenVideoAnnotations, setIsOpenVideoAnnotations] = useState(false)

	const classes = useStyles()

	const handlePlay = () => {
		if (videoRef1.current && videoRef2.current) {
			videoRef1.current.play()
			videoRef2.current.play()
		}
	}

	const handlePause = () => {
		if (videoRef1.current && videoRef2.current) {
			videoRef1.current.pause()
			videoRef2.current.pause()
		}
	}

	const handleTimeUpdate = (e) => {
		const sourceVideo = e.target
		const targetVideo = sourceVideo === videoRef1.current ? videoRef2.current : videoRef1.current

		if (targetVideo && !isSyncingRef.current) {
			const timeDifference = Math.abs(sourceVideo.currentTime - targetVideo.currentTime)

			if (timeDifference > 0.1) {
				isSyncingRef.current = true
				targetVideo.currentTime = sourceVideo.currentTime

				setTimeout(() => {
					isSyncingRef.current = false
				}, 10)
			}
		}
	}

	return (
		<Dialog
			open={open}
			onClose={() => {
				setIsOpenVideoAnnotations(false)
				onClose()
			}}
			BackdropProps={{ classes: { root: classes.videoBackDrop } }}
			maxWidth='sm'
			fullWidth
			PaperProps={{
				style: {
					borderRadius: '20px',
					backdropFilter: 'blur(10px)',
					backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
					border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.7)'}`,
					boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
				}
			}}
			unmountOnExit
		>
			{isOpenVideoAnnotations && (
				<DialogContent>
					<video
						ref={videoRef1}
						src={media}
						autoPlay
						onPlay={handlePlay}
						onPause={handlePause}
						onTimeUpdate={handleTimeUpdate}
						controls
						style={{
							width: '100%',
							height: 'auto',
							borderRadius: '10px',
							objectFit: 'cover'
						}}
					/>
				</DialogContent>
			)}
			<IconButton
				style={{ display: 'flex', justifyContent: 'end', marginRight: '10px' }}
				disableRipple
				sx={{
					'&:hover': {
						backgroundColor: 'transparent'
					}
				}}
				onClick={() => setIsOpenVideoAnnotations(!isOpenVideoAnnotations)}
			>
				<SplitIcon size={25} color={'#1d8cf8'} />
			</IconButton>
			<DialogContent>
				<video
					ref={videoRef2}
					src={originalMedia}
					autoPlay
					onPlay={handlePlay}
					onPause={handlePause}
					onTimeUpdate={handleTimeUpdate}
					controls
					style={{
						width: '100%',
						height: 'auto',
						borderRadius: '10px',
						objectFit: 'cover'
					}}
				/>
			</DialogContent>
		</Dialog>
	)
}

export default AurasightSyncVideos
