import React, { memo } from 'react'
import { connect } from 'react-redux'
import DashboardShareable from 'components/DashboardShareable/DashboardShareable'
import GlobalFilterBar from 'components/GlobalFilters/components/GlobalFilterBar/GlobalFilterBar'

const DashboardContent = ({ globalFiltersRedux, cardComparisonRedux, cardType, sharingDashboard, isSharing }) => {
	return (
		<>
			{isSharing && <GlobalFilterBar isOverview={false} disableSearch={false} />}
			<DashboardShareable
				cardList={cardComparisonRedux}
				globalFilters={globalFiltersRedux}
				isSharing={isSharing}
				cardType={cardType}
				sharingDashboard={sharingDashboard}
			/>
		</>
	)
}

const mapStateToProps = ({ store }) => ({
	cardType: store?.card?.cardType?.toUpperCase(),
	cardComparisonRedux: store.card.comparison.cards,
	globalFiltersRedux: store.global.filters
})

export default connect(mapStateToProps)(memo(DashboardContent))
