import React from 'react'
import { Grid, TextField, Card } from '@mui/material'
import { AnexIcon } from 'utils/SystemIcons'
import DateInput from 'components/DateInput/DateInput'
import HourInput from 'components/GlobalFilters/components/DateIntervalSelectorGlobal/components/HourInput/HourInput'
import FileInput from 'components/FileInput/FileInput'
import EmptyImageFeed from 'assets/img/twitterEmpty.jpg'
import TwitterIconImage from 'assets/img/twitter-icon.png'
import BlueskyIconImage from 'assets/img/bluesky-icon.png'
import FacebookIconImage from 'assets/img/facebook-icon.png'
import InstagramIconImage from 'assets/img/instagram-icon.png'
import TiktokIconImage from 'assets/img/tiktok-icon.png'
import YoutubeIconImage from 'assets/img/youtube-icon.png'
import NewsIconImage from 'assets/img/news-icon.png'
import TwitterSpecificForm from '../TwitterSpecificForm/TwitterSpecificForm'
import FacebookSpecificForm from '../FacebookSpecificForm/FacebookSpecificForm'
import InstagramSpecificForm from '../InstagramSpecificForm/InstagramSpecificForm'
import TiktokSpecificForm from '../TiktokSpecificForm/TiktokSpecificForm'
import YoutubeSpecificForm from '../YoutubeSpecificForm/YoutubeSpecificForm'
import BlueskySpecificForm from '../BlueskySpecificForm/BlueskySpecificForm'

const getImgSrc = (source) => {
	switch (source) {
		case 'twitter':
			return TwitterIconImage
		case 'bluesky':
			return BlueskyIconImage
		case 'instagram':
			return InstagramIconImage
		case 'facebook':
			return FacebookIconImage
		case 'tiktok':
			return TiktokIconImage
		case 'youtube':
			return YoutubeIconImage
		case 'news':
			return NewsIconImage
		default:
			return EmptyImageFeed
	}
}

const AddOccurrenceInputCard = ({ newOccurrenceForm, onChangeField, fieldErrors, source }) => {
	return (
		<Card elevation={0} style={{ borderRadius: '20px', margin: '25px', marginBottom: '0px', width: '100%', maxWidth: '800px', padding: '30px' }}>
			<Grid container spacing={1}>
				<Grid
					item
					xs={6}
					style={{
						color: '#1d8cf8',
						display: 'flex',
						justifyContent: 'flex-start'
					}}
				>
					<img
						style={{ width: '50px', height: '50px', borderRadius: '50px', border: 'solid 2px #1d8cf8' }}
						src={getImgSrc(source)}
						alt='...'
					/>
					<TextField
						type={'text'}
						size='small'
						className='d-flex'
						style={{ color: '#1d8cf8', marginLeft: '10px', marginTop: '8px', minWidth: '200px' }}
						sx={{
							'& .MuiOutlinedInput-root': {
								fontFamily: 'Poppins',
								'& fieldset': {
									borderRadius: '15px'
								}
							}
						}}
						value={newOccurrenceForm['author_username']}
						onChange={(e) => onChangeField(e.target.value, 'author_username', 'text')}
						variant='outlined'
						label={source === 'news' ? 'Domínio da notícia*' : 'Nome da conta*'}
						error={fieldErrors.find((fe) => fe.field === 'author_username')?.message}
						helperText={fieldErrors.find((fe) => fe.field === 'author_username')?.message}
					/>
				</Grid>
				<Grid item xs={6} style={{ color: '#1d8cf8', display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start' }}>
					<DateInput
						value={newOccurrenceForm['content_posted_at']}
						onChange={(e) => onChangeField(e, 'content_posted_at', 'date')}
						label={'Data da publicação*'}
						style={{ marginLeft: '8px', marginRight: '8px' }}
						error={fieldErrors.find((fe) => fe.field === 'content_posted_at')?.message}
						helperText={fieldErrors.find((fe) => fe.field === 'content_posted_at')?.message}
					/>
					<div style={{ marginTop: '0.4em' }}>
						<HourInput
							value={newOccurrenceForm['content_posted_at_hour']}
							inputLabel={'Hora da publicação'}
							onChange={(e) => onChangeField(e, 'content_posted_at_hour', 'dateHour')}
							style={{ marginTop: '0.4em', marginLeft: '8px', maxWidth: '10px' }}
						/>
					</div>
				</Grid>
				<Grid item xs={6} style={{ color: '#1d8cf8' }}>
					<TextField
						type={'text'}
						size='small'
						className='d-flex'
						style={{ color: '#1d8cf8', marginTop: '8px', minWidth: '200px' }}
						sx={{
							'& .MuiOutlinedInput-root': {
								fontFamily: 'Poppins',
								'& fieldset': {
									borderRadius: '15px'
								}
							}
						}}
						value={newOccurrenceForm['author_name']}
						onChange={(e) => onChangeField(e.target.value, 'author_name', 'text')}
						variant='outlined'
						label={'Nome do usuário'}
						error={fieldErrors.find((fe) => fe.field === 'author_name')?.message}
						helperText={fieldErrors.find((fe) => fe.field === 'author_name')?.message}
					/>
				</Grid>
				<Grid item xs={6} style={{ color: '#1d8cf8' }}>
					<TextField
						type={'text'}
						size='small'
						className='d-flex'
						style={{ color: '#1d8cf8', marginTop: '8px', minWidth: '200px' }}
						sx={{
							'& .MuiOutlinedInput-root': {
								fontFamily: 'Poppins',
								'& fieldset': {
									borderRadius: '15px'
								}
							}
						}}
						value={newOccurrenceForm['content_url']}
						onChange={(e) => onChangeField(e.target.value, 'content_url', 'text')}
						variant='outlined'
						label={'Url'}
						error={fieldErrors.find((fe) => fe.field === 'content_url')?.message}
						helperText={fieldErrors.find((fe) => fe.field === 'content_url')?.message}
					/>
				</Grid>
				<Grid item xs={12} style={{ color: '#1d8cf8' }}>
					<TextField
						type={'text'}
						size='small'
						className='d-flex'
						style={{ color: '#1d8cf8', marginTop: '8px', minWidth: '200px' }}
						sx={{
							'& .MuiOutlinedInput-root': {
								fontFamily: 'Poppins',
								'& fieldset': {
									borderRadius: '15px'
								}
							}
						}}
						value={newOccurrenceForm['content_title']}
						onChange={(e) => onChangeField(e.target.value, 'content_title', 'text')}
						variant='outlined'
						label={source === 'news' ? 'Título da Notícia' : 'Título da Postagem'}
						error={fieldErrors.find((fe) => fe.field === 'content_title')?.message}
						helperText={fieldErrors.find((fe) => fe.field === 'content_title')?.message}
					/>
				</Grid>
				<Grid item xs={12} style={{ marginTop: '1em' }}>
					<TextField
						type={'text'}
						size='small'
						className='d-flex'
						multiline
						minRows={7}
						maxRows={7}
						style={{ color: '#1d8cf8' }}
						sx={{
							'& .MuiOutlinedInput-root': {
								fontFamily: 'Poppins',
								'& fieldset': {
									borderRadius: '15px'
								}
							}
						}}
						value={newOccurrenceForm['content_text']}
						onChange={(e) => onChangeField(e.target.value, 'content_text', 'text')}
						variant='outlined'
						label={'Conteúdo em texto da postagem*'}
						error={fieldErrors.find((fe) => fe.field === 'content_text')?.message}
						helperText={fieldErrors.find((fe) => fe.field === 'content_text')?.message}
					/>
				</Grid>
				{source === 'twitter' && (
					<TwitterSpecificForm newOccurrenceForm={newOccurrenceForm} onChangeField={onChangeField} fieldErrors={fieldErrors} />
				)}
				{source === 'bluesky' && (
					<BlueskySpecificForm newOccurrenceForm={newOccurrenceForm} onChangeField={onChangeField} fieldErrors={fieldErrors} />
				)}
				{source === 'facebook' && (
					<FacebookSpecificForm newOccurrenceForm={newOccurrenceForm} onChangeField={onChangeField} fieldErrors={fieldErrors} />
				)}
				{source === 'instagram' && (
					<InstagramSpecificForm newOccurrenceForm={newOccurrenceForm} onChangeField={onChangeField} fieldErrors={fieldErrors} />
				)}
				{source === 'tiktok' && (
					<TiktokSpecificForm newOccurrenceForm={newOccurrenceForm} onChangeField={onChangeField} fieldErrors={fieldErrors} />
				)}
				{source === 'youtube' && (
					<YoutubeSpecificForm newOccurrenceForm={newOccurrenceForm} onChangeField={onChangeField} fieldErrors={fieldErrors} />
				)}
				<Grid item xs={12} style={{ marginTop: '1em' }}>
					<FileInput
						label={
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<AnexIcon size={20} color={'#FFF'} style={{ marginRight: '5px' }} />
								<div>Anexar um arquivo</div>
							</div>
						}
						value={newOccurrenceForm?.content_attachments}
						onChange={(e) => onChangeField(e, 'content_attachments', 'file')}
						error={fieldErrors.find((fe) => fe.field === 'content_attachments')?.message}
					/>
				</Grid>
			</Grid>
		</Card>
	)
}

export default AddOccurrenceInputCard
