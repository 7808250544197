import React, { useState, useEffect, useRef, memo } from 'react'
import { Grid, Card, IconButton } from '@mui/material'
import LoadingBar from 'components/LoadingBar/LoadingBar'
import GraphTitle from 'components/GraphTitle/GraphTitle'
import { CloseIcon, MoveResizeIcon } from 'utils/SystemIcons'
import TopicLineGraph from '../../../../../TopicAnalysis/components/TopicLineGraph/TopicLineGraph'

const emptyProp = {
	options: {
		chart: {
			type: 'area'
		}
	},
	series: [
		{
			name: '',
			data: [0]
		}
	]
}

const LineMetrics = ({ loading = false, title = '', graphData, style, description, editMode, addWidgetMode, removeWidget, keyName }) => {
	const [hardLoading, setHardLoading] = useState(true)
	const loadingBarRef = useRef()
	const isMounted = useRef(true)

	useEffect(() => {
		isMounted.current = true

		if (isMounted.current) {
			loadingBarRef?.current?.load(loading && !hardLoading)
		}

		if (!loading) {
			setHardLoading(false)
		}

		return () => {
			isMounted.current = false
		}
	}, [loading])

	return (
		<>
			{addWidgetMode && (
				<div style={{ position: 'absolute', right: '1px' }}>
					<IconButton onClick={() => removeWidget(keyName)}>
						<CloseIcon color={'#1d8cf8'} />
					</IconButton>
				</div>
			)}
			{editMode && (
				<div
					style={{
						borderRadius: '20px',
						position: 'absolute',
						top: 47,
						left: 3,
						right: 3,
						bottom: 8,
						backdropFilter: 'blur(5px)',
						backgroundColor: 'rgba(250, 250, 250, 0.5)',
						zIndex: 1000,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center'
					}}
				>
					<MoveResizeIcon size={60} style={{ marginBottom: '3em', color: '#1d8cf8' }} />
				</div>
			)}
			<Card style={{ borderRadius: '20px', height: '100%', ...style }} className={editMode ? 'editBox' : ''}>
				<Grid container spacing={3} style={{ background: '#fbfbfb', height: '70px', borderBottom: '1px solid #f5f5f5' }}>
					<Grid item xs={12}>
						<GraphTitle title={title || ''} description={description} />
					</Grid>
				</Grid>

				<div>
					<LoadingBar ref={loadingBarRef} />
				</div>

				<TopicLineGraph data={graphData || emptyProp} height={'400px'} />
			</Card>
		</>
	)
}

export default memo(LineMetrics)
