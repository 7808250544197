import { Avatar, Box, Button, List, ListItem, ListItemIcon, ListItemText, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import api from 'service/service'
import ImageAnnotationCreateLogo from './ImageAnnotationCreateLogo'
import DialogConfirmation from 'components/DialogConfirmation/DialogConfirmation'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import constantsVox from 'constants-vox'

const ImageAnnotationListLogos = () => {
	const [logos, setLogos] = useState([])
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)
	const [isCreatingLogo, setIsCreatingLogo] = useState(false)
	const [editingLogo, setEditingLogo] = useState(null)
	const [isOpenConfirmation, setIsOpenConfirmation] = useState(false)
	const [logoId, setLogoId] = useState()

	const fetchLogos = async () => {
		try {
			const response = await api.get('/image-annotation/logos')
			if (Array.isArray(response.data.data)) {
				setLogos(response.data.data)
			} else {
				throw new Error('Unexpected response format')
			}
		} catch (err) {
			console.error('Error fetching logos:', err)
			setError('Error fetching logos')
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		fetchLogos()
	}, [])

	const onCloseCreating = async () => {
		setIsCreatingLogo(false)
		await fetchLogos()
	}

	if (loading) {
		return <div>Loading...</div>
	}

	if (error) {
		return <div>{error}</div>
	}

	const handleDeleteLogo = async () => {
		try {
			await api.post(`/image-annotation/delete-logo/${logoId}`)
			await fetchLogos()
			sendMessageToast('Logo excluída com sucesso!', constantsVox.TOAST.TYPES.SUCCESS)
		} catch (error) {
			console.error('Erro ao excluir logo:', error)
			sendMessageToast('Erro ao excluir logo.', constantsVox.TOAST.TYPES.ERROR)
		} finally {
			setIsOpenConfirmation(false)
		}
	}

	return (
		<Box style={{ width: '90%', maxWidth: 800, margin: '0 auto', padding: '16px', backgroundColor: '#f7f7f7' }}>
			<Typography variant='h4' gutterBottom>
				Lista de Logos
			</Typography>
			<Button
				variant='contained'
				onClick={() => {
					setIsCreatingLogo(true)
					setEditingLogo(null)
				}}
			>
				Criar novo logo
			</Button>
			<List>
				{logos.map((logo) => (
					<ListItem key={logo.id} style={{ display: 'block', marginBottom: '8px', border: '2px solid #d1d1d1' }}>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<ListItemIcon style={{ marginRight: '0.3em', position: 'relative' }}>
								{logo.projectName[0] === 'Logo ainda sem projetos' && (
									<Tooltip title='Excluir logo' placement='left'>
										<span
											onClick={() => {
												setLogoId(logo.id)
												setIsOpenConfirmation(true)
											}}
											style={{
												position: 'absolute',
												bottom: '3.5em',
												right: '3.75em',
												backgroundColor: 'white',
												color: 'black',
												border: '2px solid black',
												borderRadius: '50%',
												padding: '1px 3px 1px 3px',
												fontSize: '12.5px',
												fontWeight: 'bold',
												cursor: 'pointer',
												zIndex: 1
											}}
										>
											X
										</span>
									</Tooltip>
								)}
								<Avatar
									src={logo.imagePath}
									sx={{ width: 56, height: 56, objectFit: 'contain', backgroundColor: 'white', zIndex: 0 }}
									variant='square'
								/>
							</ListItemIcon>
							<ListItemText primary={logo.name} secondary={logo.cleanName} />
							<div>
								<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
									<Button
										style={{ border: '1px solid' }}
										onClick={() => {
											setIsCreatingLogo(true)
											setEditingLogo(logo)
										}}
									>
										Editar
									</Button>
								</div>
								<Typography
									style={{ marginLeft: '16px', color: logo.projectName[0] === 'Logo ainda sem projetos' ? 'green' : 'black' }}
								>
									{logo.projectName.join(', ')}
								</Typography>
							</div>
						</div>

						{logo?.description && (
							<div style={{ marginTop: '2em' }}>
								<hr />
								<Typography style={{ marginLeft: '0.1em', marginTop: '0.2em' }}>{logo?.description}</Typography>
							</div>
						)}
						{logo?.secondaryImagesPaths && (
							<div style={{ marginTop: '2em' }}>
								<hr />

								{logo.secondaryImagesPaths.map((it, idx) => (
									<ListItemIcon key={it}>
										<Tooltip title={'Imagem secundária: ' + it} placement='top-start'>
											<Avatar
												src={it}
												sx={{
													width: 56,
													height: 56,
													objectFit: 'contain',
													backgroundColor: 'white',
													marginLeft: idx != 0 && '0.2em'
												}}
												variant='square'
											/>
										</Tooltip>
									</ListItemIcon>
								))}
							</div>
						)}
					</ListItem>
				))}
			</List>
			{isOpenConfirmation && (
				<DialogConfirmation
					open={isOpenConfirmation}
					handleClose={() => setIsOpenConfirmation(false)}
					title={'Deseja realmente excluir a logo?'}
					buttonText={'Excluir'}
					handleConfirm={handleDeleteLogo}
				/>
			)}
			{isCreatingLogo && <ImageAnnotationCreateLogo isOpen={isCreatingLogo} handleOnClose={onCloseCreating} editingLogo={editingLogo} />}
		</Box>
	)
}

export default ImageAnnotationListLogos
