const recordData = [
	[0, 1, 'Youtube', 159405, 204, 'Redes socias', 1690],
	[1, 2, 'Youtube', 169403, 216, 'Redes socias', 1796],
	[2, 3, 'Youtube', 113187, 144, 'Redes socias', 1200],
	[3, 4, 'Youtube', 175251, 224, 'Redes socias', 1858],
	[4, 5, 'Youtube', 151105, 193, 'Redes socias', 1602],
	[5, 6, 'Youtube', 178835, 229, 'Redes socias', 1896],
	[6, 7, 'Youtube', 195625, 250, 'Redes socias', 2074],
	[7, 8, 'Youtube', 165253, 211, 'Redes socias', 1752],
	[8, 9, 'Youtube', 176949, 226, 'Redes socias', 1876],
	[9, 10, 'Youtube', 173365, 222, 'Redes socias', 1838],
	[10, 11, 'Youtube', 160348, 205, 'Redes socias', 1700],
	[11, 12, 'Youtube', 166762, 213, 'Redes socias', 1768],
	[12, 13, 'Youtube', 160348, 205, 'Redes socias', 1700],
	[13, 14, 'Youtube', 159782, 204, 'Redes socias', 1694],
	[14, 15, 'Youtube', 159594, 204, 'Redes socias', 1692],
	[15, 16, 'Youtube', 81495, 104, 'Redes socias', 864],
	[16, 17, 'Youtube', 203171, 260, 'Redes socias', 2154],
	[17, 18, 'Youtube', 189211, 242, 'Redes socias', 2006],
	[18, 19, 'Youtube', 144879, 185, 'Redes socias', 1536],
	[19, 20, 'Youtube', 125449, 160, 'Redes socias', 1330],
	[20, 21, 'Youtube', 135824, 173, 'Redes socias', 1440],
	[21, 22, 'Youtube', 147898, 189, 'Redes socias', 1568],
	[22, 23, 'Youtube', 149595, 191, 'Redes socias', 1586],
	[23, 24, 'Youtube', 83004, 106, 'Redes socias', 880],
	[24, 25, 'Youtube', 184306, 236, 'Redes socias', 1954],
	[25, 26, 'Youtube', 202416, 259, 'Redes socias', 2146],
	[26, 27, 'Youtube', 176006, 225, 'Redes socias', 1866],
	[27, 28, 'Youtube', 133372, 170, 'Redes socias', 1414],
	[28, 29, 'Youtube', 84513, 108, 'Redes socias', 896],
	[29, 30, 'Youtube', 158650, 203, 'Redes socias', 1682],
	[30, 1, 'Tiktok', 154392, 101, 'Redes socias', 7540],
	[31, 2, 'Tiktok', 259804, 170, 'Redes socias', 12688],
	[32, 3, 'Tiktok', 153859, 100, 'Redes socias', 7514],
	[33, 4, 'Tiktok', 150132, 98, 'Redes socias', 7332],
	[34, 5, 'Tiktok', 117125, 76, 'Redes socias', 5720],
	[35, 6, 'Tiktok', 317301, 208, 'Redes socias', 15496],
	[36, 7, 'Tiktok', 183673, 120, 'Redes socias', 8970],
	[37, 8, 'Tiktok', 152262, 99, 'Redes socias', 7436],
	[38, 9, 'Tiktok', 449865, 295, 'Redes socias', 21970],
	[39, 10, 'Tiktok', 384914, 252, 'Redes socias', 18798],
	[40, 11, 'Tiktok', 159715, 104, 'Redes socias', 7800],
	[41, 12, 'Tiktok', 275243, 180, 'Redes socias', 13442],
	[42, 13, 'Tiktok', 183673, 120, 'Redes socias', 8970],
	[43, 14, 'Tiktok', 135758, 89, 'Redes socias', 6630],
	[44, 15, 'Tiktok', 155989, 102, 'Redes socias', 7618],
	[45, 16, 'Tiktok', 187399, 122, 'Redes socias', 9152],
	[46, 17, 'Tiktok', 379058, 248, 'Redes socias', 18512],
	[47, 18, 'Tiktok', 229458, 150, 'Redes socias', 11206],
	[48, 19, 'Tiktok', 191126, 125, 'Redes socias', 9334],
	[49, 20, 'Tiktok', 151197, 99, 'Redes socias', 7384],
	[50, 21, 'Tiktok', 216148, 141, 'Redes socias', 10556],
	[51, 22, 'Tiktok', 201774, 132, 'Redes socias', 9854],
	[52, 23, 'Tiktok', 196982, 129, 'Redes socias', 9620],
	[53, 24, 'Tiktok', 279502, 183, 'Redes socias', 13650],
	[54, 25, 'Tiktok', 210292, 138, 'Redes socias', 10270],
	[55, 26, 'Tiktok', 559536, 367, 'Redes socias', 27326],
	[56, 27, 'Tiktok', 276308, 181, 'Redes socias', 13494],
	[57, 28, 'Tiktok', 142147, 93, 'Redes socias', 6942],
	[58, 29, 'Tiktok', 128305, 84, 'Redes socias', 6266],
	[59, 30, 'Tiktok', 66016, 43, 'Redes socias', 3224],
	[60, 1, 'Instagram', 88622, 10, 'Redes socias', 2755],
	[61, 2, 'Instagram', 142985, 17, 'Redes socias', 4445],
	[62, 3, 'Instagram', 88300, 10, 'Redes socias', 2745],
	[63, 4, 'Instagram', 87818, 10, 'Redes socias', 2730],
	[64, 5, 'Instagram', 77846, 9, 'Redes socias', 2420],
	[65, 6, 'Instagram', 148936, 18, 'Redes socias', 4630],
	[66, 7, 'Instagram', 96181, 11, 'Redes socias', 2990],
	[67, 8, 'Instagram', 92482, 11, 'Redes socias', 2875],
	[68, 9, 'Instagram', 160838, 19, 'Redes socias', 5000],
	[69, 10, 'Instagram', 124006, 15, 'Redes socias', 3855],
	[70, 11, 'Instagram', 73342, 9, 'Redes socias', 2280],
	[71, 12, 'Instagram', 124811, 15, 'Redes socias', 3880],
	[72, 13, 'Instagram', 105510, 13, 'Redes socias', 3280],
	[73, 14, 'Instagram', 104867, 12, 'Redes socias', 3260],
	[74, 15, 'Instagram', 97951, 12, 'Redes socias', 3045],
	[75, 16, 'Instagram', 81062, 9, 'Redes socias', 2520],
	[76, 17, 'Instagram', 148454, 18, 'Redes socias', 4615],
	[77, 18, 'Instagram', 97951, 12, 'Redes socias', 3045],
	[78, 19, 'Instagram', 75594, 9, 'Redes socias', 2350],
	[79, 20, 'Instagram', 82832, 10, 'Redes socias', 2575],
	[80, 21, 'Instagram', 89265, 11, 'Redes socias', 2775],
	[81, 22, 'Instagram', 104384, 12, 'Redes socias', 3245],
	[82, 23, 'Instagram', 89104, 10, 'Redes socias', 2770],
	[83, 24, 'Instagram', 90874, 11, 'Redes socias', 2825],
	[84, 25, 'Instagram', 88461, 10, 'Redes socias', 2750],
	[85, 26, 'Instagram', 137356, 16, 'Redes socias', 4270],
	[86, 27, 'Instagram', 96342, 11, 'Redes socias', 2995],
	[87, 28, 'Instagram', 88300, 10, 'Redes socias', 2745],
	[88, 29, 'Instagram', 96825, 11, 'Redes socias', 3010],
	[89, 30, 'Instagram', 60636, 7, 'Redes socias', 1885],
	[90, 1, 'X', 358131, 156, 'Redes socias', 18442],
	[91, 2, 'X', 612835, 267, 'Redes socias', 31558],
	[92, 3, 'X', 306417, 133, 'Redes socias', 15779],
	[93, 4, 'X', 503679, 219, 'Redes socias', 25937],
	[94, 5, 'X', 377278, 164, 'Redes socias', 19428],
	[95, 6, 'X', 880957, 384, 'Redes socias', 45365],
	[96, 7, 'X', 444314, 193, 'Redes socias', 22880],
	[97, 8, 'X', 415593, 181, 'Redes socias', 21401],
	[98, 9, 'X', 1304201, 568, 'Redes socias', 67160],
	[99, 10, 'X', 563043, 245, 'Redes socias', 28994],
	[100, 11, 'X', 250878, 109, 'Redes socias', 12919],
	[101, 12, 'X', 432818, 188, 'Redes socias', 22288],
	[102, 13, 'X', 302592, 131, 'Redes socias', 15582],
	[103, 14, 'X', 444314, 193, 'Redes socias', 22880],
	[104, 15, 'X', 453888, 197, 'Redes socias', 23373],
	[105, 16, 'X', 471132, 205, 'Redes socias', 24261],
	[106, 17, 'X', 1032253, 450, 'Redes socias', 53156],
	[107, 18, 'X', 641575, 279, 'Redes socias', 33038],
	[108, 19, 'X', 442391, 192, 'Redes socias', 22781],
	[109, 20, 'X', 369627, 161, 'Redes socias', 19034],
	[110, 21, 'X', 314088, 136, 'Redes socias', 16174],
	[111, 22, 'X', 365782, 159, 'Redes socias', 18836],
	[112, 23, 'X', 570714, 248, 'Redes socias', 29389],
	[113, 24, 'X', 1501462, 654, 'Redes socias', 77318],
	[114, 25, 'X', 708591, 308, 'Redes socias', 36489],
	[115, 26, 'X', 1093540, 476, 'Redes socias', 56312],
	[116, 27, 'X', 733487, 319, 'Redes socias', 37771],
	[117, 28, 'X', 450062, 196, 'Redes socias', 23176],
	[118, 29, 'X', 691366, 301, 'Redes socias', 35602],
	[119, 30, 'X', 409845, 178, 'Redes socias', 21105],
	[120, 1, 'Facebook', 61932, 53, 'Redes socias', 2211],
	[121, 2, 'Facebook', 74089, 63, 'Redes socias', 2645],
	[122, 3, 'Facebook', 50700, 43, 'Redes socias', 1810],
	[123, 4, 'Facebook', 54621, 46, 'Redes socias', 1950],
	[124, 5, 'Facebook', 52100, 44, 'Redes socias', 1860],
	[125, 6, 'Facebook', 59663, 51, 'Redes socias', 2130],
	[126, 7, 'Facebook', 34313, 29, 'Redes socias', 1225],
	[127, 8, 'Facebook', 45238, 38, 'Redes socias', 1615],
	[128, 9, 'Facebook', 82072, 70, 'Redes socias', 2930],
	[129, 10, 'Facebook', 71568, 61, 'Redes socias', 2555],
	[130, 11, 'Facebook', 52661, 45, 'Redes socias', 1880],
	[131, 12, 'Facebook', 56722, 48, 'Redes socias', 2025],
	[132, 13, 'Facebook', 49579, 42, 'Redes socias', 1770],
	[133, 14, 'Facebook', 60084, 51, 'Redes socias', 2145],
	[134, 15, 'Facebook', 56442, 48, 'Redes socias', 2015],
	[135, 16, 'Facebook', 58683, 50, 'Redes socias', 2095],
	[136, 17, 'Facebook', 60364, 51, 'Redes socias', 2155],
	[137, 18, 'Facebook', 51400, 44, 'Redes socias', 1835],
	[138, 19, 'Facebook', 50420, 43, 'Redes socias', 1800],
	[139, 20, 'Facebook', 38375, 32, 'Redes socias', 1370],
	[140, 21, 'Facebook', 44117, 37, 'Redes socias', 1575],
	[141, 22, 'Facebook', 55462, 47, 'Redes socias', 1980],
	[142, 23, 'Facebook', 49159, 42, 'Redes socias', 1755],
	[143, 24, 'Facebook', 56722, 48, 'Redes socias', 2025],
	[144, 25, 'Facebook', 46638, 40, 'Redes socias', 1665],
	[145, 26, 'Facebook', 64425, 55, 'Redes socias', 2300],
	[146, 27, 'Facebook', 57002, 49, 'Redes socias', 2035],
	[147, 28, 'Facebook', 58403, 50, 'Redes socias', 2085],
	[148, 29, 'Facebook', 57002, 49, 'Redes socias', 2035],
	[149, 30, 'Facebook', 29972, 25, 'Redes socias', 1070],
	[150, 1, 'Globo', 0, 0, 'TV', 0],
	[151, 2, 'Globo', 0, 0, 'TV', 0],
	[152, 3, 'Globo', 0, 0, 'TV', 0],
	[153, 4, 'Globo', 0, 0, 'TV', 0],
	[154, 5, 'Globo', 0, 0, 'TV', 0],
	[155, 6, 'Globo', 43616, 20, 'TV', 0],
	[156, 7, 'Globo', 218081, 100, 'TV', 0],
	[157, 8, 'Globo', 6564241, 3010, 'TV', 0],
	[158, 9, 'Globo', 436162, 200, 'TV', 0],
	[159, 10, 'Globo', 50159, 23, 'TV', 0],
	[160, 11, 'Globo', 109041, 50, 'TV', 0],
	[161, 12, 'Globo', 21808, 10, 'TV', 0],
	[162, 13, 'Globo', 43616, 20, 'TV', 0],
	[163, 14, 'Globo', 109041, 50, 'TV', 0],
	[164, 15, 'Globo', 0, 0, 'TV', 0],
	[165, 16, 'Globo', 0, 0, 'TV', 0],
	[166, 17, 'Globo', 0, 0, 'TV', 0],
	[167, 18, 'Globo', 0, 0, 'TV', 0],
	[168, 19, 'Globo', 0, 0, 'TV', 0],
	[169, 20, 'Globo', 0, 0, 'TV', 0],
	[170, 21, 'Globo', 0, 0, 'TV', 0],
	[171, 22, 'Globo', 0, 0, 'TV', 0],
	[172, 23, 'Globo', 0, 0, 'TV', 0],
	[173, 24, 'Globo', 0, 0, 'TV', 0],
	[174, 25, 'Globo', 0, 0, 'TV', 0],
	[175, 26, 'Globo', 0, 0, 'TV', 0],
	[176, 27, 'Globo', 0, 0, 'TV', 0],
	[177, 28, 'Globo', 0, 0, 'TV', 0],
	[178, 29, 'Globo', 0, 0, 'TV', 0],
	[179, 30, 'Globo', 0, 0, 'TV', 0]
]

const slides = [
	{
		//configartion slide
		animation: {
			data: {
				series: [
					{ name: 'index', type: 'measure' },
					{ name: 'Dias', type: 'dimension' },
					{ name: 'Redes', type: 'dimension' },
					{ name: 'Impacto em R$', type: 'measure' },
					{ name: 'Segundos de tela', type: 'measure' },
					{ name: 'Tipo de Midia', type: 'dimension' },
					{ name: 'Numero de posts', type: 'measure' }
				],
				records: recordData
			},
			style: {
				backgroundColor: 'rgba(0,0,0,0)',
				borderColor: 'rgba(0,0,0,0)',
				title: { backgroundColor: 'rgba(0,0,0,0)', color: 'rgba(255,255,255,0.5)' },
				plot: {
					backgroundColor: 'rgba(0,0,0,0)',
					xAxis: {
						interlacing: { color: 'rgba(0,0,0,0.1)' }
					},
					yAxis: {
						interlacing: { color: 'rgba(0,0,0,0.1)' }
					},
					marker: {
						colorPalette: 'rgba(254, 176, 25, 1)',
						rectangleSpacing: '2em'
					}
				},
				legend: { backgroundColor: 'rgba(0,0,0,0)' }
			},
			config: {
				channels: {
					x: { set: ['Impacto em R$'] },
					y: { set: null },
					label: 'Impacto em R$',
					color: null
				},
				coordSystem: 'polar',
				geometry: 'rectangle',
				align: 'none',
				title: '25 de junho e 24 de julho 2024'
			}
		},
		animationBehavior: { speed: 4 },
		extraData: null
	},
	{
		index: 1,
		text: 'Analisamos a exposição de marca na TV e nas redes sociais durante o período entre 25 de junho e 24 de julho 2024',
		animation: {
			config: {
				channels: {
					x: { set: ['Impacto em R$'] },
					y: { set: null },
					label: 'Impacto em R$',
					color: null
				},
				coordSystem: 'polar',
				geometry: 'rectangle',
				align: 'none',
				title: '25 de junho e 24 de julho 2024'
			},
			style: {
				plot: {
					marker: {
						colorPalette: 'rgba(254, 176, 25, 1)',
						rectangleSpacing: '2em'
					}
				}
			}
		},
		animationBehavior: { speed: 1 },
		extraData: null
	},
	{
		index: 2,
		text: 'O Valor Atribuído total das aparições de marcas na TV foi de R$ 7.595.764,62, equivalente a 22,71% do retorno total das redes sociais.',
		animation: {
			config: {
				channels: {
					x: { set: ['Impacto em R$'] },
					// y: { set: null },
					y: { set: ['Tipo de Midia'] },
					label: 'Impacto em R$',
					color: 'Tipo de Midia'
				},
				coordSystem: 'polar',
				geometry: 'rectangle',
				align: 'none',
				title: null,
				sort: 'none'
			},
			style: {
				backgroundColor: 'rgba(0,0,0,0)',
				borderColor: 'rgba(0,0,0,0)',
				title: { backgroundColor: 'rgba(0,0,0,0)' },
				plot: {
					marker: {
						colorPalette: 'rgba(254, 176, 25, 1) rgba(15, 186, 118, 1)',
						rectangleSpacing: '1em'
					}
				},
				legend: { backgroundColor: 'rgba(0,0,0,0)' }
			}
		},
		animationBehavior: { speed: 1.6 },
		extraData: null
	},
	{
		index: 3,
		text: 'O X foi a rede de maior retorno, totalizando pouco mais de R$ 17 milhões. O TikTok, com R$ 6,6 milhões, foi a segunda rede de maior retorno. Já o Facebook, gerou R$ 1,6 milhão, sendo a rede menos rentável para as marcas.',
		animation: {
			config: {
				channels: {
					x: { set: ['Impacto em R$'] },
					y: { set: ['Redes'] },
					label: 'Impacto em R$',
					color: 'Redes',
					size: null
				},
				coordSystem: 'polar',
				geometry: 'rectangle',
				align: 'none',
				title: null,
				sort: 'byValue'
			},
			style: {
				backgroundColor: 'rgba(0,0,0,0)',
				borderColor: 'rgba(0,0,0,0)',
				title: { backgroundColor: 'rgba(0,0,0,0)' },
				plot: {
					marker: {
						colorPalette:
							'rgba(186, 34, 59, 1) rgba(0, 0, 0, 1) rgba(230, 81, 44, 1) rgba(43, 179, 174, 1)  rgba(44, 125, 230, 1) rgba(15, 186, 118, 1)',
						rectangleSpacing: '1em'
					}
				},
				legend: { backgroundColor: 'rgba(0,0,0,0)' }
			}
		},
		animationBehavior: { speed: 1.6 },
		extraData: null
	},
	{
		index: 4,
		text: 'Em número de postagens, o X também lidera, com 2,76 vezes mais postagens do que o TikTok, segunda com mais conteúdos.',
		animation: {
			config: {
				channels: {
					x: { set: ['Redes'] },
					y: { set: ['Numero de posts'] },
					label: 'Numero de posts',
					color: 'Redes',
					size: 'Numero de posts'
				},
				coordSystem: 'cartesian',
				geometry: 'rectangle',
				align: 'none',
				title: null,
				sort: 'none'
			}
		},
		animationBehavior: { speed: 1 },
		extraData: {
			type: 'column',
			series: [
				{
					name: 'Texto',
					data: [0, 0, 0, 763664, 49764, 0]
				},
				{
					name: 'Imagem',
					data: [0, 0, 59739, 4391, 4391, 0]
				},
				{
					name: 'Video',
					data: [49468, 324714, 37826, 67382, 4391, 0]
				}
			],
			categories: ['Youtube', 'TikTok', 'Instagram', 'X', 'Facebook', 'Globo']
		}
	},
	{
		index: 5,
		text: 'Ao todo, foram 274,3 mil segundos de tempo de tela nas redes sociais. O X foi a rede que registrou mais segundos expondo as marcas, alcançando 580 milhões de views, com cada usuário impactado em média por 44 vezes.',
		animation: {
			config: {
				channels: {
					x: { set: ['Dias'] },
					y: { set: ['Segundos de tela'] },
					label: null,
					color: 'Redes',
					size: null
				},
				coordSystem: 'cartesian',
				geometry: 'line',
				align: 'none',
				title: null,
				sort: 'none'
			}
		},
		animationBehavior: { speed: 1 },
		extraData: null
	}
]

export { slides }
