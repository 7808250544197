import React, { useEffect, useRef, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { PlusIcon } from 'utils/SystemIcons'
import { Button, Grid } from '@mui/material'
import PhotoGallerySkeletonLoad from 'components/SkeletonLoads/PhotoGallerySkeletonLoad/PhotoGallerySkeletonLoad'
import PhotoGalleryEmptyFeed from './components/PhotoGalleryEmptyFeed/PhotoGalleryEmptyFeed'
const infiniteScrollHeight = 700

const PhotoGallery = ({ photoGallery, load, loading }) => {
	const [feedPhotos, setFeedPhotos] = useState(photoGallery)
	const scrollableDiv = useRef(null)

	useEffect(() => {
		if (photoGallery) {
			if (photoGallery?.searchAfter?.length > 0 && photoGallery.page > 0) {
				setFeedPhotos({ ...photoGallery, data: [...feedPhotos.data, ...photoGallery.data] })
			} else {
				setFeedPhotos(photoGallery)
				if (scrollableDiv.current) {
					scrollableDiv.current.scrollTop = 0
				}
			}
		}
	}, [photoGallery])

	useEffect(() => {
		if (scrollableDiv.current) {
			scrollableDiv.current.scrollTop = 0
		}
	}, [])

	const loadMore = () => {
		if (load) {
			load(true)
		}
	}

	const cleanLoad = () => {
		if (scrollableDiv.current) {
			scrollableDiv.current.scrollTop = 0
		}
		if (load) {
			load()
		}
	}

	return (
		<>
			<div
				ref={scrollableDiv}
				id='scrollableDivPhotoGallery'
				style={{
					height: infiniteScrollHeight,
					overflowY: 'scroll',
					marginLeft: '0.1em',
					marginRight: '0.1em',
					marginTop: '1em',
					overflow: 'hidden',
					borderRadius: '25px',
					border: '1px solid #e0e0e0'
				}}
			>
				<InfiniteScroll
					dataLength={feedPhotos?.data?.length}
					next={loadMore}
					style={{
						pointerEvents: loading ? 'none' : 'auto',
						display: 'flex',
						flexWrap: 'wrap',
						gap: '3px',
						width: '100%'
					}}
					scrollThreshold={0.8} // It means the next will be called when user comes below 80% of the total height
					hasMore={!photoGallery?.endResults}
					height={infiniteScrollHeight}
					loader={<PhotoGallerySkeletonLoad height={infiniteScrollHeight} />}
					endMessage={
						<div style={{ marginTop: '1em', width: '100%', paddingBottom: '5em' }}>
							{<PhotoGalleryEmptyFeed />}

							<Grid container justifyContent='center'>
								<Button onClick={cleanLoad} style={{ color: '#6b6a6a', borderRadius: '100px' }}>
									<PlusIcon size={30} />
									<div style={{ fontFamily: 'Poppins', marginLeft: '8px' }}>Tentar buscar mais?</div>
								</Button>
							</Grid>
						</div>
					}
					scrollableTarget='scrollableDivPhotoGallery'
				>
					{feedPhotos?.data?.map((item, index) => (
						<div
							key={item.img + item.docId}
							style={{
								flex: index % 5 === 0 ? '1 1 40%' : '1 1 20%',
								display: 'flex'
							}}
						>
							<img src={item.img} alt='' style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
						</div>
					))}
				</InfiniteScroll>
			</div>
		</>
	)
}

export default PhotoGallery
