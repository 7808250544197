import React from 'react'
import ReactPlayer from 'react-player'

const Title = ({ title, date }) => {
	return (
		<div>
			<div
				style={{
					zIndex: '0',
					height: '60vh',
					marginTop: '10vh',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center'
				}}
			>
				<div style={{ fontFamily: 'MontSerrat', color: 'white', fontWeight: '600' }}>
					<div style={{ fontSize: '2.28vw', display: 'flex', justifyContent: 'center' }}>{title}</div>
					<div style={{ fontSize: '7.1vw', display: 'flex', justifyContent: 'center' }}>{date}</div>
				</div>
			</div>
			{/* <div
				style={{
					zIndex: '0',
					marginBottom: '15vh',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					height: '60vh'
				}}
			>
				<div className='video-container'>
					<ReactPlayer url={'https://vimeo.com/997083266'} controls={true} width='100%' height='100%' />
				</div>
			</div> */}
		</div>
	)
}

export default Title
