import React, { useRef, memo, useState } from 'react'
import { Grid, Card, FormControl, Select, MenuItem, Divider, styled } from '@mui/material'
import { connect } from 'react-redux'
import LoadingBar from 'components/LoadingBar/LoadingBar'
import GraphTitle from 'components/GraphTitle/GraphTitle'
import { makeStyles } from '@mui/styles'
import GenderAgeChart from './components/GenderAgeChart/GenderAgeChart'
import { BarsIcon, ChartInfographicIcon } from 'utils/SystemIcons'

const blue = 'rgba(29, 138, 248, 100%)'
const grey = 'rgba(0, 0, 0, 4%)'
const darkGrey = 'rgba(0, 0, 0, 25%)'

const useStyles = makeStyles(() => ({
	formControl: {
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderColor: blue
		},
		width: '100%'
	},
	select: {
		'&:hover': {
			borderColor: grey
		},
		'&:before': {
			borderColor: grey
		},
		'&:after': {
			borderColor: blue
		},
		height: '35px',
		'& .MuiSvgIcon-root': {
			color: darkGrey
		}
	},
	tab: {
		fontFamily: 'Poppins',
		fontSize: 12,
		fontWeight: 300,
		color: '#828282'
	}
}))

const isFirefox = typeof InstallTrigger !== 'undefined'

const StyledFormControl = styled(FormControl)({
	'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
		borderColor: blue
	},
	width: '100%'
})

const StyledSelect = styled(Select)({
	'&:hover': {
		borderColor: grey
	},
	'&:before': {
		borderColor: grey
	},
	'&:after': {
		borderColor: blue
	},
	height: '35px',
	'& .MuiSvgIcon-root': {
		color: darkGrey
	}
})

const optionsList = [
	{
		title: 'Totais',
		type: 'infographic'
	},
	{
		title: 'Distribuído',
		type: 'bars'
	}
]

const getIcon = (type) => {
	switch (type) {
		case 'bars':
			return <BarsIcon color={'#1d8cf8'} size={20} style={{ marginRight: '10px' }} />
		case 'infographic':
			return (
				<div style={{ color: '#1d8cf8' }}>
					<ChartInfographicIcon color={'#1d8cf8'} size={20} style={{ marginRight: '10px' }} />
				</div>
			)
	}
}

const GenderAgeDistribution = ({ cards, isFetchingData, globalFiltersTermRedux }) => {
	const loadingBarRef = useRef()
	const classes = useStyles()
	const [selectedOptionList, setSelectedOptionList] = useState('infographic')

	const onPartialLoading = (status) => {
		loadingBarRef.current.load(status)
	}

	const handleSelect = (event) => {
		setSelectedOptionList(event.target.value)
	}

	return (
		<>
			<Grid item xs={12}>
				<Card style={{ height: '100%', width: '100%', borderRadius: '20px' }}>
					<Grid container spacing={3} style={{ background: '#fbfbfb', height: '70px', borderBottom: '1px solid #f5f5f5' }}>
						<Grid item xs={12}>
							<GraphTitle
								id='span-gender-age'
								title='Gênero e Idade'
								icon={<BarsIcon size={25} style={{ color: '#1d8cf8' }} />}
								description={`
										Distribuição de gênero e idade dos usuários que interagiram com as postagens.
										Cabe ressaltar que a idade é contabilizada apenas para os usuários que se enquadram nos gêneros masculino e feminino.
									`}
							/>
						</Grid>
					</Grid>
					<div>
						<LoadingBar ref={loadingBarRef} />
					</div>
					{globalFiltersTermRedux.sources.length === 1 && globalFiltersTermRedux.sources.includes('news') ? (
						<div
							style={{
								height: '40vh',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								fontFamily: 'Poppins',
								fontSize: '24px',
								color: '#bdbbbb'
							}}
						>
							Componente não disponível para a fonte escolhida (Notícias)
						</div>
					) : (
						<>
							<Grid item xs={12} container justifyContent='flex-end'>
								<Grid item xs={2} style={{ marginTop: '6px', marginRight: '5px' }}>
									<StyledFormControl className={classes.formControl}>
										<StyledSelect
											id='select-gender-age-distribution'
											variant='standard'
											className={classes.select}
											value={selectedOptionList}
											onChange={handleSelect}
											style={{ marginTop: '7px' }}
											MenuProps={{
												anchorOrigin: {
													vertical: 'bottom',
													horizontal: 'left'
												},
												transformOrigin: {
													vertical: 'top',
													horizontal: 'left'
												},
												PaperProps: {
													style: {
														marginTop: '0px',
														marginLeft: '-1px',
														borderRadius: '0px 0px 25px 25px',
														backdropFilter: 'blur(10px)',
														backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
														borderBottom: `1px solid ${
															isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'
														}`,
														borderLeft: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
														borderRight: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
														boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
													}
												}
											}}
										>
											{optionsList.map((option) => {
												return (
													<MenuItem key={option.type} value={option.type}>
														<Grid
															container
															justifyContent='flex-start'
															alignItems='center'
															style={{ fontFamily: 'Poppins' }}
														>
															{getIcon(option.type)}
															{option.title}
														</Grid>
													</MenuItem>
												)
											})}
										</StyledSelect>
									</StyledFormControl>
								</Grid>
							</Grid>
							<Divider style={{ backgroundColor: 'rgba(0,0,0,0.001)', height: '1px', marginTop: '-1px', width: '100%' }} />
							<GenderAgeChart
								cards={cards}
								filters={globalFiltersTermRedux}
								isFetchingData={isFetchingData}
								onPartialLoading={onPartialLoading}
								selectedOption={selectedOptionList}
							/>
						</>
					)}
				</Card>
			</Grid>
		</>
	)
}

const mapStateToProps = ({ store }) => ({
	globalFiltersTermRedux: store.global.filters
})

export default connect(mapStateToProps)(memo(GenderAgeDistribution))
