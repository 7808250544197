import React, { useState } from 'react'
import { Grid } from '@mui/material'
import AddWidgetsDashboardShareable from '../../../components/AddWidgetsDashboardShareable/AddWidgetsDashboardShareable'
import DragResizeDashboard from '../../../components/DragResizeDashboard/DragResizeDashboard'
// import HeaderTermDashboardShareable from './components/HeaderTermDashboardShareable/HeaderTermDashboardShareable'
import { renderWidgets, DEFAULT_LAYOUTS_TERM } from './utils/MetricsTermDashboardShareableUtils'
import constantsVox from 'constants-vox'
const LAYOUTS_WIDGETS_KEY = constantsVox.CARD_TYPE.TERM.toLowerCase()

const MetricsTermDashboardShareable = ({
	layout,
	onLayoutChange,
	editMode,
	addWidgetMode,
	toggleAddWidgetMode,
	widgetsOnScreen,
	handleWidgetsOnScreen,
	handleRemoveWidget,
	cards,
	descriptions,
	globalFilters
}) => {
	const [droppingItem, setDroppingItem] = useState({ i: 'defaultDrag', w: 0, h: 0 })
	const [isDropping, setIsDropping] = useState(false)

	const onChangeDragItem = (item) => {
		setDroppingItem(item)
	}

	const handleAddWidget = (widget, newLayout) => {
		onLayoutChange(newLayout, LAYOUTS_WIDGETS_KEY)
		handleWidgetsOnScreen([...widgetsOnScreen, widget], LAYOUTS_WIDGETS_KEY)
	}

	const setIsDroping = (droppingState) => {
		setIsDropping(droppingState)
	}

	const removeWidget = (keyName) => {
		handleRemoveWidget(keyName, LAYOUTS_WIDGETS_KEY)
	}

	return (
		<>
			<Grid container>
				{/* <Grid item xs={addWidgetMode ? 10 : 12} style={{ marginLeft: '0.5em', paddingRight: addWidgetMode ? '1em' : '0.67em' }}>
					<HeaderTermDashboardShareable analytics={{}} />
				</Grid> */}
				<Grid item xs={addWidgetMode ? 10 : 12}>
					<DragResizeDashboard
						editMode={editMode}
						layouts={layout}
						onLayoutChange={(_, layout) => {
							onLayoutChange({ md: layout.md, sm: layout.sm }, LAYOUTS_WIDGETS_KEY)
						}}
						droppingItem={droppingItem}
						handleAddWidget={handleAddWidget}
						addWidgetMode={addWidgetMode}
						isDroppable={isDropping}
					>
						{renderWidgets({ editMode, addWidgetMode, removeWidget, cards, descriptions, widgetsOnScreen, globalFilters })}
					</DragResizeDashboard>
				</Grid>
				{addWidgetMode && (
					<Grid item xs={2} style={{ position: 'fixed', zIndex: '1', top: '8em', right: '1em', width: '100%', height: '50vh' }}>
						<AddWidgetsDashboardShareable
							defaultWidgetsLayouts={DEFAULT_LAYOUTS_TERM}
							addWidgetMode={addWidgetMode}
							toggleAddWidgetMode={toggleAddWidgetMode}
							onChangeDragItem={onChangeDragItem}
							widgetsOnScreen={widgetsOnScreen}
							setIsDroping={setIsDroping}
							descriptions={descriptions}
						/>
					</Grid>
				)}
			</Grid>
		</>
	)
}

export default MetricsTermDashboardShareable
