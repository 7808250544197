import React, { useState } from 'react'
import { Grid, Tooltip, IconButton, Menu, Button } from '@mui/material'
import { DotsVerticalIcon, HideIcon, UserIcon, AngryIcon, HeartIcon, FavouriteIcon } from 'utils/SystemIcons'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import api from 'service/service'
import { verifyServerResponseCanShowToast } from 'utils/generalFunctions'
import NewMonitoringDialogProfile from 'pages/Monitoring/components/ButtonModalNewInsight/components/NewMonitoringDialogProfile/NewMonitoringDialogProfile'
import { isUndefined } from 'lodash'
import { useQueryClient } from 'hooks/ReactQueryHooks'
import DialogConfirmation from 'components/DialogConfirmation/DialogConfirmation'

const isFirefox = typeof InstallTrigger !== 'undefined'
const blue = 'rgba(29, 138, 248, 100%)'
const red = 'rgba(255, 43, 107, 100%)'
const yellow = 'rgba(255, 170, 0, 100%)'
const IconSize = 15
const Iconstyle = { color: '#9A9A9A', marginRight: '0.5em' }
const HideIconOption = <HideIcon size={IconSize} style={Iconstyle} />
const ProfileCardIcon = <UserIcon size={IconSize} style={Iconstyle} />
const AngryIconOption = <AngryIcon size={IconSize} style={{ color: red, marginRight: '0.5em' }} />
const HeartIconOption = <HeartIcon size={IconSize} style={{ color: blue, marginRight: '0.5em' }} />
const FavoriteIconOption = <FavouriteIcon size={IconSize} style={{ color: yellow, marginRight: '0.5em' }} />

const categoriesPtBr = {
	GENERAL: 'GERAL',
	POLITICS: 'POLÍTICA',
	RELIGION: 'RELIGIÃO',
	'MEDIA CHANNEL': 'CANAL DE MÍDIA'
}

const ButtonStyle = (color) => {
	return {
		borderRadius: '100px',
		textAlign: 'center',
		color: color,
		justifyContent: 'center',
		alignItems: 'center',
		minWidth: '15em',
		fontFamily: 'Poppins'
	}
}

const ButtonOption = ({ title, icon, color = '#9A9A9A', onClick }) => (
	<Grid item xs={12} style={{ height: '3em' }} container justifyContent='center' alignItems='center'>
		<Button style={ButtonStyle(color)} onClick={onClick}>
			{icon}
			{title}
		</Button>
	</Grid>
)

const UserProfileMenuMore = ({
	cardColor = blue,
	source,
	cardTitle,
	selectedCard,
	cardData,
	onHideAccount,
	showMenuItem = ['haters', 'lovers', 'hide', 'createProfile', 'favourite']
}) => {
	const [openMenu, setOpenMenu] = useState(false)
	const [anchorEl, setAnchorEl] = useState(null)
	const [hideUser, setHideUser] = useState(false)
	const [newProfileInfoProfileCard, setNewProfileInfoProfileCard] = useState(null)
	const queryClient = useQueryClient()

	const closeNewCardProfileModal = () => {
		setNewProfileInfoProfileCard(null)
	}

	const toggleMenu = (event) => {
		if (openMenu) {
			setAnchorEl(null)
		} else {
			setAnchorEl(event.currentTarget)
		}
		setOpenMenu(!openMenu)
	}

	const toggleHide = () => {
		setHideUser(!hideUser)
	}

	const openProfileCardCreation = (selectedCard, occurrenceCardData, source) => {
		let newProfileInfo = {
			category: { value: selectedCard.searchCategoryId, label: categoriesPtBr[selectedCard.searchCategoryName] },
			country: { value: selectedCard.countryId, lang_id: selectedCard.languageId, label: selectedCard.countryName },
			cardName: ''
		}

		const sourceNameCapital = source.charAt(0).toUpperCase() + source.slice(1)

		newProfileInfo[`has${sourceNameCapital}Search`] = true
		if (source === 'facebook') {
			newProfileInfo[`${source}Page`] = '@' + occurrenceCardData.author.username
		} else if (source === 'youtube') {
			newProfileInfo[`${source}Channel`] = '@' + occurrenceCardData.author.username
		} else {
			newProfileInfo[`${source}Account`] = '@' + occurrenceCardData.author.username
		}
		newProfileInfo.cardName = occurrenceCardData.author.name
		setNewProfileInfoProfileCard(newProfileInfo)
	}

	const handleClassificationAccount = (cardTitle, classification) => {
		api.post('/occurrences/classification-account', { cardTitle, id_pesquisa: selectedCard.id, source, classification })
			.then((response) => {
				if (response?.data?.toast) {
					sendMessageToast(response?.data?.toast?.message, response?.data?.toast?.type)
				}
			})
			.catch((error) => {
				if (verifyServerResponseCanShowToast(error)) {
					sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
				}
			})
	}

	const handleAddToFavorites = (cardTitle) => {
		api.post('/occurrences/add-to-favorites', { cardTitle, id_pesquisa: selectedCard.id })
			.then((response) => {
				if (response?.data?.toast) {
					sendMessageToast(response?.data?.toast?.message, response?.data?.toast?.type)
				}
			})
			.catch((error) => {
				if (verifyServerResponseCanShowToast(error)) {
					sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
				}
			})
	}

	const handleConfirmHide = (cardTitle) => {
		let stopAccounts = selectedCard.stopAccounts

		if (source === 'twitter') {
			stopAccounts.twitter.push('@' + cardTitle)
		} else if (source === 'bluesky') {
			if (isUndefined(stopAccounts.bluesky)) {
				stopAccounts.tiktok = []
			}
			stopAccounts.bluesky.push('@' + cardTitle)
		} else if (source === 'facebook') {
			stopAccounts.facebook.push('@' + cardTitle)
		} else if (source === 'instagram') {
			if (isUndefined(stopAccounts.instagram)) {
				stopAccounts.instagram = []
			}
			stopAccounts.instagram.push('@' + cardTitle)
		} else if (source === 'tiktok') {
			if (isUndefined(stopAccounts.tiktok)) {
				stopAccounts.tiktok = []
			}
			stopAccounts.tiktok.push('@' + cardTitle)
		} else if (source === 'youtube') {
			if (isUndefined(stopAccounts.youtube)) {
				stopAccounts.youtube = []
			}
			stopAccounts.youtube.push('@' + cardTitle)
		} else if (source === 'news') {
			if (isUndefined(stopAccounts.news)) {
				stopAccounts.news = []
			}
			stopAccounts.news.push(cardTitle)
		} else if (source === 'metaAds') {
			if (isUndefined(stopAccounts.instagram)) {
				stopAccounts.instagram = []
			}
			stopAccounts.metaAds.push('@' + cardTitle)
		}
		api.post('/occurrences/hide-account', { stopAccounts, id_pesquisa: selectedCard.id })
			.then((response) => {
				onHideAccount()
				if (response?.data?.toast) {
					invalidateQueriesOnChangeCard()
					sendMessageToast(response?.data?.toast?.message, response?.data?.toast?.type)
				}
			})
			.catch((error) => {
				if (verifyServerResponseCanShowToast(error)) {
					sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
				}
			})
	}

	const invalidateQueriesOnChangeCard = () => {
		queryClient.invalidateQueries('relevanceChange_useUpdateData')
	}

	return (
		<>
			<Tooltip title='Opções' enterDelay={500} enterNextDelay={500}>
				<IconButton size='small' onClick={toggleMenu}>
					<DotsVerticalIcon size={20} style={{ color: cardColor }} />
				</IconButton>
			</Tooltip>
			<Menu
				open={openMenu}
				onClose={toggleMenu}
				anchorEl={anchorEl}
				transformOrigin={{ horizontal: 'left', vertical: 'center' }}
				anchorOrigin={{ horizontal: 'center', vertical: 'center' }}
				PaperProps={{
					style: {
						minWidth: '10em',
						borderRadius: '20px',
						backdropFilter: 'blur(10px)',
						backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
						border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
						boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
					}
				}}
			>
				{showMenuItem.includes('hide') && (
					<ButtonOption
						title='OCULTAR'
						icon={HideIconOption}
						onClick={() => {
							toggleMenu()
							toggleHide()
						}}
					/>
				)}
				{!['news'].includes(source) && (
					<Grid>
						{showMenuItem.includes('haters') && (
							<ButtonOption
								title='HATER'
								icon={AngryIconOption}
								color={red}
								onClick={() => {
									toggleMenu()
									handleClassificationAccount(cardTitle, 'haters')
								}}
							/>
						)}
						{showMenuItem.includes('lovers') && (
							<ButtonOption
								title='LOVER'
								icon={HeartIconOption}
								color={blue}
								onClick={() => {
									toggleMenu()
									handleClassificationAccount(cardTitle, 'lovers')
								}}
							/>
						)}
					</Grid>
				)}
				{showMenuItem.includes('favourite') && (
					<ButtonOption
						title='FAVORITO'
						icon={FavoriteIconOption}
						color={yellow}
						onClick={() => {
							toggleMenu()
							handleAddToFavorites(cardTitle)
						}}
					/>
				)}
				{!['news'].includes(source) && selectedCard.type === 'term' && cardData && showMenuItem.includes('createProfile') && (
					<ButtonOption
						title='Criar cartão de conta'
						icon={ProfileCardIcon}
						onClick={() => openProfileCardCreation(selectedCard, cardData, source)}
					/>
				)}
			</Menu>
			{newProfileInfoProfileCard && (
				<NewMonitoringDialogProfile
					close={closeNewCardProfileModal}
					externalCreateCardInformation={newProfileInfoProfileCard}
					cardTermId={selectedCard.id}
				/>
			)}
			{hideUser && (
				<DialogConfirmation
					open={hideUser}
					handleClose={toggleHide}
					handleConfirm={() => {
						toggleHide()
						handleConfirmHide(cardTitle)
					}}
					color={'#ff2b6b'}
					title={`Deseja realmente ocultar aparições de ${cardTitle}?`}
					buttonText={'OCULTAR'}
					icon={<HideIcon className='fix-icon-margin-bottom-negative' size={25} color='#ff2b6b' style={{ marginRight: '5px' }} />}
				/>
			)}
		</>
	)
}

export default UserProfileMenuMore
