import React from 'react'
import { connect } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'
import PrivateRoute from './components/PrivateRoute'
//redux
import { store } from 'store/redux/store'
import { changeRedirectUrlAction } from 'store/redux/actions/global.actions'
//page componentsd
import Login from 'pages/Login/Login'
import Register from 'pages/Register/Register'
import ValidateEmail from 'pages/ValidateEmail/ValidateEmail'
import ResetPassword from 'pages/ResetPassword/ResetPassword'
import Profile from 'pages/Profile/Profile'
import Report from 'pages/Report/Report'
import SubUser from 'pages/SubUser/SubUser'
import Monitoring from 'pages/Monitoring/Monitoring'
import SentimentAnalysis from 'pages/Monitoring/modules/SentimentAnalysis/SentimentAnalysis'
import SocialProfile from 'pages/Monitoring/modules/SocialProfile/SocialProfile'
import AccountsGroup from 'pages/Monitoring/modules/AccountsGroup/AccountsGroup'
import RedirectPage from 'pages/Redirect/Redirect'
import Financial from 'pages/Financial/Financial'
import ImageAnnotation from 'pages/InternalTools/ImageAnnotation'
import Products from 'pages/Products/Products'
import AccountManagement from 'pages/AccountManagement/AccountManagement'
import SocialProfileAccountManagement from 'pages/AccountManagement/modules/SocialProfileAccountManagement/SocialProfileAccountManagement'
import SharingDashboard from 'pages/SharingDashboard/SharingDashboard'
import Aura from 'pages/AuraSight/components/Jul2024/Aura/Aura'
import Aura2 from 'pages/AuraSight/components/AtleticoMineiro/Aura/Aura'

export const verifyIfPrivateRoute = (pathname, loggedUser) => routes(loggedUser).find((route) => route.path === pathname && route.isPrivate)

const routes = (user) => {
	let thisRoutes = [
		//Public
		// { path: '/', component: HOME!!!, exact: true, isPrivate: false }, //sem home no momento
		{ path: '/login', label: 'Login', component: Login, exact: true, isPrivate: false, cannotAccessAfterLogin: true },
		{ path: '/register', label: 'Registrar', component: Register, exact: true, isPrivate: false, cannotAccessAfterLogin: true },
		{ path: '/validate-email/:token', label: 'Validar Email', component: ValidateEmail, exact: true, isPrivate: false },
		{ path: '/reset-password/:token', label: 'Resetar senha', component: ResetPassword, exact: true, isPrivate: false },
		//Private
		{ path: '/products', label: 'Financeiro', component: Products, exact: true, isPrivate: true, mustHaveValidEmail: true },
		{ path: '/social-profile', label: 'Perfil Social', component: SocialProfile, exact: true, isPrivate: true, mustHaveValidEmail: true },
		{ path: '/financial', label: 'Financeiro', component: Financial, exact: true, isPrivate: true, mustHaveValidEmail: true },
		{ path: '/monitoring', label: 'Monitoramento', component: Monitoring, exact: true, isPrivate: true, mustHaveValidEmail: true },
		{
			path: '/monitoring/sentiment-analysis',
			label: 'Análise',
			component: SentimentAnalysis,
			exact: true,
			isPrivate: true,
			mustHaveValidEmail: true
		},
		{
			path: '/monitoring/social-profile',
			label: 'Contas',
			component: SocialProfile,
			exact: true,
			isPrivate: true,
			mustHaveValidEmail: true
		},
		{
			path: '/monitoring/accounts-group',
			label: 'Grupo',
			component: AccountsGroup,
			exact: true,
			isPrivate: true,
			mustHaveValidEmail: true
		},
		{
			path: '/report/:type/:cards_ids/:from_date/:to_date/:from_hour/:to_hour',
			label: 'Relatório',
			component: Report,
			exact: true,
			isPrivate: false,
			mustHaveValidEmail: true
		},
		{
			path: '/account-management',
			label: 'Gerenciamento de contas',
			component: AccountManagement,
			exact: true,
			isPrivate: true,
			mustHaveValidEmail: true
		},
		{
			path: '/image-annotation',
			label: 'Ferramentas Internas',
			component: ImageAnnotation,
			exact: true,
			isPrivate: true,
			mustHaveValidEmail: true
		},
		{
			path: '/account-management/social-profile',
			label: 'Perfil Social',
			component: SocialProfileAccountManagement,
			exact: true,
			isPrivate: true,
			mustHaveValidEmail: true
		},
		{ path: '/profile', label: 'Meu Perfil', component: Profile, exact: true, isPrivate: true, mustHaveValidEmail: false },
		{ path: '/redirect', label: 'Redirecionamento', component: RedirectPage, exact: false, isPrivate: true, mustHaveValidEmail: true },
		{
			path: '/sharing/dashboard/:sharingLinkToken',
			label: 'Dashboard Compartilhável',
			component: SharingDashboard,
			exact: false,
			isPrivate: false,
			mustHaveValidEmail: false
		},
		{
			path: '/sharing/aura-report',
			label: 'Aura',
			component: Aura,
			exact: true,
			isPrivate: false,
			mustHaveValidEmail: false
		},
		{
			path: '/sharing/aura-report-2',
			label: 'Aura',
			component: Aura2,
			exact: true,
			isPrivate: false,
			mustHaveValidEmail: false
		}
		// { component: Erro, exact: true, isPrivate: false }
	]

	if (!user?.subUser) {
		thisRoutes.push({ path: '/sub-users', label: 'Sub Users', component: SubUser, exact: true, isPrivate: true, mustHaveValidEmail: true })
	}

	return thisRoutes
}

//Poe a primeira rota que o sistema abre no redux somente se for /redirect, caso o usuario esteja deslogado, após logar irá pegar desse estado e redirecionar, na tela redirect o estado é nulificado
if (window.location.pathname.includes('/redirect')) {
	store.dispatch(changeRedirectUrlAction(`${window.location.pathname + window.location.search}`))
}

const RedirectTo = ({ user, redirectUrl }) => {
	let redirection = <Redirect to='/login' />

	if (user) {
		if (!user?.isValidEmail) {
			redirection = <Redirect to='/profile' />
		} else if (redirectUrl) {
			redirection = <Redirect to={redirectUrl} />
		} else {
			// redirection = <Redirect to='/monitoring' />
			let productQuantity = [user.hasSocial, user.hasFinancial, user.hasAccountManager].filter((hasProduct) => hasProduct).length
			if (productQuantity > 1) {
				redirection = <Redirect to='/products' />
			} else if (user.hasSocial) {
				redirection = <Redirect to='/monitoring' />
			} else if (user.hasFinancial) {
				redirection = <Redirect to='/financial' />
			} else if (user.hasAccountManager) {
				redirection = <Redirect to='/account-management' />
			}
		}
	}

	return redirection
}

const Routes = ({ user, redirectUrl }) => (
	<Switch>
		{routes(user).map((route) => {
			if (!user && route.path === '/') {
				// quando existir uma home de verdade no '/', pode tirar esse if
				return <Redirect to='/login' key={Math.random()} />
			}
			if (user && route.cannotAccessAfterLogin) {
				// quando existir uma home de verdade no '/', pode tirar esse if
				return null
			}
			if (route.isPrivate) {
				if (route.mustHaveValidEmail) {
					return (
						user?.isValidEmail && (
							<PrivateRoute
								key={route.path + route.label}
								path={route.path}
								exact={route.exact}
								component={route.component}
								loggedUser={user}
								label={route.label}
							/>
						)
					)
				} else {
					return (
						<PrivateRoute
							key={route.path + route.label}
							path={route.path}
							exact={route.exact}
							component={route.component}
							loggedUser={user}
							label={route.label}
						/>
					)
				}
			} else {
				return <Route key={route.path + route.label} path={route.path} exact={route.exact} component={route.component} label={route.label} />
			}
		})}
		<RedirectTo user={user} redirectUrl={redirectUrl} />
	</Switch>
)

const mapStateToProps = ({ store: { user, global } }) => ({
	user,
	redirectUrl: global.redirectUrl
})

export default connect(mapStateToProps, null)(Routes)
