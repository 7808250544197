import React, { memo, useRef } from 'react'
import { connect } from 'react-redux'
import { Grid, Card, IconButton } from '@mui/material'
import constantsVox from 'constants-vox'
import { ListIcon, CloseIcon, MoveResizeIcon } from 'utils/SystemIcons'
import Occurrences from 'components/Occurrences/Occurrences'
import GraphTitle from 'components/GraphTitle/GraphTitle'
import LoadingBar from 'components/LoadingBar/LoadingBar'

const PostsFeedDashboardShareable = ({ title, cards, globalFiltersRedux, editMode, addWidgetMode, removeWidget, keyName }) => {
	const loadingBarRef = useRef()

	return (
		<>
			{addWidgetMode && (
				<div style={{ position: 'absolute', right: '1px' }}>
					<IconButton onClick={() => removeWidget(keyName)}>
						<CloseIcon color={'#1d8cf8'} />
					</IconButton>
				</div>
			)}
			{editMode && (
				<div
					style={{
						borderRadius: '20px',
						position: 'absolute',
						top: 47,
						left: 3,
						right: 3,
						bottom: 8,
						backdropFilter: 'blur(5px)',
						backgroundColor: 'rgba(250, 250, 250, 0.5)',
						zIndex: 1000,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center'
					}}
				>
					<MoveResizeIcon size={60} style={{ marginBottom: '3em', color: '#1d8cf8' }} />
				</div>
			)}
			<Card
				style={{
					height: '100%',
					width: '100%',
					borderRadius: '20px',
					background: 'transparent',
					pointerEvents: editMode ? 'none' : '',
					userSelect: editMode ? 'none' : ''
				}}
				className={editMode ? 'editBox' : ''}
			>
				<Grid container spacing={3} style={{ background: '#fbfbfb', height: '70px', borderBottom: '1px solid #f5f5f5' }}>
					<Grid item xs={12}>
						<GraphTitle id='span-sentiments-nowcasting' title={title} icon={<ListIcon size={25} style={{ color: '#1d8cf8' }} />} />
					</Grid>
				</Grid>
				<div>
					<LoadingBar ref={loadingBarRef} />
				</div>
				<Occurrences
					cards={cards}
					isDialog={true}
					externalFeed={null}
					filters={globalFiltersRedux}
					type={constantsVox.CARD_TYPE.ACCOUNT_MANAGEMENT}
					isTopPosts={false}
					pageSize={10}
					orderExternal={{ date: null, relevance: 'desc' }}
					isWidget={true}
				/>
			</Card>
		</>
	)
}

const mapStateToProps = ({ store }) => ({
	globalFiltersRedux: store.global.filters
})

export default connect(mapStateToProps, null)(memo(PostsFeedDashboardShareable))
