import React, { memo, useRef } from 'react'
import { Button, Tooltip, CircularProgress } from '@mui/material'
import { TwitterIcon, BlueskyIcon, FacebookIcon, InstagramIcon, TiktokIcon, YoutubeIcon, NewsIcon, MetaAdsIcon } from 'utils/SystemIcons'

const iconSize = 17

const grey = '#b8b8b8'

const defineStatusColor = (status) => {
	switch (status) {
		case 'S':
			return '#1d8cf8'
		case 'P':
			return '#9A9A9A'
		case 'W':
			return '#ffa200'
		case 'E':
			return '#9A9A9A'
		case 'C':
			return '#1d8cf8'
		case 'A':
			return '#1d8cf8'
		default:
			return '#9A9A9A'
	}
}

const ALL_SOURCES_DATA = [
	{ sourceName: 'twitter', SourceIcon: TwitterIcon },
	{ sourceName: 'bluesky', SourceIcon: BlueskyIcon },
	{ sourceName: 'facebook', SourceIcon: FacebookIcon },
	{ sourceName: 'instagram', SourceIcon: InstagramIcon },
	{ sourceName: 'tiktok', SourceIcon: TiktokIcon },
	{ sourceName: 'youtube', SourceIcon: YoutubeIcon },
	{ sourceName: 'news', SourceIcon: NewsIcon },
	{ sourceName: 'metaAds', SourceIcon: MetaAdsIcon }
]

const defineTooltip = ({ sourceName, sourcesStatus, cardType }) => {
	let status
	switch (sourcesStatus[sourceName]) {
		case 'S':
			return ''
		case 'P':
			status = 'Processando'
			break
		case 'E':
			status = 'Não foram encontrados dados'
			break
		case 'W':
			status = cardType !== 'profile' ? 'Bloqueio encontrado' : 'Erro na conta (Verifique a conta)'
			break
		case 'C':
			status = 'Processando'
			break
		case 'A':
			status = 'Processando'
			break
		default:
			status = 'Desativada'
			break
	}

	const formattedSourceName = (sourceName.charAt(0).toUpperCase() + sourceName.slice(1)).replace(/([A-Z])/g, ' $1').trim()

	return `Fonte ${formattedSourceName}: ${status}`
}

const checkInactiveColor = (sourceStatus) => {
	if (sourceStatus !== null) {
		return grey
	} else {
		return 'rgba(184, 184, 184, 40%)'
	}
}

const verifyButtonContent = ({ SourceIcon, sourceName, selectedSources, sourcesStatus }) => {
	const color = selectedSources.includes(sourceName) ? defineStatusColor(sourcesStatus[sourceName]) : checkInactiveColor(sourcesStatus[sourceName])
	let SourceOrLoadingIcon

	switch (sourcesStatus[sourceName]) {
		case 'S':
			SourceOrLoadingIcon = <SourceIcon size={iconSize} color={color} />
			break
		case 'P':
			SourceOrLoadingIcon = (
				<>
					<div style={{ position: 'absolute', transform: 'translate(-0.5px, 4px)' }}>
						<CircularProgress thickness={1.2} style={{ color: color, width: '30px', height: '30px' }} />
					</div>
					<SourceIcon size={iconSize} color={color} />
				</>
			)
			break
		case 'E':
			SourceOrLoadingIcon = <SourceIcon size={iconSize} color={color} />
			break
		case 'W':
			SourceOrLoadingIcon = <SourceIcon size={iconSize} color={color} />
			break
		case 'C':
			SourceOrLoadingIcon = (
				<>
					<div style={{ position: 'absolute', transform: 'translate(-0.5px, 4px)' }}>
						<CircularProgress thickness={1.2} style={{ color: color, width: '30px', height: '30px' }} />
					</div>
					<SourceIcon size={iconSize} color={color} />
				</>
			)
			break
		case 'A':
			SourceOrLoadingIcon = (
				<>
					<div style={{ position: 'absolute', transform: 'translate(-0.5px, 4px)' }}>
						<CircularProgress thickness={1.2} style={{ color: color, width: '30px', height: '30px' }} />
					</div>
					<SourceIcon size={iconSize} color={color} />
				</>
			)
			break
		default:
			SourceOrLoadingIcon = <SourceIcon size={iconSize} color={color} />
			break
	}

	return SourceOrLoadingIcon
}

const IconSourceButtonGroup = ({ sourcesStatus, selectedSources, cardType, onChange, singleSourceSelection }) => {
	const isDoubleClick = useRef(false)
	const timer = useRef(null)

	const handleClick = (sourceName) => {
		if (sourcesStatus[sourceName] !== 'S') {
			return
		}

		if (timer.current) {
			clearTimeout(timer.current)
			timer.current = null
			isDoubleClick.current = true

			if (selectedSources.includes(sourceName) && !singleSourceSelection) {
				if (selectedSources.length > 1) {
					onChange([sourceName])
				} else {
					onChange(
						ALL_SOURCES_DATA.filter(
							(source) =>
								source.sourceName !== 'metaAds' && source.sourceName !== sourceName && sourcesStatus[source.sourceName] === 'S'
						).map((source) => source.sourceName)
					)
				}
			}

			setTimeout(() => {
				isDoubleClick.current = false
			}, 50)

			return
		}

		if (!timer.current) {
			isDoubleClick.current = false
		}

		timer.current = setTimeout(() => {
			if (!isDoubleClick.current) {
				if (singleSourceSelection) {
					onChange([sourceName])
				} else {
					const newSelected = selectedSources.includes(sourceName)
						? selectedSources.filter((s) => s !== sourceName)
						: [...selectedSources, sourceName]

					if (newSelected.length > 0) {
						onChange(newSelected)
					}
				}
			}

			timer.current = null
		}, 200)
	}

	return (
		<div style={{ display: 'flex', height: '3em', alignItems: 'center' }}>
			{ALL_SOURCES_DATA.filter((sd) => sourcesStatus[sd.sourceName]).map(({ sourceName, SourceIcon }) => (
				<div
					key={sourceName}
					id={`${sourceName}-source-selector-item-container`}
					style={{
						marginTop: '0.3em',
						textAlign: 'center',
						marginRight: '0.3em'
					}}
				>
					<Tooltip title={defineTooltip({ sourceName, sourcesStatus, cardType })} placement='bottom' enterDelay={100} enterNextDelay={100}>
						<Button
							id={`${sourceName}-source-selector-item-btn`}
							disableRipple={sourcesStatus[sourceName] !== 'S'}
							onClick={() => handleClick(sourceName)}
							style={{
								borderRadius: '0px',
								marginTop: '-1px',
								width: '3.2em',
								minWidth: '3.2em',
								maxWidth: '3.2em',
								height: '3.2em',
								borderBottom: selectedSources.includes(sourceName) && sourcesStatus[sourceName] === 'S' ? '2px solid #1d8cf8' : ''
							}}
						>
							{verifyButtonContent({
								SourceIcon,
								sourceName,
								selectedSources,
								sourcesStatus
							})}
						</Button>
					</Tooltip>
				</div>
			))}
		</div>
	)
}

export default memo(IconSourceButtonGroup)
