import React, { useState } from 'react'
import api from 'service/service'
import { DeleteBinIcon } from 'utils/SystemIcons'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import { verifyServerResponseCanShowToast } from 'utils/generalFunctions'
import DialogConfirmation from 'components/DialogConfirmation/DialogConfirmation'

const RemoveTopicDialog = ({ topic, openDeleteDialog, toggleDeleteTopic, update, cardId }) => {
	const [submitting, setSubmitting] = useState(false)

	const removeTopic = (event) => {
		event.preventDefault()

		setSubmitting(true)
		api.post('/topic-analysis/remove-topic', {
			topicId: topic.topic_modeling_ia_id,
			cardId
		})
			.then((response) => {
				if (response?.data?.toast) {
					sendMessageToast(response?.data?.toast?.message, response?.data?.toast?.type)
				}
			})
			.catch((error) => {
				if (verifyServerResponseCanShowToast(error)) {
					sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
				}
			})
			.finally(() => {
				setSubmitting(false)
				toggleDeleteTopic()
				update()
			})
	}

	return (
		<DialogConfirmation
			open={openDeleteDialog}
			handleClose={toggleDeleteTopic}
			handleConfirm={removeTopic}
			color={'#ff2b6b'}
			title={'Deseja realmente deletar: '}
			subTitle={topic.title}
			buttonText={'DELETAR'}
			icon={<DeleteBinIcon className='fix-icon-margin-bottom-negative' size={25} color='#ff2b6b' />}
		/>
	)
}

export default RemoveTopicDialog
