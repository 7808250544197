import React from 'react'
import PotentialReachWidget from 'components/PotentialReachWidget/PotentialReachWidget'
import MediaCountWidget from 'components/MediaCountWidget/MediaCountWidget'

const NowcastingExtraWidgetContainer = ({ PotentialReachRef, MediaCountRef, filters, cards }) => {
	return (
		<div
			style={{
				position: 'absolute',
				marginTop: '70px',
				justifyContent: 'center',
				// marginBottom: '10px',
				marginLeft: '1.5vw',
				display: 'flex'
			}}
		>
			<PotentialReachWidget ref={PotentialReachRef} filters={filters} cards={cards} />
			<MediaCountWidget ref={MediaCountRef} filters={filters} cards={cards} />
		</div>
	)
}

export default NowcastingExtraWidgetContainer
