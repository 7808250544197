import React, { memo, useState, useEffect } from 'react'
import GraficoNowcasting from 'graphs/GraficoNowcasting/GraficoNowcasting'
import GraficoSensibilidade from 'graphs/GraficoSensibilidade/GraficoSensibilidade'
import GenderAgeDistribution from 'graphs/GenderAgeDistribution/GenderAgeDistribution'
import TopComments from '../TopComments/TopComments'
import WordClouds from '../../../components/WordClouds/WordClouds'
import { Grid } from '@mui/material'

const SentimentAnalysisContent = ({ cardStatus, cards, isFetchingData }) => {
	const [cardList, setCardList] = useState(cards)
	const canShowTwitter = () => cardStatus.twitter === 'S'
	const canShowBluesky = () => cardStatus.bluesky === 'S'
	const canShowFacebook = () => cardStatus.facebook === 'S'
	const canShowInstagram = () => cardStatus.instagram === 'S'
	const canShowTiktok = () => cardStatus.tiktok === 'S'
	const canShowYoutube = () => cardStatus.youtube === 'S'
	const canShowNews = () => cardStatus.news === 'S'

	useEffect(() => {
		setCardList(cards)
	}, [cards])

	return (
		<Grid container flexDirection='row' spacing={3}>
			{(canShowTwitter() ||
				canShowBluesky() ||
				canShowFacebook() ||
				canShowInstagram() ||
				canShowTiktok() ||
				canShowYoutube() ||
				canShowNews()) && (
				<>
					<Grid item xs={12}>
						<GraficoNowcasting cards={cardList} isFetchingData={isFetchingData} />
					</Grid>
					<Grid item xs={6}>
						<TopComments cards={cardList} />
					</Grid>
					<Grid item xs={6}>
						<WordClouds cards={cardList} isDialog={false} />
					</Grid>
				</>
			)}
			{(canShowTwitter() || canShowBluesky() || canShowFacebook() || canShowInstagram() || canShowTiktok() || canShowYoutube()) && (
				<>
					<Grid item xs={12}>
						<GenderAgeDistribution cards={cardList} isFetchingData={isFetchingData} />
					</Grid>
					<Grid item xs={12}>
						<GraficoSensibilidade cards={cardList} isFetchingData={isFetchingData} />
					</Grid>
				</>
			)}
		</Grid>
	)
}

export default memo(SentimentAnalysisContent)
