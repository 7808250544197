import React from 'react'
import NumericMetric from '../../../../components/metrics/accountManagerOldMetrics/NumericMetric/NumericMetric'
import BarMetrics from '../../../../components/metrics/accountManagerOldMetrics/BarMetrics/BarMetrics'
import GenderAgeDistributionAccountManagement from '../../../../components/metrics/accountManagerOldMetrics/GenderAgeDistributionAccountManagement/GenderAgeDistributionAccountManagement'
import TopCommentsDashboardShareable from '../../../../components/metrics/accountManagerOldMetrics/TopCommentsDashboardShareable/TopCommentsDashboardShareable'
import CommentsSentiment from '../../../../components/metrics/accountManagerOldMetrics/CommentsSentiment/CommentsSentiment'
import PostsFeedDashboardShareable from '../../../../components/metrics/accountManagerOldMetrics/PostsFeedDashboardShareable/PostsFeedDashboardShareable'
import WordCloudDashboardShareable from '../../../../components/metrics/accountManagerOldMetrics/WordCloudDashboardShareable/WordCloudDashboardShareable'
export { getComponentsToRenderFacebookAccountManager, DEFAULT_LAYOUTS_FACEBOOK_ACCOUNT_MANAGER, DEFAULT_WIDGETS_FACEBOOK_ACCOUNT_MANAGER }

const getComponentsToRenderFacebookAccountManager = (facebookMetrics, editMode, addWidgetMode, removeWidget, cards, descriptions) => [
	{
		Component: (
			<NumericMetric
				data={facebookMetrics?.analytics?.followers_count}
				title={descriptions.find((item) => item.key === 'facebook_followers_count')?.title}
				keyName={'facebook_followers_count'}
				graphData={facebookMetrics?.apiSeries?.graphs?.followersFormatted}
				source='facebook'
				isOverview={false}
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'facebook_followers_count')?.description}
			/>
		),
		key: 'facebook_followers_count'
	},
	{
		Component: (
			<NumericMetric
				data={facebookMetrics?.analytics?.fan_count}
				title={descriptions.find((item) => item.key === 'facebook_fan_count')?.title}
				keyName={'facebook_fan_count'}
				graphData={facebookMetrics?.apiSeries?.graphs?.fanFormatted}
				source='facebook'
				isOverview={true}
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'facebook_fan_count')?.description}
			/>
		),
		key: 'facebook_fan_count'
	},
	{
		Component: (
			<GenderAgeDistributionAccountManagement
				title={descriptions.find((item) => item.key === 'facebook_followers_by_gender_and_age')?.title}
				keyName={'facebook_followers_by_gender_and_age'}
				data={facebookMetrics?.apiSeries?.graphs?.genderAgeFormatted}
				source='facebook'
				graphData={facebookMetrics?.apiSeries?.graphs?.genderAgeTimelineFormatted}
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'facebook_followers_by_gender_and_age')?.description}
			/>
		),
		key: 'facebook_followers_by_gender_and_age'
	},
	{
		Component: (
			<BarMetrics
				isHorizontal={false}
				values={facebookMetrics?.apiSeries?.topMetrics?.cityFans?.values}
				categories={facebookMetrics?.apiSeries?.topMetrics?.cityFans?.locations}
				title={descriptions.find((item) => item.key === 'facebook_page_fans_city')?.title}
				keyName='facebook_page_fans_city'
				label='Cidades'
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'facebook_page_fans_city')?.description}
			/>
		),
		key: 'facebook_page_fans_city'
	},
	{
		Component: (
			<BarMetrics
				isHorizontal={false}
				values={facebookMetrics?.apiSeries?.topMetrics?.fansCountry?.values}
				categories={facebookMetrics?.apiSeries?.topMetrics?.fansCountry?.locations}
				title={descriptions.find((item) => item.key === 'facebook_page_fans_country')?.title}
				keyName='facebook_page_fans_country'
				label='Paises'
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'facebook_page_fans_country')?.description}
			/>
		),
		key: 'facebook_page_fans_country'
	},
	{
		Component: (
			<BarMetrics
				isHorizontal={false}
				values={facebookMetrics?.apiSeries?.topMetrics?.fansLocale?.values}
				categories={facebookMetrics?.apiSeries?.topMetrics?.fansLocale?.locations}
				title={descriptions.find((item) => item.key === 'facebook_page_fans_locale')?.title}
				keyName='facebook_page_fans_locale'
				label='Idiomas'
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'facebook_page_fans_locale')?.description}
			/>
		),
		key: 'facebook_page_fans_locale'
	},
	{
		Component: (
			<NumericMetric
				data={facebookMetrics?.apiSeries?.graphsTotalMetrics?.engagedUsersFormatedTotal}
				title={descriptions.find((item) => item.key === 'facebook_page_engaged_users')?.title}
				keyName='facebook_page_engaged_users'
				graphData={facebookMetrics?.apiSeries?.graphs?.engagedUsersFormated}
				source='facebook'
				isOverview={true}
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'facebook_page_engaged_users')?.description}
			/>
		),
		key: 'facebook_page_engaged_users'
	},
	{
		Component: (
			<NumericMetric
				data={facebookMetrics?.apiSeries?.graphsTotalMetrics?.pagePostsImpressionsUniqueFormattedTotal}
				title={descriptions.find((item) => item.key === 'facebook_page_impressions_unique')?.title}
				keyName='facebook_page_impressions_unique'
				graphData={facebookMetrics?.apiSeries?.graphs?.pagePostsImpressionsUniqueFormatted}
				source='facebook'
				isOverview={true}
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'facebook_page_impressions_unique')?.description}
			/>
		),
		key: 'facebook_page_impressions_unique'
	},
	{
		Component: (
			<NumericMetric
				data={facebookMetrics?.apiSeries?.graphsTotalMetrics?.pageVideoViewTimeFormattedTotal}
				title={descriptions.find((item) => item.key === 'facebook_page_video_view_time')?.title}
				keyName='facebook_page_video_view_time'
				graphData={facebookMetrics?.apiSeries?.graphs?.pageVideoViewTimeFormatted}
				source='facebook'
				isOverview={true}
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'facebook_page_video_view_time')?.description}
			/>
		),
		key: 'facebook_page_video_view_time'
	},
	{
		Component: (
			<NumericMetric
				data={facebookMetrics?.apiSeries?.graphsTotalMetrics?.pageFanAddsFormattedTotal}
				title={descriptions.find((item) => item.key === 'facebook_page_fan_adds')?.title}
				keyName='facebook_page_fan_adds'
				graphData={facebookMetrics?.apiSeries?.graphs?.pageFanAddsFormatted}
				source='facebook'
				isOverview={true}
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'facebook_page_fan_adds')?.description}
			/>
		),
		key: 'facebook_page_fan_adds'
	},

	{
		Component: (
			<NumericMetric
				data={facebookMetrics?.apiSeries?.graphsTotalMetrics?.pageNewUniqueFollowsFormattedTotal}
				title={descriptions.find((item) => item.key === 'facebook_new_unique_follows')?.title}
				keyName='facebook_new_unique_follows'
				graphData={facebookMetrics?.apiSeries?.graphs?.pageNewUniqueFollowsFormatted}
				source='facebook'
				isOverview={true}
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'facebook_new_unique_follows')?.description}
			/>
		),
		key: 'facebook_new_unique_follows'
	},
	{
		Component: (
			<NumericMetric
				data={facebookMetrics?.apiSeries?.graphsTotalMetrics?.pageNewUniqueUnfollowsFormattedTotal}
				title={descriptions.find((item) => item.key === 'facebook_new_unique_unfollows')?.title}
				keyName='facebook_new_unique_unfollows'
				graphData={facebookMetrics?.apiSeries?.graphs?.pageNewUniqueUnfollowsFormatted}
				source='facebook'
				isOverview={true}
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'facebook_new_unique_unfollows')?.description}
			/>
		),
		key: 'facebook_new_unique_unfollows'
	},
	{
		Component: (
			<NumericMetric
				data={facebookMetrics?.apiSeries?.graphsTotalMetrics?.fanRemovesFormattedTotal}
				title={descriptions.find((item) => item.key === 'facebook_page_fan_removes')?.title}
				keyName='facebook_page_fan_removes'
				graphData={facebookMetrics?.apiSeries?.graphs?.fanRemovesFormatted}
				source='facebook'
				isOverview={true}
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'facebook_page_fan_removes')?.description}
			/>
		),
		key: 'facebook_page_fan_removes'
	},
	{
		Component: (
			<BarMetrics
				isHorizontal={false}
				categories={facebookMetrics?.apiSeries?.graphsTotalMetrics?.impressionsFormattedTotal?.keys}
				values={facebookMetrics?.apiSeries?.graphsTotalMetrics?.impressionsFormattedTotal?.values}
				title={descriptions.find((item) => item.key === 'facebook_page_impressions')?.title}
				keyName='facebook_page_impressions'
				label='Quantidade'
				graphData={facebookMetrics?.apiSeries?.graphs?.impressionsFormatted}
				source='facebook'
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'facebook_page_impressions')?.description}
			/>
		),
		key: 'facebook_page_impressions'
	},
	{
		Component: (
			<BarMetrics
				isHorizontal={false}
				categories={facebookMetrics?.apiSeries?.graphsTotalMetrics?.viewsFormattedTotal?.keys}
				values={facebookMetrics?.apiSeries?.graphsTotalMetrics?.viewsFormattedTotal?.values}
				title={descriptions.find((item) => item.key === 'facebook_page_views_total')?.title}
				keyName='facebook_page_views_total'
				label='Quantidade'
				graphData={facebookMetrics?.apiSeries?.graphs?.viewsFormatted}
				source='facebook'
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'facebook_page_views_total')?.description}
			/>
		),
		key: 'facebook_page_views_total'
	},
	{
		Component: (
			<BarMetrics
				isHorizontal={false}
				categories={facebookMetrics?.apiSeries?.graphsTotalMetrics?.postsFormattedTotal?.keys}
				values={facebookMetrics?.apiSeries?.graphsTotalMetrics?.postsFormattedTotal?.values}
				title={descriptions.find((item) => item.key === 'facebook_posts_metrics')?.title}
				keyName='facebook_posts_metrics'
				label='Quantidade'
				graphData={facebookMetrics?.apiSeries?.graphs?.postsFormatted}
				source='facebook'
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'facebook_posts_metrics')?.description}
			/>
		),
		key: 'facebook_posts_metrics'
	},
	{
		Component: (
			<BarMetrics
				isHorizontal={false}
				categories={facebookMetrics?.apiSeries?.graphsTotalMetrics?.reactionsFormattedTotal?.keys}
				values={facebookMetrics?.apiSeries?.graphsTotalMetrics?.reactionsFormattedTotal?.values}
				title={descriptions.find((item) => item.key === 'facebook_reactions')?.title}
				keyName='facebook_reactions'
				label='Quantidade'
				graphData={facebookMetrics?.apiSeries?.graphs?.reactionsFormatted}
				source='facebook'
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'facebook_reactions')?.description}
			/>
		),
		key: 'facebook_reactions'
	},
	{
		Component: (
			<BarMetrics
				isHorizontal={false}
				categories={facebookMetrics?.apiSeries?.graphsTotalMetrics?.pageVideoViewsFormattedTotal?.keys}
				values={facebookMetrics?.apiSeries?.graphsTotalMetrics?.pageVideoViewsFormattedTotal?.values}
				title={descriptions.find((item) => item.key === 'facebook_video_views_metrics')?.title}
				keyName='facebook_video_views_metrics'
				label='Quantidade'
				graphData={facebookMetrics?.apiSeries?.graphs?.pageVideoViewsFormatted}
				source='facebook'
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'facebook_video_views_metrics')?.description}
			/>
		),
		key: 'facebook_video_views_metrics'
	},

	{
		Component: (
			<BarMetrics
				isHorizontal={false}
				categories={facebookMetrics?.apiSeries?.graphsTotalMetrics?.pageConsumptionsFormattedTotal?.keys}
				values={facebookMetrics?.apiSeries?.graphsTotalMetrics?.pageConsumptionsFormattedTotal?.values}
				title={descriptions.find((item) => item.key === 'facebook_page_consumptions_by_consumptions_type')?.title}
				keyName='facebook_page_consumptions_by_consumptions_type'
				label='Quantidade'
				graphData={facebookMetrics?.apiSeries?.graphs?.pageConsumptionsFormatted}
				source='facebook'
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'facebook_page_consumptions_by_consumptions_type')?.description}
			/>
		),
		key: 'facebook_page_consumptions_by_consumptions_type'
	},
	{
		Component: (
			<BarMetrics
				isHorizontal={false}
				categories={facebookMetrics?.apiSeries?.graphsTotalMetrics?.pageFansByLikeSourceUniqueFormattedTotal?.keys}
				values={facebookMetrics?.apiSeries?.graphsTotalMetrics?.pageFansByLikeSourceUniqueFormattedTotal?.values}
				title={descriptions.find((item) => item.key === 'facebook_page_fans_by_like_source_unique')?.title}
				keyName='facebook_page_fans_by_like_source_unique'
				label='Quantidade'
				graphData={facebookMetrics?.apiSeries?.graphs?.pageFansByLikeSourceUniqueFormatted}
				source='facebook'
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'facebook_page_fans_by_like_source_unique')?.description}
			/>
		),
		key: 'facebook_page_fans_by_like_source_unique'
	},
	{
		Component: (
			<BarMetrics
				isHorizontal={false}
				categories={facebookMetrics?.apiSeries?.graphsTotalMetrics?.pagePositiveFeedBackByTypeFormattedTotal?.keys}
				values={facebookMetrics?.apiSeries?.graphsTotalMetrics?.pagePositiveFeedBackByTypeFormattedTotal?.values}
				title={descriptions.find((item) => item.key === 'facebook_page_positive_feedback_by_type')?.title}
				keyName='facebook_page_positive_feedback_by_type'
				label='Quantidade'
				graphData={facebookMetrics?.apiSeries?.graphs?.pagePositiveFeedBackByTypeFormatted}
				source='facebook'
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'facebook_page_positive_feedback_by_type')?.description}
			/>
		),
		key: 'facebook_page_positive_feedback_by_type'
	},
	{
		Component: (
			<BarMetrics
				isHorizontal={false}
				categories={facebookMetrics?.apiSeries?.graphsTotalMetrics?.pageNegativeFeedBackByTypeFormattedTotal?.keys}
				values={facebookMetrics?.apiSeries?.graphsTotalMetrics?.pageNegativeFeedBackByTypeFormattedTotal?.values}
				title={descriptions.find((item) => item.key === 'facebook_page_negative_feedback_by_type')?.title}
				keyName='facebook_page_negative_feedback_by_type'
				label='Quantidade'
				graphData={facebookMetrics?.apiSeries?.graphs?.pageNegativeFeedBackByTypeFormatted}
				source='facebook'
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'facebook_page_negative_feedback_by_type')?.description}
			/>
		),
		key: 'facebook_page_negative_feedback_by_type'
	},
	{
		Component: (
			<BarMetrics
				isHorizontal={false}
				categories={facebookMetrics?.apiSeries?.graphsTotalMetrics?.pageContentActivityByAgeGenderUniqueFormattedTotal?.keys}
				values={facebookMetrics?.apiSeries?.graphsTotalMetrics?.pageContentActivityByAgeGenderUniqueFormattedTotal?.values}
				title={descriptions.find((item) => item.key === 'facebook_page_content_activity_by_age_gender_unique')?.title}
				keyName='facebook_page_content_activity_by_age_gender_unique'
				label='Quantidade'
				graphData={facebookMetrics?.apiSeries?.graphs?.pageContentActivityByAgeGenderUniqueFormatted}
				source='facebook'
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'facebook_page_content_activity_by_age_gender_unique')?.description}
			/>
		),
		key: 'facebook_page_content_activity_by_age_gender_unique'
	},
	{
		Component: (
			<BarMetrics
				isHorizontal={false}
				categories={facebookMetrics?.apiSeries?.graphsTotalMetrics?.pageContentActivityByCountryUniqueFormattedTotal?.keys}
				values={facebookMetrics?.apiSeries?.graphsTotalMetrics?.pageContentActivityByCountryUniqueFormattedTotal?.values}
				title={descriptions.find((item) => item.key === 'facebook_page_content_activity_by_country_unique')?.title}
				keyName='facebook_page_content_activity_by_country_unique'
				label='Quantidade'
				graphData={facebookMetrics?.apiSeries?.graphs?.pageContentActivityByCountryUniqueFormatted}
				source='facebook'
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'facebook_page_content_activity_by_country_unique')?.description}
			/>
		),
		key: 'facebook_page_content_activity_by_country_unique'
	},
	{
		Component: (
			<BarMetrics
				isHorizontal={false}
				categories={facebookMetrics?.apiSeries?.graphsTotalMetrics?.pageContentActivityByCityUniqueFormattedTotal?.keys}
				values={facebookMetrics?.apiSeries?.graphsTotalMetrics?.pageContentActivityByCityUniqueFormattedTotal?.values}
				title={descriptions.find((item) => item.key === 'facebook_page_content_activity_by_city_unique')?.title}
				keyName='facebook_page_content_activity_by_city_unique'
				label='Quantidade'
				graphData={facebookMetrics?.apiSeries?.graphs?.pageContentActivityByCityUniqueFormatted}
				source='facebook'
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'facebook_page_content_activity_by_city_unique')?.description}
			/>
		),
		key: 'facebook_page_content_activity_by_city_unique'
	},
	{
		Component: (
			<BarMetrics
				isHorizontal={false}
				categories={facebookMetrics?.apiSeries?.graphsTotalMetrics?.pageFansByUnlikeSourceUniqueFormattedTotal?.keys}
				values={facebookMetrics?.apiSeries?.graphsTotalMetrics?.pageFansByUnlikeSourceUniqueFormattedTotal?.values}
				title={descriptions.find((item) => item.key === 'facebook_page_fans_by_unlike_source_unique')?.title}
				keyName='facebook_page_fans_by_unlike_source_unique'
				label='Quantidade'
				graphData={facebookMetrics?.apiSeries?.graphs?.pageFansByUnlikeSourceUniqueFormatted}
				source='facebook'
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'facebook_page_fans_by_unlike_source_unique')?.description}
			/>
		),
		key: 'facebook_page_fans_by_unlike_source_unique'
	},
	{
		Component: (
			<BarMetrics
				isHorizontal={false}
				categories={facebookMetrics?.apiSeries?.graphsTotalMetrics?.pageViewsByProfileTabTotalFormattedTotal?.keys}
				values={facebookMetrics?.apiSeries?.graphsTotalMetrics?.pageViewsByProfileTabTotalFormattedTotal?.values}
				title={descriptions.find((item) => item.key === 'facebook_page_views_by_profile_tab_total')?.title}
				keyName='facebook_page_views_by_profile_tab_total'
				label='Quantidade'
				graphData={facebookMetrics?.apiSeries?.graphs?.pageViewsByProfileTabTotalFormatted}
				source='facebook'
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'facebook_page_views_by_profile_tab_total')?.description}
			/>
		),
		key: 'facebook_page_views_by_profile_tab_total'
	},
	{
		Component: (
			<BarMetrics
				isHorizontal={false}
				categories={facebookMetrics?.apiSeries?.graphsTotalMetrics?.activityActionTypeUniqueFormattedTotal?.keys}
				values={facebookMetrics?.apiSeries?.graphsTotalMetrics?.activityActionTypeUniqueFormattedTotal?.values}
				title={descriptions.find((item) => item.key === 'facebook_page_content_activity_by_action_type_unique')?.title}
				keyName='facebook_page_content_activity_by_action_type_unique'
				label='Quantidade'
				graphData={facebookMetrics?.apiSeries?.graphs?.activityActionTypeUniqueFormatted}
				source='facebook'
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'facebook_page_content_activity_by_action_type_unique')?.description}
			/>
		),
		key: 'facebook_page_content_activity_by_action_type_unique'
	},
	{
		Component: (
			<TopCommentsDashboardShareable
				title={descriptions.find((item) => item.key === 'facebook_top_posts')?.title}
				keyName='facebook_top_posts'
				cards={cards}
				source='facebook'
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'facebook_top_posts')?.description}
			/>
		),
		key: 'facebook_top_posts'
	},
	{
		Component: (
			<CommentsSentiment
				sentiment={facebookMetrics?.facebookCommentsAnalytics?.sentiment}
				title={descriptions.find((item) => item.key === 'facebook_sentiments_comments_bar')?.title}
				keyName='facebook_sentiments_comments_bar'
				source='facebook'
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'facebook_sentiments_comments_bar')?.description}
			/>
		),
		key: 'facebook_sentiments_comments_bar'
	},
	{
		Component: (
			<PostsFeedDashboardShareable
				title={descriptions.find((item) => item.key === 'facebook_posts_feed')?.title}
				keyName='facebook_posts_feed'
				cards={cards}
				source='facebook'
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'facebook_posts_feed')?.description}
			/>
		),
		key: 'facebook_posts_feed'
	},
	{
		Component: (
			<WordCloudDashboardShareable
				title={descriptions.find((item) => item.key === 'facebook_word_clouds')?.title}
				keyName='facebook_word_clouds'
				cards={cards}
				source='facebook'
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'facebook_word_clouds')?.description}
			/>
		),
		key: 'facebook_word_clouds'
	}
]

const DEFAULT_LAYOUTS_FACEBOOK_ACCOUNT_MANAGER = {
	lg: [
		{ i: 'facebook_followers_count', x: 0, y: 0, w: 4, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_fan_count', x: 4, y: 0, w: 4, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_page_impressions_unique', x: 8, y: 0, w: 4, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_top_posts', x: 0, y: 4, w: 6, h: 15, minH: 15, maxH: 17, minW: 6, maxW: 12 },
		{ i: 'facebook_sentiments_comments_bar', x: 6, y: 4, w: 6, h: 15, minH: 8, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_video_views_metrics', x: 0, y: 19, w: 4, h: 11, minH: 11, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_posts_metrics', x: 4, y: 19, w: 4, h: 11, minH: 11, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_page_impressions', x: 8, y: 19, w: 4, h: 11, minH: 11, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_page_content_activity_by_action_type_unique', x: 0, y: 30, w: 6, h: 11, minH: 11, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_page_positive_feedback_by_type', x: 0, y: 41, w: 6, h: 12, minH: 11, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_new_unique_follows', x: 0, y: 41, w: 4, h: 6, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_new_unique_unfollows', x: 4, y: 41, w: 4, h: 6, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_page_fan_adds', x: 8, y: 41, w: 4, h: 6, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_page_engaged_users', x: 6, y: 47, w: 6, h: 6, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_followers_by_gender_and_age', x: 0, y: 4, w: 12, h: 17, minH: 17, maxH: 17, minW: 6, maxW: 12 },
		{ i: 'facebook_page_fans_city', x: 0, y: 12, w: 4, h: 8, minH: 8, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_page_fans_country', x: 4, y: 12, w: 4, h: 8, minH: 8, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_page_fans_locale', x: 8, y: 12, w: 4, h: 8, minH: 8, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_page_video_view_time', x: 8, y: 16, w: 4, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_page_fan_removes', x: 9, y: 20, w: 3, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_page_views_total', x: 4, y: 24, w: 4, h: 11, minH: 11, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_reactions', x: 0, y: 36, w: 4, h: 11, minH: 11, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_page_consumptions_by_consumptions_type', x: 8, y: 36, w: 4, h: 11, minH: 11, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_page_fans_by_like_source_unique', x: 0, y: 47, w: 4, h: 11, minH: 11, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_page_negative_feedback_by_type', x: 8, y: 47, w: 4, h: 11, minH: 11, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_page_content_activity_by_age_gender_unique', x: 0, y: 58, w: 4, h: 11, minH: 11, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_page_content_activity_by_country_unique', x: 4, y: 58, w: 4, h: 11, minH: 11, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_page_content_activity_by_city_unique', x: 8, y: 58, w: 4, h: 11, minH: 11, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_page_fans_by_unlike_source_unique', x: 0, y: 69, w: 4, h: 11, minH: 11, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_page_views_by_profile_tab_total', x: 4, y: 69, w: 4, h: 11, minH: 11, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_posts_feed', x: 0, y: 80, w: 6, h: 15, minH: 15, maxH: 17, minW: 6, maxW: 12 },
		{ i: 'facebook_word_clouds', x: 0, y: 95, w: 6, h: 15, minH: 15, maxH: 17, minW: 6, maxW: 12 }
	],
	md: [
		{ i: 'facebook_followers_count', x: 0, y: 0, w: 4, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_fan_count', x: 4, y: 0, w: 4, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_page_impressions_unique', x: 8, y: 0, w: 4, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_top_posts', x: 0, y: 4, w: 6, h: 15, minH: 15, maxH: 17, minW: 6, maxW: 12 },
		{ i: 'facebook_sentiments_comments_bar', x: 6, y: 4, w: 6, h: 15, minH: 8, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_video_views_metrics', x: 0, y: 19, w: 4, h: 11, minH: 11, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_posts_metrics', x: 4, y: 19, w: 4, h: 11, minH: 11, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_page_impressions', x: 8, y: 19, w: 4, h: 11, minH: 11, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_page_content_activity_by_action_type_unique', x: 0, y: 30, w: 6, h: 11, minH: 11, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_page_positive_feedback_by_type', x: 0, y: 41, w: 6, h: 12, minH: 11, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_new_unique_follows', x: 0, y: 41, w: 4, h: 6, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_new_unique_unfollows', x: 4, y: 41, w: 4, h: 6, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_page_fan_adds', x: 8, y: 41, w: 4, h: 6, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_page_engaged_users', x: 6, y: 47, w: 6, h: 6, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_followers_by_gender_and_age', x: 0, y: 4, w: 12, h: 17, minH: 17, maxH: 17, minW: 6, maxW: 12 },
		{ i: 'facebook_page_fans_city', x: 0, y: 12, w: 4, h: 8, minH: 8, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_page_fans_country', x: 4, y: 12, w: 4, h: 8, minH: 8, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_page_fans_locale', x: 8, y: 12, w: 4, h: 8, minH: 8, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_page_video_view_time', x: 8, y: 16, w: 4, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_page_fan_removes', x: 9, y: 20, w: 3, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_page_views_total', x: 4, y: 24, w: 4, h: 11, minH: 11, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_reactions', x: 0, y: 36, w: 4, h: 11, minH: 11, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_page_consumptions_by_consumptions_type', x: 8, y: 36, w: 4, h: 11, minH: 11, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_page_fans_by_like_source_unique', x: 0, y: 47, w: 4, h: 11, minH: 11, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_page_negative_feedback_by_type', x: 8, y: 47, w: 4, h: 11, minH: 11, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_page_content_activity_by_age_gender_unique', x: 0, y: 58, w: 4, h: 11, minH: 11, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_page_content_activity_by_country_unique', x: 4, y: 58, w: 4, h: 11, minH: 11, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_page_content_activity_by_city_unique', x: 8, y: 58, w: 4, h: 11, minH: 11, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_page_fans_by_unlike_source_unique', x: 0, y: 69, w: 4, h: 11, minH: 11, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_page_views_by_profile_tab_total', x: 4, y: 69, w: 4, h: 11, minH: 11, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_posts_feed', x: 0, y: 80, w: 6, h: 15, minH: 15, maxH: 17, minW: 6, maxW: 12 },
		{ i: 'facebook_word_clouds', x: 0, y: 95, w: 6, h: 15, minH: 15, maxH: 17, minW: 6, maxW: 12 }
	],
	sm: [
		{ i: 'facebook_followers_count', x: 0, y: 0, w: 6, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_fan_count', x: 4, y: 0, w: 6, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_page_impressions_unique', x: 8, y: 0, w: 6, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_top_posts', x: 0, y: 4, w: 6, h: 15, minH: 15, maxH: 17, minW: 6, maxW: 12 },
		{ i: 'facebook_sentiments_comments_bar', x: 6, y: 4, w: 6, h: 15, minH: 8, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_video_views_metrics', x: 0, y: 19, w: 6, h: 11, minH: 11, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_posts_metrics', x: 6, y: 19, w: 6, h: 11, minH: 11, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_page_impressions', x: 6, y: 19, w: 6, h: 11, minH: 11, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_page_content_activity_by_action_type_unique', x: 0, y: 30, w: 6, h: 11, minH: 11, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_page_positive_feedback_by_type', x: 0, y: 41, w: 6, h: 12, minH: 11, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_new_unique_follows', x: 6, y: 41, w: 6, h: 6, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_new_unique_unfollows', x: 6, y: 53, w: 6, h: 6, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_page_fan_adds', x: 0, y: 53, w: 6, h: 6, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_page_engaged_users', x: 6, y: 47, w: 6, h: 6, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_followers_by_gender_and_age', x: 0, y: 4, w: 12, h: 17, minH: 17, maxH: 17, minW: 6, maxW: 12 },
		{ i: 'facebook_page_fans_city', x: 0, y: 12, w: 4, h: 8, minH: 8, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_page_fans_country', x: 4, y: 12, w: 4, h: 8, minH: 8, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_page_fans_locale', x: 8, y: 12, w: 4, h: 8, minH: 8, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_page_video_view_time', x: 8, y: 16, w: 4, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_page_fan_removes', x: 9, y: 20, w: 3, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_page_views_total', x: 4, y: 24, w: 4, h: 11, minH: 11, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_reactions', x: 0, y: 36, w: 4, h: 11, minH: 11, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_page_consumptions_by_consumptions_type', x: 8, y: 36, w: 4, h: 11, minH: 11, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_page_fans_by_like_source_unique', x: 0, y: 47, w: 4, h: 11, minH: 11, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_page_negative_feedback_by_type', x: 8, y: 47, w: 4, h: 11, minH: 11, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_page_content_activity_by_age_gender_unique', x: 0, y: 58, w: 4, h: 11, minH: 11, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_page_content_activity_by_country_unique', x: 4, y: 58, w: 4, h: 11, minH: 11, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_page_content_activity_by_city_unique', x: 8, y: 58, w: 4, h: 11, minH: 11, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_page_fans_by_unlike_source_unique', x: 0, y: 69, w: 4, h: 11, minH: 11, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_page_views_by_profile_tab_total', x: 4, y: 69, w: 4, h: 11, minH: 11, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'facebook_posts_feed', x: 0, y: 80, w: 6, h: 15, minH: 15, maxH: 17, minW: 6, maxW: 12 },
		{ i: 'facebook_word_clouds', x: 0, y: 95, w: 6, h: 15, minH: 15, maxH: 17, minW: 6, maxW: 12 }
	]
}

const DEFAULT_WIDGETS_FACEBOOK_ACCOUNT_MANAGER = [
	'facebook_followers_count',
	'facebook_fan_count',
	'facebook_page_impressions_unique',
	'facebook_top_posts',
	'facebook_sentiments_comments_bar',
	'facebook_video_views_metrics',
	'facebook_posts_metrics',
	'facebook_page_impressions',
	'facebook_new_unique_follows',
	'facebook_new_unique_unfollows',
	'facebook_page_fan_adds'
]
