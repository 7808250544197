import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import AddWidgetsDashboardShareable from '../../../components/AddWidgetsDashboardShareable/AddWidgetsDashboardShareable'
import { formatMetricsAccountManagerMetaAds } from '../utils/metricsAccountManager.utils'
import DragResizeDashboard from '../../../components/DragResizeDashboard/DragResizeDashboard'
import ProfileMetaAdsHeader from './components/ProfileMetaAdsHeader/ProfileMetaAdsHeader'
import { getComponentsToRenderMetaAdsAccountManager, DEFAULT_LAYOUTS_META_ADS_ACCOUNT_MANAGER } from './utils/MetricsMetaAdsDashboardShareableUtils'

const MetricsMetaAdsDashboardShareable = ({
	data,
	layout,
	onLayoutChange,
	editMode,
	addWidgetMode,
	toggleAddWidgetMode,
	widgetsOnScreen,
	handleWidgetsOnScreen,
	handleRemoveWidget,
	cards,
	descriptions
}) => {
	const [metaAdsMetrics, setMetaAdsMetrics] = useState(null)
	const [droppingItem, setDroppingItem] = useState({ i: 'defaultDrag', w: 0, h: 0 })
	const [isDropping, setIsDropping] = useState(false)

	useEffect(() => {
		setMetaAdsMetrics(formatMetricsAccountManagerMetaAds(data))
	}, [data])

	const onChangeDragItem = (item) => {
		setDroppingItem(item)
	}

	const handleAddWidget = (widget, newLayout) => {
		onLayoutChange(newLayout, 'metaAds')
		handleWidgetsOnScreen([...widgetsOnScreen, widget], 'metaAds')
	}

	const setIsDroping = (droppingState) => {
		setIsDropping(droppingState)
	}

	const removeWidget = (keyName) => {
		handleRemoveWidget(keyName, 'metaAds')
	}

	return (
		<>
			{data ? (
				<Grid container>
					<Grid item xs={addWidgetMode ? 10 : 12} style={{ marginLeft: '0.5em', paddingRight: addWidgetMode ? '1em' : '0.67em' }}>
						<ProfileMetaAdsHeader analytics={metaAdsMetrics?.analytics} />
					</Grid>
					<Grid item xs={addWidgetMode ? 10 : 12}>
						<DragResizeDashboard
							editMode={editMode}
							layouts={layout}
							onLayoutChange={(_, layout) => onLayoutChange({ md: layout.md, sm: layout.sm }, 'metaAds')}
							droppingItem={droppingItem}
							handleAddWidget={handleAddWidget}
							addWidgetMode={addWidgetMode}
							isDroppable={isDropping}
						>
							{getComponentsToRenderMetaAdsAccountManager(metaAdsMetrics, editMode, addWidgetMode, removeWidget, cards, descriptions)
								.filter((widget) => widgetsOnScreen.includes(widget.key))
								.map((it) => (
									<div key={it.key}>{it.Component}</div>
								))}
						</DragResizeDashboard>
					</Grid>
					{addWidgetMode && (
						<Grid item xs={2} style={{ position: 'fixed', zIndex: '1', top: '8em', right: '1em', width: '100%', height: '50vh' }}>
							<AddWidgetsDashboardShareable
								defaultWidgetsLayouts={DEFAULT_LAYOUTS_META_ADS_ACCOUNT_MANAGER}
								addWidgetMode={addWidgetMode}
								toggleAddWidgetMode={toggleAddWidgetMode}
								onChangeDragItem={onChangeDragItem}
								widgetsOnScreen={widgetsOnScreen}
								setIsDroping={setIsDroping}
								descriptions={descriptions}
							/>
						</Grid>
					)}
				</Grid>
			) : (
				'Não possui a conta linkada'
			)}
		</>
	)
}

export default MetricsMetaAdsDashboardShareable
