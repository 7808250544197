import React, { useEffect, useRef, useState } from 'react'
import SkeletonLoadWidgetContainer from './components/SkeletonLoadWidgetContainer/SkeletonLoadWidgetContainer'
import { Card, IconButton } from '@mui/material'
import LoadingBar from 'components/LoadingBar/LoadingBar'
import DialogInformation from 'components/DialogInformation/DialogInformation'
import { CloseIcon, MoveResizeIcon } from 'utils/SystemIcons'

const WidgetContainer = ({
	children,
	title,
	widgetKeyName,
	isEditMode,
	isAddWidgetMode,
	removeWidget,
	description,
	loading = false,
	isSolidCardWidget = false,
	isResizable = true
}) => {
	const [hardLoading, setHardLoading] = useState(true)
	const loadingBarRef = useRef()
	const isMounted = useRef(true)

	useEffect(() => {
		isMounted.current = true

		if (isMounted.current) {
			loadingBarRef?.current?.load(loading && !hardLoading)
			if (!loading) {
				setHardLoading(false)
			}
		}

		return () => {
			isMounted.current = false
		}
	}, [loading])

	return (
		<>
			{isAddWidgetMode && (
				<div style={{ position: 'absolute', right: '1px', zIndex: '99' }}>
					<IconButton onClick={() => removeWidget(widgetKeyName)}>
						<CloseIcon color={'#1d8cf8'} />
					</IconButton>
				</div>
			)}
			<div style={{ position: 'relative', height: '100%', width: '100%' }}>
				{isEditMode && (
					<div
						style={{
							borderRadius: '20px',
							position: 'absolute',
							top: 47,
							left: 3,
							right: 3,
							bottom: 8,
							backdropFilter: 'blur(5px)',
							backgroundColor: 'rgba(250, 250, 250, 0.5)',
							zIndex: 1000,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center'
						}}
					>
						<MoveResizeIcon size={60} style={{ marginBottom: '3em', color: '#1d8cf8' }} />
					</div>
				)}
				<Card
					elevation={1}
					style={{
						backgroundColor: isSolidCardWidget ? 'white' : 'transparent',
						borderRadius: '20px',
						pointerEvents: isEditMode ? 'none' : '',
						userSelect: isEditMode ? 'none' : '',
						height: '99%',
						width: '100%',
						border: isEditMode && !isResizable ? '1px solid #1d8cf8' : ''
					}}
					className={isEditMode && isResizable ? 'editBox' : ''}
				>
					<div>
						<LoadingBar ref={loadingBarRef} />
					</div>
					{hardLoading ? (
						<SkeletonLoadWidgetContainer />
					) : (
						<div
							style={{
								width: '100%',
								height: '100%'
							}}
						>
							{title && description && (
								<div
									style={{
										display: 'flex',
										justifyContent: 'center',
										fontFamily: 'Poppins',
										color: '#878787',
										alignItems: 'center'
									}}
								>
									{title || ''}
									{description !== '' && <DialogInformation title={title} text={description} />}
								</div>
							)}
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									width: '100%',
									height: '100%'
								}}
							>
								{children}
							</div>
						</div>
					)}
				</Card>
			</div>
		</>
	)
}

export default WidgetContainer
