import React, { useState, useEffect } from 'react'
import Chart from 'react-apexcharts'
import { Grid } from '@mui/material'
import NetworkInfo from '../NetworkInfo/NetworkInfo'

const barOptions = (categories, showLegend = true) => {
	return {
		colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#69a116', '#03befc', '#fc6203', '#fc03d2'],
		chart: {
			type: 'bar'
		},
		plotOptions: {
			bar: {
				horizontal: false,
				columnWidth: '100%',
				endingShape: 'rounded'
			}
		},
		stroke: {
			show: true,
			width: 2,
			colors: ['transparent']
		},
		xaxis: {
			categories
		},
		fill: {
			opacity: 1
		},
		legend: {
			showForSingleSeries: showLegend,
			show: showLegend,
			showForZeroSeries: showLegend,
			showForNullSeries: showLegend
		},
		tooltip: {
			x: {
				show: false
			}
		}
	}
}

const barsDataProps = {
	options: barOptions(['', '', ''], false),
	series: [
		{ name: '', data: [0] },
		{ name: '', data: [0] },
		{ name: '', data: [0] }
	]
}

const NetworkMetrics = ({ cards, fullScreenHandle, nodeMetrics, height, fullScreenState }) => {
	const [barsData, setBarsData] = useState(barsDataProps)

	const setup = () => {
		let barsDataFormatted = {
			options: barOptions(['', '', '']),
			series: []
		}
		nodeMetrics?.networkItem?.author?.postCount &&
			barsDataFormatted.series.push({ name: 'Posts', data: [nodeMetrics.networkItem.author.postCount] })
		nodeMetrics?.networkItem?.engagement?.likeCount &&
			barsDataFormatted.series.push({ name: 'Likes', data: [nodeMetrics.networkItem.engagement.likeCount] })
		nodeMetrics?.networkItem?.engagement?.commentCount &&
			barsDataFormatted.series.push({ name: 'Comments', data: [nodeMetrics.networkItem.engagement.commentCount] })
		nodeMetrics?.networkItem?.engagement?.shareCount &&
			barsDataFormatted.series.push({ name: 'Shares', data: [nodeMetrics.networkItem.engagement.shareCount] })
		nodeMetrics?.networkItem?.engagement?.hahaCount &&
			barsDataFormatted.series.push({ name: 'Hahas', data: [nodeMetrics.networkItem.engagement.hahaCount] })
		nodeMetrics?.networkItem?.engagement?.angryCount &&
			barsDataFormatted.series.push({ name: 'Angry', data: [nodeMetrics.networkItem.engagement.angryCount] })
		nodeMetrics?.networkItem?.engagement?.sadCount &&
			barsDataFormatted.series.push({ name: 'Sad', data: [nodeMetrics.networkItem.engagement.sadCount] })
		nodeMetrics?.networkItem?.engagement?.wowCount &&
			barsDataFormatted.series.push({ name: 'Wow', data: [nodeMetrics.networkItem.engagement.wowCount] })
		nodeMetrics?.networkItem?.engagement?.loveCount &&
			barsDataFormatted.series.push({ name: 'Love', data: [nodeMetrics.networkItem.engagement.loveCount] })
		nodeMetrics?.networkItem?.engagement?.viewCount &&
			barsDataFormatted.series.push({ name: 'Views', data: [nodeMetrics.networkItem.engagement.viewCount] })

		if (barsDataFormatted.series.length === 0) {
			barsDataFormatted = { ...barsDataProps }
		}
		setBarsData(barsDataFormatted)
	}

	useEffect(() => {
		setup()
	}, [nodeMetrics])

	return (
		<>
			<Grid item xs={3} style={{ marginTop: '10px' }}>
				<NetworkInfo cards={cards} fullScreenHandle={fullScreenHandle} nodeMetrics={nodeMetrics} fullScreenState={fullScreenState} />

				<Grid container spacing={2}>
					<Grid item xs={12} style={{ color: 'black' }}>
						<Chart options={barsData.options} series={barsData.series} type='bar' width='100%' height={height} />
					</Grid>
				</Grid>
			</Grid>
		</>
	)
}

export default NetworkMetrics
