import React, { useState, useEffect } from 'react'
import { IoMdEye, IoMdEyeOff } from 'react-icons/io'
import { RiDeleteBinLine } from 'react-icons/ri'
import { FiEdit } from 'react-icons/fi'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import api from 'service/service'
import { Grid, Card, CardContent, Button, TextField, IconButton, FormControlLabel, Checkbox } from '@mui/material'
import { verifyServerResponseCanShowToast } from 'utils/generalFunctions'
import constantsVox from 'constants-vox'
import { LockIcon } from 'utils/SystemIcons'
import SubUserCardPermissions from './components/SubUserCardPermissions/SubUserCardPermissions'

const SubUser = () => {
	const subUserObj = {
		id: 0,
		name: '',
		email: '',
		password: '',
		permissionRead: false,
		permissionCreate: false,
		permissionRemove: false
	}
	const [subUser, setSubUser] = useState(subUserObj)
	const [subUsers, setSubUsers] = useState([])
	const [loading, setLoading] = useState(false)
	const [updating, setUpdating] = useState(false)
	const [iconVisible, setIconVisible] = useState(false)
	const [visible, setVisible] = useState('Password')
	const [cardAccessPermissionsSubUser, setCardAccessPermissionsSubUser] = useState({ isOpen: false, subUserId: null, subUserName: null })

	useEffect(() => {
		loadSubUsers()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const loadSubUsers = async () => {
		setLoading(true)
		api.get('/sub-users')
			.then((response) => {
				setSubUsers(response.data)
			})
			.catch((error) => {
				if (verifyServerResponseCanShowToast(error)) {
					sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
				}
			})
			.finally(() => setLoading(false))
	}

	const newSubUser = async () => {
		setSubUser(subUserObj)
	}

	const saveSubUser = async () => {
		setUpdating(true)
		let isValidName = true
		let isValidEmail = true
		let isValidPassword = true

		if (!subUser.name || subUser.name.trim() === '') {
			sendMessageToast('Digite um nome válido', constantsVox.TOAST.TYPES.WARNING)
			isValidName = false
		}

		if (!subUser.email || subUser.email.trim() === '' || !validateEmail(subUser.email.trim())) {
			sendMessageToast('Digite um email válido', constantsVox.TOAST.TYPES.WARNING)
			isValidEmail = false
		}

		if (subUser.id === 0) {
			if (!subUser.password || subUser.password.trim() === '') {
				sendMessageToast('Digite uma senha', constantsVox.TOAST.TYPES.WARNING)
				isValidPassword = false
			}
		}

		if (isValidName && isValidEmail && isValidPassword) {
			api.post('/sub-user', subUser)
				.then((response) => {
					if (response?.data?.toast) {
						sendMessageToast(response?.data?.toast?.message, response?.data?.toast?.type) //success
					}
					setSubUser(subUserObj)
					loadSubUsers()
				})
				.catch((error) => {
					if (verifyServerResponseCanShowToast(error)) {
						sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
					}
				})
				.finally(() => setUpdating(false))
		} else {
			setUpdating(false)
		}
	}

	const removeSubUser = async (subUserId) => {
		api.delete('/sub-user', { params: { subUserId } })
			.then((response) => {
				if (response?.data?.toast) {
					sendMessageToast(response?.data?.toast?.message, response?.data?.toast?.type)
				}
				loadSubUsers()
			})
			.catch((error) => {
				if (verifyServerResponseCanShowToast(error)) {
					sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
				}
			})
	}

	const getSubUserToEdit = async (event) => {
		event.preventDefault()
		const subUserId = event.target.dataset.subUserId

		api.get('/sub-user', { params: { subUserId } })
			.then((response) => {
				setSubUser({
					id: response.data.id,
					name: response.data.name,
					email: response.data.email,
					permissionRead: response.data.permission_read,
					permissionCreate: response.data.permission_create,
					permissionRemove: response.data.permission_remove,
					password: ''
				})
			})
			.catch((error) => {
				if (verifyServerResponseCanShowToast(error)) {
					sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
				}
			})
	}

	const validateEmail = (email) => {
		let re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
		return re.test(email)
	}

	const handlePasswordVisibility = () => {
		setIconVisible(!iconVisible)
		if (visible === 'Password') {
			setVisible('Text')
		} else {
			setVisible('Password')
		}
	}
	const toggleAccessPermissions = (id, name) => {
		let subUserId = id || null
		let subUserName = name || null
		setCardAccessPermissionsSubUser({ isOpen: !cardAccessPermissionsSubUser.isOpen, subUserId, subUserName })
	}

	return (
		<>
			<div className='page-2-content'>
				<Grid container>
					<Grid item md={12}>
						<Card>
							<CardContent>
								<h5>Criar Sub Usuário</h5>
								<Grid container item spacing={1}>
									<Grid container item md={4}>
										Dados
									</Grid>
									<Grid container item spacing={3}>
										<Grid item xs={3}>
											<TextField
												size='small'
												style={{ width: '100%' }}
												value={subUser.name}
												onChange={(e) => {
													setSubUser({
														...subUser,
														name: e.target.value
													})
												}}
												label='Nome'
												variant='outlined'
												disabled={updating || loading}
											/>
										</Grid>
										<Grid item xs={3}>
											<TextField
												size='small'
												style={{ width: '100%' }}
												value={subUser.email}
												onChange={(e) => {
													setSubUser({
														...subUser,
														email: e.target.value
													})
												}}
												label='Email'
												variant='outlined'
												disabled={updating || loading}
											/>
										</Grid>
										<Grid container item xs={4}>
											<Grid item xs={9}>
												<TextField
													size='small'
													style={{ width: '100%' }}
													value={subUser.password}
													onChange={(e) => {
														setSubUser({
															...subUser,
															password: e.target.value
														})
													}}
													label='Senha'
													variant='outlined'
													disabled={updating || loading}
													type={visible}
												/>
											</Grid>
											<Grid item xs={3}>
												<IconButton color='primary' onClick={handlePasswordVisibility} style={{ outline: 'none' }}>
													{iconVisible && (
														<IoMdEye size={18} style={{ color: '#b8b8b8' }} className='fix-icon-margin-bottom-negative' />
													)}
													{!iconVisible && (
														<IoMdEyeOff
															size={18}
															style={{ color: '#b8b8b8' }}
															className='fix-icon-margin-bottom-negative'
														/>
													)}
												</IconButton>
											</Grid>
										</Grid>
									</Grid>
									<Grid container item style={{ marginTop: '1em' }}>
										<Grid item md={12}>
											Permissões
										</Grid>
										<Grid item md={3}>
											<FormControlLabel
												control={
													<Checkbox
														checked={subUser.permissionCreate}
														onChange={() => {
															setSubUser({
																...subUser,
																permissionCreate: subUser.permissionCreate ? false : true
															})
														}}
														color='primary'
													/>
												}
												label='Criar'
											/>
										</Grid>
										<Grid item md={3}>
											<FormControlLabel
												control={
													<Checkbox
														checked={subUser.permissionRemove}
														onChange={() => {
															setSubUser({
																...subUser,
																permissionRemove: subUser.permissionRemove ? false : true
															})
														}}
														color='primary'
													/>
												}
												label='Remover'
											/>
										</Grid>
									</Grid>
								</Grid>
								<div style={{ marginTop: '0.5em' }}>
									<Button className='primary-button-app' onClick={saveSubUser} disabled={updating || loading}>
										Salvar
									</Button>
									{subUser.id !== 0 && (
										<Button
											className='primary-button-app'
											style={{ float: 'right' }}
											onClick={newSubUser}
											disabled={updating || loading}
										>
											Novo
										</Button>
									)}
								</div>
							</CardContent>
						</Card>
					</Grid>
				</Grid>

				<Grid container style={{ marginTop: '2em', marginBottom: '2em' }}>
					<Grid item md={12}>
						<Card>
							<CardContent>
								<h5>Sub Usuários</h5>
								<Grid container>
									<Grid item md={4}>
										Nome
									</Grid>
									<Grid item md={4}>
										Email
									</Grid>
								</Grid>
								{subUsers.map((subUser) => (
									<Grid
										container
										key={subUser.id}
										style={{
											paddingTop: '10px',
											paddingBottom: '10px'
										}}
									>
										<Grid item md={4}>
											{subUser.name}
										</Grid>
										<Grid item md={4}>
											{subUser.email}
										</Grid>
										<Grid item md={4}>
											<div
												style={{
													float: 'left',
													marginRight: '20px',
													textAlign: 'center',
													color: '#9A9A9A',
													cursor: 'pointer'
												}}
												data-sub-user-id={subUser.id}
												onClick={getSubUserToEdit}
												disabled={updating || loading}
											>
												Editar
												<FiEdit
													size={15}
													style={{ color: '#9A9A9A', marginLeft: '4px' }}
													className='fix-icon-margin-bottom-negative-1'
												/>
											</div>

											<div
												style={{
													float: 'left',
													marginRight: '20px',
													textAlign: 'center',
													color: '#9A9A9A',
													cursor: 'pointer'
												}}
												onClick={() => toggleAccessPermissions(subUser.id, subUser.name)}
												disabled={updating || loading}
											>
												Permissões de acesso
												<LockIcon
													size={15}
													style={{ color: '#9A9A9A', marginLeft: '4px' }}
													className='fix-icon-margin-bottom-negative-1'
												/>
											</div>

											<div
												style={{ float: 'left', textAlign: 'center', color: '#9A9A9A', cursor: 'pointer' }}
												data-sub-user-id={subUser.id}
												onClick={() => removeSubUser(subUser.id)}
												disabled={updating || loading}
											>
												Remover
												<RiDeleteBinLine
													size={15}
													style={{ color: '#9A9A9A', marginLeft: '4px', marginTop: '-3px' }}
													className='fix-icon-margin-bottom-negative-1'
												/>
											</div>
										</Grid>
									</Grid>
								))}
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</div>
			{cardAccessPermissionsSubUser.isOpen && (
				<SubUserCardPermissions
					cardAccessPermissionsSubUser={cardAccessPermissionsSubUser}
					toggleAccessPermissions={toggleAccessPermissions}
				/>
			)}
		</>
	)
}

export default SubUser
