import React from 'react'
import { Grid, Tooltip, Collapse } from '@mui/material'
import {
	LikeIcon,
	AngryIcon,
	CommentIcon,
	HahaIcon,
	HeartIcon,
	SadIcon,
	ShareIcon,
	WowIcon,
	RetweetIcon,
	RetweetIcon as RepostIcon,
	EyeIcon,
	UserCheckIcon,
	PlayIcon,
	PlayIconSmall,
	MinusIcon,
	MinusCircleIcon,
	ReplyIcon
} from 'utils/SystemIcons'
import MetricRenderer from 'components/MetricRenderer/MetricRenderer'

const iconSize = 14
const tooltipDelay = 200

const OccurrenceCardMetrics = ({ cardData, cardColor, source, type }) => {
	return (
		<>
			{source === 'twitter' && (
				<Grid container spacing={2} justifyContent='flex-end'>
					{(['term', 'profile', 'group'].includes(type) ? cardData.content.isPost : !cardData.is_retweet) && (
						<Grid item xs={4} style={{ textAlign: 'right', color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
							<Tooltip title='Curtidas' placement='top-end' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
								<div style={{ cursor: 'pointer', width: 'fit-content', margin: '0 0 auto auto' }}>
									<MetricRenderer
										value={
											['term', 'profile', 'group'].includes(type)
												? (cardData?.content?.engagement?.loveCount || 0) + (cardData?.content?.engagement?.likeCount || 0)
												: cardData?.favorite_count
										}
										color={cardColor}
										icon={
											<HeartIcon size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />
										}
										title='Curtidas'
									/>
								</div>
							</Tooltip>
						</Grid>
					)}
					<Grid item xs={4} style={{ textAlign: 'right', color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
						<Tooltip title='Retweets' placement='top-end' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
							<div style={{ cursor: 'pointer', width: 'fit-content', margin: '0 0 auto auto' }}>
								<MetricRenderer
									value={
										['term', 'profile', 'group'].includes(type)
											? cardData?.content?.engagement?.shareCount
											: cardData?.retweet_count
									}
									color={cardColor}
									icon={<RetweetIcon size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />}
									title='Retweets'
								/>
							</div>
						</Tooltip>
					</Grid>
					<Grid item xs={4} style={{ textAlign: 'right', color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
						<Tooltip title='Comentários' placement='top-end' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
							<div style={{ cursor: 'pointer', width: 'fit-content', margin: '0 0 auto auto' }}>
								<MetricRenderer
									value={
										['term', 'profile', 'group'].includes(type)
											? cardData?.content?.engagement?.commentCount
											: cardData?.public_metrics && cardData?.public_metrics?.reply_count
									}
									color={cardColor}
									icon={<CommentIcon size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />}
									title='Comentários'
								/>
							</div>
						</Tooltip>
					</Grid>
					{cardData?.non_public_metrics && (
						<>
							<Grid item xs={4} style={{ textAlign: 'right', color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
								<Tooltip title='Visualizações' placement='top-end' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
									<div style={{ cursor: 'pointer' }}>
										<MetricRenderer
											value={cardData?.non_public_metrics?.impression_count}
											color={cardColor}
											icon={
												<EyeIcon size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />
											}
											title='Visualizações'
										/>
									</div>
								</Tooltip>
							</Grid>
							<Grid item xs={4} style={{ textAlign: 'right', color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
								<Tooltip title='Cliques no perfil' placement='top-end' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
									<div style={{ cursor: 'pointer' }}>
										<MetricRenderer
											value={cardData?.non_public_metrics?.user_profile_clicks}
											color={cardColor}
											icon={
												<UserCheckIcon
													size={iconSize}
													style={{ color: cardColor }}
													className='fix-icon-margin-bottom-negative-1'
												/>
											}
											title='Cliques no perfil'
										/>
									</div>
								</Tooltip>
							</Grid>
						</>
					)}
					{cardData?.organic_metrics && (
						<Grid item xs={4} style={{ textAlign: 'right', color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
							<Tooltip title='Visualizações de video' placement='top-end' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
								<div style={{ cursor: 'pointer' }}>
									<MetricRenderer
										value={cardData?.organic_metrics?.video?.view_count}
										color={cardColor}
										icon={
											<PlayIconSmall
												size={iconSize}
												style={{ color: cardColor }}
												className='fix-icon-margin-bottom-negative-1'
											/>
										}
										title='Visualizações de video'
									/>
								</div>
							</Tooltip>
						</Grid>
					)}
					{(cardData?.content?.engagement?.viewCount > 0 || cardData?.post?.engagement?.view_count > 0) && (
						<Grid item xs={4} style={{ textAlign: 'right', color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
							<Tooltip title='Visualizações' placement='top-end' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
								<div style={{ cursor: 'pointer' }}>
									<MetricRenderer
										value={
											['term', 'profile', 'group'].includes(type)
												? cardData?.content?.engagement?.viewCount
												: cardData?.post?.engagement?.view_count
										}
										color={cardColor}
										icon={<EyeIcon size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />}
										title='Visualizações'
									/>
								</div>
							</Tooltip>
						</Grid>
					)}
				</Grid>
			)}
			{source === 'bluesky' && (
				<Grid container spacing={2} justifyContent='flex-end'>
					{(['term', 'profile', 'group'].includes(type) ? cardData.content.isPost : !cardData.is_repost) && (
						<Grid item xs={4} style={{ textAlign: 'right', color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
							<Tooltip title='Curtidas' placement='top-end' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
								<div style={{ cursor: 'pointer', width: 'fit-content', margin: '0 0 auto auto' }}>
									<MetricRenderer
										value={
											['term', 'profile', 'group'].includes(type)
												? (cardData?.content?.engagement?.loveCount || 0) + (cardData?.content?.engagement?.likeCount || 0)
												: cardData?.favorite_count
										}
										color={cardColor}
										icon={
											<HeartIcon size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />
										}
										title='Curtidas'
									/>
								</div>
							</Tooltip>
						</Grid>
					)}
					<Grid item xs={4} style={{ textAlign: 'right', color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
						<Tooltip title='Reposts' placement='top-end' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
							<div style={{ cursor: 'pointer', width: 'fit-content', margin: '0 0 auto auto' }}>
								<MetricRenderer
									value={
										['term', 'profile', 'group'].includes(type)
											? cardData?.content?.engagement?.shareCount
											: cardData?.repost_count
									}
									color={cardColor}
									icon={<RepostIcon size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />}
									title='Reposts'
								/>
							</div>
						</Tooltip>
					</Grid>
					<Grid item xs={4} style={{ textAlign: 'right', color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
						<Tooltip title='Comentários' placement='top-end' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
							<div style={{ cursor: 'pointer', width: 'fit-content', margin: '0 0 auto auto' }}>
								<MetricRenderer
									value={
										['term', 'profile', 'group'].includes(type)
											? cardData?.content?.engagement?.commentCount
											: cardData?.public_metrics && cardData?.public_metrics?.reply_count
									}
									color={cardColor}
									icon={<CommentIcon size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />}
									title='Comentários'
								/>
							</div>
						</Tooltip>
					</Grid>
					<Grid item xs={4} style={{ textAlign: 'right', color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
						<Tooltip title='Respostas' placement='top-end' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
							<div style={{ cursor: 'pointer', width: 'fit-content', margin: '0 0 auto auto' }}>
								<MetricRenderer
									value={['term', 'profile', 'group'].includes(type) && (cardData?.content?.engagement?.quoteCount ?? 0)}
									color={cardColor}
									icon={<ReplyIcon size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />}
									title='Respostas'
								/>
							</div>
						</Tooltip>
					</Grid>
					{cardData?.non_public_metrics && (
						<>
							<Grid item xs={4} style={{ textAlign: 'right', color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
								<Tooltip title='Visualizações' placement='top-end' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
									<div style={{ cursor: 'pointer' }}>
										<MetricRenderer
											value={cardData?.non_public_metrics?.impression_count}
											color={cardColor}
											icon={
												<EyeIcon size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />
											}
											title='Visualizações'
										/>
									</div>
								</Tooltip>
							</Grid>
							<Grid item xs={4} style={{ textAlign: 'right', color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
								<Tooltip title='Cliques no perfil' placement='top-end' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
									<div style={{ cursor: 'pointer' }}>
										<MetricRenderer
											value={cardData?.non_public_metrics?.user_profile_clicks}
											color={cardColor}
											icon={
												<UserCheckIcon
													size={iconSize}
													style={{ color: cardColor }}
													className='fix-icon-margin-bottom-negative-1'
												/>
											}
											title='Cliques no perfil'
										/>
									</div>
								</Tooltip>
							</Grid>
						</>
					)}
					{cardData?.organic_metrics && (
						<Grid item xs={4} style={{ textAlign: 'right', color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
							<Tooltip title='Visualizações de video' placement='top-end' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
								<div style={{ cursor: 'pointer' }}>
									<MetricRenderer
										value={cardData?.organic_metrics?.video?.view_count}
										color={cardColor}
										icon={
											<PlayIconSmall
												size={iconSize}
												style={{ color: cardColor }}
												className='fix-icon-margin-bottom-negative-1'
											/>
										}
										title='Visualizações de video'
									/>
								</div>
							</Tooltip>
						</Grid>
					)}
					{(cardData?.content?.engagement?.viewCount > 0 || cardData?.post?.engagement?.view_count > 0) && (
						<Grid item xs={4} style={{ textAlign: 'right', color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
							<Tooltip title='Visualizações' placement='top-end' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
								<div style={{ cursor: 'pointer' }}>
									<MetricRenderer
										value={
											['term', 'profile', 'group'].includes(type)
												? cardData?.content?.engagement?.viewCount
												: cardData?.post?.engagement?.view_count
										}
										color={cardColor}
										icon={<EyeIcon size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />}
										title='Visualizações'
									/>
								</div>
							</Tooltip>
						</Grid>
					)}
				</Grid>
			)}
			{source === 'news' && (
				<Grid container spacing={2} justifyContent='flex-end'>
					<Grid item xs={3} style={{ textAlign: 'right', color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
						<Tooltip title='Reações' placement='top-end' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
							<div style={{ cursor: 'pointer', width: 'fit-content', margin: '0 0 auto auto' }}>
								<MetricRenderer
									value={
										(cardData.content.engagement.angryCount || 0) +
										(cardData.content.engagement.hahaCount || 0) +
										(cardData.content.engagement.likeCount || 0) +
										(cardData.content.engagement.loveCount || 0) +
										(cardData.content.engagement.sadCount || 0) +
										(cardData.content.engagement.wowCount || 0)
									}
									color={cardColor}
									icon={<LikeIcon size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />}
									title='Reações'
								/>
							</div>
						</Tooltip>
					</Grid>
					<Grid item xs={3} style={{ textAlign: 'right', color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
						<Tooltip title='Comentários' placement='top-end' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
							<div style={{ cursor: 'pointer', width: 'fit-content', margin: '0 0 auto auto' }}>
								<MetricRenderer
									value={cardData?.content?.engagement?.commentCount}
									color={cardColor}
									icon={<CommentIcon size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />}
									title='Comentários'
								/>
							</div>
						</Tooltip>
					</Grid>
					<Grid item xs={3} style={{ textAlign: 'right', color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
						<Tooltip title='Compartilhamentos' placement='top-end' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
							<div style={{ cursor: 'pointer', width: 'fit-content', margin: '0 0 auto auto' }}>
								<MetricRenderer
									value={cardData?.content?.engagement?.shareCount}
									color={cardColor}
									icon={<ShareIcon size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />}
									title='Compartilhamentos'
								/>
							</div>
						</Tooltip>
					</Grid>
				</Grid>
			)}
			{source === 'facebook' && (
				<Grid container spacing={2} justifyContent='flex-end'>
					{cardData?.post?.media_product_type === 'REEL' && (
						<Grid item xs={4} md={3} style={{ color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
							<Tooltip title='Visualizações do Reel' placement='top' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
								<div style={{ cursor: 'pointer' }}>
									<MetricRenderer
										value={cardData.post?.metrics?.blue_reels_play_count}
										color={cardColor}
										icon={<EyeIcon size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />}
										title='Visualizações do Reel'
									/>
								</div>
							</Tooltip>
						</Grid>
					)}
					{cardData?.post?.media_product_type !== 'REEL' && (
						<>
							<Grid item xs={4} md={3} style={{ color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
								<Tooltip title='Curtidas' placement='top' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
									<div style={{ cursor: 'pointer' }}>
										<MetricRenderer
											value={
												['term', 'profile', 'group'].includes(type)
													? cardData.content?.engagement?.likeCount
													: cardData.post?.engagement?.like_count
											}
											color={cardColor}
											icon={
												<LikeIcon
													size={iconSize}
													style={{ color: cardColor }}
													className='fix-icon-margin-bottom-negative-1'
												/>
											}
											title='Curtidas'
										/>
									</div>
								</Tooltip>
							</Grid>
							<Grid item xs={4} md={3} style={{ color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
								<Tooltip title='Ameis' placement='top' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
									<div style={{ cursor: 'pointer' }}>
										<MetricRenderer
											value={
												['term', 'profile', 'group'].includes(type)
													? cardData?.content?.engagement?.loveCount
													: cardData?.post?.engagement?.love_count
											}
											color={cardColor}
											icon={
												<HeartIcon
													size={iconSize}
													style={{ color: cardColor }}
													className='fix-icon-margin-bottom-negative-1'
												/>
											}
											title='Ameis'
										/>
									</div>
								</Tooltip>
							</Grid>
							<Grid item xs={4} md={3} style={{ color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
								<Tooltip title='Hahas' placement='top' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
									<div style={{ cursor: 'pointer' }}>
										<MetricRenderer
											value={
												['term', 'profile', 'group'].includes(type)
													? cardData?.content?.engagement?.hahaCount
													: cardData?.post?.engagement?.haha_count
											}
											color={cardColor}
											icon={
												<HahaIcon
													size={iconSize}
													style={{ color: cardColor }}
													className='fix-icon-margin-bottom-negative-1'
												/>
											}
											title='Hahas'
										/>
									</div>
								</Tooltip>
							</Grid>
							<Grid item xs={4} md={3} style={{ color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
								<Tooltip title='Wows' placement='top' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
									<div style={{ cursor: 'pointer' }}>
										<MetricRenderer
											value={
												['term', 'profile', 'group'].includes(type)
													? cardData?.content?.engagement?.wowCount
													: cardData?.post?.engagement?.wow_count
											}
											color={cardColor}
											icon={
												<WowIcon size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />
											}
											title='Wows'
										/>
									</div>
								</Tooltip>
							</Grid>
							<Grid item xs={4} md={3} style={{ color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
								<Tooltip title='Tristes' placement='top' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
									<div style={{ cursor: 'pointer' }}>
										<MetricRenderer
											value={
												['term', 'profile', 'group'].includes(type)
													? cardData?.content?.engagement?.sadCount
													: cardData?.post?.engagement?.sad_count
											}
											color={cardColor}
											icon={
												<SadIcon size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />
											}
											title='Tristes'
										/>
									</div>
								</Tooltip>
							</Grid>
							<Grid item xs={4} md={3} style={{ color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
								<Tooltip title='Grrs' placement='top' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
									<div style={{ cursor: 'pointer' }}>
										<MetricRenderer
											value={
												['term', 'profile', 'group'].includes(type)
													? cardData?.content?.engagement?.angryCount
													: cardData?.post?.engagement?.angry_count
											}
											color={cardColor}
											icon={
												<AngryIcon
													size={iconSize}
													style={{ color: cardColor }}
													className='fix-icon-margin-bottom-negative-1'
												/>
											}
											title='Grrs'
										/>
									</div>
								</Tooltip>
							</Grid>
						</>
					)}
					<Grid item xs={4} md={3} style={{ color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
						<Tooltip title='Comentários' placement='top' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
							<div style={{ cursor: 'pointer' }}>
								<MetricRenderer
									value={
										['term', 'profile', 'group'].includes(type)
											? cardData.content?.engagement?.commentCount
											: cardData.post?.engagement?.comment_count
									}
									color={cardColor}
									icon={<CommentIcon size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />}
									title='Comentários'
								/>
							</div>
						</Tooltip>
					</Grid>
					<Grid item xs={4} md={3} style={{ color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
						<Tooltip title='Compartilhamentos' placement='top' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
							<div style={{ cursor: 'pointer' }}>
								<MetricRenderer
									value={
										['term', 'profile', 'group'].includes(type)
											? cardData?.content?.engagement?.shareCount
											: cardData?.post?.engagement?.share_count
									}
									color={cardColor}
									icon={<ShareIcon size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />}
									title='Compartilhamentos'
								/>
							</div>
						</Tooltip>
					</Grid>
					<Grid item xs={4} md={3} style={{ color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
						<Collapse in={cardData?.post?.negative_feedback !== undefined}>
							<Tooltip title='Feedback negativo' placement='top' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
								<div style={{ cursor: 'pointer' }}>
									<MetricRenderer
										value={cardData?.post?.negative_feedback}
										color={cardColor}
										icon={
											<MinusIcon size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />
										}
										title='Feedback negativo'
									/>
								</div>
							</Tooltip>
						</Collapse>
					</Grid>
					<Grid item xs={4} md={3} style={{ color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
						<Collapse in={cardData?.post?.negative_feedback_unique !== undefined}>
							<Tooltip title='Feedback negativo unico' placement='top' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
								<div style={{ cursor: 'pointer' }}>
									<MetricRenderer
										value={cardData?.post?.negative_feedback_unique}
										color={cardColor}
										icon={
											<MinusCircleIcon
												size={iconSize}
												style={{ color: cardColor }}
												className='fix-icon-margin-bottom-negative-1'
											/>
										}
										title='Feedback negativo unico'
									/>
								</div>
							</Tooltip>
						</Collapse>
					</Grid>
					<Grid item xs={4} md={3} style={{ color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
						<Collapse in={cardData?.post?.video_views !== undefined}>
							<Tooltip title='Visualizações de video' placement='top' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
								<div style={{ cursor: 'pointer' }}>
									<MetricRenderer
										value={cardData?.post?.video_views}
										color={cardColor}
										icon={
											<PlayIconSmall
												size={iconSize}
												style={{ color: cardColor }}
												className='fix-icon-margin-bottom-negative-1'
											/>
										}
										title='Visualizações de video'
									/>
								</div>
							</Tooltip>
						</Collapse>
					</Grid>
					<Grid item xs={4} md={3} style={{ color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
						<Collapse in={cardData?.post?.video_views_unique !== undefined}>
							<Tooltip title='Visualizações de video unicas' placement='top' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
								<div style={{ cursor: 'pointer' }}>
									<MetricRenderer
										value={cardData?.post?.video_views_unique}
										color={cardColor}
										icon={<PlayIcon size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />}
										title='Visualizações de video unicas'
									/>
								</div>
							</Tooltip>
						</Collapse>
					</Grid>
				</Grid>
			)}
			{source === 'instagram' && (
				<Grid container spacing={2} justifyContent='flex-end'>
					<Grid item xs={4} style={{ textAlign: 'right', color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
						<Tooltip title='Curtidas' placement='top-end' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
							<div style={{ cursor: 'pointer' }}>
								<MetricRenderer
									value={
										['term', 'profile', 'group'].includes(type)
											? cardData?.content?.engagement?.likeCount
											: cardData?.post?.engagement?.like_count
									}
									color={cardColor}
									icon={<LikeIcon size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />}
									title='Curtidas'
								/>
							</div>
						</Tooltip>
					</Grid>
					<Grid item xs={4} style={{ textAlign: 'right', color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
						<Tooltip title='Comentários' placement='top-end' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
							<div style={{ cursor: 'pointer' }}>
								<MetricRenderer
									value={
										['term', 'profile', 'group'].includes(type)
											? cardData?.content?.engagement?.commentCount
											: cardData?.post?.engagement?.comment_count
									}
									color={cardColor}
									icon={<CommentIcon size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />}
									title='Comentários'
								/>
							</div>
						</Tooltip>
					</Grid>

					{(cardData?.content?.engagement?.viewCount > 0 || cardData?.post?.engagement?.view_count > 0) && (
						<Grid item xs={4} style={{ textAlign: 'right', color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
							<Tooltip title='Visualizações de video' placement='top-end' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
								<div style={{ cursor: 'pointer' }}>
									<MetricRenderer
										value={
											['term', 'profile', 'group'].includes(type)
												? cardData?.content?.engagement?.viewCount
												: cardData?.post?.engagement?.view_count
										}
										color={cardColor}
										icon={<EyeIcon size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />}
										title='Visualizações de video'
									/>
								</div>
							</Tooltip>
						</Grid>
					)}
				</Grid>
			)}
			{['tiktok', 'youtube'].includes(source) && (
				<Grid container spacing={2} justifyContent='flex-end'>
					<Grid item xs={5} style={{ textAlign: 'right', color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
						<Tooltip title='Curtidas' placement='top-end' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
							<div style={{ cursor: 'pointer' }}>
								<MetricRenderer
									value={
										['term', 'profile', 'group'].includes(type)
											? cardData?.content?.engagement?.likeCount
											: cardData?.post?.engagement?.like_count
									}
									color={cardColor}
									icon={<LikeIcon size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />}
									title='Curtidas'
								/>
							</div>
						</Tooltip>
					</Grid>
					<Grid item xs={5} style={{ textAlign: 'right', color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
						<Tooltip title='Comentários' placement='top-end' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
							<div style={{ cursor: 'pointer' }}>
								<MetricRenderer
									value={
										['term', 'profile', 'group'].includes(type)
											? cardData?.content?.engagement?.commentCount
											: cardData?.post?.engagement?.comment_count
									}
									color={cardColor}
									icon={<CommentIcon size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />}
									title='Comentários'
								/>
							</div>
						</Tooltip>
					</Grid>

					{(
						['term', 'profile', 'group'].includes(type)
							? cardData?.content?.engagement?.viewCount
							: cardData?.post?.engagement?.view_count
					) ? (
						<Grid item xs={5} style={{ textAlign: 'right', color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
							<Tooltip title='Visualizações de video' placement='top-end' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
								<div style={{ cursor: 'pointer' }}>
									<MetricRenderer
										value={
											['term', 'profile', 'group'].includes(type)
												? cardData?.content?.engagement?.viewCount
												: cardData?.post?.engagement?.view_count
										}
										color={cardColor}
										icon={<EyeIcon size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />}
										title='Visualizações de video'
									/>
								</div>
							</Tooltip>
						</Grid>
					) : (
						<div></div>
					)}
					{(
						['term', 'profile', 'group'].includes(type)
							? cardData?.content?.engagement?.shareCount
							: cardData?.post?.engagement?.share_count
					) ? (
						<Grid item xs={5} style={{ textAlign: 'right', color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
							<Tooltip title='Compartilhamentos' placement='top-end' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
								<div style={{ cursor: 'pointer' }}>
									<MetricRenderer
										value={
											['term', 'profile', 'group'].includes(type)
												? cardData?.content?.engagement?.shareCount
												: cardData?.post?.engagement?.share_count
										}
										color={cardColor}
										icon={
											<ShareIcon size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />
										}
										title='Compartilhamentos'
									/>
								</div>
							</Tooltip>
						</Grid>
					) : (
						<div></div>
					)}
					{(
						['term', 'profile', 'group'].includes(type)
							? cardData?.content?.engagement?.quoteCount
							: cardData?.post?.engagement?.reply_count
					) ? (
						<Grid item xs={5} style={{ textAlign: 'right', color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
							<Tooltip title='Respostas' placement='top-end' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
								<div style={{ cursor: 'pointer' }}>
									<MetricRenderer
										value={
											['term', 'profile', 'group'].includes(type)
												? cardData?.content?.engagement?.quoteCount
												: cardData?.post?.engagement?.reply_count
										}
										color={cardColor}
										icon={
											<ReplyIcon size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />
										}
										title='Respostas'
									/>
								</div>
							</Tooltip>
						</Grid>
					) : (
						<div></div>
					)}
				</Grid>
			)}
			{source === 'metaAds' && (
				<Grid container spacing={2} justifyContent='flex-end'>
					<Grid item xs={6} style={{ textAlign: 'right', color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
						<div style={{ width: 'fit-content', margin: '0 0 auto auto' }}>
							<MetricRenderer
								value={cardData?.post?.metrics?.impressions}
								color={cardColor}
								icon={<div style={{ fontFamily: 'Poppins', fontWeight: 'bold' }}>Impressões:</div>}
								title='Impressões'
							/>
						</div>
					</Grid>
					<Grid item xs={6} style={{ textAlign: 'right', color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
						<div style={{ width: 'fit-content', margin: '0 0 auto auto' }}>
							<MetricRenderer
								value={cardData?.post?.metrics?.clicks}
								color={cardColor}
								icon={<div style={{ fontFamily: 'Poppins', fontWeight: 'bold' }}>Cliques:</div>}
								title='Cliques'
							/>
						</div>
					</Grid>
				</Grid>
			)}
		</>
	)
}

export default OccurrenceCardMetrics
