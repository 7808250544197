import React, { memo, useEffect, useState } from 'react'
import { Button, Card, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ReportIcon } from 'utils/SystemIcons'
import GraphTitle from 'components/GraphTitle/GraphTitle'
import { connect } from 'react-redux'
import moment from 'moment-timezone'
import ReportContentChoice from './components/ReportContentChoice/ReportContentChoice'
import ReportContentChoiceMenu from './components/ReportContentChoiceMenu/ReportContentChoiceMenu'
import api from 'service/service'
import constantsVox from 'constants-vox'
import ReportContentImage from './components/ReportContentImage/ReportContentImage'

const defaultOptions = {
	twitter: true,
	facebook: true,
	instagram: true,
	tiktok: true,
	youtube: true,
	news: true,
	summaryTwitter: true,
	summaryFacebook: true,
	summaryInstagram: true,
	summaryTiktok: true,
	summaryYoutube: true,
	summaryNews: true,
	locationTwitter: false,
	locationFacebook: false,
	locationInstagram: false,
	locationTiktok: false,
	locationYoutube: false,
	sentimentsTwitter: false,
	sentimentsFacebook: false,
	sentimentsInstagram: false,
	sentimentsTiktok: false,
	sentimentsYoutube: false,
	timelineTwitter: true,
	timelineFacebook: true,
	timelineInstagram: true,
	timelineTiktok: true,
	timelineYoutube: true,
	timelineNews: true,
	networkTwitter: false,
	networkFacebook: false,
	networkInstagram: false,
	networkTiktok: false,
	networkYoutube: false,
	networkNews: false,
	sentimentsTwitterStandard: false,
	sentimentsTwitterBalance: false,
	timelineTwitterEngagement: true,
	timelineTwitterPosts: true,
	networkTwitterAccounts: false,
	networkTwitterHashtags: false,
	networkTwitterSentiments: false,
	locationTwitterVolume: false,
	locationTwitterSentiments: false,
	locationTwitterGender: false,
	sentimentsFacebookStandard: false,
	sentimentsFacebookBalance: false,
	timelineFacebookEngagement: true,
	timelineFacebookPosts: true,
	networkFacebookAccounts: false,
	networkFacebookSentiments: false,
	locationFacebookVolume: false,
	locationFacebookSentiments: false,
	locationFacebookGender: false,
	sentimentsInstagramStandard: false,
	sentimentsInstagramBalance: false,
	timelineInstagramEngagement: true,
	timelineInstagramPosts: true,
	networkInstagramAccounts: false,
	networkInstagramSentiments: false,
	locationInstagramVolume: false,
	locationInstagramSentiments: false,
	locationInstagramGender: false,
	sentimentsTiktokStandard: false,
	sentimentsTiktokBalance: false,
	timelineTiktokEngagement: true,
	timelineTiktokPosts: true,
	locationTiktokVolume: false,
	locationTiktokSentiments: false,
	locationTiktokGender: false,
	networkTiktokAccounts: false,
	networkTiktokSentiments: false,
	sentimentsYoutubeStandard: false,
	sentimentsYoutubeBalance: false,
	timelineYoutubeEngagement: true,
	timelineYoutubePosts: true,
	locationYoutubeVolume: false,
	locationYoutubeSentiments: false,
	locationYoutubeGender: false,
	networkYoutubeChannels: false,
	networkYoutubeSentiments: false,
	timelineNewsEngagement: true,
	timelineNewsPosts: true,
	networkNewsAccounts: false,
	title: '',
	subTitle: '',
	image: ''
}

const blue = 'rgba(29, 138, 248, 100%)'

const useStyles = makeStyles({
	root: {
		color: blue,
		font: 'Poppins',
		fontWeight: '15'
	},
	label: {
		textTransform: 'capitalize'
	},
	button: {
		fontFamily: 'Poppins',
		border: '1px solid rgba(29, 138, 248, 100%)',
		boxShadow: 'none',
		color: 'white',
		backgroundColor: 'rgba(29, 138, 248, 100%)',
		borderRadius: '100px',
		background: 'transparent',
		'&:hover': {
			border: '1px solid rgba(29, 138, 248, 25%)',
			backgroundColor: 'rgba(29, 138, 248, 75%)',
			boxShadow: '0px 0px -10px -17px rgba(0,0,0,255)'
		}
	},
	backDrop: {
		backdropFilter: 'blur(3px)',
		backgroundColor: 'rgba(220, 220, 220, 0.4)'
	}
})

const getImageLocation = (reportInfo, userReport) => {
	if (reportInfo !== '' && reportInfo.data.locationImage !== '') {
		return reportInfo.data.locationImage
	} else if (userReport !== '' && userReport.image !== '') {
		return userReport.image
	} else {
		return constantsVox.LOGO_REPORT.DEFAULT_URL
	}
}

const ReportContent = ({ idsToCompare, globalFiltersRedux, cardType }) => {
	let filters = globalFiltersRedux //só pra contentUrlSearch não ficar indentada doida com o globalFiltersRedux, pois o nome da variável é grande
	const [graphInterval, setGraphInterval] = useState('day')
	const [graphType, setGraphType] = useState('horizontal')
	const classes = useStyles()
	const [options, setOptions] = useState(defaultOptions)
	const [isLoad, setIsLoad] = useState(false)
	const [userReport, setUserReport] = useState('')

	const load = async () => {
		const userSettings = await api.get('/user-report-settings')
		if (userSettings.data) {
			setOptions({
				...options,
				title: userSettings.data.title,
				subTitle: userSettings.data.sub_title,
				image: userSettings.data.image === '' ? constantsVox.LOGO_REPORT.DEFAULT_URL : userSettings.data.image
			})
			setUserReport(userSettings.data)
		} else {
			setOptions({ ...options, image: constantsVox.LOGO_REPORT.DEFAULT_URL })
		}
		setIsLoad(true)
	}

	useEffect(() => {
		load()
	}, [])

	const handleChangeGraphType = (type) => {
		setGraphType(type)
	}

	const handleChangeGraphTime = (time) => {
		setGraphInterval(time)
	}

	const onChangeOptions = (newOptions) => {
		setOptions(newOptions)
	}

	const handleRedirect = async () => {
		const locationState = JSON.parse(filters?.advancedFilters.authorLocation)
		let reportInfo = ''
		if (
			(options.title !== '' && options.title !== userReport.title) ||
			(options.subTitle !== '' && options.subTitle !== userReport.sub_title) ||
			(options.image !== constantsVox.LOGO_REPORT.DEFAULT_URL && options.image !== userReport.image)
		) {
			reportInfo = await api.post('/info-logo-report', {
				image: options.image,
				title: options.title,
				subTitle: options.subTitle
			})
		}
		const win = window.open(
			`/report/${cardType}/${idsToCompare}/${filters.fromDate}/${filters.toDate}/${filters.fromHour}/${filters.toHour}
					?contentTextSearch=${encodeURIComponent(filters?.contentTextSearch)}&hasManualClassification=${
				filters?.classification.hasManualClassification
			}&hasAutomaticClassification=${filters?.classification.hasAutomaticClassification}
					&manifestationsHigher=${filters?.manifestations?.higher}&manifestationsLower=${filters?.manifestations?.lower}
					&contentType=${filters?.advancedFilters.contentType}&authorIsVerified=${filters?.advancedFilters.authorIsVerified}
					&user=${encodeURIComponent(filters?.advancedFilters.user)}&authorDescriptionSearch=${encodeURIComponent(
				filters?.advancedFilters.authorDescriptionSearch
			)}
					&genderMaleTwitter=${filters?.advancedFilters.authorGender.male}&genderFemaleTwitter=${filters?.advancedFilters.authorGender.female}
					&genderMaleFacebook=${filters?.advancedFilters.authorGender.male}&genderFemaleFacebook=${filters?.advancedFilters.authorGender.female}
					&genderMaleInstagram=${filters?.advancedFilters.authorGender.male}&genderFemaleInstagram=${filters?.advancedFilters.authorGender.female}
					&genderMaleTiktok=${filters?.advancedFilters.authorGender.male}&genderFemaleTiktok=${filters?.advancedFilters.authorGender.female}
					&genderMaleYoutube=${filters?.advancedFilters.authorGender.male}&genderFemaleYoutube=${filters?.advancedFilters.authorGender.female}
					&countryCodeTwitter=${locationState.advancedFilters.countryCode}&regionCodeTwitter=${locationState.advancedFilters.regionCode}&stateCodeTwitter=${
				locationState.advancedFilters.stateCode
			}&cityCodeTwitter=${locationState.advancedFilters.cityCode}
					&countryCodeFacebook=${locationState.advancedFilters.countryCode}&regionCodeFacebook=${locationState.advancedFilters.regionCode}&stateCodeFacebook=${
				locationState.advancedFilters.stateCode
			}&cityCodeFacebook=${locationState.advancedFilters.cityCode}
					&countryCodeInstagram=${locationState.advancedFilters.countryCode}&regionCodeInstagram=${
				locationState.advancedFilters.regionCode
			}&stateCodeInstagram=${locationState.advancedFilters.stateCode}&cityCodeTiktok=${locationState.advancedFilters.cityCode}
			&countryCodeTiktok=${locationState.advancedFilters.countryCode}
			&regionCodeTiktok=${locationState.advancedFilters.regionCode}
			&stateCodeTiktok=${locationState.advancedFilters.stateCode}
			&cityCodeTiktok=${locationState.advancedFilters.cityCode}
			}&cityCodeTiktok=${locationState.advancedFilters.cityCode}
			&countryCodeYoutube=${locationState.advancedFilters.countryCode}
			&regionCodeYoutube=${locationState.advancedFilters.regionCode}
			&stateCodeYoutube=${locationState.advancedFilters.stateCode}
			&cityCodeYoutube=${locationState.advancedFilters.cityCode}
			&authorDomainSearch=${filters?.advancedFilters.authorDomainSearch}&contentUrlSearch=${filters?.advancedFilters.contentUrlSearch}
			&viewType=${graphType}&graphInterval=${graphInterval}
			&sentimentsTwitterStandard=${options.sentimentsTwitterStandard}&sentimentsTwitterBalance=${options.sentimentsTwitterBalance}
			&sentimentsFacebookStandard=${options.sentimentsFacebookStandard}&sentimentsFacebookBalance=${options.sentimentsFacebookBalance}
			&sentimentsInstagramStandard=${options.sentimentsInstagramStandard}&sentimentsInstagramBalance=${options.sentimentsInstagramBalance}
			&sentimentsTiktokStandard=${options.sentimentsTiktokStandard}&sentimentsTiktokBalance=${options.sentimentsTiktokBalance}
			&sentimentsYoutubeStandard=${options.sentimentsYoutubeStandard}&sentimentsYoutubeBalance=${options.sentimentsYoutubeBalance}
			&timelineTwitterEngagement=${options.timelineTwitterEngagement}
			&timelineFacebookEngagement=${options.timelineFacebookEngagement}&timelineInstagramEngagement=${
				options.timelineInstagramEngagement
			}&timelineTiktokEngagement=${options.timelineTiktokEngagement}
			}&timelineYoutubeEngagement=${options.timelineYoutubeEngagement}
			&timelineNewsEngagement=${options.timelineNewsEngagement}&networkNewsAccounts=${options.networkNewsAccounts}
			&networkFacebookAccounts=${options.networkFacebookAccounts}&networkFacebookSentiments=${options.networkFacebookSentiments}
			&networkInstagramAccounts=${options.networkInstagramAccounts}&networkInstagramSentiments=${options.networkInstagramSentiments}
			&networkTiktokAccounts=${options.networkTiktokAccounts}&networkTiktokSentiments=${options.networkTiktokSentiments}
			&networkYoutubeChannels=${options.networkYoutubeChannels}&networkYoutubeSentiments=${options.networkYoutubeSentiments}
			&networkTwitterAccounts=${options.networkTwitterAccounts}&networkTwitterSentiments=${options.networkTwitterSentiments}
			&networkTwitterHashtags=${options.networkTwitterHashtags}&summaryTwitter=${options.summaryTwitter}
			&summaryFacebook=${options.summaryFacebook}&summaryInstagram=${options.summaryInstagram}&summaryTiktok=${options.summaryTiktok}&summaryYoutube=${
				options.summaryYoutube
			}&summaryNews=${options.summaryNews}
			&timelineTwitterPosts=${options.timelineTwitterPosts}&timelineFacebookPosts=${options.timelineFacebookPosts}
			&timelineInstagramPosts=${options.timelineInstagramPosts}&timelineTiktokPosts=${options.timelineTiktokPosts}&timelineYoutubePosts=${
				options.timelineYoutubePosts
			}&timelineNewsPosts=${options.timelineNewsPosts}
			&locationTwitterVolume=${options.locationTwitterVolume}&locationTwitterSentiments=${options.locationTwitterSentiments}
			&locationTwitterGender=${options.locationTwitterGender}&locationFacebookVolume=${options.locationFacebookVolume}
			&locationFacebookSentiments=${options.locationFacebookSentiments}&locationFacebookGender=${options.locationFacebookGender}
			&locationInstagramVolume=${options.locationInstagramVolume}&locationInstagramSentiments=${options.locationInstagramSentiments}
			&locationInstagramGender=${options.locationInstagramGender}
			&locationTiktokVolume=${options.locationTiktokVolume}&locationTiktokSentiments=${options.locationTiktokSentiments}
			&locationTiktokGender=${options.locationTiktokGender}
			&locationYoutubeVolume=${options.locationYoutubeVolume}&locationYoutubeSentiments=${options.locationYoutubeSentiments}
			&locationYoutubeGender=${options.locationYoutubeGender}
			&twitter=${options.twitter}&facebook=${options.facebook}&instagram=${options.instagram}&tiktok=${options.tiktok}&youtube=${options.youtube}
			&news=${options.news}&title=${options.title}&subTitle=${options.subTitle}
			&image=${getImageLocation(reportInfo, userReport)}
			&authorFollowersHigher=${filters?.advancedFilters.authorFollowers.higher}
			&authorFollowersLower=${filters?.advancedFilters?.authorFollowers?.lower}
			&authorLikesHigher=${filters?.advancedFilters?.authorLikes?.higher}
			&authorLikesLower=${filters?.advancedFilters?.authorLikes?.lower}
			&hasPositiveImpact=${filters?.classification?.positive}
			&hasNegativeImpact=${filters?.classification?.negative}
			&hasNeutralImpact=${filters?.classification?.neutral}
			&hasIrrelevantImpact=${filters?.classification?.irrelevant}
			&hasIgnoredWords=${filters?.isStopWordsActive}
			&tagsGlobalFilters=${filters?.tagsGlobalFilters}
			`,
			'_blank'
		)
		win.focus()
	}

	return (
		<>
			<Grid item xs={12}>
				<Card style={{ height: '100%', borderRadius: '20px' }}>
					<Grid container spacing={3} style={{ background: '#fbfbfb', height: '70px', borderBottom: '1px solid #f5f5f5' }}>
						<Grid item xs={12}>
							<GraphTitle
								title={`Relatório para intervalo: ${moment(globalFiltersRedux.fromDate).format('DD/MM/YYYY')} - ${moment(
									globalFiltersRedux.toDate
								).format('DD/MM/YYYY')}`}
								icon={<ReportIcon size={20} style={{ color: '#1d8cf8' }} />}
							/>
						</Grid>
					</Grid>

					<Grid container>
						<Grid item xs={12}>
							<ReportContentChoiceMenu handleChangeGraphType={handleChangeGraphType} handleChangeGraphTime={handleChangeGraphTime} />
						</Grid>
					</Grid>

					<Grid
						container
						justifyContent='center'
						alignItems='center'
						style={{ paddingTop: '1.5em', paddingLeft: '1em', paddingRight: '1em' }}
					>
						<Grid item xs={7} container style={{ paddingRight: '1em' }}>
							<ReportContentChoice options={options} onChangeOptions={onChangeOptions} cardType={cardType} isLoad={isLoad} />
						</Grid>
						<Grid
							item
							xs={5}
							container
							justifyContent='center'
							alignItems='center'
							style={{
								height: '54vh',
								overflowY: 'auto',
								borderRadius: '20px',
								backgroundColor: 'rgba(245, 245, 245, 0.65)',
								border: '1px solid rgba(255, 255, 255, 0.7)'
							}}
						>
							<ReportContentImage options={options} cardType={cardType} />
						</Grid>
					</Grid>

					<Grid container direction='row' justifyContent='space-between' alignItems='center'>
						<div>
							<Button
								disableElevation
								variant={'contained'}
								onClick={() => handleRedirect()}
								style={{ outline: 'none', marginLeft: '18px', marginBottom: '15px', marginTop: '10px' }}
								className={classes.button}
								disabled={
									cardType === 'term'
										? !options.twitter &&
										  !options.facebook &&
										  !options.instagram &&
										  !options.tiktok &&
										  !options.youtube &&
										  !options.news
										: !options.twitter && !options.facebook && !options.instagram && !options.tiktok && !options.youtube
								}
							>
								Abrir Relatório
							</Button>
						</div>
					</Grid>
				</Card>
			</Grid>
		</>
	)
}

const mapStateToProps = ({ store }) => ({
	globalFiltersRedux: store.global.filters,
	cardType: store.card.cardType
})

export default connect(mapStateToProps)(memo(ReportContent))
