import React, { useState, useEffect } from 'react'
import { Grid, TextField, styled, Button, Chip, Autocomplete, Paper } from '@mui/material'
import { connect } from 'react-redux'
import { makeStyles } from '@mui/styles'
import { DocumentIcon, PlusIcon } from 'utils/SystemIcons'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import { verifyServerResponseCanShowToast } from 'utils/generalFunctions'
import moment from 'moment-timezone'
import api from 'service/service'
import constantsVox from 'constants-vox'
import ImageUploader from 'react-images-upload'
import 'global.css'

const isFirefox = typeof InstallTrigger !== 'undefined'

const blue = '#1d8af8'
const grey = '#828282'
const white = '#ffffff'
const blueTransparent = 'rgba(29, 138, 248, 10%)'

let lastOnDeleteTime = null

const CssTextField = styled(TextField)({
	'& .MuiInputBase-root': {
		color: blue,
		fontFamily: 'Poppins',
		'& input': {
			textAlign: 'left'
		}
	},
	'& .MuiOutlinedInput-root': {
		'& fieldset': {
			borderColor: grey
		},
		'&:hover fieldset': {
			borderColor: grey
		},
		'&.Mui-focused fieldset': {
			borderColor: blue
		}
	}
})

const useStyles = makeStyles(() => ({
	button: {
		fontFamily: 'Poppins',
		borderRadius: '100px',
		color: blue,
		minWidth: '300px',
		background: 'transparent',
		border: `1px solid ${blueTransparent}`,
		'&:hover': {
			backgroundColor: blueTransparent,
			boxShadow: '0px 0px -10px -17px rgba(0,0,0,255)'
		},
		'&:disabled': {
			backgroundColor: 'transparent'
		}
	}
}))

const CustomPaper = (props) => {
	return (
		<Paper
			{...props}
			sx={{
				minWidth: '15em',
				borderRadius: '0px 0px 25px 25px',
				backdropFilter: 'blur(10px)',
				backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
				border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
				boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
			}}
		/>
	)
}

const getCustomIntervalLabel = (fromDate, toDate) => {
	let label = null

	if (moment(fromDate).format('DD/MM/YYYY') === moment(toDate).format('DD/MM/YYYY')) {
		label = `${moment(fromDate).format('DD/MM/YYYY')}`
	} else {
		label = `${moment(fromDate).format('DD/MM/YYYY')} - ${moment(toDate).format('DD/MM/YYYY')}`
	}
	return label
}

const getInitialExportName = (cardName, fromDate, toDate) => {
	return `Postagens - ${cardName} - ${getCustomIntervalLabel(fromDate, toDate)}`
}

const ExportEmail = ({ user, cardId, filters, wordCloudName, toggleEmail, cardName, selectedPostsIds }) => {
	const [exportName, setExportName] = useState(getInitialExportName(cardName, filters?.fromDate, filters?.toDate))
	const [submitting, setSubmitting] = useState(false)
	const [error, setError] = useState(false)
	const [errorTextEmail, setErrorEmailText] = useState(false)
	const [errorTextFile, setErrorTextFile] = useState(false)
	const [emails, setEmails] = useState([`${user?.subUser?.email || user?.email}`])
	const [currentText, setCurrentText] = useState('')
	const [isLoad, setIsLoad] = useState(false)
	const [headerImage, setHeaderImage] = useState(constantsVox.EMAIL_EXPORT.HEADER_DEFAULT_URL)

	const classes = useStyles()

	let sources = filters?.sources

	useEffect(() => {
		load()
	}, [])

	const load = async () => {
		const userSettings = await api.get('/user-email-settings')

		if (userSettings.data) {
			const newHeader = userSettings.data.header_image === '' ? constantsVox.EMAIL_EXPORT.HEADER_DEFAULT_URL : userSettings.data.header_image

			setHeaderImage(newHeader)
		} else {
			setHeaderImage(constantsVox.EMAIL_EXPORT.HEADER_DEFAULT_URL)
		}
		setIsLoad(true)
	}

	const newEmailPostsExport = () => {
		const type = 'email'

		if (!checkErrors()) {
			api.post('/occurrences/export-posts', {
				cardId,
				filters,
				sources,
				exportName,
				wordCloudName,
				selectedPostsIds,
				type,
				emails,
				headerImage
			})
				.then(() => {
					setSubmitting(true)
					sendMessageToast('A exportação foi enviada por email.', 'INFO')
				})
				.catch((error) => {
					if (verifyServerResponseCanShowToast(error)) {
						sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
					}
				})
				.finally(() => {
					setSubmitting(false)
					toggleEmail()
				})
		}
	}

	const checkErrors = () => {
		let hasError = false

		if (exportName === '') {
			setErrorTextFile(true)
			hasError = true
		} else {
			setErrorTextFile(false)
		}
		return hasError
	}

	const handleInputEmail = (text) => {
		const dateDiff = new Date().getTime() - lastOnDeleteTime
		setError(false)
		setErrorEmailText(false)
		if (dateDiff > 100) {
			setCurrentText(text)
		}
	}

	const validateEmail = (email) => {
		const re = /\S+@\S+\.\S+/
		return re.test(email)
	}

	const handleOnChangeValue = (emails) => {
		let error = false

		emails.forEach((email) => {
			if (!validateEmail(email)) {
				error = true
			}
		})

		if (error) {
			setError(true)
			setErrorEmailText('Digite um email válido')
		} else {
			setError(false)
			setErrorEmailText(false)
			setEmails(emails)
		}
	}

	const handleFileInput = (imageFile, image64) => {
		setHeaderImage(image64[0])
	}

	return (
		<div style={{ margin: '25px' }}>
			<Grid container justifyContent='center' alignItems='center'>
				<Grid
					item
					xs={12}
					container
					justifyContent='center'
					alignItems='center'
					style={{
						fontFamily: 'Poppins',
						textAlign: 'center',
						letterSpacing: '4px',
						fontSize: '16px',
						fontWeight: '500',
						marginBottom: '30px'
					}}
				>
					EXPORTAR {selectedPostsIds.length > 0 ? selectedPostsIds.length : ''} {selectedPostsIds.length === 1 ? 'POSTAGEM' : 'POSTAGENS'}{' '}
					POR EMAIL
				</Grid>

				<Grid item xs={12} style={{ paddingLeft: '35px', paddingRight: '35px', height: '48px', marginTop: '25px', marginBottom: '30px' }}>
					<Autocomplete
						multiple
						freeSolo
						PaperComponent={CustomPaper}
						disableClearable
						value={emails}
						options={[]}
						inputValue={currentText}
						onInputChange={(_, value) => handleInputEmail(value)}
						onChange={(_, value) => handleOnChangeValue(value)}
						style={{ width: '100%' }}
						renderInput={(params) => (
							<div style={{ position: 'relative' }}>
								<CssTextField
									error={error}
									variant='standard'
									label={
										<div style={{ display: 'flex', alignItems: 'center', fontFamily: 'Poppins' }}>
											<PlusIcon
												size={19}
												style={{
													color: error === true ? '#d32f2f' : '#3d8cf8'
												}}
											/>
											<div style={{ marginLeft: '2px', marginTop: '-2px', fontFamily: 'Poppins', fontSize: '15px' }}>
												Adicionar emails para exportação
											</div>
										</div>
									}
									helperText={errorTextEmail}
									{...params}
								/>
							</div>
						)}
						renderTags={(value, getTagProps) =>
							value.map((option, index) => (
								<Chip
									size='medium'
									style={{ color: white, backgroundColor: blue, borderColor: blue }}
									key={index}
									variant='outlined'
									label={option}
									{...getTagProps({ index })}
									onDelete={(e) => {
										const props = getTagProps({ index })
										props.onDelete(e)
										lastOnDeleteTime = new Date().getTime()
									}}
								/>
							))
						}
					/>
					<div style={{ height: '1em', marginTop: '0.3em', color: grey }}>
						{currentText && currentText !== '' && 'Pressione Enter para adicionar o email'}
					</div>
				</Grid>

				<Grid item xs={12} style={{ paddingLeft: '35px', paddingRight: '35px', height: '48px', marginTop: '25px', marginBottom: '35px' }}>
					<CssTextField
						value={exportName}
						error={errorTextFile === true}
						disabled={submitting}
						helperText={errorTextFile ? 'Insira um nome para o arquivo' : ''}
						variant='standard'
						onChange={(e) => setExportName(e.target.value)}
						fullWidth
						label={
							<div style={{ display: 'flex', marginTop: '-6px', whiteSpace: 'nowrap' }}>
								<DocumentIcon
									size={17}
									style={{
										position: 'relative',
										transform: 'translate(0px, 5px)',
										color: errorTextFile === true ? '#d32f2f' : '#3d8cf8'
									}}
								/>
								<div style={{ marginLeft: '5px', marginTop: '2px', fontFamily: 'Poppins', fontSize: '15px' }}>Nome da exportação</div>
							</div>
						}
					/>
				</Grid>
				<div style={{ fontFamily: 'Poppins', color: 'rgba(0, 0, 0, 0.65)' }}>Selecionar uma imagem para o cabeçalho do email (600 x 100)</div>
				<Button
					style={{ marginLeft: '1em', minWidth: '10em' }}
					onClick={() => setHeaderImage(constantsVox.EMAIL_EXPORT.HEADER_DEFAULT_URL)}
					disabled={submitting}
					className={classes.button}
				>
					Usar Padrão
				</Button>
				<Grid
					item
					xs={12}
					style={{
						paddingLeft: '35px',
						paddingRight: '35px',
						marginBottom: '30px',
						height: '200px',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center'
					}}
				>
					{isLoad && (
						<ImageUploader
							buttonClassName='image-button-header'
							withIcon={false}
							withLabel={false}
							buttonText={<img src={`${headerImage}`} alt='logo' style={{ width: '600px', height: '100px' }} />}
							fileContainerStyle={{ backgroundColor: 'transparent', boxShadow: 'none', height: '25px' }}
							onChange={handleFileInput}
							singleImage
							imgExtension={['.jpg', '.png']}
							maxFileSize={5242880}
						/>
					)}
				</Grid>

				<Grid item xs={12} container justifyContent='center' alignItems='center'>
					<Button onClick={newEmailPostsExport} disabled={submitting} className={classes.button}>
						Exportar
					</Button>
				</Grid>
			</Grid>
		</div>
	)
}

const mapStateToProps = ({ store }) => ({
	user: store?.user
})

export default connect(mapStateToProps)(ExportEmail)
