import React, { useState, useEffect } from 'react'
import { Button, Card, Grid, Tabs, Tab, Divider, Select, MenuItem, Box, Zoom, Checkbox, Collapse } from '@mui/material'
import GraphTitle from 'components/GraphTitle/GraphTitle'
import Chart from 'react-apexcharts'

const getImageOptions = (data) => {
	let maxValue = 0

	if (data.selectedInterval.metrics.imageQuadrants) {
		let values = data.selectedInterval.metrics.imageQuadrants?.map((item) => item?.doc_count || 0)
		maxValue = Math.max(...values)
	}

	let imageOptions = {
		chart: {
			height: 400,
			type: 'heatmap'
		},
		plotOptions: {
			heatmap: {
				colorScale: {
					ranges: [
						{
							from: 1,
							to: maxValue,
							color: '#00E396',
							name: 'Imagens'
						}
					]
				}
			}
		},
		dataLabels: {
			enabled: false
		}
	}

	return imageOptions
}

const getVideoOptions = (data) => {
	let maxValue = 0

	if (data.selectedInterval.metrics.videoQuadrants) {
		let values = data.selectedInterval.metrics.videoQuadrants?.map((item) => item?.doc_count || 0)
		maxValue = Math.max(...values)
	}
	let videoOptions = {
		chart: {
			height: 400,
			type: 'heatmap'
		},
		plotOptions: {
			heatmap: {
				colorScale: {
					ranges: [
						{
							from: 1,
							to: maxValue,
							color: '#1d8cf8',
							name: 'Vídeos'
						}
					]
				}
			}
		},
		dataLabels: {
			enabled: false
		}
	}

	return videoOptions
}

const formatData = (data) => {
	let newData = []
	let categories = ['Esquerdo', 'Centro', 'Direita']

	for (let i = 0; i < categories.length; i++) {
		newData.push({
			x: categories[i],
			y: data[i]
		})
	}
	return newData
}

const formatImageSeries = (data) => {
	let quadrants = data.selectedInterval.metrics.imageQuadrants || [0, 0, 0, 0, 0, 0, 0, 0, 0]
	let imageSeries = [
		{
			name: 'Inferior',
			data: formatData([quadrants[6].doc_count, quadrants[7].doc_count, quadrants[8].doc_count])
		},
		{
			name: 'Centro',
			data: formatData([quadrants[3].doc_count, quadrants[4].doc_count, quadrants[5].doc_count])
		},
		{
			name: 'Superior',
			data: formatData([quadrants[0].doc_count, quadrants[1].doc_count, quadrants[2].doc_count])
		}
	]
	return imageSeries
}

const formatVideoSeries = (data) => {
	let quadrants = data.selectedInterval.metrics.videoQuadrants || [0, 0, 0, 0, 0, 0, 0, 0, 0]
	let videoSeries = [
		{
			name: 'Inferior',
			data: formatData([quadrants[6].doc_count, quadrants[7].doc_count, quadrants[8].doc_count])
		},
		{
			name: 'Centro',
			data: formatData([quadrants[3].doc_count, quadrants[4].doc_count, quadrants[5].doc_count])
		},
		{
			name: 'Superior',
			data: formatData([quadrants[0].doc_count, quadrants[1].doc_count, quadrants[2].doc_count])
		}
	]

	return videoSeries
}

const HeatMap = ({ data }) => {
	const [thisData, setThisData] = useState(data)

	useEffect(() => {
		setThisData(data)
	}, [data])

	return (
		<>
			{thisData.map((item) => (
				<Grid item xs={thisData.length > 1 ? 6 : 12} key={item.key}>
					<Card style={{ height: '100%', width: '100%', borderRadius: '25px' }}>
						<Grid container spacing={3} style={{ background: '#fbfbfb', height: '70px', borderBottom: '1px solid #f5f5f5' }}>
							<GraphTitle title={item.name} titleCentered={true} />
						</Grid>
						<Grid container spacing={3} style={{ padding: '1em' }}>
							<Grid item xs={6}>
								<Chart
									options={getImageOptions(item)}
									series={formatImageSeries(item)}
									type={'heatmap'}
									width={'100%'}
									height={'400'}
								/>
							</Grid>
							<Grid item xs={6}>
								<Chart
									options={getVideoOptions(item)}
									series={formatVideoSeries(item)}
									type={'heatmap'}
									width={'100%'}
									height={'400'}
								/>
							</Grid>
						</Grid>
					</Card>
				</Grid>
			))}
		</>
	)
}

export default HeatMap
