const GLOBAL_ACTION_TYPES = {
	SAVE_GLOBAL_FILTERS: 'SAVE_GLOBAL_FILTERS',
	RESET_GLOBAL_FILTERS: 'RESET_GLOBAL_FILTERS',
	CHANGE_REDIRECT_URL: 'CHANGE_REDIRECT_URL',
	SAVE_CURRENT_FOLDERS: 'SAVE_CURRENT_FOLDERS',
	SAVE_CURRENT_SELECTED_FOLDER_ID_ACTION: 'SAVE_CURRENT_SELECTED_FOLDER_ID_ACTION',
	CHANGE_SOURCES_TO_DEFAULT: 'CHANGE_SOURCES_TO_DEFAULT',
	UPDATE_ALL_COMPONENTS: 'UPDATE_ALL_COMPONENTS'
}

const saveGlobalFiltersTermAction = (newFilters) => ({
	type: GLOBAL_ACTION_TYPES.SAVE_GLOBAL_FILTERS,
	payload: { newFilters }
})

const resetGlobalFiltersTermAction = (keepSource) => {
	return {
		type: GLOBAL_ACTION_TYPES.RESET_GLOBAL_FILTERS,
		payload: { keepSource }
	}
}

const changeRedirectUrlAction = (redirectUrl) => ({
	type: GLOBAL_ACTION_TYPES.CHANGE_REDIRECT_URL,
	payload: { redirectUrl }
})

const saveCurrentFoldersAction = (folders) => ({
	type: GLOBAL_ACTION_TYPES.SAVE_CURRENT_FOLDERS,
	payload: { folders }
})
const changeSourcesToDefaultAction = (cardType) => ({
	type: GLOBAL_ACTION_TYPES.CHANGE_SOURCES_TO_DEFAULT,
	payload: { cardType }
})

const updateAllComponentsAction = (atThisTime) => ({
	type: GLOBAL_ACTION_TYPES.UPDATE_ALL_COMPONENTS,
	payload: { atThisTime }
})

// const saveCurrentSelectedFolderIdAction = (folderId) => ({
// 	type: GLOBAL_ACTION_TYPES.SAVE_CURRENT_SELECTED_FOLDER_ID_ACTION,
// 	payload: { folderId }
// })

export {
	GLOBAL_ACTION_TYPES,
	saveGlobalFiltersTermAction,
	resetGlobalFiltersTermAction,
	changeRedirectUrlAction,
	saveCurrentFoldersAction,
	changeSourcesToDefaultAction,
	updateAllComponentsAction
	// saveCurrentSelectedFolderIdAction
}
