import React, { memo } from 'react'
import { Card, CardContent, CardHeader, Grid, ButtonBase, styled, Zoom } from '@mui/material'

const blue = '#1d8cf8'

const CustomButtonBase = styled(ButtonBase)(() => ({
	width: '100%',
	borderRadius: '20px',
	border: '1px solid #fff',
	backgroundColor: '#fff',
	transition: 'box-shadow 0.3s ease',
	'&:hover': {
		boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)'
	},
	'& .MuiTouchRipple-root': {
		color: blue
	}
}))

const InferenceMediaCard = ({ mediaType = 'image', active = true, inference, delay = 0, setInferenceKey, setKey, setOpenMedia }) => {
	return (
		<Zoom in={active} unmountOnExit key={inference.key} style={{ transitionDelay: `${delay}ms` }}>
			<Grid item xs={4} spacing={1} container>
				<CustomButtonBase
					onClick={() => {
						setInferenceKey(inference.key)
						setKey(mediaType)
						setOpenMedia(true)
					}}
				>
					<Card
						style={{
							borderRadius: '20px',
							width: '100%',
							height: '100%',
							backgroundColor: 'transparent'
						}}
					>
						<Grid container>
							<Grid item>
								<div style={{ display: 'flex', alignItems: 'center', margin: '1em' }}>
									<img
										src={inference.logoUrl}
										alt={`${inference.key} logo`}
										style={{
											borderRadius: '50%',
											width: '55px',
											height: '55px',
											marginRight: '10px',
											objectFit: 'cover',
											border: '2px solid #1d8cf8'
										}}
										draggable='false'
									/>
									<CardHeader title={`${inference.name}`} />
								</div>
							</Grid>
						</Grid>
						<CardContent>
							<div
								style={{
									fontFamily: 'Poppins',
									fontSize: '1rem',
									minHeight: '115px',
									display: 'flex',
									flexDirection: 'column',
									alignContent: 'flex-start',
									textAlign: 'left'
								}}
							>
								<div
									style={{
										display: 'flex',
										justifyContent: 'flex-start',
										alignItems: 'center',
										marginTop: 'auto',
										flexWrap: 'wrap',
										width: '100%',
										boxSizing: 'border-box'
									}}
								>
									Total de {mediaType === 'image' ? 'imagens' : 'vídeos'}:{' '}
									<span
										style={{
											color: '#1d8cf8',
											marginLeft: '8px',
											fontWeight: 'bold',
											fontSize: '25px'
										}}
									>
										{inference.doc_count}
									</span>
								</div>
								<div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
									Total de Aparições em {mediaType === 'image' ? 'imagens' : 'vídeos'}:{' '}
									<span
										style={{
											color: '#1d8cf8',
											marginLeft: '8px',
											fontWeight: 'bold',
											fontSize: '25px'
										}}
									>
										{inference.show_count.value}
									</span>
								</div>
								{mediaType === 'video' && (
									<div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
										Total de Segundos:{' '}
										<span
											style={{
												color: '#1d8cf8',
												marginLeft: '8px',
												fontWeight: 'bold',
												fontSize: '25px'
											}}
										>
											{inference.total_time_seconds.value.toFixed(2)}
										</span>
									</div>
								)}
							</div>
						</CardContent>
					</Card>
				</CustomButtonBase>
			</Grid>
		</Zoom>
	)
}

export default memo(InferenceMediaCard)
