const colorsNetworks = ['rgba(186, 34, 59, 1)', 'rgba(0, 0, 0, 1)', 'rgba(230, 81, 44, 1)', 'rgba(43, 179, 174, 1)', 'rgba(44, 125, 230, 1)']

const slides = [
	{
		index: 1,
		text: 'Tempo de Tela por marca',
		animation: {},
		animationBehavior: { speed: 1 },
		extraData: {
			values: [1113, 424.27, 787.7, 910.73, 1044.63, 479.93, 154.2, 253.07, 5.13],
			colors: [
				'#e6194b',
				'#3cb44b',
				'#ffe119',
				'#4363d8',
				'#f58231',
				'#911eb4',
				'#46f0f0',
				'#f032e6',
				'#bcf60c',
				'#fabebe',
				'#008080',
				'#e6beff',
				'#9a6324',
				'#fffac8',
				'#800000',
				'#aaffc3',
				'#808000',
				'#ffd8b1',
				'#000075',
				'#808080',
				'#e3e1e1',
				'#000000'
			],
			labels: ['Adidas', 'Assist Card', 'Brb', 'Pixbet', 'Kwai', 'Mercado Livre', 'Zé delivery', 'Abc da construção', 'Tim']
		}
	},
	{
		index: 2,
		text: 'Pixbet - Tempo de Tela',
		animation: {},
		animationBehavior: { speed: 1 },
		extraData: {
			values: [2214, 1271, 1066, 1485, 420],
			colors: colorsNetworks,
			labels: ['Youtube', 'TikTok', 'Instagram', 'X', 'Facebook']
		}
		// comments : ['(TV) : R$ 2.157.457,56', '(Redes) : R$ 8.256.305,55']
	},
	{
		index: 3,
		text: 'Kwai - Tempo de Tela',
		animation: {},
		animationBehavior: { speed: 1 },
		extraData: {
			values: [942, 739, 690, 980, 136],
			colors: colorsNetworks,
			labels: ['Youtube', 'TikTok', 'Instagram', 'X', 'Facebook']
		}
		// comments : ['(TV) : R$ 1.165.784,08', '(Redes) : R$ 4.837.380,83']
	},
	{
		index: 4,
		text: 'BRB - Tempo de Tela',
		animation: {},
		animationBehavior: { speed: 1 },
		extraData: {
			values: [638, 507, 327, 1064, 191],
			colors: colorsNetworks,
			labels: ['Youtube', 'TikTok', 'Instagram', 'X', 'Facebook']
		}
		// comments : ['(TV) : R$ 912.188,20', '(Redes) : R$ 4.208.134,07']
	},
	{
		index: 5,
		text: 'Adidas - Tempo de Tela',
		animation: {},
		animationBehavior: { speed: 1 },
		extraData: {
			values: [981, 720, 502, 847, 298],
			colors: colorsNetworks,
			labels: ['Youtube', 'TikTok', 'Instagram', 'X', 'Facebook']
		}
		// comments : ['(TV) : R$ 329.296,15', '(Redes) : R$ 1.350.601,60']
	},
	{
		index: 6,
		text: 'Mercado Livre - Tempo de Tela',
		animation: {},
		animationBehavior: { speed: 1 },
		extraData: {
			values: [108, 390, 114, 1300, 65],
			colors: colorsNetworks,
			labels: ['Youtube', 'TikTok', 'Instagram', 'X', 'Facebook']
		}
		// comments : ['(TV) : R$ 658.592,31', '(Redes) : R$ 3.957.202,47']
	},
	{
		index: 7,
		text: 'Assist Card - Tempo de Tela',
		animation: {},
		animationBehavior: { speed: 1 },
		extraData: {
			values: [230, 259, 217, 689, 121],
			colors: colorsNetworks,
			labels: ['Youtube', 'TikTok', 'Instagram', 'X', 'Facebook']
		}
		// comments : ['(TV) : R$ 507.191,78', '(Redes) : R$ 2.510.047,50']
	},
	{
		index: 8,
		text: 'Abc da construção - Tempo de Tela',
		animation: {},
		animationBehavior: { speed: 1 },
		extraData: {
			values: [415, 235, 182, 554, 50],
			colors: colorsNetworks,
			labels: ['Youtube', 'TikTok', 'Instagram', 'X', 'Facebook']
		}
		// comments : ['(TV) : R$ 480.696,68', '(Redes) : R$ 2.509.040,02']
	},
	{
		index: 9,
		text: 'Zé delivery - Tempo de Tela',
		animation: {},
		animationBehavior: { speed: 1 },
		extraData: {
			values: [168, 102, 123, 384, 26],
			colors: colorsNetworks,
			labels: ['Youtube', 'TikTok', 'Instagram', 'X', 'Facebook']
		}
		// comments : ['(TV) : R$ 267.978,94', '(Redes) : R$ 1.320.200,31']
	},
	{
		index: 10,
		text: 'Tim - Tempo de Tela',
		animation: {},
		animationBehavior: { speed: 1 },
		extraData: {
			values: [278, 141, 154, 304, 103],
			colors: colorsNetworks,
			labels: ['Youtube', 'TikTok', 'Instagram', 'X', 'Facebook']
		}
		// comments : ['(TV) : R$ 1.116.578,91', '(Redes) : R$ 4.487.800,35']
	}
]

export { slides }
