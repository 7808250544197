import React, { memo, useState, useEffect } from 'react'
import { Grid, TextField, Checkbox, Collapse, Tooltip, IconButton, styled } from '@mui/material'
import { TwitterIcon, BlueskyIcon, FacebookIcon, InstagramIcon, TiktokIcon, YoutubeIcon, AtSignIcon, InfoIcon } from 'utils/SystemIcons'

const blue = 'rgba(29, 138, 248, 100%)'

const CssTextField = styled(TextField)({
	'& .MuiInputBase-root': {
		color: blue,
		fontFamily: 'Poppins',
		'& input': {
			textAlign: 'left'
		}
	},
	'& label.Mui-focused': {
		color: blue
	}
})

const SourceNotIncluded = ({ name }) => (
	<Tooltip title={`Seu plano não inclui esta fonte de dados (${name})`} placement='bottom' enterDelay={100} enterNextDelay={100}>
		<IconButton style={{ padding: '5px', marginLeft: '0.3em' }}>
			<InfoIcon size={20} style={{ color: '#1d8cf8', cursor: 'pointer' }} />
		</IconButton>
	</Tooltip>
)

const AtSignDefault = () => <AtSignIcon size={20} style={{ color: '#ac60f7', marginRight: '7px' }} />

const SocialNetworksNewInsightProfile = ({ formState, onChangeForm, fieldErrors, statusType, sourceInfo, isActive, hasSource }) => {
	const [thisFieldErrors, setThisFieldErrors] = useState(fieldErrors)

	const handleChangeCheckAccount = (value, fieldname) => {
		onChangeForm({ [fieldname]: value })
	}

	useEffect(() => {
		setThisFieldErrors(fieldErrors)
	}, [fieldErrors])

	useEffect(() => {
		if (statusType === 'edit') {
			const twitterValue = sourceInfo.twitterAccount
			const blueskyValue = sourceInfo.blueskyAccount
			const facebookValue = sourceInfo.facebookPage
			const instagramValue = sourceInfo.instagramAccount
			const tiktokValue = sourceInfo.tiktokAccount
			const youtubeValue = sourceInfo.youtubeChannel
			const hasTwitterSearch = !!twitterValue && hasSource.twitter
			const hasBlueskySearch = !!blueskyValue && hasSource.bluesky
			const hasFacebookSearch = !!facebookValue && hasSource.facebook
			const hasInstagramSearch = !!instagramValue && hasSource.instagram
			const hasTiktokSearch = !!tiktokValue && hasSource.tiktok
			const hasYoutubeSearch = !!youtubeValue && hasSource.youtube

			onChangeForm({
				twitterAccount: twitterValue,
				blueskyAccount: blueskyValue,
				facebookPage: facebookValue,
				instagramAccount: instagramValue,
				tiktokAccount: tiktokValue,
				youtubeChannel: youtubeValue,
				hasTwitterSearch,
				hasBlueskySearch,
				hasFacebookSearch,
				hasInstagramSearch,
				hasTiktokSearch,
				hasYoutubeSearch
			})
		}
	}, [])

	return (
		<Grid container spacing={3}>
			<Grid item xs={12} className='mb-1i mt-1i'>
				<span className='fs-1-5' style={{ color: '#ac60f7 ' }}>
					Fontes que você deseja monitorar
				</span>
			</Grid>
			{thisFieldErrors?.verifySources?.error && (
				<Grid item xs={12} className='ta-center color-red'>
					{thisFieldErrors?.verifySources?.error}
				</Grid>
			)}
			{!isActive && statusType === 'edit' && (
				<Grid item xs={12}>
					Não é possível alterar as contas de um cartão pausado.
				</Grid>
			)}
			<Grid container item xs={12} spacing={1}>
				<Grid item xs={12} style={{ marginLeft: '-10px', alignItems: 'center', display: 'flex' }}>
					<Checkbox
						color='primary'
						checked={formState.hasTwitterSearch}
						onChange={(_, value) => handleChangeCheckAccount(value, 'hasTwitterSearch')}
						disabled={!hasSource.twitter || (!isActive && statusType === 'edit')}
					/>
					<TwitterIcon size={25} style={{ color: '#ac60f7', marginRight: '0.3em' }} />
					Twitter
					{!hasSource.twitter && <SourceNotIncluded name='Twitter' />}
				</Grid>
				<Grid item xs={12}>
					<Collapse in={formState.hasTwitterSearch}>
						<CssTextField
							variant='standard'
							label={
								<div style={{ display: 'flex' }}>
									<AtSignDefault />
									Conta do Twitter
								</div>
							}
							inputProps={{
								maxLength: 50
							}}
							size='small'
							className='d-flex'
							style={{ color: '#ac60f7', borderRadius: '4px' }}
							value={formState.twitterAccount}
							error={!!thisFieldErrors?.twitterAccount?.error}
							helperText={thisFieldErrors?.twitterAccount?.error}
							onChange={(e) => onChangeForm({ twitterAccount: e.target.value })}
							placeholder='@josesilva'
							disabled={!formState.hasTwitterSearch || (!isActive && statusType === 'edit')}
						/>
					</Collapse>
				</Grid>
			</Grid>

			<Grid container item xs={12} spacing={1}>
				<Grid item xs={12} style={{ marginLeft: '-10px', alignItems: 'center', display: 'flex' }}>
					<Checkbox
						color='primary'
						checked={formState.hasBlueskySearch}
						onChange={(_, value) => handleChangeCheckAccount(value, 'hasBlueskySearch')}
						disabled={!hasSource.bluesky || (!isActive && statusType === 'edit')}
					/>
					<BlueskyIcon size={25} style={{ color: '#ac60f7', marginRight: '0.3em' }} />
					Bluesky
					{!hasSource.bluesky && <SourceNotIncluded name='Bluesky' />}
				</Grid>
				<Grid item xs={12}>
					<Collapse in={formState.hasBlueskySearch}>
						<CssTextField
							variant='standard'
							label={
								<div style={{ display: 'flex' }}>
									<AtSignDefault />
									Conta do Bluesky
								</div>
							}
							inputProps={{
								maxLength: 50
							}}
							size='small'
							className='d-flex'
							style={{ color: '#ac60f7', borderRadius: '4px' }}
							value={formState.blueskyAccount}
							error={!!thisFieldErrors?.blueskyAccount?.error}
							helperText={thisFieldErrors?.blueskyAccount?.error}
							onChange={(e) => onChangeForm({ blueskyAccount: e.target.value })}
							placeholder='@josesilva'
							disabled={!formState.hasBlueskySearch || (!isActive && statusType === 'edit')}
						/>
					</Collapse>
				</Grid>
			</Grid>

			<Grid container item xs={12} spacing={1}>
				<Grid item xs={12} style={{ marginLeft: '-10px', alignItems: 'center', display: 'flex' }}>
					<Checkbox
						color='primary'
						checked={formState.hasFacebookSearch}
						onChange={(_, value) => handleChangeCheckAccount(value, 'hasFacebookSearch')}
						disabled={!hasSource.facebook || (!isActive && statusType === 'edit')}
					/>
					<FacebookIcon size={25} style={{ color: '#ac60f7', marginRight: '0.3em' }} />
					Facebook
					{!hasSource.facebook && <SourceNotIncluded name='Facebook' />}
				</Grid>
				<Grid item xs={12}>
					<Collapse in={formState.hasFacebookSearch}>
						<CssTextField
							variant='standard'
							label={
								<div style={{ display: 'flex' }}>
									<AtSignDefault />
									Página do Facebook
								</div>
							}
							inputProps={{
								maxLength: 50
							}}
							size='small'
							className='d-flex'
							style={{ color: '#ac60f7', borderRadius: '4px' }}
							value={formState.facebookPage}
							error={!!thisFieldErrors?.facebookPage?.error}
							helperText={thisFieldErrors?.facebookPage?.error}
							onChange={(e) => onChangeForm({ facebookPage: e.target.value })}
							placeholder='@josesilva'
							disabled={!formState.hasFacebookSearch || (!isActive && statusType === 'edit')}
						/>
					</Collapse>
				</Grid>
			</Grid>

			<Grid container item xs={12} spacing={1}>
				<Grid item xs={12} style={{ marginLeft: '-10px', alignItems: 'center', display: 'flex' }}>
					<Checkbox
						color='primary'
						checked={formState.hasInstagramSearch}
						onChange={(_, value) => handleChangeCheckAccount(value, 'hasInstagramSearch')}
						disabled={!hasSource.instagram || (!isActive && statusType === 'edit')}
					/>
					<InstagramIcon size={25} style={{ color: '#ac60f7', marginRight: '0.3em' }} />
					Instagram
					{!hasSource.instagram && <SourceNotIncluded name='Instagram' />}
				</Grid>
				<Grid item xs={12}>
					<Collapse in={formState.hasInstagramSearch}>
						<CssTextField
							variant='standard'
							label={
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<AtSignDefault />
									Conta do Instagram
								</div>
							}
							inputProps={{
								maxLength: 50
							}}
							size='small'
							className='d-flex'
							style={{ color: '#ac60f7', borderRadius: '4px' }}
							value={formState.instagramAccount}
							error={!!thisFieldErrors?.instagramAccount?.error}
							helperText={thisFieldErrors?.instagramAccount?.error}
							onChange={(e) => onChangeForm({ instagramAccount: e.target.value })}
							placeholder='@josesilva'
							disabled={!formState.hasInstagramSearch || (!isActive && statusType === 'edit')}
						/>
					</Collapse>
				</Grid>
			</Grid>

			<Grid container item xs={12} spacing={1}>
				<Grid item xs={12} style={{ marginLeft: '-10px', alignItems: 'center', display: 'flex' }}>
					<Checkbox
						color='primary'
						checked={formState.hasTiktokSearch}
						onChange={(_, value) => handleChangeCheckAccount(value, 'hasTiktokSearch')}
						disabled={!hasSource.tiktok || (!isActive && statusType === 'edit')}
					/>
					<TiktokIcon size={25} style={{ color: '#ac60f7', marginRight: '0.3em' }} />
					Tiktok
					{!hasSource.tiktok && <SourceNotIncluded name='Tiktok' />}
				</Grid>
				<Grid item xs={12}>
					<Collapse in={formState.hasTiktokSearch}>
						<CssTextField
							variant='standard'
							label={
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<AtSignDefault />
									Conta do Tiktok
								</div>
							}
							inputProps={{
								maxLength: 50
							}}
							size='small'
							className='d-flex'
							style={{ color: '#ac60f7', borderRadius: '4px' }}
							value={formState.tiktokAccount}
							error={!!thisFieldErrors?.tiktokAccount?.error}
							helperText={thisFieldErrors?.tiktokAccount?.error}
							onChange={(e) => onChangeForm({ tiktokAccount: e.target.value })}
							placeholder='@josesilva'
							disabled={!formState.hasTiktokSearch || (!isActive && statusType === 'edit')}
						/>
					</Collapse>
				</Grid>
			</Grid>

			<Grid container item xs={12} spacing={1}>
				<Grid item xs={12} style={{ marginLeft: '-10px', alignItems: 'center', display: 'flex' }}>
					<Checkbox
						color='primary'
						checked={formState.hasYoutubeSearch}
						onChange={(_, value) => handleChangeCheckAccount(value, 'hasYoutubeSearch')}
						disabled={!hasSource.youtube || (!isActive && statusType === 'edit')}
					/>
					<YoutubeIcon size={25} style={{ color: '#ac60f7', marginRight: '0.3em' }} />
					Youtube
					{!hasSource.youtube && <SourceNotIncluded name='Youtube' />}
				</Grid>
				<Grid item xs={12}>
					<Collapse in={formState.hasYoutubeSearch}>
						<CssTextField
							variant='standard'
							label={
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<AtSignDefault />
									Canal do Youtube
								</div>
							}
							inputProps={{
								maxLength: 50
							}}
							size='small'
							className='d-flex'
							style={{ color: '#ac60f7', borderRadius: '4px' }}
							value={formState.youtubeChannel}
							error={!!thisFieldErrors?.youtubeChannel?.error}
							helperText={thisFieldErrors?.youtubeChannel?.error}
							onChange={(e) => onChangeForm({ youtubeChannel: e.target.value })}
							placeholder='@josesilva'
							disabled={!formState.hasYoutubeSearch || (!isActive && statusType === 'edit')}
						/>
					</Collapse>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default memo(SocialNetworksNewInsightProfile)
