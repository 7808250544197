import React, { useState, useEffect, useRef, memo } from 'react'
import { Grid, Button, TextField, styled, Divider } from '@mui/material'
import { HistoryIcon, ChevronLeftIcon, SearchIcon, PlusIcon, ChevronRightIcon } from 'utils/SystemIcons'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import { verifyServerResponseCanShowToast } from 'utils/generalFunctions'
import api from 'service/service'
import LoadingBar from 'components/LoadingBar/LoadingBar'
import GraphTitle from 'components/GraphTitle/GraphTitle'
import UpdatePostsHistoryItem from './components/UpdatePostsHistoryItem/UpdatePostsHistoryItem'
import VoxAiHistoryItem from './components/VoxAiHistoryItem/VoxAiHistoryItem'
import ExportPostsOccurrencesHistoryItem from './components/ExportPostsOccurrencesHistoryItem/ExportPostsOccurrencesHistoryItem'
import TopicAnalysisHistoryItem from './components/TopicAnalysisHistoryItem/TopicAnalysisHistoryItem'
import AddOccurrenceHistoryItem from './components/AddOccurrenceHistoryItem/AddOccurrenceHistoryItem'
import SharingDashboardHistoryItem from './components/SharingDashboardHistoryItem/SharingDashboardHistoryItem'
import AddAurasightConfigDownloadHistoryItem from './components/AddAurasightConfigDownloadHistoryItem/AddAurasightConfigDownloadHistoryItem'

const blue = 'rgba(29, 138, 248, 100%)'
const grey = 'rgba(184, 184, 184, 0.1)'

const CssTextField = styled(TextField)({
	'& .MuiInputBase-root': {
		color: blue,
		fontFamily: 'Poppins',
		'& input': {
			textAlign: 'left'
		}
	},
	'& label.Mui-focused': {
		color: blue
	},
	'& .MuiInput-underline:after': {
		borderBottomColor: blue
	},
	'& .MuiOutlinedInput-root': {
		'& fieldset': {
			borderColor: grey
		},
		'&:hover fieldset': {
			borderColor: grey
		},
		'&.Mui-focused fieldset': {
			borderColor: blue
		}
	}
})

const renderItemByService = (item, serviceName, toggleOpenHistory, load, cardId, applyHistory, isShareUrl) => {
	let itemToRender = <></>
	switch (serviceName) {
		case 'updatePosts':
			itemToRender = <UpdatePostsHistoryItem key={item?.id} item={item} />
			break
		case 'voxAi':
			itemToRender = (
				<VoxAiHistoryItem
					key={item?.id}
					item={item}
					toggleOpenHistory={toggleOpenHistory}
					cardId={cardId}
					applyHistory={applyHistory}
					isShareUrl={isShareUrl}
				/>
			)
			break
		case 'exportPostsOccurrences':
			itemToRender = <ExportPostsOccurrencesHistoryItem key={item?.id} item={item} />
			break
		case 'topicAnalysis':
			itemToRender = (
				<TopicAnalysisHistoryItem
					key={item?.id}
					item={item}
					toggleOpenHistory={toggleOpenHistory}
					reload={load}
					cardId={cardId}
					applyHistory={applyHistory}
					isShareUrl={isShareUrl}
				/>
			)
			break
		case 'addOccurrence':
			itemToRender = <AddOccurrenceHistoryItem key={item?.id} item={item} />
			break
		case 'addAurasightConfigDownload':
			itemToRender = <AddAurasightConfigDownloadHistoryItem key={item?.id} item={item} />
			break
		case 'sharingDashboard':
			itemToRender = <SharingDashboardHistoryItem key={item?.id} item={item} reload={load} />
			break
		default:
			break
	}

	return itemToRender
}

const getServiceFormattedName = (serviceName) => {
	let serviceNameFormatted = ''
	switch (serviceName) {
		case 'updatePosts':
			serviceNameFormatted = 'Atualização de Posts'
			break
		case 'voxAi':
			serviceNameFormatted = 'Inteligência Artificial'
			break
		case 'exportPostsOccurrences':
			serviceNameFormatted = 'Exportação de Posts'
			break
		case 'topicAnalysis':
			serviceNameFormatted = 'Análise de Tópicos'
			break
		case 'addOccurrence':
			serviceNameFormatted = 'Adicionar Nova Postagem'
			break
		case 'addAurasightConfigDownload':
			serviceNameFormatted = 'Configuração do Download'
			break
		default:
			break
	}

	return serviceNameFormatted
}

const ServiceHistoryContent = ({
	cardId,
	cardType,
	cardName,
	toggleOpenHistory,
	serviceName,
	showFilterBar,
	sources,
	height,
	applyHistory,
	customTitle,
	rightButton,
	isShareUrl = false
}) => {
	const [loading, setLoading] = useState(false)
	const [page, setPage] = useState(0)
	const [nameFilter, setNameFilter] = useState('')
	const [feed, setFeed] = useState([])
	const [isEndOfResults, setIsEndOfResults] = useState(false)
	const [emptyMessage, setEmptyMessage] = useState('')
	const [isFirstTime, setIsFirstTime] = useState(true)

	const loadingBarRef = useRef()

	useEffect(() => {
		load()
	}, [page])

	useEffect(() => {
		if (nameFilter === '' && !isFirstTime) {
			load(true)
		}
	}, [nameFilter])

	useEffect(() => {
		loadingBarRef.current.load(loading)
	}, [loading])

	const load = (isSearch = false, isOutsideLoad = false) => {
		let thisPage = page
		let thisFeed = feed

		setIsFirstTime(false)

		if (isSearch || isOutsideLoad) {
			setIsEndOfResults(false)
			thisPage = 0
			thisFeed = []
			setPage(thisPage)
			setFeed(thisFeed)
		}

		setLoading(true)
		api.get(
			`/history/get/${serviceName}/${cardType}/${cardId}/${thisPage}?nameFilter=${encodeURIComponent(
				nameFilter
			)}&sources=${sources}&cardType=${cardType}`
		)
			.then((response) => {
				if (response?.data?.toast) {
					sendMessageToast(response?.data?.toast?.message, response?.data?.toast?.type)
				}
				if (response.data.length < 10) {
					setIsEndOfResults(true)
				} else {
					setIsEndOfResults(false)
				}

				let newFeed = [...thisFeed, ...response.data]

				checkEmptyMessage()
				setFeed(newFeed)
			})
			.catch((error) => {
				if (verifyServerResponseCanShowToast(error)) {
					sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
				}
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const checkEmptyMessage = () => {
		if (feed.length === 0) {
			if (nameFilter !== '') {
				setEmptyMessage(`Nenhuma análise encontrada com o nome ${nameFilter}`)
			} else {
				setEmptyMessage('Sem histórico para esse cartão')
			}
		} else {
			setEmptyMessage('')
		}
	}

	const onEnterPress = () => {
		load(true)
	}

	const nextPage = () => {
		setPage(page + 1)
	}

	return (
		<Grid
			container
			style={{
				height: '100%',
				paddingRight: rightButton ? '0em' : '1em',
				paddingLeft: rightButton ? '5em' : '0em'
			}}
		>
			{!rightButton && (
				<Grid
					item
					xs={1}
					style={{
						height: '100%'
					}}
				>
					<Button
						id='btn-toggle-history'
						color='primary'
						onClick={toggleOpenHistory}
						style={{
							height: '100%',
							width: '40px',
							minWidth: '40px',
							borderRadius: '0px 0px 0px 0px',
							borderRight: '1px solid rgba(200,200,200, 0.2)'
						}}
					>
						<ChevronLeftIcon size={25} style={{ color: '#1d8cf8' }} />
					</Button>
				</Grid>
			)}
			<Grid
				item
				xs={11}
				style={{
					height: '100%'
				}}
			>
				<GraphTitle
					id='span-history'
					titleCentered
					title={customTitle || `Histórico de ${getServiceFormattedName(serviceName)} para o cartão ${cardName}`}
					icon={<HistoryIcon size={25} style={{ color: '#1d8cf8' }} />}
				/>
				{showFilterBar && (
					<Grid container item xs={12}>
						<Grid item xs={6} style={{ paddingLeft: '20px', height: '48px', marginTop: '4px', marginBottom: '1.5em' }}>
							<CssTextField
								value={nameFilter}
								variant='standard'
								onChange={(e) => setNameFilter(e.target.value)}
								fullWidth
								label={
									<div style={{ display: 'flex', marginTop: '-6px', whiteSpace: 'nowrap' }}>
										<SearchIcon size={17} style={{ position: 'relative', transform: 'translate(0px, 5px)', color: '#3d8cf8' }} />

										<div style={{ marginLeft: '5px', marginTop: '2px', fontFamily: 'Poppins', fontSize: '15px' }}>
											Buscar por nome
										</div>
									</div>
								}
								onKeyDown={(ev) => {
									if (ev.key === 'Enter') {
										onEnterPress()
										ev.preventDefault()
									}
								}}
							/>
							{!!nameFilter && (
								<div
									style={{
										fontFamily: 'Roboto',
										textAlign: 'start',
										fontSize: '12px',
										color: '#1d8cf8',
										marginTop: '3px'
									}}
								>
									Aperte Enter para buscar
								</div>
							)}
						</Grid>
						<Divider
							style={{
								backgroundColor: 'rgba(0, 0, 0, 0.01)',
								height: '0.5px',
								width: '93%',
								marginTop: '-25px',
								marginLeft: '20px',
								marginRight: '20px'
							}}
						/>
					</Grid>
				)}

				<Grid
					item
					xs={12}
					style={{
						width: '93%',
						marginTop: showFilterBar ? '-25px' : '10px',
						marginLeft: '20px',
						marginRight: '20px'
					}}
				>
					<LoadingBar ref={loadingBarRef} />
				</Grid>
				{!showFilterBar && (
					<Divider
						style={{
							backgroundColor: 'rgba(0, 0, 0, 0.01)',
							height: '0.5px',
							width: '93%',
							marginTop: '0px',
							marginLeft: '20px',
							marginRight: '20px'
						}}
					/>
				)}
				<Grid item xs={12} style={{ maxHeight: '80%', overflow: 'auto', marginTop: '1.5em', height }}>
					{feed.length > 0 &&
						feed.map((item) => renderItemByService(item, serviceName, toggleOpenHistory, load, cardId, applyHistory, isShareUrl))}

					{feed.length > 0 && !isEndOfResults && !loading && (
						<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
							<Button fullWidth onClick={nextPage}>
								<PlusIcon size={30} style={{ position: 'relative', transform: 'translate(0px, 0px)', color: '#3d8cf8' }} />
							</Button>
						</div>
					)}

					{feed.length === 0 && !loading && (
						<div
							style={{
								marginTop: '50px',
								marginLeft: '-50px',
								display: 'flex',
								justifyContent: 'center',
								fontFamily: 'Poppins',
								fontSize: '24px',
								color: '#c2c2c2'
							}}
						>
							{emptyMessage}
						</div>
					)}
					<div style={{ minHeight: '50px' }} />
				</Grid>
			</Grid>
			{rightButton && (
				<Grid
					item
					xs={1}
					style={{
						height: '100%',
						display: 'flex',
						justifyContent: 'flex-end'
					}}
				>
					<Button
						id='btn-toggle-history'
						color='primary'
						onClick={toggleOpenHistory}
						style={{
							height: '100%',
							width: '40px',
							minWidth: '40px',
							borderRadius: '0px 0px 0px 0px',
							borderLeft: '1px solid rgba(200,200,200, 0.2)'
						}}
					>
						<ChevronRightIcon size={25} style={{ color: '#1d8cf8' }} />
					</Button>
				</Grid>
			)}
		</Grid>
	)
}

export default memo(ServiceHistoryContent)
