import React, { useState, useEffect, memo } from 'react'
import { Grid, Divider, Collapse, Slide, Tooltip, Button } from '@mui/material'
import moment from 'moment-timezone'
import { connect } from 'react-redux'
import SourceSelectorGlobalFilters from '../SourceSelectorGlobalFilters/SourceSelectorGlobalFilters'
import DateIntervalSelectorGlobal from '../DateIntervalSelectorGlobal/DateIntervalSelectorGlobal'
import SearchBarGlobalFilters from '../SearchBarGlobalFilters/SearchBarGlobalFilters'
import GlobalFilters from 'components/GlobalFilters/GlobalFilters'
import DialogContents from 'components/DialogInformation/DialogContents'
import { saveGlobalFiltersTermAction, updateAllComponentsAction } from 'store/redux/actions/global.actions'
import { useQueryClient } from 'react-query'
import { RefreshIconFa } from 'utils/SystemIcons'

const isFirefox = typeof InstallTrigger !== 'undefined'

const floatingStyle = {
	width: '100vw',
	position: 'fixed',
	top: '31px',
	left: '0px',
	paddingLeft: '1em',
	paddingRight: '1em',
	marginTop: '19px',
	zIndex: '1200',
	marginBottom: '35px',
	borderRadius: '0px',
	backdropFilter: 'blur(10px)',
	backgroundColor: `${isFirefox ? 'rgba(245, 245, 245, 1)' : 'rgba(245, 245, 245, 0.6)'}`,
	border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6))'}`,
	boxShadow: '0 1px 5px 0 rgba(200, 200, 200, 0.7)'
}

const regularStyle = { marginTop: '19px', marginBottom: '35px' }

const GlobalFilterBar = ({
	globalFiltersRedux,
	isOverview = false,
	disableSearch = false,
	disableSourceSelector = false,
	saveGlobalFiltersDispatch,
	updateAllComponentsDispatch,
	cardTypeRedux
}) => {
	const [openDateInfo, setOpenDateInfo] = useState(false)
	const [isFloating, setIsFloating] = useState(false)
	const [slide, setSlide] = useState(true)
	const queryClient = useQueryClient()

	const handleScroll = () => {
		const scrollPosition = window.scrollY // => scroll position

		if (scrollPosition > 100) {
			setIsFloating(true)
			setSlide(true)
		} else if (scrollPosition < 69) {
			setSlide(true)
			setIsFloating(false)
		} else {
			setSlide(false)
		}
	}

	useEffect(() => {
		handleScroll()
		window.addEventListener('scroll', handleScroll)
		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [])

	const onSourceChange = (newSources) => {
		saveGlobalFiltersDispatch({ ...globalFiltersRedux, sources: newSources })
	}

	const onApplyDateSelector = (fromDate, toDate, fromHour, toHour) => {
		if (moment(toDate).diff(fromDate, 'months') > 12) {
			setOpenDateInfo(true)
		} else {
			saveGlobalFiltersDispatch({
				...globalFiltersRedux,
				fromDate,
				toDate,
				fromHour,
				toHour
			})
		}
	}

	const handleCloseDateInfo = () => {
		setOpenDateInfo(false)
	}

	const onSearchEnterOrEmpty = (newText) => {
		saveGlobalFiltersDispatch({ ...globalFiltersRedux, contentTextSearch: newText })
	}

	const refetchAllQueries = () => {
		updateAllComponentsDispatch(new Date().getTime())
		queryClient.invalidateQueries()
	}

	return (
		<>
			{isFloating && <div style={{ height: '48px', marginTop: '19px', marginBottom: '35px' }}></div>}
			<Slide direction='down' in={slide}>
				<Grid item xs={12} style={isFloating ? floatingStyle : regularStyle} container>
					<DialogContents
						open={openDateInfo}
						handleClose={handleCloseDateInfo}
						title='Informação'
						text='Não é possível filtrar períodos maiores que 12 meses. Caso necessite de períodos maiores que 12 meses entre em contato com nosso suporte.'
					/>
					<Grid item xs={4}>
						{!disableSourceSelector && (
							<SourceSelectorGlobalFilters
								onSourceChange={onSourceChange}
								sourcesControlled={globalFiltersRedux.sources}
								isOverview={isOverview}
								singleSourceSelection={cardTypeRedux === 'ACCOUNT_MANAGEMENT'}
							/>
						)}
					</Grid>
					<Grid item xs={4}>
						<Collapse in={!disableSearch}>
							<SearchBarGlobalFilters
								onSearchEnterOrEmpty={onSearchEnterOrEmpty}
								contentTextSearch={globalFiltersRedux.contentTextSearch}
								isMainSearch={true}
							/>
						</Collapse>
					</Grid>
					<Grid item xs={4} container justifyContent='flex-end'>
						{cardTypeRedux !== 'ACCOUNT_MANAGEMENT' && (
							<Tooltip title='Atualizar' placement='left' arrow>
								<Button onClick={refetchAllQueries} style={{ minWidth: '3.5em', maxWidth: '3.5em', marginRight: '1.5em' }}>
									<RefreshIconFa size={15} style={{ color: '#1d8cf8' }} />
								</Button>
							</Tooltip>
						)}
						<DateIntervalSelectorGlobal
							onApply={onApplyDateSelector}
							fromDate={globalFiltersRedux.fromDate}
							toDate={globalFiltersRedux.toDate}
							fromHour={globalFiltersRedux.fromHour}
							toHour={globalFiltersRedux.toHour}
						/>
						<GlobalFilters />
					</Grid>
					<Divider style={{ backgroundColor: 'rgba(0, 0, 0, 0.01)', height: '0.5px', width: '100%', marginTop: '-2px' }} />
				</Grid>
			</Slide>
		</>
	)
}
const mapDispatchToProps = (dispatch) => ({
	saveGlobalFiltersDispatch: (newFilters) => dispatch(saveGlobalFiltersTermAction(newFilters)),
	updateAllComponentsDispatch: (time) => dispatch(updateAllComponentsAction(time))
})

const mapStateToProps = ({ store }) => ({
	globalFiltersRedux: store.global.filters,
	cardTypeRedux: store.card.cardType
})

export default connect(mapStateToProps, mapDispatchToProps)(memo(GlobalFilterBar))
