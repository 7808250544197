import React, { useState, useEffect, memo } from 'react'
import { Button, Dialog, DialogContent } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { connect } from 'react-redux'
import { OverviewIcon } from 'utils/SystemIcons'
import Summary from 'components/Summary/Summary'

const blue = 'rgba(29, 138, 248, 100%)'

const isFirefox = typeof InstallTrigger !== 'undefined'

const useStyles = makeStyles({
	root: {
		color: blue,
		font: 'Poppins',
		fontWeight: '15'
	},
	label: {
		textTransform: 'capitalize'
	},
	button: {
		fontFamily: 'Poppins',
		border: '1px solid rgba(29, 138, 248, 25%)',
		boxShadow: 'none',
		color: blue,
		borderRadius: '100px',
		background: 'transparent',
		'&:hover': {
			border: '1px solid rgba(29, 138, 248, 90%)',
			backgroundColor: 'rgba(29, 138, 248, 5%)',
			boxShadow: '0px 0px -10px -17px rgba(0,0,0,255)'
		}
	},
	buttonNoborder: {
		fontFamily: 'Poppins',
		border: '1px solid rgba(29, 138, 248, 0%)',
		boxShadow: 'none',
		color: blue,
		borderRadius: '100px',
		background: 'transparent',
		'&:hover': {
			border: '1px solid rgba(29, 138, 248, 10%)',
			backgroundColor: 'rgba(29, 138, 248, 5%)',
			boxShadow: '0px 0px -10px -17px rgba(0,0,0,255)'
		}
	},
	backDrop: {
		backdropFilter: 'blur(3px)',
		backgroundColor: 'rgba(220, 220, 220, 0.4)'
	}
})

const InspectOverviewButton = ({
	cards,
	border = true,
	isFetchingData,
	filters,
	isTwitter,
	isBluesky,
	isFacebook,
	isInstagram,
	isTiktok,
	isYoutube,
	isNews,
	disabled
}) => {
	const [open, setOpen] = useState(false)
	const [thisFilters, setThisFilters] = useState(filters)

	useEffect(() => {
		setThisFilters(filters)
	}, [filters])

	const classes = useStyles()

	const handleClickOpen = () => {
		if (isTwitter === true) {
			setThisFilters({ ...thisFilters, sources: ['twitter'] })
		} else if (isBluesky) {
			setThisFilters({ ...thisFilters, sources: ['bluesky'] })
		} else if (isFacebook === true) {
			setThisFilters({ ...thisFilters, sources: ['facebook'] })
		} else if (isInstagram === true) {
			setThisFilters({ ...thisFilters, sources: ['instagram'] })
		} else if (isTiktok === true) {
			setThisFilters({ ...thisFilters, sources: ['tiktok'] })
		} else if (isYoutube === true) {
			setThisFilters({ ...thisFilters, sources: ['youtube'] })
		} else if (isNews === true) {
			setThisFilters({ ...thisFilters, sources: ['news'] })
		}
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	return (
		<>
			<Button
				id='btn-open-summary'
				disableElevation
				variant='outlined'
				onClick={() => handleClickOpen()}
				style={{ outline: 'none' }}
				className={border ? classes.button : classes.buttonNoborder}
				disabled={disabled}
			>
				<OverviewIcon size={16} style={{ color: blue, marginRight: '5px' }} />
				Ver Resumo
			</Button>

			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
				fullWidth={true}
				maxWidth='xl'
				PaperProps={{
					style: {
						borderRadius: '20px',
						backdropFilter: 'blur(10px)',
						backgroundColor: `${isFirefox ? 'rgba(245, 245, 245, 1)' : 'rgba(255, 255, 255, 0.4)'}`,
						border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
						boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )',
						maxWidth: '80%'
					}
				}}
				BackdropProps={{
					classes: {
						root: classes.backDrop
					}
				}}
			>
				<DialogContent style={{ overflowY: 'scroll', marginBottom: '10px' }}>
					<Summary cards={cards} isFetchingData={isFetchingData} filtersReport={thisFilters} />
				</DialogContent>
			</Dialog>
		</>
	)
}

const mapStateToProps = ({ store }) => ({
	comparisonStatusRedux: store.card.comparison.status
})

export default connect(mapStateToProps)(memo(InspectOverviewButton))
