import React from 'react'
import { ListIcon, AnalysisIcon, OverviewIcon, CloudIcon } from 'utils/SystemIcons'

const analysisIcon = <AnalysisIcon className='icon-list-items-tab' size='20' />
const overviewIcon = <OverviewIcon className='icon-list-items-tab' size='20' />
const occurrencesIcon = <ListIcon className='icon-list-items-tab' size='20' />
const wordCloudIcon = <CloudIcon className='icon-list-items-tab' size='20' />

const getStatus = (sources) => {
	let hasError = sources.every((s) => s === 'W')
	if (hasError) {
		return {
			name: 'error',
			label: 'Ocorreu algum erro ao buscar dados.'
		}
	}
	let hasWarning = sources.every((s) => s === 'E')
	if (hasWarning) {
		return {
			name: 'warning',
			label: 'Nenhum dado encontrado para esta busca.'
		}
	}
	let isLoading = sources.every((s) => s === 'P')
	if (isLoading) {
		return { name: 'loading', label: 'Processando dados.' }
	} else {
		return { name: 'success', label: '' }
	}
}

const menuDefaultNew = (
	twitterStatus = 'P',
	blueskyStatus = 'P',
	facebookStatus = 'P',
	instagramStatus = 'P',
	tiktokStatus = 'P',
	youtubeStatus = 'P'
) => {
	return [
		{
			label: 'Overview',
			tab: 'analysis',
			status: getStatus([twitterStatus, blueskyStatus, facebookStatus, instagramStatus, tiktokStatus, youtubeStatus]),
			icon: analysisIcon
		},
		{
			label: 'Resumo das redes',
			tab: 'overview',
			status: getStatus([twitterStatus, blueskyStatus, facebookStatus, instagramStatus, tiktokStatus, youtubeStatus]),
			icon: overviewIcon
		},
		{
			label: 'Postagens',
			tab: 'occurrences',
			status: getStatus([twitterStatus, blueskyStatus, facebookStatus, instagramStatus, tiktokStatus, youtubeStatus]),
			icon: occurrencesIcon
		},
		{
			// twitter
			label: 'Nuvens',
			tab: 'wordclouds',
			status: getStatus([twitterStatus, blueskyStatus, facebookStatus, instagramStatus, tiktokStatus, youtubeStatus]),
			icon: wordCloudIcon
		}
		// {
		// 	label: 'Exportar',
		// 	tab: 'export',
		// 	status: getStatus([twitterStatus, facebookStatus, instagramStatus, tiktokStatus]),
		// 	icon: buildDefaultIcon(ExportIcon)
		// }
		// {
		// 	label: 'Relatório',
		// 	tab: 'report',
		// 	status: getStatus([twitterStatus, facebookStatus, instagramStatus, tiktokStatus]),
		// 	icon: buildDefaultIcon(ReportIcon)
		// }
	]
}

export { menuDefaultNew }
