import React, { useState, memo } from 'react'
import { Tooltip, IconButton, Menu, Button, Grid } from '@mui/material'
import { DotsVerticalIcon, NotificationIcon, DeleteBinIcon, EditIcon, MoveIcon, CardHeadingIcon, UpdateIcon } from 'utils/SystemIcons'
import api from 'service/service'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import { verifyServerResponseCanShowToast } from 'utils/generalFunctions'
import { NewProfileDialogAccountManagement } from 'pages/AccountManagement/components/NewProfileDialogAccountManagement/NewProfileDialogAccountManagement'
import MoveToFolder from './components/MoveToFolder/MoveToFolder'
import NotificationConfigDialog from './components/NotificationConfigDialog/NotificationConfigDialog'
import constantsVox from 'constants-vox'
import NewMonitoringDialogTerm from 'pages/Monitoring/components/ButtonModalNewInsight/components/NewMonitoringDialogTerm/NewMonitoringDialogTerm'
import NewMonitoringDialogProfile from 'pages/Monitoring/components/ButtonModalNewInsight/components/NewMonitoringDialogProfile/NewMonitoringDialogProfile'
import NewMonitoringDialogReportTwitter from 'pages/Monitoring/components/ButtonModalNewInsight/components/NewMonitoringDialogReportTwitter/NewMonitoringDialogReportTwitter'
import NewMonitoringDialogGroup from 'pages/Monitoring/components/ButtonModalNewInsight/components/NewMonitoringDialogGroup/NewMonitoringDialogGroup'
import ModalUpdatePosts from 'components/ComparativeBar/components/ComparativeBarItem/components/ModalUpdatePosts/ModalUpdatePosts'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import DialogConfirmation from 'components/DialogConfirmation/DialogConfirmation'

const isFirefox = typeof InstallTrigger !== 'undefined'
const IconSize = 15
const Iconstyle = { color: '#9A9A9A', marginRight: '0.3em' }
const NotificationOption = <NotificationIcon size={IconSize} style={Iconstyle} />
const EditOption = <EditIcon size={IconSize} style={Iconstyle} />
const MoveOption = <MoveIcon size={IconSize} style={Iconstyle} />
const DeleteOption = <DeleteBinIcon size={IconSize} style={Iconstyle} />
const CardHeadingOption = <CardHeadingIcon size={IconSize} style={Iconstyle} />
const UpdateOption = <UpdateIcon size={IconSize} style={Iconstyle} />

const Buttonstyle = {
	borderRadius: '100px',
	textAlign: 'center',
	color: '#9A9A9A',
	justifyContent: 'center',
	alignItems: 'center',
	minWidth: '15em',
	fontFamily: 'Poppins'
}

const verifyUserType = (user) => {
	if (user.proceedAsAdminId) {
		return { user: user.proceedAsAdminId }
	} else if (isEmpty(user.subUser)) {
		return { user: user.id_usuario }
	} else {
		return { subUser: user.subUser.id }
	}
}

const ButtonOption = ({ title, icon, onClick, disabled = false }) => (
	<Grid item xs={12} style={{ height: '3em' }} container justifyContent='center' alignItems='center'>
		<Button style={Buttonstyle} onClick={onClick} disabled={disabled}>
			{icon}
			{title}
		</Button>
	</Grid>
)

const verifyShowEditButton = (type, isActive, cardStatus, permissionCreateCard) => {
	return (
		((type !== 'report' && !(cardStatus === 'P' || cardStatus === 'C' || cardStatus === 'A')) || (type === 'report' && cardStatus === 'W')) &&
		permissionCreateCard &&
		(isActive || type === 'group' || (!isActive && type === 'profile'))
	)
}

const canShowNotificationButton = (type, isActive, permissionCreateCard) => {
	return type !== 'report' && permissionCreateCard && type !== constantsVox.CARD_TYPE.ACCOUNT_MANAGEMENT && (isActive || type === 'group')
}

const canShowUpdatePostsButton = (type, isActive, cardStatus, permissionCreateCard) => {
	return false
	// pausado temas contas grupos
	// !(cardStatus === 'P' || cardStatus === 'C' || cardStatus === 'A' || !cardStatus) &&
	// permissionCreateCard &&
	// ((['profile', 'term'].includes(type) && isActive) || (permissionCreateCard && ['group'].includes(type)))
}

const getRouteByCardType = (type, isComparative) => {
	if (isComparative) {
		return '/cartoes-historico/deletar-cartao-historico'
	}

	switch (type) {
		case 'term':
			return '/card-term/monitoring/hide-card'
		case 'profile':
			return '/card-profile/monitoring/hide-card'
		case 'group':
			return '/card-group/monitoring/hide-card'
		case 'ACCOUNT_MANAGEMENT':
			return '/account-management/profile/delete'
		default:
			return '/card-report/monitoring/hide-card'
	}
}

const DEFAULT_EDIT_CARD = { isOpen: false, id: null, name: '' }

const AnalysisCardMenu = ({ card, refetchItems, color, permissionCreateCard, permissionRemoveCard, isUserAdmin, user }) => {
	const [confirmDelete, setConfirmDelete] = useState(false)
	const [anchorEl, setAnchorEl] = useState(null)
	const [submitting, setSubmitting] = useState(false)
	const [editCard, setEditCard] = useState(DEFAULT_EDIT_CARD)
	const [isOpenMoveToFolder, setIsOpenMoveToFolder] = useState(false)
	const [isOpenNotificationsModal, setIsOpenNotificationsModal] = useState(false)
	const [isOpenUpdatePosts, setIsOpenUpdatePosts] = useState(false)

	let isDemoCard = card?.isDemo
	let isComparative = card?.isComparative

	const hideCard = async () => {
		setSubmitting(true)
		const route = getRouteByCardType(card.type, isComparative)
		const userOrSubUserId = verifyUserType(user)
		api.post(route, { cardId: card.id, userOrSubUserId, log: 'Excluiu o cartão' })
			.then((response) => {
				if (response?.data?.toast) {
					sendMessageToast(response?.data?.toast?.message, response?.data?.toast?.type)
				}
				refetchItems()
			})
			.catch((error) => {
				if (verifyServerResponseCanShowToast(error)) {
					sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
				}
			})
			.finally(() => setSubmitting(false))
	}

	const handleChangeDemonstrationCard = async () => {
		setSubmitting(true)
		await api
			.post('/card/demonstration/toggle-change-to-demo', { cardId: card.id, value: isDemoCard, cardType: card.type })
			.then((response) => {
				if (response?.data?.toast) {
					refetchItems()
					sendMessageToast(response?.data?.toast?.message, response?.data?.toast?.type)
				}
			})
			.catch((error) => {
				if (verifyServerResponseCanShowToast(error)) {
					sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
				}
			})
			.finally(() => setSubmitting(false))
	}

	const toggleDelete = () => {
		setConfirmDelete(!confirmDelete)
	}

	const toggleMenu = (event) => {
		if (anchorEl) {
			setAnchorEl(null)
			setEditCard(DEFAULT_EDIT_CARD)
			setIsOpenMoveToFolder(false)
		} else {
			setAnchorEl(event.currentTarget)
		}
	}

	const handleEditCard = () => {
		setEditCard({ isOpen: true, id: card?.id, name: card?.name })
	}

	const resetEditCard = () => {
		setEditCard(DEFAULT_EDIT_CARD)
	}

	const handleOnEditedCard = () => {
		resetEditCard()
		refetchItems()
	}

	const handleUpdateCard = () => {
		setIsOpenUpdatePosts(!isOpenUpdatePosts)
	}

	const toggleMoveToFolder = () => {
		setIsOpenMoveToFolder(!isOpenMoveToFolder)
	}

	const onMovedToFolder = () => {
		toggleMoveToFolder()
		toggleMenu()
		refetchItems()
	}

	const handleNotifyCard = () => {
		if (!isOpenNotificationsModal) {
			toggleMenu()
		}
		setIsOpenNotificationsModal(!isOpenNotificationsModal)
	}

	const onClose = () => {
		resetEditCard()
	}

	const getDialogEditAccount = () => {
		let dialog = <></>

		switch (card.type) {
			case 'term':
				dialog = <NewMonitoringDialogTerm onSubmit={handleOnEditedCard} close={onClose} card={card} statusType='edit' />
				break
			case 'profile':
				dialog = <NewMonitoringDialogProfile onSubmit={handleOnEditedCard} close={onClose} card={card} statusType='edit' />
				break
			case 'group':
				dialog = <NewMonitoringDialogGroup onSubmit={handleOnEditedCard} close={onClose} card={card} statusType='edit' />
				break
			case 'report':
				dialog = <NewMonitoringDialogReportTwitter onSubmit={handleOnEditedCard} close={onClose} card={card} statusType='edit' />
				break
			case constantsVox.CARD_TYPE.ACCOUNT_MANAGEMENT:
				dialog = (
					<NewProfileDialogAccountManagement
						onCreated={refetchItems}
						onEdited={handleOnEditedCard}
						editCard={editCard}
						resetEditCard={resetEditCard}
						cardType={card.type}
						noButton
					/>
				)
				break
			default:
				break
		}

		return dialog
	}

	return (
		<>
			{(!isDemoCard || (isDemoCard && isUserAdmin)) && (permissionCreateCard || permissionRemoveCard) && (
				<Tooltip title='Opções' placement='left' enterDelay={500} enterNextDelay={500}>
					<IconButton size='small' onClick={toggleMenu} style={{ outline: 'none', marginRight: '-12px' }}>
						<DotsVerticalIcon size={22} style={{ color: color }} />
					</IconButton>
				</Tooltip>
			)}

			{isDemoCard && !isUserAdmin && !(permissionCreateCard || permissionRemoveCard) && <div style={{ height: '32px', width: '32px' }} />}

			<Menu
				open={!!anchorEl}
				onClose={toggleMenu}
				anchorEl={anchorEl}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
				PaperProps={{
					style: {
						minWidth: '15em',
						borderRadius: '20px',
						backdropFilter: 'blur(10px)',
						backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.0)'}`,
						border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
						boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
					}
				}}
			>
				{!isOpenMoveToFolder && (
					<div>
						{canShowNotificationButton(card.type, card.active, permissionCreateCard) && !isComparative && (
							<ButtonOption
								title='NOTIFICAÇÕES'
								icon={NotificationOption}
								onClick={() => {
									handleNotifyCard()
								}}
							/>
						)}
						{verifyShowEditButton(card.type, card.active, card.cardStatus.general, permissionCreateCard) && !isComparative && (
							<ButtonOption
								title='EDITAR'
								icon={EditOption}
								onClick={() => {
									toggleMenu()
									handleEditCard()
								}}
							/>
						)}
						{permissionCreateCard && !isComparative && <ButtonOption title='MOVER' icon={MoveOption} onClick={toggleMoveToFolder} />}
						{permissionRemoveCard && (
							<ButtonOption
								title='DELETAR'
								icon={DeleteOption}
								disabled={submitting}
								onClick={() => {
									toggleDelete()
									toggleMenu()
								}}
							/>
						)}
						{canShowUpdatePostsButton(card.type, card.active, card.cardStatus.general, permissionCreateCard) && !isComparative && (
							<ButtonOption
								title='ATUALIZAR MÉTRICAS'
								icon={UpdateOption}
								onClick={() => {
									toggleMenu()
									handleUpdateCard()
								}}
							/>
						)}
						{isUserAdmin && card.type !== constantsVox.CARD_TYPE.ACCOUNT_MANAGEMENT && !isComparative && (
							<ButtonOption
								title={isDemoCard ? 'VOLTAR PARA CARTÃO PADRÃO' : 'MUDAR PARA CARTÃO DEMO'}
								icon={CardHeadingOption}
								disabled={submitting}
								onClick={() => {
									handleChangeDemonstrationCard()
									toggleMenu()
								}}
							/>
						)}
					</div>
				)}
				<MoveToFolder
					cardId={card.id}
					reportSource='twitter'
					cardFolderId={card.folderId}
					toggleMoveToFolder={toggleMoveToFolder}
					isOpen={isOpenMoveToFolder}
					onMovedToFolder={onMovedToFolder}
					color={color}
				/>
			</Menu>
			{/* Modals */}
			{isOpenNotificationsModal && (
				<NotificationConfigDialog
					isOpen={isOpenNotificationsModal}
					cardId={card.id}
					cardType={card.type}
					onClose={handleNotifyCard}
					name={card.name}
				/>
			)}
			{editCard?.isOpen && getDialogEditAccount()}

			{isOpenUpdatePosts && (
				<ModalUpdatePosts
					isOpen={isOpenUpdatePosts}
					handleOnClose={handleUpdateCard}
					card={card}
					color={color}
					permissionCreateCard={permissionCreateCard}
				/>
			)}

			{confirmDelete && (
				<DialogConfirmation
					open={confirmDelete}
					handleClose={toggleDelete}
					handleConfirm={() => {
						toggleDelete()
						hideCard()
					}}
					content={
						card.type === 'report' && (
							<>
								<Grid item xs={12} style={{ borderRadius: '4px', color: 'rgb(170, 170, 170)', marginTop: '20px', marginLeft: '3px' }}>
									Deletar um cartão não devolve o relatório que ja foi contabilizado.
								</Grid>
								{card.cardStatus !== 'P' && card.cardStatus !== 'S' && (
									<Grid
										item
										xs={12}
										style={{ borderRadius: '4px', color: 'rgb(170, 170, 170)', marginTop: '20px', marginLeft: '3px' }}
									>
										Prefere editar o cartão atual?
										<Button
											style={{ color: '#1d8cf8', borderRadius: '25px' }}
											onClick={() => {
												handleEditCard()
												toggleDelete()
											}}
										>
											EDITAR
										</Button>
									</Grid>
								)}
							</>
						)
					}
					color={'#ff2b6b'}
					title={`Deseja realmente deletar ${card.name}?`}
					buttonText={'DELETAR'}
					icon={<DeleteBinIcon className='fix-icon-margin-bottom-negative' size={25} color='#ff2b6b' />}
				/>
			)}
		</>
	)
}

const mapStateToProps = ({ store }) => ({
	user: store?.user
})

export default connect(mapStateToProps)(memo(AnalysisCardMenu))
