import { Grid } from '@mui/material'
import GraphSkeletonLoad from 'components/SkeletonLoads/GraphSkeletonLoad/GraphSkeletonLoad'
import React, { useEffect, useState } from 'react'
import Chart from 'react-apexcharts'
import { buildLocationRegionGraphOptions } from '../utilsLocationStateAndRegionGraph/locationRegionGraphUtils'

const height = 500
const heightComparativeStacked = 300

const LocationGraph = ({ data, viewType, loading, isReport }) => {
	let isStacked = viewType === 'gender' || viewType === 'impact' || viewType === 'age'
	let isVertical = isStacked && data?.data?.length
	const [isLoading, setIsLoading] = useState(true)

	useEffect(() => {
		setIsLoading(loading || data == {})
	}, [loading, data])

	return (
		<>
			{isLoading ? (
				<GraphSkeletonLoad />
			) : data?.data?.length ? (
				<Grid container item xs={12} justifyContent='space-between'>
					{data.data.every((it) => it.series.length === 0) && (
						<Grid container direction='row' justifyContent='center' alignItems='center' style={{ paddingBottom: '5em' }}>
							<h2 style={{ fontFamily: 'Poppins', color: '#b0b0b0', fontWeight: 'normal' }}>Sem dados</h2>
						</Grid>
					)}
					{data.data.map(
						(it, index) =>
							it.series.length > 0 && (
								<Grid item xs={2} key={index} style={{ marginTop: '1em' }}>
									<Grid item xs={12} style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.1em', color: '#505050' }}>
										{it.title}
									</Grid>
									<Chart
										options={
											buildLocationRegionGraphOptions(data.categories, isStacked, isVertical, viewType, it.series, isReport) ||
											{}
										}
										series={it.series || []}
										type='bar'
										width='100%'
										height={heightComparativeStacked}
										style={{
											color: 'black',
											textAlign: 'left',
											fontFamily: 'Poppins'
										}}
									/>
								</Grid>
							)
					)}
				</Grid>
			) : (
				<Chart
					key={viewType}
					options={buildLocationRegionGraphOptions(data.categories, isStacked, isVertical, viewType, data.series, isReport) || {}}
					series={data.series || []}
					type='bar'
					width='100%'
					height={height}
					style={{
						color: 'black',
						textAlign: 'left',
						fontFamily: 'Poppins'
					}}
				/>
			)}
		</>
	)
}

export default LocationGraph
