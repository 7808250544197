const originalRecordData = [
	[0, 10, 'X', 'abc_construcao', 'Redes socias', 11694344],
	[1, 10, 'X', 'betano', 'Redes socias', 20939000],
	[2, 10, 'X', 'autotruck', 'Redes socias', 11410005],
	[3, 10, 'X', 'bmg', 'Redes socias', 12707756],
	[4, 10, 'X', 'gerdau', 'Redes socias', 26538286],
	[5, 10, 'X', 'multimarcasconsorcios', 'Redes socias', 4469222],
	[6, 10, 'X', 'supermercadobh', 'Redes socias', 6678316],
	[7, 10, 'X', 'vilma', 'Redes socias', 10710094],
	[8, 10, 'X', 'yousaude', 'Redes socias', 4512967],
	[9, 10, 'X', 'betnacionalplaca', 'Redes socias', 20268252],
	[10, 10, 'X', 'bet7kplaca', 'Redes socias', 1287509],
	[11, 10, 'X', 'betanoplaca', 'Redes socias', 1480852],
	[12, 10, 'X', 'superbetplaca', 'Redes socias', 1387241],

	[13, 10, 'Facebook', 'abc_construcao', 'Redes socias', 2533774],
	[14, 10, 'Facebook', 'betano', 'Redes socias', 4536783],
	[15, 10, 'Facebook', 'autotruck', 'Redes socias', 2472168],
	[16, 10, 'Facebook', 'bmg', 'Redes socias', 2753347],
	[17, 10, 'Facebook', 'gerdau', 'Redes socias', 5749962],
	[18, 10, 'Facebook', 'multimarcasconsorcios', 'Redes socias', 968332],
	[19, 10, 'Facebook', 'supermercadobh', 'Redes socias', 1446968],
	[20, 10, 'Facebook', 'vilma', 'Redes socias', 2320520],
	[21, 10, 'Facebook', 'yousaude', 'Redes socias', 977809],
	[22, 10, 'Facebook', 'betnacionalplaca', 'Redes socias', 4391455],
	[23, 10, 'Facebook', 'bet7kplaca', 'Redes socias', 352896],
	[24, 10, 'Facebook', 'betanoplaca', 'Redes socias', 422085],
	[25, 10, 'Facebook', 'superbetplaca', 'Redes socias', 398390],

	[26, 10, 'Instagram', 'abc_construcao', 'Redes socias', 5925134],
	[27, 10, 'Instagram', 'betano', 'Redes socias', 10609093],
	[28, 10, 'Instagram', 'autotruck', 'Redes socias', 5781069],
	[29, 10, 'Instagram', 'bmg', 'Redes socias', 6438597],
	[30, 10, 'Instagram', 'gerdau', 'Redes socias', 13446065],
	[31, 10, 'Instagram', 'multimarcasconsorcios', 'Redes socias', 2264406],
	[32, 10, 'Instagram', 'supermercadobh', 'Redes socias', 3383680],
	[33, 10, 'Instagram', 'vilma', 'Redes socias', 5426448],
	[34, 10, 'Instagram', 'yousaude', 'Redes socias', 2286570],
	[35, 10, 'Instagram', 'betnacionalplaca', 'Redes socias', 1269247],
	[36, 10, 'Instagram', 'bet7kplaca', 'Redes socias', 825233],
	[37, 10, 'Instagram', 'betanoplaca', 'Redes socias', 987028],
	[38, 10, 'Instagram', 'superbetplaca', 'Redes socias', 931602],

	[39, 10, 'Tiktok', 'abc_construcao', 'Redes socias', 6958135],
	[40, 10, 'Tiktok', 'betano', 'Redes socias', 12458705],
	[41, 10, 'Tiktok', 'autotruck', 'Redes socias', 6788953],
	[42, 10, 'Tiktok', 'bmg', 'Redes socias', 7561115],
	[43, 10, 'Tiktok', 'gerdau', 'Redes socias', 15790280],
	[44, 10, 'Tiktok', 'multimarcasconsorcios', 'Redes socias', 2659187],
	[45, 10, 'Tiktok', 'supermercadobh', 'Redes socias', 3973598],
	[46, 10, 'Tiktok', 'vilma', 'Redes socias', 6372506],
	[47, 10, 'Tiktok', 'yousaude', 'Redes socias', 2685215],
	[48, 10, 'Tiktok', 'betnacionalplaca', 'Redes socias', 1059610],
	[49, 10, 'Tiktok', 'bet7kplaca', 'Redes socias', 969108],
	[50, 10, 'Tiktok', 'betanoplaca', 'Redes socias', 1591107],
	[51, 10, 'Tiktok', 'superbetplaca', 'Redes socias', 1940409],

	[52, 10, 'Youtube', 'abc_construcao', 'Redes socias', 2186842],
	[53, 10, 'Youtube', 'betano', 'Redes socias', 3915593],
	[54, 10, 'Youtube', 'autotruck', 'Redes socias', 2133671],
	[55, 10, 'Youtube', 'bmg', 'Redes socias', 2376350],
	[56, 10, 'Youtube', 'gerdau', 'Redes socias', 4962660],
	[57, 10, 'Youtube', 'multimarcasconsorcios', 'Redes socias', 835745],
	[58, 10, 'Youtube', 'supermercadobh', 'Redes socias', 1248845],
	[59, 10, 'Youtube', 'vilma', 'Redes socias', 2002788],
	[60, 10, 'Youtube', 'yousaude', 'Redes socias', 843925],
	[61, 10, 'Youtube', 'betnacionalplaca', 'Redes socias', 340163],
	[62, 10, 'Youtube', 'bet7kplaca', 'Redes socias', 304564],
	[63, 10, 'Youtube', 'betanoplaca', 'Redes socias', 364219],
	[64, 10, 'Youtube', 'superbetplaca', 'Redes socias', 343414],

	[65, 10, 'TV', 'abc_construcao', 'TV', 259083],
	[66, 10, 'TV', 'betano', 'TV', 890820],
	[67, 10, 'TV', 'autotruck', 'TV', 88727],
	[68, 10, 'TV', 'bmg', 'TV', 901468],
	[69, 10, 'TV', 'gerdau', 'TV', 528814],
	[70, 10, 'TV', 'multimarcasconsorcios', 'TV', 535912],
	[71, 10, 'TV', 'supermercadobh', 'TV', 663679],
	[72, 10, 'TV', 'vilma', 'TV', 212945],
	[73, 10, 'TV', 'yousaude', 'TV', 358458],
	[74, 10, 'TV', 'betnacionalplaca', 'TV', 3818816],
	[75, 10, 'TV', 'bet7kplaca', 'TV', 415243],
	[76, 10, 'TV', 'betanoplaca', 'TV', 6480630],
	[77, 10, 'TV', 'superbetplaca', 'TV', 6384804],

	[78, 11, 'X', 'abc_construcao', 'Redes socias', 5541952],
	[79, 11, 'X', 'betano', 'Redes socias', 9922996],
	[80, 11, 'X', 'autotruck', 'Redes socias', 5407203],
	[81, 11, 'X', 'bmg', 'Redes socias', 6022208],
	[82, 11, 'X', 'gerdau', 'Redes socias', 12576498],
	[83, 11, 'X', 'multimarcasconsorcios', 'Redes socias', 2117965],
	[84, 11, 'X', 'supermercadobh', 'Redes socias', 3164855],
	[85, 11, 'X', 'vilma', 'Redes socias', 5075515],
	[86, 11, 'X', 'yousaude', 'Redes socias', 2138696],
	[87, 11, 'X', 'betnacionalplaca', 'Redes socias', 9605128],
	[88, 11, 'X', 'bet7kplaca', 'Redes socias', 778653],
	[89, 11, 'X', 'betanoplaca', 'Redes socias', 931979],
	[90, 11, 'X', 'superbetplaca', 'Redes socias', 873717],

	[91, 11, 'Facebook', 'abc_construcao', 'Redes socias', 1200756],
	[92, 11, 'Facebook', 'betano', 'Redes socias', 2149982],
	[93, 11, 'Facebook', 'autotruck', 'Redes socias', 1171561],
	[94, 11, 'Facebook', 'bmg', 'Redes socias', 1304812],
	[95, 11, 'Facebook', 'gerdau', 'Redes socias', 2724908],
	[96, 11, 'Facebook', 'multimarcasconsorcios', 'Redes socias', 458892],
	[97, 11, 'Facebook', 'supermercadobh', 'Redes socias', 685719],
	[98, 11, 'Facebook', 'vilma', 'Redes socias', 1099695],
	[99, 11, 'Facebook', 'yousaude', 'Redes socias', 463384],
	[100, 11, 'Facebook', 'betnacionalplaca', 'Redes socias', 281111],
	[101, 11, 'Facebook', 'bet7kplaca', 'Redes socias', 172375],
	[102, 11, 'Facebook', 'betanoplaca', 'Redes socias', 200262],
	[103, 11, 'Facebook', 'superbetplaca', 'Redes socias', 188772],

	[104, 11, 'Instagram', 'abc_construcao', 'Redes socias', 2807922],
	[105, 11, 'Instagram', 'betano', 'Redes socias', 5027651],
	[106, 11, 'Instagram', 'autotruck', 'Redes socias', 2739650],
	[107, 11, 'Instagram', 'bmg', 'Redes socias', 3051252],
	[108, 11, 'Instagram', 'gerdau', 'Redes socias', 6372093],
	[109, 11, 'Instagram', 'multimarcasconsorcios', 'Redes socias', 1073102],
	[110, 11, 'Instagram', 'supermercadobh', 'Redes socias', 1603527],
	[111, 11, 'Instagram', 'vilma', 'Redes socias', 2571594],
	[112, 11, 'Instagram', 'yousaude', 'Redes socias', 1083606],
	[113, 11, 'Instagram', 'betnacionalplaca', 'Redes socias', 486598],
	[114, 11, 'Instagram', 'bet7kplaca', 'Redes socias', 391074],
	[115, 11, 'Instagram', 'betanoplaca', 'Redes socias', 467536],
	[116, 11, 'Instagram', 'superbetplaca', 'Redes socias', 441950],

	[117, 11, 'Tiktok', 'abc_construcao', 'Redes socias', 3297461],
	[118, 11, 'Tiktok', 'betano', 'Redes socias', 5904182],
	[119, 11, 'Tiktok', 'autotruck', 'Redes socias', 3217286],
	[120, 11, 'Tiktok', 'bmg', 'Redes socias', 3583214],
	[121, 11, 'Tiktok', 'gerdau', 'Redes socias', 7483017],
	[122, 11, 'Tiktok', 'multimarcasconsorcios', 'Redes socias', 1260189],
	[123, 11, 'Tiktok', 'supermercadobh', 'Redes socias', 1883089],
	[124, 11, 'Tiktok', 'vilma', 'Redes socias', 3019932],
	[125, 11, 'Tiktok', 'yousaude', 'Redes socias', 1272524],
	[126, 11, 'Tiktok', 'betnacionalplaca', 'Redes socias', 575051],
	[127, 11, 'Tiktok', 'bet7kplaca', 'Redes socias', 452599],
	[128, 11, 'Tiktok', 'betanoplaca', 'Redes socias', 549308],
	[129, 11, 'Tiktok', 'superbetplaca', 'Redes socias', 514661],

	[130, 11, 'Youtube', 'abc_construcao', 'Redes socias', 1036345],
	[131, 11, 'Youtube', 'betano', 'Redes socias', 1855600],
	[132, 11, 'Youtube', 'autotruck', 'Redes socias', 1011147],
	[133, 11, 'Youtube', 'bmg', 'Redes socias', 1126153],
	[134, 11, 'Youtube', 'gerdau', 'Redes socias', 2351805],
	[135, 11, 'Youtube', 'multimarcasconsorcios', 'Redes socias', 396060],
	[136, 11, 'Youtube', 'supermercadobh', 'Redes socias', 591828],
	[137, 11, 'Youtube', 'vilma', 'Redes socias', 949121],
	[138, 11, 'Youtube', 'yousaude', 'Redes socias', 399936],
	[139, 11, 'Youtube', 'betnacionalplaca', 'Redes socias', 196159],
	[140, 11, 'Youtube', 'bet7kplaca', 'Redes socias', 144388],
	[141, 11, 'Youtube', 'betanoplaca', 'Redes socias', 126380],
	[142, 11, 'Youtube', 'superbetplaca', 'Redes socias', 169465],

	[143, 11, 'TV', 'abc_construcao', 'TV', 17272],
	[144, 11, 'TV', 'betano', 'TV', 59388],
	[145, 11, 'TV', 'autotruck', 'TV', 5915],
	[146, 11, 'TV', 'bmg', 'TV', 60098],
	[147, 11, 'TV', 'gerdau', 'TV', 35254],
	[148, 11, 'TV', 'multimarcasconsorcios', 'TV', 35727],
	[149, 11, 'TV', 'supermercadobh', 'TV', 44245],
	[150, 11, 'TV', 'vilma', 'TV', 14196],
	[151, 11, 'TV', 'yousaude', 'TV', 23897],
	[152, 11, 'TV', 'betnacionalplaca', 'TV', 254588],
	[153, 11, 'TV', 'bet7kplaca', 'TV', 27683],
	[154, 11, 'TV', 'betanoplaca', 'TV', 432042],
	[155, 11, 'TV', 'superbetplaca', 'TV', 425654],

	[156, 12, 'X', 'abc_construcao', 'Redes socias', 4273633],
	[157, 12, 'X', 'betano', 'Redes socias', 7652042],
	[158, 12, 'X', 'autotruck', 'Redes socias', 4169723],
	[159, 12, 'X', 'bmg', 'Redes socias', 4643979],
	[160, 12, 'X', 'gerdau', 'Redes socias', 9698270],
	[161, 12, 'X', 'multimarcasconsorcios', 'Redes socias', 1633253],
	[162, 12, 'X', 'supermercadobh', 'Redes socias', 2440554],
	[163, 12, 'X', 'vilma', 'Redes socias', 3913945],
	[164, 12, 'X', 'yousaude', 'Redes socias', 1649239],
	[165, 12, 'X', 'betnacionalplaca', 'Redes socias', 740921],
	[166, 12, 'X', 'bet7kplaca', 'Redes socias', 595210],
	[167, 12, 'X', 'betanoplaca', 'Redes socias', 711910],
	[168, 12, 'X', 'superbetplaca', 'Redes socias', 671916],

	[169, 12, 'Facebook', 'abc_construcao', 'Redes socias', 925954],
	[170, 12, 'Facebook', 'betano', 'Redes socias', 1657942],
	[171, 12, 'Facebook', 'autotruck', 'Redes socias', 903440],
	[172, 12, 'Facebook', 'bmg', 'Redes socias', 1006196],
	[173, 12, 'Facebook', 'gerdau', 'Redes socias', 2101292],
	[174, 12, 'Facebook', 'multimarcasconsorcios', 'Redes socias', 353871],
	[175, 12, 'Facebook', 'supermercadobh', 'Redes socias', 528787],
	[176, 12, 'Facebook', 'vilma', 'Redes socias', 848021],
	[177, 12, 'Facebook', 'yousaude', 'Redes socias', 357335],
	[178, 12, 'Facebook', 'betnacionalplaca', 'Redes socias', 104833],
	[179, 12, 'Facebook', 'bet7kplaca', 'Redes socias', 128969],
	[180, 12, 'Facebook', 'betanoplaca', 'Redes socias', 154247],
	[181, 12, 'Facebook', 'superbetplaca', 'Redes socias', 145585],

	[182, 12, 'Instagram', 'abc_construcao', 'Redes socias', 2165308],
	[183, 12, 'Instagram', 'betano', 'Redes socias', 3877035],
	[184, 12, 'Instagram', 'autotruck', 'Redes socias', 2112660],
	[185, 12, 'Instagram', 'bmg', 'Redes socias', 2352950],
	[186, 12, 'Instagram', 'gerdau', 'Redes socias', 4913790],
	[187, 12, 'Instagram', 'multimarcasconsorcios', 'Redes socias', 827515],
	[188, 12, 'Instagram', 'supermercadobh', 'Redes socias', 1236547],
	[189, 12, 'Instagram', 'vilma', 'Redes socias', 1983065],
	[190, 12, 'Instagram', 'yousaude', 'Redes socias', 835614],
	[191, 12, 'Instagram', 'betnacionalplaca', 'Redes socias', 352840],
	[192, 12, 'Instagram', 'bet7kplaca', 'Redes socias', 301571],
	[193, 12, 'Instagram', 'betanoplaca', 'Redes socias', 360746],
	[194, 12, 'Instagram', 'superbetplaca', 'Redes socias', 304555],

	[195, 12, 'Tiktok', 'abc_construcao', 'Redes socias', 2542812],
	[196, 12, 'Tiktok', 'betano', 'Redes socias', 4552965],
	[197, 12, 'Tiktok', 'autotruck', 'Redes socias', 2480985],
	[198, 12, 'Tiktok', 'bmg', 'Redes socias', 2763168],
	[199, 12, 'Tiktok', 'gerdau', 'Redes socias', 5770471],
	[200, 12, 'Tiktok', 'multimarcasconsorcios', 'Redes socias', 971785],
	[201, 12, 'Tiktok', 'supermercadobh', 'Redes socias', 1452129],
	[202, 12, 'Tiktok', 'vilma', 'Redes socias', 2328797],
	[203, 12, 'Tiktok', 'yousaude', 'Redes socias', 981297],
	[204, 12, 'Tiktok', 'betnacionalplaca', 'Redes socias', 447118],
	[205, 12, 'Tiktok', 'bet7kplaca', 'Redes socias', 354157],
	[206, 12, 'Tiktok', 'betanoplaca', 'Redes socias', 425906],
	[207, 12, 'Tiktok', 'superbetplaca', 'Redes socias', 399812],

	[208, 12, 'Youtube', 'abc_construcao', 'Redes socias', 799169],
	[209, 12, 'Youtube', 'betano', 'Redes socias', 1430932],
	[210, 12, 'Youtube', 'autotruck', 'Redes socias', 779738],
	[211, 12, 'Youtube', 'bmg', 'Redes socias', 868424],
	[212, 12, 'Youtube', 'gerdau', 'Redes socias', 1813577],
	[213, 12, 'Youtube', 'multimarcasconsorcios', 'Redes socias', 305418],
	[214, 12, 'Youtube', 'supermercadobh', 'Redes socias', 456384],
	[215, 12, 'Youtube', 'vilma', 'Redes socias', 731908],
	[216, 12, 'Youtube', 'yousaude', 'Redes socias', 308408],
	[217, 12, 'Youtube', 'betnacionalplaca', 'Redes socias', 138094],
	[218, 12, 'Youtube', 'bet7kplaca', 'Redes socias', 113058],
	[219, 12, 'Youtube', 'betanoplaca', 'Redes socias', 133125],
	[220, 12, 'Youtube', 'superbetplaca', 'Redes socias', 126549],

	[221, 12, 'TV', 'abc_construcao', 'TV', 0],
	[223, 12, 'TV', 'betano', 'TV', 0],
	[224, 12, 'TV', 'autotruck', 'TV', 0],
	[225, 12, 'TV', 'bmg', 'TV', 0],
	[226, 12, 'TV', 'gerdau', 'TV', 0],
	[227, 12, 'TV', 'multimarcasconsorcios', 'TV', 0],
	[228, 12, 'TV', 'supermercadobh', 'TV', 0],
	[229, 12, 'TV', 'vilma', 'TV', 0],
	[230, 12, 'TV', 'yousaude', 'TV', 0],
	[231, 12, 'TV', 'betnacionalplaca', 'TV', 0],
	[232, 12, 'TV', 'bet7kplaca', 'TV', 0],
	[233, 12, 'TV', 'betanoplaca', 'TV', 0],
	[234, 12, 'TV', 'superbetplaca', 'TV', 0],

	[235, 13, 'X', 'abc_construcao', 'Redes socias', 2569406],
	[236, 13, 'X', 'betano', 'Redes socias', 4600582],
	[237, 13, 'X', 'autotruck', 'Redes socias', 2506933],
	[238, 13, 'X', 'bmg', 'Redes socias', 2792066],
	[239, 13, 'X', 'gerdau', 'Redes socias', 5830821],
	[240, 13, 'X', 'multimarcasconsorcios', 'Redes socias', 981949],
	[241, 13, 'X', 'supermercadobh', 'Redes socias', 1467317],
	[242, 13, 'X', 'vilma', 'Redes socias', 2353153],
	[243, 13, 'X', 'yousaude', 'Redes socias', 991560],
	[244, 13, 'X', 'betnacionalplaca', 'Redes socias', 445310],
	[245, 13, 'X', 'bet7kplaca', 'Redes socias', 357857],
	[246, 13, 'X', 'betanoplaca', 'Redes socias', 480207],
	[247, 13, 'X', 'superbetplaca', 'Redes socias', 409926],

	[248, 13, 'Facebook', 'abc_construcao', 'Redes socias', 556705],
	[249, 13, 'Facebook', 'betano', 'Redes socias', 996793],
	[250, 13, 'Facebook', 'autotruck', 'Redes socias', 543169],
	[251, 13, 'Facebook', 'bmg', 'Redes socias', 604948],
	[252, 13, 'Facebook', 'gerdau', 'Redes socias', 1263345],
	[253, 13, 'Facebook', 'multimarcasconsorcios', 'Redes socias', 212756],
	[254, 13, 'Facebook', 'supermercadobh', 'Redes socias', 317919],
	[255, 13, 'Facebook', 'vilma', 'Redes socias', 509850],
	[256, 13, 'Facebook', 'yousaude', 'Redes socias', 214838],
	[257, 13, 'Facebook', 'betnacionalplaca', 'Redes socias', 96462],
	[258, 13, 'Facebook', 'bet7kplaca', 'Redes socias', 77536],
	[259, 13, 'Facebook', 'betanoplaca', 'Redes socias', 92778],
	[260, 13, 'Facebook', 'superbetplaca', 'Redes socias', 85317],

	[261, 13, 'Instagram', 'abc_construcao', 'Redes socias', 1301832],
	[262, 13, 'Instagram', 'betano', 'Redes socias', 2330962],
	[263, 13, 'Instagram', 'autotruck', 'Redes socias', 1270179],
	[264, 13, 'Instagram', 'bmg', 'Redes socias', 1414647],
	[265, 13, 'Instagram', 'gerdau', 'Redes socias', 2954283],
	[266, 13, 'Instagram', 'multimarcasconsorcios', 'Redes socias', 497521],
	[267, 13, 'Instagram', 'supermercadobh', 'Redes socias', 743440],
	[268, 13, 'Instagram', 'vilma', 'Redes socias', 1192264],
	[269, 13, 'Instagram', 'yousaude', 'Redes socias', 502390],
	[270, 13, 'Instagram', 'betnacionalplaca', 'Redes socias', 225293],
	[271, 13, 'Instagram', 'bet7kplaca', 'Redes socias', 181311],
	[272, 13, 'Instagram', 'betanoplaca', 'Redes socias', 216838],
	[273, 13, 'Instagram', 'superbetplaca', 'Redes socias', 204696],

	[274, 13, 'Tiktok', 'abc_construcao', 'Redes socias', 1528797],
	[275, 13, 'Tiktok', 'betano', 'Redes socias', 2737346],
	[276, 13, 'Tiktok', 'autotruck', 'Redes socias', 1491625],
	[277, 13, 'Tiktok', 'bmg', 'Redes socias', 1661279],
	[278, 13, 'Tiktok', 'gerdau', 'Redes socias', 3469339],
	[279, 13, 'Tiktok', 'multimarcasconsorcios', 'Redes socias', 584260],
	[280, 13, 'Tiktok', 'supermercadobh', 'Redes socias', 873053],
	[281, 13, 'Tiktok', 'vilma', 'Redes socias', 1400126],
	[282, 13, 'Tiktok', 'yousaude', 'Redes socias', 589978],
	[283, 13, 'Tiktok', 'betnacionalplaca', 'Redes socias', 269660],
	[284, 13, 'Tiktok', 'bet7kplaca', 'Redes socias', 212959],
	[285, 13, 'Tiktok', 'betanoplaca', 'Redes socias', 256723],
	[286, 13, 'Tiktok', 'superbetplaca', 'Redes socias', 240356],

	[287, 13, 'Youtube', 'abc_construcao', 'Redes socias', 480479],
	[288, 13, 'Youtube', 'betano', 'Redes socias', 860309],
	[289, 13, 'Youtube', 'autotruck', 'Redes socias', 468796],
	[290, 13, 'Youtube', 'bmg', 'Redes socias', 522116],
	[291, 13, 'Youtube', 'gerdau', 'Redes socias', 1090364],
	[292, 13, 'Youtube', 'multimarcasconsorcios', 'Redes socias', 183624],
	[293, 13, 'Youtube', 'supermercadobh', 'Redes socias', 274388],
	[294, 13, 'Youtube', 'vilma', 'Redes socias', 440040],
	[295, 13, 'Youtube', 'yousaude', 'Redes socias', 185422],
	[296, 13, 'Youtube', 'betnacionalplaca', 'Redes socias', 83750],
	[297, 13, 'Youtube', 'bet7kplaca', 'Redes socias', 66996],
	[298, 13, 'Youtube', 'betanoplaca', 'Redes socias', 80039],
	[299, 13, 'Youtube', 'superbetplaca', 'Redes socias', 75546],
	[300, 13, 'TV', 'abc_construcao', 'TV', 0],
	[301, 13, 'TV', 'betano', 'TV', 0],
	[302, 13, 'TV', 'autotruck', 'TV', 0],
	[303, 13, 'TV', 'bmg', 'TV', 0],
	[304, 13, 'TV', 'gerdau', 'TV', 0],
	[305, 13, 'TV', 'multimarcasconsorcios', 'TV', 0],
	[306, 13, 'TV', 'supermercadobh', 'TV', 0],
	[307, 13, 'TV', 'vilma', 'TV', 0],
	[308, 13, 'TV', 'yousaude', 'TV', 0],
	[309, 13, 'TV', 'betnacionalplaca', 'TV', 0],
	[310, 13, 'TV', 'bet7kplaca', 'TV', 0],
	[311, 13, 'TV', 'betanoplaca', 'TV', 0],
	[312, 13, 'TV', 'superbetplaca', 'TV', 0]
]

const recordData = originalRecordData.map((record) => {
	if (record[4] === 'Redes socias') {
		record[5] = Math.round(record[5] / 14)
	}

	return record
})

const slides = [
	{
		//configartion slide
		animation: {
			data: {
				series: [
					{ name: 'index', type: 'measure' },
					{ name: 'Dias', type: 'dimension' },
					{ name: 'Redes', type: 'dimension' },
					{ name: 'Marca', type: 'dimension' },
					{ name: 'Tipo de Midia', type: 'dimension' },
					{ name: 'Audiencia', type: 'measure' }
				],
				records: recordData
			},
			style: {
				backgroundColor: 'rgba(0,0,0,0)',
				borderColor: 'rgba(0,0,0,0)',
				title: { backgroundColor: 'rgba(0,0,0,0)', color: 'rgba(255,255,255,0.5)' },
				plot: {
					backgroundColor: 'rgba(0,0,0,0)',
					xAxis: {
						interlacing: { color: 'rgba(0,0,0,0.1)' }
					},
					yAxis: {
						interlacing: { color: 'rgba(0,0,0,0.1)' }
					},
					marker: {
						colorPalette: 'rgba(254, 176, 25, 1)',
						rectangleSpacing: '2em'
					}
				},
				legend: { backgroundColor: 'rgba(0,0,0,0)' }
			},
			config: {
				channels: {
					x: { set: ['Audiencia'] },
					y: { set: null },
					label: 'Audiencia',
					color: null
				},
				coordSystem: 'polar',
				geometry: 'rectangle',
				align: 'none',
				title: '25 de junho e 24 de julho 2024'
			}
		},
		animationBehavior: { speed: 4 },
		extraData: null
	},
	{
		index: 1,
		text: 'Analisamos a audiência de marca na TV e nas redes sociais durante o período entre 10 de Novembro e 13 de Novembro 2024',
		animation: {
			config: {
				channels: {
					x: { set: ['Audiencia'] },
					y: { set: null },
					label: 'Audiencia',
					color: null
				},
				coordSystem: 'polar',
				geometry: 'rectangle',
				align: 'none',
				title: '10 de Novembro a 13 de Novembro 2024'
			},
			style: {
				plot: {
					marker: {
						colorPalette: 'rgba(254, 176, 25, 1)',
						rectangleSpacing: '2em'
					}
				}
			}
		},
		animationBehavior: { speed: 1 },
		extraData: null
	},
	{
		index: 2,
		text: 'De acordo com a Kantar, a transmissão alcançou 34 pontos de audiência, atingindo cerca de 22 milhões de espectadores. Nas redes sociais, porém, os dados revelam uma diferença significativa: o número de inserções é maior devido à exposição contínua. Ao longo de 4 dias de coleta, cada usuário foi impactado, em média, 14 vezes por alguma das marcas. A audiência mínima estimada foi de 45.359.088 usuários únicos — mais que o dobro do público alcançado pela TV.',
		animation: {
			config: {
				channels: {
					x: { set: ['Audiencia'] },
					// y: { set: null },
					y: { set: ['Tipo de Midia'] },
					label: 'Audiencia',
					color: 'Tipo de Midia'
				},
				coordSystem: 'polar',
				geometry: 'rectangle',
				align: 'none',
				title: null,
				sort: 'none'
			},
			style: {
				backgroundColor: 'rgba(0,0,0,0)',
				borderColor: 'rgba(0,0,0,0)',
				title: { backgroundColor: 'rgba(0,0,0,0)' },
				plot: {
					marker: {
						colorPalette: 'rgba(254, 176, 25, 1) rgba(15, 186, 118, 1)',
						rectangleSpacing: '1em'
					}
				},
				legend: { backgroundColor: 'rgba(0,0,0,0)' }
			}
		},
		animationBehavior: { speed: 1.6 },
		extraData: null
	},
	{
		index: 3,
		text: 'Embora a TV impacte mais indivíduos por transmissão, as redes sociais têm dois diferenciais importantes: alcançam mais que o dobro de usuários no total e proporcionam uma exposição contínua, mantendo o engajamento mesmo após o término dos jogos.',
		animation: {
			config: {
				channels: {
					x: { set: ['Audiencia'] },
					y: { set: ['Redes'] },
					label: 'Audiencia',
					color: 'Redes',
					size: null
				},
				coordSystem: 'polar',
				geometry: 'rectangle',
				align: 'none',
				title: null,
				sort: 'byValue'
			},
			style: {
				backgroundColor: 'rgba(0,0,0,0)',
				borderColor: 'rgba(0,0,0,0)',
				title: { backgroundColor: 'rgba(0,0,0,0)' },
				plot: {
					marker: {
						colorPalette:
							'rgba(43, 179, 174, 1) rgba(44, 125, 230, 1) rgba(230, 81, 44, 1) rgba(0, 0, 0, 1) rgba(186, 34, 59, 1) rgba(15, 186, 118, 1)',
						rectangleSpacing: '1em'
					}
				},
				legend: { backgroundColor: 'rgba(0,0,0,0)' }
			}
		},
		animationBehavior: { speed: 1.6 },
		extraData: null
	},
	// {
	// 	index: 4,
	// 	text: '',
	// 	animation: {
	// 		config: {
	// 			channels: {
	// 				x: { set: ['Dias'] },
	// 				y: { set: ['Audiencia'] },
	// 				label: null,
	// 				color: 'Marca',
	// 				size: null
	// 			},
	// 			coordSystem: 'cartesian',
	// 			geometry: 'line',
	// 			align: 'none',
	// 			title: null,
	// 			sort: 'none'
	// 		},
	// 		style: {
	// 			backgroundColor: 'rgba(0,0,0,0)',
	// 			borderColor: 'rgba(0,0,0,0)',
	// 			title: { backgroundColor: 'rgba(0,0,0,0)' },
	// 			plot: {
	// 				marker: {
	// 					colorPalette:
	// 						'#f58231 #e61919 #000075 #bcf60c #e6194b #3cb44b #ffe119 #4363d8 #008080 #911eb4 #46f0f0 #f032e6 #fabebe #e6beff #9a6324 #fffac8 #800000 #aaffc3 #808000 #ffd8b1 #808080 #e3e1e1 #000000',
	// 					rectangleSpacing: '1em'
	// 				}
	// 			},
	// 			legend: { backgroundColor: 'rgba(0,0,0,0)' }
	// 		}
	// 	},
	// 	animationBehavior: { speed: 1 },
	// 	extraData: null
	// },
	// {
	// 	index: 5,
	// 	text: '',
	// 	animation: {
	// 		config: {
	// 			channels: {
	// 				x: { set: ['Dias'] },
	// 				y: { set: ['Audiencia'] },
	// 				label: null,
	// 				color: 'Redes',
	// 				size: null
	// 			},
	// 			coordSystem: 'cartesian',
	// 			geometry: 'line',
	// 			align: 'none',
	// 			title: null,
	// 			sort: 'none'
	// 		},
	// 		style: {
	// 			backgroundColor: 'rgba(0,0,0,0)',
	// 			borderColor: 'rgba(0,0,0,0)',
	// 			title: { backgroundColor: 'rgba(0,0,0,0)' },
	// 			plot: {
	// 				marker: {
	// 					colorPalette:
	// 						'rgba(186, 34, 59, 1) rgba(0, 0, 0, 1) rgba(230, 81, 44, 1) rgba(43, 179, 174, 1)  rgba(44, 125, 230, 1) rgba(15, 186, 118, 1)',
	// 					rectangleSpacing: '1em'
	// 				}
	// 			},
	// 			legend: { backgroundColor: 'rgba(0,0,0,0)' }
	// 		}
	// 	},
	// 	animationBehavior: { speed: 1 },
	// 	extraData: null
	// },
	{
		index: 4,
		text: 'Esse fenômeno fica evidente ao analisarmos a curva de indivíduos impactados ao longo do tempo. A audiência nas redes sociais supera a da TV em todos os momentos e, enquanto a transmissão televisiva se encerra ao final do evento, os vídeos nas redes continuam gerando engajamento por um período muito mais prolongado.',
		animation: {
			config: {
				channels: {
					x: { set: ['Dias'] },
					y: { set: ['Audiencia'] },
					label: null,
					color: 'Tipo de Midia',
					size: null
				},
				coordSystem: 'cartesian',
				geometry: 'line',
				align: 'none',
				title: null,
				sort: 'none'
			},
			style: {
				backgroundColor: 'rgba(0,0,0,0)',
				borderColor: 'rgba(0,0,0,0)',
				title: { backgroundColor: 'rgba(0,0,0,0)' },
				plot: {
					marker: {
						colorPalette: 'rgba(254, 176, 25, 1) rgba(15, 186, 118, 1)',
						rectangleSpacing: '1em'
					}
				},
				legend: { backgroundColor: 'rgba(0,0,0,0)' }
			}
		},
		animationBehavior: { speed: 1 },
		extraData: null
	}
]

export { slides }
