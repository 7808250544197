import React, { useEffect, useRef, useState } from 'react'
import { Autocomplete, Chip, Paper, TextField, styled, Checkbox } from '@mui/material'
import { CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon, CheckBox as CheckBoxIcon } from '@mui/icons-material'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import { LoadingIcon } from 'utils/SystemIcons'
import api from 'service/service'

const isFirefox = typeof InstallTrigger !== 'undefined'
const TAGS_REGEX = /[a-zA-Z0-9\b\0 _;]/
const blue = 'rgba(29, 138, 248, 100%)'
const white = '#ffffff'
let lastOnDeleteTime = null

const checkedIcon = <CheckBoxIcon fontSize='small' color='primary' />
const uncheckedIcon = <CheckBoxOutlineBlankIcon fontSize='small' color='primary' />

const CustomPaper = (props) => {
	return (
		<Paper
			{...props}
			sx={{
				minWidth: '15em',
				borderRadius: '0px 0px 25px 25px',
				backdropFilter: 'blur(10px)',
				backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
				border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
				boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
			}}
		/>
	)
}

const handleInputTag = ({ value, setErrorText, setError, setCurrentText }) => {
	const dateDiff = new Date().getTime() - lastOnDeleteTime
	const isValidTag = validateTag(value, setErrorText)
	setError(!isValidTag)
	if (dateDiff > 100) {
		setCurrentText(value)
	}
}

const handleOnChangeValue = ({ value, setErrorText, setError, setTags, setCurrentText }) => {
	let addMultiple = value.flatMap((item) => item.split(';')).filter((it) => it.trim().length > 0)
	const addMultipleUnique = Array.from(new Set(addMultiple))
	handleChangeTags({ tags: addMultipleUnique, setErrorText, setError, setTags, setCurrentText })
}

const CssTextField = styled(TextField)({
	'& .MuiInputBase-root': {
		color: blue,
		fontFamily: 'Poppins',
		'& input': {
			textAlign: 'left'
		}
	}
})

const RenderTagLabel = ({ tag }) => {
	return (
		<div style={{ display: 'flex', alignItems: 'center' }}>
			<div
				style={{
					backgroundColor: blue
				}}
			>
				{tag}
			</div>
		</div>
	)
}

const validateTag = (tag, setErrorText) => {
	if (tag[0] === ' ' || tag[tag.length - 1] === ' ') {
		setErrorText('Não pode começar ou terminar com espaço vazio, este será removido ao adicionar')
		return false
	}

	setErrorText('')
	return true
}

const removeExtraSpaces = (tag) => {
	let newTag = tag
	while (newTag.includes('  ')) {
		newTag = newTag.replace('  ', ' ')
	}
	while (newTag[0] === ' ') {
		newTag = newTag.substring(1)
	}
	while (newTag[newTag.length - 1] === ' ') {
		newTag = newTag.substring(0, newTag.length - 1)
	}
	return newTag
}

const handleChangeTags = ({ tags, setErrorText, setError, setTags, setCurrentText }) => {
	tags = tags.map((tag) => removeExtraSpaces(tag.trim()))

	let isValid = true
	let isUnique = true

	tags.forEach((tag, index) => {
		if (tags.indexOf(tag) !== index) {
			isUnique = false
		}
	})

	tags.forEach((tag) => {
		if (!validateTag(tag, setErrorText)) {
			isValid = false
		}
	})

	if (isValid && isUnique) {
		setError(false)
		setTags(tags)
		setErrorText('')
	} else {
		setError(true)
		if (isUnique === false) {
			setErrorText('Não pode ser repetido')
		}
		setCurrentText(tags[tags.length - 1])
	}
}

const SearchUsers = ({ searchTags, onChangeTags, icon, title = '', cards }) => {
	const [currentText, setCurrentText] = useState('')
	const [tags, setTags] = useState(searchTags)
	const [errorText, setErrorText] = useState('')
	const [error, setError] = useState(false)
	const [options, setOptions] = useState([])
	const [loading, setLoading] = useState(false)
	const isMounted = useRef(true)

	useEffect(() => {
		onChangeTags(tags)
	}, [tags])

	useEffect(() => {
		return () => {
			isMounted.current = false
		}
	}, [])

	const loadUsersHistory = async () => {
		if (cards.length > 1) {
			return
		}
		setLoading(true)
		const cardId = cards[0].id
		const response = await api.get(`/occurrences/get-card-favourite-accounts/${cardId}`)
		const data = response.data
		if (isMounted.current) {
			setOptions(data?.accounts || [])
		}
		setLoading(false)
	}

	const handleDelete = async (e, option) => {
		e.stopPropagation()
		const cardId = cards[0].id
		api.post('/occurrences/remove-favorite', {
			account: option,
			cardId: cardId
		}).then((e) => {
			if (e?.data?.toast) {
				if (e?.data?.toast?.type === 'SUCCESS') {
					const newOptions = options.filter((opt) => opt !== option)
					setOptions(newOptions)
				}
				sendMessageToast(e?.data?.toast?.message, e?.data?.toast?.type)
			}
		})
	}

	const optionRenderer = (props, option, { selected }) => {
		return (
			<li {...props} key={props.id} id={`btn-check-tag-${option}`}>
				<Checkbox icon={uncheckedIcon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
				<Chip
					style={{
						color: white,
						backgroundColor: '#1d8cf8',
						borderColor: '#1d8cf8'
					}}
					variant='outlined'
					label={option}
					onDelete={(e) => handleDelete(e, option)}
				/>
			</li>
		)
	}

	return (
		<Autocomplete
			id='autocomplete-search-users'
			multiple
			freeSolo
			PaperComponent={CustomPaper}
			disableClearable
			disableCloseOnSelect
			options={options}
			value={tags}
			inputValue={currentText}
			onInputChange={(_, value) => handleInputTag({ value, setErrorText, setError, setCurrentText })}
			onChange={(_, value) => handleOnChangeValue({ value, setErrorText, setError, setTags, setCurrentText })}
			style={{ width: '100%' }}
			renderOption={optionRenderer}
			renderInput={(params) => (
				<div style={{ position: 'relative' }} id='input-search-users'>
					<CssTextField
						onFocus={loadUsersHistory}
						error={error}
						onKeyDown={(event) => {
							if (!TAGS_REGEX.test(event.key)) {
								event.preventDefault()
							}
						}}
						variant='standard'
						label={
							<div style={{ display: 'flex', alignItems: 'center' }}>
								{loading ? (
									<LoadingIcon
										size={20}
										thickness={1}
										style={{
											color: '#1d8cf8',
											transform: 'translate(0px, 2px)',
											marginRight: '7px'
										}}
									/>
								) : (
									<>{icon}</>
								)}
								<div style={{ marginLeft: '5px', marginTop: '0px', fontFamily: 'Poppins', fontSize: '15px' }}>{title}</div>
							</div>
						}
						helperText={
							errorText ||
							(currentText.length > 0 &&
								'Pressione Enter para adicionar, separe por ponto e vírgula (;) para adicionar múltiplos ao mesmo tempo')
						}
						{...params}
					/>
				</div>
			)}
			renderTags={(value, getTagProps) =>
				value.map((option, index) => (
					<Chip
						size='medium'
						style={{ color: white, backgroundColor: blue, borderColor: blue }}
						key={index}
						variant='outlined'
						label={<RenderTagLabel tag={option} />}
						{...getTagProps({ index })}
						onDelete={(e) => {
							const props = getTagProps({ index })
							props.onDelete(e)
							lastOnDeleteTime = new Date().getTime()
						}}
					/>
				))
			}
		/>
	)
}

export default SearchUsers
