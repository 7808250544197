import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
// import ReactDOM from 'react-dom/client'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { QueryClient, QueryClientProvider } from 'react-query'
import { store } from 'store/redux/store'
import LogRocket from 'logrocket'
import SystemRoutes from 'routes/routes'
import Header from 'components/Header/Header'
import WebFont from 'webfontloader'

import 'global.css'
import 'global-material-ui.css'
import 'global-other-components.css'
import 'global-general-classes.css'
import 'colors.css'
import Toast from 'componentsVox/Toast/Toast'
import Vizzu from 'vizzu'
import VizzuModule from './cvizzu.wasm'
Vizzu.options({ wasmUrl: VizzuModule })

LogRocket.init('3ajvcx/voxradar')
const hist = createBrowserHistory()
const queryClient = new QueryClient()

const canRenderHeader = () => {
	const paths = ['/report/', '/sharing/', '/aura-report/']
	const url = hist?.location?.pathname
	const isMatch = paths.some((path) => url.includes(path))
	return !isMatch
}

const App = () => {
	useEffect(() => {
		WebFont.load({
			google: {
				families: ['Poppins:200,300', 'Roboto:200,300', 'Montserrat:200,300']
			}
		})
	}, [])

	return (
		<Provider store={store}>
			<Toast />
			<QueryClientProvider client={queryClient}>
				<Router history={hist}>
					{/* não aparece o header no relatório, mas se for preciso, pode aparecer, só remover essa validação */}
					{canRenderHeader() && <Header />}
					<SystemRoutes />
					{/* {!hist?.location?.pathname?.includes('/report/') && (
							<h5
								style={{
									textAlign: 'right',
									fontFamily: 'Roboto',
									fontWeight: '300',
									paddingRight: '3.5em',
									color: '#a19f9f'
								}}
							>
								©VoxRadar {new Date().getFullYear()}
							</h5>
						)} */}
				</Router>
				<ReactQueryDevtools initialIsOpen={false} />
			</QueryClientProvider>
		</Provider>
	)
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<App />)
