import { Button, Card, Dialog, DialogTitle, Grid, MenuItem, Pagination, Select, Stack, Tooltip } from '@mui/material'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import constantsVox from 'constants-vox'
import React, { memo, useState, useEffect } from 'react'
import api from 'service/service'
import { LockIcon, LoadingIcon } from 'utils/SystemIcons'
const isFirefox = typeof InstallTrigger !== 'undefined'

const SubUserCardPermissions = ({ cardAccessPermissionsSubUser, toggleAccessPermissions }) => {
	const [loading, setLoading] = useState(true)
	const [data, setData] = useState([])
	const [cardType, setCardType] = useState(constantsVox.CARD_TYPE.TERM)
	const [page, setPage] = useState(1)
	const [maxItens, setMaxItens] = useState(0)

	useEffect(() => {
		setLoading(true)
		loadCards()
	}, [cardAccessPermissionsSubUser.subUserId, page])

	useEffect(() => {
		setLoading(true)
		loadCards(true)
	}, [cardType])

	const loadCards = async (resetPage) => {
		let pageToSearch = page
		if (resetPage) {
			pageToSearch = 1
			setPage(pageToSearch)
		}
		api.get(`/profile/card-permissions/get-for-sub-user/${cardAccessPermissionsSubUser.subUserId}/${cardType}/${pageToSearch - 1}`)
			.then((response) => {
				setData(response.data.rows)
				setMaxItens(response.data.maxItens)
			})
			.catch((error) => {
				sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const handleToggleHideCardForSubUser = (item) => {
		api.post('/profile/card-permissions/change-card-visibility-for-sub-user', {
			subUserId: cardAccessPermissionsSubUser.subUserId,
			referenceId: item.reference_id,
			cardType,
			isHidden: !item.is_hidden
		})
			.then(() => {
				sendMessageToast('Permissão alterada', 'SUCCESS')
				loadCards()
			})
			.catch((error) => {
				sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const handleChangeCardType = (event) => {
		setCardType(event.target.value)
	}
	const handleOnChangePagination = (_, value) => {
		setPage(value)
	}

	return (
		<Dialog
			style={{ fontFamily: 'Poppins', whiteSpace: 'nowrap' }}
			open={cardAccessPermissionsSubUser.isOpen}
			onClose={() => toggleAccessPermissions()}
			PaperProps={{
				style: {
					borderRadius: '20px',
					backdropFilter: 'blur(10px)',
					backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
					border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
					boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
				}
			}}
			BackdropProps={{
				style: { backdropFilter: 'blur(3px)', backgroundColor: 'rgba(220, 220, 220, 0.4)' }
			}}
			fullWidth={true}
			maxWidth='sm'
		>
			<Card style={{ margin: '20px', background: 'transparent', height: '45em', display: 'flex', flexDirection: 'column' }} elevation={0}>
				<DialogTitle>
					<div style={{ color: '#1d8cf8' }}>
						<div>
							<LockIcon className='fix-icon-margin-bottom-negative' size={25} color='#1d8cf8' style={{ marginRight: '0.5em' }} />
							Alterar permissões dos cartões para o sub usuário
						</div>
						{cardAccessPermissionsSubUser?.subUserName && <div>{cardAccessPermissionsSubUser?.subUserName}</div>}
					</div>
				</DialogTitle>
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					{loading ? (
						<LoadingIcon size={30} thickness={2} color='#1d8cf8' />
					) : (
						<Grid container justifyContent='space-between'>
							<Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
								<Select value={cardType} onChange={handleChangeCardType} style={{ width: '15em', textAlign: 'center' }}>
									<MenuItem value={constantsVox.CARD_TYPE.TERM}>Temas</MenuItem>
									<MenuItem value={constantsVox.CARD_TYPE.ACCOUNT}>Contas</MenuItem>
									<MenuItem value={constantsVox.CARD_TYPE.GROUP}> Grupos</MenuItem>
								</Select>
							</Grid>
							<hr style={{ width: '100%', opacity: 0.3 }} />
							<Grid container item xs={12} spacing={3}>
								{data.map((item) => (
									<Grid item xs={6} key={item.reference_id + item.name + item.sub_user_id}>
										<Tooltip title={item.name} placement='top-start'>
											<div style={{ textOverflow: 'ellipsis', overflow: 'hidden', cursor: 'pointer' }}>{item.name}</div>
										</Tooltip>
										<Button
											disabled={loading}
											style={{ boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.1)', marginLeft: '1px' }}
											onClick={() => handleToggleHideCardForSubUser(item)}
										>
											{item.is_hidden ? 'Mostrar' : 'Esconder'}
										</Button>
										<hr style={{ width: '100%', opacity: 0.3 }} />
									</Grid>
								))}
							</Grid>
						</Grid>
					)}
				</div>
				<Stack spacing={2} style={{ marginTop: 'auto' }}>
					<Pagination count={Math.ceil(maxItens / 10)} page={page} onChange={handleOnChangePagination} />
				</Stack>
			</Card>
		</Dialog>
	)
}

export default memo(SubUserCardPermissions)
