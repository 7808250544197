import React, { useState, useRef, memo, useEffect } from 'react'
import { FiActivity } from 'react-icons/fi'
import { Grid, Card } from '@mui/material'
import { connect } from 'react-redux'
import { saveGlobalFiltersTermAction } from 'store/redux/actions/global.actions'
import moment from 'moment-timezone'
import LoadingBar from 'components/LoadingBar/LoadingBar'
import MiniCards from 'components/MiniCards/MiniCards'
import GraphTitle from 'components/GraphTitle/GraphTitle'
import GraficoImpactoNowcasting from './components/GraficoImpactoNowcasting/GraficoImpactoNowcasting'
import SideMenu from './components/SideMenu/SideMenu'
import DialogContents from 'components/DialogInformation/DialogContents'
import { canRenderHourInterval } from 'graphs/utils/TimelinesFunctions'
import api from 'service/service'
import PotentialReachWidget from 'components/PotentialReachWidget/PotentialReachWidget'
import NowcastingExtraWidgetContainer from './components/NowcastingExtraWidgetsContainer/NowcastingExtraWidgetContainer'

const defaultOptions = {
	type: 'posts',
	posts: true,
	amount: true,
	engagement: false,
	social: false,
	gender: false,
	users: false,
	men: false,
	women: false,
	organization: false,
	noClass: false,
	sources: false,
	politics: false,
	comments: false,
	reposts: false
}

const politicalDictionary = (key) => {
	switch (key) {
		case 'left_wing':
			return 'ESQUERDA'
		case 'right_wing':
			return 'DIREITA'
		case 'centre_left_wing':
			return 'CENTRO ESQUERDA'
		case 'centre_right_wing':
			return 'CENTRO DIREITA'
		case 'centre':
			return 'CENTRO'
	}
}

const GraficoNowcasting = ({
	cards,
	isFetchingData,
	globalFiltersTermRedux,
	saveGlobalFiltersDispatch,
	cardTypeRedux,
	defaultGraphTime = null,
	hideRightMenus = false,
	isWidget = false,
	show = 'all'
}) => {
	const [extendedCard, setExtendedCard] = useState(false)
	const [options, setOptions] = useState(defaultOptions)
	const [politicalOptions, setPoliticalOptions] = useState()
	const [openDateInfo, setOpenDateInfo] = useState(false)
	const MiniCardsRef = useRef(null)
	const PotentialReachRef = useRef(null)
	const [graphView, setGraphView] = useState('line')
	const MediaCountRef = useRef(null)

	const loadNowcastingMenuOptions = async () => {
		const clusterType = 'POLITICAL'
		await api.get(`/analysis/nowcasting/menu-options/${clusterType}`).then((res) => {
			let newOptions = options
			let newPoliticalOptions = []
			res.data.forEach((option) => {
				newOptions = { ...newOptions, [option.cluster_name]: false }
				newPoliticalOptions.push({ key: option.cluster_name, name: politicalDictionary(option.cluster_name) })
			})
			setOptions(newOptions)
			setPoliticalOptions(newPoliticalOptions)
		})
	}

	useEffect(() => {
		loadNowcastingMenuOptions()
	}, [])

	useEffect(() => {
		let newOptions = defaultOptions
		if (globalFiltersTermRedux.sources.includes('twitter') && politicalOptions) {
			politicalOptions.forEach((option) => {
				newOptions = { ...newOptions, [option.key]: false }
			})
			newOptions.politics = false
		}
		setOptions(newOptions)
	}, [cards, globalFiltersTermRedux.sources])

	useEffect(() => {
		let newOptions = options
		if (!globalFiltersTermRedux.sources.includes('twitter')) {
			if (politicalOptions) {
				politicalOptions.forEach((option) => {
					newOptions = { ...newOptions, [option.key]: false }
				})
				newOptions.politics = false
			}
		}
		if (globalFiltersTermRedux.sources.includes('news') && globalFiltersTermRedux.sources.length === 1) {
			newOptions.gender = false
			newOptions.men = false
			newOptions.women = false
			newOptions.organization = false
			newOptions.noClass = false
		}
		setOptions(newOptions)
	}, [globalFiltersTermRedux.sources])

	const loadingBarRef = useRef()

	const onPartialLoading = (status) => {
		loadingBarRef.current.load(status)
	}

	const onChangeCardLenght = (status) => {
		setExtendedCard(status)
	}

	const onChangeOptions = (newOptions) => {
		setOptions(newOptions)
	}

	const lockTimeInterval = (interval) => {
		if (moment(globalFiltersTermRedux.toDate).diff(globalFiltersTermRedux.fromDate, 'months') > 12) {
			setOpenDateInfo(true)
		} else {
			saveGlobalFiltersDispatch({
				...globalFiltersTermRedux,
				fromDate: interval.fromDate,
				toDate: interval.toDate,
				fromHour: interval.fromHour,
				toHour: interval.toHour
			})
		}
	}

	const getDefaultGraphTime = () => {
		return canRenderHourInterval(globalFiltersTermRedux.fromDate, globalFiltersTermRedux.toDate, 'months', 3) &&
			canRenderHourInterval(globalFiltersTermRedux.fromDate, globalFiltersTermRedux.toDate, 'weeks', 3)
			? defineSelectedTimeInterval()
			: 'day'
	}

	const defineSelectedTimeInterval = () => {
		if (defaultGraphTime) {
			return defaultGraphTime
		} else {
			return canRenderHourInterval(globalFiltersTermRedux.fromDate, globalFiltersTermRedux.toDate, 'weeks', 1) && graphView === 'line'
				? 'hour'
				: 'day'
		}
	}

	const handleCloseDateInfo = () => {
		setOpenDateInfo(false)
	}

	const MiniCardsContent = () => {
		return (
			<MiniCards
				filters={globalFiltersTermRedux}
				cards={cards}
				cardType={cardTypeRedux}
				onChangeCardLenght={onChangeCardLenght}
				isSocialProfile={cardTypeRedux !== 'term'}
				ref={MiniCardsRef}
				isWidget={isWidget}
				show={show}
			/>
		)
	}

	const defineGridSize = () => {
		if (isWidget) {
			return 12
		} else {
			return extendedCard ? 9 : 10
		}
	}

	const handleChangeGraphView = (view) => {
		setGraphView(view)
	}

	return (
		<Grid container spacing={0} style={{ width: isWidget ? '100%' : '100%', height: isWidget ? '100%' : '622px' }}>
			<DialogContents
				open={openDateInfo}
				handleClose={handleCloseDateInfo}
				title='Informação'
				text='Não é possível filtrar períodos maiores que 12 meses. Caso necessite de períodos maiores que 12 meses entre em contato com nosso suporte.'
			/>
			{(show === 'all' || show === 'posts' || show === 'engagement' || show === 'sentiments') && (
				<>
					{isWidget ? (
						<MiniCardsContent />
					) : (
						<Grid item lg={extendedCard ? 3 : 2} md={4} sm={4} spacing={2} container justifyContent='space-between'>
							<MiniCardsContent />
						</Grid>
					)}
				</>
			)}
			{(show === 'all' || show === 'timeline') && (
				<Grid item lg={defineGridSize()} md={8} sm={8} style={{ paddingLeft: isWidget ? '0' : '1em' }}>
					<Card
						style={{
							borderRadius: '20px',
							height: '100%',
							width: '100%',
							border: isWidget ? '1px solid rgba(154, 154, 154, 0.2)' : 'none'
						}}
					>
						<Grid container spacing={3} style={{ background: '#fbfbfb', height: '70px', borderBottom: '1px solid #f5f5f5' }}>
							<Grid item xs={6}>
								<GraphTitle
									id='span-nowcasting'
									title='Linha do tempo'
									icon={<FiActivity size={25} style={{ color: '#1d8cf8' }} />}
									description={`
											Evolução ao longo do tempo quanto ao engajamento em cada rede social. 
											Para calcular se o engajamento total cresceu ou decresceu, levamos em consideração algumas características da postagem,
											como o número de vezes que a postagem foi compartilhada e o valor total de reações.
											`}
								/>
							</Grid>
							{!hideRightMenus && (
								<Grid item xs={6} container justifyContent='flex-end'>
									<SideMenu
										options={options}
										onChangeOptions={onChangeOptions}
										isComp={cards?.length > 1}
										sources={globalFiltersTermRedux?.sources}
										cardType={cardTypeRedux}
										politicalOptions={politicalOptions}
									/>
								</Grid>
							)}
						</Grid>
						<div>
							<LoadingBar ref={loadingBarRef} />
						</div>
						{cardTypeRedux === 'term' && (
							<NowcastingExtraWidgetContainer
								PotentialReachRef={PotentialReachRef}
								MediaCountRef={MediaCountRef}
								filters={globalFiltersTermRedux}
								cards={cards}
							/>
						)}
						<GraficoImpactoNowcasting
							filters={globalFiltersTermRedux}
							cards={cards}
							fullScreenState={false}
							onPartialLoading={onPartialLoading}
							isFetchingData={isFetchingData}
							cardType={cardTypeRedux}
							options={options}
							defaultGraphTime={getDefaultGraphTime()}
							changeExternalInterval={(newInterval) => {
								MiniCardsRef.current?.f(newInterval) || true
								PotentialReachRef.current?.f(newInterval) || true
								MediaCountRef.current?.f(newInterval) || true
							}}
							lockTimeInterval={lockTimeInterval}
							isWidget={isWidget}
							isExtended={extendedCard}
							handleChangeGraphView={handleChangeGraphView}
						/>
					</Card>
				</Grid>
			)}
		</Grid>
	)
}

const mapDispatchToProps = (dispatch) => ({
	saveGlobalFiltersDispatch: (newFilters) => dispatch(saveGlobalFiltersTermAction(newFilters))
})

const mapStateToProps = ({ store }) => ({
	globalFiltersTermRedux: store.global.filters,
	cardTypeRedux: store.card.cardType
})

export default connect(mapStateToProps, mapDispatchToProps)(memo(GraficoNowcasting))
