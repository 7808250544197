import React, { memo, useRef } from 'react'
import { connect } from 'react-redux'
import { Grid, Card, IconButton } from '@mui/material'
import WordClouds from 'pages/Monitoring/modules/components/WordClouds/WordClouds'
import { CloudIcon, CloseIcon, MoveResizeIcon } from 'utils/SystemIcons'
import GraphTitle from 'components/GraphTitle/GraphTitle'
import LoadingBar from 'components/LoadingBar/LoadingBar'

const WordCloudDashboardShareable = ({ title, description, cards, comparisonStatusRedux, editMode, addWidgetMode, removeWidget, keyName }) => {
	const loadingBarRef = useRef()

	return (
		<>
			{addWidgetMode && (
				<div style={{ position: 'absolute', right: '1px' }}>
					<IconButton onClick={() => removeWidget(keyName)}>
						<CloseIcon color={'#1d8cf8'} />
					</IconButton>
				</div>
			)}
			{editMode && (
				<div
					style={{
						borderRadius: '20px',
						position: 'absolute',
						top: 47,
						left: 3,
						right: 3,
						bottom: 8,
						backdropFilter: 'blur(5px)',
						backgroundColor: 'rgba(250, 250, 250, 0.5)',
						zIndex: 1000,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center'
					}}
				>
					<MoveResizeIcon size={60} style={{ marginBottom: '3em', color: '#1d8cf8' }} />
				</div>
			)}
			<Card
				style={{
					height: '100%',
					width: '100%',
					borderRadius: '20px',
					background: 'transparent',
					pointerEvents: editMode ? 'none' : '',
					userSelect: editMode ? 'none' : ''
				}}
				className={editMode ? 'editBox' : ''}
			>
				<Grid container spacing={3} style={{ background: '#fbfbfb', height: '70px', borderBottom: '1px solid #f5f5f5' }}>
					<Grid item xs={12}>
						<GraphTitle
							id='span-sentiments-nowcasting'
							description={description}
							title={title}
							icon={<CloudIcon size={25} style={{ color: '#1d8cf8' }} />}
						/>
					</Grid>
				</Grid>
				<div>
					<LoadingBar ref={loadingBarRef} />
				</div>

				<WordClouds cardStatus={comparisonStatusRedux} cards={cards} isWidget={true} height={500} isCommentWordCloud={true} />
			</Card>
		</>
	)
}

const mapStateToProps = ({ store }) => ({
	comparisonStatusRedux: store.card.comparison.status
})

export default connect(mapStateToProps, null)(memo(WordCloudDashboardShareable))
