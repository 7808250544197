import React, { useState, useRef, useLayoutEffect, useEffect } from 'react'
import Vizzu from 'vizzu'
import { Scrollama, Step } from 'react-scrollama'
import { slides } from './data/IntroData'
import { defineWordColor } from '../../Aura/utils/Functions'
import ApexColumn from '../../Graphs/ApexColumn/ApexColumn'

const Intro = ({ isDebug = false }) => {
	const [currentStepIndex, setCurrentStepIndex] = useState(0)
	const canvasRef = useRef(null)
	const chartRef = useRef()

	useLayoutEffect(() => {
		chartRef.current = new Vizzu(canvasRef.current)
	}, [])

	useEffect(() => {
		onChartTypeChange(currentStepIndex)
	}, [currentStepIndex])

	const onStepEnter = ({ data }) => {
		setCurrentStepIndex(data)
	}

	const onChartTypeChange = (index) => {
		chartRef?.current?.animate(slides[index]?.animation, slides[index]?.animationBehavior)
	}

	return (
		<div style={{ border: isDebug ? '2px dashed skyblue' : '' }}>
			<div className='scroll-graph-container-left' style={{ border: isDebug ? '1px solid orchid' : '' }}>
				<div className='graph-container'>
					<canvas
						ref={canvasRef}
						style={{
							height: '100%',
							width: '100%',
							borderRadius: '25px',
							color: 'transparent'
						}}
					/>
					<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
						<div
							style={{
								backgroundColor: 'rgba(47, 47, 47, 1)',
								marginTop: '-2em',
								height: '2em',
								width: '7em',
								fontFamily: 'Poppins',
								justifyContent: 'center',
								alignItems: 'center',
								display: 'flex',
								color: '#d9d9d9',
								borderRadius: '100px'
							}}
						>
							auragraph
						</div>
					</div>
				</div>
			</div>
			<div className='scroll-steps-container-right'>
				<div>
					<Scrollama offset={0.5} onStepEnter={onStepEnter} debug={isDebug}>
						{slides.slice(1).map((slide) => (
							<Step data={slide.index} key={slide.index}>
								<div className='step-conainer'>
									<div className='step-index'>{slide.index}</div>
									<div className='step-content'>
										{slides[slide.index]?.text.split(' ').map((word, index) => {
											return <span key={`${word}-${index}`} style={{ color: defineWordColor(word) }}>{`${word} `}</span>
										})}
										{slide.extraData && (
											<ApexColumn
												series={slides[slide.index]?.extraData?.series}
												categories={slides[slide.index]?.extraData?.categories}
											/>
										)}
									</div>
								</div>
							</Step>
						))}
					</Scrollama>
				</div>
			</div>
			<div className='bottom-page' />
		</div>
	)
}

export default Intro
