import React from 'react'
import { Card } from '@mui/material'

const grey = '#d6d6d6'

const PhotoGalleryEmptyFeed = () => {
	return (
		<Card
			elevation={0}
			style={{
				width: '100%',
				borderRadius: '25px',
				marginBottom: '0.5em',
				marginLeft: '0px',
				marginRight: '0px'
			}}
		>
			<h3>
				<div
					style={{
						color: grey,
						marginTop: '30px',
						marginBottom: '30px',
						fontWeight: '500',
						fontSize: '24px',
						textAlign: 'center',
						float: 'center',
						padding: '1em',
						fontFamily: 'Poppins'
					}}
				>
					Sem mais fotos para esse intervalo
				</div>
			</h3>
		</Card>
	)
}

export default PhotoGalleryEmptyFeed
