import React, { memo, useState, useRef, useEffect } from 'react'
import { Card, FormHelperText, Grid } from '@mui/material'
import CardsQuery from './components/CardsQuery/CardsQuery'
import QueryBuilder from 'components/QueryBuilder/QueryBuilder'
import constantsVox from 'constants-vox'
import GroupsNewInsightProfile from '../../../NewMonitoringDialogProfile/components/GroupsNewInsightProfile/GroupsNewInsightProfile'

const defineQuery = (isQueryFromstate, formState, card) => {
	if (isQueryFromstate) {
		return formState?.searchTerms?.format?.jsonLogicFormat || null
	} else {
		return card?.queryLogic || null
	}
}

const TermNewInsight = ({ fieldErrors, onChangeForm, card, formState, isQueryFromstate, statusType, maxNumberOfTerms, messageToast }) => {
	const [groupsInfo, setGroupsInfo] = useState({ hasLoadedGroups: false, loading: true })
	const [query] = useState(defineQuery(isQueryFromstate, formState, card))
	const isMounted = useRef(true)

	useEffect(() => {
		isMounted.current = true
		if (statusType === 'edit') {
			load()
		} else if (!isQueryFromstate) {
			onChangeForm({ cardQuery: [] })
		}
		return () => {
			isMounted.current = false
		}
	}, [])

	const load = () => {
		onChangeForm({ cardQuery: card.cardQuery || [], groups: card.groups || [] })
	}

	const onChangeQuerys = (value) => {
		onChangeForm({ cardQuery: value })
	}

	const getLoadedGroups = (groupsInfo) => {
		setGroupsInfo({ hasLoadedGroups: groupsInfo.groups.length > 0, loading: groupsInfo.loading })
	}

	return (
		<Grid container>
			<Grid item xs={12} className='mb-3-1i mt-1i'>
				<span className='color-blue fs-1-5'>Coletas do cartão {formState?.cardName}</span>
			</Grid>
			<Grid item xs={12}>
				<Card
					elevation={0}
					style={{
						borderRadius: '25px',
						marginLeft: '1em',
						width: '100%',
						backgroundColor: 'rgba(255,255,255,0.6)',
						border: '1px solid rgba(200, 200, 200, 0.2)'
					}}
				>
					{formState.searchType === constantsVox.CARD.SEARCH_TYPE.TERM && (
						<Grid item xs={12} style={{ padding: '1.5em', font: 'Poppins' }}>
							<CardsQuery
								onChangeQuerys={onChangeQuerys}
								externalQuerys={formState?.cardQuery}
								maxNumberOfTerms={maxNumberOfTerms}
								messageToast={messageToast}
								cardName={formState.cardName}
							/>
							{statusType === 'edit' && card?.cardQuery.length === 0 && (
								<QueryBuilder //depois de um tempo tirar daqui
									getSearchTermsOnAdd={(query) => onChangeForm({ searchTerms: query })}
									error={fieldErrors?.searchTerms?.error}
									jsonQuery={query}
									createCard={true}
									hasTwitterSearch={formState.hasTwitterSearch}
									hasBlueskySearch={formState.hasBlueskySearch}
									hasFacebookSearch={formState.hasFacebookSearch}
									hasInstagramSearch={formState.hasInstagramSearch}
									hasTiktokSearch={formState.hasTiktokSearch}
									hasYoutubeSearch={formState.hasYoutubeSearch}
									justOldFormatQuery={true}
								/>
							)}
							<div style={{ padding: '1em' }}>
								<FormHelperText style={{ fontSize: '16px', color: '#d32f2f', fontFamily: 'Poppins' }}>
									{fieldErrors?.cardQuery?.error}
								</FormHelperText>
							</div>
						</Grid>
					)}
					{formState.searchType === constantsVox.CARD.SEARCH_TYPE.PROFILE && (
						<Grid item xs={12} style={{ padding: '2em' }}>
							<GroupsNewInsightProfile
								isOptional={false}
								getLoadedGroups={getLoadedGroups}
								formState={formState}
								onChangeForm={onChangeForm}
								fieldErrors={fieldErrors}
								card={card}
								messageToast={messageToast}
								showNew={false}
							/>
							<Grid style={{ color: '#9a9a9a' }}>
								{!groupsInfo.hasLoadedGroups &&
									!groupsInfo.loading &&
									'Nenhum grupo encontrado. Crie um grupo para utilizar esta funcionalidade'}
							</Grid>
						</Grid>
					)}
				</Card>
			</Grid>
		</Grid>
	)
}

export default memo(TermNewInsight)
