import React from 'react'
import { Button, Card, Grid } from '@mui/material'

const AuraReportCard = ({ path, title }) => {
	const handleOpenAura = () => {
		window.open(path, '_blank')
	}

	return (
		<Card style={{ padding: '16px', marginBottom: '16px' }}>
			<Grid container spacing={2}>
				<Grid item xs={6}>
					<Button variant='contained' color='primary' onClick={handleOpenAura}>
						Abrir relatório
					</Button>
				</Grid>
				<Grid item xs={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', fontFamily: 'Poppins' }}>
					{title}
				</Grid>
			</Grid>
		</Card>
	)
}

export default AuraReportCard
