import React, { useState, memo } from 'react'
import { Grid, Button, Collapse, Tooltip } from '@mui/material'
import {
	DeleteBinIcon,
	CheckIcon,
	CloseIcon,
	TwitterIcon,
	BlueskyIcon,
	FacebookIcon,
	InstagramIcon,
	TiktokIcon,
	YoutubeIcon,
	AllSourcesIcon
} from 'utils/SystemIcons'
import api from 'service/service'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import { verifyServerResponseCanShowToast } from 'utils/generalFunctions'

const defineIcon = (sources) => {
	if (sources.length > 1) {
		return <AllSourcesIcon size={16} color='#1d8cf8' style={{ marginTop: '3px' }} />
	} else if (sources.includes('twitter')) {
		return <TwitterIcon size={16} style={{ color: '#1d8cf8', marginTop: '4px' }} />
	} else if (sources.includes('bluesky')) {
		return <BlueskyIcon size={16} style={{ color: '#1d8cf8', marginTop: '4px' }} />
	} else if (sources.includes('facebook')) {
		return <FacebookIcon size={16} style={{ color: '#1d8cf8', marginTop: '4px' }} />
	} else if (sources.includes('instagram')) {
		return <InstagramIcon size={16} style={{ color: '#1d8cf8', marginTop: '4px' }} />
	} else if (sources.includes('tiktok')) {
		return <TiktokIcon size={16} style={{ color: '#1d8cf8', marginTop: '4px' }} />
	} else if (sources.includes('youtube')) {
		return <YoutubeIcon size={16} style={{ color: '#1d8cf8', marginTop: '4px' }} />
	}
}

const VoxAiHistoryItem = ({ item, toggleOpenHistory, cardId, applyHistory, isShareUrl = false }) => {
	const [confirmDelete, setConfirmDelete] = useState(false)
	const [submitting, setSubmitting] = useState(false)

	const loadSavedFilter = () => {
		applyHistory(item)
	}

	const toggleConfirmDelete = () => {
		setTimeout(() => {
			setConfirmDelete(!confirmDelete)
		}, 300)
	}

	const handleConfirmClick = (e, id) => {
		e.preventDefault()
		setTimeout(() => {
			setSubmitting(true)
			removeFilter(e, id)
		}, 300)
	}

	const onClickHistoryItem = (filters) => {
		loadSavedFilter(filters)
		toggleOpenHistory()
	}

	const removeFilter = async (event, analysisId) => {
		event.preventDefault()
		api.post('/voxradar-ai/remove-analysis', {
			analysisId,
			cardId
		})
			.then((response) => {
				if (response?.data?.toast) {
					sendMessageToast(response?.data?.toast?.message, response?.data?.toast?.type)
				}
			})
			.catch((error) => {
				if (verifyServerResponseCanShowToast(error)) {
					sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
				}
			})
	}

	return (
		<Grid item xs={12}>
			<Collapse in={!submitting} timeout={300}>
				<Grid container justifyContent='flex-start' alignItems='flex-start' style={{ padding: '1em', width: '50em' }}>
					<Tooltip title={item?.title} placement='bottom-start' enterDelay={500} enterNextDelay={500}>
						<Button
							disabled={submitting}
							id={`btn-load-item-${item?.title}`}
							onClick={() => onClickHistoryItem()}
							style={{
								maxHeight: '37px',
								minHeight: '37px',
								backgroundColor: '#fff',
								borderRadius: '25px 0px 0px 25px',
								width: `${confirmDelete ? '45.4em' : '50em'}`,
								display: 'flex',
								justifyContent: 'flex-start',
								alignContent: 'flex-start'
							}}
						>
							<div
								style={{
									width: '100%',
									color: 'rgba(100,100,100, 0.9)',
									marginLeft: '3px',
									display: 'flex',
									justifyContent: 'flex-start',
									alignContent: 'flex-start'
								}}
							>
								<div style={{ marginRight: '8px', marginTop: '3px' }}>{defineIcon(item?.sources)}</div>
								<div
									style={{
										fontFamily: 'Poppins',
										whiteSpace: 'nowrap',
										overflow: 'hidden',
										fontSize: '13px',
										textOverflow: 'ellipsis',
										textAlign: 'left',
										marginTop: '4px'
									}}
								>
									{item?.title}
								</div>
							</div>
						</Button>
					</Tooltip>
					{!confirmDelete && (
						<Tooltip title={isShareUrl ? '' : 'Excluir análise'} placement='bottom' enterDelay={500} enterNextDelay={500}>
							<span>
								<Button
									id={`btn-delete-item-${item?.title}`}
									disabled={submitting || isShareUrl}
									onClick={toggleConfirmDelete}
									style={{
										height: '37px',
										color: 'rgba(100,100,100, 0.9)',
										backgroundColor: '#fff',
										borderRadius: '0px 25px 25px 0px',
										fontFamily: 'Poppins',
										width: '2em'
									}}
								>
									{!isShareUrl && <DeleteBinIcon size={20} style={{ color: 'rgba(100,100,100, 0.9)' }} />}
								</Button>
							</span>
						</Tooltip>
					)}
					{confirmDelete && (
						<Collapse in={confirmDelete} timeout={300}>
							<div style={{ width: '2em', display: 'flex' }}>
								<Button
									id={`btn-confirm-delete-item-${item?.title}`}
									disabled={submitting}
									onClick={(e) => handleConfirmClick(e, item.id)}
									style={{
										color: '#1d8cf8',
										backgroundColor: '#fff',
										borderRadius: '0px',
										fontFamily: 'Poppins',
										width: '0.5em'
									}}
								>
									<CheckIcon size={25} style={{ color: '#1d8cf8' }} />
								</Button>
								<Button
									id={`btn-cancel-delete-item-${item?.title}`}
									disabled={submitting}
									onClick={toggleConfirmDelete}
									style={{
										color: '#f50057',
										backgroundColor: '#fff',
										borderRadius: '0px 25px 25px 0px',
										fontFamily: 'Poppins',
										width: '0.5em'
									}}
								>
									<CloseIcon size={25} style={{ color: '#f50057' }} />
								</Button>
							</div>
						</Collapse>
					)}
				</Grid>
			</Collapse>
		</Grid>
	)
}

export default memo(VoxAiHistoryItem)
