import React, { useState, useEffect, useRef } from 'react'
import {
	Chip,
	Grid,
	TextField,
	Autocomplete,
	Paper,
	styled,
	Collapse,
	Card,
	IconButton,
	Button,
	Dialog,
	DialogTitle,
	Tooltip,
	Avatar,
	DialogContent,
	Divider
} from '@mui/material'
import { EditIcon, DeleteBinIcon, InfoIcon, CloseIcon } from 'utils/SystemIcons'
import autoAnimate from '@formkit/auto-animate'
import { validateCardQuery, verifyCardQueryError } from '../../../SocialNetworksNewInsightTerm/components/SourceCheckContent/utils'
import DialogConfirmation from 'components/DialogConfirmation/DialogConfirmation'

const isFirefox = typeof InstallTrigger !== 'undefined'

let lastOnDeleteTime = null

const BLUE = 'rgba(29, 138, 248, 100%)'
const WHITE = '#ffffff'
const RED = '#ff2b6b'
const GRAY = 'rgba(170, 170, 170, 100%)'

const regex = /^[A-Za-z0-9@#. _;]+$/

const CssTextField = styled(TextField)({
	'& .MuiInputBase-root': {
		color: BLUE,
		fontFamily: 'Poppins',
		'& input': {
			textAlign: 'left',
			fontFamily: 'Poppins'
		}
	},
	'& label.Mui-focused': {
		color: BLUE
	}
})

const CustomPaper = (props) => {
	return (
		<Paper
			{...props}
			sx={{
				minWidth: '15em',
				borderRadius: '20px',
				backdropFilter: 'blur(10px)',
				backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
				border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
				boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
			}}
		/>
	)
}

const formatQuery = (query, color, type, index = null) => {
	return (
		<Chip
			key={query}
			avatar={
				index !== 0 && type ? (
					<Avatar style={{ padding: '0px 1px 0px 1px', backgroundColor: 'white', fontWeight: 'bold', color: 'black', fontSize: '10px' }}>
						{type}
					</Avatar>
				) : (
					<></>
				)
			}
			label={query}
			style={{ color: 'white', backgroundColor: color, margin: '2px' }}
		/>
	)
}

const verifyIsMinimized = (query) => {
	return !(query.termsAnd.length === 0 && query.termsOr.length === 0 && query.termsNot.length === 0)
}

const defineErrorByKeyword = (error, keyWord, queryObject) => {
	if (error !== false) {
		if (keyWord === 'termsAnd' && error.includes('obrigatórios') && queryObject.termsAnd.length === 0) {
			return true
		}

		if (keyWord === 'termsOr' && error.includes('equivalente') && queryObject.termsOr.length === 1) {
			return true
		}
	}
	return false
}

const CardQuery = ({ id, queryObject, title, onChangeQuery, onDelete, messageToast, cardName, validateUniqueQuery }) => {
	const [confirmDelete, setConfirmDelete] = useState(false)
	const [isMinimized, setIsMinimized] = useState(verifyIsMinimized(queryObject))
	const [typingTextAnd, setTypingTextAnd] = useState('')
	const [typingTextOr, setTypingTextOr] = useState('')
	const [typingTextNot, setTypingTextNot] = useState('')
	const [showInfo, setShowInfo] = useState(false)
	const [error, setError] = useState(false)
	const keyWordQuery = [
		{
			title: 'Simultâneas (obrigatório)',
			subTitle: 'As menções precisam conter todas essas palavras ao mesmo tempo para serem coletadas',
			key: 'termsAnd',
			word: typingTextAnd,
			icon: 'AND ',
			color: BLUE
		},
		{
			title: 'Pelo menos uma (opcional)',
			subTitle: 'Além de todas as palavras obrigatórias, as menções precisam conter pelo menos uma dessas palavras para serem coletadas',
			key: 'termsOr',
			word: typingTextOr,
			icon: 'OR',
			color: GRAY
		},
		{
			title: 'Ignoradas (opcional)',
			subTitle: 'Seu cartão não coletará menções se qualquer uma das palavras a seguir aparecer',
			key: 'termsNot',
			word: typingTextNot,
			icon: 'NOT',
			color: RED
		}
	]

	const autocompleteRef = useRef()

	const parent = useRef(null)

	useEffect(() => {
		parent.current && autoAnimate(parent.current)
	}, [parent])

	const onChangeText = (text, key) => {
		const dateDiff = new Date().getTime() - lastOnDeleteTime
		if (dateDiff > 100) {
			switch (key) {
				case 'termsAnd':
					setTypingTextAnd(text)
					break
				case 'termsOr':
					setTypingTextOr(text)
					break
				case 'termsNot':
					setTypingTextNot(text)
					break
			}
		}
	}

	const handleClickDelete = (id) => {
		onDelete(id)
	}

	const toggleDelete = () => {
		setConfirmDelete(!confirmDelete)
	}

	const onChangeQueryObject = (newQueryText, queryKey, id, newTitle) => {
		onChangeQuery(newQueryText, queryKey, id, newTitle)
	}

	const onSaveQuery = () => {
		const error = verifyCardQueryError([{ id, query: queryObject, title }]) || validateUniqueQuery(id, queryObject)
		error ? setError(error) : setError(false)
		!error ? setIsMinimized(true) : setIsMinimized(false)
	}

	const buildQuery = (keyWord) => {
		return (
			<Collapse in={!isMinimized}>
				<Grid container style={{ padding: '1em', marginTop: '1.5em' }}>
					<Grid container style={{ textAlign: 'left', display: 'flex', alignItems: 'center' }}>
						<Grid item style={{ marginRight: '0.5em', fontWeight: 'bold', color: 'GrayText', fontFamily: 'Poppins', fontSize: '15px' }}>
							{keyWord.title}
						</Grid>
						<Grid item style={{ marginRight: '0.5em', color: 'gray', fontSize: '15px', fontFamily: 'Poppins', marginTop: '0.3em' }}>
							{keyWord.subTitle}
						</Grid>
					</Grid>
					<Grid item xs={12} style={{ marginTop: '0.3em' }}>
						<Autocomplete
							className='autocomplete-tags-query'
							multiple
							freeSolo
							PaperComponent={CustomPaper}
							disableClearable
							inputValue={keyWord.word}
							value={queryObject[keyWord.key]}
							options={[]}
							onInputChange={(_, value) => onChangeText(value, keyWord.key)}
							isOptionEqualToValue={() => false}
							onChange={(event, value) =>
								validateCardQuery(value, keyWord.key, id, queryObject, messageToast, onChangeQueryObject, event)
							}
							style={{ width: '100%' }}
							renderInput={(params) => (
								<div style={{ position: 'relative' }}>
									<CssTextField
										variant='standard'
										onKeyDown={(event) => {
											if (!regex?.test(event.key)) {
												event.preventDefault()
											}
										}}
										error={defineErrorByKeyword(error, keyWord.key, queryObject)}
										helperText={defineErrorByKeyword(error, keyWord.key, queryObject) ? error : ''}
										inputRef={autocompleteRef}
										label={
											<Grid container>
												<div
													style={{
														display: 'flex',
														color: keyWord.color,
														marginRight: '5px',
														fontWeight: 'bold',
														fontFamily: 'Poppins'
													}}
												>
													{keyWord.icon}
												</div>
											</Grid>
										}
										{...params}
									/>
								</div>
							)}
							renderTags={(value, getTagProps) =>
								value.map((option, index) => (
									<Chip
										style={{ color: WHITE, backgroundColor: keyWord.color, borderColor: keyWord.color }}
										key={index}
										variant='outlined'
										label={option}
										{...getTagProps({ index })}
										onDelete={(e) => {
											const props = getTagProps({ index })
											props.onDelete(e)
											lastOnDeleteTime = new Date().getTime()
										}}
									/>
								))
							}
						/>
						{keyWord.word && keyWord.word !== '' && 'Pressione Enter para adicionar'}
					</Grid>
				</Grid>
			</Collapse>
		)
	}

	return (
		<Card
			elevation={0}
			style={{
				borderRadius: '25px',
				backgroundColor: 'rgba(255,255,255,0.6)',
				border: '1px solid rgba(29, 138, 248, 0.2)'
			}}
		>
			<Grid container style={{ padding: '1em' }}>
				<Grid item xs={12} spacing={1} container alignItems={'center'}>
					<Grid item xs={isMinimized ? 10 : 11} container justifyContent={'flex-start'}>
						{isMinimized && (
							<IconButton onClick={() => setShowInfo(true)}>
								<InfoIcon size={20} color={BLUE} />
							</IconButton>
						)}
						{isMinimized && (
							<div
								style={{
									marginLeft: '0.5em',
									fontFamily: 'Poppins',
									display: 'flex',
									alignItems: 'center'
								}}
							>
								{title}
							</div>
						)}
						{!isMinimized && (
							<CssTextField
								fullWidth
								variant='standard'
								label={
									<Grid container>
										<div
											style={{
												display: 'flex',
												color: BLUE,
												marginRight: '5px',
												fontWeight: 'bold',
												fontFamily: 'Poppins'
											}}
										>
											Título da coleta
										</div>
									</Grid>
								}
								size='small'
								className='d-flex'
								style={{ color: '#1d8cf8', borderRadius: '4px' }}
								value={title}
								onChange={(e) => onChangeQueryObject('', '', id, e.target.value)}
								error={error && error.includes('título') && !title}
								helperText={error && error.includes('título') && !title ? error : ''}
							/>
						)}
					</Grid>
					<Grid item xs={isMinimized ? 2 : 1} container justifyContent={'flex-end'}>
						{isMinimized && (
							<Tooltip title={'Editar coleta'} placement='top' enterDelay={200} enterNextDelay={200}>
								<IconButton size='medium' onClick={() => setIsMinimized(!isMinimized)}>
									<EditIcon size={20} color={BLUE} />
								</IconButton>
							</Tooltip>
						)}
						<Tooltip title={'Deletar coleta'} placement='top' enterDelay={200} enterNextDelay={200}>
							<IconButton size='medium' onClick={() => setConfirmDelete(!confirmDelete)}>
								<DeleteBinIcon size={20} color={RED} />
							</IconButton>
						</Tooltip>
					</Grid>
				</Grid>
			</Grid>

			{!isMinimized && (
				<>
					<Divider style={{ borderColor: 'rgba(29, 138, 248, 0.2)', height: '0.5px', width: '100%', marginTop: '0.5em' }} />
					<div className='jc-space-between d-flex ai-center'>
						<div
							style={{
								color: BLUE,
								fontFamily: 'Poppins',
								fontSize: '16px',
								marginLeft: '0.9em',
								marginTop: '1em',
								fontWeight: 'bold',
								marginBottom: '-1em'
							}}
						>
							Palavras-chave:
						</div>
					</div>
				</>
			)}

			{keyWordQuery.map((keyWord) => (
				<Grid item xs={12} key={keyWord.key}>
					{buildQuery(keyWord)}
				</Grid>
			))}

			{!isMinimized && error && error.includes('total') && (
				<Grid item xs={12}>
					<div style={{ padding: '1em', color: '#d32f2f', fontFamily: 'Poppins' }}>{error}</div>
				</Grid>
			)}

			{!isMinimized && (
				<Grid item xs={12}>
					<Divider style={{ borderColor: 'rgba(29, 138, 248, 0.2)', height: '0.5px', width: '100%', marginTop: '0.5em' }} />
					<Button style={{ fontWeight: 'bold', fontFamily: 'Poppins' }} fullWidth onClick={onSaveQuery}>
						SALVAR
					</Button>
				</Grid>
			)}
			{confirmDelete && (
				<DialogConfirmation
					open={confirmDelete}
					handleClose={toggleDelete}
					handleConfirm={() => {
						toggleDelete()
						handleClickDelete(id)
					}}
					color={RED}
					title={'Deseja realmente deletar essa coleta?'}
					buttonText={'DELETAR'}
					icon={<DeleteBinIcon className='fix-icon-margin-bottom-negative' size={25} color={RED} style={{ marginRight: '8px' }} />}
				/>
			)}
			{showInfo && (
				<Dialog
					id='dialog-app'
					open={showInfo}
					onClose={() => setShowInfo(false)}
					PaperProps={{
						style: {
							borderRadius: '20px',
							backdropFilter: 'blur(10px)',
							backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
							border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
							boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
						}
					}}
					BackdropProps={{
						style: { backdropFilter: 'blur(3px)', backgroundColor: 'rgba(220, 220, 220, 0.4)' }
					}}
					fullWidth={true}
					maxWidth='md'
				>
					<Card style={{ margin: '20px', background: 'transparent' }} elevation={0}>
						<DialogTitle>
							<div className='jc-space-between d-flex ai-center'>
								<div style={{ color: BLUE }}>
									<InfoIcon className='fix-icon-margin-bottom-negative' style={{ marginRight: '8px' }} size={25} color={BLUE} />A
									coleta {title} do seu cartão {cardName} vai buscar:
								</div>
								<div>
									<IconButton color='primary' style={{ outline: 'none' }} onClick={() => setShowInfo(false)}>
										<CloseIcon color='#1d8cf8' size={20} />
									</IconButton>
								</div>
							</div>
						</DialogTitle>
						<DialogContent>
							<Grid container spacing={3} style={{ marginTop: '1em' }}>
								{queryObject?.termsAnd?.length > 0 && (
									<Grid item xs={12} style={{ fontFamily: 'Poppins' }}>
										{queryObject?.termsAnd?.length === 1
											? 'Todas as menções que contenham a palavra:'
											: 'Todas as menções que contenham simultaneamente as palavras:'}
										<div style={{ marginTop: '8px' }}>
											{queryObject?.termsAnd?.map((it, index) => formatQuery(it, BLUE, null, index))}
										</div>
									</Grid>
								)}
								{queryObject?.termsOr?.length > 0 && (
									<Grid item xs={12} style={{ fontFamily: 'Poppins' }}>
										{queryObject?.termsOr?.length === 1
											? 'E ao mesmo tempo contenham a palavra:'
											: 'E ao mesmo tempo contenham pelo menos umas das palavras:'}

										<div style={{ marginTop: '8px' }}>{queryObject?.termsOr?.map((it) => formatQuery(it, GRAY, null))}</div>
									</Grid>
								)}
								{queryObject?.termsNot?.length > 0 && (
									<Grid item xs={12} style={{ fontFamily: 'Poppins' }}>
										{queryObject?.termsNot?.length === 1 ? 'E não contenham a palavra:' : 'E não contenham nenhuma das palavras:'}
										<div style={{ marginTop: '8px' }}>{queryObject?.termsNot?.map((it) => formatQuery(it, RED, null))}</div>
									</Grid>
								)}
							</Grid>
						</DialogContent>
					</Card>
				</Dialog>
			)}
		</Card>
	)
}

export default CardQuery
