import React from 'react'
import AuraReportCard from './components/AuraReportCard/AuraReportCard'

const AuraSightReportFeed = () => {
	return (
		<>
			<div>
				<AuraReportCard path={'/sharing/aura-report'} title={'Flamengo Julho 2024'} />
			</div>
			<div>
				<AuraReportCard path={'/sharing/aura-report-2'} title={'Atletico Mineiro Novembro 2024'} />
			</div>
		</>
	)
}

export default AuraSightReportFeed
