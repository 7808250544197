import React, { useState, useEffect, useRef, memo } from 'react'
import LoadingBar from 'components/LoadingBar/LoadingBar'
import { Grid, Card } from '@mui/material'
import GraphTitle from 'components/GraphTitle/GraphTitle'
import MiniCardAllBySourceNumber from '../MiniCardAllBySourceNumber/MiniCardAllBySourceNumber'
import { TwitterIcon, BlueskyIcon, FacebookIcon, InstagramIcon, TiktokIcon, YoutubeIcon, NewsIcon } from 'utils/SystemIcons'
import MiniCardAllBySourceNumberComp from '../MiniCardAllBySourceNumberComp/MiniCardAllBySourceNumberComp'
import InspectOverviewButton from 'graphs/components/InspectOverviewButton/InspectOverviewButton'
import MiniCardSkeletonOverviewNumber from 'components/SkeletonLoads/MiniCardSkeletonOverviewNumber/MiniCardSkeletonOverviewNumber'

const CardAllBySourceNumber = ({
	data,
	title,
	loading = false,
	isTwitter = false,
	isBluesky = false,
	isFacebook = false,
	isInstagram = false,
	isTiktok = false,
	isYoutube = false,
	isNews = false,
	cards,
	isFetchingData,
	filters,
	isSocialProfile,
	cardType,
	height = '265.5px',
	disabledSummary,
	cardIds,
	xs
}) => {
	const loadingBarRef = useRef()
	const [hardLoading, setHardLoading] = useState(true)
	const isMounted = useRef(true)

	const iconSize = 16

	const selectIcon = () => {
		if (title === 'twitter') {
			return <TwitterIcon size={iconSize} style={{ color: '#1d8cf8' }} />
		} else if (title === 'bluesky') {
			return <BlueskyIcon size={iconSize} style={{ color: '#1d8cf8' }} />
		} else if (title === 'facebook') {
			return <FacebookIcon size={iconSize} style={{ color: '#1d8cf8' }} />
		} else if (title === 'instagram') {
			return <InstagramIcon size={iconSize} style={{ color: '#1d8cf8' }} />
		} else if (title === 'tiktok') {
			return <TiktokIcon size={iconSize} style={{ color: '#1d8cf8' }} />
		} else if (title === 'youtube') {
			return <YoutubeIcon size={iconSize} style={{ color: '#1d8cf8' }} />
		} else {
			return <NewsIcon size={iconSize} style={{ color: '#1d8cf8' }} />
		}
	}

	useEffect(() => {
		isMounted.current = true

		if (isMounted.current) {
			loadingBarRef?.current?.load(loading && !hardLoading)
		}

		if (!loading) {
			setHardLoading(false)
		}

		return () => {
			isMounted.current = false
		}
	}, [loading])

	return (
		<Grid item xs={xs || 4}>
			<Card style={{ borderRadius: '20px', height: cardType !== 'group' ? '100%' : '40vh' }}>
				<Grid container spacing={3} style={{ background: '#fbfbfb', height: '70px', borderBottom: '1px solid #f5f5f5' }}>
					<Grid item container xs>
						<GraphTitle id={`span-minicard-${title}`} icon={selectIcon()} title={title} titleCentered={true} />
					</Grid>
				</Grid>
				<div>
					<LoadingBar ref={loadingBarRef} />
				</div>
				{hardLoading && <MiniCardSkeletonOverviewNumber height={height} />}
				{data && data.length > 1
					? !hardLoading && <MiniCardAllBySourceNumberComp data={data} isGroup={cardType === 'group'} cardIds={cardIds} />
					: !hardLoading && <MiniCardAllBySourceNumber data={data} isGroup={cardType === 'group'} />}
				{!hardLoading && (
					<Grid container style={{ marginBottom: '10px', marginTop: '10px', justifyContent: 'center' }}>
						{cardType !== 'group' && (
							<InspectOverviewButton
								cards={cards}
								isFetchingData={isFetchingData}
								filters={filters}
								isTwitter={isTwitter}
								isBluesky={isBluesky}
								isFacebook={isFacebook}
								isInstagram={isInstagram}
								isTiktok={isTiktok}
								isYoutube={isYoutube}
								isNews={isNews}
								isSocialProfile={isSocialProfile}
								disabled={disabledSummary}
							/>
						)}
					</Grid>
				)}
			</Card>
		</Grid>
	)
}

export default memo(CardAllBySourceNumber)
