import React, { useState, useEffect, useRef, memo } from 'react'
import { FiDownload, FiBarChart2 } from 'react-icons/fi'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import LoadingBar from 'components/LoadingBar/LoadingBar'
import TwitterSummarySkeletonLoad from 'components/SkeletonLoads/TwitterSummarySkeletonLoad/TwitterSummarySkeletonLoad'
import { downloadAsImage } from 'utils/downloadAsImage'
import SummaryMetrics from './components/SummaryMetrics/SummaryMetrics'
import SummaryMetricsComp from './components/SummaryMetricsComp/SummaryMetricsComp'
import { useUpdateData } from 'hooks/ReactQueryHooks'
import { Card, CardContent, IconButton, Tooltip, Grid } from '@mui/material'
import GraphTitle from 'components/GraphTitle/GraphTitle'
import { verifyServerResponseCanShowToast } from 'utils/generalFunctions'
import { connect } from 'react-redux'

const Summary = ({ cards, isReport = false, isFetchingData = true, globalFiltersTermRedux, filtersReport }) => {
	const [loading, setLoading] = useState(true)
	const [fullScreenState, setFullScreenState] = useState(false)
	const [thisFilters, setThisFilters] = useState(filtersReport || globalFiltersTermRedux)
	let isComparative = cards.length > 1
	let isCardProfile = cards?.every((card) => card?.type === 'profile')

	const getBodySummary = () => ({
		configGlobalFilters: {
			cardIds: cards?.map((card) => card.id),
			cardType: cards[0].type.toUpperCase()
		},
		globalFilters: thisFilters
	})
	let { data, error, isFetching, isFetched } = useUpdateData({
		url: '/summary',
		updateItemName: 'Summary' + cards?.map((card) => card.id),
		refetchInterval: 600000,
		freshDataTime: 600000,
		isEnabled: isFetchingData,
		queryName: 'relevanceChange_useUpdateData',
		method: 'post',
		body: getBodySummary()
	})

	const handle = useFullScreenHandle()
	const loadingBarRef = useRef()

	useEffect(() => {
		if (!loading) {
			loadingBarRef.current.load(isFetching)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFetching])

	useEffect(() => {
		if (isFetched) {
			setLoading(false)
		}
	}, [isFetched])

	useEffect(() => {
		if (globalFiltersTermRedux && !filtersReport) {
			setThisFilters(globalFiltersTermRedux)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [globalFiltersTermRedux])

	if (verifyServerResponseCanShowToast(error) && !isReport) {
		sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
	}

	return (
		<Grid item xs={12}>
			<FullScreen handle={handle} onChange={() => setFullScreenState(!fullScreenState)}>
				<div id='resumo'>
					<Card style={{ height: '100%', borderRadius: '20px' }} elevation={isReport ? 0 : 1}>
						<LoadingBar ref={loadingBarRef} />
						{!isReport && (
							<CardContent>
								<Grid data-html2canvas-ignore container spacing={3}>
									<Grid item xs={6}>
										<GraphTitle
											id='span-summary'
											title={`Resumo ${isComparative && data.length === 1 ? `(${data[0].name})` : ''}`}
											icon={<FiBarChart2 size={25} style={{ color: '#1d8cf8' }} />}
											description='Principais métricas de cada rede social para o intervalo de tempo escolhido.'
										/>
									</Grid>
								</Grid>
							</CardContent>
						)}
						{!loading && (
							<CardContent style={{ marginTop: fullScreenState && !isReport ? '-55px' : isReport ? '-15px' : '1%' }}>
								{!isReport && (
									<Grid data-html2canvas-ignore item xs={12}>
										<Tooltip title='Baixar como PNG'>
											<IconButton
												color='primary'
												onClick={() => downloadAsImage(document.getElementById('resumo'), 'resumo')}
												style={{ outline: 'none', float: 'right', marginRight: '17px' }}
											>
												<FiDownload size={20} style={{ color: '#1d8cf8' }} />
											</IconButton>
										</Tooltip>
									</Grid>
								)}
								<div style={{ marginTop: '4em' }}>
									<>
										{data.length === 1 && (
											<SummaryMetrics
												data={data[0]}
												isReport={isReport}
												hideSentimentGraph={
													thisFilters?.classification === 'automatic' && !thisFilters.sources.includes('twitter')
												}
												sources={thisFilters.sources}
											/>
										)}
										{data.length > 1 && (
											<SummaryMetricsComp
												data={data}
												isReport={isReport}
												hideSentimentGraph={
													thisFilters?.classification === 'automatic' && !thisFilters.sources.includes('twitter')
												}
												sources={thisFilters.sources}
												cardIds={cards?.map((card) => card.id)}
												isCardProfile={isCardProfile}
											/>
										)}
									</>
								</div>
							</CardContent>
						)}
						{loading && (
							<CardContent>
								<TwitterSummarySkeletonLoad />
							</CardContent>
						)}

						{!loading && !isReport && cards?.every((card) => card?.type === 'term' && card?.searchType === 'TERM') && (
							<Grid data-html2canvas-ignore container style={{ marginTop: '-25px' }}>
								<Grid item xs={12}>
									<div style={{ textAlign: 'right' }}>
										<h5
											style={{
												font: 'Poppins',
												fontWeight: 'lighter',
												paddingRight: '30px',
												color: 'rgba(220,220,220,100%)'
											}}
										>
											Última atualização: {data[0]?.lastUpdate}
										</h5>
									</div>
								</Grid>
							</Grid>
						)}
					</Card>
				</div>
			</FullScreen>
		</Grid>
	)
}

const mapStateToProps = ({ store }) => ({
	globalFiltersTermRedux: store.global.filters
})

export default connect(mapStateToProps)(memo(Summary))
