import React, { memo } from 'react'
import { Grid, Card, Slide } from '@mui/material'
import ServiceHistoryContent from './components/ServiceHistoryContent/ServiceHistoryContent'
const isFirefox = typeof InstallTrigger !== 'undefined'

const ServiceHistory = ({
	isOpen,
	cardType,
	serviceName,
	cardName,
	cardId,
	toggleOpenHistory,
	showFilterBar = false,
	onClickHistoryItem,
	style,
	sources,
	height,
	applyHistory = null,
	customTitle,
	rightButton = false,
	isShareUrl = false
}) => {
	return (
		<Slide direction={rightButton ? 'left' : 'right'} in={isOpen} mountOnEnter unmountOnExit>
			<Grid style={{ position: 'absolute', zIndex: '100', ...style }}>
				<Card
					style={{
						borderRadius: '20px',
						backdropFilter: 'blur(10px)',
						backgroundColor: `${isFirefox ? 'rgba(245, 245, 245, 1)' : 'rgba(245, 245, 245, 0.95)'}`,
						boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )',
						height: '100%'
					}}
				>
					<ServiceHistoryContent
						height={height}
						toggleOpenHistory={toggleOpenHistory}
						cardType={cardType}
						cardId={cardId}
						cardName={cardName}
						serviceName={serviceName}
						onClickHistoryItem={onClickHistoryItem}
						showFilterBar={showFilterBar}
						sources={sources}
						applyHistory={applyHistory}
						customTitle={customTitle}
						rightButton={rightButton}
						isShareUrl={isShareUrl}
					/>
				</Card>
			</Grid>
		</Slide>
	)
}

export default memo(ServiceHistory)
