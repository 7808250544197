import React, { useState, memo, useEffect } from 'react'
import { Grid, Button, Slide, Card } from '@mui/material'
import lodash from 'lodash'
import api from 'service/service'
import constantsVox from 'constants-vox'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import { getWidgetsDefaultByCardType, getLayoutsDefaultByCardType } from './utils/DashboardShareableUtils'
import ToolBarDashboardShareable from './components/ToolBarDashboardShareable/ToolBarDashboardShareable'
import DashboardShareableAccountManager from './modules/DashboardShareableAccountManager/DashboardShareableAccountManager'
import DashboardShareableTerm from './modules/DashboardShareableTerm/DashboardShareableTerm'
import './widgets.css'

const isFirefox = typeof InstallTrigger !== 'undefined'

const DashboardShareable = ({ cardList, globalFilters, isSharing, sharingDashboard, cardType }) => {
	const WIDGETS_DEFAULT = getWidgetsDefaultByCardType(cardType)
	const LAYOUTS_DEFAULT = getLayoutsDefaultByCardType(cardType)
	const [loadingLayout, setLoadingLayout] = useState(true)
	const [currentLayout, setCurrentLayout] = useState(sharingDashboard?.layouts || LAYOUTS_DEFAULT)
	const [savedLayoutsList, setSavedLayoutsList] = useState([])
	const [layoutName, setLayoutName] = useState('')
	const [editMode, setEditMode] = useState(false)
	const [addWidgetMode, setAddWidgetMode] = useState(false)
	const [widgetsOnScreen, setWidgetsOnscreen] = useState(sharingDashboard?.active_widgets || WIDGETS_DEFAULT)
	const [initialLayout, setInitialLayout] = useState({})

	useEffect(() => {
		loadInitialLayout()
	}, [])

	const loadInitialLayout = async () => {
		setLoadingLayout(true)
		if (isSharing) {
			setWidgetsOnscreen(sharingDashboard.active_widgets)
			setCurrentLayout(sharingDashboard.layouts)
			setInitialLayout(sharingDashboard.layouts)
		} else {
			api.get(`/dashboard-shareable/get-initial-layout/${cardType}/${cardList[0].id}`)
				.then((res) => {
					if (res?.data) {
						setWidgetsOnscreen(res?.data?.active_widgets)
						setCurrentLayout(res?.data)
						setInitialLayout(res?.data)
					}
				})
				.finally(() => {
					setLoadingLayout(false)
				})
		}
	}

	const onSaveLayout = async (referenceId, dashboardLayoutId) => {
		if (!referenceId && (!layoutName || layoutName === '')) {
			return alert('Nome precisa ser preenchido')
		}
		setLoadingLayout(true)
		const verifiedLayoutId = referenceId ? initialLayout?.id || null : dashboardLayoutId

		api.post('/dashboard-shareable/save-layout', {
			name: layoutName,
			productType: cardType,
			referenceId,
			layouts: currentLayout.layouts,
			dashboardLayoutId: verifiedLayoutId,
			activeWidgets: widgetsOnScreen
		})
			.then((res) => {
				setCurrentLayout(res?.data?.data)
				if (referenceId) {
					setInitialLayout(res?.data?.data)
				}
				if (res?.data?.toast) {
					sendMessageToast(res?.data?.toast?.message, res?.data?.toast?.type)
				}
			})
			.finally(() => {
				setLoadingLayout(false)
			})
	}

	const onGetSavedLayouts = () => {
		setLoadingLayout(true)
		api.get(`/dashboard-shareable/get-saved-layouts/${cardType}`)
			.then((res) => {
				if (res?.data) {
					setSavedLayoutsList(res?.data)
				}
			})
			.finally(() => {
				setLoadingLayout(false)
			})
	}

	const onDeleteLayout = (dashboardLayoutId) => {
		setLoadingLayout(true)
		api.post('/dashboard-shareable/delete-layout', { dashboardLayoutId })
			.finally(() => {
				setLoadingLayout(false)
			})
			.finally(() => setLayoutName(''))
	}

	const onDefaultLayout = () => {
		setWidgetsOnscreen(WIDGETS_DEFAULT)
		setCurrentLayout({ ...currentLayout, ...LAYOUTS_DEFAULT })
		setLayoutName('')
	}

	const onLayoutApply = (applyLayoutInfo) => {
		setWidgetsOnscreen(applyLayoutInfo.active_widgets)
		setLayoutName(applyLayoutInfo.name)
		setCurrentLayout(applyLayoutInfo)
	}

	const onChangeTemplateName = (event) => {
		setLayoutName(event.target.value)
	}

	const handleOnLayoutChange = (layouts, source) => {
		let newLayouts = lodash.cloneDeep(currentLayout)
		newLayouts.layouts[source] = layouts
		setCurrentLayout(newLayouts)
	}

	const toggleEditMode = () => {
		setEditMode(!editMode)
	}

	const deactivateEditMode = () => {
		setEditMode(false)
	}

	const toggleAddWidgetMode = () => {
		setAddWidgetMode(!addWidgetMode)
	}

	const handleWidgetsOnScreen = (widgets, source) => {
		setWidgetsOnscreen({ ...widgetsOnScreen, [source]: widgets })
	}

	const handleRemoveWidget = (widget, source) => {
		const newWidgets = widgetsOnScreen[source].filter((it) => it !== widget)
		setWidgetsOnscreen({ ...widgetsOnScreen, [source]: newWidgets })
	}

	return (
		<>
			{cardList.length > 1 ? (
				<Card
					style={{
						borderRadius: '20px',
						height: '60vh',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						fontFamily: 'Poppins',
						fontSize: '24px',
						color: '#bdbbbb'
					}}
				>
					Não disponível para comparações
				</Card>
			) : (
				<>
					{!isSharing && (
						<ToolBarDashboardShareable
							loadingLayout={loadingLayout}
							onDefaultLayout={onDefaultLayout}
							onSaveLayout={onSaveLayout}
							cardList={cardList}
							currentLayout={currentLayout}
							onGetSavedLayouts={onGetSavedLayouts}
							layoutName={layoutName}
							savedLayoutsList={savedLayoutsList}
							onChangeTemplateName={onChangeTemplateName}
							onLayoutApply={onLayoutApply}
							onDeleteLayout={onDeleteLayout}
							editMode={editMode}
							toggleEditMode={toggleEditMode}
							addWidgetMode={addWidgetMode}
							toggleAddWidgetMode={toggleAddWidgetMode}
							deactivateEditMode={deactivateEditMode}
							activeWidgets={widgetsOnScreen}
							isTerm={cardType === constantsVox.CARD_TYPE.TERM}
						/>
					)}
					<Grid item xs={12}>
						<div style={{ marginTop: '1em' }}>
							{cardType === constantsVox.CARD_TYPE.ACCOUNT_MANAGEMENT && (
								<DashboardShareableAccountManager
									currentLayout={currentLayout}
									handleOnLayoutChange={handleOnLayoutChange}
									editMode={editMode}
									addWidgetMode={addWidgetMode}
									toggleAddWidgetMode={toggleAddWidgetMode}
									widgetsOnScreen={widgetsOnScreen}
									handleWidgetsOnScreen={handleWidgetsOnScreen}
									handleRemoveWidget={handleRemoveWidget}
									cardList={cardList}
									globalFilters={globalFilters}
									cardType={cardType}
								/>
							)}
							{cardType === constantsVox.CARD_TYPE.TERM && (
								<DashboardShareableTerm
									currentLayout={currentLayout}
									handleOnLayoutChange={handleOnLayoutChange}
									editMode={editMode}
									addWidgetMode={addWidgetMode}
									toggleAddWidgetMode={toggleAddWidgetMode}
									widgetsOnScreen={widgetsOnScreen?.term}
									handleWidgetsOnScreen={handleWidgetsOnScreen}
									handleRemoveWidget={handleRemoveWidget}
									cardList={cardList}
									globalFilters={globalFilters}
								/>
							)}
						</div>
					</Grid>
					{!isSharing && (
						<Slide direction='up' in={editMode && !addWidgetMode} mountOnEnter unmountOnExit>
							<Button
								onClick={toggleEditMode}
								style={{
									position: 'fixed',
									display: 'flex',
									justifyContent: 'center',
									bottom: '0',
									right: '44.6vw',
									marginBottom: '2em',
									fontFamily: 'Poppins',
									borderRadius: '100px',
									backdropFilter: 'blur(10px)',
									backgroundColor: `${isFirefox ? 'rgba(245, 245, 245, 1)' : 'rgba(245, 245, 245, 0.6)'}`,
									border: '1px solid rgba(29, 140, 248, 0.1)',
									boxShadow: '0 3px 5px 0 rgba(200, 200, 200, 0.7)',
									height: '4em',
									fontWeight: 'bold',
									paddingLeft: '1em',
									paddingRight: '1em'
								}}
							>
								Finalizar Edição
							</Button>
						</Slide>
					)}
				</>
			)}
		</>
	)
}

export default memo(DashboardShareable)
