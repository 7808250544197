import React, { useState, useEffect, memo } from 'react'
import { Grid, Collapse, Card, Button, Divider } from '@mui/material'
import { cloneDeep } from 'lodash'
import {
	UserIcon,
	ChevronDownIcon,
	ChevronUpIcon,
	FollowersIcon,
	LikeIcon,
	NewsIcon,
	GenderIcon,
	SearchIcon,
	MediaIcon,
	PostAddIcon
} from 'utils/SystemIcons'
import { ADVANCED_GLOBAL_FILTERS_DEFAULT } from 'store/redux/reducers/utils/mainUtils'
import SearchBarGlobalFilters from '../SearchBarGlobalFilters/SearchBarGlobalFilters'
import CheckButtonGroup from '../../../CheckButtonGroup/CheckButtonGroup'
import LocationGlobalFilters from '../LocationGlobalFilters/LocationGlobalFilters'
import RangeGlobalFilters from '../RangeGlobalFilters/RangeGlobalFilters'
import SearchUsers from 'components/SearchUsers/SearchUsers'
import MediaSelect from '../MediaSelect/MediaSelect'

const searchUserIcon = <UserIcon size={20} style={{ color: '#1d8cf8', marginTop: '1px' }} />
const newsIcon = <NewsIcon size={20} style={{ color: '#1d8cf8', marginTop: '1px' }} />
const genderIcon = <GenderIcon size={25} style={{ color: '#1d8cf8', marginRight: '2px' }} />
const searchIcon = <SearchIcon size={20} style={{ color: '#1d8cf8', marginRight: '3px' }} />
const mediaIcon = <MediaIcon size={20} style={{ color: '#1d8cf8', marginRight: '3px' }} />
const postAddIcon = <PostAddIcon size={20} style={{ color: '#1d8cf8', marginRight: '3px' }} />

const AdvancedGlobalFilters = ({ valueControlled, show, onChange, openAdvancedFilters, toggleAdvancedFilters, cards }) => {
	const getOriginalAndVerifiedControlledStates = () => [
		{ label: 'autores originais', checked: filters.contentType === 'original' },
		{ label: 'usuários verificados', checked: filters.authorIsVerified === 'verified' }
	]
	const getGenderControlledStates = () => [
		{ label: 'Masculino', checked: filters.authorGender.male },
		{ label: 'Feminino', checked: filters.authorGender.female },
		{ label: 'Organização', checked: filters.authorGender.organization }
	]
	const getInsertPostTypeControlledStates = () => [
		{ label: 'Sistema', checked: filters.insertPostType.system },
		{ label: 'Manual', checked: filters.insertPostType.manual }
	]
	const [filters, setFilters] = useState(valueControlled || ADVANCED_GLOBAL_FILTERS_DEFAULT)
	const [originalAndVerifiedControlledStates, setOriginalAndVerifiedControlledStates] = useState(getOriginalAndVerifiedControlledStates())
	const [genderControlledStates, setGenderControlledStates] = useState(getGenderControlledStates())
	const [mediaControlledStates, setMediaControlledStates] = useState(filters.contentAttachmentsType)
	const [insertPostTypeControlledStates, setInsertPostTypeControlledStates] = useState(getInsertPostTypeControlledStates())
	const [locationControlled, setLocationControlled] = useState(filters.authorLocation)
	const [fieldErrors, setFieldErrors] = useState({})

	useEffect(() => {
		setOriginalAndVerifiedControlledStates(getOriginalAndVerifiedControlledStates())
		setGenderControlledStates(getGenderControlledStates())
		setMediaControlledStates(filters.contentAttachmentsType)
		setInsertPostTypeControlledStates(getInsertPostTypeControlledStates())
		setLocationControlled(filters.authorLocation)
	}, [])

	useEffect(() => {
		onChange(filters)
		setOriginalAndVerifiedControlledStates(getOriginalAndVerifiedControlledStates())
		setGenderControlledStates(getGenderControlledStates())
		setMediaControlledStates(filters.contentAttachmentsType)
		setInsertPostTypeControlledStates(getInsertPostTypeControlledStates())
		setLocationControlled(filters.authorLocation)
	}, [filters])

	useEffect(() => {
		if (JSON.stringify(valueControlled) !== JSON.stringify(filters)) {
			setFilters(valueControlled)
		}
	}, [valueControlled])

	const onChangeUserSearch = (newUsersFilter) => {
		setFilters({ ...filters, authorNameSearch: newUsersFilter })
	}

	const onChangeauthorDescriptionSearch = (newText) => {
		setFilters({ ...filters, authorDescriptionSearch: newText === '' ? null : newText })
	}

	const onFollowersChange = (newFollowers) => {
		let formattedFollowers = {
			higher: newFollowers.higher,
			lower: newFollowers.lower
		}
		setFilters({ ...filters, authorFollowers: formattedFollowers })
	}

	const onChangeOriginalOrVerified = (list) => {
		let finalFilters = cloneDeep(filters)
		list.forEach((item) => {
			if (item.label === 'usuários verificados') {
				const filterCheckedVerified = finalFilters.authorIsVerified === 'verified'
				if (item.checked !== filterCheckedVerified) {
					finalFilters = { ...finalFilters, authorIsVerified: item.checked ? 'verified' : 'all' }
				}
			}
			if (item.label === 'autores originais') {
				const filterCheckedOriginal = finalFilters.contentType === 'original'
				if (item.checked !== filterCheckedOriginal) {
					finalFilters = { ...finalFilters, contentType: item.checked ? 'original' : 'all' }
				}
			}
			setFilters(finalFilters)
		})
	}

	const onChangeGender = (list) => {
		list.forEach((item) => {
			let filterCheckedMale = filters.authorGender.male
			let filterCheckedFemale = filters.authorGender.female
			let filterCheckedOrganization = filters.authorGender.organization
			if (item.label === 'Masculino') {
				if (item.checked !== filterCheckedMale) {
					setFilters({ ...filters, authorGender: { ...filters.authorGender, male: item.checked } })
				}
			}
			if (item.label === 'Feminino') {
				if (item.checked !== filterCheckedFemale) {
					setFilters({ ...filters, authorGender: { ...filters.authorGender, female: item.checked } })
				}
			}
			if (item.label === 'Organização') {
				if (item.checked !== filterCheckedOrganization) {
					setFilters({ ...filters, authorGender: { ...filters.authorGender, organization: item.checked } })
				}
			}
		})
	}

	const onChangeInsertPostType = (list) => {
		let finalFilters = cloneDeep(filters)
		const anyTrue = list.some((item) => item.checked === true)
		if (!anyTrue) {
			const message = 'Pelo menos um tipo de inserção de post deve estar selecionado, voltará para a última seleção válida'
			setFieldErrors({ ...fieldErrors, insertPostType: message })
			return
		} else {
			setFieldErrors({ ...fieldErrors, insertPostType: null })
		}

		list.forEach((item) => {
			if (item.label === 'Sistema') {
				const filterCheckedSystem = finalFilters.insertPostType.system
				if (item.checked !== filterCheckedSystem) {
					finalFilters = { ...finalFilters, insertPostType: { ...finalFilters.insertPostType, system: item.checked } }
					setFilters(finalFilters)
				}
			}
			if (item.label === 'Manual') {
				const filterCheckedManual = finalFilters.insertPostType.manual
				if (item.checked !== filterCheckedManual) {
					finalFilters = { ...finalFilters, insertPostType: { ...finalFilters.insertPostType, manual: item.checked } }
					setFilters(finalFilters)
				}
			}
		})
	}

	const onChangeMedia = (value) => {
		setFilters({ ...filters, contentAttachmentsType: { ...filters.contentAttachmentsType, mediaQuery: value } })
	}

	const onChangeLocation = (authorLocation) => {
		setFilters({ ...filters, authorLocation })
	}

	const onLikesChange = (newLikes) => {
		let formattedAuthorLikes = {
			higher: newLikes.higher,
			lower: newLikes.lower
		}
		setFilters({ ...filters, authorLikes: formattedAuthorLikes })
	}

	const onChangeDomainSearch = (newText) => {
		setFilters({ ...filters, authorDomainSearch: newText })
	}

	const onChangeFindUrl = (newText) => {
		setFilters({ ...filters, contentUrlSearch: newText })
	}

	return (
		<Collapse in={show} style={{ width: '100%' }}>
			<Card
				elevation={0}
				style={{
					borderRadius: '25px',
					marginTop: '1em',
					width: '100%',
					backgroundColor: 'rgba(255,255,255,0.6)',
					border: '1px solid rgba(200, 200, 200, 0.2)'
				}}
			>
				<Grid container item xs={12}>
					<Button
						id='btn-toggle-filter'
						onClick={toggleAdvancedFilters}
						fullWidth
						style={{ fontFamily: 'Poppins', fontWeight: 'bold', color: '#1d8cf8', justifyContent: 'flex-start' }}
					>
						{!openAdvancedFilters && <ChevronDownIcon size={20} style={{ marginRight: '10px' }} />}
						{openAdvancedFilters && <ChevronUpIcon size={20} style={{ marginRight: '10px' }} />}
						Filtros avançados
					</Button>
				</Grid>

				<Collapse in={openAdvancedFilters}>
					<div style={{ margin: '15px' }}>
						<RangeGlobalFilters
							icon={<FollowersIcon size={25} style={{ color: '#1d8cf8', marginRight: '2px' }} />}
							title={'Número de seguidores'}
							onChange={onFollowersChange}
							value={filters.authorFollowers}
							id='input-filter-followers'
						/>
						<Grid container item xs={12} style={{ marginTop: '30px' }}>
							<RangeGlobalFilters
								icon={<LikeIcon size={20} style={{ color: '#1d8cf8', marginRight: '5px' }} />}
								title={'Número de likes do autor'}
								onChange={onLikesChange}
								value={filters.authorLikes}
								id='input-filter-likes'
							/>
						</Grid>
						<Grid container item xs={12} style={{ marginTop: '15px' }}>
							<SearchUsers
								id='input-user-search-users'
								style={{ padding: '0em 1em 0em 1em' }}
								searchTags={filters.authorNameSearch}
								onChangeTags={onChangeUserSearch}
								icon={searchUserIcon}
								title='Buscar usuários'
								cards={cards}
							/>
						</Grid>
						<Grid container item xs={12} style={{ marginTop: '15px' }}>
							<SearchBarGlobalFilters
								onSearchChange={onChangeauthorDescriptionSearch}
								contentTextSearch={filters.authorDescriptionSearch || ''}
								title='Buscar na bio'
								icon={searchUserIcon}
								id='input-search-text-user-bio'
							/>
						</Grid>
						<Grid container item xs={12} style={{ marginTop: '15px' }}>
							<SearchBarGlobalFilters
								onSearchChange={onChangeDomainSearch}
								contentTextSearch={filters.authorDomainSearch}
								title='Buscar domínio de notícias'
								icon={newsIcon}
								isQuerySearch={false}
								id='input-search-text-news-domain'
							/>
						</Grid>
						<Grid container item style={{ marginTop: '15px' }}>
							<SearchBarGlobalFilters
								onSearchChange={onChangeFindUrl}
								contentTextSearch={filters.contentUrlSearch}
								title='Buscar url do post'
								icon={newsIcon}
								id='input-search-text-news-url'
							/>
						</Grid>
						<Grid container item xs={12} style={{ marginTop: '2em' }}>
							<Grid
								container
								item
								xs={12}
								style={{
									fontFamily: 'Poppins',
									fontSize: '15px',
									color: 'rgba(0, 0, 0, 0.65)'
								}}
							>
								{searchIcon}
								Buscar
							</Grid>
							<CheckButtonGroup checkedControlled={originalAndVerifiedControlledStates} onChange={onChangeOriginalOrVerified} />
							<Divider style={{ backgroundColor: 'rgba(0, 0, 0, 0.01)', height: '0.5px', width: '100%', marginTop: '-1px' }} />
						</Grid>
						<Grid container item xs={12} style={{ marginTop: '2em' }}>
							<Grid
								container
								item
								xs={12}
								style={{
									fontFamily: 'Poppins',
									fontSize: '15px',
									color: 'rgba(0, 0, 0, 0.65)'
								}}
							>
								{genderIcon}
								Gênero
							</Grid>
							<CheckButtonGroup checkedControlled={genderControlledStates} onChange={onChangeGender} />
							<Divider style={{ backgroundColor: 'rgba(0, 0, 0, 0.01)', height: '0.5px', width: '100%', marginTop: '-1px' }} />
						</Grid>
						<Grid container item xs={12} style={{ marginTop: '2em' }}>
							<Grid
								container
								item
								xs={12}
								style={{
									fontFamily: 'Poppins',
									fontSize: '15px',
									color: 'rgba(0, 0, 0, 0.65)'
								}}
							>
								{mediaIcon}
								Mídia
							</Grid>
							<MediaSelect onChange={onChangeMedia} initialValue={mediaControlledStates.mediaQuery} />
						</Grid>
						<Grid container item xs={12} style={{ marginTop: '2em' }}>
							<Grid
								container
								item
								xs={12}
								style={{
									fontFamily: 'Poppins',
									fontSize: '15px',
									color: 'rgba(0, 0, 0, 0.65)'
								}}
							>
								{postAddIcon}
								Tipo de inserção de post
							</Grid>
							<CheckButtonGroup checkedControlled={insertPostTypeControlledStates} onChange={onChangeInsertPostType} />
							{fieldErrors?.insertPostType && (
								<div style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>{fieldErrors.insertPostType}</div>
							)}
							<Divider style={{ backgroundColor: 'rgba(0, 0, 0, 0.01)', height: '0.5px', width: '100%', marginTop: '-1px' }} />
						</Grid>
						<Grid container item style={{ marginTop: '2em' }}>
							<LocationGlobalFilters locationControlled={locationControlled} onChange={onChangeLocation} id='select-location-filter' />
						</Grid>
					</div>
				</Collapse>
			</Card>
		</Collapse>
	)
}

export default memo(AdvancedGlobalFilters)
