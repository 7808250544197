Moment.locale('pt-br')
import Moment from 'moment-timezone'
import React, { useEffect, useState, memo, useRef } from 'react'
import {
	Dialog,
	IconButton,
	DialogContent,
	DialogTitle,
	Button,
	Grid,
	Paper,
	Checkbox,
	Chip,
	Autocomplete,
	TextField,
	styled,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Tooltip
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { CloseIcon, PlusIcon, DeleteBinIcon } from 'utils/SystemIcons'
import SourceSelectorGlobalFilters from 'components/GlobalFilters/components/SourceSelectorGlobalFilters/SourceSelectorGlobalFilters'
import api from 'service/service'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import { verifyServerResponseCanShowToast } from 'utils/generalFunctions'
import { LuCalendarDays } from 'react-icons/lu'
import { CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon, CheckBox as CheckBoxIcon } from '@mui/icons-material'
import { capitalize } from 'lodash'
import constantsVox from 'constants-vox'

const grey = '#b8b8b8'
const blue = 'rgba(29, 138, 248, 100%)'
const white = '#ffffff'
const checkedIcon = <CheckBoxIcon fontSize='small' color='primary' />
const icon = <CheckBoxOutlineBlankIcon fontSize='small' color='primary' />

let lastOnDeleteTime = null

const useStyles = makeStyles({
	backDrop: {
		backdropFilter: 'blur(3px)',
		backgroundColor: 'rgba(220, 220, 220, 0.4)'
	},
	tab: {
		fontFamily: 'Poppins',
		fontSize: 12,
		color: grey
	}
})

const CssTextField = styled(TextField)({
	'& .MuiInputBase-root': {
		color: blue,
		fontFamily: 'Poppins',
		'& input': {
			textAlign: 'left'
		}
	}
})

const isFirefox = typeof InstallTrigger !== 'undefined'

const CustomPaper = (props) => {
	return (
		<Paper
			{...props}
			sx={{
				minWidth: '15em',
				borderRadius: '0px 0px 25px 25px',
				backdropFilter: 'blur(10px)',
				backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
				border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
				boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
			}}
		/>
	)
}

const fillHoursArray = (array) => {
	for (let i = 0; i < 24; i++) {
		const hour = i.toString().padStart(2, '0') // Converte para string e adiciona um zero à esquerda, se necessário
		array.push(`${hour}:00`)
	}
	return array
}

const dictionary = {
	Segunda: 'monday',
	Terça: 'tuesday',
	Quarta: 'wednesday',
	Quinta: 'thursday',
	Sexta: 'friday',
	Sábado: 'saturday',
	Domingo: 'sunday'
}

const dictionaryRule = {
	'Atualizar todas as postagens do dia atual até a hora do agendamento': constantsVox.CARD_PROFILE_UPDATE_POSTS.SCHEDULE.UPDATE_CURRENT_DAY,
	'Atualizar todas as postagens de ontem (dia anterior) ao longo do dia inteiro':
		constantsVox.CARD_PROFILE_UPDATE_POSTS.SCHEDULE.UPDATE_PREVIOUS_DAY,
	'Atualizar todas as postagens de 2 dias atrás ao longo do dia inteiro': constantsVox.CARD_PROFILE_UPDATE_POSTS.SCHEDULE.UPDATE_TWO_DAYS_AGO,
	'Atualizar todas as postagens de 3 dias atrás ao longo do dia inteiro': constantsVox.CARD_PROFILE_UPDATE_POSTS.SCHEDULE.UPDATE_THREE_DAYS_AGO
}

const dictionaryRuleMeaning = {
	'Atualizar todas as postagens do dia atual até a hora do agendamento': 'Refere-se ao dia de hoje e inclui as postagens até o momento atual',
	'Atualizar todas as postagens de ontem (dia anterior) ao longo do dia inteiro': 'Refere-se a todo o dia de ontem',
	'Atualizar todas as postagens de 2 dias atrás ao longo do dia inteiro': 'Refere-se a dois dias antes da data atual',
	'Atualizar todas as postagens de 3 dias atrás ao longo do dia inteiro': 'Refere-se a três dias antes da data atual'
}

const ModalScheduleUpdatePosts = ({ selectedSources, onSourceChange, isOpen, handleOnClose, card, color }) => {
	const classes = useStyles()
	const autocompleteRef = useRef()
	const [days, setDays] = useState([])
	const [hours, setHours] = useState([])
	const [rule, setRule] = useState('')
	const [error, setError] = useState(false)
	const [errorText, setErrorText] = useState('')
	const [scheduleList, setScheduleList] = useState()
	const hoursArray = fillHoursArray([])

	useEffect(() => {
		load()
	}, [])

	const load = async () => {
		await api
			.get(`/update-posts/schedule-update/history/${card.id}/${card.type}`)
			.then((response) => {
				setScheduleList(response?.data)
			})
			.catch((error) => {
				sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
			})
	}

	const createScheduleUpdatePosts = () => {
		if (days.length === 0 || hours.length === 0) {
			setError(true)
			setErrorText('Este campo deve ser preenchido')
			return
		}
		const body = {
			days: days?.map((day) => dictionary[day]),
			hours: hours?.map((time) => parseInt(time.split(':')[0], 10)),
			rule: dictionaryRule[rule],
			sources: selectedSources,
			cardId: card.id,
			cardType: card.type
		}
		api.post('/update-posts/schedule-update/create', body)
			.then((response) => {
				if (response?.data?.toast) {
					sendMessageToast(response?.data?.toast?.message, response?.data?.toast?.type)
				}
				setDays([])
				setHours([])
				setRule('')
				load()
			})
			.catch((error) => {
				if (verifyServerResponseCanShowToast(error)) {
					sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
				}
			})
	}

	const updateScheduleUpdatePosts = (value, scheduleUpdatePostsId) => {
		const body = {
			scheduleUpdatePostsId,
			active: value
		}
		api.post('/update-posts/schedule-update/update', body)
			.then((response) => {
				if (response?.data?.toast) {
					sendMessageToast(response?.data?.toast?.message, response?.data?.toast?.type)
				}
				load()
			})
			.catch((error) => {
				if (verifyServerResponseCanShowToast(error)) {
					sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
				}
			})
	}

	const deleteScheduleUpdatePosts = (scheduleUpdatePostsId) => {
		const body = {
			scheduleUpdatePostsId
		}
		api.post('/update-posts/schedule-update/delete', body)
			.then((response) => {
				if (response?.data?.toast) {
					sendMessageToast(response?.data?.toast?.message, response?.data?.toast?.type)
				}
				load()
			})
			.catch((error) => {
				if (verifyServerResponseCanShowToast(error)) {
					sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
				}
			})
	}

	const handleOnChangeValue = (value, type) => {
		const dateDiff = new Date().getTime() - lastOnDeleteTime
		if (dateDiff > 100) {
			if (type === 'days') {
				setDays(value)
			} else if (type === 'hours') {
				setHours(value)
			} else {
				setRule(value)
			}
		}
	}

	const optionRenderer = (props, option, { selected }) => {
		return (
			<li {...props} key={props.id} id={`btn-check-tag-${option}`}>
				<Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
				<Chip
					style={{
						color: white,
						backgroundColor: '#1d8cf8',
						borderColor: '#1d8cf8'
					}}
					variant='outlined'
					label={option}
				/>
			</li>
		)
	}

	return (
		<Dialog
			id='dialog-app'
			fullWidth={true}
			maxWidth='md'
			style={{ marginTop: '4%' }}
			open={isOpen}
			onClose={handleOnClose}
			BackdropProps={{ classes: { root: classes.backDrop } }}
			PaperProps={{
				style: {
					borderRadius: '20px',
					backdropFilter: 'blur(10px)',
					backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
					border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.7)'}`,
					boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
				}
			}}
		>
			<DialogTitle>
				<div className='jc-space-between d-flex ai-center'>
					<div style={{ color: color, fontSize: 20 }}>
						<LuCalendarDays className='fix-icon-margin-bottom-negative' size={25} color={color} /> Agendar atualização de métricas dos
						posts
					</div>
					<div>
						<IconButton color='primary' style={{ outline: 'none' }} onClick={handleOnClose}>
							<CloseIcon color={color} size={20} />
						</IconButton>
					</div>
				</div>
			</DialogTitle>
			<DialogContent style={{ paddingTop: 0, marginTop: 0, position: 'relative' }}>
				<div
					style={{
						padding: '1.5em',
						color: 'rgb(120, 120, 120)',
						fontFamily: 'Poppins',
						fontWeight: '300',
						fontSize: '20px'
					}}
				>
					<Grid container>
						<Grid item xs={6} style={{ padding: 4 }}>
							<Autocomplete
								multiple
								freeSolo
								PaperComponent={CustomPaper}
								disableClearable
								value={days}
								options={['Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado', 'Domingo']}
								onChange={(_, value) => handleOnChangeValue(value, 'days')}
								style={{ width: '100%' }}
								renderOption={optionRenderer}
								renderInput={(params) => (
									<div style={{ position: 'relative' }}>
										<CssTextField
											error={error && days.length === 0}
											onKeyDown={(event) => {
												event.preventDefault()
											}}
											inputRef={autocompleteRef}
											variant='standard'
											label={
												<div style={{ display: 'flex' }}>
													<div style={{ display: 'flex', alignItems: 'center' }}>
														<PlusIcon size={20} style={{ marginRight: '0.5em', color: blue }} />
														Adicionar dias
													</div>
												</div>
											}
											helperText={days.length === 0 ? errorText : ''}
											{...params}
										/>
									</div>
								)}
								renderTags={(value, getTagProps) =>
									value.map((option, index) => (
										<Chip
											size='medium'
											style={{ color: white, backgroundColor: blue, borderColor: blue }}
											key={index}
											variant='outlined'
											label={option}
											{...getTagProps({ index })}
											onDelete={(e) => {
												const props = getTagProps({ index })
												props.onDelete(e)
												lastOnDeleteTime = new Date().getTime()
											}}
										/>
									))
								}
							/>
						</Grid>
						<Grid item xs={6} style={{ padding: 4 }}>
							<Autocomplete
								multiple
								freeSolo
								PaperComponent={CustomPaper}
								disableClearable
								value={hours}
								options={hoursArray}
								onChange={(_, value) => handleOnChangeValue(value, 'hours')}
								style={{ width: '100%' }}
								renderOption={optionRenderer}
								renderInput={(params) => (
									<div style={{ position: 'relative' }}>
										<CssTextField
											error={error && hours.length === 0}
											onKeyDown={(event) => {
												event.preventDefault()
											}}
											inputRef={autocompleteRef}
											variant='standard'
											label={
												<div style={{ display: 'flex' }}>
													<div style={{ display: 'flex', alignItems: 'center' }}>
														<PlusIcon size={20} style={{ marginRight: '0.5em', color: blue }} />
														Adicionar horas
													</div>
												</div>
											}
											helperText={hours.length === 0 ? errorText : ''}
											{...params}
										/>
									</div>
								)}
								renderTags={(value, getTagProps) =>
									value.map((option, index) => (
										<Chip
											size='medium'
											style={{ color: white, backgroundColor: blue, borderColor: blue }}
											key={index}
											variant='outlined'
											label={option}
											{...getTagProps({ index })}
											onDelete={(e) => {
												const props = getTagProps({ index })
												props.onDelete(e)
												lastOnDeleteTime = new Date().getTime()
											}}
										/>
									))
								}
							/>
						</Grid>
						<Grid item xs={12} style={{ padding: 4 }}>
							<Autocomplete
								multiple={false}
								freeSolo
								PaperComponent={CustomPaper}
								disableClearable
								value={rule}
								options={Object.keys(dictionaryRule)}
								onChange={(_, value) => handleOnChangeValue(value, 'rule')}
								style={{ width: '100%' }}
								renderOption={optionRenderer}
								renderInput={(params) => (
									<div style={{ position: 'relative' }}>
										<CssTextField
											error={error && rule.length === 0}
											onKeyDown={(event) => {
												event.preventDefault()
											}}
											inputRef={autocompleteRef}
											variant='standard'
											label={
												<div style={{ display: 'flex' }}>
													<div style={{ display: 'flex', alignItems: 'center' }}>
														<PlusIcon size={20} style={{ marginRight: '0.5em', color: blue }} />
														Adicionar regra do agendamento
													</div>
												</div>
											}
											helperText={rule.length === 0 ? errorText : dictionaryRuleMeaning[rule]}
											{...params}
										/>
									</div>
								)}
								renderTags={(value, getTagProps) =>
									value.map((option, index) => (
										<Chip
											size='medium'
											style={{ color: white, backgroundColor: blue, borderColor: blue }}
											key={index}
											variant='outlined'
											label={option}
											{...getTagProps({ index })}
											onDelete={(e) => {
												const props = getTagProps({ index })
												props.onDelete(e)
												lastOnDeleteTime = new Date().getTime()
											}}
										/>
									))
								}
							/>
						</Grid>
						<Grid item xs={6} style={{ display: 'flex', justifyContent: 'start', marginTop: '0.5em' }}>
							<span style={{ marginTop: '1em', marginRight: '0.5em' }}>Fontes:</span>
							<SourceSelectorGlobalFilters
								onSourceChange={onSourceChange}
								sourcesControlled={selectedSources}
								comparisonStatusControlled={card.cardStatus}
								isOverview={false}
							/>
						</Grid>
						<Grid item xs={6} style={{ display: 'flex', justifyContent: 'end' }}>
							<Button
								onClick={createScheduleUpdatePosts}
								variant='contained'
								color='primary'
								style={{
									minWidth: '10em',
									fontFamily: 'Poppins',
									fontWeight: 'bold',
									color: '#1d8cf8',
									borderColor: '#1d8cf8',
									borderRadius: '100px',
									marginTop: '2em'
								}}
							>
								AGENDAR
							</Button>
						</Grid>
						{scheduleList && scheduleList?.length !== 0 && (
							<Grid item xs={12}>
								<TableContainer sx={{ minWidth: '35em', maxHeight: '20em', marginTop: '1em' }}>
									<Table sx={{ backgroundColor: 'white', borderRadius: '20px' }}>
										<TableHead>
											<TableRow>
												<TableCell align='center' sx={{ fontWeight: 'bold' }}>
													Dia
												</TableCell>
												<TableCell align='center' sx={{ fontWeight: 'bold' }}>
													Hora
												</TableCell>
												<TableCell align='center' sx={{ fontWeight: 'bold' }}>
													Rede
												</TableCell>
												<TableCell align='center' sx={{ fontWeight: 'bold' }}>
													Regra
												</TableCell>
												<TableCell align='center' sx={{ fontWeight: 'bold' }}>
													Ativo
												</TableCell>
												<TableCell align='center' sx={{ fontWeight: 'bold' }} />
											</TableRow>
										</TableHead>
										<TableBody>
											{scheduleList?.map((row) => (
												<TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
													<TableCell component='th' scope='row' align='center'>
														{row.days
															?.map((day) => `${Object.keys(dictionary).find((key) => dictionary[key] === day)}`)
															.join(', ')}
													</TableCell>
													<TableCell align='center'>{row.hours?.map((hour) => `${hour}h`).join(', ')}</TableCell>
													<TableCell align='center'>
														{row.sources?.map((source) => `${capitalize(source)}`).join(', ')}
													</TableCell>
													<TableCell align='center'>
														{Object.keys(dictionaryRule).find((key) => dictionaryRule[key] === row?.schedule_rule)}
													</TableCell>
													<TableCell align='center'>
														<Checkbox
															style={{ color }}
															checked={row.active}
															onChange={(e) => updateScheduleUpdatePosts(e.target.checked, row.id)}
														/>
													</TableCell>
													<TableCell align='center'>
														<Tooltip title='Excluir agendamento' placement='top'>
															<IconButton onClick={() => deleteScheduleUpdatePosts(row.id)}>
																<DeleteBinIcon />
															</IconButton>
														</Tooltip>
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>
							</Grid>
						)}
					</Grid>
				</div>
			</DialogContent>
		</Dialog>
	)
}

export default memo(ModalScheduleUpdatePosts)
