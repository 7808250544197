import React, { useEffect, useState } from 'react'
const { forwardRef, useImperativeHandle } = React
import MiniCardNumber from './components/MiniCardNumber/MiniCardNumber'
import MiniCardSentiment from './components/MiniCardSentiment/MiniCardSentiment'
import { useUpdateData } from 'hooks/ReactQueryHooks'
import { verifyServerResponseCanShowToast } from 'utils/generalFunctions'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import CardAllBySourcePercent from './components/CardAllBySourcePercent/CardAllBySourcePercent'
import CardAllBySourceNumber from './components/CardAllBySourceNumber/CardAllBySourceNumber'

const MiniCards = (
	{
		cards,
		filters,
		isFetchingData = true,
		onChangeCardLenght = () => {},
		menuType,
		isSocialProfile,
		cardType,
		summarySources,
		isWidget = false,
		show = 'all'
	},
	ref
) => {
	const [interval, setInterval] = useState({ fromDate: filters.fromDate, toDate: filters.toDate })
	const getIsComp = () => cards.length > 1

	useEffect(() => {
		setInterval({ fromDate: filters.fromDate, toDate: filters.toDate })
	}, [filters.fromDate, filters.toDate, cards])

	const getUrlRequest = () => (menuType !== 'summary' ? '/mini-summary' : '/mini-summary-all')

	useImperativeHandle(ref, () => ({
		f(newInterval) {
			setInterval(newInterval)
		}
	}))
	const getBody = () => ({
		configGlobalFilters: {
			cardType: cardType.toUpperCase(),
			cardIds: cards?.map((card) => card.id)
		},
		globalFilters: {
			...filters,
			fromDate: interval.fromDate,
			toDate: interval.toDate,
			sources: summarySources || filters.sources
		}
	})
	let { data, error, isFetching } = useUpdateData({
		url: getUrlRequest(),
		updateItemName: 'Summary' + cards?.map((card) => card.id),
		refetchInterval: 30000,
		freshDataTime: 30000,
		isEnabled: isFetchingData,
		queryName: 'relevanceChange_useUpdateData',
		method: 'post',
		body: getBody()
	})

	useEffect(() => {
		onChangeCardLenght(data?.length > 1)
	}, [data])

	if (verifyServerResponseCanShowToast(error)) {
		sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
	}

	if (menuType !== 'summary') {
		return (
			<>
				{(show === 'all' || show === 'posts') && (
					<MiniCardNumber
						id='span-minicard-posts'
						data={data}
						metric={'post_count'}
						title={'postagens'}
						loading={isFetching}
						isComp={getIsComp()}
						cardIds={cards?.map((card) => card.id)}
						isWidget={isWidget}
					/>
				)}
				{(show === 'all' || show === 'engagement') && (
					<MiniCardNumber
						id='span-minicard-engagement'
						data={data}
						metric={'engagement'}
						title={'engajamento'}
						loading={isFetching}
						isComp={getIsComp()}
						cardIds={cards?.map((card) => card.id)}
						isWidget={isWidget}
					/>
				)}
				{cardType === 'term' && (show === 'all' || show === 'sentiments') ? (
					<MiniCardSentiment id='span-minicard-sentiments' data={data} title={'sentimentos'} loading={isFetching} isWidget={isWidget} />
				) : (
					<>
						{(show === 'all' || show === 'comment') && (
							<MiniCardNumber
								id='span-minicard-comment'
								data={data}
								metric={'comment_count'}
								title={'comentários'}
								loading={isFetching}
								isComp={getIsComp()}
								cardIds={cards?.map((card) => card.id)}
								isWidget={isWidget}
							/>
						)}
					</>
				)}
			</>
		)
	} else {
		return (
			<>
				<CardAllBySourceNumber
					xs={isSocialProfile || cards.every((it) => it.searchType === 'PROFILE') ? 4 : 3}
					data={data.twitter}
					title={'twitter'}
					loading={isFetching}
					isTwitter={true}
					cards={cards}
					isFetchingData={isFetchingData}
					filters={filters}
					isSocialProfile={isSocialProfile}
					cardType={cardType}
					height={cardType === 'group' ? '339.39px' : '265.5px'}
					disabledSummary={menuType === 'summary' && !summarySources.includes('twitter')}
					cardIds={cards?.map((card) => card.id)}
				/>
				<CardAllBySourceNumber
					xs={isSocialProfile || cards.every((it) => it.searchType === 'PROFILE') ? 4 : 3}
					data={data.bluesky}
					title='bluesky'
					loading={isFetching}
					isBluesky={true}
					cards={cards}
					isFetchingData={isFetchingData}
					filters={filters}
					isSocialProfile={isSocialProfile}
					cardType={cardType}
					height={cardType === 'group' ? '339.39px' : '265.5px'}
					disabledSummary={menuType === 'summary' && !summarySources.includes('bluesky')}
					cardIds={cards?.map((card) => card.id)}
				/>
				<CardAllBySourceNumber
					xs={isSocialProfile || cards.every((it) => it.searchType === 'PROFILE') ? 4 : 3}
					data={data.facebook}
					title={'facebook'}
					loading={isFetching}
					isFacebook={true}
					cards={cards}
					isFetchingData={isFetchingData}
					filters={filters}
					isSocialProfile={isSocialProfile}
					cardType={cardType}
					height={cardType === 'group' ? '339.39px' : '265.5px'}
					disabledSummary={menuType === 'summary' && !summarySources.includes('facebook')}
					cardIds={cards?.map((card) => card.id)}
				/>
				<CardAllBySourceNumber
					xs={isSocialProfile || cards.every((it) => it.searchType === 'PROFILE') ? 4 : 3}
					data={data.instagram}
					title={'instagram'}
					loading={isFetching}
					isInstagram={true}
					cards={cards}
					isFetchingData={isFetchingData}
					filters={filters}
					isSocialProfile={isSocialProfile}
					cardType={cardType}
					height={cardType === 'group' ? '339.39px' : '265.5px'}
					disabledSummary={menuType === 'summary' && !summarySources.includes('instagram')}
					cardIds={cards?.map((card) => card.id)}
				/>
				<CardAllBySourceNumber
					xs={4}
					data={data.tiktok}
					title={'tiktok'}
					loading={isFetching}
					isTiktok={true}
					cards={cards}
					isFetchingData={isFetchingData}
					filters={filters}
					isSocialProfile={isSocialProfile}
					cardType={cardType}
					height={cardType === 'group' ? '339.39px' : '265.5px'}
					disabledSummary={menuType === 'summary' && !summarySources.includes('tiktok')}
					cardIds={cards?.map((card) => card.id)}
				/>
				<CardAllBySourceNumber
					xs={4}
					data={data.youtube}
					title='youtube'
					loading={isFetching}
					isYoutube={true}
					cards={cards}
					isFetchingData={isFetchingData}
					filters={filters}
					isSocialProfile={isSocialProfile}
					cardType={cardType}
					height={cardType === 'group' ? '339.39px' : '265.5px'}
					disabledSummary={menuType === 'summary' && !summarySources.includes('youtube')}
					cardIds={cards?.map((card) => card.id)}
				/>
				{!isSocialProfile && !cards.every((it) => it.searchType === 'PROFILE') && (
					<CardAllBySourceNumber
						xs={4}
						data={data.news}
						title={'notícias'}
						loading={isFetching}
						isNews={true}
						cards={cards}
						isFetchingData={isFetchingData}
						filters={filters}
						isSocialProfile={isSocialProfile}
						height={cardType === 'group' ? '339.39px' : '265.5px'}
						disabledSummary={menuType === 'summary' && !summarySources.includes('news')}
						cardIds={cards?.map((card) => card.id)}
					/>
				)}
				<MiniCardNumber
					id='span-minicard-total-posts'
					data={data.all}
					metric={'post_count'}
					title={'Postagens total'}
					loading={isFetching}
					isOverview={true}
					cardIds={cards?.map((card) => card.id)}
				/>
				<MiniCardNumber
					id='span-minicard-total-engagement'
					data={data.all}
					metric={'engagement'}
					title={'Engajamento total'}
					loading={isFetching}
					isOverview={true}
					cardIds={cards?.map((card) => card.id)}
				/>
				<MiniCardNumber
					id='span-minicard-total-comment'
					data={data.all}
					metric={'comment_count'}
					title={'Comentários total'}
					loading={isFetching}
					isOverview={true}
					cardIds={cards?.map((card) => card.id)}
				/>
				<CardAllBySourcePercent data={data.percent} title={'participação nas redes'} loading={isFetching} isSocialProfile={isSocialProfile} />
			</>
		)
	}
}

export default forwardRef(MiniCards)
