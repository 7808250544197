import React, { useState, useEffect } from 'react'
import { Scrollama, Step } from 'react-scrollama'
import Spline from '@splinetool/react-spline'
import { slides } from './data/BrandsData'
import ApexDonut from '../../Graphs/ApexDonut/ApexDonut'

const colorPalette = [
	'rgba(44, 125, 230, 1)',
	'rgba(0, 0, 0, 1)',
	'rgba(230, 81, 44, 1)',
	'rgba(43, 179, 174, 1)',
	'rgba(186, 34, 59, 1)',
	'rgba(254, 176, 25, 1)',
	'rgba(30, 30, 30, 1)',
	'rgba(0, 173, 104, 1)'
]

const defineWordColor = (word) => {
	if (word.includes('Facebook')) {
		return colorPalette[0]
	}
	if (word.includes('X')) {
		return colorPalette[3]
	}
	if (word.includes('Instagram')) {
		return colorPalette[2]
	}
	if (word.includes('TikTok')) {
		return colorPalette[1]
	}
	if (word.includes('Youtube')) {
		return colorPalette[4]
	}
	if (word.includes('redes')) {
		return colorPalette[5]
	}
	if (word.includes('TV')) {
		return colorPalette[6]
	}
	if (word.includes('41.032.477,32')) {
		return colorPalette[7]
	}
	return 'white'
}

const Brands = ({ isDebug = false }) => {
	const [currentStepIndex, setCurrentStepIndex] = useState(0)

	useEffect(() => {
		onChartTypeChange(currentStepIndex)
	}, [currentStepIndex])

	const onStepEnter = ({ data }) => {
		setCurrentStepIndex(data)
	}

	const onChartTypeChange = (index) => {
		document.dispatchEvent(new KeyboardEvent('keydown', { key: `${index}` }))
		document.dispatchEvent(new KeyboardEvent('keyup', { key: `${index}` }))

		if (index === 0) {
			document.dispatchEvent(new KeyboardEvent('keydown', { key: '0' }))
			document.dispatchEvent(new KeyboardEvent('keyup', { key: '0' }))
		}
	}

	return (
		<div style={{ border: isDebug ? '2px dashed skyblue' : '' }}>
			<div className='scroll-graph-container-left' style={{ border: isDebug ? '1px solid orchid' : '', top: '11vh' }}>
				<div className='graph-container-3d'>
					<Spline scene='https://prod.spline.design/xcsjtBbi0ozNfJ0Z/scene.splinecode' />
				</div>
			</div>

			<div className='scroll-steps-container-right'>
				<div>
					<Scrollama offset={0.5} onStepEnter={onStepEnter} debug={isDebug}>
						{slides.map((slide, index) => {
							return (
								<Step data={index} key={index}>
									<div className='step-conainer-right'>
										<div className='step-index'>{slide.index}</div>
										<div className='step-content'>
											{slides[index]?.text.split(' ').map((word, index) => {
												return <span key={`${word}-${index}`} style={{ color: defineWordColor(word) }}>{`${word} `}</span>
											})}
											{slide.extraData && (
												<>
													<ApexDonut
														labels={slides[index]?.extraData?.labels}
														colors={slides[index]?.extraData?.colors}
														values={slides[index]?.extraData?.values}
													/>
													{slides[index]?.comments &&
														slides[index]?.comments?.map((comment, index) => {
															return <div style={{ fontSize: '0.7vw' }} key={index}>{`${comment} `}</div>
														})}
												</>
											)}
										</div>
									</div>
								</Step>
							)
						})}
					</Scrollama>
				</div>
			</div>
			<div className='bottom-page' />
		</div>
	)
}

export default Brands
