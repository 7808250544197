import React from 'react'
import logo from '../../../assets/Aura.png'
import Title from '../Sections/Title/Title'
import Intro from '../Sections/Intro/Intro'
import Brands from '../Sections/Brands/Brands'
import FinancialGraphs from '../Sections/FinancialGraphs/FinancialGraphs'
import CommentSection from '../Sections/CommentSection/CommentSection'
import './Aura.css'

const Aura = () => {
	return (
		<>
			<div className='background' />
			<div style={{ height: '100vh', overflow: 'auto', backgroundColor: 'rgba(255,255,255, 0.1)' }}>
				<div className='aura-header'>
					<div className='aura-logo-container'>
						<img src={logo} className='aura-logo' />
						<div className='aura-header-text'>aurasight</div>
					</div>
				</div>
				<Title title={'Retorno de exposição nas redes sociais'} date={'JULHO/2024'} />
				<Intro />
				<Brands />
				<FinancialGraphs />
				<CommentSection
					title={'Observações'}
					comment={
						'Além disso, o desempenho nas redes sociais superou o da TV, indicando que a negociação com influenciadores específicos é mais eficaz do que com emissoras de TV, oferecendo um retorno superior em engajamento e visibilidade da marca.'
					}
				/>
			</div>
		</>
	)
}

export default Aura
