const recordData = [
	[0, 'Pixbet', 910.73, '34.9840%'],
	[1, 'Kwai', 1044.63, '23.7250%'],
	[2, 'BRB', 787.7, '16.5265%'],
	[3, 'Adidas', 1113, '12.6354%'],
	[4, 'Mercado Livre', 479.83, '8.0366%'],
	[5, 'Assist Card', 424.27, '3.5002%'],
	[6, 'ABC', 253.07, '0.4588%'],
	[7, 'Zé Delivery', 154.2, '0.1332%'],
	[8, 'Tim', 5.13, '0.0002%']
]

const slides = [
	{
		//configartion slide
		animation: {
			data: {
				series: [
					{ name: 'index', type: 'measure' },
					{ name: 'Marcas', type: 'dimension' },
					{ name: 'Tempo em segundos', type: 'measure' },
					{ name: 'Share of Voice', type: 'measure' }
				],
				records: recordData
			},
			style: {
				backgroundColor: 'rgba(0,0,0,0)',
				borderColor: 'rgba(0,0,0,0)',
				title: { backgroundColor: 'rgba(0,0,0,0)', color: 'rgba(255,255,255,0.5)' },
				plot: {
					backgroundColor: 'rgba(0,0,0,0)',
					xAxis: {
						interlacing: { color: 'rgba(0,0,0,0.1)' }
					},
					yAxis: {
						interlacing: { color: 'rgba(0,0,0,0.1)' }
					},
					marker: {
						colorPalette: '#F9C80E',
						rectangleSpacing: '2em'
					}
				},
				legend: { backgroundColor: 'rgba(0,0,0,0)' }
			},
			config: {
				channels: {
					x: { set: ['Share of Voice'] },
					y: { set: null },
					label: 'Share of Voice',
					color: null
				},
				coordSystem: 'polar',
				geometry: 'rectangle',
				align: 'none',
				title: 'Share of Voice'
			}
		},
		animationBehavior: { speed: 4 },
		extraData: null
	},
	{
		index: 1,
		text: 'Nos últimos 30 dias, diferente dos meses anteriores, nossa análise revelou que o melhor posicionamento da marca no uniforme do Flamengo é no ombro. Mesmo com áreas maiores disponíveis, essa posição gerou maior repercussão nas redes sociais.',
		animation: {
			config: {
				channels: {
					x: { set: ['Share of Voice'] },
					y: { set: ['Marcas'] },
					label: 'Share of Voice',
					color: 'Marcas'
				},
				coordSystem: 'polar',
				geometry: 'rectangle',
				align: 'none',
				title: null,
				sort: 'byValue'
			},
			style: {
				backgroundColor: 'rgba(0,0,0,0)',
				borderColor: 'rgba(0,0,0,0)',
				title: { backgroundColor: 'rgba(0,0,0,0)' },
				plot: {
					marker: {
						colorPalette: '#F9C80E #F86624 #EA3546 #9746e3 #43BCCD #1d8cf8 #d81159 #7F7F7F #8ac926',
						rectangleSpacing: '1em'
					}
				},
				legend: { backgroundColor: 'rgba(0,0,0,0)' }
			}
		},
		animationBehavior: { speed: 1.6 },
		extraData: null
	}
]

export { slides }
