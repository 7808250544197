import React, { useEffect, useState, useRef, memo } from 'react'
import { Grid, IconButton, Tooltip, Button, Divider } from '@mui/material'
import {
	CopyIcon,
	HistoryIcon,
	TwitterIcon,
	FacebookIcon,
	InstagramIcon,
	TiktokIcon,
	BlueskyIcon,
	YoutubeIcon,
	RefreshIconMd
} from 'utils/SystemIcons'
import { connect } from 'react-redux'
import { useUpdateData } from 'hooks/ReactQueryHooks'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import { verifyServerResponseCanShowToast, orderKeys } from 'utils/generalFunctions'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { TypeAnimation } from 'react-type-animation'
import constantsVox from 'constants-vox'
import Chart from 'react-apexcharts'
import api from 'service/service'
import AiAnalysisRequest from './components/AiAnalysisRequest/AiAnalysisRequest'
import AiEditTitle from './components/AiEditTitle/AiEditTitle'
import ServiceHistory from 'components/ServiceHistory/ServiceHistory'

window.Buffer = window.Buffer || require('buffer').Buffer

const serviceName = 'voxAi'

const titleStyle = {
	marginLeft: '5px',
	width: '93%',
	textAlign: 'left',
	fontFamily: 'Poppins',
	color: '#1d8cf8',
	fontSize: '20px',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis'
}

const textStyle = { color: 'black', textAlign: 'left', fontFamily: 'Poppins', whiteSpace: 'pre-wrap' }

const defineIcon = (sources) => {
	const iconStyle = { color: '#1d8cf8', marginTop: '7px' }
	const containerStyle = { marginRight: '10px' }
	return (
		<div style={{ display: 'flex' }}>
			{sources.includes('twitter') && (
				<div style={containerStyle}>
					<TwitterIcon size={16} style={iconStyle} />
				</div>
			)}
			{sources.includes('bluesky') && (
				<div style={containerStyle}>
					<BlueskyIcon size={16} style={iconStyle} />
				</div>
			)}
			{sources.includes('facebook') && (
				<div style={containerStyle}>
					<FacebookIcon size={16} style={iconStyle} />
				</div>
			)}
			{sources.includes('instagram') && (
				<div style={containerStyle}>
					<InstagramIcon size={16} style={iconStyle} />
				</div>
			)}
			{sources.includes('tiktok') && (
				<div style={containerStyle}>
					<TiktokIcon size={16} style={iconStyle} />
				</div>
			)}
			{sources.includes('youtube') && (
				<div style={containerStyle}>
					<YoutubeIcon size={16} style={iconStyle} />
				</div>
			)}
		</div>
	)
}

const VoxAi = ({ cards, graphData, globalFiltersRedux, isFetchingData, remainingCreditsRedux, graphFetched }) => {
	const [thisFilters, setThisFilters] = useState(globalFiltersRedux)
	const [submitting, setSubmitting] = useState(false)
	const [loading, setLoading] = useState(true)
	const [value, setValue] = useState('')
	const [openHistory, setOpenHistory] = useState(false)
	const [isFirstTime, setIsFirstTime] = useState(true)
	const [isReady, setIsReady] = useState(false)
	const [thisGraphData, setThisGraphData] = useState(graphData)
	const [analysisId, setAnalysisId] = useState(null)

	const isMounted = useRef(true)

	const [isShareUrl, setIsShareUrl] = useState(false)

	useEffect(() => {
		if (window.location.href.includes('sharing')) {
			setIsShareUrl(true)
		}
	}, [])

	useEffect(() => {
		reset()
	}, [globalFiltersRedux])

	const getBody = () => ({
		configGlobalFilters: {
			cardIds: cards?.map((card) => card.id),
			cardType: cards[0].type.toUpperCase()
		},
		globalFilters: thisFilters,
		componentProperties: { filtersBase64: Buffer.from(JSON.stringify(orderKeys(thisFilters))).toString('base64'), analysisId }
	})

	let { data, refetch, isFetched } = useUpdateData({
		url: '/voxradar-ai/get-engagement-analysis',
		updateItemName: 'voxradarAiEngagement',
		refetchInterval: 5000,
		freshDataTime: 5000,
		isEnabled: isFetchingData,
		queryName: 'voxradar_ai_engagement',
		method: 'post',
		body: getBody()
	})

	const reset = () => {
		setThisFilters(globalFiltersRedux)
		setThisGraphData(graphData)
		setIsFirstTime(false)
		setIsReady(false)
		setAnalysisId(null)
		refetch()
	}

	const applyHistory = (history) => {
		setThisFilters(history.filters)
		setAnalysisId(history.id)
		setThisGraphData(history.graph_data)
		refetch()
	}

	useEffect(() => {
		setThisGraphData(graphData)
		setAnalysisId(null)
	}, [graphData])

	useEffect(() => {
		setValue(data?.interpretation)
	}, [data?.interpretation])

	useEffect(() => {
		if (isFetched) {
			setLoading(false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFetched])

	useEffect(() => {
		isMounted.current = true
		return () => {
			isMounted.current = false
		}
	}, [])

	const newGraphInterpretation = () => {
		const configGlobalFilters = { cardIds: [cards[0].id] }
		const globalFilters = globalFiltersRedux
		const componentProperties = { graphData }

		if (!submitting) {
			setSubmitting(true)

			api.post('/voxradar-ai/new-engagement-analysis', { configGlobalFilters, globalFilters, componentProperties })
				.then(() => {
					setSubmitting(true)
					setLoading(true)
				})
				.catch((error) => {
					if (verifyServerResponseCanShowToast(error)) {
						sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
						setLoading(false)
					}
				})
				.finally(() => {
					setSubmitting(false)
					setIsReady(false)
				})
		}
	}

	const onCopy = React.useCallback(() => {
		sendMessageToast('Texto copiado', constantsVox.TOAST.TYPES.INFO)
	}, [])

	const onClickHistoryButton = () => {
		setOpenHistory(!openHistory)
	}

	const handleReady = () => {
		setTimeout(() => {
			setIsReady(true)
		}, 1000)
	}

	return (
		<>
			<Grid
				container
				style={{
					height: '100%',
					width: '100%',
					borderRadius: '15px',
					overflow: 'hidden',
					padding: '15px'
				}}
			>
				<Grid item xs={9} style={{ textAlign: 'center' }} container alignItems={'center'}>
					<Grid item xs={12} style={{ minHeight: '31px', marginLeft: '20px', paddingRight: '39px' }}>
						{!!data?.title && isFirstTime && (
							<div style={{ display: 'flex' }}>
								<div style={{ marginRight: '5px' }}>{defineIcon(globalFiltersRedux?.sources)}</div>
								<TypeAnimation sequence={[`${data?.title}`]} speed={99} wrapper='div' cursor={false} repeat={1} style={titleStyle} />
							</div>
						)}
						{!!data?.title && !isFirstTime && (
							<div style={{ display: 'flex' }}>
								<div style={{ marginRight: '5px' }}>{defineIcon(thisFilters?.sources)}</div>
								<div style={titleStyle}>{data?.title}</div>
							</div>
						)}
						{!!data?.title && !openHistory && (
							<AiEditTitle
								id={data?.id}
								oldTitle={data?.title}
								refetch={refetch}
								setIsFirstTime={setIsFirstTime}
								cardTermId={cards[0].id}
								isShareUrl={isShareUrl}
							/>
						)}
						{!!data?.title && <Divider style={{ backgroundColor: 'rgba(0,0,0,0.1)', height: '1px', marginTop: '0em', width: '100%' }} />}
					</Grid>

					<div
						style={{
							color: 'black',
							textAlign: 'left',
							fontFamily: 'Poppins',
							marginTop: '4em',
							marginLeft: '10px',
							marginRight: '25px',
							width: '100%',
							height: '100%'
						}}
					>
						<Chart
							options={graphData.options}
							series={thisGraphData.series}
							type={graphData.type}
							width={'100%'}
							height={window.innerHeight / 1.4 - 100}
						/>
						<Grid item xs={12}>
							<Button
								id='btn-open-ai-history'
								onClick={onClickHistoryButton}
								variant='outlined'
								style={{
									fontFamily: 'Poppins',
									borderRadius: '100px',
									fontSize: '14px',
									borderColor: 'rgba(29, 138, 248, 0.3)',
									marginRight: '2em'
								}}
							>
								<HistoryIcon size={21} style={{ color: '#1d8cf8', cursor: 'pointer', marginRight: '5px' }} />
								Histórico
							</Button>
							{data?.status === 'SUCCESS' &&
								globalFiltersRedux.fromDate !== thisFilters.fromDate &&
								globalFiltersRedux.toDate !== thisFilters.toDate && (
									<Button
										id='btn-reset-ai-analysis'
										onClick={() => reset()}
										variant='outlined'
										style={{
											fontFamily: 'Poppins',
											borderRadius: '100px',
											fontSize: '14px',
											borderColor: 'rgba(29, 138, 248, 0.3)',
											maxHeight: '36.5px',
											marginRight: '2em'
										}}
									>
										<RefreshIconMd size={21} style={{ color: '#1d8cf8', cursor: 'pointer', marginRight: '5px' }} />
										Intervalo Atual
									</Button>
								)}
						</Grid>
					</div>
				</Grid>
				<Grid item xs={3} style={{ textAlign: 'center', borderRadius: '20px', backgroundColor: 'rgba(255, 255, 255, 0.5)', height: '79vh' }}>
					{isFetched && !isReady && (
						<AiAnalysisRequest
							onClick={newGraphInterpretation}
							loading={loading}
							status={data?.status}
							handleReady={handleReady}
							credits={remainingCreditsRedux}
							graphLoading={!graphFetched}
							isShareUrl={isShareUrl}
						/>
					)}
					{data?.interpretation && data?.status === 'SUCCESS' && isReady && (
						<>
							<div
								style={{
									marginTop: '25px',
									marginLeft: '25px',
									height: '70vh',
									overflow: 'auto'
								}}
							>
								<div style={{ marginRight: '8px' }}>
									{!!data?.interpretation && isFirstTime && (
										<TypeAnimation
											sequence={[`${data?.interpretation}`]}
											speed={99}
											wrapper='div'
											cursor={false}
											repeat={1}
											style={textStyle}
										/>
									)}
									{!!data?.interpretation && !isFirstTime && <div style={textStyle}>{data?.interpretation}</div>}
								</div>
							</div>

							<div style={{ height: '6vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
								<CopyToClipboard onCopy={onCopy} text={value}>
									<Tooltip title={'Copiar texto'} placement='bottom' enterDelay={500} enterNextDelay={500}>
										<IconButton id='btn-copy-ai-text' style={{ marginLeft: '-10px' }}>
											<CopyIcon size={20} style={{ color: '#1d8cf8', cursor: 'pointer' }} />
										</IconButton>
									</Tooltip>
								</CopyToClipboard>
							</div>
						</>
					)}
				</Grid>
			</Grid>
			<ServiceHistory
				style={{ width: '60%', height: '100%' }}
				showFilterBar={true}
				cardId={cards[0].id}
				isOpen={openHistory}
				toggleOpenHistory={onClickHistoryButton}
				cardType={cards[0]?.type}
				serviceName={serviceName}
				cardName={cards[0]?.name}
				applyHistory={applyHistory}
				isShareUrl={isShareUrl}
			/>
		</>
	)
}

const mapStateToProps = ({ store }) => ({
	globalFiltersRedux: store.global.filters,
	remainingCreditsRedux: store?.user?.creditInfo?.remainingMonth
})

export default connect(mapStateToProps)(memo(VoxAi))
