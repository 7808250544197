import React, { useState, useEffect } from 'react'
import moment from 'moment-timezone'
import { Button, Grid, Menu, MenuItem, styled } from '@mui/material'
import DateIntervalSelectorBar from './DateIntervalSelectorBar'
import { CalendarIcon } from 'utils/SystemIcons'

const isFirefox = typeof InstallTrigger !== 'undefined'

const TIME_INTERVAL = {
	TODAY: {
		KEY: 'TODAY',
		VALUE: 'Hoje',
		getDate: () => ({ startDate: new Date(), endDate: new Date() })
	},
	YESTERDAY: {
		KEY: 'YESTERDAY',
		VALUE: 'Ontem',
		getDate: () => ({
			startDate: moment(new Date(), 'YYYY-MM-DD').subtract(1, 'days').toDate(),
			endDate: moment(new Date(), 'YYYY-MM-DD').subtract(1, 'days').toDate()
		})
	},
	LAST_7_DAYS: {
		KEY: 'LAST_7_DAYS',
		VALUE: 'Últimos 7 dias',
		getDate: () => ({
			startDate: moment(new Date(), 'YYYY-MM-DD').subtract(6, 'days').toDate(),
			endDate: moment(new Date(), 'YYYY-MM-DD').toDate()
		})
	},
	LAST_WEEK: {
		KEY: 'LAST_WEEK',
		VALUE: 'Semana anterior',
		getDate: () => ({
			startDate: moment(new Date(), 'YYYY-MM-DD').subtract(1, 'weeks').startOf('week').toDate(),
			endDate: moment(new Date(), 'YYYY-MM-DD').subtract(1, 'weeks').endOf('week').toDate()
		})
	},
	THIS_MONTH: {
		KEY: 'THIS_MONTH',
		VALUE: 'Mês atual',
		getDate: () => {
			let startDate = moment(new Date(), 'YYYY-MM-DD').startOf('month').toDate()
			let endDate = moment(new Date(), 'YYYY-MM-DD').endOf('month').toDate()
			if (moment(new Date(), 'YYYY-MM-DD').endOf('month').isAfter(moment(new Date(), 'YYYY-MM-DD'))) {
				//verifica se o dia do fim do mes atual é depois do dia de hoje (no futuro), provavelmente vai ser, então coloca o dia atual
				endDate = moment(new Date(), 'YYYY-MM-DD').toDate()
			}

			return { startDate, endDate }
		}
	},
	LAST_MONTH: {
		KEY: 'LAST_MONTH',
		VALUE: 'Mês anterior',
		getDate: () => ({
			startDate: moment(new Date(), 'YYYY-MM-DD').subtract(1, 'months').startOf('month').toDate(),
			endDate: moment(new Date(), 'YYYY-MM-DD').subtract(1, 'months').endOf('month').toDate()
		})
	}
}
const StyledButton = styled(Button)({
	color: 'rgb(82, 95, 127)',
	fontFamily: 'Poppins',
	fontWeight: '500'
})

const getCustomIntervalLabel = (fromDate, toDate) => {
	let label = null
	if (moment(fromDate).format('DD/MM/YYYY') === moment(toDate).format('DD/MM/YYYY')) {
		label = `${moment(fromDate).format('DD/MM/YYYY')}`
	} else {
		label = `${moment(fromDate).format('DD/MM/YYYY')} - ${moment(toDate).format('DD/MM/YYYY')}`
	}
	return label
}

const getIntervalLabel = (fromDate, toDate) => {
	let label = getCustomIntervalLabel(fromDate, toDate)

	if (moment(fromDate, 'YYYY-MM-DD').isSame(moment(new Date(), 'YYYY-MM-DD'), 'day')) {
		label = TIME_INTERVAL.TODAY.VALUE
	} else if (
		moment(fromDate, 'YYYY-MM-DD').isSame(moment(new Date(), 'YYYY-MM-DD').subtract(1, 'days'), 'day') &&
		moment(toDate, 'YYYY-MM-DD').isSame(moment(new Date(), 'YYYY-MM-DD').subtract(1, 'days'), 'day')
	) {
		label = TIME_INTERVAL.YESTERDAY.VALUE
	} else if (
		moment(fromDate, 'YYYY-MM-DD').isSame(moment(new Date(), 'YYYY-MM-DD').subtract(6, 'days'), 'day') &&
		moment(toDate, 'YYYY-MM-DD').isSame(moment(new Date(), 'YYYY-MM-DD'), 'day')
	) {
		label = TIME_INTERVAL.LAST_7_DAYS.VALUE
	} else if (
		moment(fromDate, 'YYYY-MM-DD').isSame(moment(new Date(), 'YYYY-MM-DD').subtract(1, 'weeks').startOf('week'), 'day') &&
		moment(toDate, 'YYYY-MM-DD').isSame(moment(new Date(), 'YYYY-MM-DD').subtract(1, 'weeks').endOf('week'), 'day')
	) {
		label = TIME_INTERVAL.LAST_WEEK.VALUE
	} else if (
		moment(fromDate, 'YYYY-MM-DD').isSame(moment(new Date(), 'YYYY-MM-DD').subtract(1, 'months').startOf('month'), 'day') &&
		moment(toDate, 'YYYY-MM-DD').isSame(moment(new Date(), 'YYYY-MM-DD').subtract(1, 'months').endOf('month'), 'day')
	) {
		label = TIME_INTERVAL.LAST_MONTH.VALUE
	} else if (
		moment(fromDate, 'YYYY-MM-DD').isSame(moment(new Date(), 'YYYY-MM-DD').startOf('month'), 'day') &&
		moment(toDate, 'YYYY-MM-DD').isSame(moment(new Date(), 'YYYY-MM-DD'), 'day')
	) {
		label = TIME_INTERVAL.THIS_MONTH.VALUE
	}

	return label
}

const DateIntervalSelectorGlobal = ({
	onApply,
	fromDate = moment(new Date(), 'YYYY-MM-DD').subtract(6, 'days').format('YYYY-MM-DD'),
	toDate = moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD'),
	fromHour = '00:00:00',
	toHour = '23:59:59',
	isFloating = false
}) => {
	const [anchorEl, setAnchorEl] = useState(null)
	const [dateIntervalOpen, setDateIntervalOpen] = useState(false)
	const [intervalLabel, setIntervalLabel] = useState(TIME_INTERVAL.LAST_7_DAYS.VALUE)

	useEffect(() => {
		setIntervalLabel(getIntervalLabel(fromDate, toDate))
	}, [fromDate, toDate])

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleClickTimeButton = (timeIntervalKey) => {
		const timeInterval = TIME_INTERVAL[timeIntervalKey]?.getDate()
		setIntervalLabel(TIME_INTERVAL[timeIntervalKey]?.VALUE)
		setAnchorEl(null)
		onApply(moment(timeInterval.startDate).format('YYYY-MM-DD'), moment(timeInterval.endDate).format('YYYY-MM-DD'), '00:00:00', '23:59:59')
	}

	const openCalendar = () => {
		setDateIntervalOpen(true)
	}

	const onCloseDateInterval = () => {
		setAnchorEl(null)
		setDateIntervalOpen(false)
	}

	const onApplyCalendar = (fromDate, toDate, startHour, endHour) => {
		onApply(fromDate, toDate, startHour, endHour)
	}

	return (
		<>
			{!isFloating && (
				<>
					<StyledButton id='btn-calendar' size='small' onClick={handleClick} style={{ outline: 'none', height: '48px' }}>
						<div style={{ display: 'flex', margin: '5px' }}>
							<CalendarIcon
								size={16}
								style={{
									color: '#1d8cf8',
									marginTop: '3px'
								}}
							/>
							<div
								style={{
									whiteSpace: 'nowrap',
									marginLeft: '5px'
								}}
							>
								{intervalLabel}
							</div>
						</div>
					</StyledButton>
				</>
			)}
			{isFloating && (
				<div style={{ display: 'flex', margin: '5px', marginRight: '1em' }}>
					<CalendarIcon
						size={16}
						style={{
							color: '#1d8cf8',
							marginTop: '3px'
						}}
					/>
					<div
						style={{
							whiteSpace: 'nowrap',
							marginLeft: '5px',
							fontFamily: 'Poppins',
							color: '#878787'
						}}
					>
						{intervalLabel}
					</div>
				</div>
			)}
			<Menu
				open={!!anchorEl}
				onClose={handleClose}
				anchorEl={anchorEl}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
				PaperProps={{
					style: {
						minWidth: '15em',
						borderRadius: '20px',
						backdropFilter: 'blur(10px)',
						backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.7)'}`,
						border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
						boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
					}
				}}
			>
				<MenuItem
					onClick={() => handleClickTimeButton(TIME_INTERVAL.TODAY.KEY)}
					style={{ justifyContent: 'center', fontFamily: 'Poppins', fontWeight: '500', margin: '5px', borderRadius: '25px' }}
				>
					<span style={{ textTransform: 'uppercase', opacity: '0.65', fontSize: '0.95em' }}>{TIME_INTERVAL.TODAY.VALUE}</span>
				</MenuItem>
				<MenuItem
					onClick={() => handleClickTimeButton(TIME_INTERVAL.YESTERDAY.KEY)}
					style={{ justifyContent: 'center', fontFamily: 'Poppins', fontWeight: '500', margin: '5px', borderRadius: '25px' }}
				>
					<span style={{ textTransform: 'uppercase', opacity: '0.65', fontSize: '0.95em' }}>{TIME_INTERVAL.YESTERDAY.VALUE}</span>
				</MenuItem>
				<MenuItem
					onClick={() => handleClickTimeButton(TIME_INTERVAL.LAST_7_DAYS.KEY)}
					style={{ justifyContent: 'center', fontFamily: 'Poppins', fontWeight: '500', margin: '5px', borderRadius: '25px' }}
				>
					<span style={{ textTransform: 'uppercase', opacity: '0.65', fontSize: '0.95em' }}>{TIME_INTERVAL.LAST_7_DAYS.VALUE}</span>
				</MenuItem>
				<MenuItem
					onClick={() => handleClickTimeButton(TIME_INTERVAL.LAST_WEEK.KEY)}
					style={{ justifyContent: 'center', fontFamily: 'Poppins', fontWeight: '500', margin: '5px', borderRadius: '25px' }}
				>
					<span style={{ textTransform: 'uppercase', opacity: '0.65', fontSize: '0.95em' }}>{TIME_INTERVAL.LAST_WEEK.VALUE}</span>
				</MenuItem>
				<MenuItem
					onClick={() => handleClickTimeButton(TIME_INTERVAL.THIS_MONTH.KEY)}
					style={{ justifyContent: 'center', fontFamily: 'Poppins', fontWeight: '500', margin: '5px', borderRadius: '25px' }}
				>
					<span style={{ textTransform: 'uppercase', opacity: '0.65', fontSize: '0.95em' }}>{TIME_INTERVAL.THIS_MONTH.VALUE}</span>
				</MenuItem>
				<MenuItem
					onClick={() => handleClickTimeButton(TIME_INTERVAL.LAST_MONTH.KEY)}
					style={{ justifyContent: 'center', fontFamily: 'Poppins', fontWeight: '500', margin: '5px', borderRadius: '25px' }}
				>
					<span style={{ textTransform: 'uppercase', opacity: '0.65', fontSize: '0.95em' }}>{TIME_INTERVAL.LAST_MONTH.VALUE}</span>
				</MenuItem>

				<MenuItem
					id='btn-calendar-choice-option'
					onClick={openCalendar}
					style={{ justifyContent: 'center', fontFamily: 'Poppins', fontWeight: '500', margin: '5px', borderRadius: '25px' }}
				>
					<CalendarIcon
						size={16}
						style={{
							color: '#1d8cf8',
							marginRight: '0.5em'
						}}
					/>
					<span style={{ textTransform: 'uppercase', opacity: '0.65', fontSize: '0.95em' }}>Data personalizada</span>
				</MenuItem>

				<DateIntervalSelectorBar
					onApply={onApplyCalendar}
					fromDate={fromDate}
					toDate={toDate}
					fromHour={fromHour}
					toHour={toHour}
					onClose={onCloseDateInterval}
					isOpen={dateIntervalOpen}
				/>
			</Menu>
		</>
	)
}

export default DateIntervalSelectorGlobal
