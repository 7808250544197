import React, { memo } from 'react'
import { connect } from 'react-redux'
import Occurrences from 'components/Occurrences/Occurrences'

const OccurrencesContent = ({ cards, globalFiltersRedux, updateAllComponentsAtThisTimeRedux, type }) => {
	return (
		<Occurrences
			cards={cards}
			isDialog={false}
			externalFeed={null}
			filters={globalFiltersRedux}
			type={type}
			updateAllComponentsAtThisTime={updateAllComponentsAtThisTimeRedux}
		/>
	)
}

const mapStateToProps = ({ store }) => ({
	globalFiltersRedux: store.global.filters,
	updateAllComponentsAtThisTimeRedux: store.global.updateAllComponentsAtThisTime
})

export default connect(mapStateToProps, null)(memo(OccurrencesContent))
