const toggleSideMenuOption = (option, value, thisOptions, onChangeOptions, isComp, sources) => {
	if (isComp) {
		switch (option) {
			case 'engagement':
				onChangeOptions({
					...thisOptions,
					engagement: !thisOptions.engagement,
					posts: false,
					amount: false,
					users: false,
					men: false,
					women: false,
					organization: false,
					noClass: false,
					left_wing: false,
					right_wing: false,
					centre_left_wing: false,
					centre_right_wing: false,
					centre: false,
					twitter: false,
					bluesky: false,
					facebook: false,
					instagram: false,
					tiktok: false,
					youtube: false,
					news: false,
					comments: false,
					reposts: false
				})
				break
			case 'posts':
				onChangeOptions({
					...thisOptions,
					posts: !thisOptions.posts,
					engagement: false,
					amount: false,
					users: false,
					men: false,
					women: false,
					organization: false,
					noClass: false,
					left_wing: false,
					right_wing: false,
					centre_left_wing: false,
					centre_right_wing: false,
					centre: false,
					twitter: false,
					bluesky: false,
					facebook: false,
					instagram: false,
					tiktok: false,
					youtube: false,
					news: false,
					comments: false,
					reposts: false
				})
				break
			case 'amount':
				onChangeOptions({
					...thisOptions,
					posts: thisOptions.type === 'posts' ? !thisOptions.posts : false,
					engagement: thisOptions.type === 'engagement' ? !thisOptions.engagement : false,
					amount: true,
					users: false,
					men: false,
					women: false,
					organization: false,
					noClass: false,
					left_wing: false,
					right_wing: false,
					centre_left_wing: false,
					centre_right_wing: false,
					centre: false,
					twitter: false,
					bluesky: false,
					facebook: false,
					instagram: false,
					tiktok: false,
					youtube: false,
					news: false,
					comments: false,
					reposts: false
				})
				break
			case 'users':
				onChangeOptions({
					...thisOptions,
					users: !thisOptions.users,
					posts: false,
					engagement: false,
					amount: false,
					men: false,
					women: false,
					organization: false,
					noClass: false,
					left_wing: false,
					right_wing: false,
					centre_left_wing: false,
					centre_right_wing: false,
					centre: false,
					twitter: false,
					bluesky: false,
					facebook: false,
					instagram: false,
					tiktok: false,
					youtube: false,
					news: false,
					comments: false,
					reposts: false
				})
				break

			case 'men':
				onChangeOptions({
					...thisOptions,
					men: !thisOptions.men,
					users: false,
					posts: false,
					engagement: false,
					amount: false,
					women: false,
					organization: false,
					noClass: false,
					left_wing: false,
					right_wing: false,
					centre_left_wing: false,
					centre_right_wing: false,
					centre: false,
					twitter: false,
					bluesky: false,
					facebook: false,
					instagram: false,
					tiktok: false,
					youtube: false,
					news: false,
					comments: false,
					reposts: false
				})
				break
			case 'women':
				onChangeOptions({
					...thisOptions,
					women: !thisOptions.women,
					users: false,
					amount: false,
					posts: false,
					engagement: false,
					men: false,
					organization: false,
					noClass: false,
					left_wing: false,
					right_wing: false,
					centre_left_wing: false,
					centre_right_wing: false,
					centre: false,
					twitter: false,
					bluesky: false,
					facebook: false,
					instagram: false,
					tiktok: false,
					youtube: false,
					news: false,
					comments: false,
					reposts: false
				})
				break
			case 'organization':
				onChangeOptions({
					...thisOptions,
					organization: !thisOptions.organization,
					users: false,
					posts: false,
					amount: false,
					engagement: false,
					men: false,
					women: false,
					noClass: false,
					left_wing: false,
					right_wing: false,
					centre_left_wing: false,
					centre_right_wing: false,
					centre: false,
					twitter: false,
					bluesky: false,
					facebook: false,
					instagram: false,
					tiktok: false,
					youtube: false,
					news: false,
					comments: false,
					reposts: false
				})
				break
			case 'noClass':
				onChangeOptions({
					...thisOptions,
					noClass: !thisOptions.noClass,
					engagement: false,
					users: false,
					amount: false,
					posts: false,
					men: false,
					women: false,
					organization: false,
					left_wing: false,
					right_wing: false,
					centre_left_wing: false,
					centre_right_wing: false,
					centre: false,
					twitter: false,
					bluesky: false,
					facebook: false,
					instagram: false,
					tiktok: false,
					youtube: false,
					news: false,
					comments: false,
					reposts: false
				})
				break
			case 'left_wing':
				onChangeOptions({
					...thisOptions,
					engagement: false,
					users: false,
					posts: false,
					amount: false,
					men: false,
					women: false,
					organization: false,
					noClass: false,
					left_wing: true,
					right_wing: false,
					centre_left_wing: false,
					centre_right_wing: false,
					centre: false,
					twitter: false,
					bluesky: false,
					facebook: false,
					instagram: false,
					tiktok: false,
					youtube: false,
					news: false,
					comments: false,
					reposts: false
				})
				break
			case 'right_wing':
				onChangeOptions({
					...thisOptions,
					engagement: false,
					users: false,
					posts: false,
					amount: false,
					men: false,
					women: false,
					organization: false,
					noClass: false,
					left_wing: false,
					right_wing: true,
					centre_left_wing: false,
					centre_right_wing: false,
					centre: false,
					twitter: false,
					bluesky: false,
					facebook: false,
					instagram: false,
					tiktok: false,
					youtube: false,
					news: false,
					comments: false,
					reposts: false
				})
				break
			case 'centre_left_wing':
				onChangeOptions({
					...thisOptions,
					engagement: false,
					users: false,
					posts: false,
					amount: false,
					men: false,
					women: false,
					organization: false,
					noClass: false,
					left_wing: false,
					right_wing: false,
					centre_left_wing: true,
					centre_right_wing: false,
					centre: false,
					twitter: false,
					bluesky: false,
					facebook: false,
					instagram: false,
					tiktok: false,
					youtube: false,
					news: false,
					comments: false,
					reposts: false
				})
				break
			case 'centre_right_wing':
				onChangeOptions({
					...thisOptions,
					engagement: false,
					users: false,
					posts: false,
					amount: false,
					men: false,
					women: false,
					organization: false,
					noClass: false,
					left_wing: false,
					right_wing: false,
					centre_left_wing: false,
					centre_right_wing: true,
					centre: false,
					twitter: false,
					bluesky: false,
					facebook: false,
					instagram: false,
					tiktok: false,
					youtube: false,
					news: false,
					comments: false,
					reposts: false
				})
				break
			case 'centre':
				onChangeOptions({
					...thisOptions,
					engagement: false,
					users: false,
					posts: false,
					men: false,
					amount: false,
					women: false,
					organization: false,
					noClass: false,
					left_wing: false,
					right_wing: false,
					centre_left_wing: false,
					centre_right_wing: false,
					centre: true,
					twitter: false,
					bluesky: false,
					facebook: false,
					instagram: false,
					tiktok: false,
					youtube: false,
					news: false,
					comments: false,
					reposts: false
				})
				break
			case 'twitter':
				onChangeOptions({
					...thisOptions,
					engagement: false,
					users: false,
					posts: false,
					amount: false,
					men: false,
					women: false,
					organization: false,
					noClass: false,
					left_wing: false,
					right_wing: false,
					centre_left_wing: false,
					centre_right_wing: false,
					centre: false,
					twitter: true,
					facebook: false,
					instagram: false,
					tiktok: false,
					youtube: false,
					news: false,
					comments: false,
					reposts: false
				})
				break
			case 'bluesky':
				onChangeOptions({
					...thisOptions,
					engagement: false,
					users: false,
					amount: false,
					posts: false,
					men: false,
					women: false,
					organization: false,
					noClass: false,
					left_wing: false,
					right_wing: false,
					centre_left_wing: false,
					centre_right_wing: false,
					centre: false,
					twitter: false,
					bluesky: true,
					facebook: false,
					instagram: false,
					tiktok: false,
					youtube: false,
					news: false,
					comments: false,
					reposts: false
				})
				break
			case 'facebook':
				onChangeOptions({
					...thisOptions,
					engagement: false,
					users: false,
					amount: false,
					posts: false,
					men: false,
					women: false,
					organization: false,
					noClass: false,
					left_wing: false,
					right_wing: false,
					centre_left_wing: false,
					centre_right_wing: false,
					centre: false,
					twitter: false,
					bluesky: false,
					facebook: true,
					instagram: false,
					tiktok: false,
					youtube: false,
					news: false,
					comments: false,
					reposts: false
				})
				break
			case 'instagram':
				onChangeOptions({
					...thisOptions,
					engagement: false,
					users: false,
					amount: false,
					posts: false,
					men: false,
					women: false,
					organization: false,
					noClass: false,
					left_wing: false,
					right_wing: false,
					centre_left_wing: false,
					centre_right_wing: false,
					centre: false,
					twitter: false,
					bluesky: false,
					facebook: false,
					instagram: true,
					tiktok: false,
					youtube: false,
					news: false,
					comments: false,
					reposts: false
				})
				break
			case 'tiktok':
				onChangeOptions({
					...thisOptions,
					engagement: false,
					users: false,
					amount: false,
					posts: false,
					men: false,
					women: false,
					organization: false,
					noClass: false,
					left_wing: false,
					right_wing: false,
					centre_left_wing: false,
					centre_right_wing: false,
					centre: false,
					twitter: false,
					bluesky: false,
					facebook: false,
					instagram: false,
					tiktok: true,
					youtube: false,
					news: false,
					comments: false,
					reposts: false
				})
				break
			case 'youtube':
				onChangeOptions({
					...thisOptions,
					engagement: false,
					users: false,
					amount: false,
					posts: false,
					men: false,
					women: false,
					organization: false,
					noClass: false,
					left_wing: false,
					right_wing: false,
					centre_left_wing: false,
					centre_right_wing: false,
					centre: false,
					twitter: false,
					bluesky: false,
					facebook: false,
					instagram: false,
					tiktok: false,
					youtube: true,
					news: false,
					comments: false,
					reposts: false
				})
				break
			case 'news':
				onChangeOptions({
					...thisOptions,
					engagement: false,
					users: false,
					amount: false,
					posts: false,
					men: false,
					women: false,
					organization: false,
					noClass: false,
					left_wing: false,
					right_wing: false,
					centre_left_wing: false,
					centre_right_wing: false,
					centre: false,
					twitter: false,
					bluesky: false,
					facebook: false,
					instagram: false,
					tiktok: false,
					youtube: false,
					news: true,
					comments: false,
					reposts: false
				})
				break
			case 'comments':
				onChangeOptions({
					...thisOptions,
					engagement: false,
					users: false,
					posts: false,
					amount: false,
					men: false,
					women: false,
					organization: false,
					noClass: false,
					left_wing: false,
					right_wing: false,
					centre_left_wing: false,
					centre_right_wing: false,
					centre: false,
					twitter: false,
					bluesky: false,
					facebook: false,
					instagram: false,
					tiktok: false,
					youtube: false,
					news: false,
					comments: true,
					reposts: false
				})
				break
			case 'reposts':
				onChangeOptions({
					...thisOptions,
					engagement: false,
					users: false,
					amount: false,
					posts: false,
					men: false,
					women: false,
					organization: false,
					noClass: false,
					left_wing: false,
					right_wing: false,
					centre_left_wing: false,
					centre_right_wing: false,
					centre: false,
					twitter: false,
					bluesky: false,
					facebook: false,
					instagram: false,
					tiktok: false,
					youtube: false,
					news: false,
					comments: false,
					reposts: true
				})
				break
		}
	} else {
		switch (option) {
			case 'engagement':
				onChangeOptions({ ...thisOptions, engagement: value })
				break
			case 'amount':
				onChangeOptions({
					...thisOptions,
					amount: value,
					posts: thisOptions.type === 'posts' && value,
					engagement: thisOptions.type === 'engagement' && value
				})
				break
			case 'posts':
				onChangeOptions({ ...thisOptions, posts: value })
				break
			case 'users':
				onChangeOptions({ ...thisOptions, users: value })
				break
			case 'gender':
				onChangeOptions({
					...thisOptions,
					gender: value,
					men: value,
					women: value,
					organization: value,
					noClass: value
				})
				break
			case 'men':
				onChangeOptions({ ...thisOptions, men: value })
				break
			case 'women':
				onChangeOptions({ ...thisOptions, women: value })
				break
			case 'organization':
				onChangeOptions({ ...thisOptions, organization: value })
				break
			case 'noClass':
				onChangeOptions({ ...thisOptions, noClass: value })
				break
			case 'politics':
				onChangeOptions({
					...thisOptions,
					politics: value,
					left_wing: thisOptions.left_wing === false || thisOptions.left_wing === true ? value : undefined,
					right_wing: thisOptions.right_wing === false || thisOptions.right_wing === true ? value : undefined,
					centre_left_wing: thisOptions.centre_left_wing === false || thisOptions.centre_left_wing === true ? value : undefined,
					centre_right_wing: thisOptions.centre_right_wing === false || thisOptions.centre_right_wing === true ? value : undefined,
					centre: thisOptions.centre === false || thisOptions.centre === true ? value : undefined
				})
				break
			case 'left_wing':
				onChangeOptions({ ...thisOptions, left_wing: value })
				break
			case 'right_wing':
				onChangeOptions({ ...thisOptions, right_wing: value })
				break
			case 'centre_left_wing':
				onChangeOptions({ ...thisOptions, centre_left_wing: value })
				break
			case 'centre_right_wing':
				onChangeOptions({ ...thisOptions, centre_right_wing: value })
				break
			case 'centre':
				onChangeOptions({ ...thisOptions, centre: value })
				break
			case 'sources':
				onChangeOptions({
					...thisOptions,
					sources: value,
					twitter: sources.includes('twitter') && value ? true : false,
					bluesky: sources.includes('bluesky') && value ? true : false,
					facebook: sources.includes('facebook') && value ? true : false,
					instagram: sources.includes('instagram') && value ? true : false,
					tiktok: sources.includes('tiktok') && value ? true : false,
					youtube: sources.includes('youtube') && value ? true : false,
					news: sources.includes('news') && value ? true : false
				})
				break
			case 'twitter':
				onChangeOptions({ ...thisOptions, twitter: value })
				break
			case 'bluesky':
				onChangeOptions({ ...thisOptions, bluesky: value })
				break
			case 'facebook':
				onChangeOptions({ ...thisOptions, facebook: value })
				break
			case 'instagram':
				onChangeOptions({ ...thisOptions, instagram: value })
				break
			case 'tiktok':
				onChangeOptions({ ...thisOptions, tiktok: value })
				break
			case 'youtube':
				onChangeOptions({ ...thisOptions, youtube: value })
				break
			case 'news':
				onChangeOptions({ ...thisOptions, news: value })
				break
			case 'comments':
				onChangeOptions({ ...thisOptions, comments: value })
				break
			case 'reposts':
				onChangeOptions({ ...thisOptions, reposts: value })
				break
		}
	}
}

export { toggleSideMenuOption }
