Moment.locale('pt-br')
import Moment from 'moment-timezone'
import React, { useEffect, useState, memo } from 'react'
import { Dialog, IconButton, DialogContent, DialogTitle, Button, Collapse, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { UpdateIcon, CloseIcon, LoadingIcon, HistoryIcon, AlertExclamationIcon } from 'utils/SystemIcons'
import SourceSelectorGlobalFilters from 'components/GlobalFilters/components/SourceSelectorGlobalFilters/SourceSelectorGlobalFilters'
import api from 'service/service'
import constantsVox from 'constants-vox'
import { connect } from 'react-redux'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import { verifyServerResponseCanShowToast } from 'utils/generalFunctions'
import ServiceHistory from 'components/ServiceHistory/ServiceHistory'
import { LuCalendarDays } from 'react-icons/lu'
import ModalScheduleUpdatePosts from '../ModalScheduleUpdatePosts/ModalScheduleUpdatePosts'

const grey = '#b8b8b8'

const useStyles = makeStyles({
	backDrop: {
		backdropFilter: 'blur(3px)',
		backgroundColor: 'rgba(220, 220, 220, 0.4)'
	},
	tab: {
		fontFamily: 'Poppins',
		fontSize: 12,
		color: grey
	}
})

const isFirefox = typeof InstallTrigger !== 'undefined'

const serviceName = 'updatePosts'

const renderButtonContent = (creditsLoading, lastUpdateLoading, isAnyUpdatingStatus, hasntEnoughCredits) => {
	let componentToRender = <></>

	if (creditsLoading || lastUpdateLoading) {
		componentToRender = <LoadingIcon size={25} thickness={2} style={{ color: '#FFF' }} alignCenter />
	} else if ((isAnyUpdatingStatus && !lastUpdateLoading) || hasntEnoughCredits) {
		componentToRender = (
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<AlertExclamationIcon size={20} color='#FFF' />
				<span style={{ marginLeft: '0.3em' }}>
					{' '}
					{hasntEnoughCredits ? 'Não possui créditos suficientes' : 'Aguarde a atualização dos posts'}
				</span>
			</div>
		)
	} else {
		componentToRender = <span>Atualizar</span>
	}

	return componentToRender
}

const getInitialSourcesFromCardStatus = (card) => {
	let sources = []

	Object.keys(card.cardStatus).forEach((key) => {
		if (key !== 'general' && card.cardStatus[key] === 'S') {
			sources.push(key)
		}
	})

	return sources
}

const ModalUpdatePosts = ({ isOpen, handleOnClose, card, color, globalFiltersRedux, remainingCreditsRedux }) => {
	const [creditsLoading, setCreditsLoading] = useState(true)
	const [lastUpdateLoading, setLastUpdateLoading] = useState(true)
	const [isAnyUpdatingStatus, setIsAnyUpdatingStatus] = useState(null)
	const [creditCostAmount, setCreditCostAmount] = useState(null)
	const [openHistory, setOpenHistory] = useState(false)
	const [selectedSources, setSelectedSources] = useState(getInitialSourcesFromCardStatus(card))
	const [isOpenScheduleUpdate, setIsOpenScheduleUpdate] = useState(false)
	const classes = useStyles()
	const hasntEnoughCredits = remainingCreditsRedux < creditCostAmount
	useEffect(() => {
		load()
	}, [selectedSources])

	const onSourceChange = (sources) => {
		setSelectedSources(sources)
	}

	const load = async () => {
		await api
			.get(`/credits/get-cost-amount/${serviceName}`)
			.then((response) => {
				let cost = response.data * selectedSources.length
				if (card.type === 'group') {
					cost = cost * card.accounts.length
				}
				setCreditCostAmount(cost)
				setCreditsLoading(false)
			})
			.catch((error) => {
				sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
			})

		api.get(`/history/get-is-any-updating-status/${serviceName}/${card.type}/${card.id}?sources=${selectedSources}`)
			.then((response) => {
				setIsAnyUpdatingStatus(response.data)
				setLastUpdateLoading(false)
			})
			.catch((error) => {
				sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
			})
	}

	const updatePosts = () => {
		const body = {
			fromDate: globalFiltersRedux.fromDate,
			toDate: globalFiltersRedux.toDate,
			sources: selectedSources,
			cardId: card.id,
			cardType: card.type
		}
		api.post('/update-posts/update', body)
			.then((response) => {
				if (response?.data?.toast) {
					sendMessageToast(response?.data?.toast?.message, response?.data?.toast?.type)
				}
				handleOnClose()
			})
			.catch((error) => {
				if (verifyServerResponseCanShowToast(error)) {
					sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
				}
			})
	}

	const toggleOpenHistory = () => {
		setOpenHistory(!openHistory)
	}

	return (
		<Dialog
			id='dialog-app'
			fullWidth={true}
			maxWidth='md'
			style={{ marginTop: '4%' }}
			open={isOpen}
			onClose={handleOnClose}
			BackdropProps={{ classes: { root: classes.backDrop } }}
			PaperProps={{
				style: {
					borderRadius: '20px',
					backdropFilter: 'blur(10px)',
					backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
					border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.7)'}`,
					boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
				}
			}}
		>
			<DialogTitle>
				<div className='jc-space-between d-flex ai-center'>
					<div style={{ color: color, fontSize: 20 }}>
						<UpdateIcon className='fix-icon-margin-bottom-negative' size={25} color={color} /> Atualizar métricas dos posts
					</div>
					<div>
						<IconButton color='primary' style={{ outline: 'none' }} onClick={handleOnClose}>
							<CloseIcon color={color} size={20} />
						</IconButton>
					</div>
				</div>
			</DialogTitle>
			<DialogContent style={{ paddingTop: 0, marginTop: 0, position: 'relative' }}>
				<div
					style={{
						padding: '1.5em',
						color: 'rgb(120, 120, 120)',
						fontFamily: 'Poppins',
						fontWeight: '300',
						fontSize: '20px'
					}}
				>
					<div>
						Atualizar cartão <span style={{ color }}>{card?.name}</span> de{' '}
						<span style={{ color }}>
							{Moment(globalFiltersRedux?.fromDate).format(constantsVox.JAVASCRIPT.FORMAT_DATE_DEFAULT_BRAZIL)}
						</span>{' '}
						-{' '}
						<span style={{ color }}>{Moment(globalFiltersRedux?.toDate).format(constantsVox.JAVASCRIPT.FORMAT_DATE_DEFAULT_BRAZIL)}</span>
						<br />
						para as <span style={{ color: '#1d8cf8' }}>fontes:</span>
						<div style={{ marginBottom: '1em', marginTop: '0.1em' }}>
							<SourceSelectorGlobalFilters
								onSourceChange={onSourceChange}
								sourcesControlled={selectedSources}
								comparisonStatusControlled={card.cardStatus}
								isOverview={false}
							/>
						</div>
						{hasntEnoughCredits ? (
							<p>
								Não é possível atualizar posts, o serviço custa {creditCostAmount} e você possui {remainingCreditsRedux} créditos
							</p>
						) : (
							<>
								<Collapse in={(isAnyUpdatingStatus && !lastUpdateLoading) || creditsLoading || lastUpdateLoading} unmountOnExit>
									<div style={{ marginTop: '1em', marginBottom: '1em' }}>
										<LoadingIcon size={30} thickness={2} style={{ color }} alignCenter />
									</div>
								</Collapse>

								<Collapse in={!((isAnyUpdatingStatus && !lastUpdateLoading) || creditsLoading || lastUpdateLoading)} unmountOnExit>
									<p>
										Custará {card.type === 'group' && 'aproximadamente'}{' '}
										<span style={{ color: '#1d8cf8' }}>{creditCostAmount}</span> dos seus créditos
									</p>
								</Collapse>
							</>
						)}
						<Grid container>
							<Grid item xs={8}>
								{card.type !== 'group' && (
									<Button
										onClick={toggleOpenHistory}
										variant='outlined'
										style={{
											fontFamily: 'Poppins',
											borderRadius: '100px',
											fontSize: '14px',
											borderColor: 'rgba(29, 138, 248, 0.3)'
										}}
									>
										<HistoryIcon size={21} style={{ color: '#1d8cf8', cursor: 'pointer', marginRight: '5px' }} />
										Histórico
									</Button>
								)}
								{card.type !== 'term' && (
									<Button
										variant='contained'
										onClick={updatePosts}
										disabled={creditsLoading || lastUpdateLoading || isAnyUpdatingStatus || hasntEnoughCredits}
										color='primary'
										style={{
											marginLeft: card.type === 'group' ? '0em' : '2em',
											minWidth: '10em',
											fontFamily: 'Poppins',
											fontWeight: 'bold',
											color: '#1d8cf8',
											borderColor: '#1d8cf8',
											borderRadius: '100px'
										}}
									>
										{renderButtonContent(creditsLoading, lastUpdateLoading, isAnyUpdatingStatus, hasntEnoughCredits)}
									</Button>
								)}
							</Grid>
							<Grid item xs={4} style={{ justifyContent: 'end', display: 'flex' }}>
								<Button
									onClick={() => setIsOpenScheduleUpdate(true)}
									variant='contained'
									color='primary'
									style={{
										minWidth: '10em',
										fontFamily: 'Poppins',
										fontWeight: 'bold',
										color: '#1d8cf8',
										borderColor: '#1d8cf8',
										borderRadius: '100px'
									}}
								>
									<LuCalendarDays size={20} style={{ marginRight: '5px' }} />
									AGENDAR ATUALIZAÇÃO
								</Button>
							</Grid>
						</Grid>
					</div>
				</div>
			</DialogContent>
			<ServiceHistory
				style={{ width: '100%', height: '100%' }}
				isOpen={openHistory}
				cardType={card.type}
				serviceName={serviceName}
				cardId={card.id}
				toggleOpenHistory={toggleOpenHistory}
				cardName={card.name}
				sources={getInitialSourcesFromCardStatus(card)}
			/>
			{isOpenScheduleUpdate && (
				<ModalScheduleUpdatePosts
					selectedSources={selectedSources}
					onSourceChange={onSourceChange}
					isOpen={isOpenScheduleUpdate}
					handleOnClose={() => setIsOpenScheduleUpdate(false)}
					card={card}
					color={color}
				/>
			)}
		</Dialog>
	)
}

const mapStateToProps = ({ store }) => ({
	globalFiltersRedux: store?.global?.filters,
	remainingCreditsRedux: store?.user?.creditInfo?.remainingMonth
})

export default connect(mapStateToProps)(memo(ModalUpdatePosts))
