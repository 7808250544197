import React, { memo, useState, useEffect } from 'react'
import { Grid, Card } from '@mui/material'
import TopicAnalysis from 'components/TopicAnalysis/TopicAnalysis'
import GraphTitle from 'components/GraphTitle/GraphTitle'
import { AllSourcesIcon } from 'utils/SystemIcons'
import AiAnalysis from 'components/AiAnalysis/AiAnalysis'
import { connect } from 'react-redux'

const emptyMessage = (cards) => (
	<div
		style={{
			height: '40vh',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			fontFamily: 'Poppins',
			fontSize: '24px',
			color: '#bdbbbb'
		}}
	>
		{cards.length > 1 ? 'Não disponível para comparações' : 'Componente não disponível para a fonte escolhida (Notícias)'}
	</div>
)

const VoxRadarIaContent = ({ cardStatus, cards, isFetchingData, source, showAiAnalysis = true, showTopicAnalysis = true, isWidget = false }) => {
	const [cardList, setCardList] = useState(cards)
	const canShowTwitter = () => cardStatus.twitter === 'S'
	const canShowBluesky = () => cardStatus.bluesky === 'S'
	const canShowFacebook = () => cardStatus.facebook === 'S'
	const canShowInstagram = () => cardStatus.instagram === 'S'
	const canShowTiktok = () => cardStatus.tiktok === 'S'
	const canShowYoutube = () => cardStatus.youtube === 'S'

	useEffect(() => {
		setCardList(cards)
	}, [cards])

	const AiAnalysisContent = () => (
		<>
			<Grid item xs={12} container style={{ background: '#fbfbfb', height: '40px', borderBottom: '1px solid #f5f5f5' }}>
				<GraphTitle id='span-gender-age' title='Análise com IA' icon={<AllSourcesIcon size={25} color={'#1d8cf8'} />} />
			</Grid>
			{cards.length > 1 || (source.length === 1 && source.includes('news')) ? (
				emptyMessage(cards)
			) : (
				<AiAnalysis cards={cardList} isFetchingData={isFetchingData} isWidget={isWidget} />
			)}
		</>
	)

	const TopicAnalysisContent = () => (
		<>
			<Grid item xs={12} container style={{ background: '#fbfbfb', height: '40px', borderBottom: '1px solid #f5f5f5' }}>
				<GraphTitle id='span-gender-age' title='Principais tópicos' icon={<AllSourcesIcon size={25} color={'#1d8cf8'} />} />
			</Grid>
			{cards.length > 1 || (source.length === 1 && source.includes('news')) ? (
				emptyMessage(cards)
			) : (
				<TopicAnalysis cards={cardList} isFetchingData={isFetchingData} isWidget={isWidget} />
			)}
		</>
	)

	return (
		<Grid container spacing={3}>
			{(canShowTwitter() ||
				canShowBluesky() ||
				canShowFacebook() ||
				canShowInstagram() ||
				canShowTiktok() ||
				canShowYoutube() ||
				source.includes('news')) &&
				showAiAnalysis && (
					<Grid container item xs={12}>
						<>
							{isWidget ? (
								<div
									style={{
										height: '100%',
										width: '100%',
										backgroundColor: 'transparent'
									}}
								>
									{AiAnalysisContent()}
								</div>
							) : (
								<Card
									style={{
										height: '100%',
										width: '100%',
										borderRadius: '20px',
										position: 'relative',
										backgroundColor: 'transparent'
									}}
								>
									{AiAnalysisContent()}
								</Card>
							)}
						</>
					</Grid>
				)}
			{(canShowTwitter() ||
				canShowBluesky() ||
				canShowFacebook() ||
				canShowInstagram() ||
				canShowTiktok() ||
				canShowYoutube() ||
				source.includes('news')) &&
				showTopicAnalysis && (
					<Grid container item xs={12}>
						<>
							{isWidget ? (
								<div
									style={{
										height: '100%',
										width: '100%',
										backgroundColor: 'transparent'
									}}
								>
									{TopicAnalysisContent()}
								</div>
							) : (
								<Card
									style={{
										height: '100%',
										width: '100%',
										borderRadius: '20px',
										position: 'relative',
										backgroundColor: 'transparent'
									}}
								>
									{TopicAnalysisContent()}
								</Card>
							)}
						</>
					</Grid>
				)}
		</Grid>
	)
}

const mapStateToProps = ({ store }) => ({
	source: store.global.filters.sources,
	cardStatus: store.card.comparison.status
})
export default connect(mapStateToProps)(memo(VoxRadarIaContent))
