import React, { useState, useEffect, useRef, memo } from 'react'
import { Grid, Card, IconButton } from '@mui/material'
import Chart from 'react-apexcharts'
import LoadingBar from 'components/LoadingBar/LoadingBar'
import GraphTitle from 'components/GraphTitle/GraphTitle'
import { AlertCircle, CloseIcon, MoveResizeIcon } from 'utils/SystemIcons'
import ExpandingGraph from '../ExpandingGraph/ExpandingGraph'

const isEmpty = (values) => {
	if (values?.length === 0) {
		return true
	} else {
		let isAllZero = true

		values?.forEach((value) => {
			if (value !== 0) {
				isAllZero = false
			}
		})

		return isAllZero
	}
}

const BarMetric = ({
	loading = false,
	categories,
	values,
	title = '',
	label = '',
	graphData,
	source,
	isHorizontal = true,
	style,
	description,
	editMode,
	addWidgetMode,
	removeWidget,
	keyName
}) => {
	const [hardLoading, setHardLoading] = useState(true)
	const loadingBarRef = useRef()
	const isMounted = useRef(true)

	useEffect(() => {
		isMounted.current = true

		if (isMounted.current) {
			loadingBarRef?.current?.load(loading && !hardLoading)
		}

		if (!loading) {
			setHardLoading(false)
		}

		return () => {
			isMounted.current = false
		}
	}, [loading])

	const options = {
		chart: {
			type: 'bar',
			height: 350
		},
		plotOptions: {
			bar: {
				borderRadius: 4,
				horizontal: isHorizontal,
				dataLabels: {
					position: 'bottom'
				}
			}
		},
		dataLabels: {
			enabled: true,
			offsetX: 6,
			style: {
				fontSize: '12px',
				colors: ['#fff']
			}
		},
		xaxis: {
			categories
		}
	}

	return (
		<>
			{addWidgetMode && (
				<div style={{ position: 'absolute', right: '1px' }}>
					<IconButton onClick={() => removeWidget(keyName)}>
						<CloseIcon color={'#1d8cf8'} />
					</IconButton>
				</div>
			)}
			{editMode && (
				<div
					style={{
						borderRadius: '20px',
						position: 'absolute',
						top: 47,
						left: 3,
						right: 3,
						bottom: 8,
						backdropFilter: 'blur(5px)',
						backgroundColor: 'rgba(250, 250, 250, 0.5)',
						zIndex: 1000,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center'
					}}
				>
					<MoveResizeIcon size={60} style={{ marginBottom: '3em', color: '#1d8cf8' }} />
				</div>
			)}
			<Card style={{ borderRadius: '20px', height: '100%', ...style }} className={editMode ? 'editBox' : ''}>
				<Grid container spacing={3} style={{ background: '#fbfbfb', height: '70px', borderBottom: '1px solid #f5f5f5' }}>
					<Grid item xs={12}>
						<GraphTitle title={title || ''} description={description} titleCentered={true} />
					</Grid>
				</Grid>

				<div>
					<LoadingBar ref={loadingBarRef} />
				</div>

				<>
					{isEmpty(values) ? (
						<div style={{ height: '80%', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
							<div>
								<div style={{ display: 'flex', justifyContent: 'center', marginBottom: '2em' }}>
									<div
										style={{
											backgroundColor: '#c2c2c2',
											width: '3em',
											height: '3em',
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											alignContent: 'center',
											borderRadius: '26%'
										}}
									>
										<AlertCircle size={30} style={{ color: '#fff', margin: '5px' }} />
									</div>
								</div>
								<div style={{ fontFamily: 'Poppins', fontSize: '0.9em', fontWeight: 'bold', color: '#b0b0b0' }}>
									Não foram econtrados dados para esse período
								</div>
							</div>
						</div>
					) : (
						<>
							<Chart
								options={options}
								series={[{ data: values, name: label }]}
								type='bar'
								width='100%'
								height='70%'
								style={{ margin: '1em' }}
							/>
							{graphData?.series?.length > 0 && (
								<Grid container justifyContent='center' style={{ marginTop: '2em' }}>
									<ExpandingGraph source={source} graphData={graphData} title={title} />
								</Grid>
							)}
						</>
					)}
				</>
			</Card>
		</>
	)
}

export default memo(BarMetric)
