import React, { memo, useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import LocationStateAndRegionGraph from 'graphs/LocationStateAndRegionGraph/LocationStateAndRegionGraph'
import LocationGraph from 'components/LocationGraph/LocationGraph'

const LocationContent = ({ cardStatus, cards, isFetchingData }) => {
	const [cardList, setCardList] = useState(cards)
	const canShowTwitter = () => cardStatus.twitter === 'S'
	const canShowBluesky = () => cardStatus.bluesky === 'S'
	const canShowFacebook = () => cardStatus.facebook === 'S'
	const canShowInstagram = () => cardStatus.instagram === 'S'
	const canShowTiktok = () => cardStatus.tiktok === 'S'
	const canShowYoutube = () => cardStatus.youtube === 'S'

	useEffect(() => {
		setCardList(cards)
	}, [cards])

	return (
		<Grid container spacing={3}>
			{(canShowTwitter() || canShowBluesky() || canShowFacebook() || canShowInstagram() || canShowTiktok() || canShowYoutube()) && (
				<LocationGraph cards={cardList} />
			)}
			{(canShowTwitter() || canShowBluesky() || canShowFacebook() || canShowInstagram() || canShowTiktok() || canShowYoutube()) && (
				<LocationStateAndRegionGraph cards={cardList} isFetchingData={isFetchingData} />
			)}
		</Grid>
	)
}

export default memo(LocationContent)
