import React, { memo, useState } from 'react'
import { Grid, Card, FormControl, Select, MenuItem, Divider, styled, IconButton } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { BarsIcon, ChartInfographicIcon, AlertCircle, CloseIcon, MoveResizeIcon } from 'utils/SystemIcons'
import GraphTitle from 'components/GraphTitle/GraphTitle'
import GenderAgeChartAccountManagement from './components/GenderAgeChartAccountManagement/GenderAgeChartAccountManagement'
import ExpandingGraph from '../ExpandingGraph/ExpandingGraph'

const blue = 'rgba(29, 138, 248, 100%)'
const grey = 'rgba(0, 0, 0, 4%)'
const darkGrey = 'rgba(0, 0, 0, 25%)'
const darkPurple = 'rgb(151, 85, 217)'
const lightgrey = 'rgb(220, 220, 220)'
const lightgreen = 'rgb(0, 222, 174)'

const useStyles = makeStyles(() => ({
	formControl: {
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderColor: blue
		},
		width: '100%'
	},
	select: {
		'&:hover': {
			borderColor: grey
		},
		'&:before': {
			borderColor: grey
		},
		'&:after': {
			borderColor: blue
		},
		height: '35px',
		'& .MuiSvgIcon-root': {
			color: darkGrey
		}
	},
	tab: {
		fontFamily: 'Poppins',
		fontSize: 12,
		fontWeight: 300,
		color: '#828282'
	}
}))

const isFirefox = typeof InstallTrigger !== 'undefined'

const StyledFormControl = styled(FormControl)({
	'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
		borderColor: blue
	},
	width: '100%'
})

const StyledSelect = styled(Select)({
	'&:hover': {
		borderColor: grey
	},
	'&:before': {
		borderColor: grey
	},
	'&:after': {
		borderColor: blue
	},
	height: '35px',
	'& .MuiSvgIcon-root': {
		color: darkGrey
	}
})

const optionsList = [
	{
		title: 'Totais',
		type: 'infographic'
	},
	{
		title: 'Distribuído',
		type: 'bars'
	}
]

const getIcon = (type) => {
	switch (type) {
		case 'bars':
			return <BarsIcon color={'#1d8cf8'} size={20} style={{ marginRight: '10px' }} />
		case 'infographic':
			return (
				<div style={{ color: '#1d8cf8' }}>
					<ChartInfographicIcon color={'#1d8cf8'} size={20} style={{ marginRight: '10px' }} />
				</div>
			)
	}
}

const isEmpty = (data) => {
	if (data?.formatted[0]?.seriesAll?.length === 0) {
		return true
	}

	return false
}

const GenderAgeDistributionAccountManagement = ({
	data,
	graphData,
	source,
	editMode,
	addWidgetMode,
	removeWidget,
	title,
	keyName,
	description = ''
}) => {
	const classes = useStyles()
	const [selectedOptionList, setSelectedOptionList] = useState('infographic')

	const handleSelect = (event) => {
		setSelectedOptionList(event.target.value)
	}

	return (
		<>
			{addWidgetMode && (
				<div style={{ position: 'absolute', right: '1px' }}>
					<IconButton onClick={() => removeWidget(keyName)}>
						<CloseIcon color={'#1d8cf8'} />
					</IconButton>
				</div>
			)}
			{editMode && (
				<div
					style={{
						borderRadius: '20px',
						position: 'absolute',
						top: 47,
						left: 3,
						right: 3,
						bottom: 8,
						backdropFilter: 'blur(5px)',
						backgroundColor: 'rgba(250, 250, 250, 0.5)',
						zIndex: 1000,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center'
					}}
				>
					<MoveResizeIcon size={60} style={{ marginBottom: '3em', color: '#1d8cf8' }} />
				</div>
			)}
			<Grid item style={{ height: '100%', width: '100%', borderRadius: '20px' }}>
				<Card
					style={{
						height: '100%',
						width: '100%',
						borderRadius: '20px',
						pointerEvents: editMode ? 'none' : '',
						userSelect: editMode ? 'none' : ''
					}}
					className={editMode ? 'editBox' : ''}
				>
					<Grid container spacing={3} style={{ background: '#fbfbfb', height: '70px', borderBottom: '1px solid #f5f5f5' }}>
						<Grid item xs={12}>
							<GraphTitle
								id='span-gender-age'
								title={title || ''}
								icon={<BarsIcon size={25} style={{ color: '#1d8cf8' }} />}
								description={description}
							/>
						</Grid>
					</Grid>
					{source === 'facebook' && (
						<Grid item xs={12} container justifyContent='flex-end'>
							<Grid item xs={2} style={{ marginTop: '6px', marginRight: '5px' }}>
								<StyledFormControl className={classes.formControl}>
									<StyledSelect
										id='select-gender-age-distribution'
										variant='standard'
										className={classes.select}
										value={selectedOptionList}
										onChange={handleSelect}
										style={{ marginTop: '7px' }}
										MenuProps={{
											anchorOrigin: {
												vertical: 'bottom',
												horizontal: 'left'
											},
											transformOrigin: {
												vertical: 'top',
												horizontal: 'left'
											},
											PaperProps: {
												style: {
													marginTop: '0px',
													marginLeft: '-1px',
													borderRadius: '0px 0px 25px 25px',
													backdropFilter: 'blur(10px)',
													backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
													borderBottom: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
													borderLeft: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
													borderRight: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
													boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
												}
											}
										}}
									>
										{optionsList.map((option) => {
											return (
												<MenuItem key={option.type} value={option.type}>
													<Grid container justifyContent='flex-start' alignItems='center' style={{ fontFamily: 'Poppins' }}>
														{getIcon(option.type)}
														{option.title}
													</Grid>
												</MenuItem>
											)
										})}
									</StyledSelect>
								</StyledFormControl>
							</Grid>
						</Grid>
					)}
					<Divider style={{ backgroundColor: 'rgba(0,0,0,0.001)', height: '1px', marginTop: '-1px', width: '100%' }} />
					{isEmpty(data) ? (
						<div style={{ height: '80%', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
							<div>
								<div style={{ display: 'flex', justifyContent: 'center', marginBottom: '2em' }}>
									<div
										style={{
											backgroundColor: '#c2c2c2',
											width: '3em',
											height: '3em',
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											alignContent: 'center',
											borderRadius: '26%'
										}}
									>
										<AlertCircle size={30} style={{ color: '#fff', margin: '5px' }} />
									</div>
								</div>
								<div style={{ fontFamily: 'Poppins', fontSize: '0.9em', fontWeight: 'bold', color: '#b0b0b0' }}>
									Não foram econtrados dados para esse período
								</div>
							</div>
						</div>
					) : (
						<GenderAgeChartAccountManagement
							selectedOption={selectedOptionList}
							data={data}
							colors={[lightgreen, darkPurple, lightgrey]}
						/>
					)}
					<div style={{ marginBottom: '2em' }}>
						{graphData?.series?.length > 0 && source === 'facebook' && (
							<Grid container justifyContent='center' style={{ marginTop: '2em' }}>
								<ExpandingGraph source={source} graphData={graphData} title='Seguidores por gênero e idade' />
							</Grid>
						)}
						{source === 'instagram' && (
							<Grid container>
								{graphData?.ageTimelineFormatted?.series?.length > 0 && (
									<Grid item xs={6} style={{ marginTop: '2em', justifyContent: 'center', display: 'flex' }}>
										<ExpandingGraph
											source={source}
											graphData={graphData.ageTimelineFormatted}
											title='Seguidores por idade'
											buttonName='ver gráfico de idade'
										/>
									</Grid>
								)}
								{graphData?.genderTimelineFormatted?.series?.length > 0 && (
									<Grid item xs={6} style={{ marginTop: '2em', justifyContent: 'center', display: 'flex' }}>
										<ExpandingGraph
											source={source}
											graphData={graphData.genderTimelineFormatted}
											title='Seguidores por gênero'
											buttonName='ver gráfico de gênero'
											colors={[lightgreen, darkPurple, lightgrey]}
										/>
									</Grid>
								)}
							</Grid>
						)}
					</div>
				</Card>
			</Grid>
		</>
	)
}

export default memo(GenderAgeDistributionAccountManagement)
