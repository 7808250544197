import React, { useEffect, useState } from 'react'
const { forwardRef, useImperativeHandle } = React
import MediaCountRenderer from './components/MediaCountRenderer'
import { useUpdateData } from 'hooks/ReactQueryHooks'
import { verifyServerResponseCanShowToast } from 'utils/generalFunctions'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import { ImageIcon, VideoIcon } from 'utils/SystemIcons'

const getComparativeValue = (data, type) => {
	let totalValue = 0
	data?.map((card) => {
		totalValue += card?.selectedInterval?.metrics?.[type] || 0
	})

	return totalValue
}

const imageIcon = <ImageIcon size={15} style={{ color: '#1d8cf8', marginRight: '10px' }} />
const videoIcon = <VideoIcon size={15} style={{ color: '#1d8cf8', marginRight: '10px' }} />

const MediaCountWidget = ({ cards, filters, isFetchingData = true }, ref) => {
	const [interval, setInterval] = useState({ fromDate: filters.fromDate, toDate: filters.toDate })
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		setInterval({ fromDate: filters.fromDate, toDate: filters.toDate })
	}, [filters.fromDate, filters.toDate, cards])

	useImperativeHandle(ref, () => ({
		f(newInterval) {
			setInterval(newInterval)
		}
	}))

	const getBody = () => ({
		configGlobalFilters: {
			cardIds: cards?.map((card) => card.id),
			cardType: cards[0].type.toUpperCase()
		},
		globalFilters: { ...filters, fromDate: interval.fromDate, toDate: interval.toDate }
	})
	let { data, error, isFetching, isFetched } = useUpdateData({
		url: '/mini-summary',
		updateItemName: 'MediaCount' + cards?.map((card) => card.id),
		refetchInterval: 600000,
		freshDataTime: 600000,
		isEnabled: isFetchingData,
		queryName: 'relevanceChange_useUpdateData',
		method: 'post',
		body: getBody()
	})

	if (verifyServerResponseCanShowToast(error)) {
		sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
	}

	useEffect(() => {
		if (isFetched) {
			setLoading(false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFetched])

	return (
		<>
			{!loading && !!getComparativeValue(data, 'video_count') && (
				<MediaCountRenderer loading={isFetching} value={getComparativeValue(data, 'video_count')} title={'Vídeos'} icon={videoIcon} />
			)}
			{!loading && !!getComparativeValue(data, 'image_count') && (
				<MediaCountRenderer loading={isFetching} value={getComparativeValue(data, 'image_count')} title={'Imagens'} icon={imageIcon} />
			)}
		</>
	)
}

export default forwardRef(MediaCountWidget)
