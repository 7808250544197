import React from 'react'
import { LinkShareIcon } from 'utils/SystemIcons'

const styles = {
	container: {
		marginTop: '2em',
		border: '1px solid #ddd',
		borderRadius: '20px',
		overflow: 'hidden',
		backgroundColor: '#fff',
		marginBottom: '10px',
		transition: 'transform 0.2s',
		display: 'flex',
		flexDirection: 'column'
	},
	content: {
		padding: '15px'
	},
	link: {
		fontSize: '12px',
		color: '#1d8cf8',
		textDecoration: 'none',
		wordBreak: 'break-all'
	},
	iconLinkContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start'
	},
	icon: {
		marginRight: '10px',
		width: '20px',
		height: '20px',
		flexShrink: 0
	}
}

const LinkPreview = ({ url }) => {
	return (
		<>
			<div style={styles.container}>
				<div style={styles.content}>
					<div style={styles.iconLinkContainer}>
						<LinkShareIcon color={'#1d8cf8'} style={styles.icon} />
						<a href={url} target='_blank' rel='noopener noreferrer' style={styles.link}>
							{url}
						</a>
					</div>
				</div>
			</div>
		</>
	)
}

export default LinkPreview
