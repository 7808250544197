import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import MiniCardNumber from 'components/MiniCards/components/MiniCardNumber/MiniCardNumber'
import HeatMap from './components/HeatMap/HeatMap'

const formatData = (data) => {
	return data.map((brand, index) => {
		return {
			cardId: index,
			name: brand.name,
			selectedInterval: {
				metrics: {
					imageCount: brand.imageCount,
					videoCount: brand.videoCount,
					imageProportion: brand.imageProportion,
					videoProportion: brand.videoProportion,
					imageQuadrants: brand.imageQuadrants,
					videoQuadrants: brand.videoQuadrants
				}
			}
		}
	})
}

const AuraSightDashboard = ({ brands, selectedBrands }) => {
	const [data, setData] = useState()

	useEffect(() => {
		const filteredData = brands.filter((brand) => selectedBrands.includes(brand.name))
		setData(formatData(filteredData))
	}, [brands, selectedBrands])

	return (
		<Grid container spacing={3} style={{ marginTop: '1em' }}>
			<Grid item xs={3}>
				<MiniCardNumber
					id={1}
					data={data}
					metric='imageCount'
					title='Número de imagens'
					loading={false}
					isOverview={false}
					isComp={true}
					cardIds={[1]}
					isWidget={true}
					useColorDictionary={false}
				/>
			</Grid>
			<Grid item xs={3}>
				<MiniCardNumber
					id={2}
					data={data}
					metric='imageProportion'
					title='Proporção da tela das imagens'
					loading={false}
					isOverview={false}
					isComp={true}
					cardIds={[1]}
					isWidget={true}
					useColorDictionary={false}
					dataType='percentage'
				/>
			</Grid>
			<Grid item xs={3}>
				<MiniCardNumber
					id={3}
					data={data}
					metric='videoCount'
					title='Número de vídeos'
					loading={false}
					isOverview={false}
					isComp={true}
					cardIds={[1]}
					isWidget={true}
					useColorDictionary={false}
				/>
			</Grid>

			<Grid item xs={3}>
				<MiniCardNumber
					id={4}
					data={data}
					metric='videoProportion'
					title='Proporção da tela dos vídeos'
					loading={false}
					isOverview={false}
					isComp={true}
					cardIds={[1]}
					isWidget={true}
					useColorDictionary={false}
					dataType='percentage'
				/>
			</Grid>

			{data && <HeatMap data={data} />}
		</Grid>
	)
}

export default AuraSightDashboard
