import React, { useEffect, useState } from 'react'
import { Card, Grid, IconButton, Slide } from '@mui/material'
import { CloudUploadIcon, CloudDownloadIcon } from 'utils/SystemIcons'
import DashboardLoadMenu from './components/DashboardLoadMenu/DashboardLoadMenu'
import DashboardSaveMenu from './components/DashboardSaveMenu/DashboardSaveMenu'
import SharingDashboardToolbar from './components/SharingDashboardToolbar/SharingDashboardToolbar'
import {
	HtmlTooltip,
	CustomTitle,
	AddRemoveWidgetButton,
	EditButton,
	DefaultDashboardButton,
	SaveDashboardButton,
	ShareDashboardButton
} from './utils/toolbarDashboardShareableUtils'

const isFirefox = typeof InstallTrigger !== 'undefined'

const SaveTemplateButton = ({ toggleSaveMenu, deactivateEditMode }) => {
	const [isHovering, setIsHovering] = useState(false)

	return (
		<HtmlTooltip title={<CustomTitle title='Salvar dashboard como template' />} placement='left' enterDelay={0} enterNextDelay={0}>
			<IconButton
				id='btn-save-menu-dashboard'
				color='primary'
				onPointerEnter={() => setIsHovering(true)}
				onPointerLeave={() => setIsHovering(false)}
				onClick={() => {
					toggleSaveMenu()
					deactivateEditMode()
				}}
				style={{
					marginTop: '0.75em',
					backgroundColor: isHovering ? 'rgba(29, 138, 248, 0.1)' : 'transparent',
					border: isHovering ? '1px solid rgba(29, 138, 248, 0.1)' : '1px solid rgba(240, 240, 240, 0.5)',
					boxShadow: '0 2px 10px 0 rgba( 200, 200, 200, 0.5 )'
				}}
			>
				<CloudUploadIcon size={18} style={{ color: isHovering ? '#1d8cf8' : '#616161' }} />
			</IconButton>
		</HtmlTooltip>
	)
}

const LoadTemplateButton = ({ toggleLoadMenu, deactivateEditMode }) => {
	const [isHovering, setIsHovering] = useState(false)

	return (
		<HtmlTooltip title={<CustomTitle title='Aplicar templates salvos' />} placement='left' enterDelay={0} enterNextDelay={0}>
			<IconButton
				id='btn-load-menu-dashboard'
				color='primary'
				onPointerEnter={() => setIsHovering(true)}
				onPointerLeave={() => setIsHovering(false)}
				onClick={() => {
					toggleLoadMenu()
					deactivateEditMode()
				}}
				style={{
					marginTop: '0.75em',
					backgroundColor: isHovering ? 'rgba(29, 138, 248, 0.1)' : 'transparent',
					border: isHovering ? '1px solid rgba(29, 138, 248, 0.1)' : '1px solid rgba(240, 240, 240, 0.5)',
					boxShadow: '0 2px 10px 0 rgba( 200, 200, 200, 0.5 )'
				}}
			>
				<CloudDownloadIcon size={18} style={{ color: isHovering ? '#1d8cf8' : '#616161' }} />
			</IconButton>
		</HtmlTooltip>
	)
}

const ToolBarDashboardShareable = ({
	onDefaultLayout,
	onSaveLayout,
	cardList,
	currentLayout,
	onGetSavedLayouts,
	layoutName,
	savedLayoutsList,
	onChangeTemplateName,
	onLayoutApply,
	onDeleteLayout,
	toggleEditMode,
	editMode,
	addWidgetMode,
	toggleAddWidgetMode,
	deactivateEditMode,
	activeWidgets,
	isTerm
}) => {
	const [openSaveMenu, setOpenSaveMenu] = useState(false)
	const [openLoadMenu, setOpenLoadMenu] = useState(false)
	const [openShareMenu, setOpenShareMenu] = useState(false)

	useEffect(() => {
		onGetSavedLayouts()
	}, [openSaveMenu, openLoadMenu])

	const toggleSaveMenu = () => {
		setOpenSaveMenu(!openSaveMenu)
	}

	const toggleLoadMenu = () => {
		setOpenLoadMenu(!openLoadMenu)
	}

	const toggleShareMenu = () => {
		setOpenShareMenu(!openShareMenu)
	}

	return (
		<>
			<Slide direction='left' in={!addWidgetMode}>
				<div style={{ position: 'fixed', zIndex: '1000', top: isTerm ? '14em' : '10.6em', right: '0.35em' }}>
					<Card
						elevation={1}
						style={{
							padding: '0.3em',
							borderRadius: '100px',
							borderBottom: '1px solid rgba(28,110,164,0.08)',
							backdropFilter: 'blur(10px)',
							backgroundColor: `${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.7)'}`,
							border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`
						}}
					>
						<Grid container direction='column' justifyContent='center' alignItems='center'>
							<AddRemoveWidgetButton
								toggleAddWidgetMode={toggleAddWidgetMode}
								deactivateEditMode={deactivateEditMode}
								addWidgetMode={addWidgetMode}
							/>
							<EditButton toggleEditMode={toggleEditMode} editMode={editMode} />
							<DefaultDashboardButton onDefaultLayout={onDefaultLayout} deactivateEditMode={deactivateEditMode} />
							<ShareDashboardButton toggleShareMenu={toggleShareMenu} deactivateEditMode={deactivateEditMode} />
							<SaveDashboardButton
								onSaveLayout={onSaveLayout}
								cardList={cardList}
								currentLayout={currentLayout}
								deactivateEditMode={deactivateEditMode}
							/>
							<SaveTemplateButton toggleSaveMenu={toggleSaveMenu} deactivateEditMode={deactivateEditMode} />
							<LoadTemplateButton toggleLoadMenu={toggleLoadMenu} deactivateEditMode={deactivateEditMode} />
						</Grid>
					</Card>
				</div>
			</Slide>
			<DashboardLoadMenu
				isOpen={openLoadMenu}
				toggleLoadMenu={toggleLoadMenu}
				savedLayoutsList={savedLayoutsList}
				onLayoutApply={onLayoutApply}
				onDeleteLayout={onDeleteLayout}
			/>
			<DashboardSaveMenu
				isOpen={openSaveMenu}
				toggleSaveMenu={toggleSaveMenu}
				layoutName={layoutName}
				onSaveLayout={onSaveLayout}
				onChangeTemplateName={onChangeTemplateName}
			/>
			<SharingDashboardToolbar
				isOpen={openShareMenu}
				toggleShareMenu={toggleShareMenu}
				cards={cardList}
				currentLayout={currentLayout}
				activeWidgets={activeWidgets}
			/>
		</>
	)
}

export default ToolBarDashboardShareable
