import { Button, IconButton, Tooltip } from '@mui/material'
import React from 'react'
import { CloseIcon } from 'utils/SystemIcons'

const FileInput = ({ label, value, onChange, error }) => {
	const handleOnChange = (event) => {
		const file = event.target.files[0]
		onChange({ file })
	}

	const handleRemoveFile = () => {
		onChange(null)
		document.getElementById('single-file-input-vox').value = null
	}

	return (
		<div>
			<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
				<Button
					variant='contained'
					component='label'
					style={{ fontSize: '0.6em', width: 'fit-content', borderRadius: '100px', fontFamily: 'Poppins' }}
				>
					{label}
					<input id='single-file-input-vox' type='file' hidden onChange={handleOnChange} />
				</Button>

				<Tooltip title={value?.file?.name || ''}>
					<div
						style={{
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							maxWidth: '50%',
							color: error ? 'red' : 'inherit',
							marginLeft: '1em'
						}}
					>
						{value?.file?.name || ''}
					</div>
				</Tooltip>
				<div>
					{value?.file?.name && (
						<Tooltip title='Remover arquivo'>
							<IconButton color='primary' style={{ outline: 'none' }} onClick={handleRemoveFile}>
								<CloseIcon color='#1d8cf8' size={13} />
							</IconButton>
						</Tooltip>
					)}
				</div>
			</div>
			<div style={{ fontSize: '0.7em' }}>{error}</div>
		</div>
	)
}

export default FileInput
