import React from 'react'
import Chart from 'react-apexcharts'

const ApexDonut = ({ labels, colors, values, height = '300px' }) => {
	const series = values
	const options = {
		colors: colors,
		chart: {
			type: 'donut'
		},
		labels: labels,
		fill: {
			opacity: 0.9
		},
		stroke: {
			colors: ['transparent']
		},
		tooltip: {
			enabled: true
		},
		legend: {
			labels: {
				colors: ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff']
			}
		},
		dataLabels: {
			enabled: true,
			offsetX: 0,
			textAnchor: 'middle',
			style: {
				fontSize: '13px',
				colors: ['#fff']
			},
			background: {
				enabled: true,
				foreColor: '#fff',
				padding: 4,
				borderRadius: 10,
				borderWidth: 1,
				borderColor: 'transparent',
				opacity: 0.15
			},
			dropShadow: {
				enabled: true,
				top: 1,
				left: 0,
				blur: 1,
				color: '#000',
				opacity: 0.45
			}
		}
	}

	return (
		<div style={{ padding: '1em' }}>
			<Chart options={options} series={series} type='donut' width={'100%'} height={height} />
		</div>
	)
}

export default ApexDonut
