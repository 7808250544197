import React, { useState, useEffect } from 'react'
import MetricsTermDashboardShareable from './MetricsTermDashboardShareable/MetricsTermDashboardShareable'
import constantsVox from 'constants-vox'
import api from 'service/service'
import SkeletonLoadDashboardShareable from 'components/DashboardShareable/components/SkeletonLoadDashboardShareable/SkeletonLoadDashboardShareable'
import { Grid } from '@mui/material'

const DashboardShareableTerm = ({
	currentLayout,
	handleOnLayoutChange,
	editMode,
	addWidgetMode,
	toggleAddWidgetMode,
	widgetsOnScreen,
	handleWidgetsOnScreen,
	handleRemoveWidget,
	cardList,
	globalFilters
}) => {
	const [metrics, setMetrics] = useState(null)
	const [loadingMetrics, setLoadingMetrics] = useState(false)
	const loadMetrics = () => {
		const cardType = constantsVox.CARD_TYPE.TERM
		setLoadingMetrics(true)
		const filters = globalFilters
		const configFilters = {
			cardIds: cardList.map((it) => it.id),
			cardType
		}
		api.post('/dashboard-shareable/get-metrics', {
			filters,
			configFilters
		})
			.then((res) => {
				setMetrics(res.data)
			})
			.finally(() => setLoadingMetrics(false))
	}

	useEffect(() => {
		loadMetrics()
	}, [])

	return (
		<>
			{loadingMetrics ? (
				<Grid item xs={12} style={{ marginLeft: '0.5em', marginRight: '1.6em', marginTop: '1em' }}>
					<SkeletonLoadDashboardShareable />
				</Grid>
			) : (
				<MetricsTermDashboardShareable
					layout={currentLayout?.layouts?.term}
					onLayoutChange={handleOnLayoutChange}
					editMode={editMode}
					addWidgetMode={addWidgetMode}
					toggleAddWidgetMode={toggleAddWidgetMode}
					widgetsOnScreen={widgetsOnScreen}
					handleWidgetsOnScreen={handleWidgetsOnScreen}
					handleRemoveWidget={handleRemoveWidget}
					cards={cardList}
					globalFilters={globalFilters}
					descriptions={metrics}
				/>
			)}
		</>
	)
}

export default DashboardShareableTerm
