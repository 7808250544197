import { CircularProgress, TextField, styled, Button, DialogTitle, DialogContent, Dialog, Grid, Checkbox } from '@mui/material'
import React, { useState } from 'react'
import api from 'service/service'
import ImageUploader from 'react-images-upload'
import imageCompression from 'browser-image-compression'
import blobToBase64 from 'utils/blobToBase64'
import TextAreaAutoSize from 'components/TextAreaAutoSize/TextAreaAutoSize'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import constantsVox from 'constants-vox'
import { verifyServerResponseCanShowToast } from 'utils/generalFunctions'

const FormContainer = styled('form')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	gap: theme.spacing(2),
	maxWidth: 400,
	margin: '0 auto',
	padding: theme.spacing(2),
	backgroundColor: '#f7f7f7',
	borderRadius: theme.spacing(1)
}))

const SubmitButton = styled(Button)(({ theme }) => ({
	backgroundColor: '#1976d2',
	color: '#fff',
	'&:hover': {
		backgroundColor: '#1565c0'
	}
}))

const showManyImages = (images, onRemove) => {
	if (!images) {
		return ''
	}
	return (
		<Grid container spacing={1}>
			{images.map((image, idx) => (
				<Grid item key={image + new Date().getTime()} style={{ position: 'relative' }}>
					<span
						onClick={() => onRemove(idx)}
						style={{
							position: 'absolute',
							top: '-5%',
							right: '-10%',
							backgroundColor: 'white',
							color: 'black',
							border: '2px solid black',
							borderRadius: '50%',
							padding: '1px 4px',
							fontSize: '10px',
							fontWeight: 'bold',
							cursor: 'pointer'
						}}
					>
						X
					</span>
					<img src={`${image}`} alt='logo' style={{ width: '50px', height: '50px' }} />
				</Grid>
			))}
		</Grid>
	)
}

const isValidString = (str) => {
	const regex = /^[a-zA-Z0-9]+$/
	return regex.test(str)
}

const ImageAnnotationCreateLogo = ({ isOpen, handleOnClose, editingLogo }) => {
	const [name, setName] = useState(editingLogo?.name || '')
	const [cleanName, setCleanName] = useState(editingLogo?.cleanName || '')
	const [description, setDescription] = useState(editingLogo?.description || '')
	const [image, setImage] = useState(editingLogo?.imagePath || null)
	const [secondaryImages, setSecondaryImages] = useState(editingLogo?.secondaryImagesPaths || null)
	const [isWhiteBackground, setIsWhiteBackground] = useState(false)
	const [loading, setLoading] = useState(false)
	const canFullEdit = !editingLogo || editingLogo.projectName[0] === 'Logo ainda sem projetos'

	const handleImageChange = async (imageFile, image64) => {
		const compressedFile = await formatImage(imageFile, image64)
		setImage(compressedFile)
	}

	const handleSecondaryImagesChange = async (imageFile, image64) => {
		const compressedFile = await formatImage(imageFile, image64)
		let newArray = []
		if (secondaryImages) {
			newArray = [...secondaryImages]
		}
		newArray.push(compressedFile)
		setSecondaryImages(newArray)
	}

	const formatImage = async (imageFile, image64) => {
		if (image64[0].startsWith('data:image/webp')) {
			const options = {
				maxSizeMB: 1,
				maxWidthOrHeight: 1920,
				useWebWorker: true,
				fileType: 'image/jpeg' // Specify the output format as JPEG
			}
			const compressedFile = await imageCompression(imageFile[0], options)
			return await blobToBase64(compressedFile)
		} else {
			return image64[0]
		}
	}

	const handleSubmit = async (event) => {
		event.preventDefault()
		setLoading(true)
		try {
			const response = await api.post('/image-annotation/create-edit-logo', {
				id: editingLogo?.id,
				name,
				cleanName,
				image,
				description,
				secondaryImages,
				isWhiteBackground,
				isEditingLogo: !!editingLogo
			})
			const canShowToast = verifyServerResponseCanShowToast(response)
			if (canShowToast) {
				sendMessageToast(response.data.toast.message, response.data.toast.type)
			}
		} catch (error) {
			const canShowToast = verifyServerResponseCanShowToast(error)
			if (canShowToast) {
				sendMessageToast(error.response.data.toast.message, error.response.data.toast.type)
			}
		} finally {
			setLoading(false)
			handleOnClose()
		}
	}

	const handleChangeDescription = (event) => {
		setDescription(event.target.value)
	}

	const handleOnRemoveSecondaryImage = (imgIndex) => {
		const newArray = [...secondaryImages]
		newArray.splice(imgIndex, 1)
		setSecondaryImages(newArray)
	}

	const handleChangeName = (event) => {
		if (!isValidString(event.target.value)) {
			sendMessageToast('Nome do logo deve conter apenas letras e números', constantsVox.TOAST.TYPES.WARNING)
			return
		}
		setName(event.target.value)
	}

	const handleChangeCleanName = (event) => {
		if (!isValidString(event.target.value)) {
			sendMessageToast('Nome limpo do logo deve conter apenas letras e números', constantsVox.TOAST.TYPES.WARNING)
			return
		}
		setCleanName(event.target.value)
	}

	return (
		<Dialog open={isOpen} onClose={handleOnClose}>
			<DialogTitle>{editingLogo ? 'Editar Logo' : 'Criar Novo Logo'}</DialogTitle>
			<DialogContent style={{ maxHeight: '500px', width: '500px', padding: 0 }}>
				<FormContainer onSubmit={handleSubmit}>
					<TextField label='Nome do Logo' value={name} onChange={handleChangeName} variant='outlined' required disabled={!canFullEdit} />
					<TextField
						label='Nome Limpo do Logo'
						value={cleanName}
						onChange={handleChangeCleanName}
						variant='outlined'
						required
						disabled={!canFullEdit}
					/>
					<TextAreaAutoSize
						style={{ resize: 'none', width: '100%' }}
						id='create-new-logo-description'
						maxRows={4}
						minRows={4}
						placeholder='Descrição'
						value={description}
						onChange={handleChangeDescription}
						disabled={!canFullEdit}
					/>
					<div style={{ alignItems: 'center', display: 'flex', opacity: '0.8' }}>
						<Checkbox color='primary' checked={isWhiteBackground} onChange={() => setIsWhiteBackground(!isWhiteBackground)} />
						<label>Para imagens PNG com fundo transparente, selecione esta opção para converter o fundo para branco.</label>
					</div>
					<div style={{ display: 'flex', border: '2px solid #d1d1d1' }}>
						<ImageUploader
							buttonClassName='image-button'
							withIcon={false}
							withLabel={true}
							label={image && <img src={`${image}`} alt='logo' style={{ width: '200px', height: '200px' }} />}
							buttonText='Selecionar imagem primária'
							fileContainerStyle={{
								backgroundColor: 'transparent',
								boxShadow: 'none'
							}}
							onChange={(imageFile, image64) => handleImageChange(imageFile, image64)}
							singleImage
							imgExtension={['.png', '.jpg', '.jpeg', '.webp']}
							maxFileSize={5242880}
						/>
					</div>
					<div style={{ display: 'flex', border: '2px solid #d1d1d1' }}>
						<ImageUploader
							buttonClassName='image-button'
							withIcon={false}
							withLabel={true}
							label={showManyImages(secondaryImages, handleOnRemoveSecondaryImage)}
							buttonText='Selecionar imagens secundárias'
							fileContainerStyle={{
								backgroundColor: 'transparent',
								boxShadow: 'none'
							}}
							onChange={(imageFile, image64) => handleSecondaryImagesChange(imageFile, image64)}
							singleImage
							imgExtension={['.png', '.jpg', '.jpeg', '.webp']}
							maxFileSize={5242880}
						/>
					</div>
					<SubmitButton type='submit' variant='contained' disabled={loading}>
						{loading ? <CircularProgress size={24} /> : 'Salvar'}
					</SubmitButton>
				</FormContainer>
			</DialogContent>
		</Dialog>
	)
}

export default ImageAnnotationCreateLogo
