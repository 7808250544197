import React, { useState, useEffect, useRef, memo } from 'react'
import LoadingBar from 'components/LoadingBar/LoadingBar'
import { Grid, Card, Tooltip, Button } from '@mui/material'
import GraphTitle from 'components/GraphTitle/GraphTitle'
import MiniCardNumberComp from './components/MiniCardNumberComp/MiniCardNumberComp'
import AnimatedNumber from 'react-animated-number'
import MiniCardSkeletonOverviewTotal from 'components/SkeletonLoads/MiniCardSkeletonOverviewTotal/MiniCardSkeletonOverviewTotal'
import MiniCardSkeleton from 'components/SkeletonLoads/MiniCardSkeletonLoad/MiniCardSkeletonLoad'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import constantsVox from 'constants-vox'
import CopyToClipboard from 'react-copy-to-clipboard'
import { round } from 'utils/generalFunctions'

const nFormatter = (num, isFullValue, dataType) => {
	if (num === 0 || num === true) {
		if (dataType === 'percentage') {
			return '0%'
		} else if (dataType === 'currency') {
			return 'R$ 0'
		} else if (isFullValue) {
			return 0
		}
	}

	if (dataType === 'percentage') {
		return num.toFixed(2) + '%'
	} else if (dataType === 'currency') {
		return 'R$ ' + num.toFixed(2)
	} else if (isFullValue) {
		return num.toFixed(2)
	}

	const lookup = [
		{ value: 1, symbol: '' },
		{ value: 1e3, symbol: 'k' },
		{ value: 1e6, symbol: 'M' },
		{ value: 1e9, symbol: 'B' },
		{ value: 1e12, symbol: 'T' },
		{ value: 1e15, symbol: 'QUA' },
		{ value: 1e18, symbol: 'QUI' }
	]
	const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
	let item = lookup
		.slice()
		.reverse()
		.find((item) => {
			return num >= item.value
		})
	return item ? (num / item.value).toFixed(item.value === 1 ? 0 : 1).replace(rx, '$1') + item.symbol : '0'
}

const getHeight = (isOverview, isComp, data, isWidget) => {
	if (isWidget) {
		return '100%'
	} else if (isOverview) {
		return '200px'
	} else {
		if (isComp && data?.length > 1) {
			return 'inherit'
		} else {
			return '100%'
		}
	}
}

const MiniCardNumber = ({
	id,
	data,
	metric,
	title,
	loading = false,
	isOverview = false,
	isComp,
	cardIds,
	isWidget = false,
	isFullValue = false,
	dataType,
	useColorDictionary = true
}) => {
	const [hardLoading, setHardLoading] = useState(true)
	const [value, setValue] = useState(true)
	const loadingBarRef = useRef()
	const isMounted = useRef(true)

	useEffect(() => {
		if (data) {
			setValue(data[0]?.selectedInterval?.metrics[metric] || 0)
		}

		return () => {
			isMounted.current = false
		}
	}, [data])

	useEffect(() => {
		isMounted.current = true

		if (isMounted.current) {
			loadingBarRef?.current?.load(loading && !hardLoading)
		}

		if (!loading) {
			setHardLoading(false)
		}

		return () => {
			isMounted.current = false
		}
	}, [loading])

	const onCopy = React.useCallback(() => {
		sendMessageToast(`${title} copiado`, constantsVox.TOAST.TYPES.INFO)
	}, [])

	return (
		<Grid item xs={isOverview ? 4 : 12}>
			<Card
				style={{
					borderRadius: '20px',
					width: '100%',
					height: getHeight(isOverview, isComp, data, isWidget),
					border: isWidget ? '1px solid rgba(154, 154, 154, 0.2)' : 'none'
				}}
			>
				<Grid container spacing={3} style={{ background: '#fbfbfb', height: '70px', borderBottom: '1px solid #f5f5f5' }}>
					<GraphTitle id={id} title={title} titleCentered={true} />
				</Grid>
				<div>
					<LoadingBar ref={loadingBarRef} />
				</div>
				{hardLoading && isOverview && <MiniCardSkeletonOverviewTotal />}
				{hardLoading && !isOverview && <MiniCardSkeleton isWidget={isWidget} />}
				{!hardLoading && data?.length == 1 && (
					<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '77%' }}>
						<CopyToClipboard onCopy={onCopy} text={round(value, 2)}>
							<Tooltip
								title={round(value, 2)
									.toString()
									.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
								placement='right'
								arrow
								enterDelay={200}
								enterNextDelay={200}
							>
								<Button style={{ borderRadius: '25px' }}>
									<AnimatedNumber
										value={value}
										style={{
											whiteSpace: 'nowrap',
											fontFamily: 'Poppins',
											display: 'flex',
											marginTop: '-16px',
											justifyContent: 'center',
											alignItems: 'center',
											fontWeight: 'bold',
											fontSize: '50px',
											letterSpacing: '3px',
											color: '#1d8cf8'
										}}
										duration={1000}
										formatValue={(n) => nFormatter(n, isFullValue, dataType)}
										frameStyle={(percentage) => (percentage > 20 && percentage < 80 ? { opacity: '0.4' } : { opacity: '1' })}
									/>
								</Button>
							</Tooltip>
						</CopyToClipboard>
					</div>
				)}
				{!hardLoading && data?.length > 1 && (
					<MiniCardNumberComp data={data} metric={metric} title={title} cardIds={cardIds} useColorDictionary={useColorDictionary} />
				)}
			</Card>
		</Grid>
	)
}

export default memo(MiniCardNumber)
