import React from 'react'
import Skeleton from 'react-loading-skeleton'

const SkeletonLoadWidgetContainer = () => {
	return (
		<div style={{ marginTop: '-4px', borderRadius: '0px', height: '100%' }}>
			<Skeleton height='100%' style={{ borderRadius: '0px' }} />
		</div>
	)
}

export default SkeletonLoadWidgetContainer
