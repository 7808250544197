import ReactImageAnnotate from 'react-image-annotate'
import React, { useEffect, useRef, useState } from 'react'
import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Slider, styled } from '@mui/material'
import api from 'service/service'
import { CloseIcon } from 'utils/SystemIcons'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { createRoot } from 'react-dom/client'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import constantsVox from 'constants-vox'
import DialogConfirmation from 'components/DialogConfirmation/DialogConfirmation'

const findLabelIdByName = (name, labels) => {
	return labels.find((item) => item.name === name).id
}

const compareImageData = (data1, data2) => {
	if (data1.length !== data2.length) {
		return false
	}
	return data1.every((val, index) => val === data2[index])
}

// maybe at some point this will have to be changed
const findButtonsContainer = () => {
	const regex = /(?:jss\d+\s+jss\d+|div-root-\d+\s+div-root-\d+)/
	const buttonsContainers = [...document.querySelectorAll('[class*="jss"], [class*="div-root-"]')].filter((element) =>
		regex.test(element.className)
	)
	const buttonSelectors = ['Prev', 'Next', 'Settings', 'Fullscreen', 'Save'].map((text) =>
		[...document.querySelectorAll('.MuiButtonBase-root')].find((btn) => btn.innerText.includes(text))
	)
	return buttonsContainers.filter((div) => buttonSelectors.every((btn) => div.contains(btn))).pop()
}

const StyledSlider = styled(Slider)({
	color: '#1A90FF',
	'& .MuiSlider-thumb': {
		backgroundColor: '#1A90FF'
	}
})

const AurasightAnnotationTool = ({ isFirstRendering, isOpen, handleOnClose, updateTasks, taskImages, labels, taskId, taskStatus }) => {
	const [currentImageIndex, setCurrentImageIndex] = useState(0)
	const currentImageIndexRef = useRef(0)
	const [openConfirmation, setOpenConfirmation] = useState(false)

	useEffect(() => {
		// issue where the first render of the component is faulty
		if (isOpen && isFirstRendering) {
			handleOnClose()
		}
		// using MutationObserver to modify react-image-annotate with DOM
		let buttonsContainerObserver = null
		let canvasObserver = null
		let previousData = null
		// add delete button to react-image-annotate when buttons container are found
		if (taskStatus !== 'COMPLETED') {
			const observeButtonsContainer = () => {
				const buttonsContainer = findButtonsContainer()
				if (buttonsContainer) {
					addDeleteButton(buttonsContainer)
					buttonsContainerObserver?.disconnect() // disconnect after processing
				}
			}
			buttonsContainerObserver = new MutationObserver(() => {
				observeButtonsContainer()
			})
			buttonsContainerObserver.observe(document.body, { attributes: true, childList: true, subtree: true })
		}
		// observe changes in the canvas to adjust buttons order and styles at react-image-annotate
		const observeCanvasChanges = () => {
			const targetNode = document.querySelector('canvas[class^="jss"], [class^="makeStyles-canvas-"]') // maybe at some point this will have to be changed
			if (targetNode) {
				const canvas = targetNode
				const context = canvas.getContext('2d', { willReadFrequently: true })
				const currentData = context.getImageData(0, 0, canvas.width, canvas.height).data
				if (previousData && !compareImageData(previousData, currentData)) {
					// finding changes
					previousData = currentData
					const buttonsContainer = findButtonsContainer()
					if (buttonsContainer) {
						adjustButtonsOrderAndStyles(buttonsContainer)
					}
				} else if (!previousData) {
					previousData = currentData
				}
			}
		}
		canvasObserver = new MutationObserver(() => {
			observeCanvasChanges()
		})
		canvasObserver.observe(document.body, { attributes: true, childList: true, subtree: true })
		// "cleanup" everbody
		return () => {
			buttonsContainerObserver?.disconnect()
			canvasObserver?.disconnect()
		}
	}, [taskImages])

	// enable keys to work
	useEffect(() => {
		currentImageIndexRef.current = currentImageIndex // necessary to delete button
		const handleKeyDown = (event) => {
			const isInputFocused = document.activeElement && document.activeElement.tagName.toLowerCase() === 'input'
			if (isInputFocused) {
				// prevent work if is input
				return
			}
			if (event.key === 'd' && currentImageIndex > 0) {
				setCurrentImageIndex((prevIndex) => prevIndex - 1)
			}
			if (event.key === 'f' && currentImageIndex < taskImages.length - 1) {
				setCurrentImageIndex((prevIndex) => prevIndex + 1)
			}
			if (taskStatus !== 'COMPLETED' && event.key === 'Delete') {
				setOpenConfirmation(true)
			}
		}
		window.addEventListener('keydown', handleKeyDown)
		return () => {
			window.removeEventListener('keydown', handleKeyDown)
		}
	}, [currentImageIndex])

	const addDeleteButton = (container) => {
		// create button
		const deleteButton = (
			<Button style={{ textTransform: 'none', marginTop: '0.55em', color: '#616161' }} onClick={() => setOpenConfirmation(true)}>
				<span>
					<div>
						<DeleteForeverIcon fontSize='small' />
					</div>
					<div style={{ fontSize: '11px', fontWeight: 'bold', marginTop: '-0.9em' }}>Delete</div>
				</span>
			</Button>
		)
		// add button to container
		const containerDiv = document.createElement('div')
		const root = createRoot(containerDiv)
		root.render(deleteButton)
		container.appendChild(containerDiv)
	}

	// maybe at some point this will have to be changed
	const adjustButtonsOrderAndStyles = (buttonsContainer) => {
		const buttons = [...buttonsContainer.querySelectorAll('button')]
		const buttonsLength = taskStatus !== 'COMPLETED' ? 7 : 6
		if (buttons.length === buttonsLength && !buttons[0].innerText.includes('Clone')) {
			// clone button goes to first position, so the order with or without him does not change
			const thirdButton = buttons[2]
			buttonsContainer.insertBefore(thirdButton, buttons[0])
			// fixed height to lock marking changes when making annotations
			buttonsContainer.setAttribute('style', `min-height: ${buttonsContainer.offsetHeight}px !important`)
		}
		// change default styles to fix problem that the text alternates between uppercase and lowercase
		const buttonLabels = ['Prev', 'Next', 'Clone', 'Settings', 'Fullscreen', 'Save']
		const buttonsToStyle = [...document.querySelectorAll('.MuiButtonBase-root')].filter((btn) =>
			buttonLabels.some((label) => btn.innerText.includes(label))
		)
		buttonsToStyle.forEach((button) => (button.style.textTransform = 'none'))
	}

	const handleDelete = async () => {
		try {
			await api.post(`/image-annotation/delete-image-from-task/${encodeURIComponent(taskImages[currentImageIndexRef.current].src)}`)
			await updateTasks()
			sendMessageToast('Imagem excluída da tarefa!', constantsVox.TOAST.TYPES.SUCCESS)
		} catch (error) {
			console.error('Erro ao excluir imagem:', error)
			sendMessageToast('Erro ao excluir imagem.', constantsVox.TOAST.TYPES.ERROR)
		} finally {
			setOpenConfirmation(false)
		}
	}

	const handleSave = async (output) => {
		const allRects = output.images.map((image) => {
			if (image.regions) {
				return image.regions.map((region) => {
					const xCenter = (region.x * image.pixelSize.w + (region.w * image.pixelSize.w) / 2) / image.pixelSize.w
					const yCenter = (region.y * image.pixelSize.h + (region.h * image.pixelSize.h) / 2) / image.pixelSize.h
					const width = region.w
					const height = region.h
					// formating to YOLO
					return `${findLabelIdByName(region.cls, labels)} ${xCenter.toFixed(6)} ${yCenter.toFixed(6)} ${width.toFixed(6)} ${height.toFixed(
						6
					)}`
				})
			}
		})
		try {
			await api.post(
				`/image-annotation/save-annotation-to-task-images/${taskId}`,
				taskImages.map((image, index) => ({
					...image,
					regions: output.images[index]?.regions,
					pixelSize: output.images[index]?.pixelSize,
					annotationsFormat: { 'yolo1.1': allRects[index] }
				}))
			)
			await updateTasks()
			sendMessageToast('Anotações salvas com sucesso!', constantsVox.TOAST.TYPES.SUCCESS)
		} catch (error) {
			console.error('Erro ao salvar anotações:', error)
			sendMessageToast('Erro ao salvar anotações.', constantsVox.TOAST.TYPES.ERROR)
		}
	}

	return (
		<Dialog fullWidth maxWidth={'xl'} open={isOpen} onClose={handleOnClose}>
			<DialogTitle
				sx={{
					display: 'flex',
					justifyContent: 'space-between'
				}}
			>
				Aurasight Annotation Tool
				<IconButton onClick={handleOnClose}>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Grid container>
					<Grid item xs={11} paddingLeft={2} paddingRight={2}>
						<StyledSlider
							value={currentImageIndex}
							onChange={(_, newValue) => setCurrentImageIndex(newValue)}
							min={0}
							max={taskImages.length - 1}
						/>
					</Grid>
					<Grid item xs={1} style={{ color: '#1A90FF', fontSize: '25px', marginTop: '-0.15em', fontWeight: 'bold' }}>
						{currentImageIndex + 1}/{taskImages.length}
					</Grid>
				</Grid>
				<div style={{ height: 'calc(100vh - 12.5em)' }}>
					<ReactImageAnnotate
						key={JSON.stringify(taskImages)} // re-render always taskImages change
						images={taskImages}
						selectedImage={taskImages[currentImageIndex].src}
						onNextImage={() => setCurrentImageIndex((prevIndex) => Math.min(prevIndex + 1, taskImages.length - 1))}
						onPrevImage={() => setCurrentImageIndex((prevIndex) => Math.max(prevIndex - 1, 0))}
						onExit={(output) => handleSave(output)}
						enabledTools={taskStatus !== 'COMPLETED' ? ['create-box'] : []}
						regionClsList={labels.map((item) => item.name)}
					/>
				</div>
			</DialogContent>
			{openConfirmation && (
				<DialogConfirmation
					open={openConfirmation}
					handleClose={() => setOpenConfirmation(false)}
					title={'Deseja realmente excluir a imagem da tarefa?'}
					buttonText={'Excluir'}
					handleConfirm={handleDelete}
				/>
			)}
		</Dialog>
	)
}

export default AurasightAnnotationTool
