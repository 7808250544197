import React, { useState, useEffect, useRef, memo } from 'react'
import moment from 'moment'
import { Card, Button, LinearProgress, Grid, Collapse, Tooltip, IconButton } from '@mui/material'
import { FiCheckSquare, FiDownload } from 'react-icons/fi'
import { GoVerified } from 'react-icons/go'
import { AiOutlineRetweet } from 'react-icons/ai'
import { BsChat, BsFillChatQuoteFill } from 'react-icons/bs'
import { withStyles } from '@mui/styles'
import api from 'service/service'
import OccurrenceCardMetrics from './components/OccurrenceCardMetrics/OccurrenceCardMetrics'
import OccurrenceRelevance from './components/OccurrenceRelevance/OccurrenceRelevance'
import OccurrenceProfileDropdown from './components/OccurrenceProfileDropdown/OccurrenceProfileDropdown'
import EmptyImageFeed from 'assets/img/twitterEmpty.jpg'
import BlueskyIconImage from 'assets/img/bluesky-icon.png'
import InstagramIconImage from 'assets/img/instagram-icon.png'
import TiktokIconImage from 'assets/img/tiktok-icon.png'
import MetaIconImage from 'assets/img/meta-icon.png'
import MetaAdsInfo from 'pages/AccountManagement/components/MetaAdsInfo/MetaAdsInfo'
import YoutubeIconImage from 'assets/img/youtube-icon.png'
import NewsIconImage from 'assets/img/news-icon.png'
import {
	FacebookIcon,
	BlueskyIcon,
	InstagramIcon,
	TiktokIcon,
	YoutubeIcon,
	TwitterIcon,
	NewsIcon,
	ExternalLinkIcon,
	TagIcon,
	MetaAdsIcon,
	EditIcon
} from 'utils/SystemIcons'
import constants from 'constants-vox'
import { downloadAsImage } from 'utils/downloadAsImage'
import FacebookIconImage from 'assets/img/facebook-icon.png'
import SelectText from 'components/SelectText/SelectText'
import ViewComments from './components/ViewComments/ViewComments'
import { connect } from 'react-redux'
import PostTags from './components/PostTags/PostTags'
import GraphTitle from 'components/GraphTitle/GraphTitle'
import ViewPostMetrics from './components/ViewPostMetrics/ViewPostMetrics'
import MultipleImageView from './components/MultipleImageView/MultipleImageView'
import PotentialReachRenderer from 'components/PotentialReachWidget/components/PotentialReachRenderer'
import CommentsSentimentGraph from 'pages/AccountManagement/components/CommentsSentiment/components/CommentsSentimentGraph/CommentsSentimentGraph'
import ModalAddOccurrence from 'components/Occurrences/components/FilterBar/components/ModalAddOccurrence/ModalAddOccurrence'
import UserProfileMenuMore from 'components/UserProfileMenuMore/UserProfileMenuMore'
import TwitterIconImage from 'assets/img/twitter-icon.png'
import OccurrenceAuraSight from './components/OccurrenceAuraSight/OccurrenceAuraSight'
import LinkPreview from './components/LinkPreview/LinkPreview'

const blue = 'rgba(29, 138, 248, 100%)'
const grey = 'rgba(184, 184, 184, 100%)'
const purple = 'rgba(172, 96, 247, 100%)'
const red = 'rgba(255, 43, 107, 100%)'
const black = 'rgba(77, 77, 77, 100%)'
const tooltipDelay = 200

const LoadingBlue = withStyles(() => ({
	root: { height: 2 },
	colorPrimary: { backgroundColor: blue },
	bar: { borderRadius: 5, backgroundColor: grey }
}))(LinearProgress)

const LoadingPurple = withStyles(() => ({
	root: { height: 2 },
	colorPrimary: { backgroundColor: purple },
	bar: { borderRadius: 5, backgroundColor: grey }
}))(LinearProgress)

const LoadingRed = withStyles(() => ({
	root: { height: 2 },
	colorPrimary: { backgroundColor: red },
	bar: { borderRadius: 5, backgroundColor: grey }
}))(LinearProgress)

const LoadingBlack = withStyles(() => ({
	root: { height: 2 },
	colorPrimary: { backgroundColor: black },
	bar: { borderRadius: 5, backgroundColor: grey }
}))(LinearProgress)

function extractWordsInSingleQuotes(text) {
	const regex = /'([^']+)'/g
	const matches = []

	let match
	while ((match = regex.exec(text)) !== null) {
		matches.push(match[1])
	}

	return matches
}

const formatTextWithWords = (text, words) => {
	for (const word of words) {
		const regex = new RegExp(`\\b${word}\\b`, 'gi')
		if (text) {
			text = text.replace(regex, `<span style="color: rgba(29, 138, 248, 100%); font-weight: bold;">${word}</span>`)
		}
	}
	return { __html: text }
}

const displayInstagramPostType = (type, mediaProductType) => {
	if (type === 'ACCOUNT_MANAGEMENT') {
		if (mediaProductType === 'FEED') {
			return 'Esse post é um Feed.'
		} else if (mediaProductType === 'STORY') {
			return 'Esse post é um Story.'
		} else if (mediaProductType === 'REELS') {
			return 'Esse post é um Reels.'
		} else if (mediaProductType === 'CAROUSEL_ALBUM') {
			return 'Esse post é um álbum.'
		} else {
			return ''
		}
	} else if (['term', 'profile', 'group'].includes(type)) {
		if (mediaProductType === 'feed') {
			return 'Esse post é um Feed.'
		} else if (mediaProductType === 'story') {
			return 'Esse post é um Story.'
		} else if (mediaProductType === 'reels') {
			return 'Esse post é um Reels.'
		} else if (mediaProductType === 'carousel_album') {
			return 'Esse post é um álbum.'
		} else if (mediaProductType === 'igtv') {
			return 'Esse post é um IGTV.'
		} else {
			return ''
		}
	}
}

const displayFacebookPostType = (type, mediaProductType) => {
	if (type === 'ACCOUNT_MANAGEMENT') {
		if (mediaProductType === 'POST') {
			return 'Esse post é um Feed.'
		} else if (mediaProductType === 'VIDEO') {
			return 'Esse post é um Vídeo.'
		} else if (mediaProductType === 'REEL') {
			return 'Esse post é um Reels.'
		} else {
			return ''
		}
	}
}

const defineSentimentText = (sentiment, isTopPosts = false) => {
	let text = ''
	switch (sentiment) {
		case 'positive':
			text = 'positivo'
			break
		case 'negative':
			text = 'negativo'
			break
		case 'neutral':
			text = 'neutro'
			break
		case 'irrelevant':
			text = 'irrelevante'
			break
		default:
			break
	}

	if (isTopPosts) {
		text = ''
	}

	return text
}

const OccurrenceCard = ({
	id_pesquisa,
	cardData,
	classification,
	onRelevanceSubmitting,
	isReport = false,
	type,
	isComment = false,
	onHideAccount,
	onStopWord,
	globalFiltersTermRedux,
	externalOpenTags = null,
	selectedCard,
	isTopPosts = false,
	indexNumber = null,
	onChangeSelectedPostsIds,
	selectedPostsIds,
	externalMultipleSelection = false,
	isWidget = false,
	userProductsRedux,
	isShareUrl = false
}) => {
	const [url, setUrl] = useState('')
	const [imageUrl, setImageUrl] = useState(null)
	const [cardTitle, setCardTitle] = useState('')
	const [cardColor, setCardColor] = useState(grey)
	const [cardSentimentText, setSentimentText] = useState(defineSentimentText(cardData?.sentiment?.value, isTopPosts))
	const [text, setText] = useState('')
	const [highlight, setHighlight] = useState(null)
	const [highlightUserBio, setHighlightUserBio] = useState(null)
	const [cardVisible, setCardVisible] = useState(true)
	const [submitting, setSubmitting] = useState(false)
	const [openTags, setOpenTags] = useState(false)
	const [openFullText, setOpenFullText] = useState(false)
	const [canOpenFullText, setCanOpenFullText] = useState(false)
	const [isFocused, setIsFocused] = useState(false)
	const [openProfileInfo, setOpenProfileInfo] = useState(false)
	const [isOpenEditOccurrence, setIsOpenEditOccurrence] = useState(null)
	const isMounted = useRef(true)

	const source = cardData.source

	useEffect(() => {
		setOpenTags(externalOpenTags)
	}, [externalOpenTags])

	useEffect(() => {
		isMounted.current = true
		defineCardColor()
		defineAvatarImage()
		defineCardTitle()
		defineExternalLink()
		defineCardText()
		return () => (isMounted.current = false)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openFullText])

	const defineCardColor = () => {
		let color = grey
		switch (cardData?.sentiment?.value) {
			case 'positive':
				color = blue
				break
			case 'negative':
				color = red
				break
			case 'neutral':
				color = purple
				break
			case 'irrelevant':
				color = black
				break
			default:
				break
		}

		if (isTopPosts) {
			color = grey
		}

		setCardColor(color)
	}

	const defineAvatarImage = () => {
		if (['term', 'profile', 'group'].includes(type)) {
			if (isMounted.current) {
				if (source === 'news' && cardData?.content.url) {
					api.get(`/favicon/get-one/${encodeURIComponent(cardData?.content.url)}`).then((res) => {
						setImageUrl(`data:image/png;base64,${res.data}`)
					})
				} else {
					setImageUrl(checkEmptyImage(cardData?.author?.profileImageUrl))
				}
			} else {
				if (imageUrl !== null) {
					setImageUrl(null)
				}
			}
		} else {
			if (source === 'twitter') {
				if (isMounted.current) {
					setImageUrl(checkEmptyImage(cardData?.user?.profile_image_url))
				}
			} else if (source === 'news') {
				api.get(`/favicon/get-one/${cardData?.content.url}`).then((res) => {
					if (isMounted.current) {
						setImageUrl(`data:image/png;base64,${res.data}`)
					}
				})
			} else if (source === 'facebook') {
				if (isMounted.current) {
					setImageUrl(cardData?.page?.profile_image_url || FacebookIconImage)
				}
			} else if (source === 'instagram') {
				if (isMounted.current) {
					setImageUrl(cardData?.author?.profile_image_url || InstagramIconImage)
				}
			} else if (source === 'metaAds') {
				if (isMounted.current) {
					setImageUrl(cardData?.author?.profile_image_url || MetaIconImage)
				}
			} else {
				if (isMounted.current && imageUrl !== null) {
					setImageUrl(null)
				}
			}
		}
	}

	const DefineMetaAdsImageIcon = () => {
		const url = cardData?.post?.url
		if (url) {
			if (url.includes('facebook')) {
				return FacebookIconImage
			} else if (url.includes('instagram')) {
				return InstagramIconImage
			}
		}
		return MetaIconImage
	}

	const defineCardTitle = () => {
		if (['term', 'profile', 'group'].includes(type)) {
			setCardTitle(cardData?.author?.name ?? cardData?.author?.username)
		} else {
			if (source === 'twitter') {
				setCardTitle(cardData?.user?.screen_name || cardData?.user?.name)
			} else if (source === 'bluesky') {
				setCardTitle(cardData?.user?.screen_name || cardData?.user?.name)
			} else if (source === 'news') {
				setCardTitle(cardData?.domain_full ? cardData?.domain_full : cardData?.domain)
			} else if (source === 'facebook') {
				setCardTitle(cardData?.page?.username || cardData?.page?.name)
			} else if (['instagram', 'tiktok', 'youtube'].includes(source)) {
				setCardTitle(cardData?.author?.username || cardData?.author?.name)
			} else if (source === 'metaAds') {
				setCardTitle(cardData?.author?.name)
			}
		}
	}

	const defineIcon = (color) => {
		if (source === 'twitter') {
			return <TwitterIcon color={color} size={16} />
		} else if (source === 'bluesky') {
			return <BlueskyIcon color={color} size={16} />
		} else if (source === 'news') {
			return <NewsIcon color={color} size={16} />
		} else if (source === 'facebook') {
			return <FacebookIcon color={color} size={16} />
		} else if (source === 'instagram') {
			return <InstagramIcon color={color} size={16} />
		} else if (source === 'tiktok') {
			return <TiktokIcon color={color} size={16} />
		} else if (source === 'metaAds') {
			return <MetaAdsIcon color={color} size={16} />
		} else if (source === 'youtube') {
			return <YoutubeIcon color={color} />
		}
	}

	const defineExternalLink = () => {
		if (cardData?.content?.url || cardData?.isUserInput) {
			if (cardData?.content?.url) {
				const contentUrl = cardData?.content?.url
				const urlFormatted = contentUrl.startsWith('http://') || contentUrl.startsWith('https://') ? contentUrl : `https://${contentUrl}`
				setUrl(urlFormatted)
			}
		} else {
			if (['term', 'profile', 'group'].includes(type)) {
				if (source === 'twitter') {
					setUrl(`https://twitter.com/${cardData?.sourceId}/statuses/${cardData?.sourceId}`)
				} else if (source === 'youtube') {
					setUrl(`https://www.youtube.com/watch?v=${cardData?.sourceId}`)
				} else if (source === 'bluesky') {
					setUrl(`https://bsky.app/profile/${cardData?.author?.username}/post/${cardData?.sourceId}`)
				}
			} else {
				if (source === 'twitter') {
					setUrl(`https://twitter.com/${cardData?.status_id}/statuses/${cardData?.status_id}`)
				} else if (source === 'news') {
					setUrl(cardData?.url)
				} else {
					setUrl(cardData?.post?.url)
				}
			}
		}
	}

	const truncateWithElipsis = (str, no_words) => {
		setCanOpenFullText(false)
		if (str && str.length > no_words) {
			setCanOpenFullText(true)
			let cutted = str.slice(0, no_words)
			let cleaned = cutted
			if (cutted.slice(cutted.length - 3) === '...') {
				cleaned = cleaned.slice(0, cleaned.length - 3)
			}
			return cleaned + ` ${cleaned?.length >= no_words ? ' ...' : ''}`
		}

		return str
	}

	const checkEmptyImage = (url) => {
		if (url === '' || !url) {
			return null
		} else {
			return url
		}
	}

	const getSearchWords = () => {
		let words = []
		if (!selectedCard?.cardQuery || selectedCard?.cardQuery.length === 0) {
			let query = selectedCard?.querySql || ''
			words = extractWordsInSingleQuotes(query)
		} else {
			let cardQuery = selectedCard?.cardQuery
			cardQuery.map((query) => {
				query.query.termsAnd.map((word) => {
					words.push(word)
				})
				query.query.termsOr.map((word) => {
					words.push(word)
				})
			})
		}

		return words
	}

	const highlightcontentTextSearch = (text) => {
		let words = getSearchWords()
		const formattedText = formatTextWithWords(text, words)
		return <div dangerouslySetInnerHTML={formattedText} />
	}

	const defineCardText = () => {
		let text = ''
		if (!openFullText) {
			if (['term', 'profile', 'group'].includes(type)) {
				text = truncateWithElipsis(cardData?.content?.text, 300)
			} else {
				if (['instagram', 'facebook', 'tiktok', 'metaAds', 'youtube', 'bluesky'].includes(source)) {
					if (source === 'facebook' && !cardData?.post?.text) {
						text = truncateWithElipsis(cardData?.post?.attachment?.description, 300)
					} else {
						text = truncateWithElipsis(cardData?.post?.text, 300)
					}
				} else {
					text = truncateWithElipsis(cardData?.text, 300)
				}
			}
		} else {
			if (['term', 'profile', 'group'].includes(type)) {
				text = cardData?.content?.text
			} else {
				if (['instagram', 'facebook', 'tiktok', 'metaAds', 'youtube', 'bluesky'].includes(source)) {
					if (source === 'facebook' && !cardData?.post?.text) {
						text = cardData?.post?.attachment?.description
					} else {
						text = cardData?.post?.text
					}
				} else {
					text = cardData?.text
				}
			}
		}
		if (cardData?.highlight) {
			if (['term', 'profile', 'group'].includes(type)) {
				if (cardData?.highlight['content.text']) {
					let htmlText
					htmlText = cardData?.highlight['content.text'][0]
					setHighlight(htmlText)
				}
				if (cardData?.highlight['author.description']) {
					let htmlDescription
					htmlDescription = cardData?.highlight['author.description'][0]
					setHighlightUserBio(htmlDescription)
				}
			} else {
				if (source === 'twitter') {
					if (cardData?.highlight && cardData?.highlight['tweet.text']) {
						let htmlTwitter
						htmlTwitter = cardData?.highlight['tweet.text'][0]
						setHighlight(htmlTwitter)
					}
					if (cardData?.highlight && cardData?.highlight['user.bio']) {
						let htmlBio
						htmlBio = cardData?.highlight['user.bio'][0]
						setHighlightUserBio(htmlBio)
					}
				} else {
					let sourceText
					let html
					if (source === 'news') {
						sourceText = 'news.text'
					} else if (['facebook', 'instagram', 'tiktok', 'metaAds', 'youtube', 'bluesky'].includes(source)) {
						sourceText = 'post.text'
					}

					html = cardData?.highlight[sourceText][0]
					setHighlight(html)
				}
			}
		}
		setText(highlightcontentTextSearch(text))
	}

	const displayCardText = () => {
		if (highlight) {
			return <div dangerouslySetInnerHTML={{ __html: highlight }}></div>
		} else {
			return text
		}
	}
	const onHide = () => {
		// onHideCard(cardData?.id)
	}

	const displayTwitterPostType = (source, is_comment, is_shared, is_quoted, is_post) => {
		let message = `Este ${source === 'twitter' ? 'tweet' : 'post'} é `

		if (is_shared) {
			message += 'um retweet.'
		} else if (is_comment) {
			message += 'uma resposta.'
		} else if (is_quoted) {
			message += 'uma marcação.'
		} else if (is_post) {
			message += 'original'
		} else {
			message = ''
		}

		return message
	}

	const verifyTweetTypeIcon = (is_comment, is_shared, is_quoted, is_post) => {
		if (is_shared) {
			return <AiOutlineRetweet size={20} style={{ color: cardColor }} />
		} else if (is_comment) {
			return <BsChat size={17} style={{ color: cardColor }} />
		} else if (is_quoted) {
			return <BsFillChatQuoteFill size={17} style={{ color: cardColor }} />
		} else if (is_post) {
			return <FiCheckSquare size={20} style={{ color: cardColor }} />
		}
	}

	const onColorChange = (color) => {
		setCardColor(color)
		setSubmitting(true)
	}

	const handleProfileData = (source) => {
		if (['term', 'profile', 'group'].includes(type)) {
			return cardData?.author
		} else {
			switch (source) {
				case 'twitter':
				case 'bluesky':
					return cardData?.user
				case 'facebook':
					return cardData?.page
				case 'instagram':
				case 'tiktok':
				case 'metaAds':
				case 'youtube':
					return cardData?.author
				default:
					return null
			}
		}
	}

	const onSentimetntTextChange = (sentiment) => {
		setSentimentText(defineSentimentText(sentiment, isTopPosts))
	}

	const toggleProfileInfo = () => {
		setOpenProfileInfo(!openProfileInfo)
	}

	const checkInstagramProfileEmpty = (source) => {
		if (
			source === 'instagram' &&
			(!cardData?.author ||
				(cardData?.author &&
					(['term', 'profile', 'group'].includes(type)
						? !cardData?.author?.username
						: !Object.keys(cardData?.author).includes('username'))))
		) {
			return true
		}
		return false
	}

	const displayCardTitle = () => {
		return highlightcontentTextSearch(
			['term', 'profile', 'group'].includes(type) ? cardData?.content?.title : cardData?.post?.attachment?.title || cardData?.title
		)
	}

	const verifyIfDropdownDisabled = () => isReport || type === 'ACCOUNT_MANAGEMENT'

	const getImgSrc = () => {
		switch (source) {
			case 'twitter':
				return imageUrl || TwitterIconImage
			case 'bluesky':
				return imageUrl || BlueskyIconImage
			case 'instagram':
				return imageUrl || InstagramIconImage
			case 'tiktok':
				return imageUrl || TiktokIconImage
			case 'youtube':
				return imageUrl || YoutubeIconImage
			case 'news':
				return imageUrl || NewsIconImage
			case 'metaAds':
				return DefineMetaAdsImageIcon()
			default:
				return imageUrl || EmptyImageFeed
		}
	}

	const checkIfIsVerified = () => {
		if (['term', 'profile', 'group'].includes(type)) {
			return cardData?.author?.isVerified
		} else {
			return (
				(cardData?.user?.verified && source === 'twitter') ||
				(cardData?.user?.verified && source === 'bluesky') ||
				(cardData?.author?.is_verified && ['instagram', 'tiktok', 'youtube'].includes(source))
			)
		}
	}

	const handleToggleEditOccurrence = () => {
		setIsOpenEditOccurrence(!isOpenEditOccurrence)
	}

	const handleDownloadOccurrenceFile = async (file) => {
		const fileUrl = file.url
		setSubmitting(true)
		try {
			const a = document.createElement('a')
			a.href = fileUrl
			a.download = fileUrl.split('/').pop()
			a.target = '_blank'
			document.body.appendChild(a)
			a.click()
			document.body.removeChild(a)
		} finally {
			setSubmitting(false)
		}
	}

	return (
		<Collapse in={cardVisible}>
			<div
				id={cardData.id}
				style={{
					marginBottom: '25px'
				}}
			>
				<Card elevation={0} style={{ borderRadius: '20px', marginLeft: isWidget ? '25px' : '0px', marginRight: isWidget ? '25px' : '0px' }}>
					<div style={{ marginTop: '15px', marginLeft: '30px', marginRight: '30px' }}>
						<h3>
							<Grid container style={{ paddingTop: '16px', paddingLeft: '0px', marginTop: '-20px' }}>
								<Grid item xs={12} style={{ color: cardColor }}>
									<OccurrenceProfileDropdown
										cardId={cardData?.id}
										color={cardColor}
										highlightedBio={highlightUserBio}
										disabled={verifyIfDropdownDisabled()}
										profileData={handleProfileData(source)}
										source={source}
										buttonChildren={
											<img
												style={{ width: '50px', height: '50px', borderRadius: '50px', border: `solid 2px ${cardColor}` }}
												src={getImgSrc()}
												onError={() => setImageUrl(null)}
												alt='...'
											/>
										}
										openProfileInfo={openProfileInfo}
										toggleProfileInfo={toggleProfileInfo}
										isTopPosts={isTopPosts}
										indexNumber={indexNumber}
										type={type}
										onChangeSelectedPostsIds={onChangeSelectedPostsIds}
										selectedPostsIds={selectedPostsIds}
										externalMultipleSelection={externalMultipleSelection}
										checkInstagramProfileEmpty={checkInstagramProfileEmpty}
										urlOriginal={url}
									>
										<div
											style={{
												fontFamily: 'Poppins',
												margin: '10px',
												fontWeight: '600',
												fontSize: '16px',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'space-between',
												width: '100%'
											}}
										>
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'space-between'
												}}
											>
												{cardTitle}
												{checkIfIsVerified() && (
													<Tooltip title='Usuário verificado' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
														<span
															style={{
																cursor: 'pointer',
																marginLeft: '0.3em'
															}}
														>
															<GoVerified size={15} />
														</span>
													</Tooltip>
												)}

												{['term', 'profile', 'group'].includes(type) &&
													(checkInstagramProfileEmpty(source) ? (
														<Grid style={{ marginLeft: '-2em' }}>
															<GraphTitle
																title=''
																description={
																	<>
																		Devido às limitações da API do Instagram, os dados de perfil não estão
																		disponíveis para alguns usuários. Se quiser saber quem é o usuário desta
																		postagem, você pode abrir o post original no Instagram.
																		<br />
																		{url && (
																			<a
																				href={url || ''}
																				target='_blank'
																				rel='noopener noreferrer'
																				style={{ textDecoration: 'none', outlineColor: 'none' }}
																			>
																				<Button
																					id='btn-occurrences-open'
																					size='small'
																					style={{
																						marginTop: '10px',
																						borderRadius: '100px',
																						fontFamily: 'Poppins',
																						fontSize: '14px'
																					}}
																				>
																					<ExternalLinkIcon size={16} />
																					Abrir original
																				</Button>
																			</a>
																		)}
																	</>
																}
															/>
														</Grid>
													) : (
														<>
															{!isTopPosts && type === 'term' && !isShareUrl && (
																<UserProfileMenuMore
																	cardColor={cardColor}
																	source={source}
																	cardTitle={cardData?.author?.username}
																	selectedCard={selectedCard}
																	cardData={cardData}
																	onHideAccount={onHideAccount}
																/>
															)}
															{['term', 'profile', 'group'].includes(type) &&
																!isReport &&
																!isTopPosts &&
																!isShareUrl &&
																cardData.isUserInput && (
																	<Tooltip
																		title='Editar postagem'
																		enterDelay={tooltipDelay}
																		enterNextDelay={tooltipDelay}
																	>
																		<IconButton
																			disabled={submitting}
																			onClick={handleToggleEditOccurrence}
																			style={{ marginLeft: '1em' }}
																		>
																			<EditIcon size={20} style={{ color: cardColor }} />
																		</IconButton>
																	</Tooltip>
																)}
															{['term', 'profile', 'group'].includes(type) &&
																!isReport &&
																!isTopPosts &&
																!isShareUrl &&
																cardData.isUserInput &&
																cardData?.content?.attachments?.find(
																	(it) =>
																		[constants.MEDIA_TYPE.FILE, constants.MEDIA_TYPE.VIDEO].includes(it.type) &&
																		it.url
																) && (
																	<Tooltip
																		title='Baixar arquivo adicionado'
																		enterDelay={tooltipDelay}
																		enterNextDelay={tooltipDelay}
																	>
																		<IconButton
																			onClick={() =>
																				handleDownloadOccurrenceFile(
																					cardData.content.attachments.find(
																						(it) =>
																							[
																								constants.MEDIA_TYPE.FILE,
																								constants.MEDIA_TYPE.IMAGE,
																								constants.MEDIA_TYPE.VIDEO
																							].includes(it.type) && it.url
																					)
																				)
																			}
																		>
																			<FiDownload size={20} style={{ color: cardColor }} />
																		</IconButton>
																	</Tooltip>
																)}
														</>
													))}
											</div>
											<div
												style={{
													marginLeft: '2em',
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'space-between',
													color: cardColor
												}}
											>
												{defineIcon(cardColor)}
												<div style={{ marginLeft: '0.5em', textTransform: 'capitalize' }}>{source}</div>
												{cardData?.sentiment && <div style={{ marginLeft: '2em' }}>{cardSentimentText}</div>}
												{isComment && <div style={{ marginLeft: '2em' }}>Post original</div>}
											</div>
										</div>
									</OccurrenceProfileDropdown>
								</Grid>
							</Grid>
						</h3>
						<div style={{ paddingLeft: '0px', paddingRight: '11px' }}>
							<Grid item xs={12} container direction='row' spacing={2}>
								<Grid
									item
									xs={10}
									style={{
										fontFamily: 'Roboto',
										fontWeight: '100',
										color: '#9A9A9A',
										fontSize: '15px',
										textAlign: 'left'
									}}
								>
									<div style={{ overflowY: 'hidden' }}>
										<div style={{ fontWeight: 'bold', marginBottom: '15px' }}>
											{['term'].includes(type) && !isTopPosts && !isShareUrl ? (
												<SelectText cardId={id_pesquisa} onStopWord={onStopWord}>
													{displayCardTitle()}
												</SelectText>
											) : (
												displayCardTitle()
											)}
										</div>
										{type === 'ACCOUNT_MANAGEMENT' && source === 'metaAds' && (
											<div
												style={{
													color: cardColor,
													fontWeight: 'bold',
													fontSize: '12px',
													marginBottom: '25px',
													marginTop: '-25px'
												}}
											>
												<MetaAdsInfo post={cardData?.post} isCard={false} />
											</div>
										)}
										{['term'].includes(type) && !isTopPosts && !isShareUrl ? (
											<SelectText cardId={id_pesquisa} onStopWord={onStopWord}>
												{displayCardText()}
											</SelectText>
										) : (
											displayCardText()
										)}
									</div>
									{canOpenFullText && globalFiltersTermRedux.contentTextSearch === '' && (
										<Button
											onClick={() => setOpenFullText(!openFullText)}
											fullWidth
											style={{
												fontFamily: 'Poppins',
												fontWeight: 'bold',
												justifyContent: 'center',
												color: '#9A9A9A',
												borderRadius: '25px'
											}}
										>
											{!openFullText && <div style={{ fontSize: '11px', textTransform: 'none' }}>Ver mais</div>}
											{openFullText && <div style={{ fontSize: '11px', textTransform: 'none' }}>Ver menos</div>}
										</Button>
									)}

									{['twitter', 'bluesky'].includes(source) && (
										<>
											<div
												style={{
													marginTop: '1em',
													marginBottom: '0.5em',
													display: 'flex',
													justifyContent: 'flex-start',
													alignItems: 'center'
												}}
											>
												<Tooltip
													placement='bottom-start'
													title={displayTwitterPostType(
														source,
														['term', 'profile', 'group'].includes(type)
															? cardData?.content?.isComment
															: cardData?.is_reply,
														['term', 'profile', 'group'].includes(type)
															? cardData?.content?.isShared
															: cardData?.is_retweet,
														['term', 'profile', 'group'].includes(type)
															? cardData?.content?.isQuoted
															: cardData?.is_quote,
														['term', 'profile', 'group'].includes(type)
															? cardData?.content?.isPost
															: cardData?.is_original
													)}
													enterDelay={tooltipDelay}
													enterNextDelay={tooltipDelay}
												>
													<span style={{ cursor: 'pointer' }}>
														{verifyTweetTypeIcon(
															['term', 'profile', 'group'].includes(type)
																? cardData?.content?.isComment
																: cardData?.is_reply,
															['term', 'profile', 'group'].includes(type)
																? cardData?.content?.isShared
																: cardData?.is_retweet,
															['term', 'profile', 'group'].includes(type)
																? cardData?.content?.isQuoted
																: cardData?.is_quote,
															['term', 'profile', 'group'].includes(type)
																? cardData?.content?.isPost
																: cardData?.is_original
														)}
													</span>
												</Tooltip>
											</div>
										</>
									)}
									{(type === 'ACCOUNT_MANAGEMENT' || ['term', 'profile', 'group'].includes(type)) && source === 'instagram' && (
										<div
											style={{
												color: cardColor,
												fontWeight: 'bold',
												fontSize: '12px',
												marginBottom: '15px',
												marginTop: '15px'
											}}
										>
											{displayInstagramPostType(type, cardData?.content?.type || cardData?.post?.media_product_type)}
										</div>
									)}
									{type === 'ACCOUNT_MANAGEMENT' && source === 'facebook' && (
										<div
											style={{
												color: cardColor,
												fontWeight: 'bold',
												fontSize: '12px',
												marginBottom: '15px',
												marginTop: '15px'
											}}
										>
											{displayFacebookPostType(type, cardData?.content?.type || cardData?.post?.media_product_type)}
										</div>
									)}
									{cardData?.content?.attachments?.length > 0 &&
										cardData.content.attachments.map((attachment, index) => {
											if (attachment.type === 'link') {
												return <LinkPreview key={index} url={attachment.url} />
											}
										})}
								</Grid>
								<MultipleImageView cardData={cardData} source={source} type={type} />
							</Grid>
							<Grid
								item
								style={{
									marginTop: '1em',
									fontFamily: 'Roboto',
									fontWeight: '100',
									color: '#9A9A9A',
									fontSize: '11px',
									textAlign: 'left',
									wordSpacing: '4px'
								}}
							>
								{moment(
									['term', 'profile', 'group'].includes(type)
										? cardData?.content.postedAt
										: cardData?.posted_at || cardData?.post.posted_at,
									constants.JAVASCRIPT.FORMAT_DATETIMEZONE_DEFAULT_SYSTEM
								).format('DD/MM/YYYY HH:mm:ss')}
							</Grid>
							{!!cardData?.potentialReachMetric && (
								<div style={{ marginTop: '20px', marginBottom: '30px' }}>
									<PotentialReachRenderer value={cardData.potentialReachMetric} />
								</div>
							)}
							<Grid container style={{ marginTop: '1em' }}>
								<Grid item xs={7} container>
									<Grid container item xs={12}>
										{!isReport && url && (
											<Grid item xs={4}>
												<a
													href={url || ''}
													target='_blank'
													rel='noopener noreferrer'
													style={{ textDecoration: 'none', outlineColor: 'none' }}
												>
													<Button
														id='btn-occurrences-open'
														size='small'
														style={{
															borderRadius: '100px',
															fontFamily: 'Poppins',
															fontSize: '12px',
															color: cardColor,
															marginLeft: '8px',
															minWidth: '130px'
														}}
													>
														<ExternalLinkIcon size={13} style={{ color: cardColor, marginRight: '5px' }} />
														Abrir original
													</Button>
												</a>
											</Grid>
										)}
										{!isReport && (
											<Grid item xs={4}>
												<Button
													id='btn-occurrences-download'
													size='small'
													onClick={() => downloadAsImage(document.getElementById(cardData.id), 'postagem')}
													style={{
														marginLeft: '8px',
														borderRadius: '100px',
														fontFamily: 'Poppins',
														fontSize: '12px',
														color: cardColor,
														minWidth: '110px'
													}}
												>
													<FiDownload size={13} style={{ color: cardColor, marginRight: '5px' }} />
													Baixar png
												</Button>
											</Grid>
										)}
										{['term'].includes(type) && !isReport && !isTopPosts && (
											<Grid item xs={4}>
												<Button
													id='btn-occurrences-tags'
													size='small'
													onClick={() => {
														setOpenTags(!openTags)
														setIsFocused(!openTags)
													}}
													style={{
														marginLeft: '8px',
														borderRadius: '100px',
														fontFamily: 'Poppins',
														fontSize: '12px',
														color: cardColor
													}}
												>
													<TagIcon size={13} style={{ color: cardColor, marginRight: '5px' }} />
													Tags
												</Button>
											</Grid>
										)}
									</Grid>
									{type === 'ACCOUNT_MANAGEMENT' && !isComment && (
										<Grid container item xs={12} style={{ paddingLeft: '0.25em' }}>
											{source !== 'metaAds' && (
												<Grid item xs={4}>
													<ViewComments
														cardColor={cardColor}
														id_pesquisa={id_pesquisa}
														cardData={cardData}
														source={source}
														classification={classification}
														onRelevanceSubmitting={onRelevanceSubmitting}
														isReport={isReport}
														type={type}
													/>
												</Grid>
											)}
											{!isReport && (
												<Grid item xs={4} style={{ paddingLeft: '0.2em' }}>
													<ViewPostMetrics cardData={cardData} source={source} cardColor={cardColor} />
												</Grid>
											)}
										</Grid>
									)}
								</Grid>
								<Grid item xs={5}>
									<OccurrenceCardMetrics cardData={cardData} cardColor={cardColor} source={source} type={type} />
								</Grid>
							</Grid>
						</div>
					</div>

					{['term'].includes(type) && !isReport && (
						<PostTags
							hasCollapseUp
							style={{ padding: '1em 1em 0em 1.5em' }}
							isOpen={openTags}
							isFocused={isFocused}
							externalOpenTags={externalOpenTags}
							occurrenceId={cardData?.id}
							cardId={id_pesquisa}
							source={source}
							initialTags={cardData?.tags}
							toggleTags={() => setOpenTags(!openTags)}
							cardType={type}
							isShareUrl={isShareUrl}
						/>
					)}

					{type === 'ACCOUNT_MANAGEMENT' &&
						source !== 'metaAds' &&
						(cardData?.post?.sentiments?.good > 0 || cardData?.post?.sentiments?.bad > 0 || cardData?.post?.sentiments?.neutral > 0) && (
							<Grid item xs={12} style={{ backgroundColor: 'transparent', marginRight: '1em', height: '6em', marginBottom: '-2.5em' }}>
								<CommentsSentimentGraph data={cardData?.post?.sentiments} showLegend={false} />
							</Grid>
						)}
					<div style={{ margin: '30px' }}>
						<div data-html2canvas-ignore>
							{!isTopPosts && !isReport && !isShareUrl && type !== 'profile' && type !== 'group' && type !== 'ACCOUNT_MANAGEMENT' && (
								<OccurrenceRelevance
									occurrenceId={cardData?.id}
									id_pesquisa={id_pesquisa}
									// setCardColor={setCardColor}
									setCardVisible={setCardVisible}
									source={source}
									onHideCard={onHide}
									setColorChangeSubmitting={setSubmitting}
									classification={classification}
									onRelevanceSubmitting={onRelevanceSubmitting}
									onColorChange={onColorChange}
									automaticOrManualSentiment={cardData.sentiment}
									cardType={type}
									onSentimetntTextChange={onSentimetntTextChange}
								/>
							)}
						</div>
					</div>
					{userProductsRedux?.find((it) => it === constants.PRODUCT_TYPES.AURASIGHT_MANAGER) && (
						<OccurrenceAuraSight color={cardColor} aurasightData={cardData.aurasight} />
					)}

					{!submitting && (
						<div style={{ height: '100%', width: '100%', backgroundColor: 'transparent' }}>
							<div style={{ height: '2px', backgroundColor: 'transparent' }}></div>
						</div>
					)}
					{submitting && cardColor === blue && <LoadingBlue />}
					{submitting && cardColor === purple && <LoadingPurple />}
					{submitting && cardColor === red && <LoadingRed />}
					{submitting && cardColor === black && <LoadingBlack />}
					{/* <div style={{ padding: '1em' }}>
						<div>id do post:</div>
						<div>{cardData?.post?.id}</div>
						<div>-----------------------</div>
						<div>picture url:</div>
						<div> {cardData?.post?.attachment?.picture_url || 'Esse post não tem foto'}</div>
					</div> */}
				</Card>
			</div>
			{isOpenEditOccurrence && (
				<ModalAddOccurrence
					isOpen={isOpenEditOccurrence}
					handleOnClose={handleToggleEditOccurrence}
					cardId={selectedCard.id}
					cardName={selectedCard.name}
					editOccurrenceId={cardData.sourceId}
					editOccurrenceSource={cardData.source}
					searchType={selectedCard.searchType}
				/>
			)}
		</Collapse>
	)
}

const mapStateToProps = ({ store }) => ({
	globalFiltersTermRedux: store.global.filters,
	userProductsRedux: store?.user?.product
})

export default connect(mapStateToProps)(memo(OccurrenceCard))
