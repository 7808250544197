import React, { useState, useEffect, useRef, memo } from 'react'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import { connect } from 'react-redux'
import { Grid } from '@mui/material'
import SideDrawer from 'components/SideDrawer/SideDrawer'
import { menuDefaultNew } from './utils/functionsSocialProfile'
import { useUpdateData } from 'hooks/ReactQueryHooks'
import { verifyCanShowMenuItemSocialProfile, verifyCardComparisonStatuses } from 'utils/generalFunctions'
import ComparativeBar from 'components/ComparativeBar/ComparativeBar'
import SocialProfileContent from './components/SocialProfileContent/SocialProfileContent'
import OccurrencesContent from '../../../../components/OccurrencesContent/OccurrencesContent'
import ExportContent from '../components/ExportContent/ExportContent'
import GlobalfilterBar from 'components/GlobalFilters/components/GlobalFilterBar/GlobalFilterBar'
import WordClouds from '../components/WordClouds/WordClouds'
import Overview from '../components/Overview/Overview'
import ReportContent from '../components/ReportContent/ReportContent'
import { updateCardComparisonStatusAction } from 'store/redux/actions/card.actions'
import constantsVox from 'constants-vox'

const SocialProfile = ({ cardComparison, history, globalFiltersRedux, location, updateCardComparisonStatusDispatch }) => {
	const [menuState, setMenuState] = useState(null)
	const [tab, setTab] = useState(location.state?.menu || 'analysis')
	const [cards, setCards] = useState(cardComparison)
	let updatedCards = useUpdateData({
		url: `/monitoring/get-by-type-and-ids/profile/${cards?.map((card) => card.id)}`,
		updateItemName: 'Update cards',
		refetchInterval: 5000,
		freshDataTime: 5000
	})
	const isMounted = useRef(true)

	useEffect(() => {
		setCards(cardComparison)
	}, [cardComparison])

	useEffect(() => {
		isMounted.current = true
		return () => {
			isMounted.current = false
		}
	}, [])

	useEffect(() => {
		if (updatedCards?.data?.length) {
			//Atualiza status no redux (filtro global de source)
			const newCardStatus = verifyCardComparisonStatuses(updatedCards?.data, constantsVox.CARD_TYPE.ACCOUNT)
			updateCardComparisonStatusDispatch(newCardStatus)

			//Atualiza status para o menu lateral
			let canShowMenuItem = verifyCanShowMenuItemSocialProfile(updatedCards?.data)
			setMenuState(
				menuDefaultNew(
					canShowMenuItem?.twitter_data_status,
					canShowMenuItem?.bluesky_data_status,
					canShowMenuItem?.facebook_data_status,
					canShowMenuItem?.instagram_data_status,
					canShowMenuItem?.tiktok_data_status,
					canShowMenuItem?.youtube_data_status
				)
			)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updatedCards?.data, globalFiltersRedux])

	if (updatedCards.error && cards?.length > 0) {
		if (updatedCards.error?.response?.data?.toast) {
			sendMessageToast(updatedCards.error?.response?.data?.toast?.message, updatedCards.error?.response?.data?.toast?.type)
		}

		history.push('/monitoring')
	}

	if (cards?.length < 1) {
		history.push('/monitoring')
		return null
	}

	const onSelectItem = (item) => {
		setTab(item)
	}

	return (
		<>
			<SideDrawer itemsToRender={menuState} onSelectItem={onSelectItem} selectedValue={tab} />
			<div className='page-content'>
				<GlobalfilterBar isOverview={tab === 'overview'} disableSourceSelector={tab === 'report'} />
				<ComparativeBar cards={cardComparison} style={{ marginTop: '1.5em', marginBottom: '1.5em' }} />
				<Grid container>
					{tab === 'analysis' && (
						<Grid container item>
							<SocialProfileContent cardList={cards} isFetchingData={true} />
						</Grid>
					)}
					{tab === 'overview' && (
						<Grid container item>
							<Overview cardStatus={cardComparison[0]?.cardStatus} cards={cards} isFetchingData={true} isSocialProfile={true} />
						</Grid>
					)}
					{tab === 'occurrences' && (
						<Grid container item>
							<OccurrencesContent cards={cardComparison} type='profile' />
						</Grid>
					)}
					{tab === 'wordclouds' && (
						<Grid container item>
							<WordClouds cardStatus={cardComparison?.cardStatus} cards={cards} />
						</Grid>
					)}
					{tab === 'export' && (
						<Grid container item>
							<ExportContent cards={cardComparison} type='profile' />
						</Grid>
					)}
					{tab === 'report' && (
						<Grid container item>
							<ReportContent idsToCompare={cards?.map((card) => card.id)} />
						</Grid>
					)}
				</Grid>
			</div>
		</>
	)
}

const mapStateToProps = ({ store }) => ({
	cardComparison: store.card.comparison.cards,
	globalFiltersRedux: store.global.filters
})

const mapDispatchToProps = (dispatch) => ({
	updateCardComparisonStatusDispatch: (newComparisonStatus) => dispatch(updateCardComparisonStatusAction(newComparisonStatus))
})

export default connect(mapStateToProps, mapDispatchToProps)(memo(SocialProfile))
