import React, { useState, useEffect, useRef, memo } from 'react'
import LoadingBar from 'components/LoadingBar/LoadingBar'
import DialogInformation from 'components/DialogInformation/DialogInformation'
import { Grid, Card, Tooltip, Button, IconButton } from '@mui/material'
import { CloseIcon } from 'utils/SystemIcons'
import AnimatedNumber from 'react-animated-number'
import MiniCardSkeletonOverviewTotal from 'components/SkeletonLoads/MiniCardSkeletonOverviewTotal/MiniCardSkeletonOverviewTotal'
import MiniCardSkeleton from 'components/SkeletonLoads/MiniCardSkeletonLoad/MiniCardSkeletonLoad'
import ExpandingGraph from '../ExpandingGraph/ExpandingGraph'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import constantsVox from 'constants-vox'
import CopyToClipboard from 'react-copy-to-clipboard'
import { round } from 'utils/generalFunctions'

const nFormatter = (num, isFullValue, dataType) => {
	if (num === 0 || num === true) {
		if (dataType === 'percentage') {
			return '0%'
		} else if (dataType === 'currency') {
			return 'R$ 0'
		} else if (isFullValue) {
			return 0
		}
	}

	if (dataType === 'percentage') {
		return num.toFixed(2) + '%'
	} else if (dataType === 'currency') {
		return 'R$ ' + num.toFixed(2)
	} else if (isFullValue) {
		return num.toFixed(2)
	}

	const lookup = [
		{ value: 1, symbol: '' },
		{ value: 1e3, symbol: 'k' },
		{ value: 1e6, symbol: 'M' },
		{ value: 1e9, symbol: 'B' },
		{ value: 1e12, symbol: 'T' },
		{ value: 1e15, symbol: 'QUA' },
		{ value: 1e18, symbol: 'QUI' }
	]
	const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
	let item = lookup
		.slice()
		.reverse()
		.find((item) => {
			return num >= item.value
		})
	return item ? (num / item.value).toFixed(item.value === 1 ? 0 : 1).replace(rx, '$1') + item.symbol : '0'
}

const defineValueToCopy = (value, dataType) => {
	if (dataType === 'percentage') {
		return (value * 100).toFixed(2) + '%'
	} else if (dataType === 'currency') {
		return 'R$ ' + value.toFixed(2)
	}

	return round(value, 2)
}

const NumericMetric = ({
	data,
	title,
	keyName = '',
	loading = false,
	isOverview = false,
	graphData = null,
	source = '',
	editMode,
	addWidgetMode,
	removeWidget,
	isFullValue = false,
	description = '',
	dataType
}) => {
	const [hardLoading, setHardLoading] = useState(true)
	const [value, setValue] = useState(0)
	const loadingBarRef = useRef()
	const isMounted = useRef(true)

	useEffect(() => {
		if (data === '') {
			setValue(data)
		} else if (data) {
			setValue(!isNaN(data) ? data : 0)
		}

		return () => {
			isMounted.current = false
		}
	}, [data])

	useEffect(() => {
		isMounted.current = true

		if (isMounted.current) {
			loadingBarRef?.current?.load(loading && !hardLoading)
		}

		if (!loading) {
			setHardLoading(false)
		}

		return () => {
			isMounted.current = false
		}
	}, [loading])

	const onCopy = React.useCallback(() => {
		sendMessageToast(`${title} copiado`, constantsVox.TOAST.TYPES.INFO)
	}, [])

	return (
		<>
			{addWidgetMode && (
				<div style={{ position: 'absolute', right: '1px' }}>
					<IconButton onClick={() => removeWidget(keyName)}>
						<CloseIcon color={'#1d8cf8'} />
					</IconButton>
				</div>
			)}

			<Card
				style={{
					borderRadius: '20px',
					width: '100%',
					height: '100%',
					pointerEvents: editMode ? 'none' : '',
					userSelect: editMode ? 'none' : ''
				}}
				className={editMode ? 'editBox' : ''}
			>
				<div>
					<LoadingBar ref={loadingBarRef} />
				</div>
				{hardLoading && isOverview && <MiniCardSkeletonOverviewTotal />}
				{hardLoading && !isOverview && <MiniCardSkeleton />}
				{!hardLoading && (
					<>
						<Grid container justifyContent='center' alignItems='center' style={{ height: '100%' }}>
							<Grid
								item
								xs={12}
								style={{
									display: 'flex',
									justifyContent: 'center',
									fontFamily: 'Poppins',
									color: '#878787',
									alignItems: 'center',
									marginTop: !graphData ? '-26px' : '0px'
								}}
							>
								{title || ''}
								{description !== '' && <DialogInformation title={title} text={description} />}
							</Grid>
							{value !== '' && (
								<CopyToClipboard onCopy={onCopy} text={defineValueToCopy(value, dataType)}>
									<Tooltip
										title={round(value, 2)
											.toString()
											.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
										placement='right'
										arrow
										enterDelay={200}
										enterNextDelay={200}
									>
										<Button style={{ borderRadius: '25px' }}>
											<AnimatedNumber
												value={value}
												style={{
													whiteSpace: 'nowrap',
													fontFamily: 'Poppins',
													display: 'flex',
													marginTop: !graphData ? '-70px' : '-10px',
													justifyContent: 'center',
													alignItems: 'center',
													fontWeight: 'bold',
													fontSize: '30px',
													letterSpacing: '3px',
													color: '#1d8cf8'
												}}
												duration={1000}
												formatValue={(n) => nFormatter(n, isFullValue, dataType)}
												frameStyle={(percentage) =>
													percentage > 20 && percentage < 80 ? { opacity: '0.4' } : { opacity: '1' }
												}
											/>
										</Button>
									</Tooltip>
								</CopyToClipboard>
							)}
							{graphData && (
								<Grid container item justifyContent='center' alignItems='center'>
									<ExpandingGraph source={source} graphData={graphData} title={title} />
								</Grid>
							)}
						</Grid>
					</>
				)}
			</Card>
		</>
	)
}

export default memo(NumericMetric)
