import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { Grid, Card } from '@mui/material'

const SkeletonLoadDashboardShareable = () => (
	<Grid container spacing={3}>
		<Grid item xs={12}>
			<Card style={{ borderRadius: '20px' }}>
				<Skeleton height='145px' style={{ marginTop: '-20px', lineHeight: 'inherit' }} />
			</Card>
		</Grid>

		<Grid item xs={6} style={{ height: '10em' }}>
			<Card style={{ borderRadius: '20px' }}>
				<Skeleton height='145px' style={{ marginTop: '-20px', lineHeight: 'inherit' }} />
			</Card>
		</Grid>
		<Grid item xs={6} style={{ height: '10em' }}>
			<Card style={{ borderRadius: '20px' }}>
				<Skeleton height='145px' style={{ marginTop: '-20px', lineHeight: 'inherit' }} />
			</Card>
		</Grid>
		<Grid item xs={6}>
			<Card style={{ borderRadius: '20px' }}>
				<Skeleton height='379px' style={{ marginTop: '-20px', lineHeight: 'inherit' }} />
			</Card>
		</Grid>
		<Grid item xs={6}>
			<Card style={{ borderRadius: '20px' }}>
				<Skeleton height='379px' style={{ marginTop: '-20px', lineHeight: 'inherit' }} />
			</Card>
		</Grid>

		<Grid item xs={4} style={{ height: '10em' }}>
			<Card style={{ borderRadius: '20px' }}>
				<Skeleton height='145px' style={{ marginTop: '-20px', lineHeight: 'inherit' }} />
			</Card>
		</Grid>
		<Grid item xs={4} style={{ height: '10em' }}>
			<Card style={{ borderRadius: '20px' }}>
				<Skeleton height='145px' style={{ marginTop: '-20px', lineHeight: 'inherit' }} />
			</Card>
		</Grid>
		<Grid item xs={4} style={{ height: '10em' }}>
			<Card style={{ borderRadius: '20px' }}>
				<Skeleton height='145px' style={{ marginTop: '-20px', lineHeight: 'inherit' }} />
			</Card>
		</Grid>

		<Grid item xs={4}>
			<Card style={{ borderRadius: '20px' }}>
				<Skeleton height='145px' style={{ marginTop: '-20px', lineHeight: 'inherit' }} />
			</Card>
		</Grid>
		<Grid item xs={4}>
			<Card style={{ borderRadius: '20px' }}>
				<Skeleton height='145px' style={{ marginTop: '-20px', lineHeight: 'inherit' }} />
			</Card>
		</Grid>
		<Grid item xs={4}>
			<Card style={{ borderRadius: '20px' }}>
				<Skeleton height='145px' style={{ marginTop: '-20px', lineHeight: 'inherit' }} />
			</Card>
		</Grid>
	</Grid>
)

export default SkeletonLoadDashboardShareable
