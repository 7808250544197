import React from 'react'
import ReportTerm from './components/ReportTerm/ReportTerm'
import ReportAccount from './components/ReportAccount/ReportAccount'

const ReportContent = ({
	type,
	verifiedDatasourcesSummary,
	cardsIdsObject,
	filters,
	viewType,
	graphInterval,
	sentimentsTwitterStandard,
	sentimentsTwitterBalance,
	sentimentsFacebookStandard,
	sentimentsFacebookBalance,
	sentimentsInstagramStandard,
	sentimentsInstagramBalance,
	sentimentsTiktokStandard,
	sentimentsTiktokBalance,
	sentimentsYoutubeStandard,
	sentimentsYoutubeBalance,
	timelineTwitterEngagement,
	timelineFacebookEngagement,
	timelineInstagramEngagement,
	timelineTiktokEngagement,
	timelineYoutubeEngagement,
	timelineNewsEngagement,
	networkNewsAccounts,
	networkFacebookAccounts,
	networkFacebookSentiments,
	networkTwitterAccounts,
	networkTwitterSentiments,
	networkTwitterHashtags,
	networkInstagramAccounts,
	networkInstagramSentiments,
	networkTiktokAccounts,
	networkTiktokSentiments,
	networkYoutubeChannels,
	networkYoutubeSentiments,
	summaryTwitter,
	summaryFacebook,
	summaryInstagram,
	summaryTiktok,
	summaryYoutube,
	summaryNews,
	timelineTwitterPosts,
	timelineFacebookPosts,
	timelineInstagramPosts,
	timelineTiktokPosts,
	timelineYoutubePosts,
	timelineNewsPosts,
	locationTwitterVolume,
	locationTwitterSentiments,
	locationTwitterGender,
	locationFacebookVolume,
	locationFacebookSentiments,
	locationFacebookGender,
	locationInstagramVolume,
	locationInstagramSentiments,
	locationInstagramGender,
	locationTiktokVolume,
	locationTiktokSentiments,
	locationTiktokGender,
	locationYoutubeVolume,
	locationYoutubeSentiments,
	locationYoutubeGender,
	twitter,
	facebook,
	instagram,
	tiktok,
	youtube,
	news
}) => {
	return (
		<>
			{type === 'term' && (
				<ReportTerm
					verifiedDatasourcesSummary={verifiedDatasourcesSummary}
					cardsIdsObject={cardsIdsObject}
					filters={filters}
					viewType={viewType}
					graphInterval={graphInterval}
					sentimentsTwitterStandard={sentimentsTwitterStandard}
					sentimentsTwitterBalance={sentimentsTwitterBalance}
					sentimentsFacebookStandard={sentimentsFacebookStandard}
					sentimentsFacebookBalance={sentimentsFacebookBalance}
					sentimentsInstagramStandard={sentimentsInstagramStandard}
					sentimentsInstagramBalance={sentimentsInstagramBalance}
					sentimentsTiktokStandard={sentimentsTiktokStandard}
					sentimentsTiktokBalance={sentimentsTiktokBalance}
					sentimentsYoutubeStandard={sentimentsYoutubeStandard}
					sentimentsYoutubeBalance={sentimentsYoutubeBalance}
					timelineTwitterEngagement={timelineTwitterEngagement}
					timelineFacebookEngagement={timelineFacebookEngagement}
					timelineInstagramEngagement={timelineInstagramEngagement}
					timelineTiktokEngagement={timelineTiktokEngagement}
					timelineYoutubeEngagement={timelineYoutubeEngagement}
					timelineNewsEngagement={timelineNewsEngagement}
					networkNewsAccounts={networkNewsAccounts}
					networkFacebookAccounts={networkFacebookAccounts}
					networkFacebookSentiments={networkFacebookSentiments}
					networkTwitterAccounts={networkTwitterAccounts}
					networkTwitterSentiments={networkTwitterSentiments}
					networkTwitterHashtags={networkTwitterHashtags}
					networkInstagramAccounts={networkInstagramAccounts}
					networkInstagramSentiments={networkInstagramSentiments}
					networkTiktokAccounts={networkTiktokAccounts}
					networkTiktokSentiments={networkTiktokSentiments}
					networkYoutubeChannels={networkYoutubeChannels}
					networkYoutubeSentiments={networkYoutubeSentiments}
					summaryTwitter={summaryTwitter}
					summaryFacebook={summaryFacebook}
					summaryInstagram={summaryInstagram}
					summaryTiktok={summaryTiktok}
					summaryYoutube={summaryYoutube}
					summaryNews={summaryNews}
					timelineTwitterPosts={timelineTwitterPosts}
					timelineFacebookPosts={timelineFacebookPosts}
					timelineInstagramPosts={timelineInstagramPosts}
					timelineTiktokPosts={timelineTiktokPosts}
					timelineYoutubePosts={timelineYoutubePosts}
					timelineNewsPosts={timelineNewsPosts}
					locationTwitterVolume={locationTwitterVolume}
					locationTwitterSentiments={locationTwitterSentiments}
					locationTwitterGender={locationTwitterGender}
					locationFacebookVolume={locationFacebookVolume}
					locationFacebookSentiments={locationFacebookSentiments}
					locationFacebookGender={locationFacebookGender}
					locationInstagramVolume={locationInstagramVolume}
					locationInstagramSentiments={locationInstagramSentiments}
					locationInstagramGender={locationInstagramGender}
					locationTiktokVolume={locationTiktokVolume}
					locationTiktokSentiments={locationTiktokSentiments}
					locationTiktokGender={locationTiktokGender}
					locationYoutubeVolume={locationYoutubeVolume}
					locationYoutubeSentiments={locationYoutubeSentiments}
					locationYoutubeGender={locationYoutubeGender}
					twitter={twitter}
					facebook={facebook}
					instagram={instagram}
					tiktok={tiktok}
					youtube={youtube}
					news={news}
				/>
			)}
			{type === 'profile' && (
				<ReportAccount
					verifiedDatasourcesSummary={verifiedDatasourcesSummary}
					cardsIdsObject={cardsIdsObject}
					filters={filters}
					viewType={viewType}
					graphInterval={graphInterval}
					timelineTwitterEngagement={timelineTwitterEngagement}
					timelineFacebookEngagement={timelineFacebookEngagement}
					timelineInstagramEngagement={timelineInstagramEngagement}
					timelineTiktokEngagement={timelineTiktokEngagement}
					timelineYoutubeEngagement={timelineYoutubeEngagement}
					summaryTwitter={summaryTwitter}
					summaryFacebook={summaryFacebook}
					summaryInstagram={summaryInstagram}
					summaryTiktok={summaryTiktok}
					summaryYoutube={summaryYoutube}
					timelineTwitterPosts={timelineTwitterPosts}
					timelineFacebookPosts={timelineFacebookPosts}
					timelineInstagramPosts={timelineInstagramPosts}
					timelineTiktokPosts={timelineTiktokPosts}
					timelineYoutubePosts={timelineYoutubePosts}
					twitter={twitter}
					facebook={facebook}
					instagram={instagram}
					tiktok={tiktok}
					youtube={youtube}
				/>
			)}
			{!type && <>Tipo não definido</>}
		</>
	)
}

export default ReportContent
