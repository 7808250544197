import React, { memo } from 'react'
import Chart from 'react-apexcharts'
import { Grid } from '@mui/material'
import moment from 'moment'
import constants from 'constants-vox'
let pt = require('apexcharts/dist/locales/pt.json')

const emptyProp = {
	options: {
		chart: {
			type: 'bar'
		}
	},
	series: [
		{
			name: '',
			data: [0]
		}
	]
}

const formatValue = (value) => {
	if (value !== null) {
		if (Number.isInteger(value)) {
			return `${value}%`
		} else {
			return `${value?.toFixed(2)}%`
		}
	} else {
		return null
	}
}

const sum = (array) => {
	return array.reduce((a, b) => a + b, 0)
}

const formatSeriesToTotal = (series) => {
	let totalValues = 0

	series.forEach((serie) => {
		let values = serie?.data?.map((data) => data[1])
		totalValues += sum(values)
	})

	const formated = series.map((serie) => {
		let values = serie?.data?.map((data) => (data[1] / totalValues) * 100)
		return sum(values)
	})

	const total = [
		{
			data: formated
		}
	]

	return total
}

const getCategories = (data) => {
	if (data?.series) {
		return data.series.map((serie) => serie.name)
	} else {
		return []
	}
}

const NowcastingGraphTotalRelative = ({
	data,
	isReport,
	title,
	colors,
	curve = 'smooth',
	onIntervalChange,
	graphTime,
	isSolid,
	height,
	zoom = true,
	toolbar = true,
	id = 'area',
	isAi = false
}) => {
	let utc = graphTime === 'days' ? true : false
	let tooltipFormat = graphTime === 'hour' ? 'dd MMM HH:mm' : 'dd MMM'
	const getOptions = () => {
		if (data?.options) {
			return {
				colors: colors,
				annotations: {},
				chart: {
					id: id,
					type: 'bar',
					events: {
						zoomed: function (chartContext, { xaxis }) {
							if (onIntervalChange) {
								onIntervalChange(xaxis)
							}
						}
					},
					foreColor: '#525f7f',
					height: 350,
					locales: [pt],
					defaultLocale: 'pt',
					animations: {
						enabled: true
					},
					selection: {
						enabled: true,
						fill: {
							color: '#1d8cf8',
							opacity: 0.1
						},
						stroke: {
							width: 1,
							dashArray: 3,
							color: '#1d8cf8',
							opacity: 0.4
						}
					},
					zoom: {
						enabled: zoom,
						type: 'x'
					},
					toolbar: {
						show: toolbar,
						offsetX: 0,
						offsetY: 0,
						tools: {
							download: !isReport,
							selection: false,
							zoom: !isReport && !isAi,
							zoomin: !isReport && !isAi,
							zoomout: !isReport && !isAi,
							pan: false,
							reset: (!isReport && !isAi) | '<img src="/static/icons/reset.png" width="20">',
							customIcons: []
						},
						autoSelected: 'zoom',
						export: {
							csv: {
								filename: 'voxradar_export',
								columnDelimiter: ',',
								dateFormatter(timestamp) {
									return moment(timestamp).format(constants.JAVASCRIPT.FORMAT_DATETIME_DEFAULT_SYSTEM)
								}
							}
						}
					}
				},
				plotOptions: {
					bar: {
						columnWidth: '70%'
					}
				},
				title: {
					text: `${title}`,
					align: 'left',
					margin: 10,
					offsetX: 0,
					offsetY: 0,
					floating: false,
					style: {
						fontSize: '25px',
						fontWeight: 'regular',
						fontFamily: 'Poppins',
						color: '#525f7f'
					}
				},
				grid: {
					show: true,
					borderColor: 'rgba(82, 95, 127, 0.3)',
					position: 'back',
					xaxis: {
						lines: {
							show: false
						}
					},
					yaxis: {
						lines: {
							show: true
						}
					}
				},
				dataLabels: {
					enabled: true,
					offsetX: 0,
					textAnchor: 'middle',
					style: {
						fontSize: '13px',
						fontFamily: 'Roboto',
						colors: ['#fff']
					},
					background: {
						enabled: true,
						foreColor: '#fff',
						padding: 4,
						borderRadius: 10,
						borderWidth: 1,
						borderColor: 'transparent',
						opacity: 0.15
					},
					dropShadow: {
						enabled: true,
						top: 1,
						left: 0,
						blur: 1,
						color: '#000',
						opacity: 0.45
					},
					formatter: (value) => formatValue(value)
				},
				stroke: {
					colors: colors,
					curve: curve
				},
				fill: {
					colors: colors,
					opacity: 1,
					type: isSolid ? 'solid' : 'gradient',
					gradient: {
						shade: 'dark',
						type: 'vertical',
						shadeIntensity: 0.2,
						gradientToColors: undefined,
						inverseColors: true,
						opacityFrom: 1,
						opacityTo: 0.5,
						stops: [0, 100]
					}
				},
				markers: {
					colors: colors,
					size: 0,
					strokeColors: 'rgba(255, 255, 255, 0)',
					hover: {
						size: 9
					}
				},
				legend: {
					show: false,
					fontSize: isReport ? '15px' : '12px',
					showForSingleSeries: true,
					markers: {
						fillColors: colors
					}
				},
				tooltip: {
					enabled: false,
					shared: false,
					marker: {
						show: true
					},
					x: {
						show: true,
						format: tooltipFormat,
						formatter: undefined
					}
				},
				theme: {
					palette: 'palette1'
				},
				xaxis: {
					// type: 'datetime',
					categories: getCategories(data),
					axisBorder: {
						show: true,
						color: 'rgba(82, 95, 127, 0.3)',
						height: 1,
						width: '100%',
						offsetX: 0,
						offsetY: 0
					},
					labels: {
						style: {
							colors: [],
							fontSize: '12px',
							fontFamily: 'Poppins',
							fontWeight: 400,
							cssClass: 'apexcharts-yaxis-label'
						},
						datetimeUTC: utc
					}
				},
				yaxis: {
					min: 0,
					max: 100,
					labels: {
						show: true,
						align: 'right',
						minWidth: 0,
						maxWidth: 160,
						style: {
							colors: [],
							fontSize: '12px',
							fontFamily: 'Poppins',
							fontWeight: 400,
							cssClass: 'apexcharts-yaxis-label'
						},
						offsetX: -10,
						offsetY: 0,
						rotate: 0,
						formatter: (value) => formatValue(value)
					}
				}
			}
		} else {
			return emptyProp.options
		}
	}

	return (
		<Grid
			style={{
				marginTop: '2.5em',
				marginLeft: '20px',
				marginRight: '20px',
				marginBottom: '5px',
				color: 'black',
				textAlign: 'left',
				fontFamily: 'Poppins',
				height: '100%',
				width: '100%'
			}}
		>
			<Chart
				options={getOptions()}
				series={data?.series ? formatSeriesToTotal(data?.series) : emptyProp.series}
				type={'bar'}
				width={'100%'}
				height={'100%'}
			/>
		</Grid>
	)
}

export default memo(NowcastingGraphTotalRelative)
