import React, { useState, memo } from 'react'
// import moment from 'moment-timezone'
import { Grid, Tooltip, IconButton, Button } from '@mui/material'
import {
	LinkIcon,
	FacebookIcon,
	TwitterIcon,
	BlueskyIcon,
	InstagramIcon,
	TiktokIcon,
	YoutubeIcon,
	NewsIcon,
	InfoIcon,
	MetaAdsIcon
} from 'utils/SystemIcons'
import PauseDownloadButton from './components/PauseDownloadButton/PauseDownloadButton'
import ModalCardInfo from 'components/ModalCardInfo/ModalCardInfo'
import constantsVox from 'constants-vox'

const AnalysisCardFooter = ({ item, color, canEdit, openNetworksDialog = null }) => {
	let cardId = item?.id
	let cardStatus = item?.cardStatus?.general
	let cardType = item?.type
	let isComparative = item?.isComparative
	let showSwitch = cardStatus !== 'P'
	let downloading = item.active
	const permissionRemoveCard = true
	const [isOpenCardInfo, setIsOpenCardInfo] = useState(false)

	const handleLinkNetwork = () => {
		if (openNetworksDialog) {
			openNetworksDialog()
		}
	}

	const handleOpenCardInfo = () => {
		setIsOpenCardInfo(true)
	}

	const handleCloseCardInfo = () => {
		setIsOpenCardInfo(false)
	}

	// const getCreatedAt = (createdAt) => {
	// 	let dates = [moment(createdAt).toDate().getTime(), moment(createdAt).toDate().getTime(), moment(createdAt).toDate().getTime()]

	// 	let lastUpdate = dates
	// 		.filter((date) => date)
	// 		.sort((a, b) => b - a)
	// 		.map((time) => moment(time).format('DD/MM/YYYY HH:mm:ss'))[0]
	// 	return lastUpdate
	// }

	const canShowPauseDownloadButton = () =>
		cardType !== constantsVox.CARD_TYPE.ACCOUNT_MANAGEMENT &&
		showSwitch &&
		permissionRemoveCard &&
		cardType !== 'group' &&
		cardType !== 'report' &&
		!isComparative

	return (
		<Grid container id='card-footer-app' style={{ color: '#9A9A9A' }}>
			<div className='divider-thin-default' />

			<Grid
				item
				container
				direction='row'
				alignItems='center'
				style={{ paddingLeft: '5%', height: '4em', justifyContent: cardType === 'group' ? 'flex-end' : 'space-between' }}
			>
				{canShowPauseDownloadButton() && (
					<Grid item xs={2}>
						<PauseDownloadButton color={color} downloading={downloading} cardType={cardType} cardId={cardId} canPause={canEdit} />
					</Grid>
				)}
				{cardType === constantsVox.CARD_TYPE.ACCOUNT_MANAGEMENT && (
					<Grid item xs={2}>
						<Tooltip
							title={canEdit ? 'Conexões' : 'Sem permissão para editar conexões'}
							placement='bottom-start'
							enterDelay={500}
							enterNextDelay={500}
						>
							<IconButton onClick={() => handleLinkNetwork(cardId)}>
								<LinkIcon size={22} style={{ cursor: 'pointer', color: canEdit ? color : '#9A9A9A' }} />
							</IconButton>
						</Tooltip>
					</Grid>
				)}

				{cardStatus === 'E' && (
					<Grid item xs={8} style={{ fontSize: '0.7em' }}>
						Nenhuma postagem encontrada para este cartão, iremos continuar a monitorá-lo para futuras postagens
					</Grid>
				)}
				{/* {cardStatus !== 'E' && (
					<Grid item xs={8}>
						<div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
							{cardType === 'report_twitter' && (
								<Tooltip title='Fura bolha do Twitter' placement='bottom-start' enterDelay={500} enterNextDelay={500}>
									<Grid style={{ marginRight: '10px' }}>
										<TwitterIcon size={20} style={{ color, marginTop: '5px' }} />
									</Grid>
								</Tooltip>
							)}
							{cardType === 'report_facebook' && (
								<Tooltip title='Fura Bolha do Facebook' placement='bottom-start' enterDelay={500} enterNextDelay={500}>
									<Grid>
										<FacebookIcon size={20} style={{ color, marginRight: '10px' }} />
									</Grid>
								</Tooltip>
							)}
							<Tooltip title='Data de criação' placement='bottom-start' enterDelay={500} enterNextDelay={500}>
								<div style={{ fontSize: '0.8em' }}>{getCreatedAt(createdAt)}</div>
							</Tooltip>
						</div>
					</Grid>
				)} */}

				{!isComparative && (
					<>
						{cardStatus === 'E' || cardType === 'group' ? (
							<Grid item xs={2} container justifyContent='flex-end'>
								<Tooltip title='Informações' placement='left' enterDelay={500} enterNextDelay={500}>
									<IconButton
										style={{ outline: 'none', marginRight: '10px' }}
										onClick={() => {
											handleOpenCardInfo()
										}}
									>
										<InfoIcon size={20} style={{ color, cursor: 'pointer' }} />
									</IconButton>
								</Tooltip>
							</Grid>
						) : (
							<Grid item xs container justifyContent='flex-end'>
								<Tooltip title='Informações' placement='left' enterDelay={500} enterNextDelay={500}>
									<Button
										style={{ outline: 'none', marginRight: '10px', borderRadius: '100px', justifyContent: 'flex-end' }}
										onClick={() => {
											handleOpenCardInfo()
										}}
									>
										{item?.cardStatus?.twitter && (
											<TwitterIcon size={16} style={{ color, cursor: 'pointer', marginLeft: '5px', marginRight: '5px' }} />
										)}
										{item?.cardStatus?.bluesky && (
											<BlueskyIcon size={16} style={{ color, cursor: 'pointer', marginLeft: '5px', marginRight: '5px' }} />
										)}
										{item?.cardStatus?.facebook && (
											<FacebookIcon size={16} style={{ color, cursor: 'pointer', marginLeft: '5px', marginRight: '5px' }} />
										)}
										{item?.cardStatus?.instagram && (
											<InstagramIcon size={16} style={{ color, cursor: 'pointer', marginLeft: '5px', marginRight: '5px' }} />
										)}
										{item?.cardStatus?.tiktok && (
											<TiktokIcon size={16} style={{ color, cursor: 'pointer', marginLeft: '5px', marginRight: '5px' }} />
										)}
										{item?.cardStatus?.youtube && (
											<YoutubeIcon size={16} style={{ color, cursor: 'pointer', marginLeft: '5px', marginRight: '5px' }} />
										)}
										{item?.cardStatus?.news && (
											<NewsIcon size={16} style={{ color, cursor: 'pointer', marginLeft: '5px', marginRight: '5px' }} />
										)}
										{item?.cardStatus?.metaAds && (
											<MetaAdsIcon size={16} style={{ color, cursor: 'pointer', marginLeft: '5px', marginRight: '5px' }} />
										)}
									</Button>
								</Tooltip>
							</Grid>
						)}
					</>
				)}
			</Grid>

			{isOpenCardInfo && <ModalCardInfo isOpen={isOpenCardInfo} handleOnClose={handleCloseCardInfo} card={item} color={color} />}
		</Grid>
	)
}

export default memo(AnalysisCardFooter)
