import constantsVox from 'constants-vox'
import { verifyCardQueryError } from 'pages/Monitoring/components/ButtonModalNewInsight/components/NewMonitoringDialogTerm/components/SocialNetworksNewInsightTerm/components/SourceCheckContent/utils'
import api from 'service/service'

const hasErrorTag = (tag) => {
	if (tag === '') {
		return 'A tag não pode ser vazia'
	}
	if (tag.trim().length === 0) {
		return 'A tag não pode ser composta apenas de espaços'
	}
	if (tag[0] === '_' || tag[tag.length - 1] === '_') {
		return 'A tag não pode começar ou terminar com _'
	}
	if (tag.includes(' _') || tag.includes('_ ')) {
		return 'A tag não pode conter espaços antes ou depois de _'
	}
	if (tag.includes('__')) {
		return 'A tag não pode conter mais que um _ seguido'
	}
	const splitTag = tag.split('_')
	if (splitTag.length > 2) {
		return 'A tag não pode conter mais que um _'
	}
	if (tag[0] === ' ' || tag[tag.length - 1] === ' ') {
		return 'A tag não pode começar ou terminar com espaço vazio'
	}

	if (tag[0] === ' ' || tag[tag.length - 1] === ' ') {
		return 'A tag não pode começar ou terminar com espaço vazio'
	}

	if (tag !== tag.toLowerCase()) {
		return 'Tags não podem conter letra maiúscula'
	}

	const TAGS_REGEX_STRING = /^[a-z0-9\b\0 _]+$/
	if (!TAGS_REGEX_STRING.test(tag)) {
		return 'A tag não pode conter caracteres especiais'
	}

	return null
}

const checkTags = (tags) => {
	if (tags?.value.length > 0) {
		for (let index = 0; index < tags?.value.length; index++) {
			let tag = tags?.value[index]
			let errorTag = hasErrorTag(tag?.tag_key)
			if (tag?.tag_key === '') {
				return 'Todas as tags precisam de um nome.'
			} else if (tag?.query === '') {
				return 'Todas as regras de tagueamento devem ser informadas.'
			} else if (tag?.isValidQuery !== undefined && tag?.isValidQuery === false) {
				return 'Verifique que todas as regras de tagueamento estão preenchidas corretamente.'
			} else if (errorTag) {
				return errorTag
			}
		}
	}

	//verifica se tem tag_key duplicadas
	const uniqueValues = new Set(tags?.value.map((v) => v.tag_key))
	if (uniqueValues.size < tags?.value.length) {
		return 'Valores de tag-subtag duplicados encontrados, favor manter valores únicos.'
	}

	return false
}

export const verifyCanNextStepTerm = async (formState, step, fieldErrors, statusType) => {
	switch (step) {
		case 1:
			return {
				cardName: { error: formState.cardName === '' && 'O nome do cartão deve ser informado.', step },
				category: { error: (formState.category === '' || formState.category === undefined) && 'A categoria deve ser informada.', step },
				country: { error: (formState.country === '' || formState.country === undefined) && 'O país deve ser informado.', step },
				language: { error: (formState.language === '' || formState.language === undefined) && 'O idioma deve ser informado.', step },
				politicalParty: {
					error:
						formState.category?.label === 'POLÍTICA' &&
						formState.country?.label === 'Brasil' &&
						formState.politicalParty === '' &&
						'O partido político deve ser informado.',
					step
				}
			}
		case 2: {
			if (formState.searchType === constantsVox.CARD.SEARCH_TYPE.TERM) {
				try {
					await api.get(`/card-term/monitoring/verify-user-billing-setting-max-cards/${statusType}`)
				} catch (e) {
					return {
						searchType: {
							error: e.response.data.toast.message + ' Se for o caso, tente criar um cartão com tipo de busca em grupos.',
							step
						}
					}
				}
			}

			return { searchType: { error: (!formState.searchType || formState.searchType === '') && 'Tipo de busca deve ser informado', step } }
		}
		case 3:
			return {
				step,
				verifySources: {
					error:
						!(
							formState?.hasTwitterSearch ||
							formState?.hasBlueskySearch ||
							formState?.hasFacebookSearch ||
							formState?.hasInstagramSearch ||
							formState?.hasTiktokSearch ||
							formState?.hasYoutubeSearch ||
							formState?.hasNewsSearch
						) && 'Pelo menos uma fonte deve ser marcada.',
					step
				},
				stopAccounts: {
					//Não tem essas validações no momento, deixei aqui só se no futuro precisar
					twitter: {
						error: false && 'Erro na validação das contas indesejadas'
					},
					bluesky: {
						error: false && 'Erro na validação das contas indesejadas'
					},
					facebook: {
						error: false && 'Erro na validação das contas indesejadas'
					},
					instagram: {
						error: false && 'Erro na validação das contas indesejadas'
					},
					tiktok: {
						error: false && 'Erro na validação das contas indesejadas'
					},
					youtube: {
						error: false && 'Erro na validação das contas indesejadas'
					}
				}
			}
		case 4:
			return {
				cardQuery: {
					error: formState.searchType === constantsVox.CARD.SEARCH_TYPE.TERM && verifyCardQueryError(formState.cardQuery),
					step
				},
				groups: {
					error:
						formState.searchType === constantsVox.CARD.SEARCH_TYPE.PROFILE &&
						formState.groups.length < 1 &&
						'É preciso escolher ao menos um grupo',
					step
				}
			}
		case 5:
			return {
				manualClassificationDictionary: {
					step,
					positive: {
						//Não tem essa validação no momento, deixei aqui só se no futuro precisar
						error: false && 'Palavra adicionada deve ter no mínimo 3 letras.'
					},
					negative: {
						//Não tem essa validação no momento, deixei aqui só se no futuro precisar
						error: false && 'Palavra adicionada deve ter no mínimo 3 letras.'
					}
				},
				tags: {
					step,
					error: fieldErrors?.tags?.error ?? checkTags(formState.tags)
				}
			}

		default:
			return 'Passo inexistente.'
	}
}

export const verifyCanNextStepProfile = (formState, step) => {
	switch (step) {
		case 1:
			return {
				cardName: { error: formState.cardName === '' && 'O nome do cartão deve ser informado.', step },
				category: { error: formState.category === '' && 'A categoria deve ser informada.', step }
			}
		case 2:
			return {
				country: { error: (formState.country === '' || formState.country === undefined) && 'O país deve ser informado.', step },
				politicalParty: {
					error:
						formState.category?.label === 'POLÍTICA' &&
						formState.country?.label === 'Brasil' &&
						formState.politicalParty === '' &&
						'O partido político deve ser informado.',
					step
				}
			}
		case 3:
			return {
				twitterAccount: {
					error:
						formState?.hasTwitterSearch &&
						(formState.twitterAccount === '' || formState.twitterAccount?.split('')[0] !== '@') &&
						'Adicionar no formato @conta',
					step
				},
				blueskyAccount: {
					error:
						formState?.hasBlueskySearch &&
						(formState.blueskyAccount === '' || formState.blueskyAccount?.split('')[0] !== '@') &&
						'Adicionar no formato @conta',
					step
				},
				facebookPage: {
					error:
						formState?.hasFacebookSearch &&
						(formState.facebookPage === '' || formState.facebookPage?.split('')[0] !== '@') &&
						'Adicionar no formato @pagina',
					step
				},
				instagramAccount: {
					error:
						formState?.hasInstagramSearch &&
						(formState.instagramAccount === '' || formState.instagramAccount?.split('')[0] !== '@') &&
						'Adicionar no formato @conta',
					step
				},
				tiktokAccount: {
					error:
						formState?.hasTiktokSearch &&
						(formState.tiktokAccount === '' || formState.tiktokAccount?.split('')[0] !== '@') &&
						'Adicionar no formato @conta',
					step
				},
				youtubeChannel: {
					error:
						formState?.hasYoutubeSearch &&
						(formState.youtubeChannel === '' || formState.youtubeChannel?.split('')[0] !== '@') &&
						'Adicionar no formato @conta',
					step
				},
				verifySources: {
					error:
						!(
							formState?.hasTwitterSearch ||
							formState?.hasBlueskySearch ||
							formState?.hasFacebookSearch ||
							formState?.hasInstagramSearch ||
							formState?.hasTiktokSearch ||
							formState?.hasYoutubeSearch ||
							formState?.hasNewsSearch
						) && 'Pelo menos uma fonte deve ser marcada.',
					step
				}
			}

		default:
			return 'Passo inexistente.'
	}
}

export const verifyCanNextStepGroup = (formState, step) => {
	switch (step) {
		case 1:
			return {
				cardName: { error: formState.cardName === '' && 'O nome do cartão deve ser informado.', step }
			}
		case 2:
			return {
				accounts: { error: formState.accounts.length < 2 && 'O grupo deve conter pelo menos 2 contas', step }
			}

		default:
			return 'Passo inexistente.'
	}
}

export const verifyCanNextStepReportTwitter = (formState, step) => {
	switch (step) {
		case 1:
			return {
				cardName: { error: formState.cardName === '' && 'O nome do cartão deve ser informado.', step }
			}
		case 2:
			return {
				tweetId: { error: formState.tweetId === '' && 'O Relátorio deve conter um tweet válido', step }
			}
		case 3:
			return {
				twitterAccount: {
					error: (formState.twitterAccount === '' || formState.twitterAccount?.split('')[0] !== '@') && 'Adicionar no formato @conta',
					step
				}
			}

		default:
			return 'Passo inexistente.'
	}
}

export const verifyCanNextStepReportFacebook = (formState, step) => {
	switch (step) {
		case 1:
			return {
				cardName: { error: formState.cardName === '' && 'O nome do cartão deve ser informado.', step }
			}
		case 2:
			return {
				postUrl: { error: formState.postUrl === '' && 'O Relátorio deve conter um post válido', step }
			}

		default:
			return 'Passo inexistente.'
	}
}

export default {
	verifyCanNextStepTerm,
	verifyCanNextStepProfile,
	verifyCanNextStepGroup,
	verifyCanNextStepReportTwitter,
	verifyCanNextStepReportFacebook
}
