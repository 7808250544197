import React, { useState, useEffect, memo } from 'react'
import { IconButton, Tooltip } from '@mui/material'
import { PauseIcon, PlayIcon } from 'utils/SystemIcons'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import { verifyServerResponseCanShowToast } from 'utils/generalFunctions'
import api from 'service/service'
import { useQueryClient } from 'hooks/ReactQueryHooks'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import DialogConfirmation from 'components/DialogConfirmation/DialogConfirmation'

const termRouteDownload = '/card-term/monitoring/download-status'
const profileRouteDownload = '/card-profile/monitoring/download-status'

const verifyUserType = (user) => {
	if (user.proceedAsAdminId) {
		return { user: user.proceedAsAdminId }
	} else if (isEmpty(user.subUser)) {
		return { user: user.id_usuario }
	} else {
		return { subUser: user.subUser.id }
	}
}

const PauseDownloadButton = ({ color = '#1d8cf8', downloading, cardType, cardId, canPause, user }) => {
	const [activeColor, setActiveColor] = useState(color)
	const [submitting, setSubmitting] = useState(false)
	const [changeStatus, setChangeStatus] = useState(false)
	const queryClient = useQueryClient()

	useEffect(() => {
		if (downloading && canPause) {
			setActiveColor(color)
		} else {
			setActiveColor('#9A9A9A')
		}
	}, [downloading, cardType])

	const updateDownloadStatus = async (status) => {
		if (canPause) {
			const route = cardType === 'term' ? termRouteDownload : profileRouteDownload
			const userOrSubUserId = verifyUserType(user)
			const log = downloading === true ? 'Pausou o cartão' : 'Ativou o cartão'
			setSubmitting(true)
			api.post(route, { status, cardId, userOrSubUserId, log })
				.then((response) => {
					if (response?.data?.toast) {
						sendMessageToast(response?.data?.toast?.message, response?.data?.toast?.type)
					}
					queryClient.invalidateQueries('onDemoCardChange_useUpdateData')
				})
				.catch((error) => {
					if (verifyServerResponseCanShowToast(error)) {
						sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
					}
				})
				.finally(() => setSubmitting(false))
		}
	}

	const handleClick = () => {
		if (!submitting) {
			updateDownloadStatus(!downloading)
		}
	}

	const toggleChange = () => {
		setChangeStatus(!changeStatus)
	}

	const formatConfirmIcon = () => {
		return downloading === true ? (
			<PauseIcon className='fix-icon-margin-bottom-negative' size={25} color='#ff2b6b' />
		) : (
			<PlayIcon className='fix-icon-margin-bottom-negative' size={25} color='#ff2b6b' />
		)
	}

	return (
		<>
			{downloading && (
				<Tooltip
					title={canPause ? 'Pausar cartão' : 'Sem permissão para pausar cartão'}
					placement='bottom-start'
					enterDelay={500}
					enterNextDelay={500}
				>
					<IconButton onClick={toggleChange} style={{ marginLeft: '-10px' }}>
						<PlayIcon size={25} style={{ color: activeColor, cursor: 'pointer' }} />
					</IconButton>
				</Tooltip>
			)}
			{!downloading && (
				<Tooltip
					title={canPause ? 'Ativar cartão' : 'Sem permissão para ativar cartão'}
					placement='bottom-start'
					enterDelay={500}
					enterNextDelay={500}
				>
					<IconButton onClick={toggleChange} style={{ marginLeft: '-10px' }}>
						<PauseIcon size={25} style={{ color: activeColor, cursor: 'pointer' }} />
					</IconButton>
				</Tooltip>
			)}

			{changeStatus && canPause && (
				<DialogConfirmation
					open={changeStatus}
					handleClose={toggleChange}
					handleConfirm={() => {
						toggleChange()
						handleClick()
					}}
					color='#ff2b6b'
					title={`Deseja realmente ${downloading === true ? 'pausar' : 'ativar'} o cartão?`}
					buttonText={`${downloading === true ? 'PAUSAR' : 'ATIVAR'}`}
					icon={formatConfirmIcon()}
				/>
			)}
		</>
	)
}

const mapStateToProps = ({ store }) => ({
	user: store?.user
})

export default connect(mapStateToProps)(memo(PauseDownloadButton))
