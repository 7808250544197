import React, { useState, useEffect, memo } from 'react'
import Chart from 'react-apexcharts'

const lightBlue = '#1DA1F2'
const darkBlue = '#4267B2'
const pinkRed = '#FE086B'
const turquoise = '#00F1EA'
const lightRed = '#FF0000'
const grayBlue = '#81B2F8'

const barsDataProps = {
	options: {
		chart: {
			type: 'bar',
			toolbar: {
				show: false
			},
			stacked: true,
			stackType: '100%'
		},
		labels: [''],
		plotOptions: {
			plotOptions: {
				bar: {
					horizontal: true
				}
			}
		}
	},
	series: [{ data: [0] }]
}

const formatValue = (val) => {
	if (val !== undefined) {
		return val.toFixed(1)
	}
	return 0
}

const MiniCardAllBySourcePercent = ({ data, metric, isSocialProfile }) => {
	const [barsData, setBarsData] = useState(barsDataProps)

	useEffect(() => {
		if (data) {
			load(metric)
		}
	}, [data, metric])

	const load = (metric) => {
		let categories = []

		let twitter = []
		let bluesky = []
		let facebook = []
		let instagram = []
		let tiktok = []
		let youtube = []
		let news = []

		for (let index = 0; index < data.length; index++) {
			const element = data[index]
			categories.push(element?.name || '')
			twitter.push(formatValue(element?.selectedInterval?.metrics[metric]?.percent_twitter || 0))
			bluesky.push(formatValue(element?.selectedInterval?.metrics[metric]?.percent_bluesky || 0))
			facebook.push(formatValue(element?.selectedInterval?.metrics[metric]?.percent_facebook || 0))
			instagram.push(formatValue(element?.selectedInterval?.metrics[metric]?.percent_instagram || 0))
			tiktok.push(formatValue(element?.selectedInterval?.metrics[metric]?.percent_tiktok || 0))
			youtube.push(formatValue(element?.selectedInterval?.metrics[metric]?.percent_youtube || 0))
			if (!isSocialProfile) {
				news.push(formatValue(element?.selectedInterval?.metrics[metric]?.percent_news || 0))
			}
		}

		let series = [
			{
				name: 'Twitter',
				data: twitter
			},
			{
				name: 'Bluesky',
				data: bluesky
			},
			{
				name: 'Facebook',
				data: facebook
			},
			{
				name: 'Instagram',
				data: instagram
			},
			{
				name: 'Tiktok',
				data: tiktok
			},
			{
				name: 'Youtube',
				data: youtube
			}
		]

		if (!isSocialProfile) {
			series.push({ name: 'Notícias', data: news })
		}

		const optionsBars = {
			colors: [lightBlue, darkBlue, pinkRed, turquoise, lightRed, grayBlue],
			chart: {
				type: 'bar',
				toolbar: {
					show: false
				},
				stacked: true,
				stackType: '100%'
			},
			plotOptions: {
				bar: {
					horizontal: true
				}
			},
			dataLabels: {
				enabled: true,
				offsetX: 0,
				textAnchor: 'middle',
				style: {
					fontSize: '13px',
					fontFamily: 'Roboto',
					colors: ['#fff']
				},
				background: {
					enabled: true,
					foreColor: '#fff',
					padding: 4,
					borderRadius: 10,
					borderWidth: 1,
					borderColor: 'transparent',
					opacity: 0.15
				},
				dropShadow: {
					enabled: true,
					top: 1,
					left: 0,
					blur: 1,
					color: '#000',
					opacity: 0.45
				}
			},
			xaxis: {
				categories,
				labels: {
					show: false
				},
				axisBorder: {
					show: false
				},
				axisTicks: {
					show: false
				}
			},
			yaxis: {
				labels: {
					show: data?.length > 1 ? true : false,
					align: 'right',
					minWidth: 0,
					maxWidth: 160,
					style: {
						colors: [],
						fontSize: '11px',
						fontFamily: 'Poppins',
						fontWeight: 400,
						cssClass: 'apexcharts-yaxis-label'
					}
				}
			},
			stroke: {
				width: 1,
				colors: ['#fff']
			},

			fill: {
				opacity: 1
			},
			grid: {
				show: false
			}
		}

		const apexData = {
			options: optionsBars,
			series
		}

		setBarsData(apexData)
	}

	return (
		<Chart
			style={{
				color: 'black',
				textAlign: 'left',
				fontFamily: 'Poppins',
				height: '171.6px',
				width: '1200px'
			}}
			options={barsData.options}
			series={barsData.series}
			type='bar'
			width={'100%'}
			height='100%'
		/>
	)
}

export default memo(MiniCardAllBySourcePercent)
