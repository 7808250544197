const colorPalette = [
	'rgba(44, 125, 230, 1)',
	'rgba(0, 0, 0, 1)',
	'rgba(230, 81, 44, 1)',
	'rgba(43, 179, 174, 1)',
	'rgba(186, 34, 59, 1)',
	'rgba(254, 176, 25, 1)',
	'rgba(0, 173, 104, 1)',
	'rgba(30, 30, 30, 1)'
]

const defineWordColor = (word) => {
	if (word.includes('Facebook')) {
		return colorPalette[0]
	}
	if (word.includes('X')) {
		return colorPalette[3]
	}
	if (word.includes('Instagram')) {
		return colorPalette[2]
	}
	if (word.includes('TikTok')) {
		return colorPalette[1]
	}
	if (word.includes('Youtube')) {
		return colorPalette[4]
	}
	if (word.includes('redes')) {
		return colorPalette[5]
	}
	if (word.includes('TV')) {
		return colorPalette[6]
	}

	return 'white'
}

export { defineWordColor }
