import React, { useEffect, useState, memo } from 'react'
import { Grid, Card, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { TwitterIcon, FacebookIcon, InstagramIcon, TiktokIcon, YoutubeIcon, NewsIcon } from 'utils/SystemIcons'
import ReportContentSingleOption from '../ReportContentOption/ReportContentSingleOption'
import ReportContentMultiOption from '../ReportContentOption/ReportContentMultiOption'
import ImageUploader from 'react-images-upload'
import 'global.css'

const blue = 'rgba(29, 138, 248, 100%)'
const grey = 'rgba(184, 184, 184, 100%)'

const useStyles = makeStyles(() => ({
	quantityRoot: {
		'& .MuiInputBase-root': {
			color: blue,
			fontFamily: 'Poppins',
			'& input': {
				textAlign: 'left'
			}
		},
		'& label.Mui-focused': {
			color: blue
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: blue
		},
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: grey
			},
			'&:hover fieldset': {
				borderColor: grey
			},
			'&.Mui-focused fieldset': {
				borderColor: blue
			}
		}
	}
}))

const ReportContentChoice = ({ options, onChangeOptions, cardType, isLoad }) => {
	const [thisOptions, setThisOptions] = useState(options)
	const [isFocusedTitle, setIsFocusedTitle] = useState(false)
	const [isFocusedSubTitle, setIsFocusedSubTitle] = useState(false)
	const iconSize = 15
	const classes = useStyles()

	const handleInputChangeTitle = (e) => {
		setThisOptions({ ...thisOptions, title: e.target.value })
	}

	const handleInputChangeSubTitle = (e) => {
		setThisOptions({ ...thisOptions, subTitle: e.target.value })
	}

	const handleFileInput = (imageFile, image64) => {
		setThisOptions({ ...thisOptions, image: image64[0] })
	}

	useEffect(() => {
		if (options !== thisOptions) {
			setThisOptions(options)
		}
	}, [options])

	useEffect(() => {
		if (options !== thisOptions) {
			onChangeOptions(thisOptions)
		}
	}, [thisOptions])

	return (
		<>
			<Grid>
				<Card
					elevation={0}
					style={{
						overflowY: 'auto',
						width: '100%',
						height: '400px',
						borderRadius: '20px',
						backgroundColor: 'rgba(245, 245, 245, 0.65)',
						border: '1px solid rgba(255, 255, 255, 0.7)'
					}}
				>
					<Grid container>
						<Grid item xs={3}>
							<div
								style={{
									fontFamily: 'Poppins',
									textTransform: 'uppercase',
									letterSpacing: '5px',
									fontSize: '20px',
									fontWeight: 'bold',
									display: 'flex',
									cursor: 'default',
									color: '#1d8cf8',
									justifyContent: 'center',
									marginTop: '10px',
									marginRight: '25px'
								}}
							>
								logo
							</div>
							<div style={{ marginRight: '2em' }}>
								{isLoad && (
									<ImageUploader
										withIcon={false}
										withLabel={false}
										buttonText={<img src={thisOptions.image} alt='logo' style={{ width: '75px', height: '75px' }} />}
										buttonClassName='image-button'
										fileContainerStyle={{ backgroundColor: 'transparent', boxShadow: 'none', height: '25px' }}
										onChange={handleFileInput}
										singleImage
										imgExtension={['.jpg', '.png']}
										maxFileSize={5242880}
									/>
								)}
							</div>
						</Grid>
						<Grid item xs={9}>
							<TextField
								variant='standard'
								InputLabelProps={{ shrink: (thisOptions.title !== '' && !!thisOptions.title) || isFocusedTitle }}
								onFocus={() => setIsFocusedTitle(true)}
								onBlur={() => setIsFocusedTitle(false)}
								value={thisOptions.title}
								onChange={handleInputChangeTitle}
								classes={{ root: classes.quantityRoot }}
								style={{ width: '98%', marginTop: '5px' }}
								label={
									<div style={{ display: 'flex' }}>
										<div style={{ marginLeft: '5px', marginTop: '0px', fontFamily: 'Poppins', fontSize: '15px' }}>
											Título relatório
										</div>
									</div>
								}
							/>
							<TextField
								variant='standard'
								InputLabelProps={{ shrink: (thisOptions.subTitle !== '' && !!thisOptions.subTitle) || isFocusedSubTitle }}
								onFocus={() => setIsFocusedSubTitle(true)}
								onBlur={() => setIsFocusedSubTitle(false)}
								value={thisOptions.subTitle}
								onChange={handleInputChangeSubTitle}
								classes={{ root: classes.quantityRoot }}
								style={{ width: '98%', marginTop: '5px' }}
								label={
									<div style={{ display: 'flex' }}>
										<div style={{ marginLeft: '5px', marginTop: '0px', fontFamily: 'Poppins', fontSize: '15px' }}>
											Subtítulo relatório
										</div>
									</div>
								}
							/>
						</Grid>
					</Grid>

					<Grid container>
						<ReportContentMultiOption
							mainChecked={thisOptions.twitter}
							MainTitle={
								<Grid container alignItems='center'>
									<TwitterIcon size={iconSize} style={{ color: '#1d8cf8', marginRight: '5px' }} />
									Twitter
								</Grid>
							}
							option={'twitter'}
							value={!thisOptions.twitter}
							thisOptions={thisOptions}
							setThisOptions={setThisOptions}
							content={
								<Grid container>
									<ReportContentSingleOption
										title={'Resumo Twitter'}
										checked={thisOptions.summaryTwitter}
										option={'summaryTwitter'}
										value={!thisOptions.summaryTwitter}
										thisOptions={thisOptions}
										setThisOptions={setThisOptions}
										isSubOption={true}
									/>
									{cardType === 'term' && (
										<ReportContentMultiOption
											mainChecked={thisOptions.sentimentsTwitter}
											MainTitle={'Sentimento Twitter'}
											option={'sentimentsTwitter'}
											value={!thisOptions.sentimentsTwitter}
											thisOptions={thisOptions}
											setThisOptions={setThisOptions}
											isSubOption={true}
											content={
												<Grid container>
													<ReportContentSingleOption
														title={'Sentimento Twitter Padrão'}
														checked={thisOptions.sentimentsTwitterStandard}
														option={'sentimentsTwitterStandard'}
														value={!thisOptions.sentimentsTwitterStandard}
														thisOptions={thisOptions}
														setThisOptions={setThisOptions}
														isSubOption={true}
													/>
													<ReportContentSingleOption
														title={'Sentimento Twitter Saldo'}
														checked={thisOptions.sentimentsTwitterBalance}
														option={'sentimentsTwitterBalance'}
														value={!thisOptions.sentimentsTwitterBalance}
														thisOptions={thisOptions}
														setThisOptions={setThisOptions}
														isSubOption={true}
													/>
												</Grid>
											}
										/>
									)}
									<ReportContentMultiOption
										mainChecked={thisOptions.timelineTwitter}
										MainTitle={'Linha do Tempo Twitter'}
										option={'timelineTwitter'}
										value={!thisOptions.timelineTwitter}
										thisOptions={thisOptions}
										setThisOptions={setThisOptions}
										isSubOption={true}
										content={
											<Grid container>
												<ReportContentSingleOption
													title={'Linha do Tempo Twitter Engajamento'}
													checked={thisOptions.timelineTwitterEngagement}
													option={'timelineTwitterEngagement'}
													value={!thisOptions.timelineTwitterEngagement}
													thisOptions={thisOptions}
													setThisOptions={setThisOptions}
													isSubOption={true}
												/>
												<ReportContentSingleOption
													title={'Linha do Tempo Twitter Posts'}
													checked={thisOptions.timelineTwitterPosts}
													option={'timelineTwitterPosts'}
													value={!thisOptions.timelineTwitterPosts}
													thisOptions={thisOptions}
													setThisOptions={setThisOptions}
													isSubOption={true}
												/>
											</Grid>
										}
									/>
									{cardType === 'term' && (
										<ReportContentMultiOption
											mainChecked={thisOptions.networkTwitter}
											MainTitle={'Influenciadores Twitter'}
											option={'networkTwitter'}
											value={!thisOptions.networkTwitter}
											thisOptions={thisOptions}
											setThisOptions={setThisOptions}
											isSubOption={true}
											content={
												<Grid container>
													<ReportContentSingleOption
														title={'Influenciadores Twitter Contas'}
														checked={thisOptions.networkTwitterAccounts}
														option={'networkTwitterAccounts'}
														value={!thisOptions.networkTwitterAccounts}
														thisOptions={thisOptions}
														setThisOptions={setThisOptions}
														isSubOption={true}
													/>
													<ReportContentSingleOption
														title={'Influenciadores Twitter Sentimentos'}
														checked={thisOptions.networkTwitterSentiments}
														option={'networkTwitterSentiments'}
														value={!thisOptions.networkTwitterSentiments}
														thisOptions={thisOptions}
														setThisOptions={setThisOptions}
														isSubOption={true}
													/>
													<ReportContentSingleOption
														title={'Influenciadores Twitter Hashtags'}
														checked={thisOptions.networkTwitterHashtags}
														option={'networkTwitterHashtags'}
														value={!thisOptions.networkTwitterHashtags}
														thisOptions={thisOptions}
														setThisOptions={setThisOptions}
														isSubOption={true}
													/>
												</Grid>
											}
										/>
									)}
									{cardType === 'term' && (
										<ReportContentMultiOption
											mainChecked={thisOptions.locationTwitter}
											MainTitle={'Localidade Twitter'}
											option={'locationTwitter'}
											value={!thisOptions.locationTwitter}
											thisOptions={thisOptions}
											setThisOptions={setThisOptions}
											isSubOption={true}
											content={
												<Grid container>
													<ReportContentSingleOption
														title={'Localidade Twitter Volume'}
														checked={thisOptions.locationTwitterVolume}
														option={'locationTwitterVolume'}
														value={!thisOptions.locationTwitterVolume}
														thisOptions={thisOptions}
														setThisOptions={setThisOptions}
														isSubOption={true}
													/>
													<ReportContentSingleOption
														title={'Localidade Twitter Sentimentos'}
														checked={thisOptions.locationTwitterSentiments}
														option={'locationTwitterSentiments'}
														value={!thisOptions.locationTwitterSentiments}
														thisOptions={thisOptions}
														setThisOptions={setThisOptions}
														isSubOption={true}
													/>
													<ReportContentSingleOption
														title={'Localidade Twitter Gênero'}
														checked={thisOptions.locationTwitterGender}
														option={'locationTwitterGender'}
														value={!thisOptions.locationTwitterGender}
														thisOptions={thisOptions}
														setThisOptions={setThisOptions}
														isSubOption={true}
													/>
												</Grid>
											}
										/>
									)}
								</Grid>
							}
						/>

						<ReportContentMultiOption
							mainChecked={thisOptions.facebook}
							MainTitle={
								<Grid container alignItems='center'>
									<FacebookIcon size={iconSize} style={{ color: '#1d8cf8', marginRight: '5px' }} />
									Facebook
								</Grid>
							}
							option={'facebook'}
							value={!thisOptions.facebook}
							thisOptions={thisOptions}
							setThisOptions={setThisOptions}
							content={
								<Grid container>
									<ReportContentSingleOption
										title={'Resumo Facebook'}
										checked={thisOptions.summaryFacebook}
										option={'summaryFacebook'}
										value={!thisOptions.summaryFacebook}
										thisOptions={thisOptions}
										setThisOptions={setThisOptions}
										isSubOption={true}
									/>
									{cardType === 'term' && (
										<ReportContentMultiOption
											mainChecked={thisOptions.sentimentsFacebook}
											MainTitle={'Sentimento Facebook'}
											option={'sentimentsFacebook'}
											value={!thisOptions.sentimentsFacebook}
											thisOptions={thisOptions}
											setThisOptions={setThisOptions}
											isSubOption={true}
											content={
												<Grid container>
													<ReportContentSingleOption
														title={'Sentimento Facebook Padrão'}
														checked={thisOptions.sentimentsFacebookStandard}
														option={'sentimentsFacebookStandard'}
														value={!thisOptions.sentimentsFacebookStandard}
														thisOptions={thisOptions}
														setThisOptions={setThisOptions}
														isSubOption={true}
													/>
													<ReportContentSingleOption
														title={'Sentimento Facebook Saldo'}
														checked={thisOptions.sentimentsFacebookBalance}
														option={'sentimentsFacebookBalance'}
														value={!thisOptions.sentimentsFacebookBalance}
														thisOptions={thisOptions}
														setThisOptions={setThisOptions}
														isSubOption={true}
													/>
												</Grid>
											}
										/>
									)}
									<ReportContentMultiOption
										mainChecked={thisOptions.timelineFacebook}
										MainTitle={'Linha do Tempo Facebook'}
										option={'timelineFacebook'}
										value={!thisOptions.timelineFacebook}
										thisOptions={thisOptions}
										setThisOptions={setThisOptions}
										isSubOption={true}
										content={
											<Grid container>
												<ReportContentSingleOption
													title={'Linha do Tempo Facebook Engajamento'}
													checked={thisOptions.timelineFacebookEngagement}
													option={'timelineFacebookEngagement'}
													value={!thisOptions.timelineFacebookEngagement}
													thisOptions={thisOptions}
													setThisOptions={setThisOptions}
													isSubOption={true}
												/>
												<ReportContentSingleOption
													title={'Linha do Tempo Facebook Posts'}
													checked={thisOptions.timelineFacebookPosts}
													option={'timelineFacebookPosts'}
													value={!thisOptions.timelineFacebookPosts}
													thisOptions={thisOptions}
													setThisOptions={setThisOptions}
													isSubOption={true}
												/>
											</Grid>
										}
									/>
									{cardType === 'term' && (
										<ReportContentMultiOption
											mainChecked={thisOptions.networkFacebook}
											MainTitle={'Influenciadores Facebook'}
											option={'networkFacebook'}
											value={!thisOptions.networkFacebook}
											thisOptions={thisOptions}
											setThisOptions={setThisOptions}
											isSubOption={true}
											content={
												<Grid container>
													<ReportContentSingleOption
														title={'Influenciadores Facebook Contas'}
														checked={thisOptions.networkFacebookAccounts}
														option={'networkFacebookAccounts'}
														value={!thisOptions.networkFacebookAccounts}
														thisOptions={thisOptions}
														setThisOptions={setThisOptions}
														isSubOption={true}
													/>
													<ReportContentSingleOption
														title={'Influenciadores Facebook Sentimentos'}
														checked={thisOptions.networkFacebookSentiments}
														option={'networkFacebookSentiments'}
														value={!thisOptions.networkFacebookSentiments}
														thisOptions={thisOptions}
														setThisOptions={setThisOptions}
														isSubOption={true}
													/>
												</Grid>
											}
										/>
									)}
									{cardType === 'term' && (
										<ReportContentMultiOption
											mainChecked={thisOptions.locationFacebook}
											MainTitle={'Localidade Facebook'}
											option={'locationFacebook'}
											value={!thisOptions.locationFacebook}
											thisOptions={thisOptions}
											setThisOptions={setThisOptions}
											isSubOption={true}
											content={
												<Grid container>
													<ReportContentSingleOption
														title={'Localidade Facebook Volume'}
														checked={thisOptions.locationFacebookVolume}
														option={'locationFacebookVolume'}
														value={!thisOptions.locationFacebookVolume}
														thisOptions={thisOptions}
														setThisOptions={setThisOptions}
														isSubOption={true}
													/>
													<ReportContentSingleOption
														title={'Localidade Facebook Sentimentos'}
														checked={thisOptions.locationFacebookSentiments}
														option={'locationFacebookSentiments'}
														value={!thisOptions.locationFacebookSentiments}
														thisOptions={thisOptions}
														setThisOptions={setThisOptions}
														isSubOption={true}
													/>
													<ReportContentSingleOption
														title={'Localidade Facebook Gênero'}
														checked={thisOptions.locationFacebookGender}
														option={'locationFacebookGender'}
														value={!thisOptions.locationFacebookGender}
														thisOptions={thisOptions}
														setThisOptions={setThisOptions}
														isSubOption={true}
													/>
												</Grid>
											}
										/>
									)}
								</Grid>
							}
						/>

						<ReportContentMultiOption
							mainChecked={thisOptions.instagram}
							MainTitle={
								<Grid container alignItems='center'>
									<InstagramIcon size={iconSize} style={{ color: '#1d8cf8', marginRight: '5px' }} />
									Instagram
								</Grid>
							}
							option={'instagram'}
							value={!thisOptions.instagram}
							thisOptions={thisOptions}
							setThisOptions={setThisOptions}
							content={
								<Grid container>
									<ReportContentSingleOption
										title={'Resumo Instagram'}
										checked={thisOptions.summaryInstagram}
										option={'summaryInstagram'}
										value={!thisOptions.summaryInstagram}
										thisOptions={thisOptions}
										setThisOptions={setThisOptions}
										isSubOption={true}
									/>
									{cardType === 'term' && (
										<ReportContentMultiOption
											mainChecked={thisOptions.sentimentsInstagram}
											MainTitle={'Sentimento Instagram'}
											option={'sentimentsInstagram'}
											value={!thisOptions.sentimentsInstagram}
											thisOptions={thisOptions}
											setThisOptions={setThisOptions}
											isSubOption={true}
											content={
												<Grid container>
													<ReportContentSingleOption
														title={'Sentimento Instagram Padrão'}
														checked={thisOptions.sentimentsInstagramStandard}
														option={'sentimentsInstagramStandard'}
														value={!thisOptions.sentimentsInstagramStandard}
														thisOptions={thisOptions}
														setThisOptions={setThisOptions}
														isSubOption={true}
													/>
													<ReportContentSingleOption
														title={'Sentimento Instagram Saldo'}
														checked={thisOptions.sentimentsInstagramBalance}
														option={'sentimentsInstagramBalance'}
														value={!thisOptions.sentimentsInstagramBalance}
														thisOptions={thisOptions}
														setThisOptions={setThisOptions}
														isSubOption={true}
													/>
												</Grid>
											}
										/>
									)}
									<ReportContentMultiOption
										mainChecked={thisOptions.timelineInstagram}
										MainTitle={'Linha do Tempo Instagram'}
										option={'timelineInstagram'}
										value={!thisOptions.timelineInstagram}
										thisOptions={thisOptions}
										setThisOptions={setThisOptions}
										isSubOption={true}
										content={
											<Grid container>
												<ReportContentSingleOption
													title={'Linha do Tempo Instagram Engajamento'}
													checked={thisOptions.timelineInstagramEngagement}
													option={'timelineInstagramEngagement'}
													value={!thisOptions.timelineInstagramEngagement}
													thisOptions={thisOptions}
													setThisOptions={setThisOptions}
													isSubOption={true}
												/>
												<ReportContentSingleOption
													title={'Linha do Tempo Instagram Posts'}
													checked={thisOptions.timelineInstagramPosts}
													option={'timelineInstagramPosts'}
													value={!thisOptions.timelineInstagramPosts}
													thisOptions={thisOptions}
													setThisOptions={setThisOptions}
													isSubOption={true}
												/>
											</Grid>
										}
									/>
									{cardType === 'term' && (
										<ReportContentMultiOption
											mainChecked={thisOptions.networkInstagram}
											MainTitle={'Influenciadores Instagram'}
											option={'networkInstagram'}
											value={!thisOptions.networkInstagram}
											thisOptions={thisOptions}
											setThisOptions={setThisOptions}
											isSubOption={true}
											content={
												<Grid container>
													<ReportContentSingleOption
														title={'Influenciadores Instagram Contas'}
														checked={thisOptions.networkInstagramAccounts}
														option={'networkInstagramAccounts'}
														value={!thisOptions.networkInstagramAccounts}
														thisOptions={thisOptions}
														setThisOptions={setThisOptions}
														isSubOption={true}
													/>
													<ReportContentSingleOption
														title={'Influenciadores Instagram Sentimentos'}
														checked={thisOptions.networkInstagramSentiments}
														option={'networkInstagramSentiments'}
														value={!thisOptions.networkInstagramSentiments}
														thisOptions={thisOptions}
														setThisOptions={setThisOptions}
														isSubOption={true}
													/>
												</Grid>
											}
										/>
									)}
									{cardType === 'term' && (
										<ReportContentMultiOption
											mainChecked={thisOptions.locationInstagram}
											MainTitle={'Localidade Instagram'}
											option={'locationInstagram'}
											value={!thisOptions.locationInstagram}
											thisOptions={thisOptions}
											setThisOptions={setThisOptions}
											isSubOption={true}
											content={
												<Grid container>
													<ReportContentSingleOption
														title={'Localidade Instagram Volume'}
														checked={thisOptions.locationInstagramVolume}
														option={'locationInstagramVolume'}
														value={!thisOptions.locationInstagramVolume}
														thisOptions={thisOptions}
														setThisOptions={setThisOptions}
														isSubOption={true}
													/>
													<ReportContentSingleOption
														title={'Localidade Instagram Sentimentos'}
														checked={thisOptions.locationInstagramSentiments}
														option={'locationInstagramSentiments'}
														value={!thisOptions.locationInstagramSentiments}
														thisOptions={thisOptions}
														setThisOptions={setThisOptions}
														isSubOption={true}
													/>
													<ReportContentSingleOption
														title={'Localidade Instagram Gênero'}
														checked={thisOptions.locationInstagramGender}
														option={'locationInstagramGender'}
														value={!thisOptions.locationInstagramGender}
														thisOptions={thisOptions}
														setThisOptions={setThisOptions}
														isSubOption={true}
													/>
												</Grid>
											}
										/>
									)}
								</Grid>
							}
						/>

						{cardType === 'term' && (
							<>
								<ReportContentMultiOption
									mainChecked={thisOptions.tiktok}
									MainTitle={
										<Grid container alignItems='center'>
											<TiktokIcon size={iconSize} style={{ color: '#1d8cf8', marginRight: '5px' }} />
											Tiktok
										</Grid>
									}
									option={'tiktok'}
									value={!thisOptions.tiktok}
									thisOptions={thisOptions}
									setThisOptions={setThisOptions}
									content={
										<Grid container>
											<ReportContentSingleOption
												title={'Resumo Tiktok'}
												checked={thisOptions.summaryTiktok}
												option={'summaryTiktok'}
												value={!thisOptions.summaryTiktok}
												thisOptions={thisOptions}
												setThisOptions={setThisOptions}
												isSubOption={true}
											/>
											<ReportContentMultiOption
												mainChecked={thisOptions.sentimentsTiktok}
												MainTitle={'Sentimento Tiktok'}
												option={'sentimentsTiktok'}
												value={!thisOptions.sentimentsTiktok}
												thisOptions={thisOptions}
												setThisOptions={setThisOptions}
												isSubOption={true}
												content={
													<Grid container>
														<ReportContentSingleOption
															title={'Sentimento Tiktok Padrão'}
															checked={thisOptions.sentimentsTiktokStandard}
															option={'sentimentsTiktokStandard'}
															value={!thisOptions.sentimentsTiktokStandard}
															thisOptions={thisOptions}
															setThisOptions={setThisOptions}
															isSubOption={true}
														/>
														<ReportContentSingleOption
															title={'Sentimento Tiktok Saldo'}
															checked={thisOptions.sentimentsTiktokBalance}
															option={'sentimentsTiktokBalance'}
															value={!thisOptions.sentimentsTiktokBalance}
															thisOptions={thisOptions}
															setThisOptions={setThisOptions}
															isSubOption={true}
														/>
													</Grid>
												}
											/>
											<ReportContentMultiOption
												mainChecked={thisOptions.timelineTiktok}
												MainTitle={'Linha do Tempo Tiktok'}
												option={'timelineTiktok'}
												value={!thisOptions.timelineTiktok}
												thisOptions={thisOptions}
												setThisOptions={setThisOptions}
												isSubOption={true}
												content={
													<Grid container>
														<ReportContentSingleOption
															title={'Linha do Tempo Tiktok Engajamento'}
															checked={thisOptions.timelineTiktokEngagement}
															option={'timelineTiktokEngagement'}
															value={!thisOptions.timelineTiktokEngagement}
															thisOptions={thisOptions}
															setThisOptions={setThisOptions}
															isSubOption={true}
														/>
														<ReportContentSingleOption
															title={'Linha do Tempo Tiktok Posts'}
															checked={thisOptions.timelineTiktokPosts}
															option={'timelineTiktokPosts'}
															value={!thisOptions.timelineTiktokPosts}
															thisOptions={thisOptions}
															setThisOptions={setThisOptions}
															isSubOption={true}
														/>
													</Grid>
												}
											/>
											<ReportContentMultiOption
												mainChecked={thisOptions.networkTiktok}
												MainTitle={'Influenciadores Tiktok'}
												option={'networkTiktok'}
												value={!thisOptions.networkTiktok}
												thisOptions={thisOptions}
												setThisOptions={setThisOptions}
												isSubOption={true}
												content={
													<Grid container>
														<ReportContentSingleOption
															title={'Influenciadores Tiktok Contas'}
															checked={thisOptions.networkTiktokAccounts}
															option={'networkTiktokAccounts'}
															value={!thisOptions.networkTiktokAccounts}
															thisOptions={thisOptions}
															setThisOptions={setThisOptions}
															isSubOption={true}
														/>
														<ReportContentSingleOption
															title={'Influenciadores Tiktok Sentimentos'}
															checked={thisOptions.networkTiktokSentiments}
															option={'networkTiktokSentiments'}
															value={!thisOptions.networkTiktokSentiments}
															thisOptions={thisOptions}
															setThisOptions={setThisOptions}
															isSubOption={true}
														/>
													</Grid>
												}
											/>

											<ReportContentMultiOption
												mainChecked={thisOptions.locationTiktok}
												MainTitle={'Localidade Tiktok'}
												option={'locationTiktok'}
												value={!thisOptions.locationTiktok}
												thisOptions={thisOptions}
												setThisOptions={setThisOptions}
												isSubOption={true}
												content={
													<Grid container>
														<ReportContentSingleOption
															title={'Localidade Tiktok Volume'}
															checked={thisOptions.locationTiktokVolume}
															option={'locationTiktokVolume'}
															value={!thisOptions.locationTiktokVolume}
															thisOptions={thisOptions}
															setThisOptions={setThisOptions}
															isSubOption={true}
														/>
														<ReportContentSingleOption
															title={'Localidade Tiktok Sentimentos'}
															checked={thisOptions.locationTiktokSentiments}
															option={'locationTiktokSentiments'}
															value={!thisOptions.locationTiktokSentiments}
															thisOptions={thisOptions}
															setThisOptions={setThisOptions}
															isSubOption={true}
														/>
														<ReportContentSingleOption
															title={'Localidade Tiktok Gênero'}
															checked={thisOptions.locationTiktokGender}
															option={'locationTiktokGender'}
															value={!thisOptions.locationTiktokGender}
															thisOptions={thisOptions}
															setThisOptions={setThisOptions}
															isSubOption={true}
														/>
													</Grid>
												}
											/>
										</Grid>
									}
								/>
							</>
						)}

						{cardType === 'term' && (
							<>
								<ReportContentMultiOption
									mainChecked={thisOptions.youtube}
									MainTitle={
										<Grid container alignItems='center'>
											<YoutubeIcon size={iconSize} style={{ color: '#1d8cf8', marginRight: '5px' }} />
											Youtube
										</Grid>
									}
									option='youtube'
									value={!thisOptions.youtube}
									thisOptions={thisOptions}
									setThisOptions={setThisOptions}
									content={
										<Grid container>
											<ReportContentSingleOption
												title='Resumo Youtube'
												checked={thisOptions.summaryYoutube}
												option='summaryYoutube'
												value={!thisOptions.summaryYoutube}
												thisOptions={thisOptions}
												setThisOptions={setThisOptions}
												isSubOption={true}
											/>
											<ReportContentMultiOption
												mainChecked={thisOptions.sentimentsYoutube}
												MainTitle={'Sentimento Youtube'}
												option={'sentimentsYoutube'}
												value={!thisOptions.sentimentsYoutube}
												thisOptions={thisOptions}
												setThisOptions={setThisOptions}
												isSubOption={true}
												content={
													<Grid container>
														<ReportContentSingleOption
															title={'Sentimento Youtube Padrão'}
															checked={thisOptions.sentimentsYoutubeStandard}
															option={'sentimentsYoutubeStandard'}
															value={!thisOptions.sentimentsYoutubeStandard}
															thisOptions={thisOptions}
															setThisOptions={setThisOptions}
															isSubOption={true}
														/>
														<ReportContentSingleOption
															title={'Sentimento Youtube Saldo'}
															checked={thisOptions.sentimentsYoutubeBalance}
															option={'sentimentsYoutubeBalance'}
															value={!thisOptions.sentimentsYoutubeBalance}
															thisOptions={thisOptions}
															setThisOptions={setThisOptions}
															isSubOption={true}
														/>
													</Grid>
												}
											/>
											<ReportContentMultiOption
												mainChecked={thisOptions.timelineYoutube}
												MainTitle={'Linha do Tempo Youtube'}
												option={'timelineYoutube'}
												value={!thisOptions.timelineYoutube}
												thisOptions={thisOptions}
												setThisOptions={setThisOptions}
												isSubOption={true}
												content={
													<Grid container>
														<ReportContentSingleOption
															title={'Linha do Tempo Youtube Engajamento'}
															checked={thisOptions.timelineYoutubeEngagement}
															option={'timelineYoutubeEngagement'}
															value={!thisOptions.timelineYoutubeEngagement}
															thisOptions={thisOptions}
															setThisOptions={setThisOptions}
															isSubOption={true}
														/>
														<ReportContentSingleOption
															title={'Linha do Tempo Youtube Posts'}
															checked={thisOptions.timelineYoutubePosts}
															option={'timelineYoutubePosts'}
															value={!thisOptions.timelineYoutubePosts}
															thisOptions={thisOptions}
															setThisOptions={setThisOptions}
															isSubOption={true}
														/>
													</Grid>
												}
											/>
											<ReportContentMultiOption
												mainChecked={thisOptions.networkYoutube}
												MainTitle={'Influenciadores Youtube'}
												option={'networkYoutube'}
												value={!thisOptions.networkYoutube}
												thisOptions={thisOptions}
												setThisOptions={setThisOptions}
												isSubOption={true}
												content={
													<Grid container>
														<ReportContentSingleOption
															title={'Influenciadores Youtube Contas'}
															checked={thisOptions.networkYoutubeChannels}
															option={'networkYoutubeChannels'}
															value={!thisOptions.networkYoutubeChannels}
															thisOptions={thisOptions}
															setThisOptions={setThisOptions}
															isSubOption={true}
														/>
														<ReportContentSingleOption
															title={'Influenciadores Youtube Sentimentos'}
															checked={thisOptions.networkYoutubeSentiments}
															option={'networkYoutubeSentiments'}
															value={!thisOptions.networkYoutubeSentiments}
															thisOptions={thisOptions}
															setThisOptions={setThisOptions}
															isSubOption={true}
														/>
													</Grid>
												}
											/>

											<ReportContentMultiOption
												mainChecked={thisOptions.locationYoutube}
												MainTitle={'Localidade Youtube'}
												option={'locationYoutube'}
												value={!thisOptions.locationYoutube}
												thisOptions={thisOptions}
												setThisOptions={setThisOptions}
												isSubOption={true}
												content={
													<Grid container>
														<ReportContentSingleOption
															title={'Localidade Youtube Volume'}
															checked={thisOptions.locationYoutubeVolume}
															option={'locationYoutubeVolume'}
															value={!thisOptions.locationYoutubeVolume}
															thisOptions={thisOptions}
															setThisOptions={setThisOptions}
															isSubOption={true}
														/>
														<ReportContentSingleOption
															title={'Localidade Youtube Sentimentos'}
															checked={thisOptions.locationYoutubeSentiments}
															option={'locationYoutubeSentiments'}
															value={!thisOptions.locationYoutubeSentiments}
															thisOptions={thisOptions}
															setThisOptions={setThisOptions}
															isSubOption={true}
														/>
														<ReportContentSingleOption
															title={'Localidade Youtube Gênero'}
															checked={thisOptions.locationYoutubeGender}
															option={'locationYoutubeGender'}
															value={!thisOptions.locationYoutubeGender}
															thisOptions={thisOptions}
															setThisOptions={setThisOptions}
															isSubOption={true}
														/>
													</Grid>
												}
											/>
										</Grid>
									}
								/>
							</>
						)}

						{cardType === 'term' && (
							<ReportContentMultiOption
								mainChecked={thisOptions.news}
								MainTitle={
									<Grid container alignItems='center'>
										<NewsIcon size={iconSize} style={{ color: '#1d8cf8', marginRight: '5px' }} />
										Notícias
									</Grid>
								}
								option={'news'}
								value={!thisOptions.news}
								thisOptions={thisOptions}
								setThisOptions={setThisOptions}
								content={
									<Grid container>
										<ReportContentSingleOption
											title={'Resumo Notícias'}
											checked={thisOptions.summaryNews}
											option={'summaryNews'}
											value={!thisOptions.summaryNews}
											thisOptions={thisOptions}
											setThisOptions={setThisOptions}
											isSubOption={true}
										/>
										<ReportContentMultiOption
											mainChecked={thisOptions.timelineNews}
											MainTitle={'Linha do Tempo Notícias'}
											option={'timelineNews'}
											value={!thisOptions.timelineNews}
											thisOptions={thisOptions}
											setThisOptions={setThisOptions}
											isSubOption={true}
											content={
												<Grid container>
													<ReportContentSingleOption
														title={'Linha do Tempo Notícias Engajamento'}
														checked={thisOptions.timelineNewsEngagement}
														option={'timelineNewsEngagement'}
														value={!thisOptions.timelineNewsEngagement}
														thisOptions={thisOptions}
														setThisOptions={setThisOptions}
														isSubOption={true}
													/>
													<ReportContentSingleOption
														title={'Linha do Tempo Notícias Posts'}
														checked={thisOptions.timelineNewsPosts}
														option={'timelineNewsPosts'}
														value={!thisOptions.timelineNewsPosts}
														thisOptions={thisOptions}
														setThisOptions={setThisOptions}
														isSubOption={true}
													/>
												</Grid>
											}
										/>
										<ReportContentMultiOption
											mainChecked={thisOptions.networkNews}
											MainTitle={'Influenciadores Notícias'}
											option={'networkNews'}
											value={!thisOptions.networkNews}
											thisOptions={thisOptions}
											setThisOptions={setThisOptions}
											isSubOption={true}
											content={
												<Grid container>
													<ReportContentSingleOption
														title={'Influenciadores Notícias Contas'}
														checked={thisOptions.networkNewsAccounts}
														option={'networkNewsAccounts'}
														value={!thisOptions.networkNewsAccounts}
														thisOptions={thisOptions}
														setThisOptions={setThisOptions}
														isSubOption={true}
													/>
												</Grid>
											}
										/>
									</Grid>
								}
							/>
						)}
					</Grid>
				</Card>
			</Grid>
		</>
	)
}

export default memo(ReportContentChoice)
