import React from 'react'
import Content from '../../../../components/Content/ContentReport'
import { AnalysisIcon, TwitterIcon, FacebookIcon, InstagramIcon, TiktokIcon, YoutubeIcon, NewsIcon } from 'utils/SystemIcons'
import AnalysisContentBodyTerm from './components/ContentBodiesTerm/AnalysisContentBodyTerm/AnalysisContentBodyTerm'
import FacebookContentBodyTerm from './components/ContentBodiesTerm/FacebookContentBodyTerm/FacebookContentBodyTerm'
import TwitterContentBodyTerm from './components/ContentBodiesTerm/TwitterContentBodyTerm/TwitterContentBodyTerm'
import InstagramContentBodyTerm from './components/ContentBodiesTerm/InstagramContentBodyTerm/InstagramContentBodyTerm'
import TiktokContentBodyTerm from './components/ContentBodiesTerm/TiktokContentBodyTerm/TiktokContentBodyTerm'
import YoutubeContentBodyTerm from './components/ContentBodiesTerm/YoutubeContentBodyTerm/YoutubeContentBodyTerm'
import NewsContentBodyTerm from './components/ContentBodiesTerm/NewsContentBodyTerm/NewsContentBodyTerm'

const contentHeaderTwitterIcon = <TwitterIcon size={50} style={{ color: '#1DA1F2' }} />
const contentHeaderFacebookIcon = <FacebookIcon size={50} style={{ color: '#3b5998' }} />
const contentHeaderInstagramIcon = <InstagramIcon size={50} style={{ color: '#1DA1F2', marginTop: '10px' }} />
const contentHeaderTiktokIcon = <TiktokIcon size={50} style={{ color: '#1DA1F2', marginTop: '10px' }} />
const contentHeaderYoutubeIcon = <YoutubeIcon size={50} style={{ color: '#1DA1F2', marginTop: '10px' }} />
const contentHeaderNewsIcon = <NewsIcon size={50} style={{ color: '#1DA1F2', marginTop: '10px' }} />

const contentHeaderAnalysisIcon = <AnalysisIcon size={50} style={{ color: '#1d8cf8' }} />

const ReportTerm = ({
	verifiedDatasourcesSummary,
	cardsIdsObject,
	filters,
	viewType,
	graphInterval,
	sentimentsTwitterStandard,
	sentimentsTwitterBalance,
	sentimentsFacebookStandard,
	sentimentsFacebookBalance,
	sentimentsInstagramStandard,
	sentimentsInstagramBalance,
	sentimentsTiktokStandard,
	sentimentsTiktokBalance,
	sentimentsYoutubeStandard,
	sentimentsYoutubeBalance,
	timelineTwitterEngagement,
	timelineFacebookEngagement,
	timelineInstagramEngagement,
	timelineTiktokEngagement,
	timelineYoutubeEngagement,
	timelineNewsEngagement,
	networkNewsAccounts,
	networkFacebookAccounts,
	networkFacebookSentiments,
	networkTwitterAccounts,
	networkTwitterSentiments,
	networkTwitterHashtags,
	networkInstagramAccounts,
	networkInstagramSentiments,
	networkTiktokAccounts,
	networkTiktokSentiments,
	networkYoutubeChannels,
	networkYoutubeSentiments,
	summaryTwitter,
	summaryFacebook,
	summaryInstagram,
	summaryTiktok,
	summaryYoutube,
	summaryNews,
	timelineTwitterPosts,
	timelineFacebookPosts,
	timelineInstagramPosts,
	timelineTiktokPosts,
	timelineYoutubePosts,
	timelineNewsPosts,
	locationTwitterVolume,
	locationTwitterSentiments,
	locationTwitterGender,
	locationFacebookVolume,
	locationFacebookSentiments,
	locationFacebookGender,
	locationInstagramVolume,
	locationInstagramSentiments,
	locationInstagramGender,
	locationTiktokVolume,
	locationTiktokSentiments,
	locationTiktokGender,
	locationYoutubeVolume,
	locationYoutubeSentiments,
	locationYoutubeGender,
	twitter,
	facebook,
	instagram,
	tiktok,
	youtube,
	news
}) => {
	return (
		<>
			<Content
				headerIcon={contentHeaderAnalysisIcon}
				headerText='Análise de Sentimentos'
				breakPrint
				hide={
					(!verifiedDatasourcesSummary.twitter &&
						!verifiedDatasourcesSummary.facebook &&
						!verifiedDatasourcesSummary.instagram &&
						!verifiedDatasourcesSummary.tiktok &&
						!verifiedDatasourcesSummary.news) ||
					(!twitter && !facebook && !instagram && !tiktok && !youtube && !news) ||
					(!sentimentsTwitterBalance &&
						!sentimentsTwitterStandard &&
						!sentimentsFacebookBalance &&
						!sentimentsFacebookStandard &&
						!sentimentsInstagramBalance &&
						!sentimentsInstagramStandard &&
						!sentimentsTiktokBalance &&
						!sentimentsTiktokStandard &&
						!sentimentsYoutubeBalance &&
						!sentimentsYoutubeStandard &&
						!timelineTwitterEngagement &&
						!timelineTwitterPosts &&
						!timelineFacebookEngagement &&
						!timelineFacebookPosts &&
						!timelineInstagramEngagement &&
						!timelineInstagramPosts &&
						!timelineTiktokEngagement &&
						!timelineTiktokPosts &&
						!timelineYoutubeEngagement &&
						!timelineYoutubePosts &&
						!timelineNewsEngagement &&
						!timelineNewsPosts)
				}
			>
				<AnalysisContentBodyTerm
					cards={cardsIdsObject}
					filters={filters}
					partialLoad={{
						twitter: verifiedDatasourcesSummary.twitter,
						facebook: verifiedDatasourcesSummary.facebook,
						instagram: verifiedDatasourcesSummary.instagram,
						tiktok: verifiedDatasourcesSummary.tiktok,
						youtube: verifiedDatasourcesSummary.youtube,
						news: verifiedDatasourcesSummary.news
					}}
					viewType={viewType}
					graphInterval={graphInterval}
					sentimentsTwitterStandard={sentimentsTwitterStandard}
					sentimentsTwitterBalance={sentimentsTwitterBalance}
					sentimentsFacebookStandard={sentimentsFacebookStandard}
					sentimentsFacebookBalance={sentimentsFacebookBalance}
					sentimentsInstagramStandard={sentimentsInstagramStandard}
					sentimentsInstagramBalance={sentimentsInstagramBalance}
					sentimentsTiktokStandard={sentimentsTiktokStandard}
					sentimentsTiktokBalance={sentimentsTiktokBalance}
					sentimentsYoutubeStandard={sentimentsYoutubeStandard}
					sentimentsYoutubeBalance={sentimentsYoutubeBalance}
					timelineTwitterEngagement={timelineTwitterEngagement}
					timelineFacebookEngagement={timelineFacebookEngagement}
					timelineInstagramEngagement={timelineInstagramEngagement}
					timelineTiktokEngagement={timelineTiktokEngagement}
					timelineYoutubeEngagement={timelineYoutubeEngagement}
					timelineNewsEngagement={timelineNewsEngagement}
					timelineTwitterPosts={timelineTwitterPosts}
					timelineFacebookPosts={timelineFacebookPosts}
					timelineInstagramPosts={timelineInstagramPosts}
					timelineTiktokPosts={timelineTiktokPosts}
					timelineYoutubePosts={timelineYoutubePosts}
					timelineNewsPosts={timelineNewsPosts}
				/>
			</Content>
			{verifiedDatasourcesSummary.twitter && (
				<Content headerIcon={contentHeaderTwitterIcon} headerText='Twitter' breakPrint hide={!verifiedDatasourcesSummary.twitter || !twitter}>
					<TwitterContentBodyTerm
						filters={filters}
						cards={cardsIdsObject}
						networkTwitterAccounts={networkTwitterAccounts}
						networkTwitterSentiments={networkTwitterSentiments}
						networkTwitterHashtags={networkTwitterHashtags}
						summaryTwitter={summaryTwitter}
						locationTwitterVolume={locationTwitterVolume}
						locationTwitterSentiments={locationTwitterSentiments}
						locationTwitterGender={locationTwitterGender}
					/>
				</Content>
			)}
			{verifiedDatasourcesSummary.facebook && (
				<Content
					headerIcon={contentHeaderFacebookIcon}
					headerText='Facebook'
					breakPrint
					hide={!verifiedDatasourcesSummary.facebook || !facebook}
				>
					<FacebookContentBodyTerm
						filters={filters}
						cards={cardsIdsObject}
						networkFacebookAccounts={networkFacebookAccounts}
						networkFacebookSentiments={networkFacebookSentiments}
						summaryFacebook={summaryFacebook}
						locationFacebookVolume={locationFacebookVolume}
						locationFacebookSentiments={locationFacebookSentiments}
						locationFacebookGender={locationFacebookGender}
					/>
				</Content>
			)}
			{verifiedDatasourcesSummary.instagram && (
				<Content
					headerIcon={contentHeaderInstagramIcon}
					headerText='Instagram'
					breakPrint
					hide={!verifiedDatasourcesSummary.instagram || !instagram}
				>
					<InstagramContentBodyTerm
						filters={filters}
						cards={cardsIdsObject}
						summaryInstagram={summaryInstagram}
						networkInstagramAccounts={networkInstagramAccounts}
						networkInstagramSentiments={networkInstagramSentiments}
						locationInstagramVolume={locationInstagramVolume}
						locationInstagramSentiments={locationInstagramSentiments}
						locationInstagramGender={locationInstagramGender}
					/>
				</Content>
			)}
			{verifiedDatasourcesSummary.tiktok && (
				<Content headerIcon={contentHeaderTiktokIcon} headerText='Tiktok' breakPrint hide={!verifiedDatasourcesSummary.tiktok || !tiktok}>
					<TiktokContentBodyTerm
						filters={filters}
						cards={cardsIdsObject}
						summaryTiktok={summaryTiktok}
						networkTiktokAccounts={networkTiktokAccounts}
						networkTiktokSentiments={networkTiktokSentiments}
						locationTiktokVolume={locationTiktokVolume}
						locationTiktokSentiments={locationTiktokSentiments}
						locationTiktokGender={locationTiktokGender}
					/>
				</Content>
			)}
			{verifiedDatasourcesSummary.youtube && (
				<Content headerIcon={contentHeaderYoutubeIcon} headerText='Youtube' breakPrint hide={!verifiedDatasourcesSummary.youtube || !youtube}>
					<YoutubeContentBodyTerm
						filters={filters}
						cards={cardsIdsObject}
						summaryYoutube={summaryYoutube}
						networkYoutubeChannels={networkYoutubeChannels}
						networkYoutubeSentiments={networkYoutubeSentiments}
						locationYoutubeVolume={locationYoutubeVolume}
						locationYoutubeSentiments={locationYoutubeSentiments}
						locationYoutubeGender={locationYoutubeGender}
					/>
				</Content>
			)}
			{verifiedDatasourcesSummary.news && (
				<Content headerIcon={contentHeaderNewsIcon} headerText='Notícias' breakPrint hide={!verifiedDatasourcesSummary.news || !news}>
					<NewsContentBodyTerm
						filters={filters}
						cards={cardsIdsObject}
						networkNewsAccounts={networkNewsAccounts}
						summaryNews={summaryNews}
					/>
				</Content>
			)}
		</>
	)
}

export default ReportTerm
