import React from 'react'
import { PotentialReachIcon, LoadingIcon } from 'utils/SystemIcons'
import AnimatedNumber from 'react-animated-number'
import { makeStyles } from '@mui/styles'
import { Button, Tooltip } from '@mui/material'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import constantsVox from 'constants-vox'
import CopyToClipboard from 'react-copy-to-clipboard'

const useStyles = makeStyles({
	button: {
		fontFamily: 'Poppins',
		boxShadow: 'none',
		color: '#1d8cf8',
		borderRadius: '100px',
		background: 'transparent',
		'&:hover': {
			backgroundColor: 'rgba(29, 138, 248, 5%)',
			boxShadow: '0px 0px -10px -17px rgba(0,0,0,255)'
		}
	}
})

const nFormatter = (num) => {
	const lookup = [
		{ value: 1, symbol: '' },
		{ value: 1e3, symbol: 'k' },
		{ value: 1e6, symbol: 'M' },
		{ value: 1e9, symbol: 'B' },
		{ value: 1e12, symbol: 'T' },
		{ value: 1e15, symbol: 'QUA' },
		{ value: 1e18, symbol: 'QUI' }
	]
	const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
	let item = lookup
		.slice()
		.reverse()
		.find((item) => {
			return num >= item.value
		})

	return item ? (num / item.value).toFixed(item.value === 1 ? 0 : 1).replace(rx, '$1') + item.symbol : '0'
}

const MediaCountRenderer = ({ value = 0, loading = false, color = '#1d8cf8', title, icon }) => {
	const classes = useStyles()

	const onCopy = React.useCallback(() => {
		sendMessageToast(`${title} copiado`, constantsVox.TOAST.TYPES.INFO)
	}, [])

	return (
		<div style={{ color: '#757575', fontFamily: 'Poppins', fontSize: '14px', display: 'flex', alignItems: 'center', marginLeft: '3em' }}>
			{loading ? <LoadingIcon size={15} style={{ color: color, marginRight: '10px' }} /> : <>{icon}</>}
			{title}:{' '}
			<CopyToClipboard onCopy={onCopy} text={value}>
				<Tooltip
					title={value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : 0}
					placement='right'
					arrow
					enterDelay={0}
					enterNextDelay={0}
				>
					<Button
						// onClick={}
						className={classes.button}
						style={{
							marginLeft: '5px',
							color: color,
							fontFamily: 'Poppins',
							fontSize: '14px',
							alignItems: 'center',
							textTransform: 'none',
							borderRadius: '100px',
							maxHeight: '20px',
							minWidth: '0px'
						}}
					>
						<span style={{ color: color }}>
							<AnimatedNumber
								value={value}
								style={{
									whiteSpace: 'nowrap',
									fontFamily: 'Poppins',
									display: 'flex',
									fontSize: '14px',
									color: color
								}}
								duration={1000}
								formatValue={(n) => nFormatter(n)}
								frameStyle={(percentage) => (percentage > 20 && percentage < 80 ? { opacity: '0.4' } : { opacity: '1' })}
							/>
						</span>{' '}
					</Button>
				</Tooltip>
			</CopyToClipboard>
		</div>
	)
}

export default MediaCountRenderer
