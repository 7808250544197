import React, { memo, useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { ConfigureIcon, CloseIcon, LoadingIcon, HistoryIcon } from 'utils/SystemIcons'
import SourceSelectorGlobalFilters from 'components/GlobalFilters/components/SourceSelectorGlobalFilters/SourceSelectorGlobalFilters'
import ServiceHistory from 'components/ServiceHistory/ServiceHistory'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import constantsVox from 'constants-vox'
import api from 'service/service'
import {
	Box,
	Button,
	Card,
	CardContent,
	Collapse,
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	Paper,
	Step,
	StepContent,
	StepLabel,
	Stepper,
	TextField,
	Typography
} from '@mui/material'

const isFirefox = typeof InstallTrigger !== 'undefined'

const blue = '#1d8cf8'

const SERVICE_HISTORY_NAME = 'addAurasightConfigDownload'

const useStyles = makeStyles({
	backDrop: {
		backdropFilter: 'blur(3px)',
		backgroundColor: 'rgba(220, 220, 220, 0.4)'
	}
})

const steps = [
	{
		label: 'Selecione a fonte',
		description: ''
	},
	{
		label: 'Preencha as configurações',
		description: ''
	}
]

export const AurasightConfigDialog = ({ openModal, setOpenModal, filters, selectedCard }) => {
	const [selectedSources, setSelectedSources] = useState(filters.sources)
	const [activeStep, setActiveStep] = useState(0)
	const [submitting, setSubmitting] = useState(false)
	const [openHistory, setOpenHistory] = useState(false)
	const [newConfig, setNewConfig] = useState()
	const classes = useStyles()

	useEffect(() => {
		setNewConfig({ ...newConfig, sources: selectedSources })
	}, [selectedSources])

	const onSourceChange = (sources) => {
		setSelectedSources(sources)
	}

	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1)
	}

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1)
	}

	const toggleHistory = () => {
		setOpenHistory(!openHistory)
	}

	const handleClickAddConfig = async () => {
		setSubmitting(true)
		await api.post('/image-annotation/add-config-download', { ...newConfig, cardTermId: selectedCard.id, filters: filters }).then((response) => {
			if (response.status === 200) {
				sendMessageToast('Configuração adicionada com sucesso!', constantsVox.TOAST.TYPES.SUCCESS)
			}
			setSubmitting(false)
			handleClose()
		})
	}

	const handleClose = () => {
		setOpenModal(false)
		setActiveStep(0)
		setSelectedSources(filters.sources)
		setNewConfig()
	}

	return (
		<Dialog
			id='dialog-app'
			fullWidth={true}
			size='md'
			style={{ marginTop: '4%' }}
			open={openModal}
			onClose={handleClose}
			BackdropProps={{ classes: { root: classes.backDrop } }}
			PaperProps={{
				style: {
					borderRadius: '20px',
					backdropFilter: 'blur(10px)',
					backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
					border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.7)'}`,
					boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )',
					maxWidth: '50em',
					maxHeight: '96vh',
					marginTop: '5em'
				}
			}}
			unmountOnExit
		>
			<DialogTitle>
				<div className='jc-space-between d-flex ai-center'>
					<div style={{ color: blue, fontSize: 20 }}>
						<ConfigureIcon className='fix-icon-margin-bottom-negative' size={20} color={blue} /> Configuração download
					</div>
					<div>
						<IconButton color='primary' style={{ outline: 'none' }} onClick={handleClose}>
							<CloseIcon color={blue} size={20} />
						</IconButton>
					</div>
				</div>
			</DialogTitle>
			<DialogContent style={{ paddingTop: 0, marginTop: 0 }}>
				<CardContent style={{ padding: 0, margin: 0 }}>
					<Grid
						container
						style={{
							borderRadius: '20px',
							color: 'rgb(120, 120, 120)',
							fontFamily: 'Poppins',
							fontWeight: '300',
							fontSize: '20px',
							justifyContent: 'center'
						}}
					>
						<Grid container item xs={12} style={{ display: 'flex', paddingLeft: '0.6em', marginTop: '-1em' }}>
							<Box sx={{ marginTop: '1em' }}>
								<Stepper activeStep={activeStep} orientation='vertical'>
									{steps.map((step, index) => (
										<Step key={step.label}>
											<StepLabel>{step.label}</StepLabel>
											<StepContent>
												<Typography>{step.description}</Typography>

												{index === 0 && (
													<Grid item xs={12} style={{ height: '71px', display: 'flex', alignItems: 'flex-end' }}>
														<SourceSelectorGlobalFilters
															onSourceChange={onSourceChange}
															sourcesControlled={selectedSources}
															isOverview={false}
														/>
													</Grid>
												)}

												{index === 1 && (
													<Collapse in={!!selectedSources[0]} unmountOnExit>
														<Grid item xs={12}>
															<Card
																elevation={0}
																style={{
																	borderRadius: '20px',
																	padding: '30px'
																}}
															>
																<Grid container spacing={1}>
																	<Grid
																		item
																		xs={12}
																		style={{
																			color: '#1d8cf8',
																			display: 'flex',
																			justifyContent: 'flex-start'
																		}}
																	>
																		<Grid item xs={12} container spacing={2}>
																			<Grid item xs={4}>
																				<TextField
																					type={'number'}
																					size='small'
																					className='d-flex'
																					style={{ color: '#1d8cf8' }}
																					sx={{
																						'& .MuiOutlinedInput-root': {
																							fontFamily: 'Poppins',
																							'& fieldset': {
																								borderRadius: '15px'
																							}
																						}
																					}}
																					value={newConfig?.minDownloadVideos}
																					onFocus={(event) => {
																						event.target.select()
																					}}
																					onChange={(e) =>
																						setNewConfig({
																							...newConfig,
																							minDownloadVideos: e.target.value
																						})
																					}
																					variant='outlined'
																					label={'Min Download Vídeos'}
																					// error={fieldErrors.find((fe) => fe.field === 'content_engagement_public_like_count')?.message}
																					// helperText={fieldErrors.find((fe) => fe.field === 'content_engagement_public_like_count')?.message}
																				/>
																			</Grid>
																			<Grid item xs={4}>
																				<TextField
																					type={'number'}
																					size='small'
																					className='d-flex'
																					style={{ color: '#1d8cf8' }}
																					sx={{
																						'& .MuiOutlinedInput-root': {
																							fontFamily: 'Poppins',
																							'& fieldset': {
																								borderRadius: '15px'
																							}
																						}
																					}}
																					value={newConfig?.maxDownloadVideos}
																					onFocus={(event) => {
																						event.target.select()
																					}}
																					onChange={(e) =>
																						setNewConfig({
																							...newConfig,
																							maxDownloadVideos: e.target.value
																						})
																					}
																					variant='outlined'
																					label={'Max Download Vídeos'}
																					// error={fieldErrors.find((fe) => fe.field === 'content_engagement_public_comment_count')?.message}
																					// helperText={fieldErrors.find((fe) => fe.field === 'content_engagement_public_comment_count')?.message}
																				/>
																			</Grid>
																			<Grid item xs={4}>
																				<TextField
																					type={'number'}
																					size='small'
																					className='d-flex'
																					style={{ color: '#1d8cf8' }}
																					sx={{
																						'& .MuiOutlinedInput-root': {
																							fontFamily: 'Poppins',
																							'& fieldset': {
																								borderRadius: '15px'
																							}
																						}
																					}}
																					value={newConfig?.percentageDownload}
																					onFocus={(event) => {
																						event.target.select()
																					}}
																					onChange={(e) =>
																						setNewConfig({
																							...newConfig,
																							percentageDownload: e.target.value
																						})
																					}
																					variant='outlined'
																					label={'Porcentagem Download'}
																					// error={fieldErrors.find((fe) => fe.field === 'content_engagement_public_view_count')?.message}
																					// helperText={fieldErrors.find((fe) => fe.field === 'content_engagement_public_view_count')?.message}
																				/>
																			</Grid>
																		</Grid>
																	</Grid>
																</Grid>
															</Card>
														</Grid>
													</Collapse>
												)}
												<Box sx={{ mb: 2 }}>
													<div>
														<Button
															style={{ borderRadius: '100px', fontFamily: 'Poppins' }}
															variant='outlined'
															onClick={handleNext}
															sx={{ mt: 1, mr: 1 }}
														>
															{index === steps.length - 1 ? 'Terminar' : 'Continuar'}
														</Button>
														<Button
															style={{ borderRadius: '100px', fontFamily: 'Poppins' }}
															disabled={index === 0}
															onClick={handleBack}
															sx={{ mt: 1, mr: 1 }}
														>
															Voltar
														</Button>
													</div>
												</Box>
											</StepContent>
										</Step>
									))}
								</Stepper>
								{activeStep === steps.length && (
									<Paper
										square
										elevation={0}
										style={{
											borderRadius: '25px',
											maxWidth: '340px',
											marginTop: '1em',
											padding: '1em',
											minWidth: '340px'
										}}
									>
										<div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
											<Button
												variant='outlined'
												className={classes.button}
												disabled={submitting}
												onClick={handleClickAddConfig}
												style={{ maxHeight: '36.5px', whiteSpace: 'nowrap' }}
											>
												<Collapse orientation='horizontal' in={!submitting}>
													Adicionar configuração
												</Collapse>
												<Collapse orientation='horizontal' in={submitting}>
													<LoadingIcon color='#FFF' size={20} style={{ marginTop: '6px' }} />
												</Collapse>
											</Button>
											<Button
												variant='outlined'
												disabled={submitting}
												onClick={handleBack}
												style={{ borderRadius: '100px', fontFamily: 'Poppins', marginLeft: '2em' }}
											>
												Voltar
											</Button>
										</div>
									</Paper>
								)}
							</Box>
						</Grid>
					</Grid>
					<Button
						onClick={toggleHistory}
						variant='outlined'
						style={{
							fontFamily: 'Poppins',
							borderRadius: '100px',
							fontSize: '14px',
							borderColor: 'rgba(29, 138, 248, 0.3)',
							marginTop: '2em'
						}}
					>
						<HistoryIcon size={21} style={{ color: '#1d8cf8', cursor: 'pointer', marginRight: '5px' }} />
						Histórico de configuração
					</Button>
					<Dialog
						open={openHistory}
						style={{ marginTop: '-6%' }}
						onClose={toggleHistory}
						PaperProps={{
							style: {
								borderRadius: '20px',
								backdropFilter: 'blur(10px)',
								backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
								border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
								boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )',
								maxWidth: '80em'
							}
						}}
						BackdropProps={{
							style: { backdropFilter: 'blur(3px)', backgroundColor: 'rgba(220, 220, 220, 0.4)' }
						}}
						fullWidth={true}
						maxWidth='lg'
						unmountOnExit
					>
						<DialogContent style={{ padding: 0, margin: 0, height: '590px', position: 'relative' }}>
							<ServiceHistory
								style={{ width: '100%', height: '100%' }}
								cardId={selectedCard.id}
								isOpen={openHistory}
								toggleOpenHistory={toggleHistory}
								cardType='term'
								serviceName={SERVICE_HISTORY_NAME}
								cardName={selectedCard.name}
								sources={selectedSources}
							/>
						</DialogContent>
					</Dialog>
				</CardContent>
			</DialogContent>
		</Dialog>
	)
}

export default memo(AurasightConfigDialog)
