import React, { useState, useEffect, memo } from 'react'
import moment from 'moment-timezone'
import GraphSkeletonLoad from 'components/SkeletonLoads/GraphSkeletonLoad/GraphSkeletonLoad'
import { useUpdateData } from 'hooks/ReactQueryHooks'
import { graphOptionsArea } from 'graphs/utils/GraphOptionsArea'
import InspectIntervalButton from '../../../components/InspectIntervalButton/InspectIntervalButton'
import { Grid } from '@mui/material'
import NowcastingOptionsMenu from '../NowcastingOptionsMenu/NowcastingOptionsMenu'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import { verifyServerResponseCanShowToast } from 'utils/generalFunctions'
import NowcastingGraphLine from './NowcastingLine'
import NowcastingGraphBars from './NowcastingBar'
import NowcastingGraphTotals from './NowcastingTotal'
import NowcastingGraphTotalRelative from './NowcastingTotalRelative'

const defaultDate = {
	fromDate: moment(new Date(), 'YYYY-MM-DD').subtract(6, 'days').format('YYYY-MM-DD'),
	toDate: moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD')
}

const emptyProp = {
	options: {
		chart: {
			type: 'line'
		}
	},
	series: [
		{
			name: '',
			data: [0]
		}
	]
}

const defaultOptions = {
	type: 'posts',
	engagement: false,
	amount: true,
	social: false,
	posts: true,
	gender: false,
	users: false,
	men: false,
	women: false,
	organization: false,
	noClass: false,
	sources: false,
	politics: false,
	comments: false,
	retweets: false
}

let interval = defaultDate
let canLockTime = false

const GraficoImpactoNowcasting = ({
	cards,
	fullScreenState = false,
	isReport = false,
	onPartialLoading,
	isFetchingData,
	filters,
	cardType = 'term',
	options = defaultOptions,
	changeExternalInterval,
	defaultGraphTime,
	lockTimeInterval,
	isWidget = false,
	isExtended,
	handleChangeGraphView
}) => {
	const [loading, setLoading] = useState(true)
	const [windowSize, setWindowSize] = useState(window.innerHeight)
	const [comparison, setComparison] = useState('absolute')
	const [visualComparison, setVisualComparison] = useState('absolute')
	const [graphTime, setGraphTime] = useState(defaultGraphTime)
	const [graphView, setGraphView] = useState('line')
	const [chart, setChart] = useState(<></>)

	useEffect(() => {
		canLockTime = false
		setGraphTime(defaultGraphTime)
	}, [defaultGraphTime])

	useEffect(() => {
		handleChangeGraphView(graphView)
	}, [graphView])

	const getBody = () => ({
		configGlobalFilters: {
			cardType: cardType.toUpperCase(),
			cardIds: cards?.map((card) => card.id)
		},
		globalFilters: filters,
		componentProperties: {
			comparison,
			graphTime,
			hasEngagement: options.engagement,
			hasPosts: options.posts,
			hasUsers: options.users,
			hasMen: options.men,
			hasWomen: options.women,
			hasOrganization: options.organization,
			hasNoClass: options.noClass,
			hasLeft: options.left_wing,
			hasRight: options.right_wing,
			hasCenterLeft: options.centre_left_wing,
			hasCenterRight: options.centre_right_wing,
			hasCenter: options.centre,
			hasTwitter: options.twitter,
			hasBluesky: options.bluesky,
			hasFacebook: options.facebook,
			hasInstagram: options.instagram,
			hasTiktok: options.tiktok,
			hasYoutube: options.youtube,
			hasNews: options.news,
			hasComments: options.comments,
			hasShares: options.reposts,
			type: options.type
		}
	})

	let { data, error, isFetching, isFetched } = useUpdateData({
		url: '/analysis/nowcasting/get-graph-data',
		updateItemName: 'Nowcasting',
		refetchInterval: 600000,
		freshDataTime: 600000,
		isEnabled: isFetchingData,
		queryName: 'relevanceChange_useUpdateData',
		method: 'post',
		body: getBody()
	})

	useEffect(() => {
		if (filters.fromDate !== interval.fromDate || filters.toDate !== interval.toDate) {
			interval = { fromDate: filters.fromDate, toDate: filters.toDate }
			if (changeExternalInterval) {
				changeExternalInterval(interval)
			}
		}
	}, [filters.fromDate, filters.toDate])

	useEffect(() => {
		if (isFetched) {
			setLoading(false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFetched])

	useEffect(() => {
		if (!loading && onPartialLoading) {
			onPartialLoading(isFetching)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFetching])

	if (verifyServerResponseCanShowToast(error) && !isReport) {
		sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
	}

	useEffect(() => {
		if (fullScreenState) {
			setWindowSize(435)
		} else {
			setWindowSize(window.innerHeight / 1.2 - 120)
		}
		if (isReport) {
			setWindowSize(350)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fullScreenState])

	const onIntervalChange = (xaxis) => {
		if (xaxis === undefined || xaxis.min === undefined || xaxis.max === undefined) {
			interval = { fromDate: filters.fromDate, toDate: filters.toDate, fromHour: filters.fromHour, toHour: filters.toHour }
			changeExternalInterval(interval)
			canLockTime = false
		} else {
			const fromDate = moment(xaxis.min).tz('UTC').format('YYYY-MM-DD')
			const toDate = moment(xaxis.max).tz('UTC').format('YYYY-MM-DD')
			const fromHour = graphTime === 'day' ? '00:00:00' : moment(xaxis.min).format('HH:mm:ss')
			const toHour = graphTime === 'day' ? '23:59:59' : moment(xaxis.max).format('HH:mm:ss')

			interval = { fromDate, toDate, fromHour, toHour }
			if (moment(interval.fromDate).isBefore(filters.fromDate)) {
				interval.fromDate = filters.fromDate
			}
			if (moment(interval.toDate).isAfter(filters.toDate)) {
				interval.toDate = filters.toDate
			}
			canLockTime = true
			changeExternalInterval(interval)
		}
	}

	const handleChangeGraphType = (newComparison, graphViewType) => {
		if (graphViewType === 'totals') {
			setVisualComparison(newComparison)
			setComparison('absolute')
		} else {
			setComparison(newComparison)
			setVisualComparison(newComparison)
		}
		interval = { fromDate: filters.fromDate, toDate: filters.toDate, fromHour: filters.fromHour, toHour: filters.toHour }
		changeExternalInterval(interval)
	}

	const handleChangeGraphTime = (newTime) => {
		setGraphTime(newTime)
		interval = { fromDate: filters.fromDate, toDate: filters.toDate, fromHour: filters.fromHour, toHour: filters.toHour }
		changeExternalInterval(interval)
	}

	const handleSelectGraphView = (newView) => {
		if (newView === 'totals') {
			setVisualComparison(comparison)
			setComparison('absolute')
		} else {
			setComparison(visualComparison)
		}
		setGraphView(newView)
		interval = { fromDate: filters.fromDate, toDate: filters.toDate, fromHour: filters.fromHour, toHour: filters.toHour }
		changeExternalInterval(interval)
	}

	const onClickLockInterval = () => {
		canLockTime = false
		lockTimeInterval(interval)
	}

	const getInterval = () => interval
	const getCanLockTime = () => canLockTime

	const getOptions = (type) => {
		if (data?.formatted?.options) {
			if (type === 'voxAi') {
				return graphOptionsArea(
					isReport,
					data?.formatted?.options?.title,
					data?.formatted?.options?.colors,
					'smooth',
					onIntervalChange,
					graphTime,
					false,
					false,
					true,
					'timelineVoxAi',
					true
				)
			} else {
				return graphOptionsArea(
					isReport,
					data?.formatted?.options?.title,
					data?.formatted?.options?.colors,
					'smooth',
					onIntervalChange,
					graphTime,
					false
				)
			}
		} else {
			return emptyProp.options
		}
	}

	useEffect(() => {
		if (isFetched && !isFetching) {
			setChart(handleChart())
		}
	}, [isFetched, isFetching, graphTime, graphView, options, comparison, filters, isExtended, visualComparison])

	const handleChart = () => {
		if (graphTime === 'day' && graphView === 'bars') {
			return (
				<NowcastingGraphBars
					data={data?.formatted}
					isReport={isReport}
					title={data?.formatted?.options?.title}
					colors={data?.formatted?.options?.colors}
					curve={'smooth'}
					onIntervalChange={onIntervalChange}
					graphTime={graphTime}
					isSolid={true}
				/>
			)
		} else if (graphTime === 'day' && graphView === 'totals') {
			return visualComparison === 'relative' ? (
				<NowcastingGraphTotalRelative
					data={data?.formatted}
					isReport={isReport}
					title={data?.formatted?.options?.title}
					colors={data?.formatted?.options?.colors}
					curve={'smooth'}
					onIntervalChange={onIntervalChange}
					graphTime={graphTime}
					isSolid={false}
				/>
			) : (
				<NowcastingGraphTotals
					data={data?.formatted}
					isReport={isReport}
					title={data?.formatted?.options?.title}
					colors={data?.formatted?.options?.colors}
					curve={'smooth'}
					onIntervalChange={onIntervalChange}
					graphTime={graphTime}
					isSolid={false}
				/>
			)
		} else {
			return (
				<NowcastingGraphLine
					data={data?.formatted}
					isReport={isReport}
					title={data?.formatted?.options?.title}
					colors={data?.formatted?.options?.colors}
					curve={'smooth'}
					onIntervalChange={onIntervalChange}
					graphTime={graphTime}
					isSolid={false}
				/>
			)
		}
	}

	return (
		<>
			{loading ? (
				<GraphSkeletonLoad />
			) : (
				<>
					{!isReport && (
						<NowcastingOptionsMenu
							timelineType={options.type}
							size={cards.length}
							fromDate={filters?.fromDate}
							toDate={filters?.toDate}
							handleChangeGraphType={handleChangeGraphType}
							handleChangeGraphTime={handleChangeGraphTime}
							handleSelectGraphView={handleSelectGraphView}
							selectedGraphView={graphView}
							initialGraphTime={defaultGraphTime}
							getCanLockTime={getCanLockTime}
							onClickLockInterval={onClickLockInterval}
							type={visualComparison}
							setType={setVisualComparison}
						/>
					)}
					<Grid
						container
						spacing={0}
						style={{
							padding: '0em 2em 2em 1em',
							color: 'black',
							textAlign: 'left',
							fontFamily: 'Poppins',
							height: '85%',
							width: '100%'
						}}
					>
						<Grid item xs={12}>
							{chart}
						</Grid>

						<Grid item xs={12}>
							{!isReport && (
								<InspectIntervalButton
									cards={cards}
									getInterval={getInterval}
									filters={filters}
									cardType={cardType}
									showRadar={cardType === 'term' && filters.sources.includes('twitter')}
									showTags={cardType === 'term'}
									showPhotoGallery={cardType === 'term' && !isWidget}
									graphData={{
										options: getOptions('voxAi'),
										series: data?.formatted?.series ? data?.formatted?.series : emptyProp.series,
										type: options.engagement === false && cards.length === 1 ? 'line' : 'area',
										width: '100%',
										height: windowSize / 1.2,
										graphTime: graphTime
									}}
									onIntervalChange={onIntervalChange}
								/>
							)}
						</Grid>
					</Grid>
				</>
			)}
		</>
	)
}

export default memo(GraficoImpactoNowcasting)
