export {
	//Facebook
	getEngagementFormatted,
	getCommentSharesFormatted,
	getActivityFormatted,
	getClicksUniqueFormatted,
	getImpressionsViralAndNotUniqueFormatted,
	getImpressionsPaidOrganicUniqueFormatted,
	getImpressionsFanPaidOrganicUniqueFormatted,
	getImpressionsByStoryTypeUniqueFormatted,
	getNegativeFeedBackFormatted,
	getVideoAdBreakFormatted,
	getVideoCompleteViews30sFormatted,
	getVideoCompleteViewsOrganicFormatted,
	getVideoViewTimeByAgeBucketAndGenderFormatted,
	getVideoViewTimeByCountryIdFormatted,
	getVideoViewTimeByDistributionTypeFormatted,
	getVideoViewTimeByRegionIdFormatted,
	getVideoViewByDistribuitionTypeFormatted,
	getVideoRetentionGraphFormatted,
	getVideoReactionsByTypeTotalFormatted,
	getVideoStoriesByActionTypeFormatted,
	getVideoLikesByReactionTypeFormatted,
	//Instagram
	getEngagementFormattedInstagram,
	getVideoMetricsFormattedInstagram
}

const extractValuesAndLabelsFromArray = (arrayValues) => {
	const values = []
	const labels = []
	arrayValues?.forEach((it) => {
		values.push(it.value || 0)
		labels.push(it.type || '')
	})

	return { values, labels }
}

const emptyFormatted = {
	values: [],
	labels: []
}

//Facebook
const getEngagementFormatted = (data) => {
	if (!data?.post) {
		return emptyFormatted
	}
	const likeCount = data?.post?.engagement?.like_count || 0
	const loveCount = data?.post?.engagement?.love_count || 0
	const angryCount = data?.post?.engagement?.angry_count || 0
	const hahaCount = data?.post?.engagement?.haha_count || 0
	const wowCount = data?.post?.engagement?.wow_count || 0
	const sadCount = data?.post?.engagement?.sad_count || 0
	const total = likeCount + loveCount + angryCount + hahaCount + wowCount + sadCount

	return {
		values: [likeCount, loveCount, angryCount, hahaCount, wowCount, sadCount, total],
		labels: ['Likes', 'Amei', 'Grr', 'Haha', 'Wow', 'Triste', 'Total']
	}
}

const getCommentSharesFormatted = (data) => {
	//somente POST
	if (!data?.post) {
		return emptyFormatted
	}
	const shareCount = data?.post?.engagement?.share_count || 0
	const commentCount = data?.post?.engagement?.comment_count || 0
	const total = shareCount + commentCount

	return {
		values: [shareCount, commentCount, total],
		labels: ['Compartilhamentos', 'Comentários', 'Total']
	}
}

const getActivityFormatted = (data) => {
	if (!data?.post) {
		return emptyFormatted
	}

	const activityByTypeUnique = extractValuesAndLabelsFromArray(data?.post?.metrics?.activity_by_action_type_unique)

	return {
		values: [...activityByTypeUnique.values],
		labels: [...activityByTypeUnique.labels]
	}
}

const getClicksUniqueFormatted = (data) => {
	if (!data?.post) {
		return emptyFormatted
	}
	const clicksByTypeUnique = extractValuesAndLabelsFromArray(data?.post?.metrics?.clicks_by_type_unique)

	return {
		values: [...clicksByTypeUnique.values],
		labels: [...clicksByTypeUnique.labels]
	}
}

const getImpressionsViralAndNotUniqueFormatted = (data) => {
	if (!data?.post) {
		return emptyFormatted
	}

	const impressionsNonViral = data?.post?.metrics?.impressions_nonviral_unique || 0
	const impressionsViral = data?.post?.metrics?.impressions_viral_unique || 0

	return {
		values: [impressionsViral, impressionsNonViral],
		labels: ['Virais', 'Não virais']
	}
}

const getImpressionsPaidOrganicUniqueFormatted = (data) => {
	if (!data?.post) {
		return emptyFormatted
	}

	const impressionsOrganic = data?.post?.metrics?.impressions_organic_unique || 0
	const impressionsPaid = data?.post?.metrics?.impressions_paid || 0
	const impressionsPaidUnique = data?.post?.metrics?.impressions_paid_unique || 0

	return {
		values: [impressionsOrganic, impressionsPaid, impressionsPaidUnique],
		labels: ['Alcance Orgânico', 'Impressões Promovidas', 'Alcance Promovido']
	}
}

const getImpressionsFanPaidOrganicUniqueFormatted = (data) => {
	if (!data?.post) {
		return emptyFormatted
	}

	const impressionsFanPaidUnique = data?.post?.metrics?.impressions_fan_paid_unique || 0
	const impressionsFanUnique = data?.post?.metrics?.impressions_fan_unique || 0

	return {
		values: [impressionsFanPaidUnique, impressionsFanUnique],
		labels: ['Pagas', 'Únicas']
	}
}

const getImpressionsByStoryTypeUniqueFormatted = (data) => {
	if (!data?.post) {
		return emptyFormatted
	}

	const impressionsFanPaidUnique = extractValuesAndLabelsFromArray(data?.post?.metrics?.impressions_by_story_type_unique)

	return {
		values: impressionsFanPaidUnique.values,
		labels: impressionsFanPaidUnique.labels
	}
}

const getNegativeFeedBackFormatted = (data) => {
	if (!data?.post) {
		return emptyFormatted
	}

	const negativeFeedBackUnique = data?.post?.metrics?.negative_feedback_unique
	const negativeFeedBackCount = data?.post?.metrics?.negative_feedback
	const negativeFeedBackByTypeUnique = extractValuesAndLabelsFromArray(data?.post?.metrics?.negative_feedback_by_type_unique)

	return {
		values: [...negativeFeedBackByTypeUnique.values, negativeFeedBackUnique, negativeFeedBackCount],
		labels: [...negativeFeedBackByTypeUnique.labels, 'Únicos', 'Total']
	}
}

const getVideoAdBreakFormatted = (data) => {
	if (!data?.post) {
		return emptyFormatted
	}

	const videoAdBreakAdCpm = data?.post?.metrics?.video_ad_break_ad_cpm
	const videoAdBreakAdImpressions = data?.post?.metrics?.video_ad_break_ad_impressions
	const videoAdBreakEarnings = data?.post?.metrics?.video_ad_break_earnings

	return {
		values: [videoAdBreakAdCpm, videoAdBreakAdImpressions, videoAdBreakEarnings],
		labels: ['video_ad_break_ad_cpm', 'video_ad_break_ad_impressions', 'video_ad_break_earnings']
	}
}

const getVideoCompleteViews30sFormatted = (data) => {
	if (!data?.post) {
		return emptyFormatted
	}

	const videoCompleteViews30sAutoplayed = data?.post?.metrics?.video_complete_views_30s_autoplayed
	const videoCompleteViews30sClickedToPlay = data?.post?.metrics?.video_complete_views_30s_clicked_to_play
	const videoCompleteViews30sOrganic = data?.post?.metrics?.video_complete_views_30s_organic
	const videoCompleteViews30sPaid = data?.post?.metrics?.video_complete_views_30s_paid
	const videoCompleteViews30sUnique = data?.post?.metrics?.video_complete_views_30s_unique

	return {
		values: [
			videoCompleteViews30sAutoplayed,
			videoCompleteViews30sClickedToPlay,
			videoCompleteViews30sOrganic,
			videoCompleteViews30sPaid,
			videoCompleteViews30sUnique
		],
		labels: [
			'video_complete_views_30s_autoplayed',
			'video_complete_views_30s_clicked_to_play',
			'video_complete_views_30s_organic',
			'video_complete_views_30s_paid',
			'video_complete_views_30s_unique'
		]
	}
}

const getVideoCompleteViewsOrganicFormatted = (data) => {
	if (!data?.post) {
		return emptyFormatted
	}

	const videoCompleteViewsOrganic = data?.post?.metrics?.video_complete_views_organic
	const videoCompleteViewsOrganicUnique = data?.post?.metrics?.video_complete_views_organic_unique

	return {
		values: [videoCompleteViewsOrganic, videoCompleteViewsOrganicUnique],
		labels: ['video_complete_views_organic', 'video_complete_views_organic_unique']
	}
}

const getVideoViewTimeByAgeBucketAndGenderFormatted = (data) => {
	if (!data?.post) {
		return emptyFormatted
	}

	const videoViewTimeByAgeBucketAndGender = extractValuesAndLabelsFromArray(data?.post?.metrics?.video_view_time_by_age_bucket_and_gender)

	return {
		values: videoViewTimeByAgeBucketAndGender.values,
		labels: videoViewTimeByAgeBucketAndGender.labels
	}
}

const getVideoViewTimeByCountryIdFormatted = (data) => {
	if (!data?.post) {
		return emptyFormatted
	}

	const videoViewTimeByCountryId = extractValuesAndLabelsFromArray(data?.post?.metrics?.video_view_time_by_country_id)

	return {
		values: videoViewTimeByCountryId.values,
		labels: videoViewTimeByCountryId.labels
	}
}

const getVideoViewTimeByDistributionTypeFormatted = (data) => {
	if (!data?.post) {
		return emptyFormatted
	}

	const videoViewTimeByDistributionType = extractValuesAndLabelsFromArray(data?.post?.metrics?.video_view_time_by_distribution_type)

	return {
		values: videoViewTimeByDistributionType.values,
		labels: videoViewTimeByDistributionType.labels
	}
}

const getVideoViewTimeByRegionIdFormatted = (data) => {
	if (!data?.post) {
		return emptyFormatted
	}

	const videoViewTimeByRegionId = extractValuesAndLabelsFromArray(data?.post?.metrics?.video_view_time_by_region_id)

	return {
		values: videoViewTimeByRegionId.values,
		labels: videoViewTimeByRegionId.labels
	}
}

const getVideoViewByDistribuitionTypeFormatted = (data) => {
	if (!data?.post) {
		return emptyFormatted
	}

	const videoViewByDistribuitionType = extractValuesAndLabelsFromArray(data?.post?.metrics?.video_views_by_distribution_type)

	return {
		values: videoViewByDistribuitionType.values,
		labels: videoViewByDistribuitionType.labels
	}
}

const getVideoRetentionGraphFormatted = (data) => {
	if (!data?.post) {
		return emptyFormatted
	}

	const videoRetentionGraph = extractValuesAndLabelsFromArray(data?.post?.metrics?.video_retention_graph)

	return {
		values: videoRetentionGraph.values,
		labels: videoRetentionGraph.labels
	}
}

const getVideoReactionsByTypeTotalFormatted = (data) => {
	if (!data?.post) {
		return emptyFormatted
	}

	const videoReactionsByTypeTotal = extractValuesAndLabelsFromArray(data?.post?.metrics?.video_reactions_by_type_total)

	return {
		values: videoReactionsByTypeTotal.values,
		labels: videoReactionsByTypeTotal.labels
	}
}

const getVideoStoriesByActionTypeFormatted = (data) => {
	if (!data?.post) {
		return emptyFormatted
	}

	const videoStoriesByActionType = extractValuesAndLabelsFromArray(data?.post?.metrics?.video_reactions_by_type_total)

	return {
		values: videoStoriesByActionType.values,
		labels: videoStoriesByActionType.labels
	}
}

const getVideoLikesByReactionTypeFormatted = (data) => {
	if (!data?.post) {
		return emptyFormatted
	}

	const videoLikesByReactionType = extractValuesAndLabelsFromArray(data?.post?.metrics?.video_likes_by_reaction_type)

	return {
		values: videoLikesByReactionType.values,
		labels: videoLikesByReactionType.labels
	}
}

//Instagram
const getEngagementFormattedInstagram = (data) => {
	if (!data?.post) {
		return emptyFormatted
	}
	const likeCount = data?.post?.engagement?.like_count || 0
	const commentCount = data?.post?.engagement?.comment_count || 0
	const shareCount = data?.post?.engagement?.share_count || 0
	const replyCount = data?.post?.engagement?.reply_count || 0
	const savedCount = data?.post?.engagement?.saved_count || 0
	const total = likeCount + commentCount + shareCount + replyCount + savedCount

	return {
		values: [likeCount, commentCount, shareCount, replyCount, savedCount, total],
		labels: ['Likes', 'Comentários', 'Compartilhamentos', 'Respostas', 'Salvamentos', 'Total']
	}
}

const getVideoMetricsFormattedInstagram = (data) => {
	if (!data?.post) {
		return emptyFormatted
	}
	const playsCount = data?.post?.engagement?.plays_count || 0
	const videoViewsCount = data?.post?.engagement?.video_views || 0

	return {
		values: [playsCount, videoViewsCount],
		labels: ['Reproduções', 'Visualizações']
	}
}
