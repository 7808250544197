import React, { memo, useEffect, useState, useRef } from 'react'
import OccurrenceCard from './components/OccurrenceCard/OccurrenceCard'
import OccurrenceSkeletonLoad from 'components/SkeletonLoads/OccurrenceSkeletonLoad/OccurrenceSkeletonLoad'
import OccurrencesEmptyFeed from './components/OccurrencesEmptyFeed/OccurrencesEmptyFeed'
import InfiniteScroll from 'react-infinite-scroll-component'
import { PlusIcon } from 'utils/SystemIcons'
import { Button, Card, Grid } from '@mui/material'
import './OccurrencesFeed.css'
import CommentCard from './components/OccurrenceCard/components/ViewComments/components/CommentCard/CommentCard'

const Empty = () => {
	return (
		<Card elevation={0} style={{ borderRadius: '25px', marginBottom: '0.5em' }}>
			<h3>
				<div
					style={{
						color: '#1d8cf8',
						marginTop: '30px',
						marginBottom: '30px',
						fontWeight: '500',
						fontSize: '25px',
						textAlign: 'center',
						float: 'center'
					}}
				>
					Sem mais comentários para esse post
				</div>
			</h3>
		</Card>
	)
}

const isFirefox = typeof InstallTrigger !== 'undefined'
const overflow = isFirefox ? 'auto' : 'overlay'

const OccurrencesFeed = ({
	cards,
	selectedCard,
	orderFilters,
	filters,
	search,
	isDialog = false,
	onSubmitting,
	nextLoad,
	feed,
	loading,
	isReport = false,
	type,
	load,
	isComment,
	onHideAccount,
	onStopWord,
	externalOpenTags,
	isTopPosts = false,
	onChangeSelectedPostsIds,
	selectedPostsIds,
	externalMultipleSelection,
	isWidget = false,
	isCommentWordCloud = false,
	isShareUrl = false
}) => {
	const [submitting, setSubmitting] = useState(false)
	const scrollableDiv = useRef(null)

	useEffect(() => {
		if (scrollableDiv.current !== null) {
			scrollableDiv.current.scrollTop = 0
		}
	}, [filters, orderFilters])

	const onRelevanceSubmitting = (isSubmitting) => {
		setSubmitting(isSubmitting)
		onSubmitting(isSubmitting)
	}

	const loadMore = () => {
		nextLoad()
	}

	const cleanLoad = () => {
		scrollableDiv.current.scrollTop = 0
		load()
	}

	const defineHeight = () => {
		if (isWidget) {
			if (cards?.length > 1) {
				return '32em'
			} else {
				return '34em'
			}
		} else {
			return '750px'
		}
	}

	return (
		<>
			{!isReport && !isTopPosts && (
				<div
					ref={scrollableDiv}
					id='scrollableDiv'
					style={{
						width: isDialog ? '95%' : '60%',
						margin: 'auto',
						marginLeft: isDialog ? '1.7em' : 'auto',
						height: defineHeight(),
						overflow: overflow
					}}
				>
					{isComment && selectedCard?.id && (
						<OccurrenceCard
							id_pesquisa={selectedCard.id}
							cardData={selectedCard}
							key={selectedCard?.id}
							source={filters?.sources}
							classification={filters?.classification}
							search={search}
							type={type}
							isComment={isComment}
							lockActions
							externalOpenTags={externalOpenTags}
							isWidget={isWidget}
							isShareUrl={isShareUrl}
						/>
					)}
					<div style={{ width: '100%', margin: 'auto', height: '100%' }}>
						{!(feed?.loading || loading) && feed?.data?.length > 0 && (
							<InfiniteScroll
								style={{ pointerEvents: submitting ? 'none' : 'auto' }}
								dataLength={feed.data?.length}
								next={loadMore}
								hasMore={!feed.endResults}
								loader={<OccurrenceSkeletonLoad isWidget={isWidget} />}
								endMessage={
									<div style={{ marginTop: '1em' }}>
										{!isComment ? <OccurrencesEmptyFeed isWidget={isWidget} isEndMessage={true} /> : <Empty />}

										<Grid container justifyContent='center'>
											<Button onClick={cleanLoad} style={{ color: '#6b6a6a', borderRadius: '100px' }}>
												<PlusIcon size={30} />
												<div style={{ fontFamily: 'Poppins', marginLeft: '8px' }}>Tentar buscar mais?</div>
											</Button>
										</Grid>
									</div>
								}
								scrollableTarget='scrollableDiv'
							>
								{feed?.data?.map((card) => {
									if ((isComment && card?._id) || isCommentWordCloud) {
										return <CommentCard key={`comment-${card?._id}`} cardData={card} source={filters?.sources[0]} />
									} else if (!isComment && card?.id) {
										return (
											<OccurrenceCard
												id_pesquisa={selectedCard.id}
												selectedCard={selectedCard}
												cardData={card}
												key={card?.id}
												classification={filters?.classification}
												search={search}
												onRelevanceSubmitting={onRelevanceSubmitting}
												type={type}
												isComment={isComment}
												onHideAccount={onHideAccount}
												onStopWord={onStopWord}
												externalOpenTags={externalOpenTags}
												onChangeSelectedPostsIds={onChangeSelectedPostsIds}
												selectedPostsIds={selectedPostsIds}
												externalMultipleSelection={externalMultipleSelection}
												isWidget={isWidget}
												isShareUrl={isShareUrl}
											/>
										)
									} else {
										return null
									}
								})}
							</InfiniteScroll>
						)}
						{!(feed?.loading || loading) && feed?.data?.length === 0 && (
							<OccurrencesEmptyFeed
								isWidget={isWidget}
								selectedPostCommentsNumber={
									selectedCard?.public_metrics?.reply_count || selectedCard.post?.engagement?.comment_count || 0
								}
							/>
						)}

						{(feed?.loading || loading) && <OccurrenceSkeletonLoad isWidget={isWidget} />}
						{!(feed?.loading || loading) && feed?.data?.length !== 0 && !feed.endResults && (
							<>
								<Card elevation={0} style={{ height: '300px', borderRadius: '20px', border: '2px solid rgba(255, 255, 255, 1)' }}>
									<Button onClick={loadMore} style={{ color: '#c0c0c0', height: '100%', width: '100%', borderRadius: '20px' }}>
										<PlusIcon size={50} />
									</Button>
								</Card>
								<div style={{ height: '2em' }} />
							</>
						)}
					</div>
				</div>
			)}
			{isReport &&
				feed?.data?.map((card, index) => {
					return (
						card?.id && (
							<div className={(index + 1) % 3 == 0 ? 'break-print' : ''} key={card.id}>
								<OccurrenceCard
									id_pesquisa={selectedCard.id}
									cardData={card}
									key={card?.id}
									classification={filters?.classification}
									search={search}
									onRelevanceSubmitting={onRelevanceSubmitting}
									isReport={isReport}
									type={type}
									isComment={isComment}
									isWidget={isWidget}
									isShareUrl={isShareUrl}
								/>
							</div>
						)
					)
				})}
			{isTopPosts && (
				<div
					ref={scrollableDiv}
					id='scrollableDiv'
					style={{ width: isDialog ? '100%' : '60%', margin: 'auto', height: defineHeight(), overflow: overflow }}
				>
					<div style={{ width: '100%', margin: 'auto', height: '100%' }}>
						{!(feed?.loading || loading) &&
							feed?.data?.length > 0 &&
							feed?.data?.map((card, index) => {
								if (!isComment && card?.id) {
									return (
										<OccurrenceCard
											id_pesquisa={selectedCard.id}
											selectedCard={selectedCard}
											cardData={card}
											key={card?.id}
											classification={filters?.classification}
											search={search}
											onRelevanceSubmitting={onRelevanceSubmitting}
											type={type}
											isComment={isComment}
											onHideAccount={onHideAccount}
											onStopWord={onStopWord}
											externalOpenTags={externalOpenTags}
											isTopPosts={true}
											indexNumber={index + 1}
											isWidget={isWidget}
											isShareUrl={isShareUrl}
										/>
									)
								} else {
									return null
								}
							})}
						{!(feed?.loading || loading) && feed?.data?.length === 0 && (
							<OccurrencesEmptyFeed
								isWidget={isWidget}
								selectedPostCommentsNumber={
									selectedCard?.public_metrics?.reply_count || selectedCard.post?.engagement?.comment_count || 0
								}
							/>
						)}

						{(feed?.loading || loading) && <OccurrenceSkeletonLoad isWidget={isWidget} />}
					</div>
				</div>
			)}
		</>
	)
}

export default memo(OccurrencesFeed)
