import React from 'react'
import { CircularProgress } from '@mui/material'
import {
	FiSearch,
	FiTriangle,
	FiEdit3,
	FiEye,
	FiSettings,
	FiUsers,
	FiUser,
	FiX,
	FiPlus,
	FiBookmark,
	FiList,
	FiGlobe,
	FiCalendar,
	FiChevronUp,
	FiChevronDown,
	FiChevronRight,
	FiChevronLeft,
	FiHash,
	FiFlag,
	FiInstagram,
	FiAtSign,
	FiRadio,
	FiSave,
	FiMessageSquare,
	FiHeart,
	FiPower,
	FiThumbsUp,
	FiActivity,
	FiFacebook,
	FiMinus,
	FiGrid,
	FiAlertTriangle,
	FiCheck,
	FiSmile,
	FiTrendingUp,
	FiInfo,
	FiRefreshCcw,
	FiCloud,
	FiClock,
	FiBell,
	FiMoreHorizontal,
	FiMoreVertical,
	FiBox,
	FiBarChart,
	FiBarChart2,
	FiMessageCircle,
	FiFolderPlus,
	FiFolder,
	FiPercent,
	FiLoader,
	FiPieChart,
	FiMapPin,
	FiPlayCircle,
	FiPauseCircle,
	FiDisc,
	FiRewind,
	FiFile,
	FiCircle,
	FiDownload,
	FiDollarSign,
	FiHome,
	FiPlusCircle,
	FiUpload,
	FiDownloadCloud,
	FiUploadCloud,
	FiTrash2,
	FiLayers,
	FiUserCheck,
	FiPlay,
	FiMinusCircle,
	FiLock,
	FiDroplet,
	FiCopy,
	FiTag,
	FiSlash,
	FiAlertCircle,
	FiArrowRight,
	FiMove,
	FiMail,
	FiPaperclip,
	FiShare2,
	FiLink2,
	FiStar
} from 'react-icons/fi'
import { VscTextSize, VscMultipleWindows } from 'react-icons/vsc'
import {
	BiErrorCircle,
	BiPencil,
	BiHide,
	BiShow,
	BiSortAlt2,
	BiExport,
	BiFilterAlt,
	BiRadar,
	BiMoveVertical,
	BiNetworkChart,
	BiCard,
	BiSelectMultiple
} from 'react-icons/bi'
import { HiOutlineTranslate, HiOutlineExternalLink, HiOutlineLightBulb } from 'react-icons/hi'
import { CgArrowsH, CgCreditCard } from 'react-icons/cg'
import { FaRegAngry, FaRegSurprise, FaCircle, FaRegAddressCard, FaCheck, FaUser, FaRegEdit, FaRedoAlt } from 'react-icons/fa'
import {
	MdNavigateNext,
	MdNavigateBefore,
	MdOutlineBubbleChart,
	MdHistory,
	MdOutlineCircle,
	MdOutlineReplay,
	MdOutlinePermMedia,
	MdInsights,
	MdPostAdd,
	MdSplitscreen
} from 'react-icons/md'
import { GrConfigure } from 'react-icons/gr'
import { AiOutlineTeam, AiOutlineRetweet, AiOutlineUser, AiOutlineUsergroupAdd, AiFillMessage, AiOutlineLineChart } from 'react-icons/ai'
import {
	RiEmotionLaughLine,
	RiEmotionSadLine,
	RiProfileLine,
	RiPagesLine,
	RiCopperCoinFill,
	RiMegaphoneLine,
	RiBlueskyFill,
	RiImage2Line,
	RiVideoLine
} from 'react-icons/ri'
import { ImProfile, ImStatsBars } from 'react-icons/im'
import { IoAlertOutline } from 'react-icons/io5'
import { TbZoomMoney, TbFile, TbFileHorizontal, TbPlug, TbChartInfographic, TbPlugConnected, TbLayoutDashboard } from 'react-icons/tb'
import { BsWhatsapp, BsCalendarPlus, BsCardHeading, BsFillFilePersonFill, BsReplyAll, BsYoutube } from 'react-icons/bs'
import { GoVerified, GoPlus } from 'react-icons/go'
import { RxUpdate } from 'react-icons/rx'
import { ReactComponent as AllSourcesIconSvg } from 'assets/img/all-sources-icon.svg'
import { FaXTwitter, FaMeta } from 'react-icons/fa6'
import { PiTiktokLogoBold, PiGenderIntersex } from 'react-icons/pi'

export const LoadingIcon = ({ size, color, alignCenter = false, thickness, ...rest }) => (
	<span style={{ color, ...(alignCenter && { display: 'flex', alignContent: 'center' }) }}>
		<CircularProgress size={size} thickness={thickness} color='inherit' {...rest} />
	</span>
)

export const AddressCardIcon = ({ size, color, ...rest }) => <FaRegAddressCard size={size} color={color} {...rest} />
export const AlertExclamationIcon = ({ size, color, ...rest }) => <FiAlertTriangle size={size} color={color} {...rest} />
export const AlertIcon = ({ size, color, ...rest }) => <FiTriangle size={size} color={color} {...rest} />
export const AlertCircle = ({ size, color, ...rest }) => <FiAlertCircle size={size} color={color} {...rest} />
export const AlertIconSimple = ({ size, color, ...rest }) => <IoAlertOutline size={size} color={color} {...rest} />
export const AllCardsIcon = ({ size, color, ...rest }) => <FiLayers size={size} color={color} {...rest} />
export const AllSourcesIcon = ({ size, color, style }) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		xmlnsXlink='http://www.w3.org/1999/xlink'
		viewBox='0 0 100 100'
		style={{ height: size, width: size, fill: color, ...style }}
	>
		<AllSourcesIconSvg />
	</svg>
)
export const AnalysisIcon = ({ size, color, ...rest }) => <FiActivity size={size} color={color} {...rest} />
export const AnexIcon = ({ size, color, ...rest }) => <FiPaperclip size={size} color={color} {...rest} />
export const AngryIcon = ({ size, color, ...rest }) => <FaRegAngry size={size} color={color} {...rest} />
export const AllIcon = ({ size, color, ...rest }) => <FiLoader size={size} color={color} {...rest} />
export const AurasightIcon = ({ size, color, ...rest }) => <MdInsights size={size} color={color} {...rest} />
export const ArrowRightIcon = ({ size, color, ...rest }) => <FiArrowRight size={size} color={color} {...rest} />
export const AtSignIcon = ({ size, color, ...rest }) => <FiAtSign size={size} color={color} {...rest} />
export const ChevronRightIcon = ({ size, color, ...rest }) => <FiChevronRight size={size} color={color} {...rest} />
export const BarsIcon = ({ size, color, ...rest }) => <FiBarChart2 size={size} color={color} {...rest} />
export const BarsIcon2 = ({ size, color, ...rest }) => <FiBarChart size={size} color={color} {...rest} />
export const BeforeIcon = ({ size, color, ...rest }) => <MdNavigateBefore size={size} style={{ color }} {...rest} />
export const BlueskyIcon = ({ size, color, ...rest }) => <RiBlueskyFill size={size} style={{ color }} {...rest} />
export const BookmarkIcon = ({ size, color, ...rest }) => <FiBookmark size={size} style={{ color }} {...rest} />
export const BoxIcon = ({ size, color, ...rest }) => <FiBox size={size} style={{ color }} {...rest} />
export const BubbleIcon = ({ size, color, ...rest }) => <MdOutlineBubbleChart size={size} style={{ color }} {...rest} />
export const CardIcon = ({ size, color, ...rest }) => <CgCreditCard size={size} style={{ color }} {...rest} />
export const CardHeadingIcon = ({ size, color, ...rest }) => <BsCardHeading size={size} style={{ color }} {...rest} />
export const CalendarIcon = ({ size, color, ...rest }) => <FiCalendar size={size} style={{ color }} {...rest} />
export const CalendarPlusIcon = ({ size, color, ...rest }) => <BsCalendarPlus size={size} style={{ color }} {...rest} />
export const ChartIcon = ({ size, color, ...rest }) => <AiOutlineLineChart size={size} style={{ color }} {...rest} />
export const ChartInfographicIcon = ({ size, color, ...rest }) => <TbChartInfographic size={size} style={{ color }} {...rest} />
export const CheckIcon = ({ size, color, ...rest }) => <FiCheck size={size} style={{ color }} {...rest} />
export const CheckIconBold = ({ size, color, ...rest }) => <FaCheck size={size} style={{ color }} {...rest} />
export const ChevronDownIcon = ({ size, color, ...rest }) => <FiChevronDown size={size} style={{ color }} {...rest} />
export const ChevronUpIcon = ({ size, color, ...rest }) => <FiChevronUp size={size} style={{ color }} {...rest} />
export const ChevronLeftIcon = ({ size, color, ...rest }) => <FiChevronLeft size={size} style={{ color }} {...rest} />
export const CircleIcon = ({ size, color, ...rest }) => <FiCircle size={size} style={{ color }} {...rest} />
export const ClockIcon = ({ size, color, ...rest }) => <FiClock size={size} color={color} {...rest} />
export const CloudDownloadIcon = ({ size, color, ...rest }) => <FiDownloadCloud size={size} color={color} {...rest} />
export const CloudIcon = ({ size, color, ...rest }) => <FiCloud size={size} color={color} {...rest} />
export const CloudUploadIcon = ({ size, color, ...rest }) => <FiUploadCloud size={size} color={color} {...rest} />
export const CloseIcon = ({ size, color, ...rest }) => <FiX size={size} color={color} {...rest} />
export const CommentIcon = ({ size, color, ...rest }) => <FiMessageSquare size={size} color={color} {...rest} />
export const ComparativeCardIcon = ({ size, color, ...rest }) => <VscMultipleWindows size={size} color={color} {...rest} />
export const ConfigureIcon = ({ size, color, ...rest }) => <GrConfigure size={size} color={color} {...rest} />
export const CopyIcon = ({ size, color, ...rest }) => <FiCopy size={size} color={color} {...rest} />
export const DeleteBinIcon = ({ size, color, ...rest }) => <FiTrash2 size={size} color={color} {...rest} />
export const DemoIcon = ({ size, color, ...rest }) => <BiCard size={size} color={color} {...rest} />
export const DiscIcon = ({ size, color, ...rest }) => <FiDisc size={size} color={color} {...rest} />
export const DocumentIcon = ({ size, color, ...rest }) => <FiFile size={size} color={color} {...rest} />
export const DotsIcon = ({ size, color, ...rest }) => <FiMoreHorizontal size={size} color={color} {...rest} />
export const DotsVerticalIcon = ({ size, color, ...rest }) => <FiMoreVertical size={size} color={color} {...rest} />
export const DownloadIcon = ({ size, color, ...rest }) => <FiDownload size={size} color={color} {...rest} />
export const DropIcon = ({ size, color, ...rest }) => <FiDroplet size={size} color={color} {...rest} />
export const EditIcon = ({ size, color, ...rest }) => <FiEdit3 size={size} color={color} {...rest} />
export const EditIconAura = ({ size, color, ...rest }) => <FaRegEdit size={size} color={color} {...rest} />
export const EmojiIcon = ({ size, color, ...rest }) => <FiSmile size={size} color={color} {...rest} />
export const ErrorIcon = ({ size, color, ...rest }) => <BiErrorCircle size={size} color={color} {...rest} />
export const ExternalLinkIcon = ({ size, color, ...rest }) => <HiOutlineExternalLink size={size} color={color} {...rest} />
export const ExportIcon = ({ size, color, ...rest }) => <BiExport size={size} color={color} {...rest} />
export const FacebookIcon = ({ size, color, ...rest }) => <FiFacebook size={size} color={color} {...rest} />
export const FavouriteIcon = ({ size, color, ...rest }) => <FiStar size={size} color={color} {...rest} />
export const FilterIcon = ({ size, color, ...rest }) => <BiFilterAlt size={size} color={color} {...rest} />
export const FinancialIcon = ({ size, color, ...rest }) => <FiDollarSign size={size} color={color} {...rest} />
export const FinancialSearchIcon = ({ size, color, ...rest }) => <TbZoomMoney size={size} color={color} {...rest} />
export const FlagIcon = ({ size, color, ...rest }) => <FiFlag size={size} color={color} {...rest} />
export const FolderIcon = ({ size, color, ...rest }) => <FiFolder size={size} color={color} {...rest} />
export const FollowersIcon = ({ size, color, ...rest }) => <AiOutlineUsergroupAdd size={size} color={color} {...rest} />
export const ForbiddenIcon = ({ size, color, ...rest }) => <FiSlash size={size} color={color} {...rest} />
export const EyeIcon = ({ size, color, ...rest }) => <FiEye size={size} style={{ color }} {...rest} />
export const GenderIcon = ({ size, color, ...rest }) => <PiGenderIntersex size={size} style={{ color }} {...rest} />
export const GlobeIcon = ({ size, color, ...rest }) => <FiGlobe size={size} style={{ color }} {...rest} />
export const GridIcon = ({ size, color, ...rest }) => <FiGrid size={size} style={{ color }} {...rest} />
export const GroupIcon = ({ size, color, ...rest }) => <AiOutlineTeam size={size} style={{ color }} {...rest} />
export const HahaIcon = ({ size, color, ...rest }) => <RiEmotionLaughLine size={size} style={{ color }} {...rest} />
export const HashtagIcon = ({ size, color, ...rest }) => <FiHash size={size} style={{ color }} {...rest} />
export const HeartIcon = ({ size, color, ...rest }) => <FiHeart size={size} style={{ color }} {...rest} />
export const HideIcon = ({ size, color, ...rest }) => <BiHide size={size} style={{ color }} {...rest} />
export const HistoryIcon = ({ size, color, ...rest }) => <MdHistory size={size} style={{ color }} {...rest} />
export const HomeIcon = ({ size, color, ...rest }) => <FiHome size={size} style={{ color }} {...rest} />
export const HorizontalArrowsIcon = ({ size, color, ...rest }) => <CgArrowsH size={size} style={{ color }} {...rest} />
export const ImageIcon = ({ size, color, ...rest }) => <RiImage2Line size={size} style={{ color }} {...rest} />
export const InfoIcon = ({ size, color, ...rest }) => <FiInfo size={size} style={{ color }} {...rest} />
export const InsightIcon = ({ size, color, ...rest }) => <HiOutlineLightBulb size={size} style={{ color }} {...rest} />
export const InstagramIcon = ({ size, color, ...rest }) => <FiInstagram size={size} style={{ color }} {...rest} />
export const LanguagesIcon = ({ size, color, ...rest }) => <HiOutlineTranslate size={size} style={{ color }} {...rest} />
export const LikeIcon = ({ size, color, ...rest }) => <FiThumbsUp size={size} style={{ color }} {...rest} />
export const LinkIcon = ({ size, color, ...rest }) => <TbPlug size={size} style={{ color }} {...rest} />
export const LinkShareIcon = ({ size, color, ...rest }) => <FiLink2 size={size} style={{ color }} {...rest} />
export const ListIcon = ({ size, color, ...rest }) => <FiList size={size} style={{ color }} {...rest} />
export const LockIcon = ({ size, color, ...rest }) => <FiLock size={size} style={{ color }} {...rest} />
export const MailIcon = ({ size, color, ...rest }) => <FiMail size={size} style={{ color }} {...rest} />
export const MapPinIcon = ({ size, color, ...rest }) => <FiMapPin size={size} style={{ color }} {...rest} />
export const MessageIcon = ({ size, color, ...rest }) => <FiMessageCircle size={size} style={{ color }} {...rest} />
export const MetaAdsIcon = ({ size, color, ...rest }) => <FaMeta size={size} style={{ color }} {...rest} />
export const MoveIcon = ({ size, color, ...rest }) => <BiMoveVertical size={size} style={{ color }} {...rest} />
export const NetworkIcon = ({ size, color, ...rest }) => <BiNetworkChart size={size} style={{ color }} {...rest} />
export const NeutralIcon = ({ size, color, ...rest }) => <MdOutlineCircle size={size} style={{ color }} {...rest} />
export const MediaIcon = ({ size, color, ...rest }) => <MdOutlinePermMedia size={size} style={{ color }} {...rest} />
export const MinusIcon = ({ size, color, ...rest }) => <FiMinus size={size} style={{ color }} {...rest} />
export const MinusCircleIcon = ({ size, color, ...rest }) => <FiMinusCircle size={size} style={{ color }} {...rest} />
export const MoveResizeIcon = ({ size, color, ...rest }) => <FiMove size={size} style={{ color }} {...rest} />
export const NewFolderIcon = ({ size, color, ...rest }) => <FiFolderPlus size={size} style={{ color }} {...rest} />
export const NewsIcon = ({ size, color, ...rest }) => <FiRadio size={size} style={{ color }} {...rest} />
export const NextIcon = ({ size, color, ...rest }) => <MdNavigateNext size={size} style={{ color }} {...rest} />
export const NotificationIcon = ({ size, color, ...rest }) => <FiBell size={size} style={{ color }} {...rest} />
export const NotificationPlusIcon = ({ size, color, ...rest }) => <GoPlus size={size} style={{ color }} {...rest} />
export const NotificationUnreadIcon = ({ size, color, ...rest }) => <FaCircle size={size} style={{ color }} {...rest} />
export const OrderIcon = ({ size, color, ...rest }) => <BiSortAlt2 size={size} style={{ color }} {...rest} />
export const OverviewIcon = ({ size, color, ...rest }) => <FiPieChart size={size} style={{ color }} {...rest} />
export const PageIcon = ({ size, color, ...rest }) => <RiPagesLine size={size} style={{ color }} {...rest} />
export const PauseIcon = ({ size, color, ...rest }) => <FiPauseCircle size={size} style={{ color }} {...rest} />
export const PencilIcon = ({ size, color, ...rest }) => <BiPencil size={size} style={{ color }} {...rest} />
export const PercentIcon = ({ size, color, ...rest }) => <FiPercent size={size} style={{ color }} {...rest} />
export const PlusIcon = ({ size, color, ...rest }) => <FiPlus size={size} style={{ color }} {...rest} />
export const PlusCircleIcon = ({ size, color, ...rest }) => <FiPlusCircle size={size} style={{ color }} {...rest} />
export const PostAddIcon = ({ size, color, ...rest }) => <MdPostAdd size={size} color={color} {...rest} />
export const PowerIcon = ({ size, color, ...rest }) => <FiPower size={size} style={{ color }} {...rest} />
export const ProfileIcon = ({ size, color, ...rest }) => <RiProfileLine size={size} style={{ color }} {...rest} />
export const ProfilePersonIcon = ({ size, color, ...rest }) => <ImProfile size={size} style={{ color }} {...rest} />
export const PlayIcon = ({ size, color, ...rest }) => <FiPlayCircle size={size} style={{ color }} {...rest} />
export const PlayIconSmall = ({ size, color, ...rest }) => <FiPlay size={size} style={{ color }} {...rest} />
export const RadarIcon = ({ size, color, ...rest }) => <BiRadar size={size} style={{ color }} {...rest} />
export const RadioWavesIcon = ({ size, color, ...rest }) => <FiRadio size={size} style={{ color }} {...rest} />
export const PotentialReachIcon = ({ size, color, ...rest }) => <RiMegaphoneLine size={size} style={{ color }} {...rest} />
export const RefreshIcon = ({ size, color, ...rest }) => <FiRefreshCcw size={size} style={{ color }} {...rest} />
export const RefreshIconFa = ({ size, color, ...rest }) => <FaRedoAlt size={size} style={{ color }} {...rest} />
export const RefreshIconMd = ({ size, color, ...rest }) => <MdOutlineReplay size={size} style={{ color }} {...rest} />
export const ReportIcon = ({ size, color, ...rest }) => <TbLayoutDashboard size={size} style={{ color }} {...rest} />
export const ReplyIcon = ({ size, color, ...rest }) => <BsReplyAll size={size} style={{ color }} {...rest} />
export const ReportIconVertical = ({ size, color, ...rest }) => <TbFile size={size} style={{ color }} {...rest} />
export const ReportIconHorizontal = ({ size, color, ...rest }) => <TbFileHorizontal size={size} style={{ color }} {...rest} />
export const RetweetIcon = ({ size, color, ...rest }) => <AiOutlineRetweet size={size} style={{ color }} {...rest} />
export const RewindIcon = ({ size, color, ...rest }) => <FiRewind size={size} style={{ color }} {...rest} />
export const SearchIcon = ({ size, color, ...rest }) => <FiSearch size={size} color={color} {...rest} />
export const SadIcon = ({ size, color, ...rest }) => <RiEmotionSadLine size={size} color={color} {...rest} />
export const SaveIcon = ({ size, color, ...rest }) => <FiSave size={size} style={{ color }} {...rest} />
export const SelectMultipleIcon = ({ size, color, ...rest }) => <BiSelectMultiple size={size} style={{ color }} {...rest} />
export const SeparatorIcon = ({ size, color, ...rest }) => <FiChevronRight size={size} style={{ color }} {...rest} />
export const SettingsIcon = ({ size, color, ...rest }) => <FiSettings size={size} style={{ color }} {...rest} />
export const ShareIcon = ({ size, color, ...rest }) => <FiShare2 size={size} style={{ color }} {...rest} />
export const SplitIcon = ({ size, color, ...rest }) => <MdSplitscreen size={size} style={{ color }} {...rest} />
export const SocialProfileIcon = ({ size, color, ...rest }) => <BsFillFilePersonFill size={size} style={{ color }} {...rest} />
export const StatsIcon = ({ size, color, ...rest }) => <ImStatsBars size={size} style={{ color }} {...rest} />
export const TagIcon = ({ size, color, ...rest }) => <FiTag size={size} style={{ color }} {...rest} />
export const TermsCountIcon = ({ size, color, ...rest }) => <AiFillMessage size={size} style={{ color }} {...rest} />
export const TokenIcon = ({ size, color, ...rest }) => <RiCopperCoinFill size={size} style={{ color }} {...rest} />
export const TwitterIcon = ({ size, color, ...rest }) => <FaXTwitter size={size} style={{ color }} {...rest} />
export const ShowIcon = ({ size, color, ...rest }) => <BiShow size={size} style={{ color }} {...rest} />
export const TiktokIcon = ({ size, color, ...rest }) => <PiTiktokLogoBold size={size} style={{ color }} {...rest} />
export const TrendingIcon = ({ size, color, ...rest }) => <FiTrendingUp size={size} style={{ color }} {...rest} />
export const UnlinkIcon = ({ size, color, ...rest }) => <TbPlugConnected size={size} style={{ color }} {...rest} />
export const UploadIcon = ({ size, color, ...rest }) => <FiUpload size={size} style={{ color }} {...rest} />
export const UsersIcon = ({ size, color, ...rest }) => <FiUsers size={size} style={{ color }} {...rest} />
export const UserIcon = ({ size, color, ...rest }) => <AiOutlineUser size={size} style={{ color }} {...rest} />
export const UserFillIcon = ({ size, color, ...rest }) => <FaUser size={size} style={{ color }} {...rest} />
export const User2Icon = ({ size, color, ...rest }) => <FiUser size={size} style={{ color }} {...rest} />
export const UserCheckIcon = ({ size, color, ...rest }) => <FiUserCheck size={size} style={{ color }} {...rest} />
export const UpdateIcon = ({ size, color, ...rest }) => <RxUpdate size={size} style={{ color }} {...rest} />
export const VerifiedIcon = ({ size, color, ...rest }) => <GoVerified size={size} style={{ color }} {...rest} />
export const VideoIcon = ({ size, color, ...rest }) => <RiVideoLine size={size} style={{ color }} {...rest} />
export const WhatsappIcon = ({ size, color, ...rest }) => <BsWhatsapp size={size} style={{ color }} {...rest} />
export const WordsIcon = ({ size, color, ...rest }) => <VscTextSize size={size} style={{ color }} {...rest} />
export const WowIcon = ({ size, color, ...rest }) => <FaRegSurprise size={size} style={{ color }} {...rest} />
export const YoutubeIcon = ({ size, color, ...rest }) => <BsYoutube size={size} style={{ color }} {...rest} />
