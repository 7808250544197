import React from 'react'
import { Button, Grid } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import { EyeIcon, FinancialIcon, UsersIcon, AurasightIcon } from 'utils/SystemIcons'
import { connect } from 'react-redux'

const blue = 'rgba(29, 138, 248, 100%)'
const grey = 'rgba(0, 0, 0, 25%)'
// const green = '#00E396'
const isFirefox = typeof InstallTrigger !== 'undefined'

const useStyles = makeStyles({
	root: {
		color: blue,
		font: 'Poppins',
		fontWeight: '15'
	},
	label: {
		textTransform: 'capitalize'
	},
	button: {
		fontFamily: 'Poppins',
		height: '25em',
		width: '25em',
		backdropFilter: 'blur(10px)',
		borderRadius: '30px',
		backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
		border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
		boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )',
		color: grey,
		background: 'transparent',
		'&:hover': {
			color: blue,
			// border: '1px solid rgba(29, 138, 248, 90%)',
			// backgroundColor: 'rgba(29, 138, 248, 5%)',
			backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
			// boxShadow: '0px 0px -10px -17px rgba(0,0,0,0)'
			boxShadow: '0 30px 30px 0 rgba( 200, 200, 200, 0.7 )'
		}
	},
	backDrop: {
		backdropFilter: 'blur(3px)',
		backgroundColor: 'rgba(220, 220, 220, 0.4)'
	}
})

const Products = ({ user }) => {
	const history = useHistory()
	const classes = useStyles()

	const handleRedirect = (path) => {
		history.push(path)
	}

	return (
		<Grid container style={{ height: '85vh', padding: '5%' }} justifyContent='space-around' alignItems='center'>
			{user.hasSocial && (
				<Grid item md={12} lg={3} style={{ justifyContent: 'center', display: 'flex', marginTop: '0.5em' }}>
					<Button id='btn-monitoring' className={classes.button} onClick={() => handleRedirect('/monitoring')}>
						<EyeIcon size={30} style={{ color: '#1d8cf8', marginRight: '10px' }} />
						<span style={{ fontSize: '24px' }}>Monitoramento</span>
					</Button>
				</Grid>
			)}
			{user.hasAccountManager && (
				<Grid item md={12} lg={3} style={{ justifyContent: 'center', display: 'flex', marginTop: '0.5em' }}>
					<Button id='btn-account-management' className={classes.button} onClick={() => handleRedirect('/account-management')}>
						<UsersIcon size={30} style={{ color: '#1d8cf8', marginRight: '10px' }} />
						<span style={{ fontSize: '24px' }}>minhas contas</span>
					</Button>
				</Grid>
			)}
			{user.hasFinancial && (
				<Grid item md={12} lg={3} style={{ justifyContent: 'center', display: 'flex', marginTop: '0.5em' }}>
					<Button id='btn-financial' className={classes.button} onClick={() => handleRedirect('/financial')}>
						<FinancialIcon size={30} style={{ color: '#1d8cf8', marginRight: '10px' }} />
						<span style={{ fontSize: '24px' }}>Financeiro</span>
					</Button>
				</Grid>
			)}
			{user.hasImageAnnotation && (
				<Grid item md={12} lg={3} style={{ justifyContent: 'center', display: 'flex', marginTop: '0.5em' }}>
					<Button id='btn-image-annotation' className={classes.button} onClick={() => handleRedirect('/image-annotation')}>
						<AurasightIcon size={30} style={{ color: '#1d8cf8', marginRight: '10px' }} />
						<span style={{ fontSize: '24px' }}>AuraSight</span>
					</Button>
				</Grid>
			)}
		</Grid>
	)
}

const mapStateToProps = ({ store: { user } }) => ({
	user
})

export default connect(mapStateToProps, null)(Products)
